import { Component, OnDestroy, OnInit } from "@angular/core";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { MatSelGuidelinesComponentsService } from "./services/mat-sel-guidelines-components.service";
import { Subscription } from "rxjs";
import { TreeService } from "src/app/services/Tree.service";
import { DataService } from "src/app/services/Data.service";
import { ActionService } from "src/app/services/Action.service";
import { HeaderState } from "src/app/models/header/HeaderState";
import { MatSelGuidelinesService } from "src/app/services/mat-sel-guidelines/mat-sel-guidelines.service";
import Swal from "sweetalert2";
import { PdfService } from "src/app/services/pdf.service";
import { PrintedModalData } from "../CasingWear/models/printed";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";

@Component({
  selector: "app-mat-sel-guidelines",
  templateUrl: "./mat-sel-guidelines.component.html",
  styleUrls: ["./mat-sel-guidelines.component.css"],
})
export class MatSelGuidelinesComponent implements OnInit, OnDestroy {
  isPreSimulationFormValid: boolean = false;
  customerId: number | null;
  countryId: number | null;

  readonly NAVIGATIONS = NAVIGATIONS;

  subActionService: Subscription;
  subDataService: Subscription;
  validReportMSG: boolean = false;
  simulationName: string = "";
  treeId?: number = null;
  nameCurrentUser: string;
  department: string = "";
  rev: number = 1;

  constructor(
    private matSelGuidelinesComponentsService: MatSelGuidelinesComponentsService,
    private matSelGuidelinesService: MatSelGuidelinesService,
    private preSimulationService: PreSimulationService,
    private actionService: ActionService,
    private dataService: DataService,
    private treeService: TreeService,
    private pdfService: PdfService,
    private unitMeasureService: UnitMeasureService
  ) {}

  ngOnInit() {
    this.dataService.changeModulo("matSelGuidelines");

    this.subscribeActionService();
    this.subscribeDataService();

    this.dataService.currentValidateReportMSGSource.subscribe(
      (validReport) => (this.validReportMSG = validReport)
    );

    this.dataService.currentDepartmentSource.subscribe(
      (dpto) => (this.department = dpto)
    );

    this.dataService.currentNameSurnameSource.subscribe(
      (name) => (this.nameCurrentUser = name)
    );

    this.preSimulationService.formValidity$.subscribe((isValid) => {
      this.isPreSimulationFormValid = isValid;
    });

    this.matSelGuidelinesComponentsService.formChanges.subscribe(() => {
      this.simulationName = "";
      this.treeId = null;
    });
  }

  private subscribeDataService() {
    this.subDataService = this.dataService.currentData.subscribe(
      (selectedSimulation) => {
        if (selectedSimulation.action != "") {
          switch (selectedSimulation.action) {
            case HeaderState.load:
              this.confirmLoad(selectedSimulation);
              break;
            case HeaderState.save:
              this.confirmSave(selectedSimulation);
              break;
            case HeaderState.delete:
              this.confirmDelete(selectedSimulation);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  private subscribeActionService() {
    this.subActionService = this.actionService.currentMessage.subscribe(
      (value) => {
        switch (value) {
          case HeaderState.new:
            this.confirmReset();
            break;
          case HeaderState.report:
            this.printReport();
            break;
          case HeaderState.validate:
            this.confirmReport();
            break;
          default:
            break;
        }
      }
    );
  }

  confirmReset() {
    if (this.isFormNotEmpty()) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSimulation();
          this.preSimulationService.resetValues();
        }
      });
    } else {
      this.resetSimulation();
      this.preSimulationService.resetValues();
      Swal.fire({
        title: "New simulation created",
        icon: "info",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
  }

  resetSimulation() {
    this.treeId = null;
    this.simulationName = "";
    this.matSelGuidelinesComponentsService.resetValues();
    this.matSelGuidelinesComponentsService.setIsPrinteable(false);
    this.matSelGuidelinesComponentsService.setIsPrinteableOpt1(false);
    this.matSelGuidelinesComponentsService.setIsPrinteableOpt2(false);
    this.matSelGuidelinesComponentsService.setIsPrinteableCra(false);
    this.dataService.changeValidateReportMSG(false);
  }

  printReport() {
    if (
      !this.actionService.opt1Checked &&
      !this.actionService.opt2Checked &&
      !this.actionService.craChecked
    ) {
      this.fireWarningModal(
        "Action required",
        "Please select at least one option to print"
      );

      return;
    }

    let fgOpt1 = this.matSelGuidelinesComponentsService.getCurrentFgOpt1();
    let fgOpt2 = this.matSelGuidelinesComponentsService.getCurrentFgOpt2();
    let fgPhOpt2 = this.matSelGuidelinesComponentsService.getCurrentFgPhOpt2();
    let fgCra = this.matSelGuidelinesComponentsService.getCurrentFgCra();
    let modelOpt1 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt1();
    let modelOpt2 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt2();
    let modelCra = this.matSelGuidelinesComponentsService.getCurrentModelCra();

    const isFgOpt1Empty = !fgOpt1 || this.areAllValuesNullOrUndefined(fgOpt1);
    const isFgOpt2Empty = !fgOpt2 || this.areAllValuesNullOrUndefined(fgOpt2);
    const isFgPhOpt2Empty =
      !fgPhOpt2 || this.areAllValuesNullOrUndefined(fgPhOpt2);
    const isFgCraEmpty = !fgCra || this.areAllValuesNullOrUndefined(fgCra);

    let printPdfMSGRequest = {
      header: this.printFileMSGRequest(this.actionService.data),
    };

    const opt1Units =
      this.matSelGuidelinesComponentsService.getCurrentUnitsOpt1();
    const opt2Units =
      this.matSelGuidelinesComponentsService.getCurrentUnitsOpt2();
    const craUnits =
      this.matSelGuidelinesComponentsService.getCurrentUnitsCra();

    printPdfMSGRequest["matSelGuidelineOpt1"] =
      !isFgOpt1Empty && this.actionService.opt1Checked
        ? {
            min_temperature:
              (fgOpt1.minTemperature ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Temperature,
                opt1Units.minTemperature
              )}`,
            total_pressure:
              (fgOpt1.totalPressure ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                opt1Units.totalPressure
              )}`,
            percentage_H2S: (fgOpt1.h2s ?? "-") + " %",
            grade: fgOpt1.grade ?? null,
            sour_condition: modelOpt1?.sourCondition ?? "-",
            recommended_grades: modelOpt1?.recommendedGradesString ?? null,
            recommended_grades_comment:
              modelOpt1?.recommendedGradesComment ?? null,
            criteria: modelOpt1?.criteria15156opt1 ?? null,
            background_color: modelOpt1?.backgroundColor ?? null,
            partial_pressure_h2s:
              (this.matSelGuidelinesComponentsService.getCurrentPPH2SOpt1() ??
                "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                opt1Units.totalPressure
              )}`,
          }
        : null;
    printPdfMSGRequest["matSelGuidelineOpt2"] =
      (!isFgOpt2Empty || !isFgPhOpt2Empty) && this.actionService.opt2Checked
        ? {
            ph: fgOpt2?.phInSitu ?? "-",
            partial_pressure_CO2:
              (fgPhOpt2?.ppCO2 ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                opt2Units.ppCO2
              )}`,
            partial_pressure_H2S:
              (fgOpt2?.pph2s ?? fgPhOpt2?.ppH2S ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                fgOpt2?.pph2s ? opt2Units.pph2s : opt2Units.ppH2S
              )}`,
            ca:
              (fgPhOpt2?.ca2 ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Concentration,
                opt2Units.ca2
              )}`,
            hco3:
              (fgPhOpt2?.hco3 ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Concentration,
                opt2Units.hco3
              )}`,
            temperature:
              (fgPhOpt2?.temperature ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Temperature,
                opt2Units.temperature
              )}`,
            total_pressure: (fgPhOpt2?.oSumPP ?? "-") + " kPa",
            calcImage: modelOpt2?.imgPH ? `${modelOpt2.imgPH}` : null,
            regionImage: modelOpt2?.imgModel ? `${modelOpt2.imgModel}` : null,
          }
        : null;
    printPdfMSGRequest["matSelGuidelineCra"] =
      !isFgCraEmpty && this.actionService.craChecked
        ? {
            temperature:
              (fgCra.temperature ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Temperature,
                craUnits.temperature
              )}`,
            pH: fgCra.ph ?? "-",
            total_Pressure:
              (fgCra.totalPressure ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                craUnits.totalPressure
              )}`,
            total_Cl: (fgCra.totalCl ?? "-") + " mg/l",
            percentage_H2S: (fgCra.h2s ?? "-") + " %",
            grade: fgCra.grade ?? "-",
            minYS:
              (fgCra.minYSRequired ?? "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                craUnits.minYSRequired
              )}`,
            partial_pressure_H2S:
              (this.matSelGuidelinesComponentsService.getCurrentPPH2SCra() ??
                "-") +
              ` ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Pressure,
                craUnits.totalPressure
              )}`,
            criteria: modelCra?.criteria15156cracking ?? null,
            background_color: modelCra?.backgroundColor ?? null,
            sour_considerations: modelCra?.sourConsiderations ?? null,
            recommended_grades_envi: modelCra?.recommendedGradesEnviMap ?? null,
            recommended_grades_envi_mech:
              modelCra?.recommendedGradesEnviMechMap ?? null,
            recommended_grades_comment:
              modelCra?.recommendedGradesComment ?? null,
          }
        : null;

    this.pdfService.getPdfMSG(printPdfMSGRequest);
  }

  printFileMSGRequest(printedModalData: PrintedModalData) {
    let printPdfRequest = {
      printOnlyFirstPage: printedModalData.printOnlyFirstPage,
      requestedBy: printedModalData.requestedBy,
      authorizedBy: printedModalData.authorizedBy,
      reviewedBy: printedModalData.reviewedBy,
      SDCaseNumber: printedModalData.SDCaseNumber,
      createdBy: this.nameCurrentUser ? this.nameCurrentUser : "-",
      modelVer: this.rev,
    };

    return printPdfRequest;
  }

  confirmReport() {
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
      this.dataService.changeValidateReportMSG(false);
      return;
    }

    if (this.anyFormValid()) {
      if (
        this.isFormNotEmpty() &&
        (!this.matSelGuidelinesComponentsService.getIsPrinteable() ||
          !this.validateIfPreSimulationSaved(this.customerId, this.countryId))
      ) {
        this.fireWarningModal(
          "Action required",
          "Please save simulation to print report"
        );
        this.dataService.changeValidateReportMSG(false);
        return;
      }
    } else {
      this.fireWarningModal(
        "Action required",
        "Please verify all values to print report"
      );
      this.dataService.changeValidateReportMSG(false);
      return;
    }

    this.dataService.changeValidateReportMSG(true);
  }

  validateIfPreSimulationSaved(
    customerId: number | null,
    countryId: number | null
  ): boolean {
    let isSaved = true;
    if (
      this.preSimulationService.getCurrentCountryId() == null ||
      this.preSimulationService.getCurrentCustomerId() == null ||
      this.preSimulationService.getCurrentCountryId() != countryId ||
      this.preSimulationService.getCurrentCustomerId() != customerId
    )
      isSaved = false;

    return isSaved;
  }

  private fireWarningModal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
    });
  }

  confirmLoad(selectedSimulation) {
    if (this.isFormNotEmpty()) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) this.loadSimulation(selectedSimulation);
      });
    } else {
      this.loadSimulation(selectedSimulation);
    }
  }

  loadSimulation(selectedSimulation) {
    this.matSelGuidelinesService
      .loadSimulation(selectedSimulation.id)
      .subscribe(
        (response) => {
          this.rev = response.matSelGuideline.rev;
          this.treeId = response.matSelGuideline.tree_id;
          this.customerId = response.matSelGuideline.customer_id;
          this.countryId = response.matSelGuideline.country_id;
          this.preSimulationService.setLoadedCustomerId(this.customerId);
          this.preSimulationService.setLoadedCountryId(this.countryId);
          this.preSimulationService.setSavedCustomerId(this.customerId);
          this.preSimulationService.setSavedCountryId(this.countryId);

          this.matSelGuidelinesComponentsService.loadOpt1(
            response.matSelGuideline.matSelGuidelineOpt1
          );
          this.matSelGuidelinesComponentsService.loadOpt2(
            response.matSelGuideline.matSelGuidelineOpt2
          );
          this.matSelGuidelinesComponentsService.loadCra(
            response.matSelGuideline.matSelGuidelineCra
          );

          jQuery("#sidebar").removeClass("active");
          jQuery(".overlay").removeClass("active");

          this.simulationName = selectedSimulation.path;

          this.dataService.setSimulationIdName(selectedSimulation.path);

          this.matSelGuidelinesComponentsService.setIsPrinteable(true);

          if (this.anyFormValid() && this.customerId && this.countryId)
            this.dataService.changeValidateReportMSG(true);

          this.setPrinteableCheckbox();

          if (!this.customerId || !this.countryId)
            Swal.fire({
              title: "Action required",
              text: "Please select customer and country to view the simulation",
              icon: "warning",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          else
            Swal.fire({
              title: "Loaded!",
              text: "The simulation has been loaded.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });
        },
        (err: Error) => {
          Swal.fire({
            title: err.message,
            icon: "error",
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
          });

          this.simulationName = "";
          this.dataService.changeValidateReportMSG(false);
        }
      );
  }

  confirmSave(selectedSimulation) {
    if (selectedSimulation.visibility == "Select") {
      this.fireWarningModal("Action required", "Please select Visibility");
    } else {
      let fgOpt1 = this.matSelGuidelinesComponentsService.getCurrentFgOpt1();
      let fgOpt2 = this.matSelGuidelinesComponentsService.getCurrentFgOpt2();
      let fgPhOpt2 =
        this.matSelGuidelinesComponentsService.getCurrentFgPhOpt2();
      let fgCra = this.matSelGuidelinesComponentsService.getCurrentFgCra();
      let modelOpt1 =
        this.matSelGuidelinesComponentsService.getCurrentModelOpt1();
      let modelOpt2 =
        this.matSelGuidelinesComponentsService.getCurrentModelOpt2();
      let modelCra =
        this.matSelGuidelinesComponentsService.getCurrentModelCra();

      const isFgOpt1Empty = !fgOpt1 || this.areAllValuesNullOrUndefined(fgOpt1);
      const isFgOpt2Empty = !fgOpt2 || this.areAllValuesNullOrUndefined(fgOpt2);
      const isFgPhOpt2Empty =
        !fgPhOpt2 || this.areAllValuesNullOrUndefined(fgPhOpt2);
      const isFgCraEmpty = !fgCra || this.areAllValuesNullOrUndefined(fgCra);

      let saveSimulationRequest = {
        user_id: selectedSimulation.userId,
        tree_id: selectedSimulation.id,
        name: selectedSimulation.text,
        department: this.department,
        visibility: selectedSimulation.visibility,
        tree_notes: selectedSimulation.notes,
        matSelGuidelineOpt1: !isFgOpt1Empty
          ? {
              min_temperature: fgOpt1.minTemperatureDefault ?? null,
              total_pressure: fgOpt1.totalPressureDefault ?? null,
              percentage_H2S: fgOpt1.h2s ?? null,
              grade: fgOpt1.grade ?? null,
              sour_condition: modelOpt1?.sourCondition ?? null,
              recommended_grades: modelOpt1?.recommendedGradesString ?? null,
              recommended_grades_comment:
                modelOpt1?.recommendedGradesComment ?? null,
              criteria: modelOpt1?.criteria15156opt1 ?? null,
              background_color: modelOpt1?.backgroundColor ?? null,
              partial_pressure_h2s: modelOpt1?.ppH2s ?? null,
            }
          : null,
        matSelGuidelineOpt2:
          !isFgOpt2Empty || !isFgPhOpt2Empty
            ? {
                model_partial_pressure_H2S: fgOpt2?.pph2sDefault ?? null,
                model_ph: fgOpt2?.phInSitu ?? null,
                partial_pressure_CO2: fgPhOpt2?.ppCO2Default ?? null,
                partial_pressure_H2S: fgPhOpt2?.ppH2SDefault ?? null,
                ca: fgPhOpt2?.ca2Default ?? null,
                hco3: fgPhOpt2?.hco3Default ?? null,
                temperature: fgPhOpt2?.temperatureDefault ?? null,
                hasPtCoord:
                  this.matSelGuidelinesComponentsService.getHasPtCoord(),
              }
            : null,
        matSelGuidelineCra: !isFgCraEmpty
          ? {
              temperature: fgCra.temperatureDefault ?? null,
              pH: fgCra.ph ?? null,
              total_Pressure: fgCra.totalPressureDefault ?? null,
              total_Cl: fgCra.totalCl ?? null,
              percentage_H2S: fgCra.h2s ?? null,
              grade: fgCra.grade ?? null,
              minYS: fgCra.minYSRequiredDefault ?? null,
              partial_pressure_H2S: modelCra?.pph2s ?? null,
              criteria: modelCra?.criteria15156cracking ?? null,
              background_color: modelCra?.backgroundColor ?? null,
              sour_considerations: modelCra?.sourConsiderations ?? null,
              recommended_grades_envi:
                modelCra?.recommendedGradesEnviMap ?? null,
              recommended_grades_envi_mech:
                modelCra?.recommendedGradesEnviMechMap ?? null,
              recommended_grades_comment:
                modelCra?.recommendedGradesComment ?? null,
            }
          : null,
        customer_id: this.preSimulationService.getCurrentCustomerId(),
        country_id: this.preSimulationService.getCurrentCountryId(),
      };

      this.matSelGuidelinesService
        .saveSimulation(saveSimulationRequest)
        .subscribe(
          (response) => {
            Swal.fire({
              title: "Saved!",
              text: "The simulation has been saved.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });
            jQuery("#sidebar").removeClass("active");
            jQuery(".overlay").removeClass("active");

            this.rev = response.rev;
            this.treeId = response.treeId;
            this.simulationName = selectedSimulation.path;
            this.customerId = saveSimulationRequest.customer_id;
            this.countryId = saveSimulationRequest.country_id;
            this.preSimulationService.setSavedCustomerId(this.customerId);
            this.preSimulationService.setSavedCountryId(this.countryId);
            this.dataService.setSimulationIdName(selectedSimulation.path);

            this.matSelGuidelinesComponentsService.setIsPrinteable(true);

            this.setPrinteableCheckbox();

            if (this.anyFormValid() && this.customerId && this.countryId)
              this.dataService.changeValidateReportMSG(true);
            else this.dataService.changeValidateReportMSG(false);
          },
          (err: Error) => {
            Swal.fire({
              title: err.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
            this.dataService.changeValidateReportMSG(false);
          }
        );
    }
  }

  setPrinteableCheckbox() {
    let fgOpt1 = this.matSelGuidelinesComponentsService.getCurrentFormOpt1();
    let fgOpt2 = this.matSelGuidelinesComponentsService.getCurrentFormOpt2();
    let fgPhOpt2 =
      this.matSelGuidelinesComponentsService.getCurrentFormPhOpt2();
    let fgCra = this.matSelGuidelinesComponentsService.getCurrentFormCra();
    let modelOpt1 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt1();
    let modelOpt2 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt2();
    let modelCra = this.matSelGuidelinesComponentsService.getCurrentModelCra();

    this.matSelGuidelinesComponentsService.setIsPrinteableOpt1(
      fgOpt1?.valid && modelOpt1
    );
    this.matSelGuidelinesComponentsService.setIsPrinteableOpt2(
      (fgOpt2?.valid || fgPhOpt2?.valid) && modelOpt2
    );
    this.matSelGuidelinesComponentsService.setIsPrinteableCra(
      fgCra?.valid && modelCra
    );
  }

  confirmDelete(selectedSimulation) {
    Swal.fire({
      title: "Do you want to delete the item",
      text: selectedSimulation.path + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSimulation(selectedSimulation);
      }
    });
  }

  deleteSimulation(selectedSimulation) {
    this.treeService.deleteTree(
      this.dataService.extraData,
      selectedSimulation.userId,
      selectedSimulation
    );
    this.actionService.changeAction(HeaderState.cancel);
    jQuery("#sidebar").removeClass("active");
    jQuery(".overlay").removeClass("active");
    if (selectedSimulation.level == "simulation") {
      this.matSelGuidelinesService
        .deleteSimulation(selectedSimulation.id)
        .subscribe(
          (response) => {
            Swal.fire({
              title: "Deleted!",
              text: "The item has been deleted.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });

            if (selectedSimulation.id == this.treeId) this.resetSimulation();
          },
          (err: Error) => {
            Swal.fire({
              title: err.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        );
    } else {
      Swal.fire({
        title: "Deleted!",
        text: "The item has been deleted.",
        icon: "success",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  areAllValuesNullOrUndefined(obj: any): boolean {
    return Object.values(obj).every(
      (value) =>
        value === null ||
        value === undefined ||
        (typeof value === "object" && Object.keys(value).length === 0)
    );
  }

  isFormNotEmpty() {
    let fgOpt1 = this.matSelGuidelinesComponentsService.getCurrentFgOpt1();
    let fgOpt2 = this.matSelGuidelinesComponentsService.getCurrentFgOpt2();
    let fgPhOpt2 = this.matSelGuidelinesComponentsService.getCurrentFgPhOpt2();
    let fgCra = this.matSelGuidelinesComponentsService.getCurrentFgCra();

    const isFgOpt1Empty = !fgOpt1 || this.areAllValuesNullOrUndefined(fgOpt1);
    const isFgOpt2Empty = !fgOpt2 || this.areAllValuesNullOrUndefined(fgOpt2);
    const isFgPhOpt2Empty =
      !fgPhOpt2 || this.areAllValuesNullOrUndefined(fgPhOpt2);
    const isFgCraEmpty = !fgCra || this.areAllValuesNullOrUndefined(fgCra);

    return (
      !isFgOpt1Empty || !isFgOpt2Empty || !isFgPhOpt2Empty || !isFgCraEmpty
    );
  }

  anyFormValid() {
    let fgOpt1 = this.matSelGuidelinesComponentsService.getCurrentFormOpt1();
    let fgOpt2 = this.matSelGuidelinesComponentsService.getCurrentFormOpt2();
    let fgPhOpt2 =
      this.matSelGuidelinesComponentsService.getCurrentFormPhOpt2();
    let fgCra = this.matSelGuidelinesComponentsService.getCurrentFormCra();
    let modelOpt1 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt1();
    let modelOpt2 =
      this.matSelGuidelinesComponentsService.getCurrentModelOpt2();
    let modelCra = this.matSelGuidelinesComponentsService.getCurrentModelCra();

    return (
      (fgOpt1?.valid && modelOpt1) ||
      ((fgOpt2?.valid || fgPhOpt2?.valid) && modelOpt2) ||
      (fgCra?.valid && modelCra)
    );
  }

  ngOnDestroy(): void {
    this.subActionService.unsubscribe();
    this.subDataService.unsubscribe();
    this.preSimulationService.resetValues();
  }
}
