<style>
  label {
    color: rgb(97, 97, 97);
    font-weight: bold;
    font-family: FrugiterBold, sans-serif;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  @media screen and (max-width: 1420px) {
    label {
      min-height: 37px;
    }
  }

  .thickness-0 {
    padding: 0;
    margin: 0;
  }

  ::ng-deep
    .ng-select.unit-select.ng-select-single
    .ng-select-container
    .ng-value-container {
    justify-content: center;
  }

  ::ng-deep .ng-select.unit-select .ng-select-container {
    min-height: 100% !important;
  }

  ::ng-deep
    .ng-select.unit-select.ng-select-single
    .ng-select-container
    .ng-value-container {
    justify-content: center;
    height: 100%;
  }

  ::ng-deep
    .ng-select.unit-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ::ng-deep .ng-select.unit-select.ng-select-single .ng-select-container {
    height: 22px !important;
    border: none !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    color: #777 !important;
    background-color: #eaeaea !important;
  }

  ::ng-deep
    .ng-select.unit-select.ng-select-focused:not(.ng-select-opened)
    > .ng-select-container {
    border: none !important;
    box-shadow: none !important;
  }

  ::ng-deep
    .ng-select.unit-select
    .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected {
    color: #777 !important;
  }

  ::ng-deep
    .ng-select.unit-select.cr.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value,
  ::ng-deep .ng-select.unit-select.cr .ng-dropdown-panel .scroll-host {
    font-size: 10px;
  }

  ::ng-deep .ng-select.unit-select.ng-select-disabled .ng-arrow-wrapper,
  ::ng-deep
    .ng-select.unit-select.ng-select-disabled.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    cursor: not-allowed;
  }

  .container-references {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 0 0;
  }

  .container-references span {
    width: 40%;
  }

  #crReferences{
    width: 30vw;
  }

  #rangePopUp {
    text-decoration: underline;
    cursor: pointer;
  }

  .range-cr-box {
      width: 305px; 
      height: auto;
      padding: 10px;
      box-sizing: border-box;
      border: solid 1px #ccc;
      color: rgba(0, 0, 0, 0.87);
      text-align: left;
      background: #fff;
      border-radius: 4px;
      position: relative;
      z-index: 1;
      transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .range-cr-box.free-dragging {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .range-container {
      position: fixed;
      z-index: 9999;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 0;
      width: 0;
    }

    #rangesModalTitle {
    text-align: center; 
    padding: 0; 
    margin: 0; 
    color: #666666; 
    font-size: 17px; 
    font-family: FrugiterBold, sans-serif;
  }

  #rangeTitleContainer{
    display: flex; 
    align-items: baseline; 
  }

  #alertIcon{
    display: flex; 
    margin: 1em; 
    width: 2em;
    height: 2em; 
    margin-right: 115px;
  }

    #rangeVal > p{
    margin: 0 0 5px;
    font-size: 13px;
  }

    @media screen and (min-width:610px) and (max-width:1280px) {
      #rangeCR {
        width: 305px;
      }
    }

    @media screen and (min-width:1280px) and (max-width:1920px) {
      #rangeCR {
        width: 305px;
      }
    }

</style>

<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">13 Chromium Corrosion Rates</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.CORROSION_RATE.name }}</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div [ngStyle]="{'border': !isPreSimulationFormValid ? 'none' : ''}" class="panel-heading">
            <div style="font-size: 16px">
              <div *ngIf="simulationName == ''; else elseBlock">Simulation</div>
              <ng-template #elseBlock>
                <div>
                  <strong> Simulation: {{ simulationName }} </strong>
                </div></ng-template
              >
            </div>
            <app-pre-simulation #preSimulation></app-pre-simulation>
          </div>
          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" style="font-size: 14px; margin-left: 20px; margin-top: 5px">
            Complete the following fields: Model Validity 
            <span id="rangePopUp" (click)="rangePopUp()" ><b>Ranges</b></span>
          </div>
          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="panel-body">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-5">
                  <div class="panel-default row">
                    <div
                      class="widget-head"
                      style="
                        background-color: #e4e2e2;
                        color: #666666;
                        font-size: 12px;
                        padding: 1px 20px 1px;
                        border: none;
                      "
                    >
                      <h3
                        style="
                          font-weight: bold;
                          font-family: FrugiterBold, sans-serif;
                          font-size: 20px;
                          margin-bottom: 11px;
                          margin-top: 11px;
                          text-align: center;
                        "
                      >
                        Conditions
                      </h3>
                    </div>
                  </div>
                  <div class="text-center" style="padding: 15px 0 20px">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label style="overflow: visible">Temperature</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="temperatureUnitControl"
                            convertMultipleUnits
                            [unitFractionDigits]="2"
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Temperature
                            "
                            [convertedControls]="temperatureControls"
                            [defaultControls]="temperatureDefaultControls"
                            [emitEventDefaultControl]="false"
                            [items]="TEMPERATURE_UNITS_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Pressure CO<sub>2</sub></label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="pressureCO2UnitControl"
                            convertMultipleUnits
                            [unitFractionDigits]="2"
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Pressure
                            "
                            [convertedControls]="pressureCO2Controls"
                            [defaultControls]="pressureCO2DefaultControls"
                            [emitEventDefaultControl]="false"
                            [items]="PRESSURE_UNITS_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Chlorides (Cl<sup>-</sup>)</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="sodiumChlorideUnitControl"
                            convertMultipleUnits
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Concentration
                            "
                            [convertedControls]="sodiumChlorideControls"
                            [defaultControls]="sodiumChlorideDefaultControls"
                            [emitEventDefaultControl]="false"
                            [object]="{
                              waterDensity: waterDensityDefaultControls,
                              defaultUnit:
                                unitMeasureService.concentrationUnits.MilligramsLiter
                            }"
                            [items]="CONCENTRATION_UNITS_CL_13CR_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>NaCl</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div [ngClass]="{'col-sm-4': !isModified13CrVisible, 'col-sm-5': isModified13CrVisible}">
                  <div class="panel-default">
                    <div
                      class="widget-head"
                      style="
                        background-color: #006400;
                        color: #f5f5f5;
                        font-size: 12px;
                        text-align: center;
                        padding: 1px 20px 1px;
                        border: none;
                      "
                    >
                      <h3
                        style="
                          font-weight: bold;
                          font-size: 20px;
                          font-family: FrugiterBold, sans-serif;
                          margin-bottom: 11px;
                          margin-top: 11px;
                        "
                      >
                        Corrosion Rates <sup><b>[1]</b></sup>
                      </h3>
                    </div>
                  </div>
                  <div class="text-center" style="padding: 15px 0 20px">
                    <div [ngClass]="{'col-sm-6': !isModified13CrVisible, 'col-sm-4': isModified13CrVisible}">
                      <div class="form-group">
                        <label>Conventional 13Cr</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="conventional13CrUnitControl"
                            convertMultipleUnits
                            [unitFractionDigits]="2"
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Velocity
                            "
                            [convertedControls]="conventional13CrControls"
                            [defaultControls]="conventional13CrDefaultControls"
                            [emitEventDefaultControl]="false"
                            [items]="VELOCITY_UNITS_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                    <div [ngClass]="{'col-sm-6': !isModified13CrVisible, 'col-sm-4': isModified13CrVisible}">
                      <div class="form-group">
                        <label>Super 13Cr</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="super13CrUnitControl"
                            convertMultipleUnits
                            [unitFractionDigits]="2"
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Velocity
                            "
                            [convertedControls]="super13CrControls"
                            [defaultControls]="super13CrDefaultControls"
                            [emitEventDefaultControl]="false"
                            [items]="VELOCITY_UNITS_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="isModified13CrVisible" class="col-sm-4">
                      <div class="form-group">
                        <label>Modified 13Cr</label>
                        <span
                          class="input-group-addon input-xs label-dts thickness-0"
                        >
                          <ng-select
                            class="unit-select cr"
                            [formControl]="modified13CrUnitControl"
                            convertMultipleUnits
                            [unitFractionDigits]="2"
                            [unitMeasure]="
                              unitMeasureService.unitMeasures.Velocity
                            "
                            [convertedControls]="modified13CrControls"
                            [defaultControls]="modified13CrDefaultControls"
                            [emitEventDefaultControl]="false"
                            [items]="VELOCITY_UNITS_ARRAY"
                            bindLabel="symbol"
                            bindValue="enum"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-filters-cr
                [fgFiltersCR]="fgFiltersCR"
                [filtersCR]="filtersCR"
                [deletedSimulations]="deletedSimulations"
                [unitMeasureService]="unitMeasureService"
                [temperatureUnitControl]="temperatureUnitControl"
                [pressureCO2UnitControl]="pressureCO2UnitControl"
                [sodiumChlorideUnitControl]="sodiumChlorideUnitControl"
                [conventional13CrUnitControl]="conventional13CrUnitControl"
                [super13CrUnitControl]="super13CrUnitControl"
                [modified13CrUnitControl]="modified13CrUnitControl"
                [TEMPERATURE_LIMIT_13CR]="TEMPERATURE_LIMIT_13CR"
                [TEMPERATURE_LIMIT_13CRS]="TEMPERATURE_LIMIT_13CRS"
                [TEMPERATURE_LIMIT_13CRM]="TEMPERATURE_LIMIT_13CRM"
                [TEMPERATURE_LIMIT_WARNING]="TEMPERATURE_LIMIT_WARNING"
                [MAX_TEMPERATURE_LIMIT]="maxTemperatureLimit"
                [NACL_LIMIT_13CR]="NACL_LIMIT_13CR"
                [NACL_LIMIT_13CRS]="NACL_LIMIT_13CRS"
                [NACL_LIMIT_13CRM]="NACL_LIMIT_13CRM"
                [NACL_LIMIT_WARNING]="NACL_LIMIT_WARNING"
                (setControls)="setControls($event)"
              ></app-filters-cr>
              <div
                *ngIf="fgFiltersCR.invalid"
                class="col-sm-5 text-danger text-center"
              >
                Check for errors or duplicated entries.
              </div>
            </div>
            <div class="col-sm-10">
              <div class="container-references">
                <span>
                  <sup><b>[1]</b></sup> This model considers only uniform corrosion, please
                  consider the service window for localized corrosion.
                </span>
                <img src="assets/img/cr_references.png" id="crReferences"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="rangeCR" class="boundary range-container">
      <div id="rangeCR" class="range-cr-box" appFreeDragging appFreeDraggingHandle>
          <button type="button" class="close" (click)="closeModal()">&times;</button>
          <div id="rangeTitleContainer">
              <p id="rangesModalTitle"><b> Valid Ranges </b></p>
              <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
                <div class="swal2-icon-content" style="font-size: 1.75em;">!</div>
              </div>
          </div>
          <div id="rangeVal">
            <p> Temperature 0 - 180 °C </p>
            <p> Temperature 273.15 - 453.15 °K</p>
            <p> Temperature 32 - 356 °F</p>
            <p> Pressure CO<sub>2</sub> 0 - 50 bar</p>
            <p> Pressure CO<sub>2</sub> 0 - 725.19 psi</p>
            <p> Pressure CO<sub>2</sub> 0 - 49.35 atm</p>
            <p> Chlorides (Cl<sup>-</sup>) => 0 mg/l</p>
            <p> Chlorides (Cl<sup>-</sup>) => 0 ppm</p>
          </div>
      </div>
    </div>
  </div>
</div>



<ngx-spinner
bdColor="rgba(0, 0, 0, 0.8)"
size="medium"
color="#fff"
type="ball-clip-rotate"
[fullScreen]="true"><p style="color: white">Loading...</p></ngx-spinner>