import { Component, Input, OnInit  } from '@angular/core';
import { HomeComponent } from 'src/app/pages/home/Home.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoginService } from './pages/auth/login/Login.service';
import { DataTablesModule } from "angular-datatables";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})

export class AppComponent implements OnInit{
  title = 'Tenaris';
  @Input()
  isAdmin: boolean = false;
  lisAuthenticated:boolean;




  constructor(
    public oidcSecurityService: OidcSecurityService
    // ,public loginService: LoginService
    ) {


    // service.getLogin().subscribe((e: boolean) => this.isAdmin = e);
    

  }




  ngOnInit() {
    localStorage.setItem('sidebarAction', 'default');


  }

  



  }
