<section *ngIf="showPerforatedResults && perforatedResults">
  <div class="row row-styles">
    <div class="col-md-12">
      <div id="outputTablePanel" class="widget-head">
        <h3>Simulation Output</h3>
      </div>
    </div>
    <div class="col-md-12">
      <table #table class="table table-perforated">
        <thead>
          <tr>
            <th></th>
            <th class="tableTitle text-center">Pipe Body Ratings</th>
            <th class="tableTitle text-center">Pre-Perforated Pipe Ratings</th>
            <th class="tableTitle text-center">Remaining Factor [%]</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Axial [lbs]</td>
            <td class="text-center">
              {{
                perforatedResults?.results.Axial.pipeBodyRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Axial.slottedPipeRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Axial.reductionFactor
                  | transformToPercent
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
          </tr>
          <tr>
            <td>Torsion [ft-lbs]</td>
            <td class="text-center">
              {{
                perforatedResults?.results.Torsion.pipeBodyRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Torsion.slottedPipeRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Torsion.reductionFactor
                  | transformToPercent
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
          </tr>
          <tr>
            <td>Collapse [psi]</td>
            <td class="text-center">
              {{
                perforatedResults?.results.Collapse.pipeBodyRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Collapse.slottedPipeRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Collapse.reductionFactor
                  | transformToPercent
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
          </tr>
          <tr>
            <td>Bending [º/100ft]</td>
            <td class="text-center">
              {{
                perforatedResults?.results.Bending.pipeBodyRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Bending.slottedPipeRating
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.results.Bending.reductionFactor
                  | transformToPercent
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div id="outputTablePanel" class="widget-head">
        <h3>Open Area</h3>
      </div>
      <table #table class="table table-perforated">
        <thead>
          <tr>
            <th></th>
            <th class="tableTitle text-center">At Outer Diameter</th>
            <th class="tableTitle text-center">At Inner Diameter</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Percentage [%]<sup> [1]</sup></td>
            <td class="text-center">
              {{
                perforatedResults?.openAreaPercentAtOD
                  | roundDecimals : 2
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.openAreaPercentAtID
                  | roundDecimals : 2
                  | emptyDataTable
              }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Total Area [in<sup>2</sup>]</td>
            <td class="text-center">
              {{
                perforatedResults?.openAreaInchAtOD
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td class="text-center">
              {{
                perforatedResults?.openAreaInchAtID
                  | roundDecimals : 0
                  | emptyDataTable
              }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <span class="table-ref"
        ><sup>[1]</sup> Always referenced to pipe external surface</span
      >
    </div>
  </div>
</section>
