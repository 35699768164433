import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreSimulationService } from "./service/pre-simulation.service";
import { CustomerService } from "src/app/services/customer/customer.service";
import { CountryService } from "src/app/services/country/country.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-pre-simulation",
  templateUrl: "./pre-simulation.component.html",
  styleUrls: ["./pre-simulation.component.scss"],
})
export class PreSimulationComponent implements OnInit, OnDestroy {
  preSimulationForm: FormGroup;
  customers: { id: number; description: string }[] = [];
  countries: { id: number; description: string; code: string }[] = [];

  constructor(
    private preSimulationService: PreSimulationService,
    private customerService: CustomerService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.preSimulationForm = new FormGroup({
      customer: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
    });

    this.preSimulationForm.valueChanges.subscribe(() => {
      this.preSimulationService.updateFormValidity(
        this.preSimulationForm.valid
      );

      this.preSimulationService.updateForm(this.preSimulationForm);
    });

    this.preSimulationService.customerCreated$.subscribe((customer) => {
      if (customer) {
        this.customerService.getPreSimulationActiveCustomers().subscribe((customers) => {
          this.customers = customers;
          this.preSimulationForm.get("customer").setValue(customer);
        });
      }
    });

    this.preSimulationService.customerIdChanges$.subscribe((id) => {
      this.preSimulationForm.get("customer").setValue(this.customers.find(customer => customer.id === id));
    });

    this.preSimulationService.countryIdChanges$.subscribe((id) => {
      this.preSimulationForm.get("country").setValue(this.countries.find(country => country.id === id));
    });

    this.getPreSimulationData();
  }

  countrySearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.description.toLowerCase().indexOf(term) > -1 ||
      item.code.toLowerCase().indexOf(term) > -1
    );
  }

  ngOnDestroy(): void {}

  addCustomer() {
    this.preSimulationService.setCustomers(this.customers);
  }

  private getPreSimulationData() {
    forkJoin({
      customers: this.customerService.getPreSimulationActiveCustomers(),
      countries: this.countryService.getActiveCountries(),
    }).subscribe(({ customers, countries }) => {
      this.customers = customers;
      this.countries = countries;
    });
  }
}
