import { Component, OnInit } from "@angular/core";
import { SlottedService } from "../../services/slotted.service";

@Component({
  selector: "app-slotted-ranges-modal",
  templateUrl: "./slotted-ranges-modal.component.html",
  styleUrls: ["./slotted-ranges-modal.component.css"],
})

export class SlottedRangesModalComponent implements OnInit{
  showModal: boolean = false;

  constructor(private slottedService: SlottedService) {}

  ngOnInit(): void {
    this.slottedService.showRangeModal$.subscribe((show: boolean) => {
      this.showModal = show;
    })
  }

  closeModal() {
    this.showModal = false;
  }
}
