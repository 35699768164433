import { Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {
  @Input() searchedWord: string;
  @Input() content: string;
  
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.content) {
      return;
    }
    
    if (!this.searchedWord) {
      this.renderer.removeClass(this.el.nativeElement,"myFilter");  
      return;
    }
    
    this.renderer.addClass(this.el.nativeElement,"myFilter");
  }
}
