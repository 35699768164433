import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Customer } from '../models/pre-simulation';
import { CustomerMgr } from 'src/app/modules/customermgr/models/customermgr';

@Injectable({
  providedIn: 'root'
})
export class PreSimulationService {
  private formValidity = new BehaviorSubject<boolean>(false);
  private customers = new BehaviorSubject<Customer[] | CustomerMgr[] | null>(null);
  private customerCreated = new BehaviorSubject<Customer | null>(null);
  private countryDescription = new BehaviorSubject<string | null>(null);
  private customerDescription = new BehaviorSubject<string | null>(null);
  private currentCustomerId = new BehaviorSubject<number | null>(null);
  private currentCountryId = new BehaviorSubject<number | null>(null);
  private loadedCustomerId = new BehaviorSubject<number | null>(null);
  private loadedCountryId = new BehaviorSubject<number | null>(null);
  private savedCustomerId = new BehaviorSubject<number | null>(null);
  private savedCountryId = new BehaviorSubject<number | null>(null);
  private loadedFormValidity = new BehaviorSubject<boolean | null>(null);

  formValidity$ = this.formValidity.asObservable();
  customers$ = this.customers.asObservable();
  customerCreated$ = this.customerCreated.asObservable();
  countryDescriptionChanges$ = this.countryDescription.asObservable();
  customerDescriptionChanges$ = this.customerDescription.asObservable();
  customerIdChanges$ = this.loadedCustomerId.asObservable();
  countryIdChanges$ = this.loadedCountryId.asObservable();  

  updateFormValidity(isValid: boolean) {
    this.formValidity.next(isValid);
    this.loadedFormValidity.next(isValid);
  }

  updateForm(form: FormGroup) {
    this.currentCountryId.next(form.get('country').value?.id || null);
    this.currentCustomerId.next(form.get('customer').value?.id || null);
    this.countryDescription.next(form.get('country').value?.description || null);
    this.customerDescription.next(form.get('customer').value?.description || null);
    this.loadedFormValidity.next(form.valid);
  }

  createCustomer(customer: Customer) {
    this.customerCreated.next(customer);
  }

  resetValues() {
    this.formValidity.next(false);
    this.customerCreated.next(null);
    this.customers.next(null);
    this.countryDescription.next(null);
    this.customerDescription.next(null);
    this.currentCustomerId.next(null);
    this.currentCountryId.next(null);
    this.loadedCustomerId.next(null);
    this.loadedCountryId.next(null);
    this.savedCustomerId.next(null);
    this.savedCountryId.next(null);
    this.loadedFormValidity.next(null);
  }

  getCurrentCustomerId(): number | null {
    return this.currentCustomerId.getValue();
  }

  getCurrentCountryId(): number | null {
    return this.currentCountryId.getValue();
  }

  getFormLoadedValidity(): boolean | null {
    return this.loadedFormValidity.getValue();
  }

  setLoadedCustomerId(id: number) {
    this.loadedCustomerId.next(id);
  }

  setLoadedCountryId(id: number) {
    this.loadedCountryId.next(id);
  }
  
  setCustomers(customers: Customer[] | CustomerMgr[]) {
    this.customers.next([...customers]);
  }

  getSavedCustomerId(): number | null {
    return this.savedCustomerId.getValue();
  }

  getSavedCountryId(): number | null {
    return this.savedCountryId.getValue();
  }

  setSavedCustomerId(id: number) {
    this.savedCustomerId.next(id);
  }

  setSavedCountryId(id: number) {
    this.savedCountryId.next(id);
  }
}
