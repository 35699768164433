<div class="am-content">
<div class="main-content">
  <div class="page-head">
     <h2 class="title">Well Design</h2>
     <ol class="breadcrumb">
      <li><a [routerLink]="NAVIGATIONS.HOME.route">{{NAVIGATIONS.HOME.name}}</a></li>
     </ol>
  </div>
  <div class="row">
     <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3>Welcome..</h3>
          </div>
          <div class="panel-body">
            <p> Please select a simulation model from the sidebar to continue </p> 
          </div>
        </div>
     </div>
  </div>
</div>
</div>