<div class="row pre-sim-container">
  <form [formGroup]="preSimulationForm">
    <div class="col-4 col-sm-4">
      <label class="required"><span>*</span>Customer</label>
      <div class="customer-container">
        <ng-select
          formControlName="customer"
          class="pre-sim-ng-select"
          placeholder="Select"
          [items]="customers"
          bindLabel="description"
          clearAllText="Clear"
          [searchable]="true"
        >
        </ng-select>
        <div
          class="icon"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Add new customer"
          (click)="addCustomer()"
        >
          <span class="s7-plus"  
          data-toggle="modal"
          data-target="#create-customer-modal"
          data-placement="right"
          data-tooltip>
        </span>
        </div>
      </div>
    </div>
    <div class="col-4 col-sm-4">
      <label class="required"><span>*</span>Country</label>
      <ng-select
        formControlName="country"
        class="pre-sim-ng-select"
        placeholder="Select"
        [items]="countries"
        bindLabel="description"
        clearAllText="Clear"
        [searchable]="true"
        [searchFn]="countrySearchFn"
      >
      </ng-select>
    </div>
  </form>
</div>
<app-create-customer-modal></app-create-customer-modal>