<style>
  @media only screen and (min-width: 800px) and (max-width: 1030px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 66vh;
      overflow-y: scroll;
    }
  }

  @media only screen and (min-width: 1030px) and (max-width: 1366px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 66vh;
      overflow-y: scroll;
    }
  }

  @media only screen and (min-width: 1370px) and (max-width: 1920px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 75vh;
      overflow-y: scroll;
    }
  }
</style>

<div class="treecontainer">
  <app-tree [tree]="tree" [onlyUserSimulationsOption]="true" (canActivateActions)="canActivateActions($event)"></app-tree>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>