import { VelocityUnits } from "../enums/velocity-units.enum";

export const VELOCITY_UNITS = {
  MILLIMITERS_YEAR: {
    name: "Millimeters per year",
    symbol: "mm/year",
    enum: VelocityUnits.MillimetersYear,
    fractionDigits: 4,
  },
  INCHES_YEAR: {
    name: "Inches per year",
    symbol: "in/year",
    enum: VelocityUnits.InchesYear,
    fractionDigits: 4,
  },
};

export const VELOCITY_UNITS_ARRAY: VelocityUnit[] = [
  VELOCITY_UNITS.MILLIMITERS_YEAR,
  VELOCITY_UNITS.INCHES_YEAR,
];

class VelocityUnit {
  name: string;
  symbol: string;
  enum: VelocityUnits;
  fractionDigits: number;
}
