<style>
@media only screen and (min-width: 800px) and (max-width: 1030px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 62vh;
      overflow-y: scroll;
    }
  }

 @media only screen and (min-width: 1030px) and (max-width: 1366px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 55vh;
      overflow-y: scroll;
    }
  }

  @media only screen and (min-width: 1370px) and (max-width: 1920px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      height: 68vh;
      overflow-y: scroll;
    }
  }

  .textarea {
    height: 80px;
    margin-bottom: 35px;
  }
</style>

<div>
  <div class="treecontainer">
    <app-tree [tree]="tree" (canActivateActions)="canActivateActions($event)"></app-tree>
  </div>

  <label for="noteTextarea">Notes</label>
  <textarea class="form-control textarea" id="noteTextarea" [value]="valueNote" rows="4" readonly></textarea>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"><p style="color: white" > Deleting... </p></ngx-spinner>