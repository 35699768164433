<div style="font-size: 14px; margin-bottom: 10px; margin-left: -8px">
  Complete the following fields: Model Validity
  <span id="rangePopUp" (click)="rangePopUp()"><b>Ranges</b></span>
</div>
<div class="row">
  <div class="col-sm-4">
    <form [formGroup]="fg" (ngSubmit)="runModel()">
      <div class="row" style="min-height: 80px">
        <div class="col-sm-4 control-label input-m">
          <span><b>INPUT</b></span>
        </div>
        <div
          class="col-sm-8"
          style="text-align: right; height: 25px; font-size: 18px"
        >
          <button
            type="button"
            class="btn-no-styles"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Reset"
            (click)="onNew()"
          >
            <b>Reset</b>
            <span class="icon s7-refresh"></span>
          </button>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">Temperature</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="temperature"
              [ngClass]="{
                'input-error':
                  (form.temperature.touched || form.dirty) &&
                  form.temperature.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="temperature"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'temperatureCraError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
              [selectedUnit]="temperatureUnit.value"
              [defaultControl]="form.temperatureDefault"
              [unitControl]="temperatureUnit"
              warningMessage
              [warningContainerId]="'temperatureCraWarning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="temperatureUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
                [convertedControl]="form.temperature"
                [defaultControl]="form.temperatureDefault"
                [items]="TEMPERATURE_UNITS_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="temperatureCraError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'temperatureCraWarning'"
            [ngClass]="{ 'd-none': form.temperature.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Total Pressure</label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="totalPressure"
              [ngClass]="{
                'input-error':
                  (form.totalPressure.touched || form.dirty) &&
                  form.totalPressure.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="totalPressure"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'totalPressureCraError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="totalPressureUnit.value"
              [defaultControl]="form.totalPressureDefault"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
              [unitControl]="totalPressureUnit"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="totalPressureUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="form.totalPressure"
                [defaultControl]="form.totalPressureDefault"
                [object]="{
                  defaultUnit: unitMeasureService.pressureUnits.Kpa
                }"
                [items]="PRESSURE_UNITS_KPA_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="totalPressureCraError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >H<sub>2</sub>S</label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="h2s"
              [ngClass]="{
                'input-error':
                  (form.h2s.touched || form.dirty) && form.h2s.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="h2s"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'h2sCraError'"
            />
            <span
              class="unit input-group-addon input-xs label-dts thickness-0 not-allowed"
              >%</span
            >
          </div>
          <error-message
            id="h2sCraError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Min YS required</label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="minYSRequired"
              [ngClass]="{
                'input-error':
                  (form.minYSRequired.touched || form.dirty) &&
                  form.minYSRequired.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="minYSRequired"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'minYSRequiredCraError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="minYSRequiredUnit.value"
              [defaultControl]="form.minYSRequiredDefault"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Ksi }"
              [unitControl]="minYSRequiredUnit"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="minYSRequiredUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="form.minYSRequired"
                [defaultControl]="form.minYSRequiredDefault"
                [object]="{
                  defaultUnit: unitMeasureService.pressureUnits.Ksi
                }"
                [items]="PRESSURE_UNITS_KSI_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="minYSRequiredCraError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 control-label input-xs">pH</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="ph"
              [ngClass]="{
                'input-error':
                  (form.ph.touched || form.dirty) && form.ph.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="ph"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'phError'"
              warningMessage
              [warningContainerId]="'phWarning'"
            />
          </div>
          <error-message
            id="phError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'phWarning'"
            [ngClass]="{ 'd-none': form.ph.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Total Cl<sup>-</sup></label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="totalCl"
              [ngClass]="{
                'input-error':
                  (form.totalCl.touched || form.dirty) && form.totalCl.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="totalCl"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'totalClCraError'"
            />
            <span
              class="unit input-group-addon input-xs label-dts thickness-0 not-allowed"
              >mg/l</span
            >
          </div>
          <error-message
            id="totalClCraError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Grade</label
        >
        <div class="col-sm-8">
          <ng-select
            id="grade"
            formControlName="grade"
            [ngClass]="{
              'grade-acceptable':
                model && !model.criteria15156cracking?.toLowerCase().includes('not acceptable'),
              'grade-not-acceptable':
                model && model.criteria15156cracking?.toLowerCase().includes('not acceptable')
            }"
            class="grades-ng-select"
            placeholder="Select"
            [items]="gradesFiltered"
            clearAllText="Clear"
            [searchable]="true"
          >
          </ng-select>
        </div>
      </div>

      <div class="row" style="margin-top: 15px">
        <p style="padding: 1px 7px">
          ** TN 95Cr13S not included in ISO 15156-3
        </p>
      </div>

      <div class="row" style="margin-top: 15px">
        <div class="col-sm-4 control-label input-xs">
          <button type="submit" class="btn btn-primary" [disabled]="fg.invalid">
            Run Model
          </button>
        </div>
        <div class="col-sm-8"></div>
      </div>
    </form>
  </div>
  <div *ngIf="model" class="col-sm-8">
    <div class="row" style="min-height: 80px">
      <div class="col-sm-12 control-label input-m not-p">
        <span><b>OUTPUT</b></span>
      </div>
    </div>

    <div class="row" *ngIf="oPph2s || oPph2s === 0">
      <div class="form-group">
        <label class="col-sm-1 control-label not-p" style="height: 100%"
          >ppH<sub>2</sub>S</label
        >
        <div class="col-sm-4 not-p">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="oPph2s"
              class="form-control input-xs no-spin"
              dlNumericInput
              tabindex="-1"
              readonly
              [value]="oPph2s"
            />
            <span
              class="unit input-group-addon input-xs label-dts thickness-0 not-allowed"
              >{{unitPPH2S}}</span
            >
          </div>
        </div>
        <div class="col-sm-7"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 control-label input-m not-p">
        <span><b>ISO 15156-3 Criteria for cracking</b></span>
      </div>
    </div>
    <div class="row">
      <div
        style="height: auto; padding: 10px; color: #fff"
        [ngStyle]="{ 'background-color': model.backgroundColor }"
        class="col-sm-12 control-label text-m results-opt1"
      >
        <span
          ><b>{{ model.criteria15156cracking }}</b></span
        >
      </div>
    </div>
    <div class="row">
      <div class="grades-table">
        <div class="considerations" style="border-bottom: 6px solid #fff">
          {{ model?.sourConsiderations }}
        </div>
        <div class="row not-m" style="display: flex">
          <div
            class="grades col-sm-6"
            style="border: solid #fff; border-width: 3px 3px 0 0"
          >
            <span style="width: 100%; padding: 10px 0"
              ><b>Environmental</b></span
            >
            <div
              [ngStyle]="{ 'background-color': gradeEnvi[1] }"
              *ngFor="let gradeEnvi of model?.recommendedGradesEnvi"
            >
              {{ gradeEnvi[0] }}
            </div>
          </div>
          <div
            class="grades col-sm-6"
            style="border: solid #fff; border-width: 3px 0 0 3px"
          >
            <span style="width: 100%; padding: 10px 0"
              ><b>Environmental + Mechanical</b></span
            >
            <div
              [ngStyle]="{ 'background-color': gradeEnviMech[1] }"
              *ngFor="let gradeEnviMech of model?.recommendedGradesEnviMech"
            >
              {{ gradeEnviMech[0] }}
            </div>
          </div>
        </div>
        <div
          class="row"
          style="
            margin: 0;
            border-top: 6px solid #fff;
            padding: 10px;
            color: #666;
            background-color: rgb(237, 237, 237);
          "
        >
          <div
            class="col-sm-12 attr-line"
            style="
              border-left: 11px solid #a1d487;
              border-bottom: 1px solid #a1d487;
              margin-bottom: 2px;
            "
          >
            <p>Proper materials for cracking according ISO 15156-3</p>
          </div>
          <div
            class="col-sm-12 attr-line"
            style="
              border-left: 11px solid #fbec5d;
              border-bottom: 1px solid #fbec5d;
              margin-bottom: 2px;
            "
          >
            <p>
              Proper material for cracking, but with a lower hardness than
              suggested by ISO
            </p>
          </div>
        </div>
        <div class="comments" style="border-top: 6px solid #fff">
          {{ model?.recommendedGradesComment }}
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="rangeCra" class="boundary range-container">
  <div
    id="rangeOpt2"
    class="range-cr-box"
    appFreeDragging
    appFreeDraggingHandle
  >
    <button type="button" class="close" (click)="closeModal()">&times;</button>
    <div id="rangeTitleContainer">
      <p id="rangesModalTitle"><b> Valid Ranges </b></p>
      <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
        <div class="swal2-icon-content" style="font-size: 1.75em">!</div>
      </div>
    </div>

    <div id="rangeVal">
      <p>Temperature 20 - 150 °C</p>
      <p>Total Pressure 0 - 20000 kPa</p>
      <p>% H<sub>2</sub>S >= 0</p>
      <p>Min YS required 35 - 180 ksi</p>
      <p>pH >= 0</p>
      <p>Cl<sup>-</sup> >= 0 mg/l</p>
    </div>
  </div>
</div>