import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { PerforatedService } from "src/app/modules/slotted-perforated-pipe/perforated-pipe/services/perforated.service";
import { Datasheet } from "src/app/modules/slotted-perforated-pipe/shared/slotted-modal/models/dcp-modal";
import { SlottedService } from "src/app/modules/slotted-perforated-pipe/slotted-pipe/services/slotted.service";
import { SlottedModalService } from "../../services/slotted-modal.service";

@Component({
  selector: "app-dcp-table",
  templateUrl: "./dcp-table.component.html",
  styleUrls: ["./dcp-table.component.css"],
})
export class DcpTableComponent implements OnInit {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDtInitialized: boolean = false;
  clickCount = 0;

  dcpProducts: Datasheet[];

  constructor(
    private slottedModalService: SlottedModalService,
    private slottedService: SlottedService,
    private perforatedService: PerforatedService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      language: {
        emptyTable:
          "Please select outside diameter and wall thickness to view products",
      },
      pagingType: "simple_numbers",
      pageLength: 10,
      order: [[0, "asc"]],
      autoWidth: true,
    };

    this.slottedModalService.dcpProducts$.subscribe((dcpProducs) => {
      this.dcpProducts = dcpProducs;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  loadDataInForm(dcpProduct: any) {
    this.clickCount++;
    setTimeout(() => {
      if (this.clickCount >= 2) {
        this.slottedService.setDcpProduct(dcpProduct);
        this.perforatedService.setDcpProduct(dcpProduct);
      }

      this.clickCount = 0;
    }, 250);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
