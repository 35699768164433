import { ValidationErrors } from "@angular/forms";
import { WarningEnum } from "../enums/warnings.enum";
import {
  AbstractControlWarning,
  Warning,
} from "../helpers/AbstractControlWarning";

export class WarningValidator {
  static requiredBooleanWarning(
    requiredBoolean: boolean,
    origin?: string,
    customWarningMessage?: string
  ) {
    return (control: AbstractControlWarning): ValidationErrors | null => {
      if (!control.warnings) control.warnings = [];

      if (requiredBoolean === control.value) {
        if (
          !control.warnings.some(
            (warning: Warning) =>
              warning.type === WarningEnum.RequiredBoolean &&
              warning.origin === origin
          )
        ) {
          const warning: Warning = {
            type: WarningEnum.RequiredBoolean,
            warningMessage: customWarningMessage ?? "Must be true",
            origin: origin,
          };
          control.warnings.push(warning);
        }
      } else {
        const warningIndex = control.warnings.findIndex(
          (warning: Warning) =>
            warning.type === WarningEnum.RequiredBoolean &&
            warning.origin === origin
        );

        if (warningIndex > -1) control.warnings.splice(warningIndex, 1);
      }

      return null;
    };
  }

  static maxValueLimitedByValueWarning(
    max: number,
    limiterValue?: number,
    origin?: string,
    customWarningMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControlWarning): ValidationErrors | null => {
      if (!control.warnings) control.warnings = [];
      const value = limiterValue
        ? limiterValue
        : Number(String(control.value).replace(",", "."));
      if (
        (value || value === 0) &&
        control.value != "" &&
        ((isEqualsIncluded && value > max) ||
          (!isEqualsIncluded && value >= max))
      ) {
        if (
          !control.warnings.some(
            (warning: Warning) =>
              warning.type === WarningEnum.MaxValueLimitedByValue &&
              warning.origin === origin
          )
        ) {
          const warning: Warning = {
            type: WarningEnum.MaxValueLimitedByValue,
            warningMessage: customWarningMessage ?? "Max value exceed",
            origin: origin,
          };
          control.warnings.push(warning);
        }
      } else {
        const warningIndex = control.warnings.findIndex(
          (warning: Warning) =>
            warning.type === WarningEnum.MaxValueLimitedByValue &&
            warning.origin === origin
        );

        if (warningIndex > -1) control.warnings.splice(warningIndex, 1);
      }

      return null;
    };
  }

  static minValueLimitedByValueWarning(
    min: number,
    limiterValue?: number,
    origin?: string,
    customWarningMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControlWarning): ValidationErrors | null => {
      if (!control.warnings) control.warnings = [];
      const value = limiterValue
        ? limiterValue
        : Number(String(control.value).replace(",", "."));

      if (
        (value || value === 0) &&
        control.value != "" &&
        ((isEqualsIncluded && value < min) ||
          (!isEqualsIncluded && value <= min))
      ) {
        if (
          !control.warnings.some(
            (warning: Warning) =>
              warning.type === WarningEnum.MinValueLimitedByValue &&
              warning.origin === origin
          )
        ) {
          const warning: Warning = {
            type: WarningEnum.MinValueLimitedByValue,
            warningMessage: customWarningMessage ?? "Min value exceed",
            origin: origin,
          };
          control.warnings.push(warning);
        }
      } else {
        const warningIndex = control.warnings.findIndex(
          (warning: Warning) =>
            warning.type === WarningEnum.MinValueLimitedByValue &&
            warning.origin === origin
        );

        if (warningIndex > -1) control.warnings.splice(warningIndex, 1);
      }

      return null;
    };
  }

  static maxValueLimitedByFunctionWarning(
    limiterFunction: (x: number) => number,
    max?: number,
    limiterValue?: number,
    limiterValueRelated?: number,
    origin?: string,
    customWarningMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControlWarning): ValidationErrors | null => {
      if (!control.warnings) control.warnings = [];
      const value = limiterValue
        ? limiterValue
        : Number(String(control.value).replace(",", "."));
      const valueRelated = limiterValueRelated
        ? limiterValueRelated
        : Number(String(control.value).replace(",", "."));
      if (
        (value || value === 0) &&
        control.value != "" &&
        (valueRelated || valueRelated === 0) &&
        ((isEqualsIncluded && value > limiterFunction(valueRelated)) ||
          (!isEqualsIncluded && value >= limiterFunction(valueRelated)) ||
          (isEqualsIncluded && value > max) ||
          (!isEqualsIncluded && value >= max))
      ) {
        if (
          !control.warnings.some(
            (warning: Warning) =>
              warning.type === WarningEnum.MaxValueLimitedByValue &&
              warning.origin === origin
          )
        ) {
          const warning: Warning = {
            type: WarningEnum.MaxValueLimitedByValue,
            warningMessage: customWarningMessage ?? "Max value exceed",
            origin: origin,
          };
          control.warnings.push(warning);
        }
      } else {
        const warningIndex = control.warnings.findIndex(
          (warning: Warning) =>
            warning.type === WarningEnum.MaxValueLimitedByValue &&
            warning.origin === origin
        );

        if (warningIndex > -1) control.warnings.splice(warningIndex, 1);
      }

      return null;
    };
  }

  static minValueLimitedByFunctionWarning(
    limiterFunction: (x: number) => number,
    min?: number,
    limiterValue?: number,
    limiterValueRelated?: number,
    origin?: string,
    customWarningMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControlWarning): ValidationErrors | null => {
      if (!control.warnings) control.warnings = [];
      const value = limiterValue
        ? limiterValue
        : Number(String(control.value).replace(",", "."));
      const valueRelated = limiterValueRelated
        ? limiterValueRelated
        : Number(String(control.value).replace(",", "."));
      if (
        (value || value === 0) &&
        control.value != "" &&
        ((isEqualsIncluded && value < limiterFunction(valueRelated)) ||
          (!isEqualsIncluded && value <= limiterFunction(valueRelated)) ||
          (isEqualsIncluded && value < min) ||
          (!isEqualsIncluded && value <= min))
      ) {
        if (
          !control.warnings.some(
            (warning: Warning) =>
              warning.type === WarningEnum.MinValueLimitedByValue &&
              warning.origin === origin
          )
        ) {
          const warning: Warning = {
            type: WarningEnum.MinValueLimitedByValue,
            warningMessage: customWarningMessage ?? "Min value exceed",
            origin: origin,
          };
          control.warnings.push(warning);
        }
      } else {
        const warningIndex = control.warnings.findIndex(
          (warning: Warning) =>
            warning.type === WarningEnum.MinValueLimitedByValue &&
            warning.origin === origin
        );

        if (warningIndex > -1) control.warnings.splice(warningIndex, 1);
      }

      return null;
    };
  }
}
