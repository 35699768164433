import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import { DataService } from "src/app/services/Data.service";
import { DcpService } from "src/app/services/dcp/dcp.service";
import { DcpShortNames } from "../../../enums/dcp-short-names.enum";
import { ComboData, SearchItemsRequest } from "../../models/dcp-modal";
import { SlottedModalService } from "../../services/slotted-modal.service";

@Component({
  selector: "app-dcp-filter",
  templateUrl: "./dcp-filter.component.html",
  styleUrls: ["./dcp-filter.component.css"],
})
export class DcpFilterComponent implements OnInit {
  @Input() dcpService: DcpService;

  subfiltersVisible: boolean = false;

  isInitialized: boolean = false;

  fgFilters: FormGroup = this.fb.group({
    simulateUserRole: [false],
    fgMainFilters: this.fb.group({
      outsideDiameter: [null, Validators.required],
      wallThickness: [null, Validators.required],
      grade: [null],
      connection: [null],
    }),
    fgSubFilters: this.fb.group({
      minWallThickness: [null],
      type: [null],
      pipeBodyDrift: [null],
      specialBevel: [null],
      connectionODOption: [null],
    }),
  });

  filters: { [key in DcpShortNames]: ComboData[] };

  canSimulateUserRole: boolean = false;

  constructor(
    private fb: FormBuilder,
    private slottedModalService: SlottedModalService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.currentRolSource.pipe(take(1)).subscribe((role) => {
      this.canSimulateUserRole = role == "ADMIN" || role == "SUPER_USER";
    });

    this.fgFilters.get("simulateUserRole").valueChanges.subscribe(() => {
      this.fgFilters.get("fgMainFilters").reset();
    });
  }

  changeMainFilters(mainFiltersValid: boolean) {
    this.subfiltersVisible = mainFiltersValid;

    if (!mainFiltersValid)
      this.fgFilters.get("fgSubFilters").reset({ emitEvent: false });

    this.dcpService
      .getProductsByFilter(this.getSearchItemsRequest())
      .subscribe((searchItemsResponse) => {
        this.filters = searchItemsResponse.filters;

        if (this.fgFilters.get("fgMainFilters").get("wallThickness").value) {
          this.fgFilters
            .get("fgMainFilters")
            .get("wallThickness")
            .setValue(
              searchItemsResponse.filters?.WT?.find(
                (x) =>
                  x.id.split("#")[0] ===
                  this.fgFilters
                    .get("fgMainFilters")
                    .get("wallThickness")
                    .value?.split("#")[0]
              ).id,
              { emitEvent: false }
            );
        }

        this.slottedModalService.setDcpProducts(searchItemsResponse.datasheets);
      });
  }

  changeSubFilters(mainFiltersValid: boolean) {
    this.subfiltersVisible = mainFiltersValid;

    this.dcpService
      .getProductsByFilter(this.getSearchItemsRequest())
      .subscribe((searchItemsResponse) => {
        this.filters = searchItemsResponse.filters;

        if (this.fgFilters.get("fgMainFilters").get("wallThickness").value) {
          this.fgFilters
            .get("fgMainFilters")
            .get("wallThickness")
            .setValue(
              searchItemsResponse.filters?.WT?.find(
                (x) =>
                  x.id.split("#")[0] ===
                  this.fgFilters
                    .get("fgMainFilters")
                    .get("wallThickness")
                    .value?.split("#")[0]
              ).id,
              { emitEvent: false }
            );
        }

        this.slottedModalService.setDcpProducts(searchItemsResponse.datasheets);
      });
  }

  private getSearchItemsRequest(): SearchItemsRequest {
    let filterNames: DcpShortNames[] = [];
    let page: number;
    let size: number;
    let simulateUserRole: boolean;

    if (this.fgFilters.get("fgMainFilters").valid) {
      page = 0;
      size = 1000;
      filterNames = [
        DcpShortNames.OutsideDiameter,
        DcpShortNames.WallThickness,
        DcpShortNames.Grade,
        DcpShortNames.Connection,
        DcpShortNames.MinWallThickness,
        DcpShortNames.Type,
        DcpShortNames.PipeBodyDrift,
        DcpShortNames.SpecialBevel,
        DcpShortNames.ConnectionOdOption,
      ];
    } else {
      page = 0;
      size = 0;
      filterNames = [
        DcpShortNames.OutsideDiameter,
        DcpShortNames.WallThickness,
      ];
    }

    simulateUserRole = this.fgFilters.get("simulateUserRole").value;

    let searchItemsRequest: SearchItemsRequest = {
      page: page,
      size: size,
      filter: {
        filtersNames: filterNames,
        unitChanged: false,
        unitSystem: "USC",
      },
      simulateUserRole: this.fgFilters.get("simulateUserRole").value,
    };

    if (this.fgFilters.get("fgMainFilters").get("outsideDiameter").value)
      searchItemsRequest.filter.outsideDiameter = this.fgFilters
        .get("fgMainFilters")
        .get("outsideDiameter").value;

    if (this.fgFilters.get("fgMainFilters").get("wallThickness").value)
      searchItemsRequest.filter.wallThickness = this.fgFilters
        .get("fgMainFilters")
        .get("wallThickness").value;

    if (this.fgFilters.get("fgMainFilters").get("grade").value)
      searchItemsRequest.filter.grade = this.fgFilters
        .get("fgMainFilters")
        .get("grade").value;

    if (this.fgFilters.get("fgMainFilters").get("connection").value)
      searchItemsRequest.filter.connection = this.fgFilters
        .get("fgMainFilters")
        .get("connection").value;

    if (this.fgFilters.get("fgSubFilters").get("minWallThickness").value)
      searchItemsRequest.filter.wtTolerance = this.fgFilters
        .get("fgSubFilters")
        .get("minWallThickness").value;

    if (this.fgFilters.get("fgSubFilters").get("pipeBodyDrift").value)
      searchItemsRequest.filter.pipeBodyDrift = this.fgFilters
        .get("fgSubFilters")
        .get("pipeBodyDrift").value;

    if (this.fgFilters.get("fgSubFilters").get("specialBevel").value)
      searchItemsRequest.filter.specialBevel = this.fgFilters
        .get("fgSubFilters")
        .get("specialBevel").value;

    if (this.fgFilters.get("fgSubFilters").get("connectionODOption").value)
      searchItemsRequest.filter.connectionOdOption = this.fgFilters
        .get("fgSubFilters")
        .get("connectionODOption").value;

    return searchItemsRequest;
  }

  private getPreloadSearchItemsRequest(filterValues: any): SearchItemsRequest {
    let filterNames: DcpShortNames[] = [];
    let page: number;
    let size: number;
    let simulateUserRole: boolean;

    page = 0;
    size = 1000;

    if (filterValues.outsideDiameter && filterValues.wallThickness) {
      page = 0;
      size = 1000;
      filterNames = [
        DcpShortNames.OutsideDiameter,
        DcpShortNames.WallThickness,
        DcpShortNames.Grade,
        DcpShortNames.Connection,
        DcpShortNames.MinWallThickness,
        DcpShortNames.Type,
        DcpShortNames.PipeBodyDrift,
        DcpShortNames.SpecialBevel,
        DcpShortNames.ConnectionOdOption,
      ];
    } else {
      page = 0;
      size = 0;
      filterNames = [
        DcpShortNames.OutsideDiameter,
        DcpShortNames.WallThickness,
      ];
    }

    simulateUserRole = this.fgFilters.get("simulateUserRole").value;

    let searchItemsRequest: SearchItemsRequest = {
      page: page,
      size: size,
      filter: {
        filtersNames: filterNames,
        unitChanged: false,
        unitSystem: "USC",
      },
      simulateUserRole: this.fgFilters.get("simulateUserRole").value,
    };

    searchItemsRequest.filter.outsideDiameter = filterValues.outsideDiameter;
    searchItemsRequest.filter.wallThickness =
      filterValues.wallThickness && filterValues.nominalWeight
        ? `${filterValues.wallThickness}#(${filterValues.nominalWeight})`
        : null;

    if (filterValues.outsideDiameter && filterValues.wallThickness) {
      this.subfiltersVisible = true;
      searchItemsRequest.filter.grade = filterValues.grade;
      searchItemsRequest.filter.connection = filterValues.connection;
      searchItemsRequest.filter.wtTolerance = filterValues.minWallThickness
        ? (filterValues.minWallThickness - 100) / 100
        : null;
    } else {
      this.subfiltersVisible = false;
    }

    return searchItemsRequest;
  }

  resetFilters() {
    if (!this.isInitialized) {
      this.fgFilters.get("fgMainFilters").reset({}, { emitEvent: false });
      this.fgFilters.get("fgSubFilters").reset({ emitEvent: false });
      this.slottedModalService.setDcpProducts([]);
      this.isInitialized = true;
    }
  }

  preloadFilters(filterValues: any) {
    this.dcpService
      .getProductsByFilter(this.getPreloadSearchItemsRequest(filterValues))
      .subscribe((searchItemsResponse) => {
        this.filters = searchItemsResponse.filters;

        if (
          searchItemsResponse.filters?.OD.length === 0 ||
          searchItemsResponse.filters?.WT.length === 0
        )
          this.fgFilters.get("fgMainFilters").reset();
        else {
          this.fgFilters
            .get("fgSubFilters")
            .get("type")
            .setValue(null, { emitEvent: false });
          this.fgFilters
            .get("fgSubFilters")
            .get("pipeBodyDrift")
            .setValue(null, { emitEvent: false });
          this.fgFilters
            .get("fgSubFilters")
            .get("specialBevel")
            .setValue(null, { emitEvent: false });
          this.fgFilters
            .get("fgSubFilters")
            .get("connectionODOption")
            .setValue(null, { emitEvent: false });

          this.fgFilters
            .get("fgMainFilters")
            .get("outsideDiameter")
            .setValue(
              searchItemsResponse.filters?.OD?.find(
                (x) => x.id == filterValues.outsideDiameter
              )?.id,
              { emitEvent: false }
            );

          this.fgFilters
            .get("fgMainFilters")
            .get("wallThickness")
            .setValue(
              searchItemsResponse.filters?.WT?.find(
                (x) => x.text.split(" (")[0] == filterValues.wallThickness
              )?.id,
              { emitEvent: false }
            );

          if (filterValues.outsideDiameter && filterValues.wallThickness) {
            this.fgFilters
              .get("fgMainFilters")
              .get("grade")
              .setValue(
                searchItemsResponse.filters?.GR?.find(
                  (x) => x.id == filterValues.grade
                )?.id,
                { emitEvent: false }
              );

            this.fgFilters
              .get("fgMainFilters")
              .get("connection")
              .setValue(
                searchItemsResponse.filters?.CN?.find(
                  (x) => x.id == filterValues.connection
                )?.id,
                { emitEvent: false }
              );

            this.fgFilters
              .get("fgSubFilters")
              .get("minWallThickness")
              .setValue(
                searchItemsResponse.filters?.MW?.find(
                  (x) =>
                    Number(x.id).toFixed(3) ==
                    (filterValues.minWallThickness
                      ? ((filterValues.minWallThickness - 100) / 100).toFixed(3)
                      : null)
                )?.id,
                { emitEvent: false }
              );
          }
        }

        this.slottedModalService.setDcpProducts(searchItemsResponse.datasheets);
      });
  }
}
