import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";
import { CorrosionClasPdf } from "../models/corrosionclas/CorrosionClass";
import { PrintedCWRequest } from "../modules/CasingWear/models/printed";
import { PrintPdfCRRequest } from "../modules/CorrosionRate/models/treeCR";
import { PrintPdfSPRequest } from "../modules/slotted-perforated-pipe/slotted-pipe/models/treeSP";
import { PrintPdfPRRequest } from "../modules/slotted-perforated-pipe/perforated-pipe/models/treePR";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  localPdf: any;
  constructor(private http: HttpClient) {}

  getPdfCc(corrosionclas?: CorrosionClasPdf): any {
    let apiUrlCc = `${environment.apiEndpoint}util/v1/ReportPdf/CorrosionCLAS`;
    this.http.post(apiUrlCc, corrosionclas).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        saveAs(
          blob,
          "ReportCorrosionCLAS" +
            formatDate(new Date(), "yyyyMMdd", "en") +
            ".pdf"
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  getPdfCw(printCWRequest: PrintedCWRequest): any {
    let apiUrlCc = `${environment.apiEndpoint}util/v1/ReportPdf/CasingWear`;
    this.http.post(apiUrlCc, printCWRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(
          blob,
          "ReportCasingWear_"
            .concat(printCWRequest.SDCaseNumber.trim().replace(/\s/g, "_"))
            .concat("_")
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".pdf")
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  getPdfCR(printPdfCRRequest: PrintPdfCRRequest): any {
    let apiUrlCc = `${environment.apiEndpoint}util/v1/ReportPdf/CorrosionRate`;
    this.http.post(apiUrlCc, printPdfCRRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(
          blob,
          "ReportCorrosionRate_"
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".pdf")
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  getPdfSP(printPdfSPRequest: PrintPdfSPRequest): any {
    let apiUrlSp = `${environment.apiEndpoint}util/v1/ReportPdf/Slotted`;
    this.http.post(apiUrlSp, printPdfSPRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(
          blob,
          "ReportSlottedPipe_"
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".pdf")
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  getPdfPR(printPdfPRRequest: PrintPdfPRRequest): any {
    let apiUrlSp = `${environment.apiEndpoint}util/v1/ReportPdf/Perforated`;
    this.http.post(apiUrlSp, printPdfPRRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(
          blob,
          "ReportPerforatedPipe_"
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".pdf")
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  getPdfMSG(printPdfMSGRequest: any): any {
    let apiUrlCc = `${environment.apiEndpoint}util/v1/ReportPdf/MatSelGuidelines`;
    this.http.post(apiUrlCc, printPdfMSGRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["pdf"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(
          blob,
          "ReportISO15156Guidelines_"
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".pdf")
        );
      },
      (e) => {
        throw e;
      }
    );
  }
}
