<style>
  /* NestedList */
  .dd-list .dd-item .dd3-handle:before,
  .dd-list .dd3-item .dd3-handle:before {
    content: "\e605";
    font-family: 'Stroke 7';
    font-size: 21px;
    color: #808080 !important;
  }

  #dd3-handle1:before {
    content: "\e605";
  }

  #dd3-handle2:before {
    content: "\e662";
  }

  #dd3-handle3:before {
    content: "\e64f";
  }

  #dd3-handle4:before {
    content: "\e622";
  }

  #dd3-handle5:before {
    content: "\e619";
  }

  .dd-list .dd-item .dd3-handle {
    background-color: white !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }

  .dd-list .dd-item>button[data-action=collapse]:before,
  .dd-list .dd3-item>button[data-action=collapse]:before {
    font-family: FontAwesome !important;
    content: '\f068';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
  }

  .dd-list .dd-item>button:before,
  .dd-list .dd3-item>button:before {
    font-family: FontAwesome !important;
    content: '\f067';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
  }

  .dd-list .dd-item .dd3-handle,
  .dd-list .dd3-item .dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: unset;
    width: 34px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border-right-color: #dedede !important;
    height: 36px;
  }

  .dd-list .dd3-item .dd3-content,
  .dd-list .dd-item .dd3-content {
    font-family: FrutigerRoman, sans-serif;
    display: block;
    margin: 5px 0;
    padding: 7px 10px 7px 40px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #dedede;
  }

  .myFilter>* {
    color: green;
  }
  
  .myFilter [data-level="simulation"] {
    color:#009; 
    font-weight: bolder;
  }
</style>

<div class="form-group">
  <input style="padding: 0 24px; height: 36px;" type="text" class="form-control" id="searchTtext" aria-describedby="searchText" [(ngModel)]="searchText"
    placeholder="What are you searching for?">
  <ng-container *ngIf="onlyUserSimulationsOption">
    <div class="am-checkbox">
      <input
      id="only-my-simulations"
      type="checkbox"
      style="float: left"
      class="needsclick"
      [(ngModel)]="onlyMySimulations"
      (ngModelChange)="onCheckOnlyMySimulations($event)"
    />
    <label for="only-my-simulations">Only my simulations</label>
  </div>
  </ng-container>
</div>

<div id="tree" class="dd">
  <ol *ngFor="let user of tree?.users | appFilter: searchText" class="dd-list">
    <li class="dd-item dd3-item">
      <div id="dd3-handle1" class="dd-handle dd3-handle dd-nodrag"></div>
      <div #treeL1 id="treeL1" [attr.data-level]="'user'" [attr.data-id]="user.id" [attr.data-text]="user.userName"
        class="dd3-content" (click)="canActivateButton($event)">{{
        user.userName }}</div>
      <ol *ngFor="let customer of user.customers" appHighlight
      [searchedWord]="searchText" [content]="customer.customerName" class="dd-list">
        <li *ngIf="customer.visible" class="dd-item dd3-item">
          <div id="dd3-handle2" class="dd-handle dd3-handle dd-nodrag"></div>
          <div [attr.data-level]="'customer'" [attr.data-id]="customer.id" [attr.data-text]="customer.customerName"
            class="dd3-content" (click)="canActivateButton($event)">{{
            customer.customerName }}</div>
          <ol *ngFor="let region of customer.regions" appHighlight
          [searchedWord]="searchText" [content]="region.regionName" class="dd-list">
            <li *ngIf="region.visible" class="dd-item dd3-item">
              <div id="dd3-handle3" class="dd-handle dd3-handle dd-nodrag">
              </div>
              <div [attr.data-level]="'region'" [attr.data-id]="region.id" [attr.data-text]="region.regionName"
                class="dd3-content" (click)="canActivateButton($event)">
                {{ region.regionName }}</div>
              <ol *ngFor="let project of region.projects" appHighlight
              [searchedWord]="searchText" [content]="project.projectName" class="dd-list">
                <li *ngIf="project.visible" class="dd-item dd3-item">
                  <div id="dd3-handle4" class="dd-handle dd3-handle dd-nodrag">
                  </div>
                  <div [attr.data-level]="'project'" [attr.data-id]="project.id" [attr.data-text]="project.projectName"
                    class="dd3-content" (click)="canActivateButton($event)">
                    {{ project.projectName }}
                  </div>
                  <ol *ngFor="let sim of project.simulations" appHighlight [searchedWord]="searchText"
                  [content]="sim.simulationName" class="dd-list">
                    <li *ngIf="sim.visible" class="dd-item dd3-item">
                      <div id="dd3-handle5" class="dd-handle dd3-handle dd-nodrag"></div>
                      <div [attr.data-level]="'simulation'" [attr.data-id]="sim.id"
                        [attr.data-text]="sim.simulationName" class="dd3-content" (click)="canActivateButton($event)">
                        {{ sim.simulationName }}
                      </div>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </li>
  </ol>
</div>