import { Component, OnInit } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import Swal from 'sweetalert2';
import { HomeComponent } from '../home/Home.component';
@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html',
})
export class UnauthorizedComponent implements OnInit {
  isAdmin : boolean;
  constructor() {
    this.isAdmin = false;

    // this.service.setAuth(this.isAdmin);    
  }

    ngOnInit() {
    this.noPermits();
    //redireccionar a path de login
    // this.serviceRoute.navigate(['login']);
  }

  noPermits(){
    Swal.fire({
      title: "You don't have access to this application",
      html: 'If you need permission please create a ticket through ITUSS Self Service ' + '<a href="https://applications.tenaris.net/sites/ITUserSupport">https://applications.tenaris.net/sites/ITUserSupport</a>',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#aaa',
      timer: 30000,
      showConfirmButton: false
    });
  }
}
