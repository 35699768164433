import { TreeCustomer, TreeLevel, TreeLevels, TreeNode, TreeProject, TreeRegion, TreeSimulation, TreeUser } from "src/app/models/tree/Index";

export function createTree(resp: TreeNode[], clean:boolean = false): TreeLevels {

  const tree = new TreeLevels();
  tree.users = [];
  if (resp == undefined || resp == null) {
    return tree;
  }

  const l1 = resp.filter(data => data.item_level == TreeLevel.l1);
  if (l1.length > 0) {
    for (let i1 = 0; i1 < l1.length; i1++) {
      let user = new TreeUser();
      user.id = l1[i1].id;
      user.userId = l1[i1].user_id;
      user.userName = l1[i1].item_name;
      user.accion = '';
      user.customers = [];
      user.visible = true;

      const l2 = resp.filter(data => data.item_level == TreeLevel.l2 && data.parent_id == user.id);
      if (l2.length > 0) {
        for (let i2 = 0; i2 < l2.length; i2++) {
          const cus = new TreeCustomer();
          cus.id = l2[i2].id
          cus.customerId = l2[i2].item_id;
          cus.customerName = l2[i2].item_name;
          cus.parentId = user.id;
          cus.regions = [];
          cus.visible = true;
          user.customers.push(cus)

          const l3 = resp.filter(data => data.item_level == TreeLevel.l3 && data.parent_id == cus.id);
          if (l3.length > 0) {
            for (let i3 = 0; i3 < l3.length; i3++) {
              const reg = new TreeRegion();
              reg.id = l3[i3].id;
              reg.regionId = l3[i3].item_id;
              reg.regionName = l3[i3].item_name;
              reg.parentId = cus.id;
              reg.projects = [];
              reg.visible = true;
              user.customers[i2].regions.push(reg);

              const l4 = resp.filter(data => data.item_level == TreeLevel.l4 && data.parent_id == reg.id);
              if (l4.length > 0) {
                for (let i4 = 0; i4 < l4.length; i4++) {
                  const proj = new TreeProject();
                  proj.id = l4[i4].id
                  proj.projectId = l4[i4].item_id;
                  proj.projectName = l4[i4].item_name;
                  proj.parentId = reg.id;
                  proj.simulations = [];
                  proj.visible = true;
                  user.customers[i2].regions[i3].projects.push(proj);

                  const l5 = resp.filter(data => data.item_level == TreeLevel.l5 && data.parent_id == proj.id);
                  if (l5.length > 0) {
                    for (let i5 = 0; i5 < l5.length; i5++) {
                      const sim = new TreeSimulation();
                      sim.id = l5[i5].id;
                      sim.simulationId = l5[i5].item_id;
                      sim.simulationName = l5[i5].item_name;
                      sim.parentId = proj.id;
                      sim.visible = true;
                      user.customers[i2].regions[i3].projects[i4].simulations.push(sim);
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (clean){
        let userl2 = cleanUser(user);
        let userl3 = cleanUser(userl2);
        let userl4 = cleanUser(userl3);
        user = userl4;
      }
      tree.users.push(user);
    }
  }
  return tree;
}

function cleanUser(user: TreeUser): TreeUser {
  const userClone: TreeUser = Object.assign({}, user);
  for (let i2 = 0; i2 < user.customers.length; i2++) {
    const cus = user.customers[i2];
    if (cus?.regions.length == 0){
      userClone.customers.splice(i2,1);
    } else {
      for (let i3 = 0; i3 < cus?.regions.length; i3++) {
        const reg = cus?.regions[i3];
        if ( reg?.projects.length == 0){
          userClone.customers[i2].regions.splice(i3,1);
        } else {
          for (let i4 = 0; i4 < reg?.projects.length; i4++) {
            const pro = reg?.projects[i4];
            if (pro.simulations.length == 0) {
              userClone.customers[i2].regions[i3].projects.splice(i4,1);
            }
          }
        }
      }
    }
  }
  return userClone;
}
