import { Pipe, PipeTransform } from "@angular/core";

/*
 * Rounds the number if exceeds the decimal places indicated.
 * Takes a 'decimalPlaces' argument that defaults to 0.
 * Usage:
 *   value | roundDecimals:decimalPlaces
 * Example:
 *   {{ 2.188 | decimalPlaces:2 }}
 *   formats to: 2.19
 */
@Pipe({
  name: "roundDecimals",
})
export class RoundDecimalsPipe implements PipeTransform {
  transform(value: number, decimalPlaces = 0): number {
    return (value || value === 0) && Number.isFinite(value) ? Number(value.toFixed(decimalPlaces)) : value;
  }
}
