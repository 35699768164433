<div id="divInstallation" class="panel-default">
  <div id="attachmentsPanel" class="widget-head">
    <h3>Attachments</h3>
  </div>
  <div class="panel-body">
    <form [formGroup]="fgSlottedPipe">
      <div formGroupName="fgAttachments">
        <div class="row" style="display: flex; flex-wrap: wrap">
          <div class="col-sm-12">
            <input hidden formControlName="attachments" />
            <div
              [ngClass]="{
                disabled: this.fgSlottedPipe.get('fgAttachments').disabled
              }"
            >
              <ngx-dropzone
                [ngClass]="{
                  'disabled-section':
                    this.fgSlottedPipe.get('fgAttachments').disabled
                }"
                class="slotted-attachments"
                (change)="onSelect($event)"
              >
                <ngx-dropzone-label
                  >Drop your attachments here</ngx-dropzone-label
                >
                <ngx-dropzone-preview
                  *ngFor="let attachment of attachments"
                  [removable]="true"
                  (removed)="onRemove(attachment)"
                >
                  <ngx-dropzone-label
                    ><div class="file-desc-container">
                      <span class="file-name">{{ attachment.file.name }}</span>
                      <span class="file-type"
                        >({{ attachment.file.type }})</span
                      >
                    </div>
                    <span class="overwrites" *ngIf="attachment.overwrite"
                      >Overwrites</span
                    ></ngx-dropzone-label
                  >
                </ngx-dropzone-preview>
              </ngx-dropzone>
              <span style="font-size: 9px; color: red;" *ngIf="attachments.length > 0">*Changes apply only when simulation is saved.</span>
            </div>
          </div>
          <div
            class="col-sm-12 attachments-uploaded-container widget-head"
            *ngIf="attachmentsUploaded.length > 0"
          >
            <h3>Attachments Uploaded</h3>
            <div class="attachments-list-container">
              <div *ngFor="let attachmentUploaded of attachmentsUploaded">
                <span [ngClass]="{'overwritten': attachmentUploaded.overwritten}" class="file-uploaded-desc">{{ attachmentUploaded.file_name }}</span>
                <span [ngClass]="{'overwritten': attachmentUploaded.overwritten}">{{attachmentUploaded.last_update_date| date: 'dd/MM/yyyy'}}</span>
                <div class="icons-container">
                  <span
                    style="
                      font-size: 22px;
                      padding: 0;
                      margin-right: 10px;
                      cursor: pointer;
                    "
                    class="icon s7-download am-toggle-right-sidebar"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Download Attachment"
                    (click)="downloadAttachment(attachmentUploaded.id)"
                  ></span>
                  <span
                    style="font-size: 22px; padding: 0; cursor: pointer"
                    class="icon s7-trash am-toggle-right-sidebar"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Delete Attachment"
                    (click)="confirmDeleteAttachment(attachmentUploaded)"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
