import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {
  AuthenticatedResult,
  OidcClientNotification,
  OidcSecurityService,
  OpenIdConfiguration,
  UserDataResult
} from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

export interface Config {
  /*heroesUrl: string;
  textfile: string;
  date: any;*/

  date: any;
  temperatureC: any;
  temperatureF:  any;
  summary: string;
}

@Injectable()
export class LoginService {
  configUrl = 'assets/config.json';

  error: any;
  headers: string[] = [];
  config: Config | undefined;
  configurations: OpenIdConfiguration[];
  userDataChanged$: Observable<OidcClientNotification<any>>;
  userData$: Observable<UserDataResult>;
  isAuthenticated$: Observable<AuthenticatedResult>; 
  isAuth$: boolean;
  lisAuthenticated: boolean;

  constructor(private oidcSecurityService: OidcSecurityService ) { }
    
  login(configId: string) {
    this.oidcSecurityService.authorize(configId);
  }
  
  logout(configId: string) {
    this.oidcSecurityService.logoff(configId);
    sessionStorage.clear();
  }

//function para traer el current config 
  currentConfig()
  {
    return this.oidcSecurityService.getConfigurations();;
  }

  forceRefreshSession() {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }

  refreshSessionId4(configId: string) {
    this.oidcSecurityService.forceRefreshSession(null, configId).subscribe();
  }

  refreshSessionAuth0(configId: string) {
    this.oidcSecurityService
      .forceRefreshSession({ scope: 'openid profile offline_access auth0-user-api-spa' }, configId)
      .subscribe();
  }

  logoffAndRevokeTokens(configId: string) {
    this.oidcSecurityService.logoffAndRevokeTokens(configId).subscribe();
  }

  revokeRefreshToken(configId: string) {
    this.oidcSecurityService.revokeRefreshToken(null, configId).subscribe();

  }

  getType(val: any): string {
    return val instanceof Date ? 'date' : Array.isArray(val) ? 'array' : typeof val;
  }
}