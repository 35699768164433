<div class="col-md-12">
  <div id="divInstallation" class="panel-default">
    <div id="holesPanel" class="widget-head">
      <h3>Holes Scheme</h3>
    </div>
  </div>
  <div class="panel-body conditions-fields-container">
    <form [formGroup]="fgPerforatedPipe">
      <div formGroupName="fgHoles">
        <div class="row">
          <div class="col-md-4 p-0">
            <div class="col-md-12">
              <label class="input-xs">Hole Diameter</label>
              <div class="xs-mb-15">
                <div class="input-group">
                  <input
                    formControlName="holeDiameter"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'holeDiameterWarning'"
                    [ngClass]="{
                      'input-warning': holeDiameter?.warnings?.length > 0,
                      'input-error':
                        (holeDiameter.touched || holeDiameter.dirty) &&
                        holeDiameter.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'holeDiameterWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="holeDiameter.errors?.delimited"
                >
                  {{ holeDiameter.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Hole Diameter Tolerance (+-)</label>
              <div class="xs-mb-15">
                <div class="input-group">
                  <input
                    formControlName="holeDiameterTolerance"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'holeDiameterToleranceWarning'"
                    [ngClass]="{
                      'input-warning':
                        holeDiameterTolerance?.warnings?.length > 0,
                      'input-error':
                        (holeDiameterTolerance.touched ||
                          holeDiameterTolerance.dirty) &&
                        holeDiameterTolerance.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'holeDiameterToleranceWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="holeDiameterTolerance.errors?.delimited"
                >
                  {{ holeDiameterTolerance.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Holes per Foot</label>
              <div class="xs-mb-15">
                <div class="input-group" style="width: 100%">
                  <input
                    commaToDot
                    formControlName="holesPerFoot"
                    class="form-control input-xs no-spin"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    Nr/ft</span
                  >
                </div>
              </div>
            </div>
            <div
              class="col-md-12"
              style="display: flex; justify-content: center; flex-wrap: wrap"
            >
              <div class="col-md-12">
                <div
                  class="image-border"
                  style="display: flex; justify-content: center"
                >
                  <img
                    src="../../../../../assets/img/perforated/References-Columns.png"
                    class="static-img"
                  />
                  <img
                    src="../../../../../assets/img/perforated/References-Axes.png"
                    class="static-img"
                  />
                </div>
              </div>
              <div class="col-md-12 holes-ref">
                <p>
                  Fig. 4
                  <br />
                  References
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-0">
            <div class="col-md-12">
              <label class="input-xs">Number of Axes</label>
              <div class="xs-mb-15">
                <div class="input-group holesAttr">
                  <input
                    formControlName="numberOfAxes"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'numberOfAxesWarning'"
                    [ngClass]="{
                      'input-warning': numberOfAxes?.warnings?.length > 0,
                      'input-error':
                        (numberOfAxes.touched || numberOfAxes.dirty) &&
                        numberOfAxes.errors?.delimited
                    }"
                  />
                  <warning-message
                    [id]="'numberOfAxesWarning'"
                    class="text-danger error-warning-text"
                  ></warning-message>
                  <div
                    class="text-danger error-warning-text"
                    *ngIf="numberOfAxes.errors?.delimited"
                  >
                    {{ numberOfAxes.errors?.errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Number of Columns per Foot<br />(See Figure 4)</label
              >
              <div class="input-group xs-mb-15 holesAttr">
                <input
                  formControlName="numberOfColumnsPerFoot"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  Nr/ft</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Hole Axial Distance (S)</label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="holeAxialDistance"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4 p-0">
            <div class="col-md-12">
              <label class="input-xs"
                >Staggered Only-1st/2nd Row Pitch<br />(s´ in fig 4)</label
              >
              <div class="input-group xs-mb-15">
                <input
                  formControlName="staggeredOnly"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Hole Perimetral Distance (T) </label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="holePerimetralDistance"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Helicoidal Only-Perimetral Pitch<br />(t´ in fig 4)</label
              >
              <div class="input-group xs-mb-15">
                <input
                  formControlName="helicoidalOnly"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <label style="visibility: hidden" class="input-xs"
                >Data Check</label
              >
              <button
                *ngIf="showDataCheck"
                data-toggle="modal"
                data-target="#dataCheckModal"
                data-placement="right"
                data-tooltip
                title="Data Check"
                type="button"
                class="datacheck"
                (click)="dataCheck()"
                tabindex="-1"
                style="margin-bottom: 15px"
              >
                Data Check
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
