import { Component, OnInit } from "@angular/core";
import { Results } from "../../models/slotted-pipe";
import { SlottedService } from "../../services/slotted.service";

@Component({
  selector: "app-slotted-results",
  templateUrl: "./slotted-results.component.html",
  styleUrls: ["./slotted-results.component.css"],
})
export class SlottedResultsComponent implements OnInit {
  slottedResults: Results;
  showSlottedResults: boolean = false;

  constructor(private slottedService: SlottedService) {}

  ngOnInit(): void {
    this.slottedService.slottedResults$.subscribe((slottedResults) => {
      this.slottedResults = slottedResults;
    });

    this.slottedService.showSlottedResults$.subscribe((showSlottedResults) => {
      this.showSlottedResults = showSlottedResults;
    });
  }
}
