<style>
  .am-content {
    margin-left: 5px;
  }
  .menu li {
    display: inline-block;
  }
  .am-white-header .am-top-header .navbar-brand {
    border-bottom: 1px solid #e6e6e6;
  }
  .disabled {
    pointer-events: none;
  }
  span {
    cursor: pointer;
  }
  .input-error {
    border: 2px solid rgba(255, 0, 0, 0.65);
    color: red;
  }
</style>
<div class="am-wrapper am-white-header">
  <div>
    <nav class="navbar navbar-default navbar-fixed-top am-top-header">
      <div class="container-fluid">
        <div class="navbar-header">
          <div class="page-title"><span>Form Elements</span></div>
          <a href="#" class="am-toggle-left-sidebar navbar-toggle collapsed"
            ><span class="icon-bar"
              ><span></span><span></span><span></span></span></a
          ><a [routerLink]="NAVIGATIONS.HOME.route" class="navbar-brand"></a>
        </div>

        <div id="actions-container">
        <div *ngIf="currentUrl == NAVIGATIONS.CASING_WEAR.route">
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickCwValidate()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-notebook am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="Link to Manual"
            (click)="onLinkToManualClick()"
          ></span>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.CORROSION_CLAS.route">
          <!-- <span style="font-size: 22px; padding: 0;cursor: pointer;" [class.disabled]="enabledButtonReportCC" class="icon s7-print am-toggle-right-sidebar" data-placement="right" data-tooltip title="Report" (click)="onReportClickCc()"></span> -->
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickCcValidate()"
          ></span>
          <span
            id="sidebarDelete"
            style="font-size: 22px; padding: 0"
            class="icon s7-trash am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Delete Simulation"
            (click)="onDeleteClick()"
          ></span>
          <span
            id="sidebarSave"
            style="font-size: 22px; padding: 0"
            class="icon s7-diskette am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Save Simulation"
            (click)="onSaveClick()"
          ></span>
          <span
            id="sidebarLoad"
            style="font-size: 22px; padding: 0"
            class="icon s7-upload am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Load Simulation"
            (click)="onLoadClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-plus am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="New Simulation"
            (click)="onNewClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-notebook am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="Link to Manual"
            (click)="onLinkToManualClick()"
          ></span>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.CORROSION_RATE.route">
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-download am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Download Spreadsheet"
            (click)="onDownloadClick()"
          ></span>
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickCRValidate()"
          ></span>
          <span
            id="sidebarDelete"
            style="font-size: 22px; padding: 0"
            class="icon s7-trash am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Delete Simulation"
            (click)="onDeleteClick()"
          ></span>
          <span
            id="sidebarSave"
            style="font-size: 22px; padding: 0"
            class="icon s7-diskette am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Save Simulation"
            (click)="onSaveClick()"
          ></span>
          <span
            id="sidebarLoad"
            style="font-size: 22px; padding: 0"
            class="icon s7-upload am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Load Simulation"
            (click)="onLoadClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-plus am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="New Simulation"
            (click)="onNewClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-notebook am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="Link to Manual"
            (click)="onLinkToManualClick()"
          ></span>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.SLOTTED.route">
          <!-- <span style="font-size: 22px; padding: 0;cursor: pointer;" [class.disabled]="enabledButtonReportCC" class="icon s7-print am-toggle-right-sidebar" data-placement="right" data-tooltip title="Report" (click)="onReportClickCc()"></span> -->
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickSPValidate()"
          ></span>
          <span
            id="sidebarDelete"
            style="font-size: 22px; padding: 0"
            class="icon s7-trash am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Delete Simulation"
            (click)="onDeleteClick()"
          ></span>
          <span
            id="sidebarSave"
            style="font-size: 22px; padding: 0"
            class="icon s7-diskette am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Save Simulation"
            (click)="onSaveClick()"
          ></span>
          <span
            id="sidebarLoad"
            style="font-size: 22px; padding: 0"
            class="icon s7-upload am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Load Simulation"
            (click)="onLoadClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-plus am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="New Simulation"
            (click)="onNewClick()"
          ></span>
          <span
          style="font-size: 22px; padding: 0"
          class="icon s7-notebook am-toggle-right-sidebar"
          placement="left"
          ngbTooltip="Tooltip on left"
          data-tooltip
          title="Link to Manual"
          (click)="onLinkToManualClick()"
          ></span>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.PERFORATED.route">
          <!-- <span style="font-size: 22px; padding: 0;cursor: pointer;" [class.disabled]="enabledButtonReportCC" class="icon s7-print am-toggle-right-sidebar" data-placement="right" data-tooltip title="Report" (click)="onReportClickCc()"></span> -->
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickPRValidate()"
          ></span>
          <span
            id="sidebarDelete"
            style="font-size: 22px; padding: 0"
            class="icon s7-trash am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Delete Simulation"
            (click)="onDeleteClick()"
          ></span>
          <span
            id="sidebarSave"
            style="font-size: 22px; padding: 0"
            class="icon s7-diskette am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Save Simulation"
            (click)="onSaveClick()"
          ></span>
          <span
            id="sidebarLoad"
            style="font-size: 22px; padding: 0"
            class="icon s7-upload am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Load Simulation"
            (click)="onLoadClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-plus am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="New Simulation"
            (click)="onNewClick()"
          ></span>
          <span
          style="font-size: 22px; padding: 0"
          class="icon s7-notebook am-toggle-right-sidebar"
          placement="left"
          ngbTooltip="Tooltip on left"
          data-tooltip
          title="Link to Manual"
          (click)="onLinkToManualClick()"
          ></span>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.MAT_SEL_GUIDELINES.route">
          <!-- <span style="font-size: 22px; padding: 0;cursor: pointer;" [class.disabled]="enabledButtonReportCC" class="icon s7-print am-toggle-right-sidebar" data-placement="right" data-tooltip title="Report" (click)="onReportClickCc()"></span> -->
          <span
            data-toggle="modal"
            data-target="#form-create-report"
            style="font-size: 22px; padding: 0"
            class="icon s7-print am-toggle-right-sidebar"
            data-placement="right"
            data-tooltip
            title="Report"
            (click)="onReportClickMSGValidate()"
          ></span>
          <span
            id="sidebarDelete"
            style="font-size: 22px; padding: 0"
            class="icon s7-trash am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Delete Simulation"
            (click)="onDeleteClick()"
          ></span>
          <span
            id="sidebarSave"
            style="font-size: 22px; padding: 0"
            class="icon s7-diskette am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Save Simulation"
            (click)="onSaveClick()"
          ></span>
          <span
            id="sidebarLoad"
            style="font-size: 22px; padding: 0"
            class="icon s7-upload am-toggle-right-sidebar"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Load Simulation"
            (click)="onLoadClick()"
          ></span>
          <span
            style="font-size: 22px; padding: 0"
            class="icon s7-plus am-toggle-right-sidebar"
            placement="left"
            ngbTooltip="Tooltip on left"
            data-tooltip
            title="New Simulation"
            (click)="onNewClick()"
          ></span>
          <span
          style="font-size: 22px; padding: 0"
          class="icon s7-notebook am-toggle-right-sidebar"
          placement="left"
          ngbTooltip="Tooltip on left"
          data-tooltip
          title="Link to Manual"
          (click)="onLinkToManualClick()"
          ></span>
        </div>
      </div>

        <div id="am-navbar-collapse" class="collapse navbar-collapse">
          <ul class="nav navbar-nav am-user-nav">
            <li class="dropdown">
              <a
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
                class="dropdown-toggle"
                >{{ nameUser }}
                <img [src]="imagePath" />
                <span class="user-name">Tenaris</span>
              </a>
              <ul role="menu" class="dropdown-menu">
                <!-- <li><a href="#"> <span class="icon s7-user"></span>My profile</a></li>
              <li><a href="#"> <span class="icon s7-config"></span>Settings</a></li> -->
                <li>
                  <a href="./help"> <span class="icon s7-help1"></span>Help</a>
                </li>
                <li (click)="logout(configurations[0].configId)">
                  <a> <span class="icon s7-power"></span>Sign Out</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <app-menu></app-menu>
  </div>
</div>

<div
  id="form-create-report"
  tabindex="-1"
  role="dialog"
  class="modal fade modal-colored-header"
  *ngIf="showModalReport"
>
  <div class="modal-dialog custom-width">
    <div style="background-color: #009">
      <div class="panel-heading">
        <h3 style="color: white; font-weight: bold">Report Details</h3>
      </div>
    </div>

    <div class="panel-body" style="background-color: white; color: black">
      <form
        id="fgCreateReport"
        [formGroup]="fgCreateReport"
        (ngSubmit)="onReportClick()"        
        role="form"
        class="form-horizontal"
      >
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputCustomer" class="col-sm-4 control-label"
            >Customer</label
          >
          <div class="col-sm-6">
            <input
              formControlName="customer"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('customer').touched ||
                    fgCreateReport.get('customer').dirty) &&
                  fgCreateReport.get('customer').invalid
              }"
              autocomplete="off"
              id="inputCustomer"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputCountry" class="col-sm-4 control-label"
            >Country</label
          >
          <div class="col-sm-6">
            <input
              formControlName="country"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('country').touched ||
                    fgCreateReport.get('country').dirty) &&
                  fgCreateReport.get('country').invalid
              }"
              autocomplete="off"
              id="inputCountry"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputCase" class="col-sm-4 control-label"
            >Simulation Code</label
          >
          <div class="col-sm-6">
            <input
              formControlName="SDCaseNumber"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('SDCaseNumber').touched ||
                    fgCreateReport.get('SDCaseNumber').dirty) &&
                  fgCreateReport.get('SDCaseNumber').invalid
              }"
              autocomplete="off"
              id="inputCase"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputRequested" class="col-sm-4 control-label"
            >Requested By</label
          >
          <div class="col-sm-6">
            <input
              formControlName="requestedBy"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('requestedBy').touched ||
                    fgCreateReport.get('requestedBy').dirty) &&
                  fgCreateReport.get('requestedBy').invalid
              }"
              autocomplete="off"
              id="inputRequested"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputReviewed" class="col-sm-4 control-label"
            >Reviewed By</label
          >
          <div class="col-sm-6">
            <input
              formControlName="reviewedBy"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('reviewedBy').touched ||
                    fgCreateReport.get('reviewedBy').dirty) &&
                  fgCreateReport.get('reviewedBy').invalid
              }"
              autocomplete="off"
              id="inputReviewed"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputAuthorized" class="col-sm-4 control-label"
            >Authorized By</label
          >
          <div class="col-sm-6">
            <input
              formControlName="authorizedBy"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('authorizedBy').touched ||
                    fgCreateReport.get('authorizedBy').dirty) &&
                  fgCreateReport.get('authorizedBy').invalid
              }"
              autocomplete="off"
              id="inputAuthorized"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.SLOTTED.route || currentUrl == NAVIGATIONS.PERFORATED.route" class="form-group" style="padding-bottom: 0px">
          <label for="inputPreManufacturer" class="col-sm-4 control-label"
            >Pre-machining manufacturer </label
          >
          <div class="col-sm-6">
            <input
              formControlName="preMachiningManufacturer"
              [ngClass]="{
                'input-error':
                  (fgCreateReport.get('preMachiningManufacturer').touched ||
                    fgCreateReport.get('preMachiningManufacturer').dirty) &&
                  fgCreateReport.get('preMachiningManufacturer').invalid
              }"
              autocomplete="off"
              id="inputPreManufacturer"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.CASING_WEAR.route" class="form-group" style="padding-bottom: 0px">
          <div class="col-sm-offset-2">
            <div class="col-sm-offset-2 col-sm-8" style="margin: 0px">
              <div class="am-checkbox">
                Print Only 1st Page<input
                  id="check2"
                  formControlName="printOnlyFirstPage"
                  type="checkbox"
                  class="needsclick"
                />
                <label for="check2"></label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentUrl == NAVIGATIONS.MAT_SEL_GUIDELINES.route" class="form-group" style="padding-bottom: 0px">
          <div class="col-sm-offset-2">
            <div class="col-sm-offset-2 col-sm-4" style="margin: 0px">
              <div class="am-checkbox">
                Option 1<input
                  id="option1Checkbox"
                  formControlName="option1"
                  type="checkbox"
                  class="needsclick"
                />
                <label for="option1Checkbox"></label>
              </div>
            </div>
            <div class="col-sm-offset-2 col-sm-4" style="margin: 0px">
              <div class="am-checkbox">
                Option 2<input
                  id="option2Checkbox"
                  formControlName="option2"
                  type="checkbox"
                  class="needsclick"
                />
                <label for="option2Checkbox"></label>
              </div>
            </div>
            <div class="col-sm-offset-2 col-sm-4" style="margin: 0px">
              <div class="am-checkbox">
                CRA<input
                  id="craCheckbox"
                  formControlName="cra"
                  type="checkbox"
                  class="needsclick"
                />
                <label for="craCheckbox"></label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-8">
            <div class="text-inverse">
              <button
                type="submit"
                class="btn btn-space btn-primary"
                [disabled]="fgCreateReport.invalid"
              >
                Download
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-space btn-default"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

