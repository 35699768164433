export const NAVIGATIONS = {
  HOME: {
    route: "/home",
    name: "Home",
  },
  CASING_WEAR: {
    route: "/casingwear",
    name: "Casing Wear",
  },
  CORROSION_CLAS: {
    route: "/corrosionclas",
    name: "Corrosion CLAS",
  },
  CORROSION_RATE: {
    route: "/corrosionrate",
    name: "13Cr Corrosion Rate",
  },
  HELP: {
    route: "/help",
    name: "Help",
  },
  USER_MGR:{
    route: "/usermgr",
    name: "User Mgr",
  },
  CUSTOMER_MGR:{
    route: "/customermgr",
    name: "Customer Mgr",
  },
  MAT_SEL_GUIDELINES:{
    route: "/isoguidelines",
    name: "ISO 15156 Guidelines",
  },
  SLOTTED: {
    route: "/slotted",
    name: "Slotted Model",
  },
  PERFORATED: {
    route: "/perforated",
    name: "Pre-Perforated Model",
  },
};
