import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { UnitMeasures } from "../enums/unit-measures.enum";
import { UnitMeasureService } from "../../services/unit-measures.service";
import { Subscription } from "rxjs";

@Directive({
  selector: "[convertMultipleUnits]",
})
export class ConvertMultipleUnitsDirective implements OnInit, OnDestroy {
  @Input() unitMeasure: UnitMeasures;
  @Input() convertedControls: AbstractControl[];
  @Input() defaultControls: AbstractControl[];
  @Input() emitEventDefaultControl: boolean = true;
  @Input() unitFractionDigits?: number;
  @Input() object?: any;
  controlValueChanges: Subscription;

  constructor(
    private ngControl: NgControl,
    private unitMeasureService: UnitMeasureService
  ) {}

  ngOnInit(): void {
    this.configureControlValueChanges();
  }

  private configureControlValueChanges() {
    let defaultValue: number;
    let convertedValue: number;

    this.controlValueChanges = this.ngControl.control.valueChanges.subscribe((controlValue: any) => {
      if (
        this.convertedControls &&
        !this.convertedControls.some(
          (convertedControl) =>
            convertedControl.invalid && !convertedControl.errors?.required
        )
      ) {
        this.defaultControls.forEach((defaultControl, index) => {
          if (
            this.convertedControls[index].value ||
            this.convertedControls[index].value === 0
          ) {
            defaultValue = Number(defaultControl.value);

            convertedValue =
              defaultValue != null
                ? this.unitMeasureService.convertUnit(
                    defaultValue,
                    this.unitMeasure,
                    controlValue,
                    false,
                    this.unitFractionDigits ?? this.unitMeasureService.getUnitFractionDigits(
                      this.unitMeasure,
                      controlValue
                    ),
                    this.object
                      ? { waterDensity: this.object.waterDensity ? this.object.waterDensity[index].value : null, defaultUnit: this.object.defaultUnit}
                      : null
                  )
                : null;

            this.convertedControls[index].setValue(convertedValue, {
              emitEvent: false,
            });
            this.defaultControls[index].setValue(defaultValue, {
              emitEvent: this.emitEventDefaultControl,
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.controlValueChanges.unsubscribe();
  }
}
