<style>
  .menu {
    float: right;
  }

  .menu li {
    display: inline-block;
  }

  .input-group-addon {
    width: 60px;
  }

  .water-chemical {
    padding-top: 15px;
  }

  .water-chemical-row .input-group-addon {
    width: 81px;
  }

  #divConditions .input-group-addon {
    width: 55px;
  }
  
  #divInstallation .input-group-addon {
    width: 75px;
  }

  @media (min-width: 1367px) {
    #divInstallation label.input-xs {
      margin-bottom: 0px;
    }
  }

  #divInstallation label.input-xs {
    margin-top: 8px;
    padding-left: 0px;
  }

  label {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  .table > thead > tr > th {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  .label-dts {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  .no-spin::-webkit-inner-spin-button,
  .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 5px !important;
  }

  .no-spin {
    -moz-appearance: textfield !important;
  }

  .wcc {
    padding: 1px !important;
  }

  .label-water-chemical {
    padding-bottom: 35px;
  }

  tr th p {
    margin: 0px 0px 0px 0px;
  }

  .input-error {
    border: 2px solid rgba(255, 0, 0, 0.65);
    color: red;
  }

  #rangePopUp {
    color: red;
    text-decoration: underline red !important;
    cursor: pointer;
  }

  #validRangePopUp{
    color: blue; 
    text-decoration: underline blue !important;
    cursor: pointer;
  }

  .select-input {
    background: none;
    border: none;
    height: 100%;
    padding: 1px 7px;
  }

  .select-input:focus-visible {
    outline: none;
  }

  .select-input > option {
    font-weight: 400;
    line-height: 1;
    color: #777;
    text-align: center;
    background-color: #eaeaea;
  }

  sup {
    top: -0.8em;
  }

.supsub {
    display: inline-block;
    text-align: center;
}

.supsub sup,
.supsub sub {
    position: relative;
    display: block;
}

.supsub sub {
    top: .3em;
}

.water-chemical-row {
 display: flex; 
 justify-content: center;
}

::ng-deep
    .ng-select.unit-select.ng-select-single
    .ng-select-container
    .ng-value-container {
    justify-content: center;
  }

  ::ng-deep .ng-select.unit-select .ng-select-container {
    min-height: 100% !important;
  }

  ::ng-deep .ng-select.unit-select.ng-select-single .ng-select-container .ng-value-container {
    justify-content: center;
    height: 100%;
  }

  ::ng-deep .ng-select.unit-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ::ng-deep .ng-select.unit-select.ng-select-single .ng-select-container {
    height: 22px !important;
    border: none !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    color: #777 !important;
    background-color: #eaeaea !important;
  }

  ::ng-deep
    .ng-select.unit-select.ng-select-focused:not(.ng-select-opened)
    > .ng-select-container {
    border: none !important;
    box-shadow: none !important;
  }

  ::ng-deep
    .ng-select.unit-select
    .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected {
    color: #777 !important;
  }

  ::ng-deep .ng-select.unit-select.ng-select-disabled .ng-arrow-wrapper,
  ::ng-deep
    .ng-select.unit-select.ng-select-disabled.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    cursor: not-allowed;
  }

.conditions-fields-container {
  padding-right: 0 !important;
}

.thickness-0 {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}

.not-allowed {
  cursor: not-allowed;
}

.iPipeIDError {
  top: -15px; 
  position: relative;
  color: red;
  font-size: 10px;
}

.d-none{
  display: none;
}

  #oDissolvedSolids{
    cursor: not-allowed;
  }

  #corrosionRateInfo{
    padding-left: 0px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .range-cc-box {
    width: 305px; 
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    background: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 5;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .range-cc-box.free-dragging {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .range-container{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 0;
    width: 0;
  }

  #rangesModalTitle {
    text-align: center; 
    padding: 0; 
    margin: 0; 
    color: #666666; 
    font-size: 17px; 
    font-family: FrugiterBold, sans-serif;
  }

  #rangeTitleContainer{
    display: flex; 
    align-items: baseline; 
  }

  #alertIcon{
    display: flex; 
    margin:1em 1.6em; 
    width: 2em;
    height: 2em; 
    margin-right: 210px;
  }

  #rangeVal > div > p{
    margin: 0 0 5px;
    font-size: 13px;
  }

  @media screen and (min-width:610px) and (max-width:1280px) {
      #rangeCR {
        width: 505px;
      }
    }

    @media screen and (min-width:1280px) and (max-width:1920px) {
      #rangeCR {
        width: 505px;
      }
    }
</style>
<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">
        De Waard Sweet Corrosion Model for Oil & Gas - Carbon and Low Alloy
        Steel
      </h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
            }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.CORROSION_CLAS.name }}</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div [ngStyle]="{'border': !isPreSimulationFormValid ? 'none' : ''}" class="panel-heading">
            <div>
              <div style="font-size: 14px" *ngIf="simulationName == ''">
                Simulation
              </div>
              <div style="font-size: 14px" *ngIf="simulationName != ''">
                <strong>Simulation: {{ simulationName }}</strong>
              </div>
            </div>
            <app-pre-simulation #preSimulation></app-pre-simulation>
          </div>
          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="panel-body">
            <form [formGroup]="formCorrosionClas" (ngSubmit)="submitData()">
              <div class="row">
                <div class="col-md-3">
                  <div id="divConditions" class="panel-default">
                    <div
                      class="widget-head"
                      style="
                      background-color: #f5f5f5;
                      color: #666666;
                      font-size: 12px;
                      text-align: center;
                      padding: 1px 20px 1px;
                      margin-left: 5px;
                      margin-bottom: 25px;
                      border: none;
                      ">
                      <h3
                        style="
                        font-weight: bold;
                        font-family: FrugiterBold, sans-serif;
                        font-size: 20px;
                        margin-bottom: 11px;
                        margin-top: 11px;
                        ">
                        Conditions
                      </h3>
                    </div>
                    <div class="panel-body conditions-fields-container">
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs">Temperature</label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="iTemperature"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iTemperature.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iTemperature.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iTemperature"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iTemperatureError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Temperature
                                "
                                [selectedUnit]="
                                iTemperatureUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iTemperatureDefault
                                "
                                [unitControl]="iTemperatureUnit"
                                warningMessage
                                [warningContainerId]="'iTemperatureWarning'"
                              />
                              <span class="input-group-addon input-xs label-dts thickness-0"
                                >
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iTemperatureUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Temperature
                                  "
                                  [convertedControl]="
                                  CCformControl.iTemperature
                                  "
                                  [defaultControl]="
                                  CCformControl.iTemperatureDefault
                                  "
                                  [items]="TEMPERATURE_UNITS_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iTemperatureError" class="text-danger" style="top: -15px; position: relative"></error-message>
                            <warning-message
                            [id]="'iTemperatureWarning'"
                            [ngClass]="{'d-none': CCformControl.iTemperature.invalid }"
                            class="text-danger"
                            style="top: -15px; position: relative"
                            ></warning-message>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group" style="display: flex; align-items: center;">
                          <label class="col-sm-4 control-label input-xs" style="height: 100%;">Total Pressure<sup> [1]</sup></label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="iTotalPressure"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iTotalPressure.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iTotalPressure.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iTotalPressure"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iTotalPressureError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Pressure
                                "
                                [selectedUnit]="
                                iTotalPressureUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iTotalPressureDefault
                                "
                                [unitControl]="iTotalPressureUnit"
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0">
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iTotalPressureUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Pressure
                                  "
                                  [convertedControl]="
                                  CCformControl.iTotalPressure
                                  "
                                  [defaultControl]="
                                  CCformControl.iTotalPressureDefault
                                  "
                                  [items]="PRESSURE_UNITS_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iTotalPressureError"
                              class="text-danger" style="top: -15px; position:
                              relative"></error-message>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs"><span>CO<sub>2</sub>
                              in Gas</span></label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="iCO2perc"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iCO2perc.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iCO2perc.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iCO2perc"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iCO2percError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Percentage
                                "
                                [selectedUnit]="
                                iCO2percUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iCO2percDefault
                                "
                                [unitControl]="iCO2percUnit"
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0">
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iCO2percUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Percentage
                                  "
                                  [convertedControl]="
                                  CCformControl.iCO2perc
                                  "
                                  [defaultControl]="
                                  CCformControl.iCO2percDefault
                                  "
                                  [items]="PERCENTAGE_UNITS_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iCO2percError"
                              class="text-danger" style="top: -15px; position:
                              relative"></error-message>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs"><span>H<sub>2</sub>S
                              in Gas</span></label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="iH2Sperc"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iH2Sperc.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iH2Sperc.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iH2Sperc"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iH2SpercError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Percentage
                                "
                                [selectedUnit]="
                                iH2SpercUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iH2SpercDefault
                                "

                                [unitControl]="iH2SpercUnit"
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0">
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iH2SpercUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Percentage
                                  "
                                  [convertedControl]="
                                  CCformControl.iH2Sperc
                                  "
                                  [defaultControl]="
                                  CCformControl.iH2SpercDefault
                                  "
                                  [items]="PERCENTAGE_UNITS_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iH2SpercError"
                              class="text-danger" style="top: -15px; position:
                              relative"></error-message>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs">Temperature</label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="oAbsoluteTemp"
                                class="form-control input-xs no-spin"
                                formControlName="oAbsoluteTemp"
                                dlNumericInput
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0 not-allowed">K</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs"><span>pCO<sub>2</sub></span></label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-15">
                              <input
                                id="oPartialCO2pressure"
                                class="form-control input-xs no-spin"
                                formControlName="oPartialCO2pressure"
                                dlNumericInput
                                readonly
                                [ngClass]="{
                                'input-error':
                                CCformControl.oPartialCO2pressure.invalid
                                }"
                                warningMessage
                                [warningContainerId]="'oPartialCO2pressureWarning'"
                                />
                              <span id="oPartialCO2pressureUnit"
                                class="input-group-addon input-xs label-dts
                                thickness-0 not-allowed">bar</span>
                            </div>
                            <div
                              class="text-danger"
                              *ngIf="
                              CCformControl.oPartialCO2pressure.errors?.limit_pCo2
                              "
                              style="top: -10px; position: relative; font-size: 10px;"
                            >
                              The value must be less than {{unitMeasureService.convertUnit(200, pressureUnitMessure, iTotalPressureUnit.value, false, FRACTION_DIGITS.CORROSION_CLAS)}} {{unitMeasureService.getUnitSymbol(pressureUnitMessure, iTotalPressureUnit.value)}}
                            </div>
                            <warning-message
                            [id]="'oPartialCO2pressureWarning'"
                            [ngClass]="{'d-none': CCformControl.oPartialCO2pressure.errors?.limit_pCo2 }"
                            class="text-danger"
                            style="top: -15px; position: relative"
                            ></warning-message>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-4 control-label input-xs"><span>pH<sub>2</sub>S</span></label>
                          <div class="col-sm-8">
                            <div class="input-group xs-mb-10">
                              <input
                                id="oPartialH2Spressure"
                                class="form-control input-xs no-spin"
                                formControlName="oPartialH2Spressure"
                                dlNumericInput
                                readonly
                                [ngClass]="{
                                'input-error':
                                CCformControl.oPartialH2Spressure.invalid
                                }"
                                />
                              <span id="oPartialH2SpressureUnit"
                                class="input-group-addon input-xs label-dts
                                thickness-0 not-allowed">bar</span>
                            </div>
                            <div
                              class="text-danger"
                              *ngIf="
                              formCorrosionClas.controls.oPartialH2Spressure
                              .errors
                              "
                              style="top: -5px; position: relative; font-size:
                              10px;">
                              pCO2/pH2S must be greater than 50
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-9">
                  <div id="divInstallation" class="panel-default">
                    <div
                      class="widget-head"
                      style="
                      background-color: #f5f5f5;
                      color: #666666;
                      font-size: 12px;
                      text-align: center;
                      padding: 1px 20px 1px;
                      border: none;
                      ">
                      <h3
                        style="
                        font-weight: bold;
                        font-size: 20px;
                        font-family: FrugiterBold, sans-serif;
                        margin-bottom: 11px;
                        margin-top: 11px;
                        ">
                        Tubular &amp; Production
                      </h3>
                    </div>
                    <div class="panel-body">
                      <div class="form-group" style="text-align: center">
                        <div class="am-radio inline">
                          <input
                            id="rdNormalizado"
                            type="radio"
                            checked=""
                            name="radioOption"
                            class="input-xs"
                            formControlName="radioOption"
                            value="normalizado"
                            />
                          <label for="rdNormalizado" class="input-xs">Normalized
                            or As Rolled</label>
                        </div>
                        <div class="am-radio inline">
                          <input
                            id="rdQT"
                            type="radio"
                            checked=""
                            name="radioOption"
                            class="input-xs"
                            formControlName="radioOption"
                            value="quenched"
                            />
                          <label for="rdQT" class="input-xs">Quenched + Tempered</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="input-xs">Pipe Outer Diameter</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iPipeOD"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iPipeOD.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iPipeOD.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iPipeOD"
                              autocomplete="off"
                              errorMessage
                              [errorContainerId]="'iPipeODError'"
                              interchangeableUnit
                              [unitMeasure]="
                              unitMeasureService.unitMeasures.Length
                              "
                              [selectedUnit]="
                              iPipeODUnit.value
                              "
                              [defaultControl]="
                              CCformControl.iPipeODDefault
                              "
                              [unitControl]="iPipeODUnit"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0">
                              <ng-select
                                class="unit-select"
                                [formControl]="iPipeODUnit"
                                convertUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Length
                                "
                                [convertedControl]="
                                CCformControl.iPipeOD
                                "
                                [defaultControl]="
                                CCformControl.iPipeODDefault
                                "
                                [items]="LENGTH_UNITS_ARRAY"
                                bindLabel="symbol"
                                bindValue="enum"
                                [clearable]="false"
                                [searchable]="false">
                              </ng-select>
                            </span>
                          </div>
                          <error-message id="iPipeODError" class="text-danger"
                            style="top: -15px; position: relative"></error-message>
                          <div *ngIf="CCformControl.iPipeOD.errors?.invalid"
                            class="iPipeIDError">{{CCformControl.iPipeOD.errors?.errorMessage}}</div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Pipe Wall Thickness</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iPipeWT"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iPipeWT.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iPipeWT.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iPipeWT"
                              autocomplete="off"
                              errorMessage
                              [errorContainerId]="'iPipeWTError'"
                              interchangeableUnit
                              [unitMeasure]="
                              unitMeasureService.unitMeasures.Length
                              "
                              [selectedUnit]="
                              iPipeWTUnit.value
                              "
                              [defaultControl]="
                              CCformControl.iPipeWTDefault
                              "
                              [unitControl]="iPipeWTUnit"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0">
                              <ng-select
                                class="unit-select"
                                [formControl]="iPipeWTUnit"
                                convertUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Length
                                "
                                [convertedControl]="
                                CCformControl.iPipeWT
                                "
                                [defaultControl]="
                                CCformControl.iPipeWTDefault
                                "
                                [items]="LENGTH_UNITS_ARRAY"
                                bindLabel="symbol"
                                bindValue="enum"
                                [clearable]="false"
                                [searchable]="false">
                              </ng-select>
                            </span>
                          </div>
                          <error-message id="iPipeWTError" class="text-danger"
                            style="top: -15px; position: relative"></error-message>
                          <div *ngIf="CCformControl.iPipeWT.errors?.invalid"
                            class="iPipeIDError">{{CCformControl.iPipeWT.errors?.errorMessage}}</div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Pipe Inner Diameter</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iPipeID"
                              class="form-control input-xs no-spin"
                              formControlName="iPipeID"
                              dlNumericInput
                              />
                            <span id="iPipeIDUnit" class="input-group-addon
                              input-xs label-dts thickness-0 not-allowed">mm</span>
                          </div>
                          <div *ngIf="CCformControl.iPipeID.errors?.invalid"
                            class="iPipeIDError">{{CCformControl.iPipeID.errors?.errorMessage}}</div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Carbon (C)</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iCperc"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iCperc.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iCperc.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iCperc"
                              autocomplete="off"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">%</span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iCperc.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iCperc.errors
                            ?.limitCarbonRange &&
                            !formCorrosionClas.controls.iCperc.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            The value entered must be between 0 % and 0.5 %
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="input-xs">Chromium (Cr)</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iCrperc"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iCrperc.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iCrperc.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iCrperc"
                              autocomplete="off"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">%</span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iCrperc.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iCrperc.errors
                            ?.limitChromiumRange &&
                            !formCorrosionClas.controls.iCrperc.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            The value entered must be between 0 % and 1.2 %
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Oil Production</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iOilProduction"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iOilProduction.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iOilProduction.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iOilProduction"
                              autocomplete="off"
                              errorMessage
                              [errorContainerId]="'iOilProductionError'"
                              interchangeableUnit
                              [unitMeasure]="
                              unitMeasureService.unitMeasures.Volume
                              "
                              [selectedUnit]="
                              iOilProductionUnit.value
                              "
                              [defaultControl]="
                              CCformControl.iOilProductionDefault
                              "
                              [unitControl]="iOilProductionUnit"
                              [controlDisabledUnit]="false"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0">
                              <ng-select
                                class="unit-select"
                                [formControl]="iOilProductionUnit"
                                convertUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Volume
                                "
                                [convertedControl]="
                                CCformControl.iOilProduction
                                "
                                [defaultControl]="
                                CCformControl.iOilProductionDefault
                                "
                                [items]="VOLUME_UNITS_ARRAY"
                                bindLabel="symbol"
                                bindValue="enum"
                                [clearable]="false"
                                [searchable]="false">
                              </ng-select>
                            </span>
                          </div>
                          <error-message id="iOilProductionError"
                            class="text-danger" style="top: -15px; position:
                            relative"></error-message>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Water Produ​ction</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iWaterProduction"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iWaterProduction.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iWaterProduction.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iWaterProduction"
                              autocomplete="off"
                              errorMessage
                              [errorContainerId]="'iWaterProductionError'"
                              interchangeableUnit
                              [unitMeasure]="
                              unitMeasureService.unitMeasures.Volume
                              "
                              [selectedUnit]="
                              iWaterProductionUnit.value
                              "
                              [defaultControl]="
                              CCformControl.iWaterProductionDefault
                              "
                              [unitControl]="iWaterProductionUnit"
                              [controlDisabledUnit]="false"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0">
                              <ng-select
                                class="unit-select"
                                [formControl]="iWaterProductionUnit"
                                convertUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Volume
                                "
                                [convertedControl]="
                                CCformControl.iWaterProduction
                                "
                                [defaultControl]="
                                CCformControl.iWaterProductionDefault
                                "
                                [items]="VOLUME_UNITS_ARRAY"
                                bindLabel="symbol"
                                bindValue="enum"
                                [clearable]="false"
                                [searchable]="false">
                              </ng-select>
                            </span>
                          </div>
                          <error-message id="iWaterProductionError"
                            class="text-danger" style="top: -15px; position:
                            relative"></error-message>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs">Watercut</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iWatercut"
                              class="form-control input-xs no-spin"
                              formControlName="iWatercut"
                              dlNumericInput
                              readonly
                              [ngClass]="{
                              'input-error': CCformControl.iWatercut.invalid
                              }"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="input-xs" style="padding-bottom: 35px">Deviation
                            at Considered Point</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iDesviacion"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iDesviacion.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iDesviacion.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iDesviacion"
                              autocomplete="off"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">°</span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iDesviacion.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iDesviacion.errors
                            ?.limitDesviationRange &&
                            !formCorrosionClas.controls.iDesviacion.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            The value entered must be between 0 ° and 30 °
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs" style="padding-bottom: 35px">API
                            Oil Gravity</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iAPIoilGravity"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iAPIoilGravity.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iAPIoilGravity.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iAPIoilGravity"
                              autocomplete="off"
                              errorMessage
                              [errorContainerId]="'iAPIoilGravityError'"
                              interchangeableUnit
                              [unitMeasure]="
                              unitMeasureService.unitMeasures.APIGravity
                              "
                              [selectedUnit]="
                              iAPIoilGravityUnit.value
                              "
                              [defaultControl]="
                              CCformControl.iAPIoilGravityDefault
                              "
                              [unitControl]="iAPIoilGravityUnit"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0">
                              <ng-select
                                class="unit-select"
                                [formControl]="iAPIoilGravityUnit"
                                convertUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.APIGravity
                                "
                                [convertedControl]="
                                CCformControl.iAPIoilGravity
                                "
                                [defaultControl]="
                                CCformControl.iAPIoilGravityDefault
                                "
                                [items]="API_GRAVITY_UNITS_ARRAY"
                                bindLabel="symbol"
                                bindValue="enum"
                                [clearable]="false"
                                [searchable]="false">
                              </ng-select>
                            </span>
                          </div>
                          <error-message id="iAPIoilGravityError"
                            class="text-danger" style="top: -15px; position:
                            relative"></error-message>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs" style="padding-bottom: 35px">Liquid
                            Velocity U</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iLiquidVelocity"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iLiquidVelocity.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iLiquidVelocity.invalid
                              }"
                              class="form-control input-xs no-spin"
                              formControlName="iLiquidVelocity"
                              autocomplete="off"
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">m/sec</span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iLiquidVelocity.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iLiquidVelocity.errors
                            ?.limitVelocityURange &&
                            !formCorrosionClas.controls.iLiquidVelocity.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            The value entered must be between 0.1 m/sec and 13
                            m/sec
                          </div>
                        </div>
                        <div class="col-md-3">
                          <label class="input-xs" style="padding-bottom: 35px">Maximum
                            Water in Oil, Wbreak
                          </label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="oWbreak"
                              class="form-control input-xs no-spin"
                              formControlName="oWbreak"
                              dlNumericInput
                              />
                            <span class="input-group-addon input-xs label-dts
                              thickness-0 not-allowed">fraction</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="col-md-12" style="padding-left: 7px;">
                    <p><sup>[1] </sup>If Bubble Point Pressure < Total Pressure, use Bubble Point Pressure</p>
                  </div>
              </div>
                <div class="col-md-12">
                  <div id="div5" class="panel-default">
                    <div
                      id="pnlChemicalComposition"
                      class="widget-head"
                      style="
                      background-color: #f5f5f5;
                      color: #666666;
                      font-size: 12px;
                      text-align: center;
                      padding: 1px 20px 1px;
                      border: none;
                      margin-left: 5px;
                      ">
                      <h3
                        style="
                        font-weight: bold;
                        font-size: 20px;
                        font-family: FrugiterBold, sans-serif;
                        margin-bottom: 11px;
                        margin-top: 11px;
                        ">
                        Water Chemical Composition (mg/lts)
                      </h3>
                    </div>
                    <div class="panel-body" style="padding-top: 0px">
                      <div class="row water-chemical-row">
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Na<sup>+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iNa"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iNa.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iNa.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iNa"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iNa.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">K<sup>+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iK"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iK.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iK.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iK"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iK.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Ca<sup>2+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iCa"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iCa.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iCa.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iCa"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iCa.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Mg<sup>2+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iMg"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iMg.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iMg.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iMg"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iMg.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Ba<sup>2+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iBa"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iBa.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iBa.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iBa"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iBa.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Sr<sup>2+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iSr"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iSr.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iSr.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iSr"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iSr.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">HCO<sub>3</sub><sup>-</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iHCO3"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iHCO3.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iHCO3.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iHCO3"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iHCO3.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">SO<sub>4</sub><sup>2-</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iSO42"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iSO42.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iSO42.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iSO42"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iSO42.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Fe<sup>2+</sup></label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iFe"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iFe.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iFe.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iFe"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iFe.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                        <div class="col-md-1 water-chemical">
                          <label class="input-xs label-water-chemical">Acetate</label>
                          <div class="input-group xs-mb-15">
                            <input
                              id="iAcetato"
                              [ngClass]="{
                              'input-error':
                              (CCformControl.iAcetato.touched ||
                              CCformControl.dirty) &&
                              CCformControl.iAcetato.invalid
                              }"
                              class="form-control input-xs no-spin wcc"
                              formControlName="iAcetato"
                              />
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                            formCorrosionClas.controls.iAcetato.errors
                            ?.pattern
                            "
                            style="top: -15px; position: relative; font-size:
                            10px;">
                            {{ERROR_MESSAGES.pattern}}
                          </div>
                        </div>
                      </div>
                      <div class="row water-chemical-row">
                        <div class="col-md-4 thickness-0">
                          <div class="col-md-6">
                            <label class="input-xs">Water Density</label>
                            <div class="input-group xs-mb-15" style="width: 100%;">
                              <input
                                id="iWaterDensity"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iWaterDensity.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iWaterDensity.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iWaterDensity"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iWaterDensityError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Concentration
                                "
                                [selectedUnit]="
                                iWaterDensityUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iWaterDensityDefault
                                "
                                [object]="{defaultUnit:
                                unitMeasureService.concentrationUnits.KilogramsCubicDecimeter}"
                                [unitControl]="iWaterDensityUnit"
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0">
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iWaterDensityUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Concentration
                                  "
                                  [convertedControl]="
                                  CCformControl.iWaterDensity
                                  "
                                  [defaultControl]="
                                  CCformControl.iWaterDensityDefault
                                  "
                                  [object]="{defaultUnit:
                                  unitMeasureService.concentrationUnits.KilogramsCubicDecimeter}"
                                  [items]="CONCENTRATION_UNITS_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iWaterDensityError"
                              class="text-danger" style="top: -15px; position:
                              relative"></error-message>
                          </div>
                          <div class="col-md-6">
                            <label class="input-xs">Cl<sup>-</sup></label>
                            <div class="input-group xs-mb-15" style="width: 100%;">
                              <input
                                id="iCl"
                                [ngClass]="{
                                'input-error':
                                (CCformControl.iCl.touched ||
                                CCformControl.dirty) &&
                                CCformControl.iCl.invalid
                                }"
                                class="form-control input-xs no-spin"
                                formControlName="iCl"
                                autocomplete="off"
                                errorMessage
                                [errorContainerId]="'iClError'"
                                interchangeableUnit
                                [unitMeasure]="
                                unitMeasureService.unitMeasures.Concentration
                                "
                                [selectedUnit]="
                                iClUnit.value
                                "
                                [defaultControl]="
                                CCformControl.iClDefault
                                "
                                [object]="{waterDensity:
                                CCformControl.iWaterDensityDefault.value,
                                defaultUnit:
                                unitMeasureService.concentrationUnits.MilligramsLiter}"
                                [unitControl]="iClUnit"
                                />
                              <span class="input-group-addon input-xs label-dts
                                thickness-0">
                                <ng-select
                                  class="unit-select"
                                  [formControl]="iClUnit"
                                  convertUnit
                                  [unitMeasure]="
                                  unitMeasureService.unitMeasures.Concentration
                                  "
                                  [convertedControl]="
                                  CCformControl.iCl
                                  "
                                  [defaultControl]="
                                  CCformControl.iClDefault
                                  "
                                  [object]="{waterDensity:
                                  CCformControl.iWaterDensityDefault.value,
                                  defaultUnit:
                                  unitMeasureService.concentrationUnits.MilligramsLiter}"
                                  [items]="CONCENTRATION_UNITS_CL_ARRAY"
                                  bindLabel="symbol"
                                  bindValue="enum"
                                  [clearable]="false"
                                  [searchable]="false">
                                </ng-select>
                              </span>
                            </div>
                            <error-message id="iClError" class="text-danger"
                              style="top: -15px; position: relative"></error-message>
                          </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-2">
                          <label class="input-xs">Dissolved Solids</label>
                          <div class="input-group xs-mb-15" style="width:
                            100%;">
                            <input
                              id="oDissolvedSolids"
                              class="form-control input-xs no-spin wcc"
                              formControlName="oDissolvedSolids"
                              dlNumericInput
                              readonly
                              [ngClass]="{
                              'input-error':
                              CCformControl.oDissolvedSolids.invalid
                              }"
                              warningMessage
                              [warningContainerId]="'oDissolvedSolidsWarning'"
                            />
                            <div
                              *ngIf="
                              formCorrosionClas.get('oDissolvedSolids').errors
                              ">
                            </div>
                          </div>
                              <warning-message
                              [id]="'oDissolvedSolidsWarning'"
                              [ngClass]="{'d-none': CCformControl.oDissolvedSolids.invalid}"
                              class="text-danger"
                              style="top: -15px; position: relative"
                              ></warning-message>
                          </div>
                      </div>
                    </div>
                    <div
                      class="panel-body"
                      style="padding-top: 0px; padding-bottom: 0px">
                      <div class="form-group">
                        <div
                          class="content"
                          style="
                          display: flex;
                          flex-wrap: wrap;
                          justify-content: flex-end;
                          ">
                          <p
                            *ngIf="hasFormError()"
                            class="text-danger h5"
                            style="padding-right: 5px">
                            All fields are required and values need to be within
                            the allowed
                            <span id="rangePopUp" (click)="rangePopUp()"><b>range</b></span>
                            to submit a simulation, please check data.
                          </p>
                          <p
                            *ngIf="!hasFormError()"
                            style="padding-right: 50px; padding-top: 6px;">
                            <span id="validRangePopUp" (click)="rangePopUp()"><b>Range</b></span>
                          </p>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="hasFormError()">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!-- <div class="row"> -->
            <div *ngIf="isOutputVisible">
              <div class="col-sm-12" style="padding-left: 0px">
                <div id="div6" class="panel-default">
                  <div
                    id="Div7"
                    class="panel-heading"
                    style="
                    color: #666666;
                    text-align: left;
                    padding: 10px 20px 10px 0px;
                    border: none;
                    margin-left: 5px;
                    ">
                    <h3
                      class="table-title global-title-04"
                      style="
                      font-weight: bold;
                      font-size: 28px;
                      font-family: FrutigerBold, sans-serif;
                      ">
                      Calculation results
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="isOutputVisibleToAdmin">
                <div class="main-content" style="padding-left: 0px">
                  <!--ini-->
                  <div
                    class="col-sm-6 xs-pr-5"
                    style="
                    border: none;
                    padding: 0px 0px 0px 0px;
                    margin-left: 0px;
                    ">
                    <div
                      class="widget widget-fullwidth widget-small"
                      style="
                      padding-top: 1px;
                      padding-bottom: 1px;
                      margin-left: 20px;
                      ">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Ph Values
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="35%" class="">
                              pH Pure H<sub>2</sub>O + CO<sub>2</sub>
                            </th>
                            <th class="text-right">
                              {{
                              outputValues.pH_CO2 | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">pHCO<sub>2</sub></th>

                            <th width="5%" style="border: none"></th>

                            <th width="35%" class="">pH Solution</th>
                            <th class="text-right">
                              {{
                              outputValues.pH_Solution
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">pH</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <!--fin-->
                  <div
                    class="col-sm-6 xs-pl-5"
                    style="padding: 0px 0px 0px 0px">
                    <div class="widget widget-fullwidth widget-small">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Baseline Velocity
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="35%" class="">Reaction At Surface</th>
                            <th class="text-right">
                              {{
                              outputValues.surfaceReactionSpeed
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>

                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Transport</th>
                            <th class="text-right">
                              {{
                              outputValues.transport | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <div class="row" *ngIf="isOutputVisibleToAdmin">
                <div class="main-content" style="padding-left: 0px">
                  <div
                    class="col-sm-6 xs-pr-5"
                    style="padding: 0px 0px 0px 0px">
                    <div
                      class="widget widget-fullwidth widget-small"
                      style="padding-left: 0px; margin-left: 20px">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Protection Factors
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="45%" style="font-weight: bold" class="">
                              Oil Protection Factor
                            </th>
                            <th class="text-right"></th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" style="font-weight: bold" class="">
                              Scale Protection Factor
                            </th>
                            <th class="text-right"></th>
                            <th class="text-right"></th>
                          </tr>

                          <tr>
                            <th width="45%" class="">Foil</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactor
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Fscale</th>
                            <th class="text-right">
                              {{
                              outputValues.scaleProtectionFactor
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                          </tr>
                          <tr>
                            <!-- <th width="45%" class="">Foil Calc</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactorCalc
                              | safeNumber : 1 : 3
                              }}
                            </th> -->
                            <th class="text-right" style="border: none"></th>
                            <th class="text-right" style="border: none"></th>
                            <th class="text-right" style="border: none"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Tsc</th>
                            <th class="text-right">
                              {{
                              outputValues.temperatureScale
                              | safeNumber : "en" : 1 : 2
                              }}
                            </th>
                            <th class="text-right">(°C)</th>
                          </tr>
                          <!-- <tr>
                            <th width="45%" class="">Foil RestrWC</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactorRestrWC
                              | safeNumber : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                          </tr> -->
                        </thead>
                      </table>
                    </div>
                  </div>
                  <!--ini-->
                  <div
                    class="col-sm-6 xs-pl-5"
                    style="padding: 0px 0px 0px 0px">
                    <div class="widget widget-fullwidth widget-small">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Steel Composition Effect
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="35%" class="">Chromium Factor</th>
                            <th class="text-right">
                              {{
                              outputValues.cr_Factor | safeNumber : "en" : 1 : 3
                              }}
                            </th>

                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Carbon Factor</th>
                            <th class="text-right">
                              {{
                              outputValues.c_Factor | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <!--fin-->
                </div>
              </div>
              <div class="row" *ngIf="isOutputVisibleToUser">
                <div class="main-content" style="padding-left: 0px">
                  <!--ini-->
                  <div
                    class="col-sm-6 xs-pr-5"
                    style="padding: 0px 0px 0px 0px">
                    <div
                      class="widget widget-fullwidth widget-small"
                      style="padding-left: 0px; margin-left: 20px">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Protection Factors
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="45%" style="font-weight: bold" class="">
                              Oil Protection Factor
                            </th>
                            <th class="text-right"></th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" style="font-weight: bold" class="">
                              Scale Protection Factor
                            </th>
                            <th class="text-right"></th>
                            <th class="text-right"></th>
                          </tr>

                          <tr>
                            <th width="45%" class="">Foil</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactor
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Fscale</th>
                            <th class="text-right">
                              {{
                              outputValues.scaleProtectionFactor
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                          </tr>
                          <tr>
                            <!-- <th width="45%" class="">Foil Calc</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactorCalc
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th> -->
                            <th class="text-right" style="border: none"></th>
                            <th class="text-right" style="border: none"></th>
                            <th class="text-right" style="border: none"></th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Tsc</th>
                            <th class="text-right">
                              {{
                              outputValues.temperatureScale
                              | safeNumber : "en" : 1 : 2
                              }}
                            </th>
                            <th class="text-right">(°C)</th>
                          </tr>
                          <!-- <tr>
                            <th width="45%" class="">Foil RestrWC</th>
                            <th class="text-right">
                              {{
                              outputValues.oilProtectionFactorRestrWC
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                            <th width="5%" style="border: none"></th>
                          </tr> -->
                        </thead>
                      </table>
                    </div>
                  </div>
                  <!--fin-->
                  <div
                    class="col-sm-6 xs-pl-5"
                    style="padding: 0px 0px 0px 0px">
                    <div class="widget widget-fullwidth widget-small">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Baseline Velocity
                        </div>
                      </div>
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="35%" class="">Reaction At Surface</th>
                            <th class="text-right">
                              {{
                              outputValues.surfaceReactionSpeed
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>

                            <th width="5%" style="border: none"></th>
                            <th width="35%" class="">Transport</th>
                            <th class="text-right">
                              {{
                              outputValues.transport | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="isOutputVisibleToAdmin">
                <div
                  class="col-sm-12"
                  style="padding: 0px 0px 0px 0px; margin-left: 5px">
                  <div id="div6" class="panel-default">
                    <div
                      id="Div99"
                      class="panel-heading"
                      style="
                      color: #666666;
                      width: auto;
                      text-align: left;
                      border: none;
                      ">
                      <h3
                        class="table-title global-title-04"
                        style="
                        font-weight: bold;
                        font-size: 28px;
                        font-family: FrutigerBold, sans-serif;
                        ">
                        Correction Considering H<sub>2</sub>S
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="main-content" style="padding-left: 0px">
                  <div class="col-sm-12" style="padding: 0px 0px 0px 0px">
                    <div
                      class="widget widget-fullwidth widget-small"
                      style="
                      padding-bottom: 0px;
                      padding-left: 0px;
                      margin-left: 20px;
                      ">
                      <div
                        class="widget-head"
                        style="
                        background-color: #f5f5f5;
                        margin-bottom: 1px;
                        margin-top: 1px;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        ">
                        <div
                          class="title"
                          style="
                          font-size: 20px;
                          color: #666666;
                          font-family: FrugiterBold, sans-serif;
                          padding-top: 11px;
                          padding-bottom: 11px;
                          ">
                          Values
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6" style="padding: 0px 5px 0px 20px">
                    <div class="widget widget-fullwidth widget-small">
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="40%">[H<sub>2</sub>S]</th>
                            <th class="text-right">
                              {{
                              outputValues.correctionH2S_H2Sppm
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">ppm</th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%">[HS<sup>-</sup>]</th>
                            <th class="text-right">
                              {{
                              outputValues.correctionH2S_HSppm
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">ppm</th>
                          </tr>
                          <tr>
                            <th>FH<sub>2</sub>S</th>
                            <th class="text-right">
                              {{
                              outputValues.correctionH2S_FH2S
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right"></th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6" style="padding: 0px 0px 0px 5px">
                    <div class="widget widget-fullwidth widget-small">
                      <table class="table table-fw-widget">
                        <thead>
                          <tr>
                            <th width="35%">[H<sub>2</sub>S]</th>
                            <th class="text-right">
                              {{
                              outputValues.correctionH2S_H2Smoll
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">mol/liter</th>
                            <th width="5%" style="border: none"></th>
                            <th width="35%">[HS<sup>-</sup>]</th>
                            <th class="text-right">
                              {{
                              outputValues.correctionH2S_HSmoll
                              | safeNumber : "en" : 1 : 3
                              }}
                            </th>
                            <th class="text-right">mol/liter</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <div id="div6" class="panel-default">
                    <div
                      id="Div8"
                      class="panel-heading"
                      style="
                      background-color: rgba(0, 153, 0, 1);
                      color: white;
                      font-size: 12px;
                      width: auto;
                      text-align: center;
                      padding: 10px 20px 10px 0px;
                      margin-left: 5px;
                      ">
                      <h3
                        style="
                        font-weight: normal;
                        font-family: FrugiterBold, sans-serif;
                        ">
                        Corrosion Rates (mm/year)
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="main-content" id="corrosionRateInfo">
                  <div class="col-sm-6 xs-pr-5" style="padding: 0px 5px 0px 20px">
                    <div class="widget widget-fullwidth widget-small">
                      <table class="table table-fw-widget">
                        <thead>
                          <tr style="height: 65px">
                            <th style="width: 40%">
                              <p>Corrosion CLAS</p>
                            </th>
                            <th class="text-right">
                                {{
                                  outputValues.corrosionRates_CorrectedByCO2andH2S | safeNumber : "en" : 1 : 2
                                }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div style="
                    padding: 20px 20px 15px;
                    border-bottom: 1px solid transparent;
                    border-top-right-radius: -1;
                    border-top-left-radius: -1;">
                    Corrosion CLAS = Baseline * Fscale Carbonates * FOil
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="rangeCC" class="boundary range-container">
        <div id="rangeCR" class="range-cc-box" appFreeDragging appFreeDraggingHandle>
            <button type="button" class="close" (click)="closeModal()">&times;</button>
            <div id="rangeTitleContainer">
                <p id="rangesModalTitle"><b> Valid Ranges </b></p>
                <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
                  <div class="swal2-icon-content" style="font-size: 1.75em;">!</div>
                </div>
            </div>
            <div class="row" id="rangeVal"> 
                <div class="col-md-6">
                  <p>Temperature 0 - 160 °C   </p>
                  <p>Total Pressure 0 - 1000 bar  </p>
                  <p>CO<sub>2</sub> in Gas 0 - 100 % </p>
                  <p>H<sub>2</sub>S in Gas 0 - 100 % </p>
                  <p>Pipe Outer Diameter 25 - 800 mm </p>
                  <p>Pipe Wall Thickness 3 - 50 mm </p>
                  <p>Carbon 0 - 0.5 % </p>
                  <p>Chromium 0 - 1.2 % </p>
                  <p>Oil Production 0 - 25000 m<sup>3</sup>/day </p>
                  <p>Water Production 1 - 25000 m<sup>3</sup>/day </p>
                  <p>Deviation at Considered Point 0 - 30 °</p>
                  <p>API Oil Gravity 20 - 50 °</p>
                  <p>Liquid Velocity U 0.1 - 13 m/sec </p>
                </div>
                <div class="col-md-6">
                  <p>Na<sup>+</sup> >= 0 </p>
                  <p>K<sup>+</sup> >= 0 </p>
                  <p>Ca<sup>2+</sup> >= 0 </p>
                  <p>Mg<sup>2+</sup> >= 0 </p>
                  <p>Ba<sup>2+</sup> >= 0 </p>
                  <p>Sr<sup>2+</sup> >= 0 </p>
                  <p>HCO<sub>3</sub><sup>-</sup> >= 0 </p>
                  <p>SO<sub>4</sub><sup>2-</sup> >= 0 </p>
                  <p>Fe<sup>2+</sup> >= 0 </p>
                  <p>Acetate >= 0 </p>
                  <p>Water Density 0.9 - 1.1 kg/dm<sup>3</sup></p>
                  <p>Cl<sup>-</sup> >= 0</p>
                  <p>Dissolved Solids >= 0 </p>
                </div>
            </div>
        </div>
      </div>
    </div>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="ball-clip-rotate"
      [fullScreen]="true"><p style="color: white">Loading...</p></ngx-spinner>
    <script>
      $(function () {
        $("[data-tooltip]").tooltip();
      });
    </script>
  </div>
</div>
