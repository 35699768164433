import { Injectable } from "@angular/core";
import { API_GRAVITY_UNITS } from "../shared/consts/api-gravity-units";
import { CONCENTRATION_UNITS } from "../shared/consts/concentration-units";
import { LENGTH_UNITS } from "../shared/consts/length-units";
import { PERCENTAGE_UNITS } from "../shared/consts/percentage-units";
import { PRESSURE_UNITS } from "../shared/consts/pressure-units";
import { TEMPERATURE_UNITS } from "../shared/consts/temperature-units";
import { VELOCITY_UNITS } from "../shared/consts/velocity-units";
import { VOLUME_UNITS } from "../shared/consts/volume-units";
import { APIGravityUnits } from "../shared/enums/api-gravity-units.enum";
import { ConcentrationUnits } from "../shared/enums/concentration-units.enum";
import { LengthUnits } from "../shared/enums/length-units.enum";
import { PercentageUnits } from "../shared/enums/percentage-units.enum";
import { PressureUnits } from "../shared/enums/pressure-units.enum";
import { TemperatureUnits } from "../shared/enums/temperature-units.enum";
import { UnitMeasures } from "../shared/enums/unit-measures.enum";
import { VelocityUnits } from "../shared/enums/velocity-units.enum";
import { VolumeUnits } from "../shared/enums/volume-units.enum";

@Injectable({
  providedIn: "root",
})
export class UnitMeasureService {
  constructor() {}

  convertUnit(
    value: number,
    unitMeasure: UnitMeasures,
    unit: any,
    toDefaultValue: boolean,
    fractionDigits: number,
    object?: any
  ): number {
    let convertedValue: number;

    if (!value && value !== 0) return null;

    switch (unitMeasure) {
      case UnitMeasures.Temperature:
        convertedValue = this.convertTemperature(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
      case UnitMeasures.Pressure:
        convertedValue = this.convertPressure(
          unit,
          value,
          toDefaultValue,
          fractionDigits,
          object?.defaultUnit
        );
        break;
      case UnitMeasures.Percentage:
        convertedValue = this.convertPercentage(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
      case UnitMeasures.Length:
        convertedValue = this.convertLength(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
      case UnitMeasures.Volume:
        convertedValue = this.convertVolume(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
      case UnitMeasures.APIGravity:
        convertedValue = this.convertAPIGravity(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
      case UnitMeasures.Concentration:
        convertedValue = this.convertConcentration(
          unit,
          value,
          toDefaultValue,
          fractionDigits,
          object?.waterDensity,
          object?.defaultUnit,
          object?.molecularWeight,
          object?.valence
        );
        break;
      case UnitMeasures.Velocity:
        convertedValue = this.convertVelocity(
          unit,
          value,
          toDefaultValue,
          fractionDigits
        );
        break;
    }

    return convertedValue;
  }

  getUnitSymbol(unitMeasure: UnitMeasures, unit: any): string {
    let symbol: string;
    switch (unitMeasure) {
      case UnitMeasures.Temperature:
        symbol = this.getTemperatureSymbol(unit);
        break;
      case UnitMeasures.Pressure:
        symbol = this.getPressureSymbol(unit);
        break;
      case UnitMeasures.Percentage:
        symbol = this.getPercentageSymbol(unit);
        break;
      case UnitMeasures.Length:
        symbol = this.getLengthSymbol(unit);
        break;
      case UnitMeasures.Volume:
        symbol = this.getVolumeSymbol(unit);
        break;
      case UnitMeasures.APIGravity:
        symbol = this.getAPIGravitySymbol(unit);
        break;
      case UnitMeasures.Concentration:
        symbol = this.getConcentrationSymbol(unit);
        break;
      case UnitMeasures.Velocity:
        symbol = this.getVelocitySymbol(unit);
        break;
    }
    return symbol;
  }

  getUnitFractionDigits(unitMeasure: UnitMeasures, unit: any): number {
    let fractionDigits: number;
    switch (unitMeasure) {
      case UnitMeasures.Temperature:
        fractionDigits = this.getTemperatureFractionDigits(unit);
        break;
      case UnitMeasures.Pressure:
        fractionDigits = this.getPressureFractionDigits(unit);
        break;
      case UnitMeasures.Percentage:
        fractionDigits = this.getPercentageFractionDigits(unit);
        break;
      case UnitMeasures.Length:
        fractionDigits = this.getLengthFractionDigits(unit);
        break;
      case UnitMeasures.Volume:
        fractionDigits = this.getVolumeFractionDigits(unit);
        break;
      case UnitMeasures.APIGravity:
        fractionDigits = this.getAPIGravityFractionDigits(unit);
        break;
      case UnitMeasures.Concentration:
        fractionDigits = this.getConcentrationFractionDigits(unit);
        break;
      case UnitMeasures.Velocity:
        fractionDigits = this.getVelocityFractionDigits(unit);
        break;
    }
    return fractionDigits;
  }

  private convertTemperature(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case TemperatureUnits.Celsius:
        value = value;
        break;
      case TemperatureUnits.Kelvin:
        value = toDefaultValue ? value - 273.15 : value + 273.15;
        break;
      case TemperatureUnits.Fahrenheit:
        value = toDefaultValue ? ((value - 32) * 5) / 9 : (value * 9) / 5 + 32;
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertPressure(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number,
    defaultUnit = PressureUnits.Bar
  ): number {
    switch (Number(defaultUnit)) {
      case PressureUnits.Bar:
        value = this.convertPressureBar(unit, value, toDefaultValue);
        break;
      case PressureUnits.Kpa:
        value = this.convertPressureKpa(unit, value, toDefaultValue);
        break;
      case PressureUnits.Ksi:
        value = this.convertPressureKsi(unit, value, toDefaultValue);
        break;
    }

    return Number(value.toFixed(fractionDigits));
  }

  private convertPressureBar(
    unit: any,
    value: number,
    toDefaultValue: boolean
  ): number {
    switch (Number(unit)) {
      case PressureUnits.Bar:
        value = value;
        break;
      case PressureUnits.Psi:
        value = toDefaultValue ? value / 14.5038 : value * 14.5038;
        break;
      case PressureUnits.Atm:
        value = toDefaultValue ? value / 0.986923 : value * 0.986923;
        break;
    }
    return value;
  }

  private convertPressureKpa(
    unit: any,
    value: number,
    toDefaultValue: boolean
  ): number {
    switch (Number(unit)) {
      case PressureUnits.Kpa:
        value = value;
        break;
      case PressureUnits.Bar:
        value = toDefaultValue ? value * 100 : value / 100;
        break;
      case PressureUnits.Psi:
        value = toDefaultValue
          ? (value * 100) / 14.5038
          : (value / 100) * 14.5038;
        break;
    }
    return value;
  }

  private convertPressureKsi(
    unit: any,
    value: number,
    toDefaultValue: boolean
  ): number {
    switch (Number(unit)) {
      case PressureUnits.Ksi:
        value = value;
        break;
      case PressureUnits.Mpa:
        value = toDefaultValue
          ? 6.89475908677537 / value
          : value * 6.89475908677537;
        break;
    }
    return value;
  }

  private convertPercentage(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case PercentageUnits.Percent:
        value = value;
        break;
      case PercentageUnits.Ppm:
        value = toDefaultValue ? value / 10000 : value * 10000;
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertLength(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case LengthUnits.Mm:
        value = value;
        break;
      case LengthUnits.In:
        value = toDefaultValue ? value * 25.4 : value / 25.4;
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertVolume(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case VolumeUnits.CubicMetersDay:
        value = value;
        break;
      case VolumeUnits.BarrelsDay:
        value = toDefaultValue ? value / 6.2898 : value * 6.2898;
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertAPIGravity(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case APIGravityUnits.Grades:
        value = value;
        break;
      case APIGravityUnits.GramsCubicCentimeter:
        value = toDefaultValue
          ? 141.5 / (value * 131.5)
          : 141.5 / (value * 131.5);
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertConcentration(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number,
    waterDensity?: number,
    defaultUnit?: any,
    molecularWeight?: number,
    valence?: number
  ): number {
    switch (Number(defaultUnit)) {
      case ConcentrationUnits.KilogramsCubicDecimeter:
        value = this.convertConcentrationKilogramsCubicDecimeter(
          unit,
          value,
          toDefaultValue,
          waterDensity
        );
        break;
      case ConcentrationUnits.MilligramsLiter:
        value = this.convertConcentrationMilligramsLiter(
          unit,
          value,
          toDefaultValue,
          waterDensity
        );
        break;
      case ConcentrationUnits.NaClPercent:
        value = this.convertConcentrationNaClPercent(
          unit,
          value,
          toDefaultValue,
          waterDensity
        );
        break;
      case ConcentrationUnits.MilliequivalentsLiter:
        value = this.convertConcentrationMilliequivalentsLiter(
          unit,
          value,
          toDefaultValue,
          molecularWeight,
          valence
        );
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private convertConcentrationKilogramsCubicDecimeter(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    waterDensity: number
  ) {
    switch (Number(unit)) {
      case ConcentrationUnits.KilogramsCubicDecimeter:
        value = value;
        break;
      case ConcentrationUnits.GramsCubicCentimeter:
        value = value;
        break;
    }
    return value;
  }

  private convertConcentrationMilligramsLiter(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    waterDensity: number
  ) {
    switch (Number(unit)) {
      case ConcentrationUnits.MilligramsLiter:
        value = value;
        break;
      case ConcentrationUnits.Ppm:
        value = toDefaultValue ? waterDensity * value : value / waterDensity;
        break;
      case ConcentrationUnits.NaClPercent:
        value = toDefaultValue
          ? waterDensity * ((value * 10000) / (58.5 / 35.5))
          : ((value / waterDensity) * (58.5 / 35.5)) / 10000;
        break;
    }
    return value;
  }

  private convertConcentrationMilliequivalentsLiter(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    molecularWeight: number,
    valence: number
  ) {
    switch (Number(unit)) {
      case ConcentrationUnits.MilliequivalentsLiter:
        value = value;
        break;
      case ConcentrationUnits.MilligramsLiter:
        value = toDefaultValue
          ? (value / molecularWeight) * valence
          : (value / valence) * molecularWeight;
        break;
    }
    return value;
  }

  private convertConcentrationNaClPercent(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    waterDensity: number
  ) {
    switch (Number(unit)) {
      case ConcentrationUnits.MilligramsLiter:
        value = toDefaultValue
          ? ((value / waterDensity) * (58.5 / 35.5)) / 10000
          : waterDensity * ((value * 10000) / (58.5 / 35.5));
        break;
      case ConcentrationUnits.Ppm:
        value = toDefaultValue
          ? (value * (58.5 / 35.5)) / 10000
          : (value * 10000) / (58.5 / 35.5);
        break;
      case ConcentrationUnits.NaClPercent:
        value = value;
        break;
    }
    return value;
  }

  private convertVelocity(
    unit: any,
    value: number,
    toDefaultValue: boolean,
    fractionDigits: number
  ): number {
    switch (Number(unit)) {
      case VelocityUnits.MillimetersYear:
        value = value;
        break;
      case VelocityUnits.InchesYear:
        value = toDefaultValue ? 25.4 * value : value / 25.4;
        break;
    }
    return Number(value.toFixed(fractionDigits));
  }

  private getTemperatureSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case TemperatureUnits.Celsius:
        symbol = TEMPERATURE_UNITS.CELSIUS.symbol;
        break;
      case TemperatureUnits.Kelvin:
        symbol = TEMPERATURE_UNITS.KELVIN.symbol;
        break;
      case TemperatureUnits.Fahrenheit:
        symbol = TEMPERATURE_UNITS.FAHRENHEIT.symbol;
        break;
    }
    return symbol;
  }

  private getPressureSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case PressureUnits.Bar:
        symbol = PRESSURE_UNITS.BAR.symbol;
        break;
      case PressureUnits.Psi:
        symbol = PRESSURE_UNITS.PSI.symbol;
        break;
      case PressureUnits.Atm:
        symbol = PRESSURE_UNITS.ATM.symbol;
        break;
      case PressureUnits.Kpa:
        symbol = PRESSURE_UNITS.KPA.symbol;
        break;
      case PressureUnits.Ksi:
        symbol = PRESSURE_UNITS.KSI.symbol;
        break;
      case PressureUnits.Mpa:
        symbol = PRESSURE_UNITS.MPA.symbol;
        break;
    }
    return symbol;
  }

  private getPercentageSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case PercentageUnits.Percent:
        symbol = PERCENTAGE_UNITS.PERCENTAGE.symbol;
        break;
      case PercentageUnits.Ppm:
        symbol = PERCENTAGE_UNITS.PPM.symbol;
        break;
    }
    return symbol;
  }

  private getLengthSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case LengthUnits.Mm:
        symbol = LENGTH_UNITS.MM.symbol;
        break;
      case LengthUnits.In:
        symbol = LENGTH_UNITS.IN.symbol;
        break;
    }
    return symbol;
  }

  private getVolumeSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case VolumeUnits.CubicMetersDay:
        symbol = VOLUME_UNITS.CUBIC_METERS_DAY.symbol;
        break;
      case VolumeUnits.BarrelsDay:
        symbol = VOLUME_UNITS.BARRELS_DAY.symbol;
        break;
    }
    return symbol;
  }

  private getAPIGravitySymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case APIGravityUnits.Grades:
        symbol = API_GRAVITY_UNITS.GRADES.symbol;
        break;
      case APIGravityUnits.GramsCubicCentimeter:
        symbol = API_GRAVITY_UNITS.GRAMS_CUBIC_CENTIMETER.symbol;
        break;
    }
    return symbol;
  }

  private getConcentrationSymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case ConcentrationUnits.KilogramsCubicDecimeter:
        symbol = CONCENTRATION_UNITS.KILOGRAMS_CUBIC_DECIMETER.symbol;
        break;
      case ConcentrationUnits.GramsCubicCentimeter:
        symbol = CONCENTRATION_UNITS.GRAMS_CUBIC_CENTIMETER.symbol;
        break;
      case ConcentrationUnits.MilligramsLiter:
        symbol = CONCENTRATION_UNITS.MILLIGRAMS_LITER.symbol;
        break;
      case ConcentrationUnits.Ppm:
        symbol = CONCENTRATION_UNITS.PPM.symbol;
        break;
      case ConcentrationUnits.NaClPercent:
        symbol = CONCENTRATION_UNITS.NA_CL_PERCENT.symbol;
        break;
      case ConcentrationUnits.NaClGramsLiter:
        symbol = CONCENTRATION_UNITS.NA_CL_GRAMS_LITER.symbol;
        break;
      case ConcentrationUnits.MilliequivalentsLiter:
        symbol = CONCENTRATION_UNITS.MILLIEQUIVALENTS_LITER.symbol;
        break;
    }
    return symbol;
  }

  private getVelocitySymbol(unit: any): string {
    let symbol: string;
    switch (Number(unit)) {
      case VelocityUnits.MillimetersYear:
        symbol = VELOCITY_UNITS.MILLIMITERS_YEAR.symbol;
        break;
      case VelocityUnits.InchesYear:
        symbol = VELOCITY_UNITS.INCHES_YEAR.symbol;
        break;
    }
    return symbol;
  }

  private getTemperatureFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case TemperatureUnits.Celsius:
        fractionDigits = TEMPERATURE_UNITS.CELSIUS.fractionDigits;
        break;
      case TemperatureUnits.Kelvin:
        fractionDigits = TEMPERATURE_UNITS.KELVIN.fractionDigits;
        break;
      case TemperatureUnits.Fahrenheit:
        fractionDigits = TEMPERATURE_UNITS.FAHRENHEIT.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getPressureFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case PressureUnits.Bar:
        fractionDigits = PRESSURE_UNITS.BAR.fractionDigits;
        break;
      case PressureUnits.Psi:
        fractionDigits = PRESSURE_UNITS.PSI.fractionDigits;
        break;
      case PressureUnits.Atm:
        fractionDigits = PRESSURE_UNITS.ATM.fractionDigits;
        break;
      case PressureUnits.Kpa:
        fractionDigits = PRESSURE_UNITS.KPA.fractionDigits;
        break;
      case PressureUnits.Psi:
        fractionDigits = PRESSURE_UNITS.PSI.fractionDigits;
        break;
      case PressureUnits.Mpa:
        fractionDigits = PRESSURE_UNITS.MPA.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getPercentageFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case PercentageUnits.Percent:
        fractionDigits = PERCENTAGE_UNITS.PERCENTAGE.fractionDigits;
        break;
      case PercentageUnits.Ppm:
        fractionDigits = PERCENTAGE_UNITS.PPM.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getLengthFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case LengthUnits.Mm:
        fractionDigits = LENGTH_UNITS.MM.fractionDigits;
        break;
      case LengthUnits.In:
        fractionDigits = LENGTH_UNITS.IN.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getVolumeFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case VolumeUnits.CubicMetersDay:
        fractionDigits = VOLUME_UNITS.CUBIC_METERS_DAY.fractionDigits;
        break;
      case VolumeUnits.BarrelsDay:
        fractionDigits = VOLUME_UNITS.BARRELS_DAY.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getAPIGravityFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case APIGravityUnits.Grades:
        fractionDigits = API_GRAVITY_UNITS.GRADES.fractionDigits;
        break;
      case APIGravityUnits.GramsCubicCentimeter:
        fractionDigits =
          API_GRAVITY_UNITS.GRAMS_CUBIC_CENTIMETER.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getConcentrationFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case ConcentrationUnits.KilogramsCubicDecimeter:
        fractionDigits =
          CONCENTRATION_UNITS.KILOGRAMS_CUBIC_DECIMETER.fractionDigits;
        break;
      case ConcentrationUnits.GramsCubicCentimeter:
        fractionDigits =
          CONCENTRATION_UNITS.GRAMS_CUBIC_CENTIMETER.fractionDigits;
        break;
      case ConcentrationUnits.MilligramsLiter:
        fractionDigits = CONCENTRATION_UNITS.MILLIGRAMS_LITER.fractionDigits;
        break;
      case ConcentrationUnits.Ppm:
        fractionDigits = CONCENTRATION_UNITS.PPM.fractionDigits;
        break;
      case ConcentrationUnits.NaClPercent:
        fractionDigits = CONCENTRATION_UNITS.NA_CL_PERCENT.fractionDigits;
        break;
      case ConcentrationUnits.NaClGramsLiter:
        fractionDigits = CONCENTRATION_UNITS.NA_CL_GRAMS_LITER.fractionDigits;
        break;
      case ConcentrationUnits.MilliequivalentsLiter:
        fractionDigits =
          CONCENTRATION_UNITS.MILLIEQUIVALENTS_LITER.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  private getVelocityFractionDigits(unit: any): number {
    let fractionDigits: number;
    switch (Number(unit)) {
      case VelocityUnits.MillimetersYear:
        fractionDigits = VELOCITY_UNITS.MILLIMITERS_YEAR.fractionDigits;
        break;
      case VelocityUnits.InchesYear:
        fractionDigits = VELOCITY_UNITS.INCHES_YEAR.fractionDigits;
        break;
    }
    return fractionDigits;
  }

  get unitMeasures(): typeof UnitMeasures {
    return UnitMeasures;
  }

  get concentrationUnits(): typeof ConcentrationUnits {
    return ConcentrationUnits;
  }

  get pressureUnits(): typeof PressureUnits {
    return PressureUnits;
  }
}
