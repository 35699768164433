<style>
#cabecera{
    background-color: white;
    width: 100%;
    height: 95px;
    padding: 20px 35px 0px 0px;
}
*{
    margin: 0%;
    padding: 0%;
    border: 0px;
    
}
#contenedor-titulo{
    width: 100%;
    text-align: left;
}
#separador-titulo
{
    height: 5px;
    width: 100%;
    background-color: white;
}
#columna1-principal{
    height: 80%;
}
#columna2-principal{
    height: 20%;
}
.am-wrapper am-white-header{
    margin: 0%;
    padding: 0%;
    border: 0px;
}

.am-content{
    margin: 0%;
    padding: 0%;
    border: 0px;
}
#data-user{
    text-align: right;
}
#col1-linea{
    width: 50%;
    background-color: grey;
    height: 5px;
}
#col2-linea{
    width: 35%;
    height: 20px;
}
#titulo{
    font-size: 38px;
}
body{
    padding: 50px 40px 50px 50px;
    background-color: white;   
}
#col-condition
{
    width: 100%;
}
#columna1-principal{
    background-color: white;
    width: 60%;
}
#columna2-principal{
    background-color: white;
    width: 40%;
}
#linea-divisora
{
    width: 100%;
    background-color: grey;
    height: 10px;
}
#logo
{
    background-color: #3d3c3c;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
    height: 70px;
    width: 100px;
    background-image: url(logo-full.png);
    /* background-image: url(/src/assets/img/logo-full.png); */
    background-repeat: no-repeat;
    /* background-position: center center; */
    margin-right: 20px;
    /* border-bottom: 1px solid #474646; */

    background-color: #FFF;
    /* border-bottom: 1px solid #d7d7d7; */
    position: relative;
    top: 1px;
    width: 300px;
    height: 70px;
    margin-left: 20px;
    padding-left: 0px;
    
}


#col-logo{
width: 50%;
padding-left: 0px;
}

#col-datos{
width: 50%;
text-align: right;
}

#contenedor-subtitulo
{
background-color: #f5f5f5;
color: #666666;
font-size: 10px;
text-align: center;
padding: 1px 20px 1px;
margin-bottom: 25px;
border: none;
font-weight: bold;
font-family: FrugiterBold,sans-serif;
font-size: 14px;
margin-bottom: 11px;
margin-top: 11px;
}

#contenedor-subtitulo-radio
{
/* background-color: #f5f5f5; */
color: #666666;
font-size: 10px;
text-align: center;
padding: 1px 20px 1px;
margin-bottom: 25px;
border: none;
font-weight: bold;
font-family: FrugiterBold,sans-serif;
font-size: 14px;
margin-bottom: 11px;
margin-top: 11px;
}

#contenedor-subtitulo-cor
{
background-color: green;
color: white;
font-size: 12px;
text-align: center;
padding: 1px 20px 1px;
/* // margin-left: 5px; */
margin-bottom: 25px;
border: none;

font-weight: bold;
font-family: FrugiterBold,sans-serif;
font-size: 20px;
margin-bottom: 11px;
margin-top: 11px;

}
#parrafo-uno
{
margin-top: 200px;
padding-top: 200px;
}
footer{
margin-top: 200px;
}
/*Nuevos elementos*/

.menu {
  float: right;
}

.menu li {
  display: inline-block;
}

.input-group-addon {
  width: 60px;
}

.water-chemical {
  padding-top: 15px;
}

#divConditions .input-group-addon {
  width: 35px;
}

@media (min-width: 1367px) {
  #divInstallation label.input-xs {
     margin-bottom: 0px;
  }
}

#divInstallation label.input-xs {
  margin-top: 8px;
  padding-left: 0px;
}

label {
  font-family: FrutigerRoman, sans-serif;
  font-size: 13px;
}

.table>thead>tr>th {
    font-family: FrutigerRoman, sans-serif;
    font-size: 10px;
}

.label-dts {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 5px !important;
}

.no-spin {
    -moz-appearance: textfield !important;
}

.wcc {
    padding: 1px !important;
}

.label-water-chemical
{
    padding-bottom: 35px;
}
tr th p {
    margin: 0px 0px 0px 0px;
}
.textoFilaCondition{
    font-size: 2px;
    width: 280px;
    padding-left: 20px;
}
.inputValores{
    text-align: right;
    width: 50px;
}
.table-fw-widget thead tr th{
    padding-top:8px;
    
}
#separadorConditionChemical{
    padding-top: 50px;
}
.parrafos-inferiores{
    text-align: justify;
}
</style>
<body>
    <header>
        <!--Logo y datos de creador-->
        <div id="cabecera">
            <div id="col-logo" class="col-sm-6">
                <div id="logo">

                </div>
            </div>
            <div id="col-datos" class="col-sm-6">
                <div id="data-user">
                    <p>Create By: Raffaelo Zambetti</p>
                    <p>Date: {{varFecha | date: 'mediumDate'}}</p>                    
                    <p>Model Ver.: 1.0.7381.23794</p>
                </div>
            </div>
        </div>
        <!--Linea divisora-->
        <div    id="linea">
            <div id="col1-linea"></div>
            <div id="col2-linea"></div>
        </div>
        <!--Titulo principal-->
        <div id="contenedor-titulo">
            <div id="titulo" class="title">
                <p  style="padding-bottom: 25px;">De Waard Model Predicted</p>
                
                <p style="padding-bottom: 35px;">Corrosion Rates</p>
            </div>
            <div id ="titulo2"></div>
        </div>
        <!--Espacio divisor déspues de titulo-->
        <div id="separador-titulo">

        </div>


    </header>
    <main>
        <!-- <form [formGroup]="formCorrosionClasPdf" (ngSubmit)="submitData()"> -->
            <form (ngSubmit)="submitData()">
        <!--Section que contiene conditions, chemical y tubular-->
        <div id="container1">
            <div id="columna1-principal" class="col-sm-6">
                                        <div id="contenedor-subtitulo" class="widget-head" 
                                        style="background-color:#f5f5f5; color: #666666; font-size:12px;text-align: center;padding:1px 20px 1px;  margin-bottom: 25px;border: none;">
                                            <h3 style="font-weight:bold; font-family: FrugiterBold,sans-serif;font-size:14px ;margin-bottom: 11px;margin-top: 11px;">
                                                Conditions
                                            </h3>
                                        </div>


                                        <div id="contenido-condition">
                                            <div id="col-condition">
                                                <table class="table table-fw-widget">
                                                    <thead>
                                                        <tr>
                                                            <th class="textoFilaCondition" >Temperature (°C)</th>
                                                            <th class="text-right"></th>
                                                            <th class="text-right" > 
                                                                <label class="inputValores">
                                                                    {{simulation.temperature}}
                                                                </label>
                                                                <!-- <input id="iTemperature" type="number" class="input-xs no-spin inputValores"
                                                                formControlName="iTemperature"> -->
                                                            </th>
                                                           
                                                            <th  style="border: none;"></th>
                                                            <th  class="textoFilaCondition">CO<sub>2</sub> in gas (%)</th>
                                                            <th class="text-right" style="width: 0px;"></th>
                                                            <th class="text-right">
                                                                <!-- <input id="iCO2perc" type="number" class="input-xs no-spin inputValores"
                                                                formControlName="iCO2perc"> -->
                                                                <label class="inputValores">
                                                                    {{simulation.cO2_per}}
                                                                </label>
                                                            </th>

                                                        </tr>

                                                        <tr>
                                                            <th class="textoFilaCondition">Total Pressure (bar)</th>
                                                            <th class="text-right"></th>
                                                            <th class="text-right" style="width: 0px;">
                                                                <!-- <input id="iTotalPressure" type="number" class=" input-xs no-spin inputValores" 
                                                                formControlName="iTotalPressure"> -->
                                                                <label class="inputValores">
                                                                    {{simulation.total_pressure}}
                                                                </label>
                                                            </th>
                                                            <th style="border: none;"></th>
                                                            <th class="textoFilaCondition">H<sub>2</sub>S in gas (%)</th>
                                                            <th class="text-right"></th>
                                                            <th class="text-right">
                                                                <!-- <input id="iH2Sperc" type="number" class=" input-xs no-spin inputValores" 
                                                                formControlName="iH2Sperc"> -->
                                                                <label class="inputValores">
                                                                    {{simulation.h2S_per}}
                                                                </label>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                   </div>

                                   <div id="separadorConditionChemical">
                                       
                                   </div>

                                <div id="contenedor-subtitulo" class="widget-head">
                                    <h3 style="font-weight:bold; font-family: FrugiterBold,sans-serif;font-size:14px ;margin-bottom: 11px;margin-top: 11px;">
                                        Chemical Composition (mg/lts)</h3>
                                </div>
                                <div id="contenido-chemical">
                                    <div id="col-condition">
                                        <table class="table table-fw-widget">
                                            <thead>
                                                <tr>
                                                    <th class="textoFilaCondition">Na</th>
                                                    <th class="text-right"></th>
                                                    <th >
                                                        <!-- <input id="iNa" type="number" class=" input-xs no-spin inputValores" 
                                                        formControlName="iNa"> -->
                                                        <label class="inputValores">
                                                            {{simulation.na}}
                                                        </label>
                                                    </th>
                                                    <th style="border: none;"></th>
                                                    <th  class="textoFilaCondition">CI</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <!-- <input id="iCl" type="number" class=" input-xs no-spin inputValores"
                                                        formControlName="iCl"> -->
                                                        <label class="inputValores">
                                                            {{simulation.cl}}
                                                        </label>
                                                    </th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th class="textoFilaCondition">K</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.k}}
                                                        </label>
                                                    </th>
                                                    <th style="border: none;"></th>
                                                    <th  class="textoFilaCondition">Ca</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.ca}}
                                                        </label>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th class="textoFilaCondition">Mg</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.mg}}
                                                        </label>
                                                    </th>
                                                    <th style="border: none;"></th>
                                                    <th class="textoFilaCondition">Fe</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.fe}}
                                                        </label>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th class="textoFilaCondition">Ba</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.ba}}
                                                        </label>
                                                    </th>
                                                    
                                                    <th style="border: none;"></th>
                                                    <th  class="textoFilaCondition">Acetate</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.acetate}}
                                                        </label>
                                                    </th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th class="textoFilaCondition">Sr</th>
                                                    <th class="text-right"></th>
                                                    <th>
                                                        <label class="inputValores">
                                                            {{simulation.sr}}
                                                        </label>
                                                    </th>
                                                    <th width="5%" style="border: none;"></th>
                                                </tr>
                                                <tr>
                                                    
                                                </tr>
                                            </thead>
                                        </table>
                
                                    </div>
                                </div>

                                <div id="contenedor-subtitulo-radio" class="widget-head">
                                    <h3 style="font-weight:bold; font-family: FrugiterBold,sans-serif;font-size:14px ;margin-bottom: 11px;margin-top: 11px;">
                                        Heat Treatment: Quenched + Tempered</h3>
                                </div>

                            </div>

                            <div id="columna2-principal" class="col-sm-6">
                                <div id="contenedor-subtitulo">
                                    <h3 style="font-weight:bold; font-family: FrugiterBold,sans-serif;font-size:14px ;margin-bottom: 11px;margin-top: 11px;">Tubular & Production</h3>
                                </div>
                                <div id="content-tubular">
                                    <table class="table table-fw-widget">
                                        <thead>
                                    <tr>
                                        <th class="textoFilaCondition">Pipe Outside Diameter (mm)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.external_pipe_diam}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr style="padding-top: 0px;">
                                        <th class="textoFilaCondition">Pipe Wall Thickness (mm)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <!-- <input id="iPipeWT" type="number" class=" input-xs no-spin"
                                            formControlName="iPipeWT"> -->
                                            <label class="inputValores">
                                                {{simulation.pipe_wall_thickness}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Pipe Inner Diameter (mm)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.id}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Carbon (%)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.c_per}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Chromium (%)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.cr_per}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Oil Production (m<sup>3</sup>/day)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.oil_production}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Water Production (m<sup>3</sup>/day)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.water_production}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">WaterCut (fraction)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.watercut}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Deviation at considered point (°)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.deviation}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">API oil gravity (°)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.api_oil_gravity}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Liquid Velocity (m/sec)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.liquid_velocity}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                     <tr>
                                        <th class="textoFilaCondition">Maximum Water in Oil wBreak (fraction)</th>
                                        <th class="text-right"></th>
                                        <th  class="text-right"> 
                                            <label class="inputValores">
                                                {{simulation.wbreak}}
                                            </label>
                                        </th>
                                        <!-- <th style="border: none;"></th> -->
                                     </tr>
                                </thead>
                            </table>  
                                </div>
                            </div>      
                       



        
    </div>
        <!--Section que contiene corrosion rates-->
    <div id="container2">

        <div id="container-corrosion" class="col-sm-12">
            <div id="contenedor-subtitulo-cor" class="widget-head">
                <h3 style="font-weight:bold; font-family: FrugiterBold,sans-serif;font-size:14px ;margin-bottom: 11px;margin-top: 11px;">Corrosion Rates</h3>
            </div>

            <table class="table table-fw-widget">
                <thead>
            <tr>

                <th width="35%" class="">Corrected By Composition (mm/year)</th>
                <th class="text-right"></th>
                <th  class="text-right"> 
                        <label class="inputValores">
                            {{ outputValues.corrosionRates_CorrectedByComposition |
                                number:'1.2-2'}}
                        </label>
                </th>

                <th width="5%" style="border: none;"></th>

                <th width="35%" class="">Corrected By Scale Carbonates (mm/year)</th>
                <th class="text-right"></th>
                <th  class="text-right"> 
                    <label class="inputValores"
                    >{{ outputValues.corrosionRates_CorrectedByCO2 |
                        number:'1.2-2'}}
                    </label>
                </th>

             </tr>

             <tr>

                <th width="35%" class="">Corrected By Scale CO<sub>2</sub> or H<sub>2</sub>S (mm/year)</th>
                <th class="text-right"></th>
                <th  class="text-right"> 
                        <label class="inputValores">
                            {{ outputValues.corrosionRates_CorrectedByCO2andH2S |
                                number:'1.2-2'}}
                        </label>
                </th>

                <th width="5%" style="border: none;"></th>

                <th width="35%" class="">Corrected By Oil (mm/year)</th>
                <th class="text-right"></th>
                <th  class="text-right"> 
                        <label class="inputValores">
                            {{ outputValues.corrosionRates_CorrectedByOil |
                                number:'1.2-2'}}
                        </label>
                </th>

             </tr>
            </thead>
        </table>
        <div style="padding:20px 20px 15px 0px;border-bottom:1px solid transparent;border-top-right-radius:-1;border-top-left-radius:-1">
            Corrosion CLAS = Baseline * Fscale Carbonates * (Fscale CO<sub>2</sub> or H<sub>2</sub>S) * FOil
         </div>
        </div>

    </div>

    </form>
    <!-- <div style="display: inline-block;">
        <div>
        <div id="separador-titulo" style="padding-top: 100px;width: 100%;height: 20px;background-color:white"> 
        </div>
    </div>
    

    </div> -->


    </main>
    <footer>

        <div id="parrafo-uno">
            <div class="col-sm-12">
                <br>
                <br>
                <br>
                <br>
                <br>
                <p class="parrafos-inferiores">
                    The models for prediction of corrosion rates are updated versions of the ones described in Paper 'Influence of Liquid Flow Velocity on CO<sub>2</sub>
                    Corrosion: A Semi-Empirical Model', CORROSION/1995 C. de Waard, U. Lotz, A. Dugstad Paper No. 128, (Houston, TX: NACE
                    International,1995                
                </p>
                <br>
                        <div id="linea-divisora" style="height: 1px;">
                            
    
        </div>
        <br>
                <p class="parrafos-inferiores">
                    Tenaris has produced this report for general information only, and the information in this report is not intended to constitute professional or any other
                    type of advice and is provided on an “as is” basis. No warranty is given. Tenaris has not independently verified any information –if any- provided to
                    Tenaris in connection with, or for the purpose of, the information contained hereunder. The use of the information is at user’s own risk and Tenaris
                    does
                    not assume any responsibility or liability of any kind for any loss, damage or injury resulting from, or in connection with any information provided
                    hereunder or the use thereof. Tenaris products and services are subject to the Company’s standard terms and conditions or otherwise to the terms
                    resulting from the respective contracts of sale, services or license, as the case may be. Unless specifically agreed under such contract of sale, services
                    or license, if Tenaris is required to provide any warranty or assume any liability in connection with the information provided hereinabove, any such
                    warranty or liability shall be subject to the execution of a separate written agreement between petitioner and Tenaris. This report is confidential and no
                    part of this report may be reproduced or disclosed in any form or by any means whatsoever, without prior permission from Tenaris. For more
                    complete
                    information please contact a Tenaris representative or visit our website at www.tenaris.com. ©Tenaris 2022. All rights reserved.                              
                </p>
            </div>
    
        </div>
        <!-- <div id="linea-divisora">
    
        </div> -->
        <div id="parrafo-dos">
            <div class="col-sm-12">

            </div>
        </div>

    </footer>
</body>
<script>
    $(function () {
       $('[data-tooltip]').tooltip();
    });
 </script>