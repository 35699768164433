import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from "rxjs";
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CasingWear, GetPercentagesRequest, GetPercentagesResponse, SeriesRequest } from '../models/Casingwear'
import { CasingWearArrayReq, ComboReq } from '../models/Casingwear';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CasingwearService {
  private readonly API_ENDPOINT: string = environment.apiEndpoint;

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  filters: CasingWear[] = [
    { od: 0, wt: 0, odGr: 0, grade: 0, percent: 0, loadMode: "string", lpfMax: 0, lpfEnd: 0 }
  ];

  constructor(private http: HttpClient, private httpService: HttpService) { }

  GetDropdownHttp(req: ComboReq) {
    const url = `${this.API_ENDPOINT}casingwear/v1/CasingWear/GetDropdown`;
    return this.http.post<any>(url, req, this.httpOptions);
  }

  getFilter(filter: number | string, index: number): Observable<any> {
    // const url = `${environment.apiUrlCw}/api/casingwear/v1/filter/${filter}`;
    // const resp = this.http.get(url, this.httpOptions).pipe(
    //   tap((resp: any) => resp),
    //   catchError(err => {
    //     throw err.message;
    //   }));
    //return resp
    return of(null);
  }

  getLoadMode(): Observable<string[]> {
    const values = ['loads', 'collapse', 'burst', 'tension', 'compression'];
    return of(values);
  }

  getHttpAllCasingWear() {
    const url = `${this.API_ENDPOINT}casingwear/v1/CasingWear/GetAllCasingWear`;
    return this.http.get<any>(url);
  }

  getSeries(inputValues: SeriesRequest): Observable<any> {
    let url = `${this.API_ENDPOINT}casingwear/v1/CasingWear/GetSeries`;
    let body = JSON.stringify(inputValues);
    const resp = this.http.post(url, body, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
  }

  getHttpCroquis(req: CasingWearArrayReq) {
    const url = `${this.API_ENDPOINT}casingwear/v1/CasingWear/GetCroquis`;
    return this.http.post<any>(url, req);
  }

  getPercentages(getPercentagesRequest: GetPercentagesRequest): Observable<GetPercentagesResponse> {
    let url = `${this.API_ENDPOINT}casingwear/v1/CasingWear/GetPercentages`;
    let body = JSON.stringify(getPercentagesRequest);
    const resp = this.http.post(url, body, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
  }

  registerLog(request: any) {
    const httpOptionsCustom = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Loading': 'true'
      })
    }

    const URL: string = `${this.API_ENDPOINT}casingwear/v1/CasingWear/RegisterLogs`;

    return this.http.post<any>(URL, request, httpOptionsCustom).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }
}
