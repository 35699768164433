<section>
    <form [formGroup]="fgFiltersCR">
      <div *ngFor="let filterCR of filtersCR">
        <app-filter-cr
          [fgFilterCR]="getFormGroup(filterCR.key)"
          [fgFiltersCR]="fgFiltersCR"
          [key]="filterCR.key"
          [unitMeasureService]="unitMeasureService"
          [temperatureUnitControl]="temperatureUnitControl"
          [pressureCO2UnitControl]="pressureCO2UnitControl"
          [sodiumChlorideUnitControl]="sodiumChlorideUnitControl"
          [conventional13CrUnitControl]="conventional13CrUnitControl"
          [super13CrUnitControl]="super13CrUnitControl"
          [modified13CrUnitControl]="modified13CrUnitControl"
          [temperatureUnitEnabled]="temperatureUnitEnabled"
          [pressureCO2UnitEnabled]="pressureCO2UnitEnabled"
          [sodiumChlorideUnitEnabled]="sodiumChlorideUnitEnabled"
          (add)="add($event)"
          (delete)="delete($event)"
          (clear)="clear($event)"
          (formChanges)="formChanges($event)"
        ></app-filter-cr>
      </div>
      <app-filter-cr
        #emptyInput
        [fgFilterCR]="fgFilterCR"
        [fgFiltersCR]="fgFiltersCR"
        [unitMeasureService]="unitMeasureService"
        [temperatureUnitControl]="temperatureUnitControl"
        [pressureCO2UnitControl]="pressureCO2UnitControl"
        [sodiumChlorideUnitControl]="sodiumChlorideUnitControl"
        [conventional13CrUnitControl]="conventional13CrUnitControl"
        [super13CrUnitControl]="super13CrUnitControl"
        [modified13CrUnitControl]="modified13CrUnitControl"
        [hiddenErrors]="hiddenErrors"
        (add)="add($event)"
        (formChanges)="formChanges($event)"
        (isTemperatureUnitEnabled)="isTemperatureUnitEnabled($event)"
        (isPressureCO2UnitEnabled)="isPressureCO2UnitEnabled($event)"
        (isSodiumChlorideUnitEnabled)="isSodiumChlorideUnitEnabled($event)"
      ></app-filter-cr>
    </form>
</section>
