import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { APIResponse } from "../http/models/api-response";
import { Observable } from "rxjs";
import { CreateCustomerRequest, Customer } from "src/app/shared/components/pre-simulation/models/pre-simulation";
import { CustomerMgr, DeleteActiveCustomerRequest, EditActiveCustomerRequest, VerifyActiveCustomerRequest } from "src/app/modules/customermgr/models/customermgr";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private readonly MODULE: string = "customer";
  private readonly VERSION: string = "v1";
  private readonly RESOURCE: string = "Customer";
  private readonly GET_PRE_SIMULATION_ACTIVE_CUSTOMERS_ACTION: string = "GetPreSimulationActiveCustomers";
  private readonly GET_ACTIVE_CUSTOMERS_ACTION: string = "GetActiveCustomers";
  private readonly CREATE_CUSTOMER_ACTION: string = "CreateCustomer";
  private readonly EDIT_ACTIVE_CUSTOMER_ACTION: string = "EditActiveCustomer";
  private readonly VERIFY_ACTIVE_CUSTOMER_ACTION: string = "VerifyActiveCustomer";
  private readonly DELETE_ACTIVE_CUSTOMER_ACTION: string = "DeleteActiveCustomer";
  private readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({
      "Content-type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getPreSimulationActiveCustomers(search?: string): Observable<Customer[]> {
    let httpParams = new HttpParams();

    if (search) httpParams = httpParams.set("search", search);

    return this.http
      .get<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.GET_PRE_SIMULATION_ACTIVE_CUSTOMERS_ACTION
        ),
        { params: httpParams }
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  getActiveCustomers(search?: string, onlyNotVerified?: boolean): Observable<CustomerMgr[]> {
    let httpParams = new HttpParams();

    if (search) httpParams = httpParams.set("search", search);
    if (onlyNotVerified) httpParams = httpParams.set("onlyNotVerified", onlyNotVerified);

    return this.http
      .get<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.GET_ACTIVE_CUSTOMERS_ACTION
        ),
        { params: httpParams }
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  createCustomer(request: CreateCustomerRequest): Observable<Customer> {
    return this.http
      .post<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.CREATE_CUSTOMER_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else {
            if (APIResponse.status.message === "Customer already exists.") {
              Swal.fire({
                title: APIResponse.status.message,
                icon: "error",
                confirmButtonColor: "#009",
                cancelButtonColor: "#aaa",
              });
            }
            else
              this.httpService.handleErrorMessage(APIResponse.status.message);
          }
        }),
        catchError(this.httpService.handleError)
      );
  }

  editActiveCustomer(request: EditActiveCustomerRequest): Observable<Customer> {
    return this.http
      .put<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.EDIT_ACTIVE_CUSTOMER_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  verifyActiveCustomer(request: VerifyActiveCustomerRequest): Observable<Customer> {
    return this.http
      .put<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.VERIFY_ACTIVE_CUSTOMER_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  deleteActiveCustomer(request: DeleteActiveCustomerRequest): Observable<Customer> {
    return this.http
      .put<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.DELETE_ACTIVE_CUSTOMER_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }
}
