import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { merge, Subscription } from "rxjs";
import { SlottedPipeService } from "src/app/services/slotted-pipe/slotted-pipe.service";
import { AbstractControlWarning } from "src/app/shared/helpers/AbstractControlWarning";
import { DCSlottedSlotsRequest } from "../../models/slotted-pipe";
import { SlottedService } from "../../services/slotted.service";
import { Groupings } from "../../../shared/enums/groupings.enum";
import { Patterns } from "../../../shared/enums/patterns.enum";


@Component({
  selector: "app-slotted-slots-scheme",
  templateUrl: "./slotted-slots-scheme.component.html",
  styleUrls: ["./slotted-slots-scheme.component.css"],
})
export class SlottedSlotSchemeComponent implements OnInit {
  @Input() fgSlottedPipe: FormGroup;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;
  slottedInputsDisabled$: Subscription;
  slottedInputsGang$: Subscription;

  constructor(
    private slottedService: SlottedService,
    private slottedPipeService: SlottedPipeService
  ) {}

  ngOnInit(): void {
    this.slottedInputsDisabled$ = this.slottedService.slottedInputs$.subscribe(
      (inputData) => {
        if (inputData === Patterns.Aligned) {
          this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").disable();
        } else if (inputData == Patterns.Staggered) {
          this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").enable();
        }
      }
    );

    this.slottedInputsGang$ = this.slottedService.slottedInputGang$.subscribe(
      (inputData) => {
        if (inputData === Groupings.Single) {
          this.fgSlottedPipe
            .get("fgSlots")
            .get("gangOnlyCircSeparation")
            .disable();
          this.fgSlottedPipe.get("fgSlots").get("gangOnly").disable();
        } else if (inputData === Groupings.Gangs) {
          this.fgSlottedPipe
            .get("fgSlots")
            .get("gangOnlyCircSeparation")
            .enable();
          this.fgSlottedPipe.get("fgSlots").get("gangOnly").enable();
        }
      }
    );

    this.refreshDataCheckData$ =
      this.slottedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "SLOTS") this.dataCheck();
      });

    merge(
      this.fgSlottedPipe.get("fgType").valueChanges,
      this.fgSlottedPipe.get("fgSlots").valueChanges,
      this.fgSlottedPipe.get("fgPipes").get("outsideDiameter").valueChanges,
      this.fgSlottedPipe.get("fgPipes").get("wallThickness").valueChanges
    ).subscribe(() => {
      if (
        this.fgSlottedPipe.get("fgType").valid &&
        this.fgSlottedPipe.get("fgSlots").valid &&
        this.fgSlottedPipe.get("fgPipes").get("outsideDiameter").valid &&
        this.fgSlottedPipe.get("fgPipes").get("wallThickness").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });
  }

  dataCheck() {
    let dcSlottedSlotsRequest: DCSlottedSlotsRequest = {
      iOutsideDiameter: this.fgSlottedPipe.get("fgPipes").get("outsideDiameter")
        .value,
      iWallThickness: this.fgSlottedPipe.get("fgPipes").get("wallThickness")
        .value,
      iGroovesAxialDistance: this.fgSlottedPipe
        .get("fgSlots")
        .get("groovesAxialDistance").value,
      iGroovesPerFoot: this.fgSlottedPipe.get("fgSlots").get("groovesPerFoot")
        .value,
      iGroovesPerimetralDistance: this.fgSlottedPipe
        .get("fgSlots")
        .get("groovesPerimetralDistance").value,
      iMaxGrooveLID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthID")
        .value,
      iMaxGrooveLOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthOD")
        .value,
      iMaxGrooveWID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthID")
        .value,
      iMaxGrooveWOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthOD")
        .value,
      iNumberOfAxis: this.fgSlottedPipe.get("fgSlots").get("numberOfAxis")
        .value,
      iNumberOfColumnsXft: this.fgSlottedPipe
        .get("fgSlots")
        .get("columnsPerFoot").value,
      iNumberOfGrooves: this.fgSlottedPipe.get("fgSlots").get("gangOnly").value,
      iPitchBetweenAxes: this.fgSlottedPipe.get("fgSlots").get("staggeredOnly")
        .value,
      iCircSeparation: this.fgSlottedPipe
        .get("fgSlots")
        .get("gangOnlyCircSeparation").value,
      rdAligned:
        this.fgSlottedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgSlottedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdGroupingGangs:
        this.fgSlottedPipe?.get("fgType").get("grouping").value ===
        Groupings.Gangs,
      rdGroupingSingle:
        this.fgSlottedPipe?.get("fgType").get("grouping").value ===
        Groupings.Single,
      rdNo: this.fgSlottedPipe?.get("fgType").get("groovesMachinedWithSaw")
        .value === false,
      rdYes: this.fgSlottedPipe?.get("fgType").get("groovesMachinedWithSaw")
        .value === true,
    };

    this.slottedPipeService
      .dataCheckSlottedSlots(dcSlottedSlotsRequest)
      .subscribe((data) => {
        let dataCheckData = [];

        if (data.results.Output.hasError)
          for (let key in data.results.Output.errors) {
            let errorData = {
              type: "ERROR",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.errors[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.errors[key].options) {
              errorData.options.push(
                data.results.Output.errors[key].options[optionKey]
              );
            }

            dataCheckData.push(errorData);
          }

        if (data.results.Output.hasWarning)
          for (let key in data.results.Output.warnings) {
            let warningData = {
              type: "WARNING",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.warnings[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.warnings[key].options) {
              warningData.options.push(
                data.results.Output.warnings[key].options[optionKey]
              );
            }

            dataCheckData.push(warningData);
          }

        this.slottedService.setDataCheckData(dataCheckData, "SLOTS");
      });
  }

  get maxGrooveLengthOD() {
    return this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthOD") as AbstractControlWarning;
  }

  get maxGrooveLengthID() {
    return this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthID") as AbstractControlWarning;
  }

  get maxGrooveWidthOD() {
    return this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthOD") as AbstractControlWarning;
  }

  get maxGrooveWidthID() {
    return this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthID") as AbstractControlWarning;
  }

  get gangOnly() {
    return this.fgSlottedPipe.get("fgSlots").get("gangOnly") as AbstractControlWarning;
  }

  get numberOfAxis() {
    return this.fgSlottedPipe.get("fgSlots").get("numberOfAxis") as AbstractControlWarning;
  }

  

  ngOnDestroy(): void {
    this.refreshDataCheckData$.unsubscribe();
  }
}
