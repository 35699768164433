import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PreSimulationService } from "../service/pre-simulation.service";
import { CustomerService } from "src/app/services/customer/customer.service";
import { CreateCustomerRequest, Customer } from "../models/pre-simulation";
import { DataService } from "src/app/services/Data.service";
import { CustomerMgr } from "src/app/modules/customermgr/models/customermgr";

@Component({
  selector: "app-create-customer-modal",
  templateUrl: "./create-customer-modal.component.html",
  styleUrls: ["./create-customer-modal.component.scss"],
})
export class CreateCustomerModalComponent implements OnInit {
  @ViewChild("closeCreateCustomerModal") closeBtn: ElementRef;
  createCustomerForm: FormGroup;
  customers: Customer[] | CustomerMgr[];

  body = document.body;

  constructor(
    private preSimulationService: PreSimulationService,
    private customerService: CustomerService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.createCustomerForm = new FormGroup({
      description: new FormControl(null, Validators.required),
    });

    this.preSimulationService.customers$.subscribe((customers) => {
      if(customers)
        this.customers = customers;
      else
        this.customers = [];

      this.createCustomerForm.reset();
    });
  }
  
  createCustomer() {
    const createCustomerRequest: CreateCustomerRequest = {
      userId: this.dataService.getUserId(),
      description: this.createCustomerForm.get("description").value,
    };

    this.customerService
      .createCustomer(createCustomerRequest)
      .subscribe((customer) => {
        this.preSimulationService.createCustomer(customer);
        this.closeModal();
      });
  }

  private closeModal(): void {
    this.createCustomerForm.reset();
    this.closeBtn.nativeElement.click();
  }
}
