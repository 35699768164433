import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Patterns } from "../../../shared/enums/patterns.enum";
import { PerforatedService } from "../../services/perforated.service";

@Component({
  selector: "app-perforated-type",
  templateUrl: "./perforated-type.component.html",
  styleUrls: ["./perforated-type.component.css"],
})
export class PerforatedTypeComponent implements OnInit {
  @Input() fgPerforatedPipe: FormGroup;

  constructor(private perforatedService: PerforatedService) {}

  ngOnInit(): void {
    this.fgPerforatedPipe.get("fgType").get("pattern").valueChanges.subscribe((value) => {this.perforatedService.setPerforatedPattern(value)});
    this.fgPerforatedPipe.get("fgType").get("groovesMachinedWithSaw").valueChanges.subscribe((value) => {this.perforatedService.setPerforatedGrooves(value)});
  }

  setActiveGroupBtn($event: any) {
    let clickedElement = $event.target || $event.srcElement;
    if (clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive =
        clickedElement.parentElement.querySelector(".active");
      // if a Button already has Class: .active
      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
      }
      clickedElement.className += " active";
    }
  }

  setFormControlValue(value: any, formControl: AbstractControl) {
    if (formControl.enabled) formControl.setValue(value);
  }

  public get patterns(): typeof Patterns {
    return Patterns;
  }
}
