<div class="col-md-12">
  <div id="divInstallation" class="panel-default">
    <div id="slotsPanel" class="widget-head">
      <h3>Slots Scheme</h3>
    </div>
  </div>
  <div class="panel-body conditions-fields-container">
    <form [formGroup]="fgSlottedPipe">
      <div formGroupName="fgSlots">
        <div class="row">
          <div class="col-md-3 p-0">
            <div class="col-md-12">
              <label class="input-xs"
                >Max Groove Length at OD (LOD in Fig. 5)</label
              >
              <div class="xs-mb-15">
                <div class="input-group">
                  <input
                    formControlName="maxGrooveLengthOD"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'maxGrooveLengthODWarning'"
                    [ngClass]="{
                      'input-warning': maxGrooveLengthOD?.warnings?.length > 0,
                      'input-error':
                        (maxGrooveLengthOD.touched ||
                          maxGrooveLengthOD.dirty) &&
                        maxGrooveLengthOD.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'maxGrooveLengthODWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="maxGrooveLengthOD.errors?.delimited"
                >
                  {{ maxGrooveLengthOD.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Max Groove Length at ID (LID in Fig. 5)</label
              >
              <div class="xs-mb-15">
                <div class="input-group">
                  <input
                    formControlName="maxGrooveLengthID"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'maxGrooveLengthIDWarning'"
                    [ngClass]="{
                      'input-warning': maxGrooveLengthID?.warnings?.length > 0,
                      'input-error':
                        (maxGrooveLengthID.touched ||
                          maxGrooveLengthID.dirty) &&
                        maxGrooveLengthID.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'maxGrooveLengthIDWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="maxGrooveLengthID.errors?.delimited"
                >
                {{ maxGrooveLengthID.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Max Groove Width at OD</label>
              <div class="xs-mb-15">
                <div class="input-group" style="width: 100%">
                  <input
                    formControlName="maxGrooveWidthOD"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'maxGrooveWidthODWarning'"
                    [ngClass]="{
                      'input-warning': maxGrooveWidthOD?.warnings?.length > 0,
                      'input-error':
                        (maxGrooveWidthOD.touched || maxGrooveWidthOD.dirty) &&
                        maxGrooveWidthOD.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'maxGrooveWidthODWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="maxGrooveWidthOD.errors?.delimited"
                >
                {{ maxGrooveWidthOD.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Max Groove Width at ID</label>
              <div class="xs-mb-15">
                <div class="input-group">
                  <input
                    formControlName="maxGrooveWidthID"
                    class="form-control input-xs no-spin"
                    commaToDot
                    warningMessage
                    [warningContainerId]="'maxGrooveWidthIDWarning'"
                    [ngClass]="{
                      'input-warning': maxGrooveWidthID?.warnings?.length > 0,
                      'input-error':
                        (maxGrooveWidthID.touched || maxGrooveWidthID.dirty) &&
                        maxGrooveWidthID.errors?.delimited
                    }"
                  />
                  <span
                    class="input-group-addon input-xs label-dts thickness-0"
                  >
                    in</span
                  >
                </div>
                <warning-message
                  [id]="'maxGrooveWidthIDWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div
                  class="text-danger error-warning-text"
                  *ngIf="maxGrooveWidthID.errors?.delimited"
                >
                {{ maxGrooveWidthID.errors?.errorMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="col-md-12">
              <label class="input-xs">Grooves per Foot</label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="groovesPerFoot"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  Nr/ft</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Number of Axes (Fig. 6)</label>
              <div class="input-group xs-mb-15 slotsAttr">
                <input
                  formControlName="numberOfAxis"
                  class="form-control input-xs no-spin"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'numberOfAxisWarning'"
                  [ngClass]="{
                    'input-warning': numberOfAxis?.warnings?.length > 0,
                    'input-error':
                      (numberOfAxis.touched || numberOfAxis.dirty) &&
                      numberOfAxis.errors?.delimited
                  }"
                />
                <warning-message
                  [id]="'numberOfAxisWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div class="text-danger error-warning-text" *ngIf="numberOfAxis.errors?.delimited">
                  {{ numberOfAxis.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Number of Columns per Foot (Fig. 6)</label
              >
              <div class="input-group xs-mb-15">
                <input
                  formControlName="columnsPerFoot"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  Nr/ft
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <label style="visibility: hidden" class="input-xs"
                >Data Check</label
              >
              <button
                *ngIf="showDataCheck"
                data-toggle="modal"
                data-target="#dataCheckModal"
                data-placement="right"
                data-tooltip
                title="Data Check"
                type="button"
                class="datacheck"
                (click)="dataCheck()"
                tabindex="-1"
              >
                Data Check
              </button>
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="col-md-12">
              <label class="input-xs">Grooves Axial Distance (S)</label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="groovesAxialDistance"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Staggered Only - 1st/2nd Row pitch <br />
                (s´in Fig. 6)
              </label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="staggeredOnly"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs">Grooves Perimetral Distance (T)</label>
              <div class="input-group xs-mb-15">
                <input
                  formControlName="groovesPerimetralDistance"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="col-md-12">
              <label class="input-xs"
                >Gang Only (Fig. 4). Number of Grooves</label
              >
              <div class="input-group xs-mb-15 slotsAttr">
                <input
                  formControlName="gangOnly"
                  class="form-control input-xs no-spin"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'gangOnlyWarning'"
                  [ngClass]="{
                    'input-warning': gangOnly?.warnings?.length > 0,
                    'input-error':
                      (gangOnly.touched || gangOnly.dirty) && gangOnly.errors?.delimited
                  }"
                />
                <warning-message
                  [id]="'gangOnlyWarning'"
                  class="text-danger error-warning-text"
                ></warning-message>
                <div class="text-danger error-warning-text" *ngIf="gangOnly.errors?.delimited">
                  {{ gangOnly.errors?.errorMessage }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="input-xs"
                >Gang Only (Fig. 4). Circ.Separation</label
              >
              <div class="input-group xs-mb-15 slotsAttr">
                <input
                  formControlName="gangOnlyCircSeparation"
                  class="form-control input-xs no-spin"
                  commaToDot
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" id="rowImg">
          <div class="col-md-1"></div>
          <div class="col-md-4" style="display: flex; justify-content: center; flex-wrap: wrap;">
            <div class="col-md-12">
            <div class="image-border" style="display: flex; justify-content: center; height: 200px; align-items: center;">
              <img
                src="../../../../../assets/img/GrooveSAW.png"
                class="static-img-2"
              />
            </div>
          </div>
            <div class="col-md-12 slots-ref">
              <p>
                Fig. 5 <br />
                Groove made with saw
              </p>
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4" style="display: flex; justify-content: center; flex-wrap: wrap;">
            <div class="col-md-12">
            <div class="image-border" style="display: flex; justify-content: center;">
              <img
                src="../../../../../assets/img/References-Columns.png"
                class="static-img"
              />
              <img
                src="../../../../../assets/img/References-Axes.png"
                class="static-img"
              />
            </div>
          </div>
            <div class="col-md-12 slots-ref">
              <p>
                Fig. 6 <br />
                References
              </p>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </form>
  </div>
</div>
