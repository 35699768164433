import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatSelGuidelinesComponentsService {
  private fgOpt1Source = new BehaviorSubject<any>(null);
  private modelOpt1Source = new BehaviorSubject<any>(null);
  private fgOpt2Source = new BehaviorSubject<any>(null);
  private fgPhOpt2Source = new BehaviorSubject<any>(null);
  private modelOpt2Source = new BehaviorSubject<any>(null);
  private fgCraSource = new BehaviorSubject<any>(null);
  private modelCraSource = new BehaviorSubject<any>(null);
  fgOpt1 = this.fgOpt1Source.asObservable();
  modelOpt1 = this.modelOpt1Source.asObservable();
  fgOpt2 = this.fgOpt2Source.asObservable();
  fgPhOpt2 = this.fgPhOpt2Source.asObservable();
  modelOpt2 = this.modelOpt2Source.asObservable();
  fgCra = this.fgCraSource.asObservable();
  modelCra = this.modelCraSource.asObservable();

  private formOpt1Source = new BehaviorSubject<any>(null);
  private formOpt2Source = new BehaviorSubject<any>(null);
  private formPhOpt2Source = new BehaviorSubject<any>(null);
  private formCraSource = new BehaviorSubject<any>(null);
  formOpt1 = this.formOpt1Source.asObservable();
  formOpt2 = this.formOpt2Source.asObservable();
  formPhOpt2 = this.formPhOpt2Source.asObservable();
  formCra = this.formCraSource.asObservable();

  private loadOpt1Source = new BehaviorSubject<any>(null);
  loadOpt1Obs = this.loadOpt1Source.asObservable();
  private loadOpt2Source = new BehaviorSubject<any>(null);
  loadOpt2Obs = this.loadOpt2Source.asObservable();
  private loadCraSource = new BehaviorSubject<any>(null);
  loadCraObs = this.loadCraSource.asObservable();

  private resetValuesSource = new BehaviorSubject<void>(null);
  resetValuesObs = this.resetValuesSource.asObservable();

  private formChangesSource = new BehaviorSubject<void>(null);
  formChanges = this.formChangesSource.asObservable();

  private opt1UnitsSource = new BehaviorSubject<any>(null);
  opt1UnitsObs = this.opt1UnitsSource.asObservable();
  private opt2UnitsSource = new BehaviorSubject<any>(null);
  opt2UnitsObs = this.opt2UnitsSource.asObservable();
  private craUnitsSource = new BehaviorSubject<any>(null);
  craUnitsObs = this.craUnitsSource.asObservable();

  private PPH2SOpt1Source = new BehaviorSubject<any>(null);
  PPH2SOpt1Obs = this.PPH2SOpt1Source.asObservable();

  private PPH2SCraSource = new BehaviorSubject<any>(null);
  PPH2SCraObs = this.PPH2SCraSource.asObservable();

  private isPrinteable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPrinteableOpt1: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPrinteableOpt2: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPrinteableCra: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hasPtCoord: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  updatePPH2SOpt1(value: any) {
    this.PPH2SOpt1Source.next(value);
  }

  getCurrentPPH2SOpt1() {
    return this.PPH2SOpt1Source.getValue();
  }

  updatePPH2SCra(value: any) {
    this.PPH2SCraSource.next(value);
  }

  getCurrentPPH2SCra() {
    return this.PPH2SCraSource.getValue();
  }

  updateOpt1Units(units: any) {
    this.opt1UnitsSource.next(units);
  }
  
  updateOpt2Units(units: any) {
    this.opt2UnitsSource.next(units);
  }

  updateCraUnits(units: any) {
    this.craUnitsSource.next(units);
  }

  updateFgOpt1(fg: any) {
    this.fgOpt1Source.next(fg);
  }

  updateFgOpt2(fg: any) {
    this.fgOpt2Source.next(fg);
  }

  updateFgPhOpt2(fg: any) {
    this.fgPhOpt2Source.next(fg);
  }

  updateFgCra(fg: any) {
    this.fgCraSource.next(fg);
  }

  updateFormOpt1(fg: any) {
    this.formOpt1Source.next(fg);
  }

  updateFormOpt2(fg: any) {
    this.formOpt2Source.next(fg);
  }

  updateFormPhOpt2(fg: any) {
    this.formPhOpt2Source.next(fg);
  }

  updateFormCra(fg: any) {
    this.formCraSource.next(fg);
  }

  updateModelOpt1(model: any) {
    this.modelOpt1Source.next(model);
  }

  updateModelOpt2(model: any) {
    this.modelOpt2Source.next(model);
  }

  updateModelCra(model: any) {
    this.modelCraSource.next(model);
  }

  loadOpt1(load: any) {
    this.loadOpt1Source.next(load);
  }

  loadOpt2(load: any) {
    this.loadOpt2Source.next(load);
  }
  
  loadCra(load: any) {
    this.loadCraSource.next(load);
  }

  resetValues() {
    this.resetValuesSource.next();
  }

  fireFormChanges() {
    this.formChangesSource.next();
  }

  getCurrentFgOpt1() {
    return this.fgOpt1Source.getValue();
  }

  getCurrentFgOpt2() {
    return this.fgOpt2Source.getValue();
  }

  getCurrentFgPhOpt2() {
    return this.fgPhOpt2Source.getValue();
  }

  getCurrentFgCra() {
    return this.fgCraSource.getValue();
  }

  getCurrentFormOpt1() {
    return this.formOpt1Source.getValue();
  }

  getCurrentFormOpt2() {
    return this.formOpt2Source.getValue();
  }

  getCurrentFormPhOpt2() {
    return this.formPhOpt2Source.getValue();
  }

  getCurrentFormCra() {
    return this.formCraSource.getValue();
  }

  getCurrentModelOpt1() {
    return this.modelOpt1Source.getValue();
  }

  getCurrentModelOpt2() {
    return this.modelOpt2Source.getValue();
  }

  getCurrentModelCra() {
    return this.modelCraSource.getValue();
  }

  getCurrentUnitsOpt1() {
    return this.opt1UnitsSource.getValue();
  }

  getCurrentUnitsOpt2() {
    return this.opt2UnitsSource.getValue();
  }

  getCurrentUnitsCra() {
    return this.craUnitsSource.getValue();
  }

  setIsPrinteable(value: boolean) {
    this.isPrinteable.next(value);
  }

  getIsPrinteable() {
    return this.isPrinteable.getValue();
  }

  setIsPrinteableOpt1(value: boolean) {
    this.isPrinteableOpt1.next(value);
  }

  getIsPrinteableOpt1() {
    return this.isPrinteableOpt1.getValue();
  }

  setIsPrinteableOpt2(value: boolean) {
    this.isPrinteableOpt2.next(value);
  }

  getIsPrinteableOpt2() {
    return this.isPrinteableOpt2.getValue();
  }

  setIsPrinteableCra(value: boolean) {
    this.isPrinteableCra.next(value);
  }

  getIsPrinteableCra() {
    return this.isPrinteableCra.getValue();
  }

  setHasPtCoord(value: boolean) {
    this.hasPtCoord.next(value);
  }

  getHasPtCoord() {
    return this.hasPtCoord.getValue();
  }
}
