export class CorrosionClasOutput {
    corrosionRates_CorrectedByOil: number;
    corrosionRates_CorrectedByComposition: number;
    correctionH2S_HSmoll: number;
    correctionH2S_H2Smoll: number;
    correctionH2S_FH2S: number;
    correctionH2S_HSppm: number;
    correctionH2S_H2Sppm: number;
    oilProtectionFactorRestrWC: number;
    temperatureScale: number;
    oilProtectionFactorCalc: number;
    scaleProtectionFactor: number;
    oilProtectionFactor: number;
    c_Factor: number;
    cr_Factor: number;
    transport: number;
    surfaceReactionSpeed: number;
    pH_Solution: number;
    pH_CO2: number;
    dissolvedSolids: number;
    wbreak: number;
    partialH2Spressure: number;
    partialCO2pressure: number;
    absoluteTemp: number;
    corrosionRates_CorrectedByCO2: number;
    corrosionRates_CorrectedByCO2andH2S: number;
}
