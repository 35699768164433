import { APIGravityUnits } from "../enums/api-gravity-units.enum";

export const API_GRAVITY_UNITS = {
  GRADES: {
    name: "Grades",
    symbol: "°",
    enum: APIGravityUnits.Grades,
    fractionDigits: 4,
  },
  GRAMS_CUBIC_CENTIMETER: {
    name: "Grams per cubic centimeter",
    symbol: "g/cm³",
    enum: APIGravityUnits.GramsCubicCentimeter,
    fractionDigits: 4,
  },
};

export const API_GRAVITY_UNITS_ARRAY: APIGravityUnit[] = [
  API_GRAVITY_UNITS.GRADES,
  API_GRAVITY_UNITS.GRAMS_CUBIC_CENTIMETER,
];

class APIGravityUnit {
  name: string;
  symbol: string;
  enum: APIGravityUnits;
  fractionDigits: number;
}
