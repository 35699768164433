<div
  id="create-customer-modal"
  tabindex="-1"
  role="dialog"
  class="modal fade modal-colored-header"
>
  <div class="modal-dialog custom-width">
    <div style="background-color: #009">
      <div class="panel-heading">
        <h3 style="color: white; font-weight: bold">Create Customer</h3>
        <button
          style="display: none"
          #closeCreateCustomerModal
          type="button"
          class="close"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
    </div>

    <div class="panel-body" style="background-color: white; color: black">
      <form
        [formGroup]="createCustomerForm"
        (ngSubmit)="createCustomer()"
        role="form"
        class="form-horizontal"
      >
        <div class="form-group" style="padding-bottom: 0px">
          <label for="inputCustomer" class="col-sm-4 control-label"
            >Description</label
          >
          <div class="col-sm-6">
            <ng-select
              class="pre-sim-ng-select"
              placeholder="Select"
              [items]="customers"
              formControlName="description"
              bindLabel="description"
              bindValue="description"
              clearAllText="Clear"
              [searchable]="true"
              appendTo="body"
              [addTag]="true"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-8">
            <div class="text-inverse">
              <button
                type="submit"
                class="btn btn-space btn-primary"
                [disabled]="createCustomerForm.invalid"
              >
                Create
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-space btn-default"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
