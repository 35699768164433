<div class="col-md-6">
  <div id="divInstallation" class="panel-default">
    <div id="pipesPanel" class="widget-head">
      <h3>Pipes</h3>
      <div
        style="font-size: 25px; cursor: pointer"
        [ngClass]="{
          disabled: this.fgSlottedPipe.get('fgPipes').disabled
        }"
      >
        <span
          [ngClass]="{
            'disabled-icon': this.fgSlottedPipe.get('fgPipes').disabled
          }"
          class="s7-search"
          data-toggle="modal"
          data-target="#dcpModal"
          data-placement="right"
          data-tooltip
          title="Search Pipes"
          (click)="resetModal()"
        ></span>
      </div>
    </div>
  </div>
  <div class="panel-body conditions-fields-container">
    <form [formGroup]="fgSlottedPipe">
      <div formGroupName="fgPipes">
        <div class="row">
          <div class="col-md-12">
            <p>
              Check pipe connection existance on
              <a href="https://dcp.tenaris.com/" target="_blank"
                >Tenaris Premium Connections Performance Datasheets
              </a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="input-xs">Outside Diameter (OD)</label>
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="outsideDiameter"
                  class="form-control input-xs no-spin"
                  [readOnly]="!canEditPipes"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'outsideDiameterWarning'"
                  [ngClass]="{
                    disabled: !canEditPipes,
                    'input-warning': outsideDiameter?.warnings?.length > 0 || wtByOdWarning,
                    'input-error':
                      ((outsideDiameter.touched || outsideDiameter.dirty) &&
                      outsideDiameter.errors?.delimited) || fgSlottedPipe.get('fgPipes').errors?.wtByOd
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
              <warning-message
                [id]="'outsideDiameterWarning'"
                class="text-danger error-warning-text"
              ></warning-message>
              <div
                class="text-danger error-warning-text"
                *ngIf="outsideDiameter.errors?.delimited"
              >
                {{ outsideDiameter.errors?.errorMessage }}
              </div>
              <span
                class="text-danger error-warning-text"
                *ngIf="fgSlottedPipe.get('fgPipes').errors?.wtByOd"
              >
                Wall thickness must be lower than half of outside diameter.
            </span>
              <span
                class="warning-message error-warning-text"
                *ngIf="wtByOdWarning"
              >
                Wall thickness must be lower than half of outside diameter.
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <label class="input-xs">Wall Thickness</label>
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="wallThickness"
                  class="form-control input-xs no-spin"
                  [readOnly]="!canEditPipes"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'wallThicknessWarning'"
                  [ngClass]="{
                    disabled: !canEditPipes,
                    'input-warning': wallThickness?.warnings?.length > 0 || wtByOdWarning,
                    'input-error':
                      ((wallThickness.touched || wallThickness.dirty) &&
                      wallThickness.errors?.delimited) || fgSlottedPipe.get('fgPipes').errors?.wtByOd
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  in</span
                >
              </div>
              <warning-message
                [id]="'wallThicknessWarning'"
                class="text-danger error-warning-text"
              ></warning-message>
              <div
                class="text-danger error-warning-text"
                *ngIf="wallThickness.errors?.delimited"
              >
                {{ wallThickness.errors?.errorMessage }}
              </div>
              <span
                class="text-danger error-warning-text"
                *ngIf="fgSlottedPipe.get('fgPipes').errors?.wtByOd"
              >
                Wall thickness must be lower than half of outside diameter.
              </span>
              <span
                class="warning-message error-warning-text"
                *ngIf="wtByOdWarning"
              >
                Wall thickness must be lower than half of outside diameter.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="input-xs">Nominal Weight</label>
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="nominalWeight"
                  class="form-control input-xs no-spin"
                  [readOnly]="!canEditPipes"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'nominalWeightWarning'"
                  [ngClass]="{
                    disabled: !canEditPipes,
                    'input-warning': nominalWeight?.warnings?.length > 0,
                    'input-error':
                      (nominalWeight.touched || nominalWeight.dirty) &&
                      nominalWeight.errors?.delimited
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  lb/ft
                </span>
              </div>
              <warning-message
                [id]="'nominalWeightWarning'"
                class="text-danger error-warning-text"
              ></warning-message>
              <div
                class="text-danger error-warning-text"
                *ngIf="nominalWeight.errors?.delimited"
              >
                {{ nominalWeight.errors?.errorMessage }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="input-xs">Min. Wall Thickness (API Tolerance)</label>
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="minWallThickness"
                  class="form-control input-xs no-spin"
                  [readOnly]="!canEditPipes"
                  commaToDot
                  warningMessage
                  [warningContainerId]="'minWallThicknessWarning'"
                  [ngClass]="{
                    disabled: !canEditPipes,
                    'input-warning': minWallThickness?.warnings?.length > 0,
                    'input-error':
                      (minWallThickness.touched || minWallThickness.dirty) &&
                      minWallThickness.errors?.delimited
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  %
                </span>
              </div>
              <warning-message
                [id]="'minWallThicknessWarning'"
                class="text-danger error-warning-text"
              ></warning-message>
              <div
                class="text-danger error-warning-text"
                *ngIf="minWallThickness.errors?.delimited"
              >
                {{ minWallThickness.errors?.errorMessage }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="input-xs">Grade</label>
            <div class="input-group xs-mb-15" style="width: 100%">
              <input
                hidden
                formControlName="gradeWarning"
                warningMessage
                [warningContainerId]="'gradeWarning'"
              />
              <input
                formControlName="grade"
                class="form-control input-xs no-spin"
                [readOnly]="!canEditPipes"
                [ngClass]="{
                  disabled: !canEditPipes,
                  'input-warning': gradeWarning?.warnings?.length > 0
                }"
              />
              <warning-message
                [id]="'gradeWarning'"
                class="text-danger error-warning-text"
              ></warning-message>
            </div>
          </div>
          <div class="col-md-6">
            <label class="input-xs">Connection</label>
            <div class="input-group xs-mb-15" style="width: 100%">
              <input
                formControlName="connection"
                class="form-control input-xs no-spin"
                [readOnly]="!canEditPipes"
                [ngClass]="{ disabled: !canEditPipes }"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="input-xs">Grade Strength</label>
            <div class="input-group xs-mb-15">
              <input
                formControlName="gradeStrength"
                class="form-control input-xs no-spin"
                commaToDot
                [readOnly]="!canEditPipes"
                [ngClass]="{ disabled: !canEditPipes }"
              />
              <span class="input-group-addon input-xs label-dts thickness-0">
                ksi
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <label class="input-xs">Pipe Body Collapse Rating</label>
            <div class="input-group xs-mb-15">
              <input
                formControlName="pipeBodyCollapseRating"
                class="form-control input-xs no-spin"
                commaToDot
                [readOnly]="!canEditPipes"
                [ngClass]="{ disabled: !canEditPipes }"
              />
              <span class="input-group-addon input-xs label-dts thickness-0">
                psi</span
              >
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <button
          *ngIf="showDataCheck"
          data-toggle="modal"
          data-target="#dataCheckModal"
          data-placement="right"
          data-tooltip
          title="Data Check"
          type="button"
          class="datacheck"
          (click)="dataCheck()"
          tabindex="-1"
        >
          Data Check
        </button>
      </div>
    </div>
  </div>
</div>

<app-slotted-modal></app-slotted-modal>
