import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { Observable } from "rxjs";
import { APIResponse } from "../http/models/api-response";
import { catchError, map } from "rxjs/operators";
import { Grade } from "src/app/modules/mat-sel-guidelines/models/grade";
import {
  RunModelOpt1Request,
  RunModelOpt1Response,
} from "src/app/modules/mat-sel-guidelines/models/opt1";
import {
  CalculateOpt2Request,
  CalculateOpt2Response,
  RunModelOpt2Request,
} from "src/app/modules/mat-sel-guidelines/models/opt2";
import Swal from "sweetalert2";
import { RunModelCraRequest } from "src/app/modules/mat-sel-guidelines/models/cra";

@Injectable({
  providedIn: "root",
})
export class MatSelGuidelinesService {
  private readonly MODULE: string = "matselguidelines";
  private readonly VERSION: string = "v1";
  private readonly RESOURCE_OPT1: string = "MatSelGuidelinesOpt1";
  private readonly RESOURCE_OPT2: string = "MatSelGuidelinesOpt2";
  private readonly RESOURCE_CRA: string = "MatSelGuidelinesCra";
  private readonly RESOURCE_MSG: string = "MatSelGuidelines";
  private readonly GET_GRADES_ACTION: string = "GetGrades";
  private readonly RUN_MODEL_ACTION: string = "RunModel";
  private readonly CALCULATE_ACTION: string = "Calculate";
  private readonly SAVE_ACTION: string = "Save";
  private readonly BY_TREE_ID_ACTION: string = "byTreeId";
  private readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({
      "Content-type": "application/json",
    }),
  };

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getGrades(): Observable<string[]> {
    return this.http
      .get<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_OPT1,
          this.GET_GRADES_ACTION
        )
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  runModelOpt1(request: RunModelOpt1Request): Observable<RunModelOpt1Response> {
    return this.http
      .post<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_OPT1,
          this.RUN_MODEL_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else if (APIResponse.data.code === 500) {
            Swal.fire({
              title: APIResponse.data.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          } else {
            this.httpService.handleErrorMessage(APIResponse.status.message);
          }
        }),
        catchError(this.httpService.handleError)
      );
  }

  runModelOpt2(request: RunModelOpt2Request): Observable<any> {
    return this.http
      .post<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_OPT2,
          this.RUN_MODEL_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else if (APIResponse.data.code === 500) {
            Swal.fire({
              title: APIResponse.data.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          } else {
            this.httpService.handleErrorMessage(APIResponse.status.message);
          }
        }),
        catchError(this.httpService.handleError)
      );
  }

  calculate(request: CalculateOpt2Request): Observable<CalculateOpt2Response> {
    return this.http
      .post<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_OPT2,
          this.CALCULATE_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else if (APIResponse.data?.code === 500) {
            Swal.fire({
              title: APIResponse.data.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          } else {
            Swal.fire({
              title: APIResponse.status.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        }),
        catchError(this.httpService.handleError)
      );
  }

  runModelCra(request: RunModelCraRequest): Observable<any> {
    return this.http
      .post<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_CRA,
          this.RUN_MODEL_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else {
            Swal.fire({
              title: APIResponse.status.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        }),
        catchError(this.httpService.handleError)
      );
  }

  getGradesCra(minYs?: number): Observable<string[]> {
    let httpParams: HttpParams;
    if (minYs || minYs === 0) httpParams = new HttpParams().set("minYS", minYs);
    return this.http
      .get<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_CRA,
          this.GET_GRADES_ACTION
        ),
        { params: httpParams }
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  saveSimulation(request: any): Observable<any> {
    return this.http
      .post<any>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE_MSG,
          this.SAVE_ACTION
        ),
        request,
        this.HTTP_OPTIONS
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }

  loadSimulation(treeId: number) {
    return this.http.get<any>(        this.httpService.buildUrl(
      this.MODULE,
      this.VERSION,
      this.RESOURCE_MSG,
      `${this.BY_TREE_ID_ACTION}/${treeId}`
    ), this.HTTP_OPTIONS)
    .pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success) return APIResponse.data;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  deleteSimulation(treeId: number) {
    return this.http.delete<any>(this.httpService.buildUrl(
      this.MODULE,
      this.VERSION,
      this.RESOURCE_MSG,
      `${this.BY_TREE_ID_ACTION}/${treeId}`
    ), this.HTTP_OPTIONS)
    .pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success) return APIResponse.data;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }
}
