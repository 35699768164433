import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Groupings } from 'src/app/modules/slotted-perforated-pipe/shared/enums/groupings.enum';
import { Patterns } from '../../../shared/enums/patterns.enum';
import { SlottedService } from '../../services/slotted.service';

@Component({
  selector: 'app-slotted-type',
  templateUrl: './slotted-type.component.html',
  styleUrls: ['./slotted-type.component.css']
})
export class SlottedTypeComponent implements OnInit {

  @Input() fgSlottedPipe : FormGroup;


  constructor(private slottedService: SlottedService) { }

  ngOnInit(): void {
    this.fgSlottedPipe.get("fgType").get("pattern").valueChanges.subscribe((value) => {this.slottedService.setSlottedInputsDisable(value)});
    this.fgSlottedPipe.get("fgType").get("grouping").valueChanges.subscribe((value) => {this.slottedService.setSlottedInputsGang(value)});
    this.fgSlottedPipe.get("fgType").get("groovesMachinedWithSaw").valueChanges.subscribe((value) => {this.slottedService.setSlottedInputGrooves(value)});
  }
  
  setActiveGroupBtn($event: any){
    let clickedElement = $event.target || $event.srcElement; if( clickedElement.nodeName === "BUTTON" ) 
    { let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active"); 
    // if a Button already has Class: .active 
    if( isCertainButtonAlreadyActive ) { isCertainButtonAlreadyActive.classList.remove("active"); } 
    clickedElement.className += " active"; }
  }

  setFormControlValue(value: any, formControl: AbstractControl)
  {
      if(formControl.enabled)
        formControl.setValue(value);    
  }

  public get patterns(): typeof Patterns { 
    return Patterns; 
  }

  public get groupings(): typeof Groupings { 
    return Groupings; 
  }

}
