import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Patterns } from "../../shared/enums/patterns.enum";
import { PipeResults } from "../../shared/enums/slotted-results.enum";
import { Attachment } from "../../slotted-pipe/models/slotted-pipe";
import { LoadPerforatedOutputs, LoadSlottedPerforatedAttachments, Results } from "../models/perforated-pipe";

@Injectable({
  providedIn: "root",
})
export class PerforatedService {
  private perforatedResultsSource$: Subject<Results> = new Subject<Results>();
  perforatedResults$ = this.perforatedResultsSource$.asObservable();

  private showPerforatedResultsSource$: Subject<boolean> = new Subject<boolean>();
  showPerforatedResults$ = this.showPerforatedResultsSource$.asObservable();

  private attachmentsSource$: Subject<Attachment[]> = new Subject<Attachment[]>();
  attachments$ = this.attachmentsSource$.asObservable();

  private attachmentsUploadedSource$: Subject<LoadSlottedPerforatedAttachments[]> = new Subject<LoadSlottedPerforatedAttachments[]>();
  attachmentsUploaded$ = this.attachmentsUploadedSource$.asObservable();

  private dataCheckDataSource$: Subject<any> = new Subject<any>();
  dataCheckData$ = this.dataCheckDataSource$.asObservable();

  private submitEnabledSource$: Subject<any> = new Subject<any>();
  submitEnabled$ = this.submitEnabledSource$.asObservable();

  private showRangeModalSource$: Subject<boolean> = new Subject<boolean>();
  showRangeModal$ = this.showRangeModalSource$.asObservable();

  private dataCheckFixSource$: Subject<any> = new Subject<any>();
  dataCheckFix$ = this.dataCheckFixSource$.asObservable();

  private refreshDataCheckDataSource$: Subject<any> = new Subject<any>();
  refreshDataCheckData$ = this.refreshDataCheckDataSource$.asObservable();

  private patternSource$: Subject<any> = new Subject<any>();
  pattern$ = this.patternSource$.asObservable();

  private groovesSource$: Subject<boolean> = new Subject<boolean>();
  grooves$ = this.groovesSource$.asObservable();

  private dcpProductSource$: Subject<any> = new Subject<any>();
  dcpProduct$ = this.dcpProductSource$.asObservable();

  private closeDcpModalSource$: Subject<boolean> = new Subject<boolean>();
  closeDcpModal$ = this.closeDcpModalSource$.asObservable();

  private submitSource$: Subject<void> = new Subject<void>();
  submit$ = this.submitSource$.asObservable();

  constructor() {}

  setPerforatedPattern(pattern: Patterns){
    this.patternSource$.next(pattern);
  }

  setPerforatedGrooves(grooves: boolean){
    this.groovesSource$.next(grooves);
  }

  setCloseDcpModal(closeDcpModal: boolean) {
    this.closeDcpModalSource$.next(closeDcpModal);
  }

  setDcpProduct(dcpProduct: any) {
    this.dcpProductSource$.next(dcpProduct);
  }

  setRefreshDataCheckData(section: string, isSubmitting: boolean = false) {
    this.refreshDataCheckDataSource$.next({section: section, isSubmitting: isSubmitting});
  }

  setDataCheckFix(data: any, section: string, isSubmitting: boolean = false) {
    this.dataCheckFixSource$.next({data: data, section: section, isSubmitting: isSubmitting});
  }

  setPerforatedResults(slottedResults: Results) {
    this.perforatedResultsSource$.next(slottedResults);
  }

  setPerforatedResultsOnLoad(perforated: LoadPerforatedOutputs) {
    let slottedResults: Results = {
      openAreaInchAtID: perforated.open_area_inch_at_id,
      openAreaInchAtOD: perforated.open_area_inch_at_od,
      openAreaPercentAtID: perforated.open_area_percent_at_id,
      openAreaPercentAtOD: perforated.open_area_percent_at_od,
      results: {
        [PipeResults.Axial]: {
          pipeBodyRating: null,
          slottedPipeRating: null,
          reductionFactor: null
        },
        [PipeResults.Bending]: {
          pipeBodyRating: null,
          slottedPipeRating: null,
          reductionFactor: null
        },
        [PipeResults.Collapse]: {
          pipeBodyRating: null,
          slottedPipeRating: null,
          reductionFactor: null
        },
        [PipeResults.Torsion]: {
          pipeBodyRating: null,
          slottedPipeRating: null,
          reductionFactor: null
        },
      },
    };

    perforated.results.forEach((loadMode) => {
      switch (loadMode.key) {
        case PipeResults.Axial:
          slottedResults.results.Axial.pipeBodyRating =
            loadMode.pipe_body_rating;
          slottedResults.results.Axial.slottedPipeRating =
            loadMode.slotted_pipe_rating;
          slottedResults.results.Axial.reductionFactor =
            loadMode.reduction_factor;
        case PipeResults.Bending:
          slottedResults.results.Bending.pipeBodyRating =
            loadMode.pipe_body_rating;
          slottedResults.results.Bending.slottedPipeRating =
            loadMode.slotted_pipe_rating;
          slottedResults.results.Bending.reductionFactor =
            loadMode.reduction_factor;
        case PipeResults.Collapse:
          slottedResults.results.Collapse.pipeBodyRating =
            loadMode.pipe_body_rating;
          slottedResults.results.Collapse.slottedPipeRating =
            loadMode.slotted_pipe_rating;
          slottedResults.results.Collapse.reductionFactor =
            loadMode.reduction_factor;
        case PipeResults.Torsion:
          slottedResults.results.Torsion.pipeBodyRating =
            loadMode.pipe_body_rating;
          slottedResults.results.Torsion.slottedPipeRating =
            loadMode.slotted_pipe_rating;
          slottedResults.results.Torsion.reductionFactor =
            loadMode.reduction_factor;
      }
    });

    this.perforatedResultsSource$.next(slottedResults);
  }

  setShowPerforatedResults(showPerforatedResults: boolean) {
    this.showPerforatedResultsSource$.next(showPerforatedResults);

    if (!showPerforatedResults) {
      this.setPerforatedResults(null);
    }
  }

  setAttachments(attachments: Attachment[]) {
    this.attachmentsSource$.next(attachments);
  }

  setAttachmentsUploaded(attachments: LoadSlottedPerforatedAttachments[]) {
    this.attachmentsUploadedSource$.next(attachments);
  }

  setDataCheckData(dataCheckData: any, section: string, isSubmitting: boolean = false, anySectionWithErrors: boolean = false, anySectionWithWarnings: boolean = false) {
    this.dataCheckDataSource$.next({data: dataCheckData, section: section, isSubmitting: isSubmitting, anySectionWithErrors: anySectionWithErrors, anySectionWithWarnings: anySectionWithWarnings});
  }

  setShowRangeModal(show: boolean) {
    this.showRangeModalSource$.next(show);
  }

  submit() {
    this.submitSource$.next();
  }
}
