<style>
#cabecera{
    background-color: white;
    width: 100%;
    height: 95px;
    padding: 20px 0px 0px 0px;
}
*{
    margin: 0%;
    padding: 0%;
    border: 0px;
}
#logo
{
    background-color: #3d3c3c;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
    height: 70px;
    width: 100px;
    background-image: url(logo-full.png);
    /* background-image: url(/src/assets/img/logo-full.png); */
    background-repeat: no-repeat;
    margin-right: 20px;
    background-color: #FFF;
    position: relative;
    top: 1px;
    width: 300px;
    height: 70px;
    margin-left: 20px;
    padding-left: 0px;
    
}
#col-logo{
width: 33%;
padding-left: 0px;
}

#col-datos{
width: 10%;
text-align: right;
padding-right: 0px;
}
#data-caracteristicas{
width: 56%;
text-align: right;
padding-right: 0px;
}

#linea-divisora
{
    width: 100%;
    background-color: grey;
    height: 10px;
}
#contenedor-titulo{
    width: 100%;
    text-align: left;
}
#separador-titulo
{
    height: 5px;
    width: 100%;
    background-color: white;
}
#titulo{
    font-size: 38px;
}
body{
    padding: 0px 40px 50px 50px;
    background-color: white;   
}
#col1-linea{
    width: 30%;
    background-color: grey;
    height: 5px;
}
#data-user{
    text-align: right;
}
    .table>thead>tr>th {
        font-weight: bold;
        color: black;
        border-bottom: 1px solid black ;
        font-family: FrutigerRoman, sans-serif;
        font-size: 13px;
     }
     .cuadro-principal{
        border-color: #aaaaaa;
        border-width: 1px;
        border-style: solid;

     }

     #contenedor-subtitulo-groove
{
background-color: green;
color: white;
font-size: 12px;
text-align: center;
padding: 1px 20px 1px;
/* // margin-left: 5px; */
margin-bottom: 25px;
border: none;

font-weight: bold;
font-family: FrugiterBold,sans-serif;
font-size: 20px;
margin-bottom: 11px;
margin-top: 11px;

}
     </style>

<body>
    <header>
        <!--Logo y datos de user-->
        <div id="cabecera">
            <div id="col-logo" class="col-sm-3">
                <div id="logo">

                </div>
            </div>


             <div id="data-caracteristicas" class="col-sm-3">
                <div id="data-data">
                    <p>Report Number: <label for="" style="width: 130px;text-align:left;">1</label> </p>
                    <p>Request By: <label for="" style="width: 130px;text-align:left;">Raffaelo Zambetti</label></p>
                    <p>Reviewed By: <label for="" style="width: 130px;text-align:left;">Raffaelo Zambetti</label></p>
                    <p>Authorized By: <label for="" style="width: 130px;text-align:left;">Raffaelo Zambetti</label></p>       
                </div>             
             </div>


            <div id="col-datos" class="col-sm-3">
                <div id="data-user">
                    <p>Create By: Raffaelo Zambetti</p>
                    <p>Date: {{varFecha | date: 'mediumDate'}}</p>                    
                </div>
            </div>
        </div>
        <!--Linea divisora-->
        <div    id="linea">
            <div id="col1-linea"></div>
            <div id="col2-linea"></div>
        </div>
        <!--Titulo principal-->
        <div id="contenedor-titulo">
            <div id="titulo" class="title">
                <p  style="padding-bottom: 25px;padding-top: 20px;">Casing Wear</p>
            </div>
            <div id ="titulo2"></div>
        </div>
        <!--Espacio divisor déspues de titulo-->
        <div id="separador-titulo">

        </div>


    </header>
    <main>
        <div id="cuadro-principal">
            <!--    width="1508"-->
            <svg height="250" version="1.1" width="100%" xmlns="http://www.w3.org/2000/svg" style="overflow: hidden; position: relative; top: -0.2px;">
                <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">Created with Raphaël 2.1.0</desc>
                <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></defs>
                <text x="32.533334732055664" y="212.19999885559082" text-anchor="end" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">0</tspan>
                </text>
                <path fill="none" stroke="#aaaaaa" d="M45.033334732055664,212.19999885559082H1483" stroke-width="0.5" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <text x="32.533334732055664" y="165.39999914169312" text-anchor="end" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266661167144775">175</tspan>
                </text>
                <path fill="none" stroke="#aaaaaa" d="M45.033334732055664,165.39999914169312H1483" stroke-width="0.5" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <text x="32.533334732055664" y="118.59999942779541" text-anchor="end" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266656875610352">350</tspan>
                </text>
                <path fill="none" stroke="#aaaaaa" d="M45.033334732055664,118.59999942779541H1483" stroke-width="0.5" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <text x="32.533334732055664" y="71.7999997138977" text-anchor="end" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266664028167725">525</tspan>
                </text>
                <path fill="none" stroke="#aaaaaa" d="M45.033334732055664,71.7999997138977H1483" stroke-width="0.5" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <text x="32.533334732055664" y="25" text-anchor="end" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: end; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266665458679199">700</tspan>
                </text>
                <path fill="none" stroke="#aaaaaa" d="M45.033334732055664,25H1483" stroke-width="0.5" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <text x="1483" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2013</tspan>
                </text>
                <text x="1302.8850788884095" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2012</tspan>
                </text>
                <text x="1123.2622750476157" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2011</tspan>
                </text>
                <text x="943.6394712068218" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2010</tspan>
                </text>
                <text x="764.0166673660278" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2009</tspan>
                </text>
                <text x="583.9017462544373" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2008</tspan>
                </text>
                <text x="404.27894241364345" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2007</tspan>
                </text>
                <text x="224.65613857284956" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2006</tspan>
                </text>
                <text x="45.033334732055664" y="224.69999885559082" text-anchor="middle" font="10px &quot;Arial&quot;" stroke="none" fill="#888888" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-anchor: middle; font: 12px sans-serif;" font-size="12px" font-family="sans-serif" font-weight="normal" transform="matrix(1,0,0,1,0,6.4)">
                   <tspan style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" dy="4.266657829284668">2005</tspan>
                </text>
                <path fill="none" stroke="#666666" d="M45.033334732055664,209.5257131576538C89.93903569225414,203.5085703372955,179.75043761265107,190.13714184761048,224.65613857284956,185.4571418762207C269.56183953304804,180.77714190483093,359.37324145344496,171.75142767429352,404.27894241364345,172.08571338653564C449.18464337384194,172.41999909877777,538.9960452942388,190.80205487852712,583.9017462544373,188.13142757415773C628.930476532335,185.4534834826531,718.9879370881301,166.08960632919124,764.0166673660278,150.69142780303955C808.9223683262263,135.3353208029156,898.7337702466233,70.79714257717133,943.6394712068218,65.11428546905518C988.5451721670203,59.43142836093902,1078.3565740874174,95.19999957084656,1123.2622750476157,105.22857093811035C1168.167976007814,115.25714230537415,1257.979377928211,145.34285640716553,1302.8850788884095,145.34285640716553C1347.9138091663071,145.34285640716553,1437.9712697221023,115.25714230537415,1483,105.22857093811035" stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <path fill="none" stroke="#000099" d="M45.033334732055664,198.82857036590576C89.93903569225414,192.14285612106323,179.75043761265107,177.09999907016754,224.65613857284956,172.08571338653564C269.56183953304804,167.07142770290375,359.37324145344496,158.37999918460847,404.27894241364345,158.7142848968506C449.18464337384194,159.0485706090927,538.9960452942388,177.43062638884206,583.9017462544373,174.75999908447267C628.930476532335,172.08205499296804,718.9879370881301,152.7181778395062,764.0166673660278,137.31999931335451C808.9223683262263,121.96389231323056,898.7337702466233,57.42571408748627,943.6394712068218,51.74285697937012C988.5451721670203,46.059999871253964,1078.3565740874174,81.8285710811615,1123.2622750476157,91.8571424484253C1168.167976007814,101.88571381568909,1257.979377928211,131.97142791748047,1302.8850788884095,131.97142791748047C1347.9138091663071,131.97142791748047,1437.9712697221023,101.88571381568909,1483,91.8571424484253" stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <path fill="none" stroke="#cc0066" d="M45.033334732055664,172.08571338653564C89.93903569225414,165.39999914169312,179.75043761265107,150.35714209079742,224.65613857284956,145.34285640716553C269.56183953304804,140.32857072353363,359.37324145344496,131.63714220523835,404.27894241364345,131.97142791748047C449.18464337384194,132.3057136297226,538.9960452942388,150.68776940947194,583.9017462544373,148.01714210510255C628.930476532335,145.33919801359792,718.9879370881301,125.97532086013607,764.0166673660278,110.57714233398438C808.9223683262263,95.22103533386043,898.7337702466233,30.682857108116153,943.6394712068218,25C988.5451721670203,19.317142891883847,1078.3565740874174,55.085714101791375,1123.2622750476157,65.11428546905518C1168.167976007814,75.14285683631897,1257.979377928211,105.22857093811035,1302.8850788884095,105.22857093811035C1347.9138091663071,105.22857093811035,1437.9712697221023,75.14285683631897,1483,65.11428546905518" stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <path fill="none" stroke="#009900" d="M45.033334732055664,158.7142848968506C89.93903569225414,152.02857065200806,179.75043761265107,131.30285649299623,224.65613857284956,131.97142791748047C269.56183953304804,132.6399993419647,359.37324145344496,165.73428485393524,404.27894241364345,164.0628562927246C449.18464337384194,162.39142773151397,538.9960452942388,120.93679831911862,583.9017462544373,118.59999942779541C628.930476532335,116.25679834772885,718.9879370881301,150.36400157873672,764.0166673660278,145.34285640716553C808.9223683262263,140.33543021147293,898.7337702466233,81.8285710811615,943.6394712068218,78.48571395874023C988.5451721670203,75.14285683631897,1078.3565740874174,116.92857086658478,1123.2622750476157,118.59999942779541C1168.167976007814,120.27142798900604,1257.979377928211,93.52628451365615,1302.8850788884095,91.8571424484253C1347.9138091663071,90.18342739123489,1437.9712697221023,101.88571381568909,1483,105.22857093811035" stroke-width="3" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></path>
                <circle cx="45.033334732055664" cy="209.5257131576538" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="224.65613857284956" cy="185.4571418762207" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="404.27894241364345" cy="172.08571338653564" r="7" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="583.9017462544373" cy="188.13142757415773" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="764.0166673660278" cy="150.69142780303955" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="943.6394712068218" cy="65.11428546905518" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1123.2622750476157" cy="105.22857093811035" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1302.8850788884095" cy="145.34285640716553" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1483" cy="105.22857093811035" r="4" fill="#666666" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="45.033334732055664" cy="198.82857036590576" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="224.65613857284956" cy="172.08571338653564" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="404.27894241364345" cy="158.7142848968506" r="7" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="583.9017462544373" cy="174.75999908447267" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="764.0166673660278" cy="137.31999931335451" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="943.6394712068218" cy="51.74285697937012" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1123.2622750476157" cy="91.8571424484253" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1302.8850788884095" cy="131.97142791748047" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1483" cy="91.8571424484253" r="4" fill="#000099" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="45.033334732055664" cy="172.08571338653564" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="224.65613857284956" cy="145.34285640716553" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="404.27894241364345" cy="131.97142791748047" r="7" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="583.9017462544373" cy="148.01714210510255" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="764.0166673660278" cy="110.57714233398438" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="943.6394712068218" cy="25" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1123.2622750476157" cy="65.11428546905518" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1302.8850788884095" cy="105.22857093811035" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1483" cy="65.11428546905518" r="4" fill="#cc0066" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="45.033334732055664" cy="158.7142848968506" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="224.65613857284956" cy="131.97142791748047" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="404.27894241364345" cy="164.0628562927246" r="7" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="583.9017462544373" cy="118.59999942779541" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="764.0166673660278" cy="145.34285640716553" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="943.6394712068218" cy="78.48571395874023" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1123.2622750476157" cy="118.59999942779541" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1302.8850788884095" cy="91.8571424484253" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
                <circle cx="1483" cy="105.22857093811035" r="4" fill="#009900" stroke="#ffffff" stroke-width="1" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></circle>
             </svg>
        </div>

        <div class="panel-body" style="padding-bottom: 0px;padding-top: 0px;">
            <h2 class="title" id="contenedor-subtitulo-groove">Groove Depth 61% Of Nominal Wall Thickness</h2>
            <table class="table">
              <thead>
                <tr>
                  <th>Configuration</th>
                  <th>Load Mode</th>
                  <th>0%</th>
                  <th>10%</th>
                  <th>20%</th>
                  <th>30%</th>
                  <th>40%</th>
                  <th>50%</th>
                  <th>60%</th>
                  <th *ngIf="isAdmin">70%</th>
                  <th *ngIf="isAdmin">80%</th>
                  <th *ngIf="isAdmin">90%</th>
                  <th *ngIf="isAdmin">100%</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of table_data">
                  <td>{{item.configuration}}</td>
                  <td>{{item.load}}</td>
                  <td>{{item.col0}}</td>
                  <td>{{item.col10}}</td>
                  <td>{{item.col20}}</td>
                  <td>{{item.col30}}</td>
                  <td>{{item.col40}}</td>
                  <td>{{item.col50}}</td>
                  <td>{{item.col60}}</td>
                  <td *ngIf="isAdmin">{{item.col70}}</td>
                  <td *ngIf="isAdmin">{{item.col80}}</td>
                  <td *ngIf="isAdmin">{{item.col90}}</td>
                  <td *ngIf="isAdmin">{{item.col100}}</td>
                </tr>
              </tbody>
            </table>
          </div>

    </main>
    <footer>
        <div class="panel-body" style="padding-top: 0px;padding-bottom: 0px;">
            <h3 class="title" style="color: black;padding-top: 0px;">Description</h3>
            <p> <strong>Resistance Worn</strong> = Resistance Nom * Derating Coeff/100<br>
              Where :<br> 
              <strong>Resistance Worn</strong>  Is the resistance of the worn tubular;<br>
              <strong>Resistance Nom</strong> Is the resistance of the new component (i.e. from data-sheets)<br>
              <strong>Derating Coeff</strong> Is the calculated derating for the give wear depth<br>
              [1] user calculations Interpolated</p>
        </div>
        <div id="parrafo-uno">
            <div class="col-sm-12">
        <div id="linea-divisora" style="height: 1px;">
        </div>
        <!-- <br> -->
                <p class="parrafos-inferiores" style="padding-top: 0px;">
                    Tenaris has produced this report for general information only, and the information in this report is not intended to constitute professional or any other
                    type of advice and is provided on an “as is” basis. No warranty is given. Tenaris has not independently verified any information –if any- provided to
                    Tenaris in connection with, or for the purpose of, the information contained hereunder. The use of the information is at user’s own risk and Tenaris
                    does
                    not assume any responsibility or liability of any kind for any loss, damage or injury resulting from, or in connection with any information provided
                    hereunder or the use thereof. Tenaris products and services are subject to the Company’s standard terms and conditions or otherwise to the terms
                    resulting from the respective contracts of sale, services or license, as the case may be. Unless specifically agreed under such contract of sale, services
                    or license, if Tenaris is required to provide any warranty or assume any liability in connection with the information provided hereinabove, any such
                    warranty or liability shall be subject to the execution of a separate written agreement between petitioner and Tenaris. This report is confidential and no
                    part of this report may be reproduced or disclosed in any form or by any means whatsoever, without prior permission from Tenaris. For more
                    complete
                    information please contact a Tenaris representative or visit our website at www.tenaris.com. ©Tenaris 2022. All rights reserved.                              
                </p>
            </div>
    
        </div>

        <div id="parrafo-dos">
            <div class="col-sm-12" style="text-align: center;">
                    Page 1 of 1
            </div>
        </div>
    </footer>
</body>



