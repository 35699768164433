import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { UnitMeasures } from "../enums/unit-measures.enum";
import { UnitMeasureService } from "../../services/unit-measures.service";
import { Subscription } from "rxjs";

@Directive({
  selector: "[interchangeableUnit]",
})
export class InterchangeableUnitDirective implements OnInit, OnDestroy {
  @Input() unitMeasure: UnitMeasures;
  @Input() selectedUnit: any;
  @Input() defaultControl: AbstractControl;
  @Input() unitControl: AbstractControl;
  @Input() controlDisabledUnit: boolean = true;
  @Input() object?: any;
  controlValueChanges: Subscription;

  constructor(
    private ngControl: NgControl,
    private unitMeasureService: UnitMeasureService
  ) {}

  ngOnInit(): void {
    this.configureControlValueChanges();
  }

  private configureControlValueChanges() {
    this.controlValueChanges = this.ngControl.control.valueChanges.subscribe(
      (convertedValue: any) => {
        convertedValue = String(convertedValue);

        let convertedValueReplaced: string = convertedValue.replace(",", ".");
        let convertedValueNumber: number =
          convertedValueReplaced != "" ? +convertedValueReplaced : null;
          
        if (
          (this.ngControl.control.valid &&
            !Number.isNaN(convertedValueNumber)) ||
          convertedValueNumber == null
        ) {
          this.defaultControl.setValue(
            convertedValueNumber != null
              ? this.unitMeasureService.convertUnit(
                  convertedValueNumber,
                  this.unitMeasure,
                  this.selectedUnit,
                  true,
                  this.unitMeasureService.getUnitFractionDigits(
                    this.unitMeasure,
                    this.selectedUnit
                  ),
                  this.object
                )
              : null
          );
          if (this.controlDisabledUnit)
            this.unitControl.enable({ emitEvent: false });
        } else if (this.controlDisabledUnit)
          this.unitControl.disable({ emitEvent: false });
      }
    );
  }

  ngOnDestroy(): void {
    this.controlValueChanges.unsubscribe();
  }
}
