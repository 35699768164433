<div style="font-size: 14px; margin-bottom: 10px; margin-left: -8px">
  Complete the following fields: Model Validity
  <span id="rangePopUp" (click)="rangePopUp()"><b>Ranges</b></span>
</div>
<div class="row">
  <div class="col-4 col-sm-4">
    <form [formGroup]="fg" (ngSubmit)="onSubmit()">
      <div class="row" style="min-height: 80px">
        <div class="col-sm-4 control-label input-m">
          <span><b>INPUT</b></span>
        </div>
        <div
          class="col-sm-8"
          style="text-align: right; height: 25px; font-size: 18px"
        >
          <button
            type="button"
            class="btn-no-styles"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Reset"
            (click)="onNew()"
          >
            <b>Reset</b>
            <span class="icon s7-refresh"></span>
          </button>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">Temperature</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="minTemperature"
              [ngClass]="{
                'input-error':
                  (form.minTemperature.touched || form.dirty) &&
                  form.minTemperature.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="minTemperature"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'minTemperatureError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
              [selectedUnit]="minTemperatureUnit.value"
              [defaultControl]="form.minTemperatureDefault"
              [unitControl]="minTemperatureUnit"
              warningMessage
              [warningContainerId]="'minTemperatureWarning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="minTemperatureUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
                [convertedControl]="form.minTemperature"
                [defaultControl]="form.minTemperatureDefault"
                [items]="TEMPERATURE_UNITS_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="minTemperatureError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'minTemperatureWarning'"
            [ngClass]="{ 'd-none': form.minTemperature.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Total Pressure</label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="totalPressure"
              [ngClass]="{
                'input-error':
                  (form.totalPressure.touched || form.dirty) &&
                  form.totalPressure.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="totalPressure"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'totalPressureError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="totalPressureUnit.value"
              [defaultControl]="form.totalPressureDefault"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
              [unitControl]="totalPressureUnit"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="totalPressureUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="form.totalPressure"
                [defaultControl]="form.totalPressureDefault"
                [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
                [items]="PRESSURE_UNITS_KPA_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="totalPressureError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >H<sub>2</sub>S</label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="h2s"
              [ngClass]="{
                'input-error':
                  (form.h2s.touched || form.dirty) && form.h2s.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="h2s"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'h2sError'"
            />
            <span
              class="unit input-group-addon input-xs label-dts thickness-0 not-allowed"
              >%</span
            >
          </div>
          <error-message
            id="h2sError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 control-label input-xs" style="height: 100%"
          >Grade</label
        >
        <div class="col-sm-8">
          <ng-select
            id="grade"
            formControlName="grade"
            [ngClass]="{
              'grade-acceptable':
                model &&
                !model.criteria15156opt1
                  ?.toLowerCase()
                  .includes('not acceptable'),
              'grade-not-acceptable':
                model &&
                model.criteria15156opt1
                  ?.toLowerCase()
                  .includes('not acceptable')
            }"
            class="grades-ng-select"
            placeholder="Select"
            [items]="grades"
            clearAllText="Clear"
            [searchable]="true"
          >
          </ng-select>
        </div>
      </div>

      <div class="row" style="margin-top: 15px">
        <div class="col-sm-4 control-label input-xs">
          <button type="submit" class="btn btn-primary" [disabled]="fg.invalid">
            Run Model
          </button>
        </div>
        <div class="col-sm-8"></div>
      </div>
    </form>
  </div>
  <div *ngIf="model" class="col-4 col-sm-4">
    <div class="row" style="min-height: 80px">
      <div class="col-sm-12 control-label input-m">
        <span><b>OUTPUT</b></span>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label class="col-sm-4 control-label input-xs"
          ><span>ppH<sub>2</sub>S</span></label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              [(ngModel)]="PPH2S"
              class="form-control input-xs no-spin"
              readonly
            />
            <span
              class="unit input-group-addon input-xs label-dts not-allowed"
              >{{ unitPPH2S }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-4 control-label input-xs"
        ><span>Sour Condition</span></label
      >
      <div class="col-sm-8">
        <div class="input-group xs-mb-15">
          <label class="control-label"
            ><span
              ><b>{{ model.sourCondition }}</b></span
            ></label
          >
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="model" class="col-4 col-sm-4">
    <div class="row" style="min-height: 80px">
      <div class="col-sm-12 control-label input-m">
        <span><b>ISO 15156-2-2020 Criteria</b></span>
      </div>
    </div>
    <div class="row">
      <div
        [ngStyle]="{
          'background-color': model.backgroundColor
        }"
        class="col-sm-12 control-label text-m results-opt1"
      >
        <span
          ><b>{{ model.criteria15156opt1 }}</b></span
        >
      </div>
    </div>
    <div class="row">
      <div class="grades-table">
        <div class="comments">
          {{ model?.recommendedGradesComment }}
        </div>
        <div class="grades">
          <div *ngFor="let grade of model?.recommendedGrades">{{ grade }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="rangeOpt1" class="boundary range-container">
  <div
    id="rangeOpt1"
    class="range-cr-box"
    appFreeDragging
    appFreeDraggingHandle
  >
    <button type="button" class="close" (click)="closeModal()">&times;</button>
    <div id="rangeTitleContainer">
      <p id="rangesModalTitle"><b> Valid Ranges </b></p>
      <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
        <div class="swal2-icon-content" style="font-size: 1.75em">!</div>
      </div>
    </div>
    <div id="rangeVal">
      <p>Temperature 20 - 150 °C</p>
      <p>Total Pressure 0 - 20000 kPa</p>
      <p>% H<sub>2</sub>S >= 0</p>
    </div>
  </div>
</div>
