<div class="col-md-6">
  <div id="divInstallation" class="panel-default">
    <div id="typePanel" class="widget-head">
      <h3>Type</h3>
    </div>
    <div class="panel-body">
      <form [formGroup]="fgSlottedPipe">
        <div formGroupName="fgType">
          <div class="row type">
            <div class="col-sm-6 type-label">
              <label>Grooves Machined with Saw</label>
            </div>
            <div class="col-sm-6 type-btn">
              <input formControlName="groovesMachinedWithSaw" hidden />
              <button
              style="margin: 0 6px 0 0;"
                (click)="
                  setFormControlValue(
                    true,
                    fgSlottedPipe.get('fgType').get('groovesMachinedWithSaw')
                  )
                "
                [ngClass]="{
                  active:
                    fgSlottedPipe?.get('fgType').get('groovesMachinedWithSaw')
                      .value === true
                }"
                class="groupBTN slotted-pipe col-md-6 btn btn-alt4 btn-rad"
                type="button"
              >
                Yes
              </button>
              <button
              style="margin: 0 0 0 6px;"
                (click)="
                  setFormControlValue(
                    false,
                    fgSlottedPipe.get('fgType').get('groovesMachinedWithSaw')
                  )
                "
                [ngClass]="{
                  active:
                    fgSlottedPipe?.get('fgType').get('groovesMachinedWithSaw')
                      .value === false
                }"
                class="groupBTN slotted-pipe col-md-6 btn btn-alt4"
                type="button"
              >
                No
              </button>
            </div>
          </div>
          <div class="row type">
            <div class="col-sm-6 type-label">
              <label>Pattern</label>
            </div>
            <div class="col-sm-6 type-btn">
              <input formControlName="pattern" hidden />
              <div
                [ngClass]="{
                  disabled: this.fgSlottedPipe.get('fgType').get('pattern')
                    .disabled
                }"
              >
                <button
                  (click)="
                    setFormControlValue(
                      patterns.Staggered,
                      fgSlottedPipe.get('fgType').get('pattern')
                    )
                  "
                  style="margin: 0 6px 0 0;"
                  [ngClass]="{
                    active:
                      fgSlottedPipe?.get('fgType').get('pattern').value ===
                        patterns.Staggered &&
                      fgSlottedPipe?.get('fgType').get('pattern').enabled,
                    'disabled-section': this.fgSlottedPipe
                      .get('fgType')
                      .get('pattern').disabled
                  }"
                  class="groupBTN slotted-pipe col-md-6 btn btn-alt4 btn-rad"
                  type="button"
                >
                  Staggered
                </button>
              </div>
              <div
                [ngClass]="{
                  disabled: this.fgSlottedPipe.get('fgType').get('pattern')
                    .disabled
                }"
              >
                <button
                  (click)="
                    setFormControlValue(
                      patterns.Aligned,
                      fgSlottedPipe.get('fgType').get('pattern')
                    )
                  "
                  style="margin: 0 0 0 6px;"
                  [ngClass]="{
                    active:
                      fgSlottedPipe?.get('fgType').get('pattern').value ===
                        patterns.Aligned &&
                      fgSlottedPipe?.get('fgType').get('pattern').enabled,
                    'disabled-section': this.fgSlottedPipe
                      .get('fgType')
                      .get('pattern').disabled
                  }"
                  class="groupBTN slotted-pipe col-md-6 btn btn-alt4"
                  type="button"
                >
                  Aligned
                </button>
              </div>
            </div>
          </div>
          <div id="groupingRow" class="row">
            <div class="col-sm-6 type-label">
              <label>Grouping</label>
            </div>
            <div class="col-sm-6 type-btn" (click)="setActiveGroupBtn($event)">
              <input formControlName="grouping" hidden />
              <div
                [ngClass]="{
                  disabled: this.fgSlottedPipe.get('fgType').get('grouping')
                    .disabled
                }"
              >
                <button
                  (click)="
                    setFormControlValue(
                      groupings.Single,
                      fgSlottedPipe.get('fgType').get('grouping')
                    )
                  "
                  style="margin: 0 6px 0 0;"
                  [ngClass]="{
                    active:
                      fgSlottedPipe?.get('fgType').get('grouping').value ===
                        groupings.Single &&
                      fgSlottedPipe?.get('fgType').get('grouping').enabled,
                    'disabled-section': this.fgSlottedPipe
                      .get('fgType')
                      .get('grouping').disabled
                  }"
                  class="groupBTN slotted-pipe col-md-6 btn btn-alt4 btn-rad"
                  type="button"
                >
                  Single
                </button>
              </div>
              <div
                [ngClass]="{
                  disabled: this.fgSlottedPipe.get('fgType').get('grouping')
                    .disabled
                }"
              >
                <button
                  (click)="
                    setFormControlValue(
                      groupings.Gangs,
                      fgSlottedPipe.get('fgType').get('grouping')
                    )
                  "
                  style="margin: 0 0 0 6px;"
                  [ngClass]="{
                    active:
                      fgSlottedPipe?.get('fgType').get('grouping').value ===
                        groupings.Gangs &&
                      fgSlottedPipe?.get('fgType').get('grouping').enabled,
                    'disabled-section': this.fgSlottedPipe
                      .get('fgType')
                      .get('grouping').disabled
                  }"
                  class="groupBTN slotted-pipe col-md-6 btn btn-alt4"
                  type="button"
                >
                  Gangs
                </button>
              </div>
            </div>
          </div>
          <div class="row row-img">
            <div class="col-md-3 type-col">
              <div
                [ngClass]="{
                  selectedOption:
                    fgSlottedPipe?.get('fgType').get('pattern').value ==
                      patterns.Staggered &&
                    fgSlottedPipe?.get('fgType').get('pattern').enabled
                }"
              >
                <img
                  src="../../../../../assets/img/Staggered.png"
                  class="type-image"
                />
              </div>
            </div>
            <div class="col-md-3 type-col">
              <div
                [ngClass]="{
                  selectedOption:
                    fgSlottedPipe?.get('fgType').get('pattern').value ==
                      patterns.Aligned &&
                    fgSlottedPipe?.get('fgType').get('pattern').enabled
                }"
              >
                <img
                  src="../../../../../assets/img/Aligned.png"
                  class="type-image"
                  id="alignedImg"
                />
              </div>
            </div>
            <div class="col-md-3 type-col">
              <div
                [ngClass]="{
                  selectedOption:
                    fgSlottedPipe?.get('fgType').get('grouping').value ==
                      groupings.Single &&
                    fgSlottedPipe?.get('fgType').get('grouping').enabled
                }"
              >
                <img
                  src="../../../../../assets/img/Single.png"
                  class="type-image"
                  id="singleImg"
                />
              </div>
            </div>
            <div class="col-md-3 type-col">
              <div
                [ngClass]="{
                  selectedOption:
                    fgSlottedPipe?.get('fgType').get('grouping').value ==
                      groupings.Gangs &&
                    fgSlottedPipe?.get('fgType').get('grouping').enabled
                }"
                id="gangedContainer"
              >
                <img
                  src="../../../../../assets/img/Ganged.png"
                  class="type-image"
                  id="firstGangedImg"
                />
                <img
                  src="../../../../../assets/img/Ganged - 2.png"
                  class="type-image"
                  id="gangedImg"
                />
              </div>
            </div>
          </div>
          <div class="row row-img">
            <div class="col-md-3 type-col">
              <p>
                Fig. 1 <br />
                Staggered
              </p>
            </div>
            <div class="col-md-3 type-col">
              <p>
                Fig. 2 <br />
                Aligned
              </p>
            </div>
            <div class="col-md-3 type-col">
              <p>
                Fig. 3 <br />
                Single
              </p>
            </div>
            <div class="col-md-3 type-col">
              <p>
                Fig. 4 <br />
                Gangs
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
