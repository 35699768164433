import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-perforated-notes',
  templateUrl: './perforated-notes.component.html',
  styleUrls: ['./perforated-notes.component.css']
})
export class PerforatedNotesComponent implements OnInit {

  @Input() fgPerforatedPipe : FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
