import { TreeUser } from "src/app/models/tree/Index";

export function getTreeFiltered(user: TreeUser, searchText: string): TreeUser {

  for (let i2 = 0; i2 < user.customers.length; i2++) {
    const cus = user.customers[i2];
    if (cus.customerName.toLocaleLowerCase().indexOf(searchText) >= 0) {
      cus.visible = true;
      break;
    } else {
      cus.visible = false;
    }
    for (let i3 = 0; i3 < cus?.regions.length; i3++) {
      const reg = cus?.regions[i3];
      if (reg.regionName.toLocaleLowerCase().indexOf(searchText) >= 0) {
        cus.visible = true;
        reg.visible = true;
        break;
      } else {
        reg.visible = false;
      }
      for (let i4 = 0; i4 < reg?.projects.length; i4++) {
        const pro = reg?.projects[i4];
        if (pro.projectName.toLocaleLowerCase().indexOf(searchText) >= 0) {
          cus.visible = true;
          reg.visible = true;
          pro.visible = true;
          break;          
        } else {
          pro.visible = false;
        }
        for (let i5 = 0; i5 < pro?.simulations.length; i5++) {
          const sim = pro?.simulations[i5];
          if (sim.simulationName.toLocaleLowerCase().indexOf(searchText) >= 0) {
            cus.visible = true;
            reg.visible = true;
            pro.visible = true;
            sim.visible = true;
          } else {
            sim.visible = false;
          }
        }
      }
    }
  }
  return user;
  
}