<div class="am-content">
    <div class="main-content">
      <div class="page-head">
         <h2 class="title">Well Design</h2>
         <ol class="breadcrumb">
            <li>
               <a [routerLink]="NAVIGATIONS.HOME.route">{{NAVIGATIONS.HOME.name}}</a>
            </li>
          <li class="active">{{ NAVIGATIONS.HELP.name }}</li>
         </ol>
      </div>
      <div class="row">
         <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 style="font-family: 'Open Sans',sans-serif;color: #808080;">Help</h3>
              </div>
              <div class="panel-body">
                <ul>
                  <li> <a href="https://ior.ad/8cSJ?iframeHash=trysteps-1" target="_blank">Create simulación tree</a></li>
                  <li> <a href="https://ior.ad/8dc8?iframeHash=trysteps-1" target="_blank">Remove item from tree</a></li>
                </ul>
              </div>
            </div>
         </div>
      </div>
    </div>
    </div>