import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms"
import { ActivatedRoute } from "@angular/router";
import { HomeComponent } from 'src/app/pages/home/Home.component';
import { Subscription } from "rxjs";
import { CorrosionClasOutput } from "src/app/models/corrosionclas/Index";
import { CorrosionClasInput } from "src/app/models/corrosionclas/CorrosionClasInput";
import { CorrosionClas } from "src/app/models/corrosionclas/CorrosionClass";
import { TreeAction } from "src/app/models/tree/TreeAction";
import { ActionService } from "src/app/services/Action.service";
import { CorrosionclasService } from "src/app/services/Corrosionclas.service";
import Swal from 'sweetalert2';
// import { Console } from 'console';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-corrosionclaspdf',
  templateUrl: 'corrosionclaspdf.component.html',
  styleUrls: ['./corrosionclaspdf.component.scss']
})
export class CorrosionclaspdfComponent implements OnInit, OnDestroy {

  isAdmin : boolean;
  paramId : string;
  //Actions
  subscription: Subscription;
  selected: TreeAction = { id: 0, text: '', level: '', activate: false, action: '', path: '', parents: '', userId: 0, notes: '', visibility: '' };
  
  //CorrosionCLAS
  title = "Corrosion CLAS";
  isOutputVisible: boolean = false;
  formCorrosionClasPdf: FormGroup;
  inputValues: CorrosionClasInput = null;
  outputValues: CorrosionClasOutput = null;
  simulation: CorrosionClas = null;

  varFecha:number = Date.now();
  constructor(

    private fb: FormBuilder,
    public service: CorrosionclasService,
    public actionService: ActionService,
    private route: ActivatedRoute
    ){
    this.isAdmin = false;

    // this.serviceHome.setAuth(this.isAdmin);


  }


  // setAutorizado(){
  //   this.isAdmin=true;
  //   this.serviceHome.setAuth(this.isAdmin);


  //   }

    ngOnInit() {

        this.paramId=this.route.snapshot.params.id
        this.createForm();
        // this.getAction();
        // this.route.params.subscribe(params => {
        //   this.selected.id = params.id;
        //   this.selected.action = params.action;
        // });
        this.selected.id = Number(this.paramId);
        this.selected.action = 'load';
        if (this.selected.id > 0 && this.selected.action == 'load') {
          this.resetInput();
          this.resetOutput();
          this.loadSimulation();
        }
        // this.submitData();





        // this.formCorrosionClasPdf.get('iTemperature').disable();
        // this.formCorrosionClasPdf.get('iCO2perc').disable();

        // this.formCorrosionClasPdf.get('iTotalPressure').disable();
        // this.formCorrosionClasPdf.get('iCO2perc').disable();
        // alert(this.simulation.temperature);
        // this.loadSimulation();
        
      }
    
      getAction() {
        this.subscription = this.actionService.currentMessage.subscribe(value => {
          this.selected.action = value;
          if (this.selected.action == 'new') {
            this.resetInput();
            this.selected.action = "default";
          };
        });
      }
    
      loadSimulation() {
        this.service.getSimulationById(this.selected.id).subscribe(simulation => {
          //this.simulation = simulation.data;
          this.simulation = simulation['data'];
          this.joinInfo();
        });
      }
    
      createForm() {

        this.formCorrosionClasPdf = this.fb.group({
          // Create Inputs controls

          "iTemperature": new FormControl(80),
          "iTotalPressure": new FormControl(100),
          "iCO2perc": new FormControl(10),
          "iH2Sperc": new FormControl(0),
          "radioOption": new FormControl("quenched"),
          "iPipeOD": new FormControl(120),
          "iPipeWT": new FormControl(10),
          "iPipeID": new FormControl({ value: "", disabled: true }),
          "iCperc": new FormControl(0.2),
          "iCrperc": new FormControl(1),
          "iOilProduction": new FormControl(339.31),
          "iWaterProduction": new FormControl(339.31),
          "iWatercut": new FormControl({ value: "", disabled: true }),
          "iDesviacion": new FormControl(10),
          "iAPIoilGravity": new FormControl(25),
          "iLiquidVelocity": new FormControl(1),
          "iNa": new FormControl(15000),
          "iK": new FormControl(0),
          "iCa": new FormControl(0),
          "iMg": new FormControl(0),
          "iBa": new FormControl(0),
          "iSr": new FormControl(0),
          "iCl": new FormControl(20000),
          "iHCO3": new FormControl(100),
          "iSO42": new FormControl(0),
          "iFe": new FormControl(0),
          "iAcetato": new FormControl(0),
    
          // Create Outputs controls
          "oDissolvedSolids": new FormControl({ value: "", disabled: true }),
          "oAbsoluteTemp": new FormControl({ value: "", disabled: true }),
          "oPartialCO2pressure": new FormControl({ value: "", disabled: true }),
          "oPartialH2Spressure": new FormControl({ value: "", disabled: true }),
          "oWbreak": new FormControl({ value: "", disabled: true }),
        });

      }
    
      sumWaterComposition() {
    
        let waterChemicalComp: number = 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iNa").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iK").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iCa").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iMg").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iBa").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iSr").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iCl").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iHCO3").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iSO42").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iFe").value || 0;
        waterChemicalComp += this.formCorrosionClasPdf.get("iAcetato").value || 0;
    
        this.formCorrosionClasPdf.get("oDissolvedSolids").setValue(waterChemicalComp);
      }
    
      resetInput() {
        //this.formCorrosionClasPdf.get("radioOptions").setValue("normalizado");
        this.isOutputVisible = false;
        this.formCorrosionClasPdf.get("iTemperature").setValue(null);
        this.formCorrosionClasPdf.get("iTotalPressure").setValue(null);
        this.formCorrosionClasPdf.get("iCO2perc").setValue(null);
        this.formCorrosionClasPdf.get("iH2Sperc").setValue(null);
        this.formCorrosionClasPdf.get("iPipeOD").setValue(null);
        this.formCorrosionClasPdf.get("iPipeWT").setValue(null);
        this.formCorrosionClasPdf.get("iCperc").setValue(null);
        this.formCorrosionClasPdf.get("iCrperc").setValue(null);
        this.formCorrosionClasPdf.get("iOilProduction").setValue(null);
        this.formCorrosionClasPdf.get("iWaterProduction").setValue(null);
        this.formCorrosionClasPdf.get("iDesviacion").setValue(null);
        this.formCorrosionClasPdf.get("iAPIoilGravity").setValue(null);
        this.formCorrosionClasPdf.get("iLiquidVelocity").setValue(null);
        this.formCorrosionClasPdf.get("iPipeID").setValue(null);
        this.formCorrosionClasPdf.get("iWatercut").setValue(null);
        this.formCorrosionClasPdf.get("iNa").setValue(null);
        this.formCorrosionClasPdf.get("iK").setValue(null);
        this.formCorrosionClasPdf.get("iCa").setValue(null);
        this.formCorrosionClasPdf.get("iMg").setValue(null);
        this.formCorrosionClasPdf.get("iBa").setValue(null);
        this.formCorrosionClasPdf.get("iSr").setValue(null);
        this.formCorrosionClasPdf.get("iCl").setValue(null);
        this.formCorrosionClasPdf.get("iHCO3").setValue(null);
        this.formCorrosionClasPdf.get("iSO42").setValue(null);
        this.formCorrosionClasPdf.get("iFe").setValue(null);
        this.formCorrosionClasPdf.get("iAcetato").setValue(null);
        this.formCorrosionClasPdf.get("oDissolvedSolids").setValue(null);
        this.formCorrosionClasPdf.get("oWbreak").setValue(null);
      }
    
      resetOutput() {
        this.isOutputVisible = false;
        this.outputValues = null;
      }
    
      submitData() {
        this.inputValues = this.formCorrosionClasPdf.value;
        this.inputValues.oWbreak = 0;
        this.service.postCalculation(this.inputValues).subscribe(calc => {
          if (calc.status.success == true) {
            this.resetOutput();
            this.outputValues = <CorrosionClasOutput>calc.data;
            this.isOutputVisible = true;
            this.showOutput();
          } else {
            Swal.fire({
              title: 'An error occurred',
              icon: 'error',
              confirmButtonColor: '#009',
              cancelButtonColor: '#aaa',
            });
          }
        });
      }
    
      showOutput() {
        this.formCorrosionClasPdf.get("oDissolvedSolids").setValue(this.outputValues.dissolvedSolids);
        this.formCorrosionClasPdf.get("oAbsoluteTemp").setValue(this.outputValues.absoluteTemp.toFixed(3));
        this.formCorrosionClasPdf.get("oPartialCO2pressure").setValue(this.outputValues.partialCO2pressure.toFixed(3));
        this.formCorrosionClasPdf.get("oPartialH2Spressure").setValue(this.outputValues.partialH2Spressure.toFixed(3));
        this.formCorrosionClasPdf.get("oWbreak").setValue(this.outputValues.wbreak);
      }
    
      joinInfo() {

        this.formCorrosionClasPdf.get("iTemperature").setValue(this.simulation.temperature);
        this.formCorrosionClasPdf.get("iTotalPressure").setValue(this.simulation.total_pressure);
        this.formCorrosionClasPdf.get("iCO2perc").setValue(this.simulation.cO2_per);
        this.formCorrosionClasPdf.get("iH2Sperc").setValue(this.simulation.h2S_per);
        this.formCorrosionClasPdf.get("iPipeOD").setValue(this.simulation.external_pipe_diam);
        this.formCorrosionClasPdf.get("iPipeWT").setValue(this.simulation.pipe_wall_thickness);
        this.formCorrosionClasPdf.get("iCperc").setValue(this.simulation.c_per);
        this.formCorrosionClasPdf.get("iCrperc").setValue(this.simulation.cr_per);
        this.formCorrosionClasPdf.get("iOilProduction").setValue(this.simulation.oil_production);
        this.formCorrosionClasPdf.get("iWaterProduction").setValue(this.simulation.water_production);
        this.formCorrosionClasPdf.get("iDesviacion").setValue(this.simulation.deviation);
        this.formCorrosionClasPdf.get("iAPIoilGravity").setValue(this.simulation.api_oil_gravity);
        this.formCorrosionClasPdf.get("iLiquidVelocity").setValue(this.simulation.liquid_velocity);
        this.formCorrosionClasPdf.get("iWatercut").setValue(this.simulation.watercut.toFixed(3));
        this.formCorrosionClasPdf.get("iNa").setValue(this.simulation.na);
        this.formCorrosionClasPdf.get("iK").setValue(this.simulation.k);
        this.formCorrosionClasPdf.get("iCa").setValue(this.simulation.ca);
        this.formCorrosionClasPdf.get("iMg").setValue(this.simulation.mg);
        this.formCorrosionClasPdf.get("iBa").setValue(this.simulation.ba);
        this.formCorrosionClasPdf.get("iSr").setValue(this.simulation.sr);
        this.formCorrosionClasPdf.get("iCl").setValue(this.simulation.cl);
        this.formCorrosionClasPdf.get("iHCO3").setValue(this.simulation.hcO3);
        this.formCorrosionClasPdf.get("iSO42").setValue(this.simulation.sO4);
        this.formCorrosionClasPdf.get("iFe").setValue(this.simulation.fe);
        this.formCorrosionClasPdf.get("iAcetato").setValue(this.simulation.acetate);
        this.formCorrosionClasPdf.get("oWbreak").setValue(this.simulation.wbreak);
        this.formCorrosionClasPdf.get("oDissolvedSolids").setValue(this.simulation.dissolvedSolids);
    
        this.isOutputVisible = true;
        this.outputValues = new CorrosionClasOutput()
        this.outputValues.pH_CO2 = this.simulation.ph_pure_H2O_CO2;
        this.outputValues.pH_Solution = this.simulation.ph_solution;
        this.outputValues.surfaceReactionSpeed = this.simulation.reaction_at_surface;
        this.outputValues.transport = this.simulation.transport;
        this.outputValues.oilProtectionFactor = this.simulation.oil_protection_factor_foil;
        this.outputValues.oilProtectionFactorCalc = this.simulation.oil_protection_factor_foil_calc;
        this.outputValues.oilProtectionFactorRestrWC = this.simulation.oil_protection_factor_foil_restrwc;
        this.outputValues.scaleProtectionFactor = this.simulation.scale_protection_factor_fscale;
        this.outputValues.temperatureScale = this.simulation.scale_protection_factor_tsc;
        this.outputValues.cr_Factor = this.simulation.chromium_factor;
        this.outputValues.c_Factor = this.simulation.carbon_factor;
        this.outputValues.correctionH2S_H2Sppm = this.simulation.h2S_ppm;
        this.outputValues.correctionH2S_HSppm = this.simulation.hS_ppm;
        this.outputValues.correctionH2S_H2Smoll = this.simulation.h2S_mol_liter;
        this.outputValues.correctionH2S_HSmoll = this.simulation.hS_mol_liter;
        this.outputValues.correctionH2S_FH2S = this.simulation.fH2S;
        this.outputValues.corrosionRates_CorrectedByComposition = this.simulation.corrected_by_composition;
        this.outputValues.corrosionRates_CorrectedByOil = this.simulation.corrected_by_oil;
        this.outputValues.corrosionRates_CorrectedByCO2 = this.simulation.corrected_by_scale;
        this.outputValues.corrosionRates_CorrectedByCO2andH2S = this.simulation.corrected_by_scales_CO2_or_H2S;
      }
    
      ngOnDestroy() {
        this.subscription.unsubscribe();
      }

}