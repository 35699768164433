import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import {
  SearchItemsRequest,
  SearchItemsResponse
} from "src/app/modules/slotted-perforated-pipe/shared/slotted-modal/models/dcp-modal";
import { environment } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import { APIResponse } from "../http/models/api-response";

@Injectable({
  providedIn: "root",
})
export class DcpService {
  private readonly API_ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getProductsByFilter(
    searchItemsRequest: SearchItemsRequest
  ): Observable<SearchItemsResponse> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      searchItemsRequest,
      httpParams,
      "searchItemsRequest"
    );

    const URL: string = `${this.API_ENDPOINT}elasticsearch/v1/ElasticSearch/Search`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as SearchItemsResponse;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }
}
