import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import Swal from "sweetalert2";
import { CasingWearData, ComboReq } from "../../models/Casingwear";
import { FilterAction } from "../../models/Filteraction";
import { CasingwearService } from "../../services/Casingwaer.service";
declare var jQuery: any;

@Component({
  selector: "app-filtroform",
  templateUrl: "filtroform.component.html",
  styleUrls: ["filtroform.component.scss"],
})
export class FiltroformComponent implements OnInit {
  @Input() item: FilterAction = {
    action: "",
    nroItem: 0,
    valFiltro0: 0,
    valFiltro1: 0,
    valFiltro2: 0,
    valFiltro3: 0,
    valFiltro4: "",
    visible: true,
    titles: false,
    label: "",
  };
  @Output() filterAction = new EventEmitter<FilterAction>();
  @Output() productCliked: EventEmitter<any> = new EventEmitter();

  filter: any = [];
  casingWearData: CasingWearData;
  casingWearOne: CasingWearData;
  objComboReq: ComboReq;
  ResOutside_diameter: number[];
  ResWall_thickness: number[];
  ResTool_join_od: number[];
  ResGrade: number[];
  ResLoadMode: string[];
  public selectedOutside_diameter: number;
  public selectedWall_thickness: number;
  public selectedTool_join_odr: number;
  public selectedGrade: number;
  public selectedLoadMode: string;
  public outside_diameters: number[];
  public wall_thickness: number[];

  constructor(private service: CasingwearService) {}

  ngOnInit(): void {
    this.fillDropDown(
      "",
      this.selectedOutside_diameter,
      this.selectedWall_thickness,
      this.selectedTool_join_odr,
      this.selectedGrade,
      this.selectedLoadMode
    );
  }

  onChangedDropDown(event: Event, nameDropDown: string, nroItem: number): void {
    this.fillDropDown(
      nameDropDown,
      this.selectedOutside_diameter,
      this.selectedWall_thickness,
      this.selectedTool_join_odr,
      this.selectedGrade,
      this.selectedLoadMode
    );

    jQuery("#cwf" + (nroItem) + "0").val(this.selectedOutside_diameter);
    jQuery("#cwf" + (nroItem) + "1").val(this.selectedWall_thickness);
    jQuery("#cwf" + (nroItem) + "2").val(this.selectedTool_join_odr);
    jQuery("#cwf" + (nroItem) + "3").val(this.selectedGrade);
    jQuery("#cwf" + (nroItem) + "4").val(this.selectedLoadMode);

    if (this.fullAllDropDown()) {
      if (this.item.nroItem > 4) {
        this.item.nroItem = 4;
      }
      let filter: FilterAction = {
        action: "complete",
        nroItem: this.item.nroItem,
        valFiltro0: this.selectedOutside_diameter,
        valFiltro1: this.selectedWall_thickness,
        valFiltro2: this.selectedTool_join_odr,
        valFiltro3: this.selectedGrade,
        valFiltro4: this.selectedLoadMode,
        visible: true,
        titles: false,
        label: this.createLabel(
          this.selectedOutside_diameter,
          this.selectedWall_thickness,
          this.selectedTool_join_odr,
          this.selectedGrade,
          this.selectedLoadMode
        ),
      };
      this.filterAction.emit(filter);
    } else {
      let filter: FilterAction = {
        action: "refresh",
        nroItem: this.item.nroItem,
        valFiltro0: 0,
        valFiltro1: 0,
        valFiltro2: 0,
        valFiltro3: 0,
        valFiltro4: "",
        visible: true,
        titles: false,
        label: "",
      };
      this.filterAction.emit(filter);
    }
  }

  fullAllDropDown(): boolean {
    if (
      this.selectedOutside_diameter &&
      this.selectedWall_thickness &&
      this.selectedTool_join_odr &&
      this.selectedGrade &&
      this.selectedLoadMode
    )
      return true;
    else return false;
  }

  fillDropDown(
    pfield: string,
    poutside_diameter: number,
    pwall_thickness: number,
    ptool_join_od: number,
    pgrade: number,
    ploadMode: string
  ): any {
    let tobjComboReq: ComboReq = {
      field: pfield,
      id: 0,
      outside_diameter: poutside_diameter ? poutside_diameter : 0,
      wall_thickness: pwall_thickness ? pwall_thickness : 0,
      tool_join_od: ptool_join_od ? ptool_join_od : 0,
      grade: pgrade ? pgrade : 0,
      percent: 0,
      loadMode: ploadMode,
      lpfMax: 0,
      lpfEnd: 0,
      staDate: new Date(Date.now()),
    };

    this.service.GetDropdownHttp(tobjComboReq).subscribe((casinwear) => {
      this.ResOutside_diameter = casinwear["data"]["aOd"];
      this.ResWall_thickness = casinwear["data"]["aWT"];
      this.ResTool_join_od = casinwear["data"]["aTj"];
      this.ResGrade = casinwear["data"]["aGr"];
      this.ResLoadMode = casinwear["data"]["aLm"];
    });
  }

  createLabel(
    od: number,
    wt: number,
    odgr: number,
    grade: number,
    load: string
  ): string {
    let label: string = `${od}" x ${wt}" TJOD: ${odgr}" ${grade} [ksi] ${load}`;
    return label;
  }

  cleanFilterLine(nroItem: number) {
    this.selectedOutside_diameter = null;
    this.selectedWall_thickness = null;
    this.selectedTool_join_odr = null;
    this.selectedGrade = null;
    this.selectedLoadMode = null;

    jQuery("#cwf" + (nroItem) + "0").val(null);
    jQuery("#cwf" + (nroItem) + "1").val(null);
    jQuery("#cwf" + (nroItem) + "2").val(null);
    jQuery("#cwf" + (nroItem) + "3").val(null);
    jQuery("#cwf" + (nroItem) + "4").val(null);

    this.fillDropDown(
      "",
      this.selectedOutside_diameter,
      this.selectedWall_thickness,
      this.selectedTool_join_odr,
      this.selectedGrade,
      this.selectedLoadMode
    );

    let filter: FilterAction = {
      action: "clean",
      nroItem: nroItem,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: true,
      titles: false,
      label: "",
    };
    this.filterAction.emit(filter);
  }

  cleanItems(nroItem: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "The values on filter will be cleared",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.cleanFilterLine(nroItem);
        let filter: FilterAction = {
          action: "refresh",
          nroItem: this.item.nroItem,
          valFiltro0: 0,
          valFiltro1: 0,
          valFiltro2: 0,
          valFiltro3: 0,
          valFiltro4: "",
          visible: true,
          titles: false,
          label: "",
        };
        this.filterAction.emit(filter);
      }
    });
  }

  addNewItem() {
    if (this.item.nroItem > 4) {
      this.item.nroItem = 4;
    }
    let filter: FilterAction = {
      action: "new",
      nroItem: this.item.nroItem,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: true,
      titles: false,
      label: "",
    };
    this.filterAction.emit(filter);
  }

  copyItems(nroItem: number) {
    let indexToCopy = 1;

    while(!(jQuery("#cwf" + (nroItem - indexToCopy) + "0").length > 0))
      indexToCopy++;

    this.selectedOutside_diameter = jQuery("#cwf" + (nroItem - indexToCopy) + "0").val() ? jQuery("#cwf" + (nroItem - indexToCopy) + "0").val() : null;
    this.selectedWall_thickness = jQuery("#cwf" + (nroItem - indexToCopy) + "1").val() ? jQuery("#cwf" + (nroItem - indexToCopy) + "1").val() : null;
    this.selectedTool_join_odr = jQuery("#cwf" + (nroItem - indexToCopy) + "2").val() ? jQuery("#cwf" + (nroItem - indexToCopy) + "2").val() : null;
    this.selectedGrade = jQuery("#cwf" + (nroItem - indexToCopy) + "3").val() ? jQuery("#cwf" + (nroItem - indexToCopy) + "3").val() : null;
    this.selectedLoadMode = jQuery("#cwf" + (nroItem - indexToCopy) + "4").val() ? jQuery("#cwf" + (nroItem - indexToCopy) + "4").val() : null;

    jQuery("#cwf" + (nroItem) + "0").val(this.selectedOutside_diameter);
    jQuery("#cwf" + (nroItem) + "1").val(this.selectedWall_thickness);
    jQuery("#cwf" + (nroItem) + "2").val(this.selectedTool_join_odr);
    jQuery("#cwf" + (nroItem) + "3").val(this.selectedGrade);
    jQuery("#cwf" + (nroItem) + "4").val(this.selectedLoadMode);

    this.fillDropDown(
      "",
      this.selectedOutside_diameter,
      this.selectedWall_thickness,
      this.selectedTool_join_odr,
      this.selectedGrade,
      this.selectedLoadMode
    );

    if (this.fullAllDropDown()) {
      let filter: FilterAction = {
        action: "complete",
        nroItem: nroItem,
        valFiltro0: this.selectedOutside_diameter,
        valFiltro1: this.selectedWall_thickness,
        valFiltro2: this.selectedTool_join_odr,
        valFiltro3: this.selectedGrade,
        valFiltro4: this.selectedLoadMode,
        visible: true,
        titles: false,
        label: this.createLabel(
          this.selectedOutside_diameter,
          this.selectedWall_thickness,
          this.selectedTool_join_odr,
          this.selectedGrade,
          this.selectedLoadMode
        ),
      };

      this.filterAction.emit(filter);
    } else {
      let filter: FilterAction = {
        action: "emptyData",
        nroItem: nroItem,
        valFiltro0: this.selectedOutside_diameter,
        valFiltro1: this.selectedWall_thickness,
        valFiltro2: this.selectedTool_join_odr,
        valFiltro3: this.selectedGrade,
        valFiltro4: this.selectedLoadMode,
        visible: true,
        titles: false,
        label: this.createLabel(
          this.selectedOutside_diameter,
          this.selectedWall_thickness,
          this.selectedTool_join_odr,
          this.selectedGrade,
          this.selectedLoadMode
        ),
      };

      this.filterAction.emit(filter);
    }
  }

  deleteItems(nroItem: number) {
    if (this.item.nroItem < 0) {
      this.item.nroItem = 0;
    }
    this.cleanFilterLine(nroItem);
    let filter: FilterAction = {
      action: "delete",
      nroItem: nroItem,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: false,
      titles: false,
      label: "",
    };
    this.filterAction.emit(filter);
  }

  getAllCasingWear() {
    this.service.getHttpAllCasingWear().subscribe((casinwear) => {
      this.casingWearData = casinwear["data"];
    });
  }

  getLoadMode() {
    this.service.getLoadMode().subscribe((load) => {
      this.ResLoadMode = load;
    });
  }
}
