import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import Swal from "sweetalert2";
import { DataService } from "src/app/services/Data.service";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { DataTableDirective } from "angular-datatables";
import { UserMgr } from "src/app/pages/usermgr/components/Usermgr.model";
import { CustomerService } from "src/app/services/customer/customer.service";
import {
  CustomerMgr,
  DeleteActiveCustomerRequest,
  EditActiveCustomerRequest,
  VerifyActiveCustomerRequest,
} from "./models/customermgr";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";

@Component({
  selector: "app-customermgr",
  templateUrl: "./customermgr.component.html",
  styleUrls: ["./customermgr.component.css"],
})
export class CustomermgrComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  title = "Customer Mgr";
  userMgrData: UserMgr[];
  userMgrDataForEdit: UserMgr[];

  customers: CustomerMgr[];
  customersCopy: CustomerMgr[];

  onlyNotVerified: boolean = false;

  body = document.body;

  customer: CustomerMgr = {
    id: 0,
    description: "",
    verified: false,
    lastUpdateUser: null,
    lastUpdateDate: null,
  };

  readonly NAVIGATIONS = NAVIGATIONS;

  constructor(
    public dataService: DataService,
    private customerService: CustomerService,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit() {
    this.loadCustomers();

    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 50,
      order: [[2, "asc"]],
      columnDefs: [{ targets: [4], type: "date" }],
    };
    this.preSimulationService.customerCreated$.subscribe((customer) => {
      if (customer) {
        this.refreshTable();
        this.alertCreated();
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.preSimulationService.resetValues();
  }

  loadCustomers() {
    this.customerService.getActiveCustomers().subscribe((customers) => {
      this.customers = customers;
      this.customersCopy = customers;
      this.preSimulationService.setCustomers(customers);
      this.dtTrigger.next();
    });
  }

  addCustomer() {
    this.preSimulationService.setCustomers(this.customersCopy);
  }

  editCustomer(id: number) {
    const foundCustomer = this.customers.find((item) => id === item.id);
    this.customersCopy = [...this.customers];
    if (foundCustomer) {
      this.customer = { ...foundCustomer };
    }
  }

  verifyCustomer(id: number) {
    const foundCustomer = this.customers.find((item) => id === item.id);
    this.customersCopy = [...this.customers];
    if (foundCustomer) {
      this.customer = { ...foundCustomer };
    }
  }

  submitEdit() {
    const editCustomerRequest: EditActiveCustomerRequest = {
      id: this.customer.id,
      userId: this.dataService.getUserId(),
      description: this.customer.description,
    };

    this.customerService
      .editActiveCustomer(editCustomerRequest)
      .subscribe(() => {
        this.refreshTable();
        this.alertUpdated();
      });
  }

  submitVerify() {
    const verifyCustomerRequest: VerifyActiveCustomerRequest = {
      id: this.customer.id,
      userId: this.dataService.getUserId(),
      verified: this.customer.verified,
    };

    this.customerService
      .verifyActiveCustomer(verifyCustomerRequest)
      .subscribe(() => {
        this.refreshTable();
        this.alertUpdated();
      });
  }

  handleCustomerDeletionNotice(id: number) {
    this.customer = this.customers.find((item) => id === item.id);

    this.confirmDelete();
  }

  alertUpdated() {
    Swal.fire({
      title: "Updated!",
      text: "The customer has been updated.",
      icon: "success",
      confirmButtonColor: "#009",
      timer: 8000,
      timerProgressBar: true,
    });
  }

  alertCreated() {
    Swal.fire({
      title: "Created!",
      text: "The customer has been created.",
      icon: "success",
      confirmButtonColor: "#009",
      timer: 8000,
      timerProgressBar: true,
    });
  }

  confirmDelete() {
    Swal.fire({
      title: "Do you want to delete the customer:",
      text: this.customer.description,
      icon: "warning",
      confirmButtonColor: "#009",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure to delete the customer:",
          text: this.customer.description,
          icon: "warning",
          confirmButtonColor: "#009",
          showCancelButton: true,
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed)
            this.deleteCustomer(this.customer.id);
        });
      }
    });
  }

  deleteCustomer(id: number) {
    const deleteCustomerRequest: DeleteActiveCustomerRequest = {
      id: id,
      userId: this.dataService.getUserId(),
    };

    this.customerService
      .deleteActiveCustomer(deleteCustomerRequest)
      .subscribe(() => {
        this.alertDelete();
        this.refreshTable();
      });
  }

  alertDelete() {
    Swal.fire({
      title: "The customer was deleted.",
      icon: "success",
      confirmButtonColor: "#009",
      timer: 8000,
      timerProgressBar: true,
    });
  }

  refreshTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.customerService
        .getActiveCustomers(null, this.onlyNotVerified)
        .subscribe((customers) => {
          dtInstance.clear().draw();

          // Destroy the table first
          dtInstance.destroy();

          // Set the new data
          this.customers = customers;
          this.customersCopy = customers;

          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
    });
  }
}
