import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { HeaderState } from 'src/app/models/header/HeaderState';
import { TreeAction } from 'src/app/models/tree/TreeAction';
import { TreeLevels } from 'src/app/models/tree/TreeLevels';
import { TreeService } from 'src/app/services/Tree.service';
import { activateTreeItem, createTree } from '../../helpers/Index';
import Swal from 'sweetalert2';
import { TreeNode } from 'src/app/models/tree/TreeNode';
import { DataService } from 'src/app/services/Data.service';
import { take } from 'rxjs/operators';
declare var jQuery: any;

@Component({
  selector: 'app-tree-load',
  templateUrl: './tree.load.component.html',
})
export class TreeLoadComponent implements OnInit  {
  title : string = 'Tree-Load';
  
  //Tree values
  @Output() canActivateActionsMenu = new EventEmitter<TreeAction>();
  @Input() userId: number = 0;
  @Input() module: string = '';
  @Input() department: string = '';
  @Input() rol: string = '';
  onlyMySimulations: boolean;

  searchText: string = null;
  @Input() set search(value: string) { this.searchText = value; }
    
  tree: TreeLevels;
  selected: TreeAction = { id: 0, text: '', level: '', activate: false, action: '', path: '', parents: '', userId: 0, notes: '', visibility: '' };
  nameUser: string;
  userNode: TreeNode;
  constructor(public treeService: TreeService,public dataService: DataService) {}
  ngOnInit(): void {
    this.dataService.currentUserIdSource.subscribe(userId => (this.userId= userId));
    this.dataService.currentModuloSource.subscribe(module => (this.module= module));
    this.ValidaIngresoUsuario();
    this.dataService.currentDepartmentSource.subscribe(dpto => (this.department= dpto));
    this.dataService.currentNameSurnameSource.subscribe(nameSurname => (this.nameUser= nameSurname));
    this.dataService.currentRolSource.pipe(take(1)).subscribe(rol => (this.rol= rol));
    this.treeService.observableOnlyMySimulations.subscribe(onlyMySimulationsValue => {
      if(this.onlyMySimulations != undefined)
        this.getTree(this.userId, this.module, onlyMySimulationsValue);

      this.onlyMySimulations = onlyMySimulationsValue;
    });
  }

  getTree(userId: number, module:string, onlyMySimulations: boolean) {
    this.treeService.getNodeTreeByUserIdVisibility(this.userId, this.department, this.rol, this.module, onlyMySimulations).subscribe(levels => {
      if (levels.status.success){
        this.tree = createTree(levels.data, true);
      }
    },
      err  => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }

  canActivateActions(selected: TreeAction){
    selected.userId = this.userId;
    selected.action = HeaderState.load;
    this.selected = selected;
    this.selected = activateTreeItem(this.tree, HeaderState.load, selected);
    this.canActivateActionsMenu.emit(selected);
  }

  ValidaIngresoUsuario() {
    //validar si existe, si no se inserta en la tabla TREE.    
    this.treeService.getTreeByUserId(this.userId,this.module).subscribe(
      levels => {

        this.userNode = levels['data'].find(item => item.item_level == 'user');

        if (levels['data'] == null || levels['data'] == undefined || levels['data'].length < 1) {

          //no existe en la tabla tree, insertar user
          let newNode: TreeNode = {
            id: 0,
            user_id: this.userId,
            item_id: 1,
            item_name: this.nameUser,
            item_level: 'user',
            parent_id: null,
            module: this.module,
            last_update_date: null,
            creation_date: null,
            deletion_date: null,
            deleted_flag: false
          };
          //graba el row tree
          this.treeService.postNodeTree(newNode).subscribe(
            node => {
              if (node.status.success) {
                this.getTree(this.userId, this.module, true);
              }
            });
        } else {
          //actualiza el nombre en la tabla tree con el nombre de la tabla user

          this.treeService.getNodeTreeById(this.userNode['id']).subscribe(node => {
            if (node.status.success) {
              let newNode: TreeNode = node.data;
              newNode.item_name = this.nameUser;
      
              this.treeService.putNodeTree(this.userNode['id'], newNode).subscribe(node => {
                this.getTree(this.userId, this.module, true);
              });
            }
          });
        }
      });
  }
}
