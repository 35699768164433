<div class="row">
  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label for="cwf{{ item.nroItem }}0"
          >Outside Diameter [in]</label
        >
      </div>
      <div class="container-select">
        <ng-select
          [(ngModel)]="selectedOutside_diameter"
          id="cwf{{ item.nroItem }}0"
          class="cw-ng-select"
          (ngModelChange)="onChangedDropDown($event, 'od', item.nroItem)"
          placeholder="Select"
          clearAllText="Clear"
        >
          <ng-option
            *ngFor="let resCasingWear of ResOutside_diameter; index as i"
            value="{{ resCasingWear }}"
            >{{ resCasingWear | number }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label for="cwf{{ item.nroItem }}1"
          >Wall Thickness [in]</label
        >
      </div>
      <div class="container-select">
        <ng-select
          [(ngModel)]="selectedWall_thickness"
          id="cwf{{ item.nroItem }}1"
          class="cw-ng-select"
          (ngModelChange)="onChangedDropDown($event, 'od', item.nroItem)"
          placeholder="Select"
          clearAllText="Clear"
        >
          <ng-option
            *ngFor="let resCasingWear of ResWall_thickness; index as i"
            value="{{ resCasingWear }}"
            >{{ resCasingWear | number }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label for="cwf{{ item.nroItem }}2"
          >Tool Joint Outside Diameter [in]</label
        >
      </div>
      <div class="container-select">
        <ng-select
          [(ngModel)]="selectedTool_join_odr"
          id="cwf{{ item.nroItem }}2"
          class="cw-ng-select"
          (ngModelChange)="onChangedDropDown($event, 'od', item.nroItem)"
          placeholder="Select"
          clearAllText="Clear"
        >
          <ng-option
            *ngFor="let casingWear of ResTool_join_od; index as i"
            value="{{ casingWear }}"
            >{{ casingWear | number }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label for="cwf{{ item.nroItem }}3">Grade [ksi]</label>
      </div>
      <div class="container-select">
        <ng-select
          [(ngModel)]="selectedGrade"
          id="cwf{{ item.nroItem }}3"
          class="cw-ng-select"
          (ngModelChange)="onChangedDropDown($event, 'od', item.nroItem)"
          placeholder="Select"
          clearAllText="Clear"
        >
          <ng-option
            *ngFor="let casingWear of ResGrade; index as i"
            value="{{ casingWear }}"
            >{{ casingWear | number }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label for="cwf{{ item.nroItem }}4">Load Mode</label>
      </div>
      <div class="container-select">
        <ng-select
          [(ngModel)]="selectedLoadMode"
          id="cwf{{ item.nroItem }}4"
          class="cw-ng-select"
          (ngModelChange)="onChangedDropDown($event, 'od', item.nroItem)"
          placeholder="Select"
          clearAllText="Clear"
        >
          <ng-option
            *ngFor="let casingWear of ResLoadMode; index as i"
            value="{{ casingWear }}"
            >{{ casingWear }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-2 col-sm-2">
    <div class="form-group filter">
      <div *ngIf="item.titles" class="container-title">
        <label>Actions</label>
      </div>
      <div class="container-icons">
        <div
          *ngIf="item.nroItem < 3"
          class="icon"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Add filter row"
          [ngClass]="{'disabled': !fullAllDropDown()}"
          (click)="addNewItem()"
        >
          <span class="s7-plus"></span>
        </div>
        <div
          *ngIf="item.nroItem > 0"
          class="icon"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Delete filter row"
          (click)="deleteItems(item.nroItem)"
        >
          <span class="s7-less"></span>
        </div>
        <div
          class="icon"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Delete filter values"
          (click)="cleanItems(item.nroItem)"
        >
          <span class="s7-refresh"></span>
        </div>
        <div
          *ngIf="item.nroItem > 0"
          class="icon"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Copy filter in new row"
          (click)="copyItems(item.nroItem)"
        >
          <span class="s7-copy-file"></span>
        </div>
      </div>
    </div>
  </div>
</div>
