import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { merge, Subscription } from "rxjs";
import { distinctUntilChanged, take } from "rxjs/operators";
import { CorrosionClasInput } from "src/app/models/corrosionclas/CorrosionClasInput";
import { ccRangeValidation } from 'src/app/models/corrosionclas/corrosionClasRangeValidator';
import { CorrosionClas, CorrosionClasPdf } from "src/app/models/corrosionclas/CorrosionClass";
import { CorrosionClasOutput } from "src/app/models/corrosionclas/Index";
import { HeaderState } from "src/app/models/header/HeaderState";
import { TreeAction } from "src/app/models/tree/TreeAction";
import { PrintedModalData } from "src/app/modules/CasingWear/models/printed";
import { RoundDecimalsPipe } from "src/app/pipes/round-decimals/round-decimals.pipe";
import { ActionService } from "src/app/services/Action.service";
import { CorrosionclasService } from "src/app/services/Corrosionclas.service";
import { DataService } from "src/app/services/Data.service";
import { PdfService } from "src/app/services/pdf.service";
import { TreeService } from "src/app/services/Tree.service";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";
import { API_GRAVITY_UNITS_ARRAY } from "src/app/shared/consts/api-gravity-units";
import { CONCENTRATION_UNITS_ARRAY, CONCENTRATION_UNITS_CL_ARRAY } from "src/app/shared/consts/concentration-units";
import { ERROR_MESSAGES } from "src/app/shared/consts/error-messages";
import { FRACTION_DIGITS } from "src/app/shared/consts/fraction-digits";
import { LENGTH_UNITS_ARRAY } from "src/app/shared/consts/length-units";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { PERCENTAGE_UNITS_ARRAY } from "src/app/shared/consts/percentage-units";
import { PRESSURE_UNITS_ARRAY } from "src/app/shared/consts/pressure-units";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { TEMPERATURE_UNITS_ARRAY } from "src/app/shared/consts/temperature-units";
import { VOLUME_UNITS_ARRAY } from "src/app/shared/consts/volume-units";
import { APIGravityUnits } from "src/app/shared/enums/api-gravity-units.enum";
import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";
import { LengthUnits } from "src/app/shared/enums/length-units.enum";
import { PercentageUnits } from "src/app/shared/enums/percentage-units.enum";
import { PressureUnits } from "src/app/shared/enums/pressure-units.enum";
import { TemperatureUnits } from "src/app/shared/enums/temperature-units.enum";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { VolumeUnits } from "src/app/shared/enums/volume-units.enum";
import { WarningEnum } from "src/app/shared/enums/warnings.enum";
import { AbstractControlWarning, Warning } from "src/app/shared/helpers/AbstractControlWarning";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";
import { NumericInputValidator } from "src/app/shared/validators/numeric-input.validator";
import { WarningValidator } from "src/app/shared/validators/warning.validator";
import Swal from 'sweetalert2';


declare var jQuery: any;

@Component({
  selector: "app-corrosionclas",
  templateUrl: "corrosionclas.component.html"
})
export class CorrosionclasComponent implements OnInit, OnDestroy {

  subActionService: Subscription;
  subDataService: Subscription;

  selected: TreeAction = { id: 0, text: '', level: '', activate: false, action: '', path: '', parents: '', userId: 0, notes: '', visibility: '' };

  //CorrosionCLAS
  title = "Corrosion CLAS";
  isOutputVisible: boolean = false;
  isOutputVisibleToAdmin: boolean = false;
  isOutputVisibleToUser: boolean = true;
  formCorrosionClas: FormGroup;
  inputValues: CorrosionClasInput = null;
  outputValues: CorrosionClasOutput = null;
  simulation: CorrosionClas = null;
  simulationName: string = '';
  saveMsg: string = '';
  rolName: string;
  waterCutError: boolean = false;
  isSimulationSaved: boolean = false;
  customerId: number | null;
  countryId: number | null;
  showModalReport = false;
  validReportCC: boolean = false;
  rangeCC: boolean = false;

  simSelectedId: number;

  enabledButtonReportCC: boolean = true;
  department:string='';
  nameCurrentUser :string='';

  isPreSimulationFormValid: boolean = false;

  iTemperatureUnit: FormControl = new FormControl(TemperatureUnits.Celsius);
  iTotalPressureUnit: FormControl = new FormControl(PressureUnits.Bar);
  iCO2percUnit: FormControl = new FormControl(PercentageUnits.Percent);
  iH2SpercUnit: FormControl = new FormControl(PercentageUnits.Percent);
  iPipeODUnit: FormControl = new FormControl(LengthUnits.Mm);
  iPipeWTUnit: FormControl = new FormControl(LengthUnits.Mm);
  iOilProductionUnit: FormControl = new FormControl(VolumeUnits.CubicMetersDay);
  iWaterProductionUnit: FormControl = new FormControl(VolumeUnits.CubicMetersDay);
  iAPIoilGravityUnit: FormControl = new FormControl(APIGravityUnits.Grades);
  iWaterDensityUnit: FormControl = new FormControl(ConcentrationUnits.KilogramsCubicDecimeter);
  iClUnit: FormControl = new FormControl(ConcentrationUnits.MilligramsLiter);

  roundDecimalsPipe: RoundDecimalsPipe;

  readonly NAVIGATIONS = NAVIGATIONS;
  readonly ERROR_MESSAGES = ERROR_MESSAGES;

  readonly TEMPERATURE_UNITS_ARRAY = TEMPERATURE_UNITS_ARRAY;
  readonly PRESSURE_UNITS_ARRAY = PRESSURE_UNITS_ARRAY;
  readonly PERCENTAGE_UNITS_ARRAY = PERCENTAGE_UNITS_ARRAY;
  readonly LENGTH_UNITS_ARRAY = LENGTH_UNITS_ARRAY;
  readonly VOLUME_UNITS_ARRAY = VOLUME_UNITS_ARRAY;
  readonly API_GRAVITY_UNITS_ARRAY = API_GRAVITY_UNITS_ARRAY;
  readonly CONCENTRATION_UNITS_ARRAY = CONCENTRATION_UNITS_ARRAY;
  readonly CONCENTRATION_UNITS_CL_ARRAY = CONCENTRATION_UNITS_CL_ARRAY;
  readonly FRACTION_DIGITS = FRACTION_DIGITS;


  readonly TEMPERATURE_LIMIT: number = 120;
  readonly DISSOLVE_SOLIDS_LIMIT: number = 50000;
  readonly TEMPERATURE_LIMIT_WARNING: any = {
    origin: "TEMPERATURE",
    message: "The value exceeds the validation limit of the model",
  };
  readonly DISSOLVE_SOLIDS_LIMIT_WARNING: any = {
    origin: "DISSOLVESOLIDS",
    message: "The amout of dissolved solids is unusually high. Please check data",
  };

  constructor(private fb: FormBuilder,
    public service: CorrosionclasService,
    public actionService: ActionService,
    public dataService: DataService,
    private route: ActivatedRoute,
    private pdfSvc: PdfService,
    public treeService: TreeService,
    public unitMeasureService: UnitMeasureService,
    private preSimulationService: PreSimulationService) { }

  ngOnInit() {
    this.roundDecimalsPipe = new RoundDecimalsPipe();
    this.simulation = new CorrosionClas();
    this.dataService.changeModulo('corrosionclas');
    this.createForm();
    this.getAction();
    this.getDataService();
    this.dataService.currentRolSource.pipe(take(1)).subscribe(rol => (this.rolName= rol));
    this.dataService.currentDepartmentSource.subscribe(dpto => (this.department= dpto));
    this.dataService.currentNameSurnameSource.subscribe(name => (this.nameCurrentUser= name));
    this.dataService.currentValidateReportCCSource.subscribe(validReport => (this.validReportCC = validReport));
    this.preSimulationService.formValidity$.subscribe(isValid => {
      this.isPreSimulationFormValid = isValid;
    });
    if (this.rolName == "ADMIN" || this.rolName == "SUPER_USER") {
      this.isOutputVisibleToAdmin = true;
      this.isOutputVisibleToUser = false;
    } else {
      this.isOutputVisibleToAdmin = false;
      this.isOutputVisibleToUser = true;
    }
  }

  getDataService() {
    this.subDataService = this.dataService.currentData.subscribe(selected => {
      if (selected.action != '') {
        this.selected = selected;
        switch (selected.action) {
          case HeaderState.load:
            this.loadSimulation();
            break;
          case HeaderState.save:
            this.confirmSave();
            break;
          case HeaderState.delete:
            this.confirmDelete();
            break;
          default:
            break;
        }
      }
    });
  }

  rangePopUp(){
     this.rangeCC = true;
  }

  closeModal(){
    this.rangeCC= false;
  }

  getAction() {
    this.subActionService = this.actionService.currentMessage.subscribe(value => {
      this.selected.action = value;
      switch (this.selected.action) {
        case HeaderState.new:
          this.confirmNew();
          break;
        case HeaderState.validate:
          this.confirmReport();
          break;
        case HeaderState.report:
          this.printReport();
          break;
        default:
          break;
      };
    });
  }

  confirmNew() {
    if (this.isOutputVisible == true) {
      Swal.fire({
        title: 'Are you sure ?',
        text: 'The values on screen will be deleted',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#009',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Proceed',
      }).then((result) => {
        if (result.isConfirmed) {
          this.newSimulation();
          this.preSimulationService.resetValues();
        }
      });
    } else {
      this.newSimulation();
      this.preSimulationService.resetValues();
      Swal.fire({
        title: 'New simulation created',
        icon: 'info',
        confirmButtonColor: '#009',
        cancelButtonColor: '#aaa',
      });
    }
  }

  newSimulation() {
    this.fillWaterInputs();
    this.simulation = new CorrosionClas();
    this.simulation.tree_id = this.selected.id;
    this.simulationName = '';
    this.simSelectedId = null;
    this.selected.action = HeaderState.default;
    this.waterCutError = false;
    this.isSimulationSaved = false
    this.dataService.changeValidateReportCC(false);
  }

  loadSimulation() {
    if (this.selected.id > 0 && this.selected.action == HeaderState.load) {
      this.service.getSimulationByTreeId(this.selected.id).subscribe(sim => {
        if (sim.status.success) {
          this.resetForm();
          this.simulation = sim.data;
          
          this.customerId = this.simulation.customerId;
          this.countryId = this.simulation.countryId;
          this.preSimulationService.setLoadedCustomerId(this.customerId);
          this.preSimulationService.setLoadedCountryId(this.countryId);
          this.preSimulationService.setSavedCustomerId(this.customerId);
          this.preSimulationService.setSavedCountryId(this.countryId);

          this.joinInfo();
          this.simulationName = this.selected.path;
          this.dataService.setSimulationIdName(this.selected.path);

          if(!this.customerId || !this.countryId)
            Swal.fire({
              title: "Action required",
              text: "Please select customer and country to view the simulation",
              icon: "warning",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          else
            Swal.fire({
              title: "Loaded!",
              text: "The simulation has been loaded.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });

          this.simSelectedId = this.selected.id;
        }
        if(this.formCorrosionClas.valid && this.simulation.ph_pure_H2O_CO2 > 0) {
          this.isSimulationSaved = true;
          this.dataService.changeValidateReportCC(true);
        }
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
    }
  }

  confirmSave() {

    if (this.selected.visibility == 'Select'){ 
      Swal.fire({
        title: 'Action required',
        text: 'Please select Visibility',
        icon: 'warning',
        confirmButtonColor: '#009',
        cancelButtonColor: '#aaa',
      });
    }else{
    this.service.getSimulationByTreeId(this.selected.id).subscribe(sim => {
      if (sim.status.success) {
        Swal.fire({
          title: 'Do you want to overwrite the simulation',
          text: this.selected.path + ' ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
          confirmButtonText: 'Proceed'          
        }).then((result) => {
          if (result.isConfirmed) {
            let newData = this.DataToSave(sim.data);
            this.service.putSimulation(sim.data.id, newData).subscribe(resp => {
              if (resp.status.success == true) {
                this.simulation.rev = resp.data.simulationId;
                Swal.fire({
                  title: 'Saved!',
                  text: 'The simulation has been saved.',
                  icon: 'success',
                  confirmButtonColor: '#009',
                  timer: 3000,
                  timerProgressBar: true                  
                })
                jQuery('#sidebar').removeClass('active');
                jQuery('.overlay').removeClass('active');
                this.simulationName = this.selected.path;
                this.simSelectedId = this.selected.id;
                this.dataService.setSimulationIdName(this.selected.path);
                this.customerId = newData.customerId;
                this.countryId = newData.countryId;
                this.preSimulationService.setSavedCustomerId(this.customerId);
                this.preSimulationService.setSavedCountryId(this.countryId);   
                if(this.formCorrosionClas.valid && newData.ph_pure_H2O_CO2 > 0) {
                  this.isSimulationSaved = true;
                  this.dataService.changeValidateReportCC(true);
                }
              } else {
                Swal.fire({
                  title: 'An error occurred',
                  icon: 'error',
                  confirmButtonColor: '#009',
                  cancelButtonColor: '#aaa',
                });
              }
            },
              err => {
                Swal.fire({
                  title: 'An error occurred',
                  icon: 'error',
                  confirmButtonColor: '#009',
                  cancelButtonColor: '#aaa',
                });
              });
          }
        });
      } else {
        Swal.fire({
          title: 'Do you want to save the simulation',
          text: this.selected.path + ' ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
          confirmButtonText: 'Proceed'          
        }).then((result) => {
          if (result.isConfirmed) {
            let newData = this.DataToSave();
            this.service.postSimulation(newData).subscribe(resp => {
              if (resp.status.success == true) {
                Swal.fire({
                  title: 'Saved!',
                  text: 'The simulation has been saved.',
                  icon: 'success',
                  confirmButtonColor: '#009',
                  timer: 3000,
                  timerProgressBar: true                  
                })
                this.simulationName = this.selected.path;  
                this.simulation.rev = resp.data.simulationId;
                jQuery('#sidebar').removeClass('active');
                jQuery('.overlay').removeClass('active'); 
                this.dataService.setSimulationIdName(this.selected.path);    
                this.customerId = newData.customerId;
                this.countryId = newData.countryId;
                this.preSimulationService.setSavedCustomerId(this.customerId);
                this.preSimulationService.setSavedCountryId(this.countryId);  
                if(this.formCorrosionClas.valid && newData.ph_pure_H2O_CO2 > 0) {
                  this.isSimulationSaved = true;
                  this.dataService.changeValidateReportCC(true);
                }    
              } else {
                Swal.fire({
                  title: 'An error occurred',
                  icon: 'error',
                  confirmButtonColor: '#009',
                  cancelButtonColor: '#aaa',
                });
              }
            },
              err => {
                Swal.fire({
                  title: 'An error occurred',
                  icon: 'error',
                  confirmButtonColor: '#009',
                  cancelButtonColor: '#aaa',
                });
              });
          }
          this.simulationName = '';
          this.simSelectedId = null; 
        });
      }
    });
  }
  }

  confirmDelete() {
    Swal.fire({
      title: "Do you want to delete the item",
      text: this.selected.path + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.treeService.deleteTree(this.dataService.extraData, this.selected.userId, this.selected);
        this.service.deleteSimulationByTree(this.selected.id).subscribe(sim => {
          this.actionService.changeAction(HeaderState.cancel);
          jQuery('#sidebar').removeClass('active');
          jQuery('.overlay').removeClass('active');
          Swal.fire({
            title: "Deleted!",
            text: "The item has been deleted.",
            icon: "success",
            confirmButtonColor: "#009",
            timer: 3000,
            timerProgressBar: true,
          })
          if(this.simSelectedId == this.selected.id)
            this.newSimulation();
        });
      }
    });
  }

  confirmReport() {    
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

      this.isSimulationSaved = false;
      
      return;
    }

    if(this.validReportCC == false) {
      if (!this.formCorrosionClas.valid) {
        Swal.fire({
          title: 'Action required',
          text: 'Please load a simulation or enter values for all fields',
          icon: 'warning',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
        return;
      }
      if (this.isOutputVisible == false) {
        Swal.fire({
          title: 'Action required',
          text: 'Please press submit and save the simulation to print report',
          icon: 'warning',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
        return;
      }
      if(this.isSimulationSaved == false || !this.validateIfPreSimulationSaved(this.customerId, this.countryId)){
        Swal.fire({
          title: 'Action required',
          text: 'Please save the simulation to print report',
          icon: 'warning',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
        return;
      }
    }

    if (!this.validateIfPreSimulationSaved(this.customerId, this.countryId)) {
      Swal.fire({
        title: "Action required",
        text: "Please save the simulation to print report",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
      return;
    }
  }

  validateIfPreSimulationSaved(customerId: number | null, countryId: number | null): boolean {
    let isSaved = true;
    if(this.preSimulationService.getCurrentCountryId() == null || this.preSimulationService.getCurrentCustomerId() == null || 
      this.preSimulationService.getCurrentCountryId() != countryId || this.preSimulationService.getCurrentCustomerId() != customerId)
      isSaved = false;

      return isSaved;
  }

  private printReport() {
    let newData = this.DataToPdf(this.actionService.data);
    this.pdfSvc.getPdfCc(newData);
    this.actionService.changeAction(HeaderState.default);
  }

  //Create Form
  get CCformControl() {
    return this.formCorrosionClas.controls;
  }

  get pressureUnitMessure() {
    return UnitMeasures.Pressure;
  }


  createForm() {
    this.formCorrosionClas = this.fb.group({
      "iTemperature": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 160, this.iTemperatureUnit, UnitMeasures.Temperature ,this.unitMeasureService),
        WarningValidator.maxValueLimitedByValueWarning(
            this.TEMPERATURE_LIMIT,
            null,
            this.TEMPERATURE_LIMIT_WARNING.origin,
            `${this.TEMPERATURE_LIMIT_WARNING.message}`,
          )]),
      "iTemperatureDefault": new FormControl(null),
      "iTotalPressure": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 1000, this.iTotalPressureUnit, UnitMeasures.Pressure ,this.unitMeasureService)]),
      "iTotalPressureDefault": new FormControl(null),
      "iCO2perc": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 100, this.iCO2percUnit, UnitMeasures.Percentage ,this.unitMeasureService)]),
      "iCO2percDefault": new FormControl(null),
      "iH2Sperc": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 100, this.iH2SpercUnit, UnitMeasures.Percentage ,this.unitMeasureService)]),
      "iH2SpercDefault": new FormControl(null),
      "radioOption": new FormControl(radioOptionValues.Quenched),
      "iPipeOD": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(25, 800, this.iPipeODUnit, UnitMeasures.Length ,this.unitMeasureService)]),
      "iPipeODDefault": new FormControl(null),
      "iPipeWT": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(3, 50, this.iPipeWTUnit, UnitMeasures.Length ,this.unitMeasureService)]),
      "iPipeWTDefault": new FormControl(null),
      "iPipeID": new FormControl({ value: null, disabled: true }),
      "iPipeIDDefault": new FormControl(null),
      "iCperc": new FormControl({value:null, disabled: true} ,[Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS) ,ccRangeValidation.limitCarbonRange]),
      "iCrperc": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), ccRangeValidation.limitChromiumRange]),
      "iOilProduction": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 25000, this.iOilProductionUnit, UnitMeasures.Volume ,this.unitMeasureService)]),
      "iOilProductionDefault": new FormControl(null),
      "iWaterProduction": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(1, 25000, this.iWaterProductionUnit, UnitMeasures.Volume ,this.unitMeasureService)]),
      "iWaterProductionDefault": new FormControl(null),
      "iWatercut": new FormControl({ value: null, disabled: true }),
      "iWatercutDefault": new FormControl(null),
      "iDesviacion": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), ccRangeValidation.limitDesviationRange]),
      "iAPIoilGravity": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(20, 50, this.iAPIoilGravityUnit, UnitMeasures.APIGravity ,this.unitMeasureService)]),
      "iAPIoilGravityDefault": new FormControl(null),
      "iLiquidVelocity": new FormControl(null, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), ccRangeValidation.limitVelocityURange]),
      "iNa": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iK": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iCa": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iMg": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iBa": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iSr": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iCl": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.minWithoutConvert(0, this.iClUnit,UnitMeasures.Concentration, this.unitMeasureService)]),
      "iClDefault": new FormControl(0),
      "iWaterDensity": new FormControl(1, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0.9, 1.1, this.iWaterDensityUnit, UnitMeasures.Concentration ,this.unitMeasureService)]),
      "iWaterDensityDefault": new FormControl(1),
      "iHCO3": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iSO42": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iFe": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "iAcetato": new FormControl(0, [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), NumericInputValidator.min(0)]),
      "oDissolvedSolids": new FormControl(0,[ WarningValidator.maxValueLimitedByValueWarning(this.DISSOLVE_SOLIDS_LIMIT, null, this.DISSOLVE_SOLIDS_LIMIT_WARNING.origin, `${this.DISSOLVE_SOLIDS_LIMIT_WARNING.message}`)]),
      "oAbsoluteTemp": new FormControl({ value: null, disabled: true }),
      "oPartialCO2pressure": new FormControl({ value: null, disabled: true }),
      "oPartialH2Spressure": new FormControl({ value: null, disabled: true }),
      "oWbreak": new FormControl({ value: null, disabled: true }),
    });

    // Controla cambios en los campos
    this.formCorrosionClas.get("iTemperatureDefault").valueChanges
      .subscribe(defaultValue => {
        if (defaultValue == null || "e+-".indexOf(defaultValue) > -1) {
          this.formCorrosionClas.get("oAbsoluteTemp").setValue(null);
          return;
        }

        this.formCorrosionClas.get("oAbsoluteTemp").setValue(this.unitMeasureService.convertUnit(
          defaultValue,
          UnitMeasures.Temperature,
          TemperatureUnits.Kelvin,
          false,
          this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Temperature, TemperatureUnits.Kelvin),
        ));

        this.formCorrosionClas.get("iTemperature").setValidators( [Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS), InterchaneableUnitValidator.delimited(0, 160, this.iTemperatureUnit, UnitMeasures.Temperature ,this.unitMeasureService),
          WarningValidator.maxValueLimitedByValueWarning(
              this.TEMPERATURE_LIMIT,
              defaultValue,
              this.TEMPERATURE_LIMIT_WARNING.origin,
              `${this.TEMPERATURE_LIMIT_WARNING.message}`,
            )])
          this.formCorrosionClas.get("iTemperature").updateValueAndValidity({emitEvent: false});
      });

    this.iTotalPressureUnit.valueChanges.subscribe((value) => {
      const unitSymbol = this.unitMeasureService.getUnitSymbol(UnitMeasures.Pressure, value)
      document.getElementById("oPartialCO2pressureUnit").innerHTML = unitSymbol;
      document.getElementById("oPartialH2SpressureUnit").innerHTML = unitSymbol;
    })

    this.formCorrosionClas.get("iWaterDensityDefault").valueChanges.subscribe((defaultValue) => {
      if(this.formCorrosionClas.get("iWaterDensityDefault").valid && this.formCorrosionClas.get("iWaterDensityDefault").value && (this.iClUnit.value == ConcentrationUnits.Ppm || this.iClUnit.value == ConcentrationUnits.NaClPercent)) {
        let clControl = this.formCorrosionClas.get('iClDefault');
        let object = {waterDensity: defaultValue, defaultUnit: this.unitMeasureService.concentrationUnits.MilligramsLiter};

        this.formCorrosionClas.get('iCl').setValue(this.unitMeasureService.convertUnit(clControl.value, UnitMeasures.Concentration, this.iClUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Concentration, this.iClUnit.value), object), {emitEvent: false});

        this.formCorrosionClas.get('iCl').updateValueAndValidity({emitEvent: false});
      }
    }); 
    
    
    this.formCorrosionClas.get("iTotalPressureDefault").valueChanges
      .subscribe((pressure) => {

        if (pressure == null) {
          this.formCorrosionClas.get("oPartialCO2pressure").setValue(null);
          this.formCorrosionClas.get("oPartialH2Spressure").setValue(null);
          return;
        }

        //CO2 Control
        let CO2 = this.formCorrosionClas.get("iCO2percDefault").value || 0;
        const PCO2 = pressure * CO2 / 100;
        let resultPCO2 = this.unitMeasureService.convertUnit((PCO2), UnitMeasures.Pressure, this.iTotalPressureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Pressure, this.iTotalPressureUnit.value));
        
        this.warningPCO2(PCO2);

        if(PCO2 > 200)
          this.formCorrosionClas.get("oPartialCO2pressure").setErrors({ limit_pCo2: true}, {emitEvent: false});
        else 
          this.formCorrosionClas.get("oPartialCO2pressure").setErrors(null, {emitEvent: false});

        this.formCorrosionClas.get("oPartialCO2pressure").setValue(resultPCO2);

        //PH2 Control
        let H2S = this.formCorrosionClas.get("iH2SpercDefault").value || 0;
        let resultPH2 = this.unitMeasureService.convertUnit((pressure * H2S / 100), UnitMeasures.Pressure, this.iTotalPressureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Pressure, this.iTotalPressureUnit.value));
        this.formCorrosionClas.get("oPartialH2Spressure").setValue(resultPH2);

        //PH2S field validator
        let pCO2 = this.formCorrosionClas.get("oPartialCO2pressure").value;
        let pH2S = this.formCorrosionClas.get("oPartialH2Spressure").value;

        this.pH2sControlValidator(pCO2, pH2S);
      });

    // Total Presure * CO2 in gas / 100 -> pCO2
    this.formCorrosionClas.get("iCO2percDefault").valueChanges
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("oPartialCO2pressure").setValue(null);
          return;
        }
        let CO2 = this.formCorrosionClas.get("iCO2percDefault").value || 0;
        let pressure = this.formCorrosionClas.get("iTotalPressureDefault").value;

        const PCO2 = pressure * CO2 / 100;
        let result = this.unitMeasureService.convertUnit((PCO2), UnitMeasures.Pressure, this.iTotalPressureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Pressure, this.iTotalPressureUnit.value));

        this.warningPCO2(PCO2);
       
        if(PCO2 > 200)
          this.formCorrosionClas.get("oPartialCO2pressure").setErrors({ limit_pCo2: true}, {emitEvent: false});
        else 
          this.formCorrosionClas.get("oPartialCO2pressure").setErrors(null, {emitEvent: false});

        this.formCorrosionClas.get("oPartialCO2pressure").setValue(result);

        let pCO2 = this.formCorrosionClas.get("oPartialCO2pressure").value;
        let pH2S = this.formCorrosionClas.get("oPartialH2Spressure").value;

        this.pH2sControlValidator(pCO2, pH2S);
      });

    // H2S in gas * Total Pressure / 100 -> pH2S
    this.formCorrosionClas.get("iH2SpercDefault").valueChanges
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("oPartialH2Spressure").setValue(null);
          return;
        }
        let pressure = this.formCorrosionClas.get("iTotalPressureDefault").value || 0;
        let H2S = this.formCorrosionClas.get("iH2SpercDefault").value || 0;
        
        let result = this.unitMeasureService.convertUnit((pressure * H2S / 100), UnitMeasures.Pressure, this.iTotalPressureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Pressure, this.iTotalPressureUnit.value));
        
        this.formCorrosionClas.get("oPartialH2Spressure").setValue(result);

        let pCO2 = this.formCorrosionClas.get("oPartialCO2pressure").value;
        let pH2S = this.formCorrosionClas.get("oPartialH2Spressure").value;

        this.pH2sControlValidator(pCO2, pH2S);
      });

    this.iPipeODUnit.valueChanges.subscribe((value) => {
      const unitSymbol = this.unitMeasureService.getUnitSymbol(UnitMeasures.Length, value)
      document.getElementById("iPipeIDUnit").innerHTML = unitSymbol;
    })

    // Pipe Outside Diameter + Pipe Wall Thickness -> Pipe inner Diameter
    this.formCorrosionClas.get("iPipeODDefault").valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("iPipeID").setValue(null);
          this.formCorrosionClas.get("iPipeIDDefault").setValue(null);
          return;
        }

        let wall = this.formCorrosionClas.get("iPipeWTDefault").value || 0;
        let IDValue = value - 2 * wall;
        let tubeID = this.unitMeasureService.convertUnit(IDValue, UnitMeasures.Length, this.iPipeODUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeODUnit.value));
        let tubeIDDefault = this.unitMeasureService.convertUnit(IDValue, UnitMeasures.Length, LengthUnits.Mm, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeODUnit.value));
        
        this.formCorrosionClas.get("iPipeID").setValue(tubeID);
        this.formCorrosionClas.get("iPipeIDDefault").setValue(tubeIDDefault);
      });

    this.formCorrosionClas.get("iPipeWTDefault").valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("iPipeID").setValue(null);
          this.formCorrosionClas.get("iPipeIDDefault").setValue(null);
          return;
        }
        let outside = this.formCorrosionClas.get("iPipeODDefault").value || 0;
        let IDValue = outside - 2 * value;
        let tubeID = this.unitMeasureService.convertUnit(IDValue, UnitMeasures.Length, this.iPipeODUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeODUnit.value));
        let tubeIDDefault = this.unitMeasureService.convertUnit(IDValue, UnitMeasures.Length, LengthUnits.Mm, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeODUnit.value));

        this.formCorrosionClas.get("iPipeID").setValue(tubeID);
        this.formCorrosionClas.get("iPipeIDDefault").setValue(tubeIDDefault);
      });
    
      this.formCorrosionClas.get("iPipeIDDefault").valueChanges.subscribe((value) => {
        if (value <= 0) {
          this.iPipeODUnit.disable({emitEvent: false});
          this.iPipeWTUnit.disable({emitEvent: false});
          this.formCorrosionClas.get("iPipeOD").setErrors({ invalid: true, errorMessage: "Pipe OD less twice Pipe WT must be greater or equal than 0" }, {emitEvent: false});
          this.formCorrosionClas.get("iPipeWT").setErrors({ invalid: true, errorMessage: "Pipe OD less twice Pipe WT must be greater or equal than 0" }, {emitEvent: false});
          this.formCorrosionClas.get("iPipeID").setErrors({ invalid: true, errorMessage: "Pipe OD less twice Pipe WT must be greater or equal than 0" }, {emitEvent: false});
        }
        else {
          this.iPipeODUnit.enable({emitEvent: false});
          this.iPipeWTUnit.enable({emitEvent: false});
          this.formCorrosionClas.get("iPipeOD").setErrors(null, {emitEvent: false});
          this.formCorrosionClas.get("iPipeWT").setErrors(null, {emitEvent: false});
          this.formCorrosionClas.get("iPipeID").setErrors(null, {emitEvent: false});     
          this.formCorrosionClas.get("iPipeOD").updateValueAndValidity({emitEvent: false});
          this.formCorrosionClas.get("iPipeWT").updateValueAndValidity({emitEvent: false});
          this.formCorrosionClas.get("iPipeID").updateValueAndValidity({emitEvent: false});
        }
      })

      merge(
        this.formCorrosionClas.get('iWaterProduction').valueChanges,
        this.formCorrosionClas.get('iOilProduction').valueChanges
      )
      .subscribe(() => {
      if((!this.formCorrosionClas.get("iWaterProduction").valid && !this.formCorrosionClas.get("iWaterProduction").errors?.required) || (!this.formCorrosionClas.get("iOilProduction").valid && !this.formCorrosionClas.get("iOilProduction").errors?.required)) {
        this.iWaterProductionUnit.disable({emitEvent: false});
        this.iOilProductionUnit.disable({emitEvent: false});
      } else {
        this.iWaterProductionUnit.enable({emitEvent: false});
        this.iOilProductionUnit.enable({emitEvent: false});
      }
    })

    this.formCorrosionClas.get("iWaterProductionDefault").valueChanges
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("iWatercut").setValue(null);
          this.formCorrosionClas.get("iWatercutDefault").setValue(null);
          return;
        }

        let oilProduction = this.formCorrosionClas.get("iOilProductionDefault").value || 0;
        let waterProduction = this.formCorrosionClas.get("iWaterProductionDefault").value || 0;

        if (waterProduction != 0) {
          let watercut = waterProduction / (waterProduction + oilProduction);
          this.formCorrosionClas.get("iWatercut").setValue(Number((watercut * 100).toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
          this.formCorrosionClas.get("iWatercutDefault").setValue(Number(watercut.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
        }
      });

    this.formCorrosionClas.get("iOilProductionDefault").valueChanges
      .subscribe(value => {
        if (value == null) {
          this.formCorrosionClas.get("iWatercut").setValue(null);
          this.formCorrosionClas.get("iWatercutDefault").setValue(null);
          return;
        }

        let oilProduction = this.formCorrosionClas.get("iOilProductionDefault").value || 0;
        let waterProduction = this.formCorrosionClas.get("iWaterProductionDefault").value || 0; 

        if (waterProduction != 0) {
          let watercut = waterProduction / (waterProduction + oilProduction);
          this.formCorrosionClas.get("iWatercut").setValue(Number((watercut * 100).toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
          this.formCorrosionClas.get("iWatercutDefault").setValue(Number(watercut.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
        }
      });     

    this.formCorrosionClas.get("radioOption").valueChanges.subscribe((value) => {
      this.formCorrosionClas.get("iCperc").reset();
        if(value == radioOptionValues.Normalized) {
          this.formCorrosionClas.get("iCperc").enable();
          this.formCorrosionClas.get("iCperc").setValidators([Validators.required, Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS) ,ccRangeValidation.limitCarbonRange]);  
        }
          else if (value == radioOptionValues.Quenched) {
          this.formCorrosionClas.get("iCperc").disable();
          this.formCorrosionClas.get("iCperc").setValidators(null);  
        }

        this.formCorrosionClas.get("iCperc").updateValueAndValidity();
    });
      
    this.iOilProductionUnit.valueChanges.subscribe((unit) => {
      this.iWaterProductionUnit.setValue(unit, {emitEvent: false});
      let waterProd = this.formCorrosionClas.get("iWaterProduction");
      waterProd.setValue(waterProd.value ? this.unitMeasureService.convertUnit(this.formCorrosionClas.get("iWaterProductionDefault").value, UnitMeasures.Volume, unit, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Volume, unit)) : waterProd.value === 0 ? waterProd.value : null, {emitEvent: false});
    });

    this.iWaterProductionUnit.valueChanges.subscribe((unit) => {
      this.iOilProductionUnit.setValue(unit, {emitEvent: false});
      let oilProd = this.formCorrosionClas.get("iOilProduction");
      oilProd.setValue(oilProd.value ? this.unitMeasureService.convertUnit(this.formCorrosionClas.get("iOilProductionDefault").value, UnitMeasures.Volume, unit, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Volume, unit)) : oilProd.value === 0 ? oilProd.value : null, {emitEvent: false});
    });

    merge(
      this.formCorrosionClas.get("radioOption").valueChanges,
      this.formCorrosionClas.get("iTemperatureDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iTotalPressureDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iCO2percDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iH2SpercDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iPipeODDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iPipeWTDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iPipeIDDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iCperc").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iCrperc").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iWaterProductionDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iOilProductionDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iAPIoilGravityDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iDesviacion").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iLiquidVelocity").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iNa").valueChanges,
      this.formCorrosionClas.get("iK").valueChanges,
      this.formCorrosionClas.get("iCa").valueChanges,
      this.formCorrosionClas.get("iMg").valueChanges,
      this.formCorrosionClas.get("iBa").valueChanges,
      this.formCorrosionClas.get("iSr").valueChanges,
      this.formCorrosionClas.get("iClDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iWaterDensityDefault").valueChanges.pipe(distinctUntilChanged()),
      this.formCorrosionClas.get("iHCO3").valueChanges,
      this.formCorrosionClas.get("iSO42").valueChanges,
      this.formCorrosionClas.get("iFe").valueChanges,
      this.formCorrosionClas.get("iAcetato").valueChanges,
    ).subscribe(() => {
      this.dataService.changeValidateReportCC(false);
      this.isSimulationSaved = false;
      this.simulationName = '';
      this.simSelectedId = null;
      this.resetOutput();
      this.formCorrosionClas.get("oWbreak").setValue(null);
    })

    // Water Chemical Composition
    merge(
    this.formCorrosionClas.get("iNa").valueChanges,
    this.formCorrosionClas.get("iK").valueChanges,
    this.formCorrosionClas.get("iCa").valueChanges,
    this.formCorrosionClas.get("iMg").valueChanges,
    this.formCorrosionClas.get("iBa").valueChanges,
    this.formCorrosionClas.get("iSr").valueChanges,
    this.formCorrosionClas.get("iClDefault").valueChanges,
    this.formCorrosionClas.get("iHCO3").valueChanges,
    this.formCorrosionClas.get("iSO42").valueChanges,
    this.formCorrosionClas.get("iFe").valueChanges,
    this.formCorrosionClas.get("iAcetato").valueChanges,
    ).subscribe(()=> {
      this.sumWaterComposition();
    })
  }

  private warningPCO2(PCO2: number) {
    let partialCO2AbstractControl = this.formCorrosionClas.get("oPartialCO2pressure") as AbstractControlWarning;

    if (!partialCO2AbstractControl.warnings)
      partialCO2AbstractControl.warnings = [];

    const warning: Warning = {
      type: WarningEnum.MaxValueLimitedByValue,
      warningMessage: "The value exceed the validation limit of the model",
      origin: "PARTIALCO2PRESSURE",
    };

    const existWarning = partialCO2AbstractControl.warnings.some(
      (warningItem: Warning) => warningItem.type === WarningEnum.MaxValueLimitedByValue && warningItem.origin === warning.origin
    );

    if (PCO2 > 20 && PCO2 <= 200) {
      if (!existWarning)
      {
        partialCO2AbstractControl.warnings.push(warning);
      }
    }
    else {
      const warningIndex = partialCO2AbstractControl.warnings.findIndex(
        (warningItem: Warning) => warningItem.type === WarningEnum.MaxValueLimitedByValue &&
          warningItem.origin === warning.origin
      );

      if (warningIndex > -1)
        partialCO2AbstractControl.warnings.splice(warningIndex, 1);
    }
  }

  //Control Value
  pH2sControlValidator(pCO2: number, pH2S: number) {
    let result = pCO2 / pH2S;
    if (pH2S > 0 && result < 50) 
      this.formCorrosionClas.get("oPartialH2Spressure").setErrors({ invalid: true })
    else
      this.formCorrosionClas.get("oPartialH2Spressure").setErrors(null)
  }

  sumWaterComposition() {
    let waterChemicalComp: number = 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iNa").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iK").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iCa").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iMg").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iBa").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iSr").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iClDefault").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iHCO3").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iSO42").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iFe").value) || 0;
    waterChemicalComp += Number(this.formCorrosionClas.get("iAcetato").value) || 0;

    this.formCorrosionClas.get("oDissolvedSolids").setValue(this.roundDecimalsPipe.transform(waterChemicalComp, 2));
  }

  fillWaterInputs() {
    this.resetOutput();
    this.resetForm();
    this.formCorrosionClas.get("radioOption").setValue(radioOptionValues.Quenched);
    this.formCorrosionClas.get("iNa").setValue(0);
    this.formCorrosionClas.get("iK").setValue(0);
    this.formCorrosionClas.get("iCa").setValue(0);
    this.formCorrosionClas.get("iMg").setValue(0);
    this.formCorrosionClas.get("iBa").setValue(0);
    this.formCorrosionClas.get("iSr").setValue(0);
    this.formCorrosionClas.get("iCl").setValue(0);
    this.formCorrosionClas.get("iClDefault").setValue(0);
    this.formCorrosionClas.get("iHCO3").setValue(0);
    this.formCorrosionClas.get("iSO42").setValue(0);
    this.formCorrosionClas.get("iFe").setValue(0);
    this.formCorrosionClas.get("iAcetato").setValue(0);
    this.formCorrosionClas.get("oDissolvedSolids").setValue(0);
  }

  resetOutput() {
    this.isOutputVisible = false;
    this.outputValues = null;
  }

  submitData() {
    this.inputValues = this.getFormDefaultValues();
    this.inputValues.oWbreak = (this.formCorrosionClas.get("oWbreak").value == null) ? 0 : this.formCorrosionClas.get("oWbreak").value;
    this.inputValues.oDissolvedSolids = (this.formCorrosionClas.get("oDissolvedSolids").value == null) ? 0 : this.formCorrosionClas.get("oDissolvedSolids").value;
    this.inputValues.iPipeID = (this.formCorrosionClas.get("iPipeIDDefault").value == null) ? 0 : this.formCorrosionClas.get("iPipeIDDefault").value;
    this.inputValues.iWatercut = (this.formCorrosionClas.get("iWatercutDefault").value == null) ? 0 : this.formCorrosionClas.get("iWatercutDefault").value;
    this.service.postCalculation(this.inputValues).subscribe(calc => {
      if (calc.status.success == true) {
        this.resetOutput();
        this.outputValues = calc.data;
        this.isOutputVisible = true;
        this.showOutput();
        const isNormalized = this.inputValues.radioOption == radioOptionValues.Normalized;
        let logRequest = [{
          IsNormalized: isNormalized,
          Temperature: this.inputValues.iTemperature,
          TotalPressure: this.inputValues.iTotalPressure,
          Co2InGas: this.inputValues.iCO2perc,
          H2sInGas: this.inputValues.iH2Sperc,
          PCo2: this.inputValues.iCO2perc,
          PH2s: this.inputValues.iH2Sperc,
          PipeOuterDiameter: this.inputValues.iPipeOD,
          Chromium: this.inputValues.iCrperc,
          DeviationAtConsideredPoint: this.inputValues.iDesviacion,
          PipeWallThickness: this.inputValues.iPipeWT,
          OilProduction: this.inputValues.iOilProduction,
          ApiOilGravity: this.inputValues.iAPIoilGravity,
          PipeInnerDiameter: this.inputValues.iPipeID,
          WaterProduction: this.inputValues.iWaterProduction,
          LiquidVelocityU: this.inputValues.iLiquidVelocity,
          Carbon: isNormalized ? this.inputValues.iCperc : null,
          Watercut: this.inputValues.iWatercut,
          Wbreak: this.validateNumberToLogs(this.outputValues.wbreak),
          Na: this.inputValues.iNa,
          K: this.inputValues.iK,
          Ca: this.inputValues.iCa,
          Mg: this.inputValues.iMg,
          Ba: this.inputValues.iBa,
          Sr: this.inputValues.iSr,
          Hco3: this.inputValues.iHCO3,
          So4: this.inputValues.iSO42,
          Fe: this.inputValues.iFe,
          Acetate: this.inputValues.iAcetato,
          WaterDensity: this.formCorrosionClas.get("iWaterDensityDefault").value,
          Cl: this.inputValues.iCl,
          DissolvedSolids: this.validateNumberToLogs(this.outputValues.dissolvedSolids),
          PhPureH2oCo2: this.validateNumberToLogs(this.outputValues.pH_CO2),
          PhSolution: this.validateNumberToLogs(this.outputValues.pH_Solution),
          ReactionAtSurface: this.validateNumberToLogs(this.outputValues.surfaceReactionSpeed),
          Transport: this.validateNumberToLogs(this.outputValues.transport),
          Foil: this.validateNumberToLogs(this.outputValues.oilProtectionFactor),
          Fscale: this.validateNumberToLogs(this.outputValues.scaleProtectionFactor),
          Tsc: this.validateNumberToLogs(this.outputValues.temperatureScale),
          ChromiumFactor: this.validateNumberToLogs(this.outputValues.cr_Factor),
          CarbonFactor: this.validateNumberToLogs(this.outputValues.c_Factor),
          Fh2s: this.validateNumberToLogs(this.outputValues.correctionH2S_FH2S),
          H2sPpm: this.validateNumberToLogs(this.outputValues.correctionH2S_H2Sppm),
          HsPpm: this.validateNumberToLogs(this.outputValues.correctionH2S_HSppm),
          H2sMolPerLiter: this.validateNumberToLogs(this.outputValues.correctionH2S_H2Smoll),
          HsMolPerLiter: this.validateNumberToLogs(this.outputValues.correctionH2S_HSmoll),
          CorrosionClas: this.validateNumberToLogs(this.outputValues.corrosionRates_CorrectedByCO2andH2S),
          CustomerId: this.preSimulationService.getCurrentCustomerId(),
          CountryId: this.preSimulationService.getCurrentCountryId()
        }];

        this.service.registerLog(logRequest).subscribe();
      } else {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      }
    });
  }

  private validateNumberToLogs(value: number) {
    let returnValue: number = value;
    if (typeof value !== 'number' || isNaN(value) || !isFinite(value))
      returnValue = null;
    else if (Object.is(value, -0))
      returnValue = 0;
    return returnValue;
  }

  getFormDefaultValues() {
    let values = this.formCorrosionClas.value;
    values.iAPIoilGravity = values.iAPIoilGravityDefault;
    values.iWatercut = values.iWatercutDefault;
    values.iWaterProduction = values.iWaterProductionDefault;
    values.iOilProduction = values.iOilProductionDefault;
    values.iPipeID = values.iPipeIDDefault;
    values.iPipeWT= values.iPipeWTDefault;
    values.iPipeOD = values.iPipeODDefault;
    values.iH2Sperc = values.iH2SpercDefault;
    values.iCO2perc = values.iCO2percDefault;
    values.iTotalPressure = values.iTotalPressureDefault;
    values.iTemperature = values.iTemperatureDefault;
    return values;
  }

  showOutput() {
    this.formCorrosionClas.get("oDissolvedSolids").setValue(this.outputValues.dissolvedSolids);
    this.formCorrosionClas.get("oAbsoluteTemp").setValue(Number(this.outputValues.absoluteTemp.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));

    // BUG: It needs to be reviewed.
    this.formCorrosionClas.get("oPartialCO2pressure").setValue(Number(this.outputValues.partialCO2pressure.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
    this.formCorrosionClas.get("oPartialH2Spressure").setValue(Number(this.outputValues.partialH2Spressure.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
    
    this.formCorrosionClas.get("oWbreak").setValue(this.outputValues.wbreak || this.outputValues.wbreak === 0 ? Number(this.outputValues.wbreak?.toFixed(FRACTION_DIGITS.CORROSION_CLAS)) : this.outputValues.wbreak);
  }

  joinInfo() {
    if (this.simulation.heart_treatment == 0) {
      this.formCorrosionClas.get("radioOption").setValue(radioOptionValues.Normalized);
    } else {
      this.formCorrosionClas.get("radioOption").setValue(radioOptionValues.Quenched);
    }

    this.formCorrosionClas.get("iTemperature").setValue(this.unitMeasureService.convertUnit(this.simulation.temperature, UnitMeasures.Temperature, this.iTemperatureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Temperature, this.iTemperatureUnit.value)));
    this.formCorrosionClas.get("iTotalPressure").setValue(this.unitMeasureService.convertUnit(this.simulation.total_pressure, UnitMeasures.Pressure, this.iTotalPressureUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Pressure, this.iTotalPressureUnit.value)));
    this.formCorrosionClas.get("iCO2perc").setValue(this.unitMeasureService.convertUnit(this.simulation.cO2_per, UnitMeasures.Percentage, this.iCO2percUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Percentage, this.iCO2percUnit.value)));
    this.formCorrosionClas.get("iH2Sperc").setValue(this.unitMeasureService.convertUnit(this.simulation.h2S_per, UnitMeasures.Percentage, this.iH2SpercUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Percentage, this.iH2SpercUnit.value)));
    this.formCorrosionClas.get("iPipeOD").setValue(this.unitMeasureService.convertUnit(this.simulation.external_pipe_diam, UnitMeasures.Length, this.iPipeODUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeODUnit.value))); // If == 0 ''
    this.formCorrosionClas.get("iPipeWT").setValue(this.unitMeasureService.convertUnit(this.simulation.pipe_wall_thickness, UnitMeasures.Length, this.iPipeWTUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Length, this.iPipeWTUnit.value))); // If == 0 ''
    this.formCorrosionClas.get("iCperc").setValue(this.simulation.c_per == 0 ? 0 : this.simulation.c_per);
    this.formCorrosionClas.get("iCrperc").setValue(this.simulation.cr_per == 0 ? 0 : this.simulation.cr_per);
    this.formCorrosionClas.get("iOilProduction").setValue(this.unitMeasureService.convertUnit(this.simulation.oil_production, UnitMeasures.Volume, this.iOilProductionUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Volume, this.iOilProductionUnit.value)));
    this.formCorrosionClas.get("iWaterProduction").setValue(this.unitMeasureService.convertUnit(this.simulation.water_production, UnitMeasures.Volume, this.iWaterProductionUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Volume, this.iWaterProductionUnit.value)));
    this.formCorrosionClas.get("iDesviacion").setValue(this.simulation.deviation == 0 ? 0 : this.simulation.deviation);
    this.formCorrosionClas.get("iAPIoilGravity").setValue(this.unitMeasureService.convertUnit(this.simulation.api_oil_gravity, UnitMeasures.APIGravity, this.iAPIoilGravityUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.APIGravity, this.iAPIoilGravityUnit.value)));
    this.formCorrosionClas.get("iLiquidVelocity").setValue(this.simulation.liquid_velocity == 0 ? '' : this.simulation.liquid_velocity);
    this.formCorrosionClas.get("iWatercut").setValue(Number(this.simulation.watercut.toFixed(FRACTION_DIGITS.CORROSION_CLAS)) == 0 ? null : Number((this.simulation.watercut * 100).toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
    this.formCorrosionClas.get("iWatercutDefault").setValue(Number(this.simulation.watercut.toFixed(FRACTION_DIGITS.CORROSION_CLAS)) == 0 ? null : Number(this.simulation.watercut.toFixed(FRACTION_DIGITS.CORROSION_CLAS)));
    this.formCorrosionClas.get("iNa").setValue(this.simulation.na);
    this.formCorrosionClas.get("iK").setValue(this.simulation.k);
    this.formCorrosionClas.get("iCa").setValue(this.simulation.ca);
    this.formCorrosionClas.get("iMg").setValue(this.simulation.mg);
    this.formCorrosionClas.get("iBa").setValue(this.simulation.ba);
    this.formCorrosionClas.get("iSr").setValue(this.simulation.sr);
    this.formCorrosionClas.get("iCl").setValue(this.unitMeasureService.convertUnit(this.simulation.cl, UnitMeasures.Concentration, this.iClUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Concentration, this.iClUnit.value), {waterDensity: this.simulation.water_density, defaultUnit: this.unitMeasureService.concentrationUnits.MilligramsLiter}));
    this.formCorrosionClas.get("iWaterDensity").setValue(this.unitMeasureService.convertUnit(this.simulation.water_density, UnitMeasures.Concentration, this.iWaterDensityUnit.value, false, this.unitMeasureService.getUnitFractionDigits(UnitMeasures.Concentration, this.iWaterDensityUnit.value),{defaultUnit: this.unitMeasureService.concentrationUnits.KilogramsCubicDecimeter}));
    this.formCorrosionClas.get("iHCO3").setValue(this.simulation.hcO3);
    this.formCorrosionClas.get("iSO42").setValue(this.simulation.sO4);
    this.formCorrosionClas.get("iFe").setValue(this.simulation.fe);
    this.formCorrosionClas.get("iAcetato").setValue(this.simulation.acetate);
    this.formCorrosionClas.get("oWbreak").setValue(this.simulation.wbreak || this.simulation.wbreak === 0 ? Number(this.simulation.wbreak?.toFixed(FRACTION_DIGITS.CORROSION_CLAS)) : this.simulation.wbreak);
    if (this.simulation.dissolvedSolids > 0) {
      this.formCorrosionClas.get("oDissolvedSolids").setValue(this.simulation.dissolvedSolids);
    } else {
      this.sumWaterComposition();
    }

    if (this.formCorrosionClas.valid && this.simulation.ph_pure_H2O_CO2 > 0) { this.isOutputVisible = true; } else { this.isOutputVisible = false; }
    this.outputValues = new CorrosionClasOutput()
    this.outputValues.pH_CO2 = this.simulation.ph_pure_H2O_CO2;
    this.outputValues.pH_Solution = this.simulation.ph_solution;
    this.outputValues.surfaceReactionSpeed = this.simulation.reaction_at_surface;
    this.outputValues.transport = this.simulation.transport;
    this.outputValues.oilProtectionFactor = this.simulation.oil_protection_factor_foil;
    this.outputValues.oilProtectionFactorCalc = this.simulation.oil_protection_factor_foil_calc;
    this.outputValues.oilProtectionFactorRestrWC = this.simulation.oil_protection_factor_foil_restrwc;
    this.outputValues.scaleProtectionFactor = this.simulation.scale_protection_factor_fscale;
    this.outputValues.temperatureScale = this.simulation.scale_protection_factor_tsc;
    this.outputValues.cr_Factor = this.simulation.chromium_factor;
    this.outputValues.c_Factor = this.simulation.carbon_factor;
    this.outputValues.correctionH2S_H2Sppm = this.simulation.h2S_ppm;
    this.outputValues.correctionH2S_HSppm = this.simulation.hS_ppm;
    this.outputValues.correctionH2S_H2Smoll = this.simulation.h2S_mol_liter;
    this.outputValues.correctionH2S_HSmoll = this.simulation.hS_mol_liter;
    this.outputValues.correctionH2S_FH2S = this.simulation.fH2S;
    this.outputValues.corrosionRates_CorrectedByComposition = this.simulation.corrected_by_composition;
    this.outputValues.corrosionRates_CorrectedByOil = this.simulation.corrected_by_oil;
    this.outputValues.corrosionRates_CorrectedByCO2 = this.simulation.corrected_by_scale;
    this.outputValues.corrosionRates_CorrectedByCO2andH2S = this.simulation.corrected_by_scales_CO2_or_H2S;
    this.formCorrosionClas.markAllAsTouched();
  }

  DataToSave(corrosionclas?: CorrosionClas): CorrosionClas {
    let ret = new CorrosionClas();
    if (corrosionclas != undefined) {
      ret = corrosionclas;
    }

    ret.user_id = this.selected.userId;
    ret.tree_id = this.selected.id;
    ret.name = this.selected.text;
    ret.department = this.department;
    ret.heart_treatment = (this.formCorrosionClas.get("radioOption").value == radioOptionValues.Normalized) ? 0 : 1;
    ret.temperature = this.formCorrosionClas.get("iTemperatureDefault").value;
    ret.total_pressure = this.formCorrosionClas.get("iTotalPressureDefault").value;
    ret.cO2_per = this.formCorrosionClas.get("iCO2percDefault").value;
    ret.h2S_per = this.formCorrosionClas.get("iH2SpercDefault").value;
    ret.external_pipe_diam = this.formCorrosionClas.get("iPipeODDefault").value || 0;
    ret.pipe_wall_thickness = this.formCorrosionClas.get("iPipeWTDefault").value || 0;
    ret.c_per = this.formCorrosionClas.get("iCperc").value;
    ret.cr_per = this.formCorrosionClas.get("iCrperc").value;
    ret.oil_production = this.formCorrosionClas.get("iOilProductionDefault").value;
    ret.water_production = this.formCorrosionClas.get("iWaterProductionDefault").value;
    ret.deviation = this.formCorrosionClas.get("iDesviacion").value;
    ret.api_oil_gravity = this.formCorrosionClas.get("iAPIoilGravityDefault").value || 0;
    ret.liquid_velocity = this.formCorrosionClas.get("iLiquidVelocity").value || 0;
    ret.watercut = this.formCorrosionClas.get("iWatercutDefault").value || 0;
    ret.na = this.formCorrosionClas.get("iNa").value || 0;
    ret.k = this.formCorrosionClas.get("iK").value || 0;
    ret.ca = this.formCorrosionClas.get("iCa").value || 0;
    ret.mg = this.formCorrosionClas.get("iMg").value || 0;
    ret.ba = this.formCorrosionClas.get("iBa").value || 0;
    ret.sr = this.formCorrosionClas.get("iSr").value || 0;
    ret.cl = this.formCorrosionClas.get("iClDefault").value || 0;
    ret.water_density = this.formCorrosionClas.get("iWaterDensityDefault").value;
    ret.hcO3 = this.formCorrosionClas.get("iHCO3").value || 0;
    ret.sO4 = this.formCorrosionClas.get("iSO42").value || 0;
    ret.fe = this.formCorrosionClas.get("iFe").value || 0;
    ret.acetate = this.formCorrosionClas.get("iAcetato").value || 0;
    ret.wbreak = this.formCorrosionClas.get("oWbreak").value || 0;
    ret.dissolvedSolids = (this.formCorrosionClas.get("oDissolvedSolids").value == null) ? 0 : this.formCorrosionClas.get("oDissolvedSolids").value;
    ret.ph_pure_H2O_CO2 = this.outputValues?.pH_CO2 || 0;
    ret.ph_solution = this.outputValues?.pH_Solution || 0;
    ret.reaction_at_surface = this.outputValues?.surfaceReactionSpeed || 0;
    ret.transport = this.outputValues?.transport || 0;
    ret.oil_protection_factor_foil = this.outputValues?.oilProtectionFactor || 0;
    ret.oil_protection_factor_foil_calc = this.outputValues?.oilProtectionFactorCalc || 0;
    ret.oil_protection_factor_foil_restrwc = this.outputValues?.oilProtectionFactorRestrWC || 0;
    ret.scale_protection_factor_fscale = this.outputValues?.scaleProtectionFactor || 0;
    ret.scale_protection_factor_tsc = this.outputValues?.temperatureScale || 0;
    ret.chromium_factor = this.outputValues?.cr_Factor || 0;
    ret.carbon_factor = this.outputValues?.c_Factor || 0;
    ret.h2S_ppm = this.outputValues?.correctionH2S_H2Sppm || 0;
    ret.hS_ppm = this.outputValues?.correctionH2S_HSppm || 0;
    ret.h2S_mol_liter = this.outputValues?.correctionH2S_H2Smoll || 0;
    ret.hS_mol_liter = this.outputValues?.correctionH2S_HSmoll || 0;
    ret.fH2S = this.outputValues?.correctionH2S_FH2S || 0;
    ret.corrected_by_composition = this.outputValues?.corrosionRates_CorrectedByComposition || 0;
    ret.corrected_by_oil = this.outputValues?.corrosionRates_CorrectedByOil || 0;
    ret.corrected_by_scale = this.outputValues?.corrosionRates_CorrectedByCO2 || 0;
    ret.corrected_by_scales_CO2_or_H2S = this.outputValues?.corrosionRates_CorrectedByCO2andH2S || 0;
    ret.notes = this.selected.notes;
    ret.visibility = this.selected.visibility;

    
    ret.customerId = this.preSimulationService.getCurrentCustomerId();
    ret.countryId = this.preSimulationService.getCurrentCountryId();

    return ret;
  }

  DataToPdf(printedModalData: PrintedModalData): CorrosionClasPdf {
    let ret = new CorrosionClasPdf;
    ret.printOnlyFirstPage= printedModalData.printOnlyFirstPage;
    ret.requestedBy= printedModalData.requestedBy;
    ret.authorizedBy= printedModalData.authorizedBy;
    ret.reviewedBy= printedModalData.reviewedBy;
    ret.SDCaseNumber= printedModalData.SDCaseNumber;
    ret.user_id = this.selected.userId;
    ret.tree_id = this.selected.id;
    ret.name = this.selected.text;
    ret.createdBy = this.nameCurrentUser
    ? this.nameCurrentUser
    : "-",
    ret.rev = this.simulation.rev;
    ret.department = this.department;
    ret.heart_treatment = (this.formCorrosionClas.get("radioOption").value == radioOptionValues.Normalized) ? 'Normalized or As Rolled' : 'Quenched + Tempered';
    ret.temperature = this.formCorrosionClas.get("iTemperature").value || 0;
    ret.total_pressure = this.formCorrosionClas.get("iTotalPressure").value || 0;
    ret.cO2_per = this.formCorrosionClas.get("iCO2perc").value || 0;
    ret.h2S_per = this.formCorrosionClas.get("iH2Sperc").value || 0;
    ret.external_pipe_diam = this.formCorrosionClas.get("iPipeOD").value || 0;
    ret.pipe_wall_thickness = this.formCorrosionClas.get("iPipeWT").value || 0;
    ret.c_per = this.formCorrosionClas.get("iCperc").value || 0;
    ret.cr_per = this.formCorrosionClas.get("iCrperc").value || 0;
    ret.oil_production = this.formCorrosionClas.get("iOilProduction").value || 0;
    ret.water_production = this.formCorrosionClas.get("iWaterProduction").value || 0;
    ret.deviation = this.formCorrosionClas.get("iDesviacion").value || 0;
    ret.api_oil_gravity = this.formCorrosionClas.get("iAPIoilGravity").value || 0;
    ret.liquid_velocity = this.formCorrosionClas.get("iLiquidVelocity").value || 0;
    ret.watercut = this.formCorrosionClas.get("iWatercut").value || 0;
    ret.na = this.formCorrosionClas.get("iNa").value || 0;
    ret.k = this.formCorrosionClas.get("iK").value || 0;
    ret.ca = this.formCorrosionClas.get("iCa").value || 0;
    ret.mg = this.formCorrosionClas.get("iMg").value || 0;
    ret.ba = this.formCorrosionClas.get("iBa").value || 0;
    ret.sr = this.formCorrosionClas.get("iSr").value || 0;
    ret.cl = this.formCorrosionClas.get("iCl").value || 0;
    ret.water_density = this.formCorrosionClas.get("iWaterDensity").value;
    ret.hcO3 = this.formCorrosionClas.get("iHCO3").value || 0;
    ret.sO4 = this.formCorrosionClas.get("iSO42").value || 0;
    ret.fe = this.formCorrosionClas.get("iFe").value || 0;
    ret.acetate = this.formCorrosionClas.get("iAcetato").value || 0;
    ret.wbreak = (this.formCorrosionClas.get("oWbreak").value == null) ? 0 : this.formCorrosionClas.get("oWbreak").value;
    ret.dissolvedSolids = (this.formCorrosionClas.get("oDissolvedSolids").value == null) ? 0 : this.formCorrosionClas.get("oDissolvedSolids").value;
    ret.ph_pure_H2O_CO2 = this.outputValues?.pH_CO2 || 0;
    ret.ph_solution = this.outputValues?.pH_Solution || 0;
    ret.reaction_at_surface = this.outputValues?.surfaceReactionSpeed || 0;
    ret.transport = this.outputValues?.transport || 0;
    ret.oil_protection_factor_foil = this.outputValues?.oilProtectionFactor || 0;
    ret.oil_protection_factor_foil_calc = this.outputValues?.oilProtectionFactorCalc || 0;
    ret.oil_protection_factor_foil_restrwc = this.outputValues?.oilProtectionFactorRestrWC || 0;
    ret.scale_protection_factor_fscale = this.outputValues?.scaleProtectionFactor || 0;
    ret.scale_protection_factor_tsc = this.outputValues?.temperatureScale || 0;
    ret.chromium_factor = this.outputValues?.cr_Factor || 0;
    ret.carbon_factor = this.outputValues?.c_Factor || 0;
    ret.h2S_ppm = this.outputValues?.correctionH2S_H2Sppm || 0;
    ret.hS_ppm = this.outputValues?.correctionH2S_HSppm || 0;
    ret.h2S_mol_liter = this.outputValues?.correctionH2S_H2Smoll || 0;
    ret.hS_mol_liter = this.outputValues?.correctionH2S_HSmoll || 0;
    ret.fH2S = this.outputValues?.correctionH2S_FH2S || 0;
    ret.corrected_by_composition = this.outputValues?.corrosionRates_CorrectedByComposition || 0;
    ret.corrected_by_oil = this.outputValues?.corrosionRates_CorrectedByOil || 0;
    ret.corrected_by_scale = this.outputValues?.corrosionRates_CorrectedByCO2 || 0;
    ret.corrected_by_scales_CO2_or_H2S = this.outputValues?.corrosionRates_CorrectedByCO2andH2S || 0;
    ret.notes = this.selected.notes;
    ret.visibility = this.selected.visibility;
    ret.pipeInnerDiameter = this.formCorrosionClas.get("iPipeID").value || 0;
    ret.maximumWaterInOilWbreak = this.formCorrosionClas.get("oWbreak").value || 0;
    ret.temperatureUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Temperature, this.iTemperatureUnit.value);
    ret.totalPressureUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Pressure, this.iTotalPressureUnit.value);
    ret.CO2percUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Percentage, this.iCO2percUnit.value);
    ret.H2SpercUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Percentage, this.iH2SpercUnit.value);
    ret.pipeODUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Length, this.iPipeODUnit.value);
    ret.pipeIDUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Length, this.iPipeODUnit.value);
    ret.pipeWTUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Length, this.iPipeWTUnit.value);
    ret.oilProductionUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Volume, this.iOilProductionUnit.value);
    ret.waterProductionUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Volume, this.iWaterProductionUnit.value);
    ret.APIoilGravityUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.APIGravity, this.iAPIoilGravityUnit.value);
    ret.waterDensityUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Concentration, this.iWaterDensityUnit.value);
    ret.ClUnit = this.unitMeasureService.getUnitSymbol(UnitMeasures.Concentration, this.iClUnit.value);
    return ret;
  }

  hasFormError() {
    return this.formCorrosionClas.invalid || this.formCorrosionClas.get("oPartialCO2pressure").errors ||
    this.formCorrosionClas.get("oPartialH2Spressure").errors || this.formCorrosionClas.get("iPipeID").errors ||
    this.formCorrosionClas.get("iWatercut").errors;
  }

  resetForm() {
    this.formCorrosionClas.reset();
    this.formCorrosionClas.get('iWaterDensity').setValue(1);
    this.formCorrosionClas.get('iWaterDensityDefault').setValue(1);

    //remove warning
    let partialCO2AbstractControl = this.formCorrosionClas.get("oPartialCO2pressure") as AbstractControlWarning;
    partialCO2AbstractControl.warnings = [];
    this.formCorrosionClas.get("oPartialCO2pressure").updateValueAndValidity();

    //set to null here because when disabled the validator is not updated
    this.formCorrosionClas.get("oPartialCO2pressure").setErrors(null);
    this.formCorrosionClas.get("oPartialH2Spressure").setErrors(null);

    this.formCorrosionClas.get("iPipeOD").setErrors(null);
    this.formCorrosionClas.get("iPipeWT").setErrors(null);
    this.formCorrosionClas.get("iPipeID").setErrors(null);

    this.formCorrosionClas.get("iPipeOD").updateValueAndValidity({emitEvent: false});
    this.formCorrosionClas.get("iPipeWT").updateValueAndValidity({emitEvent: false});
    this.formCorrosionClas.get("iPipeID").updateValueAndValidity({emitEvent: false});

    this.enableUnitControls();
  }

  enableUnitControls() {
    this.iTemperatureUnit.enable();
    this.iTotalPressureUnit.enable();
    this.iCO2percUnit.enable();
    this.iH2SpercUnit.enable();
    this.iPipeODUnit.enable();
    this.iPipeWTUnit.enable();
    this.iOilProductionUnit.enable();
    this.iWaterProductionUnit.enable();
    this.iWaterDensityUnit.enable();
  }




  ngOnDestroy() {
    this.subActionService.unsubscribe();
    this.subDataService.unsubscribe();
    this.preSimulationService.resetValues();
  }
}

enum radioOptionValues {
  Normalized = "normalizado",
  Quenched = "quenched"
}
