import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TreeAction } from "src/app/models/tree/Index";
import { ContextMenuModel } from "../../../models/contextmenu/Context-menu-model";

@Component({
  selector: "app-context-menu",
  templateUrl: "./context-menu.component.html"
})
export class ContextMenuComponent {
  @Input()
  contextMenuItems: Array<ContextMenuModel>;

  @Output()
  onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();
  
  onContextMenuClick(event, data): any {
    this.onContextMenuItemClick.emit({ event, data });
  }
}
