import { PressureUnits } from "../enums/pressure-units.enum";

export const PRESSURE_UNITS = {
  BAR: {
    name: "Bar",
    symbol: "bar",
    enum: PressureUnits.Bar,
    fractionDigits: 4,
  },
  PSI: {
    name: "Pound per square inch",
    symbol: "psi",
    enum: PressureUnits.Psi,
    fractionDigits: 4,
  },
  ATM: {
    name: "Atmosphere",
    symbol: "atm",
    enum: PressureUnits.Atm,
    fractionDigits: 4,
  },
  KPA: {
    name: "Kilopascals",
    symbol: "kPa",
    enum: PressureUnits.Kpa,
    fractionDigits: 4,
  },
  KSI: {
    name: "Pounds per square inch",
    symbol: "ksi",
    enum: PressureUnits.Ksi,
    fractionDigits: 4,
  },
  MPA: {
    name: "Megapascals",
    symbol: "MPa",
    enum: PressureUnits.Mpa,
    fractionDigits: 4,
  },
};

export const PRESSURE_UNITS_ARRAY: PressureUnit[] = [
  PRESSURE_UNITS.BAR,
  PRESSURE_UNITS.PSI,
  PRESSURE_UNITS.ATM,
];

export const PRESSURE_UNITS_KPA_ARRAY: PressureUnit[] = [
  PRESSURE_UNITS.BAR,
  PRESSURE_UNITS.KPA,
  PRESSURE_UNITS.PSI,
];

export const PRESSURE_UNITS_KSI_ARRAY: PressureUnit[] = [
  PRESSURE_UNITS.KSI,
  PRESSURE_UNITS.MPA,
];

class PressureUnit {
  name: string;
  symbol: string;
  enum: PressureUnits;
  fractionDigits: number;
}
