<div
  id="dataCheckModal"
  tabindex="-1"
  role="dialog"
  class="modal fade modal-colored-header"
>
  <div class="modal-dialog modal-lg">
    <div style="background-color: rgb(0 153 0)">
      <div class="panel-heading headering">
        <h1>Data Check</h1>
        <button #closeBtn type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
    </div>

    <div class="panel-body" style="background-color: white; color: black">
      <div class="widget widget-fullwidth widget-small">
        <div
          id="table1_wrapper"
          class="dataTables_wrapper form-inline dt-bootstrap no-footer"
        >
          <div class="row am-datatable-body">
            <div class="col-sm-12">
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="row-border hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th class="title">Type</th>
                    <th class="title">Parent</th>
                    <th class="title">Description</th>
                    <th #options class="title"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dtData, let iData = index" class="datosGrilla">
                    <td>{{ item.parent }}</td>
                    <td [ngClass]="{'warning': item.type == 'WARNING', 'error': item.type == 'ERROR'}">{{ item.type }}</td>
                    <td>{{ item.description }}</td>
                    <td><ol *ngIf="item.options"><li class="option" *ngFor="let option of item.options, let iOption = index" [attr.ioption]="iOption" [attr.idata]="iData">{{ option.description }}</li></ol></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 m-t-20" *ngIf="isSubmitting">
              <div class="text-inverse">
                <button type="submit" class="btn btn-space btn-primary" (click)="submit()" [disabled]="isSubmitDisabled">
                  Submit
                </button>
                <button type="button" data-dismiss="modal" class="btn btn-space btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
