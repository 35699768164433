import { HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { merge, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { HeaderState } from "src/app/models/header/HeaderState";
import { EmptyDataTablePipe } from "src/app/pipes/empty-data-table/empty-data-table.pipe";
import { RoundDecimalsPipe } from "src/app/pipes/round-decimals/round-decimals.pipe";
import { TransformToPercentPipe } from "src/app/pipes/transform-to-percent/transform-to-percent.pipe";
import { ActionService } from "src/app/services/Action.service";
import { DataService } from "src/app/services/Data.service";
import { HttpService } from "src/app/services/http/http.service";
import { PdfService } from "src/app/services/pdf.service";
import { PerforatedPipeService } from "src/app/services/perforated-pipe/perforated-pipe.service";
import { TreeService } from "src/app/services/Tree.service";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { SimulationTypes } from "src/app/shared/enums/simulation-types";
import { UnitSystems } from "src/app/shared/enums/unit-systems";
import { NumericInputValidator } from "src/app/shared/validators/numeric-input.validator";
import { WarningValidator } from "src/app/shared/validators/warning.validator";
import Swal from "sweetalert2";
import { PrintedModalData } from "../../CasingWear/models/printed";
import { Groupings } from "../shared/enums/groupings.enum";
import { Patterns } from "../shared/enums/patterns.enum";
import { PipeResults } from "../shared/enums/slotted-results.enum";
import { SlottedPerforatedPipeImagesRequest } from "../shared/models/slotted-perforated-image";
import { Attachment } from "../slotted-pipe/models/slotted-pipe";
import {
  DCPerforatedBeforeRequest,
  LoadSlottedPerforatedAttachments,
  PerforatedPipeRequest,
  Results,
  SavePerforatedSimulationResponse,
  SaveSimulationPerforatedRequest,
} from "./models/perforated-pipe";
import { PrintPdfPRRequest } from "./models/treePR";
import { PerforatedService } from "./services/perforated.service";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";

@Component({
  selector: "app-perforated-pipe",
  templateUrl: "./perforated-pipe.component.html",
  styleUrls: ["./perforated-pipe.component.css"],
})
export class PerforatedPipeComponent implements OnInit {
  //Services
  subActionService: Subscription;
  subDataService: Subscription;
  //Validators
  validReportPR: boolean = false;
  isSimulationSaved: boolean = false;
  customerId: number | null;
  countryId: number | null;
  //Form Group
  fgPerforatedPipe: FormGroup;
  formInitialValues: any;
  //Attributes
  simulationName: string = "";
  treeId?: number = null;
  version?: number = null;
  nameCurrentUser: string;
  department: string = "";
  perforatedResults: Results;
  attachments: Attachment[] = [];
  attachmentsUploaded: LoadSlottedPerforatedAttachments[] = [];
  dynamicImage: any = null;
  pdfImages: any = null;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;
  dcpProduct$: Subscription;

  showPipeExists: boolean = false;
  showGradeWarning: boolean = false;

  isPreSimulationFormValid: boolean = false;

  @ViewChild('dataCheckButton') dataCheckButton: ElementRef;

  readonly NAVIGATIONS = NAVIGATIONS;

  constructor(
    private fb: FormBuilder,
    private perforatedPipeService: PerforatedPipeService,
    private perforatedService: PerforatedService,
    private actionService: ActionService,
    private dataService: DataService,
    private treeService: TreeService,
    private httpService: HttpService,
    private pdfService: PdfService,
    private sanitizer: DomSanitizer,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.dataService.changeModulo("perforatedPipe");

    this.createForm();
    this.subscribeActionService();
    this.subscribeDataService();

    this.dataService.currentValidateReportPRSource.subscribe(
      (validReport) => (this.validReportPR = validReport)
    );

    this.dataService.currentDepartmentSource.subscribe(
      (dpto) => (this.department = dpto)
    );

    this.dataService.currentNameSurnameSource.subscribe(
      (name) => (this.nameCurrentUser = name)
    );

    this.preSimulationService.formValidity$.subscribe(isValid => {
      this.isPreSimulationFormValid = isValid;
    });

    this.perforatedService.dataCheckFix$.subscribe((fix) => {
      fix.data.forEach((dataCheckFix) => {
        switch (dataCheckFix.fieldName) {
          case "iConnection":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("connection")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGrade":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("grade")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGradeStrength":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("gradeStrength")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iNominalWeight":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("nominalWeight")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iOutsideDiameter":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("outsideDiameter")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPipeBodyCollapseRating":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("pipeBodyCollapseRating")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iRemainingBodyWall":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("minWallThickness")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iWallThickness":
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("wallThickness")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iLL1":
            this.fgPerforatedPipe
              .get("fgLengths")
              .get("freeDistanceToBoxLL1")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iLL2":
            this.fgPerforatedPipe
              .get("fgLengths")
              .get("freeDistanceToBoxLL2")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPipeLengthLT":
            this.fgPerforatedPipe
              .get("fgLengths")
              .get("pipeLengthLT")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHoleAxialDistance":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("holeAxialDistance")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHoleDiameter":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("holeDiameter")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHoleDiameterTol":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("holeDiameterTolerance")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHoleNumberOfAxis":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("numberOfAxes")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHoleNumberOfColumnsXft":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("numberOfColumnsPerFoot")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHolePerimetralDistance":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("holePerimetralDistance")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iHolesPerFoot":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("holesPerFoot")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPitchBetweenAxes":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("staggeredOnly")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPitchCircBetweenAxes":
            this.fgPerforatedPipe
              .get("fgHoles")
              .get("helicoidalOnly")
              .setValue(dataCheckFix.dataValue);
            break;
        }
      });

      this.perforatedService.setRefreshDataCheckData(fix.section, fix.isSubmitting);
    });

    this.refreshDataCheckData$ =
      this.perforatedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "BEFORE") this.dataCheck(refreshData.isSubmitting);
      });

    this.dcpProduct$ = this.perforatedService.dcpProduct$.subscribe(
      (dcpProduct) => {
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("outsideDiameter")
          .setValue(dcpProduct.outsideDiameter);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("wallThickness")
          .setValue(dcpProduct.wallThickness);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("nominalWeight")
          .setValue(dcpProduct.nominalWeight);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("minWallThickness")
          .setValue((1 + dcpProduct.minWallThickness) * 100);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("grade")
          .setValue(dcpProduct.grade);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("connection")
          .setValue(dcpProduct.connection);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("gradeStrength")
          .setValue(dcpProduct.minYieldStrength / 1000);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating")
          .setValue(dcpProduct.collapsePressure);
        this.fgPerforatedPipe
          .get("fgPipes")
          .get("gradeWarning")
          .setValue(dcpProduct.gradeWarning);

        this.fgPerforatedPipe
          .get("fgPipes")
          .get("grade")
          .valueChanges.pipe(take(1))
          .subscribe(() => {
            this.fgPerforatedPipe
              .get("fgPipes")
              .get("gradeWarning")
              .setValue(false);
          });

        this.perforatedService.setCloseDcpModal(true);
      }
    );

    this.perforatedService.perforatedResults$.subscribe((perforatedResults) => {
      this.perforatedResults = perforatedResults;
    });

    this.perforatedService.attachments$.subscribe((attachments) => {
      this.attachments = attachments;
    });

    this.perforatedService.attachmentsUploaded$.subscribe(
      (attachmentsUploaded) => {
        this.attachmentsUploaded = attachmentsUploaded;
      }
    );

    this.perforatedService.submit$.subscribe(() => {
      this.submit();
    });


    this.perforatedService.grooves$.subscribe((grooves) => {
      if (grooves == false) {
        this.disableForm();
        Swal.fire({
          text: "The model and equations related with this analysis are valid only for machined perforations. Irregularities in the final geometry of the slots, residual tensions, and high temperature changes, produced due to alternative slotting methods, were not taken into account.",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
      } else if (grooves == true) {
        this.enableForm();
      }
    });

    this.dataService.currentRolSource.pipe(take(1)).subscribe((role) => {
      if (role == "USER") {
        this.addFormValidators();
        this.showPipeExists = false;
      } else if (role == "ADMIN" || role == "SUPER_USER") {
        this.showPipeExists = true;
        this.fgPerforatedPipe
          .get("pipeExists")
          .addValidators(Validators.requiredTrue);
        this.addFormWarnings();
      }
    });

    merge(
      this.fgPerforatedPipe.get("fgType").valueChanges,
      this.fgPerforatedPipe.get("fgHoles").valueChanges,
      this.fgPerforatedPipe.get("fgPipes").valueChanges,
      this.fgPerforatedPipe.get("fgLengths").valueChanges
    ).subscribe(() => {
      if (
        this.fgPerforatedPipe.get("fgType").valid &&
        this.fgPerforatedPipe.get("fgHoles").valid &&
        this.fgPerforatedPipe.get("fgPipes").valid &&
        this.fgPerforatedPipe.get("fgLengths").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });
  }

  private enableForm() {
    this.fgPerforatedPipe.enable({ emitEvent: false });
    if (
      this.fgPerforatedPipe.get("fgType").get("pattern").value ===
      Patterns.Aligned
    ) {
      this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").disable();
      this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").disable();
    } else if (
      this.fgPerforatedPipe.get("fgType").get("pattern").value ==
      Patterns.Staggered
    ) {
      this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").disable();
    } else if (
      this.fgPerforatedPipe.get("fgType").get("pattern").value ==
      Patterns.Helicoidal
    ) {
      this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").disable();
    }
  }

  private resetSimulation() {
    this.treeId = null;
    this.version = null;
    this.simulationName = "";
    this.perforatedService.setShowPerforatedResults(false);
    this.perforatedService.setPerforatedResults(null);
    this.fgPerforatedPipe.reset(this.formInitialValues);
    this.dataService.changeValidateReportPR(false);
    this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").enable();
    this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").enable();
    this.pdfImages = null;
    this.dynamicImage = null;
    this.perforatedService.setAttachments([]);
    this.perforatedService.setAttachmentsUploaded([]);
  }

  private subscribeActionService() {
    this.subActionService = this.actionService.currentMessage.subscribe(
      (value) => {
        switch (value) {
          case HeaderState.new:
            this.confirmReset();
            break;
          case HeaderState.report:
            this.printReport();
            break;
          case HeaderState.validate:
            this.confirmReport();
            break;
          default:
            break;
        }
      }
    );
  }

  confirmReset() {
    if (
      JSON.stringify(this.formInitialValues) !==
      JSON.stringify(this.fgPerforatedPipe.value)
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSimulation();
          this.preSimulationService.resetValues();
        }
      });
    } else {
      this.resetSimulation();
      this.preSimulationService.resetValues();
      Swal.fire({
        title: "New simulation created",
        icon: "info",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
  }

  private printReport() {
    let printPdfPRRequest: PrintPdfPRRequest = this.printFilePRRequest(
      this.actionService.data
    );
    this.pdfService.getPdfPR(printPdfPRRequest);
  }

  private printFilePRRequest(printedModalData: PrintedModalData) {
    var roundDecimalsPipe = new RoundDecimalsPipe();
    var emptyDataTablePipe = new EmptyDataTablePipe();
    var transformToPercentPipe = new TransformToPercentPipe();

    let printPdfPRRequest: PrintPdfPRRequest = {
      printOnlyFirstPage: printedModalData.printOnlyFirstPage,
      requestedBy: printedModalData.requestedBy,
      authorizedBy: printedModalData.authorizedBy,
      reviewedBy: printedModalData.reviewedBy,
      SDCaseNumber: printedModalData.SDCaseNumber,
      createdBy: this.nameCurrentUser ? this.nameCurrentUser : "-",
      modelVer: this.version,
      preMachiningManufacturer: printedModalData.preMachiningManufacturer,
      type: {
        grooveMachinedWithSaw: this.fgPerforatedPipe
          .get("fgType")
          .get("groovesMachinedWithSaw").value,
        pattern: this.fgPerforatedPipe.get("fgType").get("pattern").value,
        grouping: null,
      },
      pipe: {
        outsideDiameter: this.fgPerforatedPipe
          .get("fgPipes")
          .get("outsideDiameter").value,
        nominalWeight: this.fgPerforatedPipe.get("fgPipes").get("nominalWeight")
          .value,
        grade: this.fgPerforatedPipe.get("fgPipes").get("grade").value,
        gradeStrength: this.fgPerforatedPipe.get("fgPipes").get("gradeStrength")
          .value,
        wallThickness: this.fgPerforatedPipe.get("fgPipes").get("wallThickness")
          .value,
        connection: this.fgPerforatedPipe.get("fgPipes").get("connection")
          .value,
        pipeBodyCollapseRating: this.fgPerforatedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating").value,
        remainingBodyWall: this.fgPerforatedPipe
          .get("fgPipes")
          .get("minWallThickness").value,
      },
      holeScheme: {
        holeDiameter: this.fgPerforatedPipe.get("fgHoles").get("holeDiameter")
          .value,
        holeDiameterTol: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holeDiameterTolerance").value,
        holesPerFoot: this.fgPerforatedPipe.get("fgHoles").get("holesPerFoot")
          .value,
        holeNumberOfAxis: this.fgPerforatedPipe
          .get("fgHoles")
          .get("numberOfAxes").value,
        holeNumberOfColumnsXft: this.fgPerforatedPipe
          .get("fgHoles")
          .get("numberOfColumnsPerFoot").value,
        holeAxialDistance: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holeAxialDistance").value,
        staggeredColumnPitch:
          this.fgPerforatedPipe.get("fgType").get("pattern").value ==
          Patterns.Staggered
            ? this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").value
            : null,
        holePerimetralDistance: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holePerimetralDistance").value,
        helicoidalPerimetralPitch:
          this.fgPerforatedPipe.get("fgType").get("pattern").value ==
          Patterns.Helicoidal
            ? this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").value
            : null,
      },
      length: {
        pipeLengthLT: this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT")
          .value,
        LL1: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
          .value,
        LL2: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
          .value,
      },
      outputs: {
        openAreaInchAtID: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.perforatedResults?.openAreaInchAtID,
            0
          )
        ),
        openAreaPercentAtID: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.perforatedResults?.openAreaPercentAtID,
            2
          )
        ),
        openAreaInchAtOD: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.perforatedResults?.openAreaInchAtOD,
            0
          )
        ),
        openAreaPercentAtOD: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.perforatedResults?.openAreaPercentAtOD,
            2
          )
        ),
        results: [
          {
            key: PipeResults.Axial,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Axial.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Axial.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.perforatedResults?.results.Axial.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Bending,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Bending.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Bending.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.perforatedResults?.results.Bending.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Collapse,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Collapse.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Collapse.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.perforatedResults?.results.Collapse.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Torsion,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Torsion.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.perforatedResults?.results.Torsion.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.perforatedResults?.results.Torsion.reductionFactor
                ),
                0
              )
            ),
          },
        ],
      },
      patternImage: this.pdfImages?.Pattern,
      pipeLengthImage: this.pdfImages?.PipeLength,
      slotHoleImage: this.pdfImages?.Slot_Hole,
      xSectionImage: this.pdfImages?.XSection,
    };

    return printPdfPRRequest;
  }

  confirmReport() {  
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

      this.isSimulationSaved = false;
      
      return;
    }

    if (!this.validReportPR) {
      if (this.fgPerforatedPipe.invalid) {
        Swal.fire({
          title: "Action required",
          text: "Please load a simulation or verify values for all fields",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
      if (!this.perforatedResults) {
        Swal.fire({
          title: "Action required",
          text: "Please press submit and save the simulation to print report",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
      if (this.isSimulationSaved == false || !this.validateIfPreSimulationSaved(this.customerId, this.countryId)) {
        Swal.fire({
          title: "Action required",
          text: "Please save the simulation to print report",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
    }

    if (!this.validateIfPreSimulationSaved(this.customerId, this.countryId)) {
        Swal.fire({
          title: "Action required",
          text: "Please save the simulation to print report",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
    }
  }

  validateIfPreSimulationSaved(customerId: number | null, countryId: number | null): boolean {
    let isSaved = true;

    if(this.preSimulationService.getCurrentCountryId() == null || this.preSimulationService.getCurrentCustomerId() == null || 
      this.preSimulationService.getCurrentCountryId() != countryId || this.preSimulationService.getCurrentCustomerId() != customerId)
      isSaved = false;

      return isSaved;
  }

  private subscribeDataService() {
    this.subDataService = this.dataService.currentData.subscribe(
      (selectedSimulation) => {
        if (selectedSimulation.action != "") {
          switch (selectedSimulation.action) {
            case HeaderState.load:
              this.confirmLoad(selectedSimulation);
              break;
            case HeaderState.save:
              this.confirmSave(selectedSimulation);
              break;
            case HeaderState.delete:
              this.confirmDelete(selectedSimulation);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  confirmSave(selectedSimulation: any) {
    if (selectedSimulation.visibility == "Select") {
      this.fireWarningModal("Action required", "Please select Visibility");
    } else {
      this.perforatedPipeService
        .existsSimulation(selectedSimulation.id)
        .subscribe((resp) => {
          Swal.fire({
            title: resp.exists
              ? "Do you want to overwrite the simulation"
              : "Do you want to save the simulation",
            text: selectedSimulation.path + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
            confirmButtonText: "Proceed",
            width: 700,
          }).then((result) => {
            if (result.isConfirmed) {
              let saveSimulationPerforatedRequest: SaveSimulationPerforatedRequest =
                {
                  userId: selectedSimulation.userId,
                  treeId: selectedSimulation.id,
                  name: selectedSimulation.text,
                  department: this.department,
                  visibility: selectedSimulation.visibility,
                  treeNotes: selectedSimulation.notes,
                  simulationNotes: this.fgPerforatedPipe
                    .get("fgNotes")
                    .get("notes").value,
                  idAttachmentsToCopy:
                    selectedSimulation.id == this.treeId
                      ? []
                      : this.attachmentsUploaded
                          .filter((x) => !x.overwritten)
                          .map((x) => x.id),
                  overwriteAttachments: selectedSimulation.id != this.treeId,
                  customerId: this.preSimulationService.getCurrentCustomerId(),
                  countryId: this.preSimulationService.getCurrentCountryId(),
                  type: {
                    groove_machined_with_saw: this.fgPerforatedPipe
                      .get("fgType")
                      .get("groovesMachinedWithSaw").value,
                    pattern: this.fgPerforatedPipe.get("fgType").get("pattern")
                      .value,
                    grouping: null,
                  },
                  pipe: {
                    outside_diameter: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("outsideDiameter").value,
                    nominal_weight: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("nominalWeight").value,
                    grade: this.fgPerforatedPipe.get("fgPipes").get("grade")
                      .value,
                    grade_strength: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("gradeStrength").value,
                    wall_thickness: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("wallThickness").value,
                    connection: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("connection").value,
                    pipe_body_collapse_rating: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("pipeBodyCollapseRating").value,
                    remaining_body_wall: this.fgPerforatedPipe
                      .get("fgPipes")
                      .get("minWallThickness").value,
                  },
                  holeScheme: {
                    hole_diameter: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("holeDiameter").value,
                    hole_diameter_tol: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("holeDiameterTolerance").value,
                    holes_per_foot: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("holesPerFoot").value,
                    hole_number_of_axis: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("numberOfAxes").value,
                    hole_number_of_columns_xft: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("numberOfColumnsPerFoot").value,
                    hole_axial_distance: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("holeAxialDistance").value,
                    staggered_column_pitch:
                      this.fgPerforatedPipe.get("fgType").get("pattern")
                        .value == Patterns.Staggered
                        ? this.fgPerforatedPipe
                            .get("fgHoles")
                            .get("staggeredOnly").value
                        : null,
                    hole_perimetral_distance: this.fgPerforatedPipe
                      .get("fgHoles")
                      .get("holePerimetralDistance").value,
                    helicoidal_perimetral_pitch:
                      this.fgPerforatedPipe.get("fgType").get("pattern")
                        .value == Patterns.Helicoidal
                        ? this.fgPerforatedPipe
                            .get("fgHoles")
                            .get("helicoidalOnly").value
                        : null,
                  },
                  length: {
                    pipe_length_LT: this.fgPerforatedPipe
                      .get("fgLengths")
                      .get("pipeLengthLT").value,
                    LL1: this.fgPerforatedPipe
                      .get("fgLengths")
                      .get("freeDistanceToBoxLL1").value,
                    LL2: this.fgPerforatedPipe
                      .get("fgLengths")
                      .get("freeDistanceToBoxLL2").value,
                  },
                  attachments: this.attachments,
                  outputs: {
                    open_area_inch_at_id:
                      this.perforatedResults?.openAreaInchAtID,
                    open_area_percent_at_id:
                      this.perforatedResults?.openAreaPercentAtID,
                    open_area_inch_at_od:
                      this.perforatedResults?.openAreaInchAtOD,
                    open_area_percent_at_od:
                      this.perforatedResults?.openAreaPercentAtOD,
                    results: [
                      {
                        key: PipeResults.Axial,
                        pipe_body_rating:
                          this.perforatedResults?.results.Axial.pipeBodyRating,
                        slotted_pipe_rating:
                          this.perforatedResults?.results.Axial
                            .slottedPipeRating,
                        reduction_factor:
                          this.perforatedResults?.results.Axial.reductionFactor,
                      },
                      {
                        key: PipeResults.Bending,
                        pipe_body_rating:
                          this.perforatedResults?.results.Bending
                            .pipeBodyRating,
                        slotted_pipe_rating:
                          this.perforatedResults?.results.Bending
                            .slottedPipeRating,
                        reduction_factor:
                          this.perforatedResults?.results.Bending
                            .reductionFactor,
                      },
                      {
                        key: PipeResults.Collapse,
                        pipe_body_rating:
                          this.perforatedResults?.results.Collapse
                            .pipeBodyRating,
                        slotted_pipe_rating:
                          this.perforatedResults?.results.Collapse
                            .slottedPipeRating,
                        reduction_factor:
                          this.perforatedResults?.results.Collapse
                            .reductionFactor,
                      },
                      {
                        key: PipeResults.Torsion,
                        pipe_body_rating:
                          this.perforatedResults?.results.Torsion
                            .pipeBodyRating,
                        slotted_pipe_rating:
                          this.perforatedResults?.results.Torsion
                            .slottedPipeRating,
                        reduction_factor:
                          this.perforatedResults?.results.Torsion
                            .reductionFactor,
                      },
                    ],
                  },
                };

              this.perforatedPipeService
                .saveSimulation(saveSimulationPerforatedRequest)
                .subscribe(
                  (event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                      console.log("UPLOADING FILES");
                      console.log(event.loaded);
                      console.log(event.total);
                    }
                    if (event.type === HttpEventType.Response) {
                      if (event.body.status.success) {
                        let resp = event.body
                          .data as SavePerforatedSimulationResponse;

                        Swal.fire({
                          title: "Saved!",
                          text: "The simulation has been saved.",
                          icon: "success",
                          confirmButtonColor: "#009",
                          timer: 3000,
                          timerProgressBar: true,
                        });
                        jQuery("#sidebar").removeClass("active");
                        jQuery(".overlay").removeClass("active");

                        this.treeId = resp.treeId;
                        this.version = resp.version;
                        this.simulationName = selectedSimulation.path;
                        this.isSimulationSaved = true;
                        this.customerId = saveSimulationPerforatedRequest.customerId;
                        this.countryId = saveSimulationPerforatedRequest.countryId;
                        this.preSimulationService.setSavedCustomerId(this.customerId);
                        this.preSimulationService.setSavedCountryId(this.countryId);                
                        this.dataService.setSimulationIdName(
                          selectedSimulation.path
                        );

                        if (resp.attachments.length > 0) {
                          this.perforatedService.setAttachments([]);
                          this.perforatedService.setAttachmentsUploaded(
                            resp.attachments
                          );
                        }

                        this.setValidationToPrint();
                      } else
                        this.httpService.handleErrorMessage(
                          event.body.status.message
                        );
                    }
                  },
                  (err: Error) => {
                    Swal.fire({
                      title: err.message,
                      icon: "error",
                      confirmButtonColor: "#009",
                      cancelButtonColor: "#aaa",
                    });
                  },
                  () => {
                    this.formChangesOnce();
                  }
                );
            }
          });
        });
    }
  }

  confirmLoad(selectedSimulation: any) {
    if (
      JSON.stringify(this.formInitialValues) !==
      JSON.stringify(this.fgPerforatedPipe.value)
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) this.loadSimulation(selectedSimulation);
      });
    } else {
      this.loadSimulation(selectedSimulation);
    }
  }

  private setValidationToPrint() {
    if (this.fgPerforatedPipe.valid && this.perforatedResults)
      this.dataService.changeValidateReportPR(true);
  }

  private loadSimulation(selectedSimulation: any) {
    this.resetSimulation();

    this.perforatedPipeService.loadSimulation(selectedSimulation.id).subscribe(
      (resp) => {
        this.customerId = resp.customerId;
        this.countryId = resp.countryId;
        this.preSimulationService.setLoadedCustomerId(this.customerId);
        this.preSimulationService.setLoadedCountryId(this.countryId);
        this.preSimulationService.setSavedCustomerId(this.customerId);
        this.preSimulationService.setSavedCountryId(this.countryId);

        jQuery("#sidebar").removeClass("active");
        jQuery(".overlay").removeClass("active");

        if (resp.data) {
          if (resp.treeId) this.treeId = resp.treeId;

          if (resp.version || resp.version === 0) this.version = resp.version;

          this.fgPerforatedPipe
            .get("fgType")
            .get("groovesMachinedWithSaw")
            .setValue(resp.data.type.groove_machined_with_saw);
          this.fgPerforatedPipe
            .get("fgType")
            .get("pattern")
            .setValue(resp.data.type.pattern);

          this.fgPerforatedPipe
            .get("fgPipes")
            .get("outsideDiameter")
            .setValue(resp.data.pipe.outside_diameter);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("wallThickness")
            .setValue(resp.data.pipe.wall_thickness);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("nominalWeight")
            .setValue(resp.data.pipe.nominal_weight);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("minWallThickness")
            .setValue(resp.data.pipe.remaining_body_wall);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("grade")
            .setValue(resp.data.pipe.grade);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("connection")
            .setValue(resp.data.pipe.connection);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("gradeStrength")
            .setValue(resp.data.pipe.grade_strength);
          this.fgPerforatedPipe
            .get("fgPipes")
            .get("pipeBodyCollapseRating")
            .setValue(resp.data.pipe.pipe_body_collapse_rating);

          this.fgPerforatedPipe
            .get("fgHoles")
            .get("holeDiameter")
            .setValue(resp.data.holeScheme.hole_diameter);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("holeDiameterTolerance")
            .setValue(resp.data.holeScheme.hole_diameter_tol);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("holesPerFoot")
            .setValue(resp.data.holeScheme.holes_per_foot);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("numberOfAxes")
            .setValue(resp.data.holeScheme.hole_number_of_axis);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("numberOfColumnsPerFoot")
            .setValue(resp.data.holeScheme.hole_number_of_columns_xft);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("holeAxialDistance")
            .setValue(resp.data.holeScheme.hole_axial_distance);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("staggeredOnly")
            .setValue(resp.data.holeScheme.staggered_column_pitch);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("holePerimetralDistance")
            .setValue(resp.data.holeScheme.hole_perimetral_distance);
          this.fgPerforatedPipe
            .get("fgHoles")
            .get("helicoidalOnly")
            .setValue(resp.data.holeScheme.helicoidal_perimetral_pitch);

          this.fgPerforatedPipe
            .get("fgLengths")
            .get("pipeLengthLT")
            .setValue(resp.data.length.pipe_length_LT);
          this.fgPerforatedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL1")
            .setValue(resp.data.length.lL1);
          this.fgPerforatedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL2")
            .setValue(resp.data.length.lL2);

          const pipeExists: boolean =
            resp.data.outputs.open_area_inch_at_id != null ||
            resp.data.outputs.open_area_inch_at_od != null ||
            resp.data.outputs.open_area_percent_at_id != null ||
            resp.data.outputs.open_area_percent_at_od != null;

          this.fgPerforatedPipe.get("pipeExists").setValue(pipeExists);

          if (resp.data.attachments.length > 0)
            this.perforatedService.setAttachmentsUploaded(
              resp.data.attachments
            );

          if (pipeExists) {
            this.getImages().then(() => {
              if(!this.customerId || !this.countryId)
                Swal.fire({
                  title: "Action required",
                  text: "Please select customer and country to view the simulation",
                  icon: "warning",
                  confirmButtonColor: "#009",
                  cancelButtonColor: "#aaa",
                });
              else
                Swal.fire({
                  title: "Loaded!",
                  text: "The simulation has been loaded.",
                  icon: "success",
                  confirmButtonColor: "#009",
                  timer: 3000,
                  timerProgressBar: true,
                });
            });
            this.perforatedService.setPerforatedResultsOnLoad(
              resp.data.outputs
            );
            this.perforatedService.setShowPerforatedResults(true);
          }
          else {
            if(!this.customerId || !this.countryId)
              Swal.fire({
                title: "Action required",
                text: "Please select customer and country to view the simulation",
                icon: "warning",
                confirmButtonColor: "#009",
                cancelButtonColor: "#aaa",
              });
            else
              Swal.fire({
                title: "Loaded!",
                text: "The simulation has been loaded.",
                icon: "success",
                confirmButtonColor: "#009",
                timer: 3000,
                timerProgressBar: true,
              });   
          }
        }

        this.fgPerforatedPipe
          .get("fgNotes")
          .get("notes")
          .setValue(resp.simulation_notes);

        this.simulationName = selectedSimulation.path;
        this.isSimulationSaved = true;
        this.dataService.setSimulationIdName(selectedSimulation.path);
        this.setValidationToPrint();
      },
      (err: Error) => {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        this.resetSimulation();
      },
      () => {
        this.formChangesOnce();
      }
    );
  }

  confirmDelete(selectedSimulation: any) {
    Swal.fire({
      title: "Do you want to delete the item",
      text: selectedSimulation.path + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSimulation(selectedSimulation);
      }
    });
  }

  private deleteSimulation(selectedSimulation: any) {
    this.treeService.deleteTree(
      this.dataService.extraData,
      selectedSimulation.userId,
      selectedSimulation
    );
    this.actionService.changeAction(HeaderState.cancel);
    jQuery('#sidebar').removeClass('active');
    jQuery('.overlay').removeClass('active');
    if (selectedSimulation.level == "simulation") {
      this.perforatedPipeService
        .deleteSimulation(selectedSimulation.id)
        .subscribe(
          (resp) => {
            Swal.fire({
              title: "Deleted!",
              text: "The item has been deleted.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });

            if (resp.treeId == this.treeId) this.resetSimulation();
          },
          (err: Error) => {
            Swal.fire({
              title: err.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        );
    } else {
      Swal.fire({
        title: "Deleted!",
        text: "The item has been deleted.",
        icon: "success",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  private fireWarningModal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
    });
  }

  disableForm() {
    this.fgPerforatedPipe
      .get("fgType")
      .get("pattern")
      .disable({ emitEvent: false });
    this.fgPerforatedPipe.get("fgPipes").disable({ emitEvent: false });
    this.fgPerforatedPipe.get("fgHoles").disable({ emitEvent: false });
    this.fgPerforatedPipe.get("fgLengths").disable({ emitEvent: false });
    this.fgPerforatedPipe.get("fgNotes").disable({ emitEvent: false });
    this.fgPerforatedPipe.get("fgAttachments").disable({ emitEvent: false });
    this.fgPerforatedPipe.get("pipeExists").disable({ emitEvent: false });
  }

  private createForm() {
    this.fgPerforatedPipe = this.fb.group({
      fgType: this.fb.group({
        groovesMachinedWithSaw: [null, Validators.requiredTrue],
        pattern: [null, Validators.required],
      }),
      fgPipes: this.fb.group({
        outsideDiameter: [null, Validators.required],
        wallThickness: [null, Validators.required],
        nominalWeight: [null, Validators.required],
        minWallThickness: [null, Validators.required],
        grade: [null, Validators.required],
        connection: [null, Validators.required],
        gradeStrength: [null, Validators.required],
        pipeBodyCollapseRating: [null, Validators.required],
        gradeWarning: [false],
      }),
      fgHoles: this.fb.group({
        holeDiameter: [null, Validators.required],
        holeDiameterTolerance: [null, Validators.required],
        holesPerFoot: [null, Validators.required],
        numberOfAxes: [null, Validators.required],
        numberOfColumnsPerFoot: [null, Validators.required],
        holeAxialDistance: [null, Validators.required],
        staggeredOnly: [null, Validators.required],
        holePerimetralDistance: [null, Validators.required],
        helicoidalOnly: [null, Validators.required],
      }),
      fgLengths: this.fb.group({
        pipeLengthLT: [null, Validators.required],
        freeDistanceToBoxLL1: [null, Validators.required],
        freeDistanceToBoxLL2: [null, Validators.required],
      }),
      fgNotes: this.fb.group({
        notes: [null],
      }),
      fgAttachments: this.fb.group({
        attachments: [null],
      }),
      pipeExists: [false],
    });

    this.formInitialValues = this.fgPerforatedPipe.value;
  }

  addFormWarnings() {
    //PIPES
    this.fgPerforatedPipe
      .get("fgPipes")
      .get("gradeWarning")
      .addValidators(
        WarningValidator.requiredBooleanWarning(
          true,
          "GRADE",
          "This tool does not apply for anisotropic (Highly alloyed) materials, High Collapse or Improved Collapse grades. For these cases contact WEDE."
        )
      );

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("outsideDiameter")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          20,
          null,
          "OUTSIDE_DIAMETER",
          "Pipe OD out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          2.375,
          null,
          "OUTSIDE_DIAMETER",
          "Pipe OD out of common range."
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("nominalWeight")
      .addValidators([
        WarningValidator.minValueLimitedByValueWarning(
          4.6,
          null,
          "NOMINAL_WEIGHT",
          "Nominal weight out of common range."
        ),
        WarningValidator.maxValueLimitedByValueWarning(
          297.1,
          null,
          "NOMINAL_WEIGHT",
          "Nominal weight out of common range."
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("wallThickness")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          1.5,
          null,
          "WALL_THICKNESS",
          "Wall thickness out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          0.19,
          null,
          "WALL_THICKNESS",
          "Wall thickness out of common range."
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("minWallThickness")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          100,
          null,
          "MIN_WALL_THICKNESS",
          "Remaining body wall out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          87.5,
          null,
          "MIN_WALL_THICKNESS",
          "Remaining body wall out of common range."
        ),
      ]);

    //HOLES
    this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameter")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          1.5,
          null,
          "HOLE_DIAMETER",
          "Perforations OD out of valid range.",
          false
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameterTolerance")
      .addValidators([
        WarningValidator.minValueLimitedByValueWarning(
          0,
          null,
          "HOLE_DIAMETER_TOLERANCE",
          "Tolerance out of valid range.",
          true
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgHoles")
      .get("numberOfAxes")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          180,
          null,
          "NUMBER_OF_AXES",
          "Number of axes too big to fit in the circunference.",
          false
        ),
      ]);
  }

  addFormValidators() {
    //PIPES
    this.fgPerforatedPipe
      .get("fgPipes")
      .get("outsideDiameter")
      .addValidators([
        NumericInputValidator.max(20, "Pipe OD out of common range."),
        NumericInputValidator.min(2.375, "Pipe OD out of common range."),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("nominalWeight")
      .addValidators([
        NumericInputValidator.max(297.1, "Nominal weight out of common range."),
        NumericInputValidator.min(4.6, "Nominal weight out of common range."),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("wallThickness")
      .addValidators([
        NumericInputValidator.max(21.5, "Wall thickness out of common range."),
        NumericInputValidator.min(0.19, "Wall thickness out of common range."),
      ]);

    this.fgPerforatedPipe
      .get("fgPipes")
      .get("minWallThickness")
      .addValidators([
        NumericInputValidator.max(
          100,
          "Remaining body wall out of common range."
        ),
        NumericInputValidator.min(
          87.5,
          "Remaining body wall out of common range."
        ),
      ]);

    //HOLES
    this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameter")
      .addValidators([
        NumericInputValidator.max(
          1.5,
          "Perforations OD out of valid range.",
          false
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameterTolerance")
      .addValidators([
        NumericInputValidator.min(
          0.001,
          "Tolerance out of valid range.",
          false
        ),
      ]);

    this.fgPerforatedPipe
      .get("fgHoles")
      .get("numberOfAxes")
      .addValidators([
        NumericInputValidator.max(
          180,
          "Number of axes too big to fit in the circunference.",
          false
        ),
      ]);
  }

  onSubmit() {
    this.preDataCheck(true);
  }

  preDataCheck(isSubmitting = false) {
    if(!this.fgPerforatedPipe.get("fgType").valid) {
      Swal.fire({
        title: "Invalid Type Section",
        text: "Please, complete the type section.",
        icon: "warning",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      this.dataCheck(isSubmitting);
      this.dataCheckButton.nativeElement.click();
    }
  }

  submit() {
    this.simulationName = "";
    this.dataService.changeValidateReportPR(false);
    this.isSimulationSaved = false;

    let perforatedPipeRequest: PerforatedPipeRequest = {
      lengths: {
        pipeLength: this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT")
          .value,
        lenL1: this.fgPerforatedPipe
          .get("fgLengths")
          .get("freeDistanceToBoxLL1").value,
        lenL2: this.fgPerforatedPipe
          .get("fgLengths")
          .get("freeDistanceToBoxLL2").value,
        lenMachined:
          this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT").value -
          (this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
            .value +
            this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
              .value),
      },
      pipe: {
        od: this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").value,
        nominalWeight: this.fgPerforatedPipe.get("fgPipes").get("nominalWeight")
          .value,
        grade: this.fgPerforatedPipe.get("fgPipes").get("grade").value,
        gradeStrength: this.fgPerforatedPipe.get("fgPipes").get("gradeStrength")
          .value,
        wt: this.fgPerforatedPipe.get("fgPipes").get("wallThickness").value,
        connection: this.fgPerforatedPipe.get("fgPipes").get("connection")
          .value,
        collapsePressure: this.fgPerforatedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating").value,
        rbw: this.fgPerforatedPipe.get("fgPipes").get("minWallThickness").value,
      },
      slotsScheme: {
        groovePerimDistance: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holePerimetralDistance").value,
        grooveAxialDistance: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holeAxialDistance").value,
        staggeredColumnPitch:
          this.fgPerforatedPipe.get("fgType").get("pattern").value ==
          Patterns.Staggered
            ? this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").value
            : 0,
        numColumnsPerFoot: this.fgPerforatedPipe
          .get("fgHoles")
          .get("numberOfColumnsPerFoot").value,
        numAxis: this.fgPerforatedPipe.get("fgHoles").get("numberOfAxes").value,
        gangCircSeparation: 0,
        gangNumofGroove: 0,
        maxGrooveWideID: 0,
        maxGrooveWideOD: 0,
        maxGrooveLenID: 0,
        maxGrooveLenOD: 0,
        groovePerFoot: this.fgPerforatedPipe.get("fgHoles").get("holesPerFoot")
          .value,
        holeDiameterToll: this.fgPerforatedPipe
          .get("fgHoles")
          .get("holeDiameterTolerance").value,
        holeDiameter: this.fgPerforatedPipe.get("fgHoles").get("holeDiameter")
          .value,
        groovePitchBetweenAxisHelicoidal: this.fgPerforatedPipe.get("fgType").get("pattern")
        .value == Patterns.Helicoidal
        ? this.fgPerforatedPipe
            .get("fgHoles")
            .get("helicoidalOnly").value
        : 0,
      },
      type: {
        machinedSaw: this.fgPerforatedPipe
          .get("fgType")
          .get("groovesMachinedWithSaw").value,
        pattern: this.fgPerforatedPipe.get("fgType").get("pattern").value,
        grouping: Groupings.Single,
      },
    };

    this.getImages();

    this.perforatedPipeService
      .getPerforatedPipeResults(perforatedPipeRequest)
      .subscribe(
        (perforatedPipeResponse) => {
          this.perforatedService.setPerforatedResults(
            perforatedPipeResponse.results
          );
          this.perforatedService.setShowPerforatedResults(true);

          let logRequest = [{
            MachinedHoles: perforatedPipeRequest.type.machinedSaw,
            Pattern: perforatedPipeRequest.type.pattern,
            OutsideDiameter: perforatedPipeRequest.pipe.od,
            WallThickness: perforatedPipeRequest.pipe.wt,
            NominalWeight: perforatedPipeRequest.pipe.nominalWeight,
            MinWallThickness: perforatedPipeRequest.pipe.rbw,
            Grade: perforatedPipeRequest.pipe.grade,
            Connection: perforatedPipeRequest.pipe.connection,
            GradeStrength: perforatedPipeRequest.pipe.gradeStrength,
            PipeBodyCollapseRating: perforatedPipeRequest.pipe.collapsePressure,
            HoleDiameter: perforatedPipeRequest.slotsScheme.holeDiameter,
            HoleDiameterTolerance: perforatedPipeRequest.slotsScheme.holeDiameterToll,
            HolesPerFoot: perforatedPipeRequest.slotsScheme.groovePerFoot,
            NumberOfAxes: perforatedPipeRequest.slotsScheme.numAxis,
            NumberOfColumnsPerFoot: perforatedPipeRequest.slotsScheme.numColumnsPerFoot,
            HoleAxialDistance: perforatedPipeRequest.slotsScheme.grooveAxialDistance,
            FirstSecondRowPitch: perforatedPipeRequest.type.pattern ==
            Patterns.Staggered
              ? perforatedPipeRequest.slotsScheme.staggeredColumnPitch
              : null,
            HolePerimetralDistance: perforatedPipeRequest.slotsScheme.groovePerimDistance,
            PerimetralPitch: perforatedPipeRequest.type.pattern ==
            Patterns.Helicoidal
              ? perforatedPipeRequest.slotsScheme.groovePitchBetweenAxisHelicoidal
              : null,
            PipeLengthLT: perforatedPipeRequest.lengths.pipeLength,
            FreeDistanceToBoxLL1: perforatedPipeRequest.lengths.lenL1,
            FreeDistanceToPinLL2: perforatedPipeRequest.lengths.lenL2,
            AxialPipeBodyRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Axial.pipeBodyRating),
            TorsionPipeBodyRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Torsion.pipeBodyRating),
            CollapsePipeBodyRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Collapse.pipeBodyRating),
            BendingPipeBodyRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Bending.pipeBodyRating),
            AxialPrePerforatedPipeRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Axial.slottedPipeRating),
            TorsionPrePerforatedPipeRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Torsion.slottedPipeRating),
            CollapsePrePerforatedPipeRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Collapse.slottedPipeRating),
            BendingPrePerforatedPipeRatings: this.validateNumberToLogs(perforatedPipeResponse.results.results.Bending.slottedPipeRating),
            AxialRemainingFactor: this.validateNumberToLogs(perforatedPipeResponse.results.results.Axial.reductionFactor),
            TorsionRemainingFactor: this.validateNumberToLogs(perforatedPipeResponse.results.results.Torsion.reductionFactor),
            CollapseRemainingFactor: this.validateNumberToLogs(perforatedPipeResponse.results.results.Collapse.reductionFactor),
            BendingRemainingFactor: this.validateNumberToLogs(perforatedPipeResponse.results.results.Bending.reductionFactor),
            PercentageAtOuterDiameter: this.validateNumberToLogs(perforatedPipeResponse.results.openAreaPercentAtOD),
            PercentageAtInnerDiameter: this.validateNumberToLogs(perforatedPipeResponse.results.openAreaPercentAtID),
            TotalAreaAtOuterDiameter: this.validateNumberToLogs(perforatedPipeResponse.results.openAreaInchAtOD),
            TotalAreaAtInnerDiameter: this.validateNumberToLogs(perforatedPipeResponse.results.openAreaInchAtID),
            CustomerId: this.preSimulationService.getCurrentCustomerId(),
            CountryId: this.preSimulationService.getCurrentCountryId()
          }];
  
          this.perforatedPipeService.registerLog(logRequest).subscribe();
        },
        (err: Error) => {
          Swal.fire({
            title: err.message,
            icon: "error",
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
          });
        },
        () => {
          this.formChangesOnce();
        }
      );
  }

  private validateNumberToLogs(value: number) {
    let returnValue: number = value;
    if (typeof value !== 'number' || isNaN(value) || !isFinite(value))
      returnValue = null;
    else if (Object.is(value, -0))
      returnValue = 0;
    return value;
  }

  private formChangesOnce() {
    this.fgPerforatedPipe.valueChanges.pipe(take(1)).subscribe(() => {
      this.treeId = null;
      this.version = null;
      this.simulationName = "";
      this.perforatedService.setShowPerforatedResults(false);
      this.perforatedService.setPerforatedResults(null);
      this.dataService.changeValidateReportPR(false);
      this.isSimulationSaved = false;
      this.dynamicImage = null;
      this.pdfImages = null;
    });
  }

  private getImages() {
    return new Promise<void>((resolve) => {
      let slottedPerforatedPipeImagesRequest: SlottedPerforatedPipeImagesRequest =
        {
          iEnumSimType: SimulationTypes.PERFORATED,
          iEnumUnitMeasure: UnitSystems.API,
          iCircSeparation: 0,
          iPatternType: this.fgPerforatedPipe.get("fgType").get("pattern")
            .value,
          iGroupingType: Groupings.Single,
          iHoleDiameter: this.fgPerforatedPipe
            .get("fgHoles")
            .get("holeDiameter").value,
          iLL1: this.fgPerforatedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL1").value,
          iLL2: this.fgPerforatedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL2").value,
          iMaxGrooveLOD: 0,
          iMaxGrooveWID: 0,
          iMaxGrooveWOD: 0,
          iNumberOfAxis: this.fgPerforatedPipe
            .get("fgHoles")
            .get("numberOfAxes").value,
          iNumberOfColumnsXft: this.fgPerforatedPipe
            .get("fgHoles")
            .get("numberOfColumnsPerFoot").value,
          iNumberOfGrooves: 0,
          iOutsideDiameter: this.fgPerforatedPipe
            .get("fgPipes")
            .get("outsideDiameter").value,
          iPerimetralDistance: this.fgPerforatedPipe
            .get("fgHoles")
            .get("holePerimetralDistance").value,
          iPipeLengthLT: this.fgPerforatedPipe
            .get("fgLengths")
            .get("pipeLengthLT").value,
          iPitchBetweenColumn:
            this.fgPerforatedPipe.get("fgType").get("pattern").value ==
            Patterns.Staggered
              ? this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").value
              : 0,
          iPitchCircBetweenAxes:
            this.fgPerforatedPipe.get("fgType").get("pattern").value ==
            Patterns.Helicoidal
              ? this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").value
              : 0,
          iRotate: true,
          iSlot_Hole_AxialDistance: this.fgPerforatedPipe
            .get("fgHoles")
            .get("holeAxialDistance").value,
        };

      this.perforatedPipeService
        .getPerforatedPipeImages(slottedPerforatedPipeImagesRequest)
        .subscribe((perforatedPipeImagesResponse) => {
          this.pdfImages = perforatedPipeImagesResponse.imageDic;

          this.dynamicImage =
            perforatedPipeImagesResponse.imageList ||
            perforatedPipeImagesResponse.imageList.length === 0
              ? this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/png;base64, ${perforatedPipeImagesResponse.imageList}`
                )
              : null;

          resolve();
        });
    });
  }

  dataCheck(isSubmitting = false) {
    let dcPerforatedBeforeRequest: DCPerforatedBeforeRequest = {
      iConnection: this.fgPerforatedPipe.get("fgPipes").get("connection").value,
      iGrade: this.fgPerforatedPipe.get("fgPipes").get("grade").value,
      iGradeStrength: this.fgPerforatedPipe.get("fgPipes").get("gradeStrength")
        .value,
      iNominalWeight: this.fgPerforatedPipe.get("fgPipes").get("nominalWeight")
        .value,
      iOutsideDiameter: this.fgPerforatedPipe
        .get("fgPipes")
        .get("outsideDiameter").value,
      iPipeBodyCollapseRating: this.fgPerforatedPipe
        .get("fgPipes")
        .get("pipeBodyCollapseRating").value,
      iRemainingBodyWall: this.fgPerforatedPipe
        .get("fgPipes")
        .get("minWallThickness").value,
      iWallThickness: this.fgPerforatedPipe.get("fgPipes").get("wallThickness")
        .value,
      rdAligned:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdHelicoidal:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Helicoidal,
      rdNo:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === false,
      rdYes:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === true,
      iLL1: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
        .value,
      iLL2: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
        .value,
      iPipeLengthLT: this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT")
        .value,
      iHoleAxialDistance: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holeAxialDistance").value,
      iHoleDiameter: this.fgPerforatedPipe.get("fgHoles").get("holeDiameter")
        .value,
      iHoleDiameterTol: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holeDiameterTolerance").value,
      iHoleNumberOfAxis: this.fgPerforatedPipe
        .get("fgHoles")
        .get("numberOfAxes").value,
      iHoleNumberOfColumnsXft: this.fgPerforatedPipe
        .get("fgHoles")
        .get("numberOfColumnsPerFoot").value,
      iHolePerimetralDistance: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holePerimetralDistance").value,
      iHolesPerFoot: this.fgPerforatedPipe.get("fgHoles").get("holesPerFoot")
        .value,
      iPitchBetweenAxes: this.fgPerforatedPipe
        .get("fgHoles")
        .get("staggeredOnly").value,
      iPitchCircBetweenAxes: this.fgPerforatedPipe
        .get("fgHoles")
        .get("helicoidalOnly").value,
    };

    this.perforatedPipeService
      .dataCheckPerforatedBefore(dcPerforatedBeforeRequest)
      .subscribe((data) => {
        let dataCheckData = [];
        let anySectionWithErrors = false;
        let anySectionWithWarnings = false;

        for (let section in data.results) {
          if (data.results[section].hasError) {
            anySectionWithErrors = true;
            for (let key in data.results[section].errors) {
              let errorData = {
                type: "ERROR",
                parent: data.results[section].belongsTo,
                description: data.results[section].errors[key].description,
                options: [],
              };

              for (let optionKey in data.results[section].errors[key].options) {
                errorData.options.push(
                  data.results[section].errors[key].options[optionKey]
                );
              }

              dataCheckData.push(errorData);
            }
          }
          if (data.results[section].hasWarning) {
            anySectionWithWarnings = true;
            for (let key in data.results[section].warnings) {
              let warningData = {
                type: "WARNING",
                parent: data.results[section].belongsTo,
                description: data.results[section].warnings[key].description,
                options: [],
              };

              for (let optionKey in data.results[section].warnings[key]
                .options) {
                warningData.options.push(
                  data.results[section].warnings[key].options[optionKey]
                );
              }

              dataCheckData.push(warningData);
            }
          }
        }

        this.perforatedService.setDataCheckData(dataCheckData, "BEFORE", isSubmitting, anySectionWithErrors, anySectionWithWarnings);
      });
  }

  showRangeModal() {
    this.perforatedService.setShowRangeModal(true);
  }

  ngOnDestroy() {
    this.subActionService.unsubscribe();
    this.subDataService.unsubscribe();
    this.refreshDataCheckData$.unsubscribe();
    this.dcpProduct$.unsubscribe();
    this.preSimulationService.resetValues();
  }
}
