import { Component, OnInit } from "@angular/core";
import { PerforatedService } from "../../services/perforated.service";

@Component({
  selector: "app-perforated-ranges-modal",
  templateUrl: "./perforated-ranges-modal.component.html",
  styleUrls: ["./perforated-ranges-modal.component.css"],
})

export class PerforatedRangesModalComponent implements OnInit{
  showModal: boolean = false;

  constructor(private perforatedService: PerforatedService) {}

  ngOnInit(): void {
    this.perforatedService.showRangeModal$.subscribe((show: boolean) => {
      this.showModal = show;
    })
  }

  closeModal() {
    this.showModal = false;
  }
}
