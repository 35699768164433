import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-slotted-notes',
  templateUrl: './slotted-notes.component.html',
  styleUrls: ['./slotted-notes.component.css']
})
export class SlottedNotesComponent implements OnInit {

  @Input() fgSlottedPipe : FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
