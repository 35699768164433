import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NumericInputModule, NUMERIC_INPUT_LOCALE } from '@ng-dl/numeric-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { DataTablesModule } from "angular-datatables";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from "ngx-spinner";
import { HomeComponent } from "src/app/pages/home/Home.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthConfigModule } from "./auth/Auth-config.module";
import { FiltroformComponent } from './modules/CasingWear/components/filtroform/Filtroform.component';
import { CasingwearComponent } from './modules/CasingWear/pages/Casingwear.component';
import { FilterCRComponent } from './modules/CorrosionRate/components/filter-cr/filter-cr.component';
import { FiltersCRComponent } from "./modules/CorrosionRate/components/filters-cr/filters-cr.component";
import { CorrosionRateComponent } from "./modules/CorrosionRate/pages/corrosion-rate.component";
import { PerforatedPipeComponent } from './modules/slotted-perforated-pipe/perforated-pipe/perforated-pipe.component';
import { DataCheckModalComponent } from "./modules/slotted-perforated-pipe/shared/data-check-modal/data-check-modal.component";
import { DcpFilterComponent } from "./modules/slotted-perforated-pipe/shared/slotted-modal/components/dcp-filter/dcp-filter.component";
import { MainFiltersComponent } from './modules/slotted-perforated-pipe/shared/slotted-modal/components/dcp-filter/main-filters/main-filters.component';
import { SubFiltersComponent } from "./modules/slotted-perforated-pipe/shared/slotted-modal/components/dcp-filter/sub-filters/sub-filters.component";
import { DcpTableComponent } from './modules/slotted-perforated-pipe/shared/slotted-modal/components/dcp-table/dcp-table.component';
import { SlottedModalComponent } from "./modules/slotted-perforated-pipe/shared/slotted-modal/slotted-modal.component";
import { SlottedAttachmentsComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-attachments/slotted-attachments.component';
import { SlottedLengthComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-lengths/slotted-lengths.component';
import { SlottedNotesComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-notes/slotted-notes.component';
import { SlottedPipePipesComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-pipes/slotted-pipes.component';
import { SlottedResultsComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-results/slotted-results.component';
import { SlottedSlotSchemeComponent as SlottedSlotsSchemeComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-slots-scheme/slotted-slots-scheme.component';
import { SlottedTypeComponent } from './modules/slotted-perforated-pipe/slotted-pipe/components/slotted-type/slotted-type.component';
import { SlottedPipeComponent } from "./modules/slotted-perforated-pipe/slotted-pipe/slotted-pipe.component";

import { AppmgrComponent } from "./pages/appmgr/Appmgr.component";
import { AdvancedpipeComponent } from "./pages/home/advancedpipe/Advancedpipe.component";
import { CorrosionclasComponent } from "./pages/home/corrosionclas/Corrosionclas.component";
import { HelpComponent } from "./pages/home/help/help.component";
import { TestComponent } from "./pages/home/test/Test.component";
import { LayoutComponent } from "./pages/layout/Layout.component";
import { CasingwearpdfComponent } from "./pages/reports/casingwear/Casingwearpdf.component";
import { CorrosionclaspdfComponent } from "./pages/reports/corrosionclass/Corrosionclaspdf.component";
import { StatisticsComponent } from "./pages/statistics/Statistics.component";
import { UsermgrComponent } from "./pages/usermgr/Usermgr.component";
import { EmptyDataTablePipe } from "./pipes/empty-data-table/empty-data-table.pipe";
import { FilterPipe } from "./pipes/Filter.pipe";
import { RoundDecimalsPipe } from "./pipes/round-decimals/round-decimals.pipe";
import { TransformToPercentPipe } from "./pipes/transform-to-percent/transform-to-percent.pipe";
import { ContextMenuComponent } from "./shared/components/context-menu/Context-menu.component";
import { ErrorMessageComponent } from './shared/components/error-message/error-message.component';
import { HeaderComponent } from "./shared/components/header/Header.component";
import MenuComponent from "./shared/components/menu/Menu.component";
import { SidebarComponent } from "./shared/components/sidebar/Sidebar.component";
import { TreeComponent } from "./shared/components/tree/Tree.component";
import { TreeCreateComponent } from "./shared/components/tree/Tree.create.component";
import { TreeDeleteComponent } from "./shared/components/tree/Tree.delete.component";
import { TreeLoadComponent } from "./shared/components/tree/Tree.load.component";
import { WarningMessageComponent } from './shared/components/warning-message/warning-message.component';
import { ConvertMultipleUnitsDirective } from './shared/directives/convert-multiple-units.directive';
import { ConvertUnitDirective } from './shared/directives/convert-unit.directive';
import { ErrorMessageDirective } from './shared/directives/error-message.directive';
import { HighlightDirective } from "./shared/directives/Highlight.directive";
import { InterchangeableUnitDirective } from './shared/directives/interchangeable-unit.directive';
import { WarningMessageDirective } from './shared/directives/warning-message.directive';
import { AuthInterceptor } from "./shared/interceptors/AuthInterceptor";
import {FreeDraggingHandleDirective} from './shared/directives/free-dragging-handle.directive';
import {FreeDraggingDirective} from './shared/directives/free-dragging.directive'
import { PerforatedTypeComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-type/perforated-type.component";
import { PerforatedPipePipesComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-pipes/perforated-pipes.component";
import { PerforatedHolesSchemeComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-holes-scheme/perforated-holes-scheme.component";
import { PerforatedNotesComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-notes/perforated-notes.component";
import { PerforatedLengthComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-lengths/perforated-lengths.component";
import { PerforatedAttachmentsComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-attachments/perforated-attachments.component";
import { PerforatedResultsComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-results/perforated-results.component";
import { PerforatedRangesModalComponent } from "./modules/slotted-perforated-pipe/perforated-pipe/components/perforated-ranges-modal/perforated-ranges-modal.component";
import { SlottedRangesModalComponent } from "./modules/slotted-perforated-pipe/slotted-pipe/components/slotted-ranges-modal/slotted-ranges-modal.component";
import { CommaToDotDirective } from "./shared/directives/comma-to-dot.directive";
import { SafeNumberPipe } from "./pipes/safe-number/safe-number.pipe";
import { PreSimulationComponent } from './shared/components/pre-simulation/pre-simulation.component';
import { CreateCustomerModalComponent } from './shared/components/pre-simulation/create-customer-modal/create-customer-modal.component';
import { CustomermgrComponent } from './modules/customermgr/customermgr.component';
import { MatSelGuidelinesComponent } from './modules/mat-sel-guidelines/mat-sel-guidelines.component';
import { Option1Component } from './modules/mat-sel-guidelines/option1/option1.component';
import { Option2Component } from './modules/mat-sel-guidelines/option2/option2.component';
import { CraComponent } from './modules/mat-sel-guidelines/cra/cra.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    HomeComponent,
    ContextMenuComponent,
    AdvancedpipeComponent,
    CorrosionclasComponent,
    CorrosionclaspdfComponent,
    CasingwearpdfComponent,
    CorrosionRateComponent,
    FiltersCRComponent,
    FilterCRComponent,
    AppmgrComponent,
    StatisticsComponent,
    UsermgrComponent,
    SidebarComponent,
    TreeComponent,
    TreeCreateComponent,
    TreeLoadComponent,
    TreeDeleteComponent,
    HighlightDirective,
    FilterPipe,
    TestComponent,
    LayoutComponent,
    CasingwearComponent,
    FiltroformComponent,
    HelpComponent,
    InterchangeableUnitDirective,
    ConvertUnitDirective,
    ConvertMultipleUnitsDirective,
    ErrorMessageDirective,
    ErrorMessageComponent,
    SlottedPipeComponent,
    SlottedSlotsSchemeComponent,
    SlottedPipePipesComponent,
    SlottedTypeComponent,
    SlottedModalComponent,
    DcpTableComponent,
    DcpFilterComponent,
    MainFiltersComponent,
    SubFiltersComponent,
    SlottedLengthComponent,
    SlottedNotesComponent,
    SlottedAttachmentsComponent,
    WarningMessageDirective,
    WarningMessageComponent,
    SlottedResultsComponent,
    RoundDecimalsPipe,
    EmptyDataTablePipe,
    TransformToPercentPipe,
    FreeDraggingHandleDirective,
    FreeDraggingDirective,
    DataCheckModalComponent,
    PerforatedPipeComponent,
    PerforatedTypeComponent,
    PerforatedPipePipesComponent,
    PerforatedHolesSchemeComponent,
    PerforatedNotesComponent,
    PerforatedLengthComponent,
    PerforatedAttachmentsComponent,
    PerforatedResultsComponent,
    PerforatedRangesModalComponent,
    SlottedRangesModalComponent,
    CommaToDotDirective,
    SafeNumberPipe,
    PreSimulationComponent,
    CreateCustomerModalComponent,
    CustomermgrComponent,
    MatSelGuidelinesComponent,
    Option1Component,
    Option2Component,
    CraComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    AuthConfigModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NumericInputModule,
    NgSelectModule,
    NgxDropzoneModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NUMERIC_INPUT_LOCALE, useValue: "en" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
