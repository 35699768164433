import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { merge, Subscription } from "rxjs";
import { RoundDecimalsPipe } from "src/app/pipes/round-decimals/round-decimals.pipe";
import { PerforatedPipeService } from "src/app/services/perforated-pipe/perforated-pipe.service";
import { AbstractControlWarning } from "src/app/shared/helpers/AbstractControlWarning";
import { Patterns } from "../../../shared/enums/patterns.enum";
import { DCPerforatedHolesRequest } from "../../models/perforated-pipe";
import { PerforatedService } from "../../services/perforated.service";

@Component({
  selector: "app-perforated-holes-scheme",
  templateUrl: "./perforated-holes-scheme.component.html",
  styleUrls: ["./perforated-holes-scheme.component.css"],
})
export class PerforatedHolesSchemeComponent implements OnInit {
  @Input() fgPerforatedPipe: FormGroup;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;
  pattern$: Subscription;
  roundDecimalsPipe: RoundDecimalsPipe;

  constructor(
    private perforatedService: PerforatedService,
    private perforatedPipeService: PerforatedPipeService
  ) {}

  ngOnInit(): void {
    this.roundDecimalsPipe = new RoundDecimalsPipe();
    this.pattern$ = this.perforatedService.pattern$.subscribe((inputData) => {
      if (inputData === Patterns.Aligned) {
        this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").disable();
        this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").disable();
      } else if (inputData == Patterns.Staggered) {
        this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").enable();
        this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").disable();
      } else if (inputData == Patterns.Helicoidal) {
        this.fgPerforatedPipe.get("fgHoles").get("staggeredOnly").disable();
        this.fgPerforatedPipe.get("fgHoles").get("helicoidalOnly").enable();
      }
    });

    this.refreshDataCheckData$ =
      this.perforatedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "HOLE") this.dataCheck();
      });

    merge(
      this.fgPerforatedPipe.get("fgType").valueChanges,
      this.fgPerforatedPipe.get("fgHoles").valueChanges,
      this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").valueChanges
    ).subscribe(() => {
      if (
        this.fgPerforatedPipe.get("fgType").valid &&
        this.fgPerforatedPipe.get("fgHoles").valid &&
        this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });

    merge(
      this.fgPerforatedPipe.get("fgHoles").get("numberOfAxes").valueChanges,
      this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").valueChanges
    ).subscribe(() => {
      if (
        this.fgPerforatedPipe.get("fgHoles").get("numberOfAxes").valid &&
        this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").valid
      )
        this.fgPerforatedPipe
          .get("fgHoles")
          .get("holePerimetralDistance")
          .setValue(
            this.roundDecimalsPipe.transform(
              (Math.PI *
                this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter")
                  .value) /
                this.fgPerforatedPipe.get("fgHoles").get("numberOfAxes").value,
              2
            )
          );
      else
        this.fgPerforatedPipe
          .get("fgHoles")
          .get("holePerimetralDistance")
          .setValue(null);
    });
  }

  dataCheck() {
    let dcPerforatedHolesRequest: DCPerforatedHolesRequest = {
      iOutsideDiameter: this.fgPerforatedPipe
        .get("fgPipes")
        .get("outsideDiameter").value,
      iHoleAxialDistance: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holeAxialDistance").value,
      iHoleDiameter: this.fgPerforatedPipe.get("fgHoles").get("holeDiameter")
        .value,
      iHoleDiameterTol: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holeDiameterTolerance").value,
      iHoleNumberOfAxis: this.fgPerforatedPipe
        .get("fgHoles")
        .get("numberOfAxes").value,
      iHoleNumberOfColumnsXft: this.fgPerforatedPipe
        .get("fgHoles")
        .get("numberOfColumnsPerFoot").value,
      iHolePerimetralDistance: this.fgPerforatedPipe
        .get("fgHoles")
        .get("holePerimetralDistance").value,
      iHolesPerFoot: this.fgPerforatedPipe.get("fgHoles").get("holesPerFoot")
        .value,
      iPitchBetweenAxes: this.fgPerforatedPipe
        .get("fgHoles")
        .get("staggeredOnly").value,
      iPitchCircBetweenAxes: this.fgPerforatedPipe
        .get("fgHoles")
        .get("helicoidalOnly").value,
      rdAligned:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdHelicoidal:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Helicoidal,
      rdNo:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === false,
      rdYes:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === true,
    };

    this.perforatedPipeService
      .dataCheckPerforatedHoles(dcPerforatedHolesRequest)
      .subscribe((data) => {
        let dataCheckData = [];

        if (data.results.Output.hasError)
          for (let key in data.results.Output.errors) {
            let errorData = {
              type: "ERROR",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.errors[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.errors[key].options) {
              errorData.options.push(
                data.results.Output.errors[key].options[optionKey]
              );
            }

            dataCheckData.push(errorData);
          }

        if (data.results.Output.hasWarning)
          for (let key in data.results.Output.warnings) {
            let warningData = {
              type: "WARNING",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.warnings[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.warnings[key].options) {
              warningData.options.push(
                data.results.Output.warnings[key].options[optionKey]
              );
            }

            dataCheckData.push(warningData);
          }

        this.perforatedService.setDataCheckData(dataCheckData, "HOLE");
      });
  }

  get holeDiameter() {
    return this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameter") as AbstractControlWarning;
  }

  get holeDiameterTolerance() {
    return this.fgPerforatedPipe
      .get("fgHoles")
      .get("holeDiameterTolerance") as AbstractControlWarning;
  }

  get numberOfAxes() {
    return this.fgPerforatedPipe
      .get("fgHoles")
      .get("numberOfAxes") as AbstractControlWarning;
  }

  ngOnDestroy(): void {
    this.refreshDataCheckData$.unsubscribe();
    this.pattern$.unsubscribe();
  }
}
