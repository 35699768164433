<section id="main-filters">
  <form [formGroup]="fgFilters">
    <div formGroupName="fgMainFilters">
      <div class="row">
        <div class="col-md-3">
          <label class="filter-title">Outside Diameter</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.OD"
            bindValue="id"
            bindLabel="text"
            formControlName="outsideDiameter"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Wall Thickness (Weight)</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.WT"
            bindValue="id"
            bindLabel="text"
            formControlName="wallThickness"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Grade</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.GR"
            bindValue="id"
            bindLabel="text"
            formControlName="grade"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Connection</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.CN"
            bindValue="id"
            bindLabel="text"
            formControlName="connection"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</section>
