import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { ERROR_MESSAGES } from "../consts/error-messages";
import { UnitMeasures } from "../enums/unit-measures.enum";

@Directive({
  selector: "[errorMessage]",
})
export class ErrorMessageDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() unitMeasure: UnitMeasures;
  @Input() selectedUnit: any;
  @Input() errorContainerId: string;
  @Input() hiddenErrors: string[];
  elementRef: ElementRef;
  controlValueChanges: Subscription;

  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    this.hiddenErrors = this.hiddenErrors ?? [];

    this.controlValueChanges = this.ngControl.control?.valueChanges.subscribe(
      () => {
        this.displayErrors();
      }
    );
  }

  ngAfterViewInit(): void {
    this.displayErrors();
  }

  displayErrors() {
    const errors = Object.entries(this.ngControl.control?.errors || {}).filter(
      (error) => !this.hiddenErrors.includes(error[0])
    );

    const errorElement = document.getElementById(this.errorContainerId);

    if (
      !errors.length ||
      (!this.ngControl.control.dirty && !this.ngControl.control.touched)
    ) {
      if (typeof errorElement != "undefined" && errorElement != null)
        errorElement.getElementsByClassName("error-message")[0].innerHTML = "";
      return;
    }

    const customError = errors.find((error) => error[0] == "errorMessage");

    let errorMessage = customError
      ? customError[1]
      : ERROR_MESSAGES[errors[0][0]];

    if (typeof errorElement != "undefined" && errorElement != null)
      errorElement.getElementsByClassName("error-message")[0].innerHTML =
        errorMessage;
  }

  ngOnDestroy(): void {
    this.controlValueChanges.unsubscribe();
  }
}
