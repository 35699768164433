<div style="font-size: 14px; margin-bottom: 10px; margin-left: -8px">
  Complete the following fields: Model Validity
  <span id="rangePopUp" (click)="rangePopUp()"><b>Ranges</b></span>
</div>
<div class="row">
  <div class="col-4 col-sm-4">
    <form [formGroup]="fg" (ngSubmit)="onSubmit()">
      <div class="row" style="min-height: 80px">
        <div
          class="col-sm-12 control-label input-m2"
          style="display: flex; flex-wrap: wrap; height: auto; padding-left: 0"
        >
          <div
            class="col-sm-4"
            style="
              display: flex;
              justify-content: space-between;
              padding: 1px 7px;
            "
          >
            <span><b>INPUT</b> </span>
          </div>
          <div
            class="col-sm-8"
            style="text-align: right; height: 25px; font-size: 18px; padding: 0"
          >
            <button
              type="button"
              class="btn-no-styles"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Reset"
              (click)="onNewInput()"
              [disabled]="fg.disabled"
              [ngClass]="{ 'btn-disabled': fg.disabled }"
            >
              <b>Reset</b>
              <span class="icon s7-refresh"></span>
            </button>
          </div>
          <span class="col-sm-8 sub-text" style="padding: 1px 7px"
            ><b
              >*If you don't have pH (In-situ) value, go to pH (In-situ)
              determination.</b
            ></span
          >
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">pH (In-situ)</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15" style="width: 100%">
            <input
              id="phInSitu"
              [ngClass]="{
                'input-error':
                  (form.phInSitu.touched || form.dirty) && form.phInSitu.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="phInSitu"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'phInSituError'"
              warningMessage
              [warningContainerId]="'phInSituWarning'"
            />
          </div>
          <error-message
            id="phInSituError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'phInSituWarning'"
            [ngClass]="{ 'd-none': form.phInSitu.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">ppH<sub>2</sub>S</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="pph2s"
              [ngClass]="{
                'input-error':
                  (form.pph2s.touched || form.dirty) && form.pph2s.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="pph2s"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'pph2sError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="pph2sUnit.value"
              [defaultControl]="form.pph2sDefault"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
              [unitControl]="pph2sUnit"
              warningMessage
              [warningContainerId]="'pph2sWarning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="pph2sUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="form.pph2s"
                [defaultControl]="form.pph2sDefault"
                [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
                [items]="PRESSURE_UNITS_KPA_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="pph2sError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'pph2sWarning'"
            [ngClass]="{ 'd-none': form.pph2s.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 control-label input-xs">Determine pH</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <div class="switch-button switch-button-xs" style="font-size: 13px">
              <input
                type="checkbox"
                id="determinePH"
                [formControl]="determinePH"
              /><span> <label for="determinePH"></label></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 15px">
        <div class="col-sm-4 control-label" style="padding: 1px 7px">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="fg.disabled || fg.invalid"
          >
            Run Model
          </button>
        </div>
        <div class="col-sm-8"></div>
      </div>
    </form>
  </div>
  <form [formGroup]="fgPH" (ngSubmit)="calculate()">
    <div class="col-4 col-sm-4 b-l" style="padding-left: 25px">
      <div class="row" style="min-height: 80px">
        <div
          class="col-sm-12 control-label input-m"
          style="display: flex; flex-wrap: wrap; height: auto"
        >
          <span style="width: 100%"><b>pH (In-situ) DETERMINATION</b></span>
          <span style="width: 100%" class="sub-text"
            ><b>(according to ISO 15156-2 Annex D) - Inputs</b></span
          >
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">ppCO<sub>2</sub></label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="ppCO2"
              [ngClass]="{
                'input-error':
                  (formPH.ppCO2.touched || formPH.dirty) && (formPH.ppCO2.invalid || fgPH.errors?.sumInvalid)
              }"
              class="form-control input-xs no-spin"
              formControlName="ppCO2"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'ppCO2Error'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="ppCO2Unit.value"
              [defaultControl]="formPH.ppCO2Default"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
              [unitControl]="ppCO2Unit"
              warningMessage
              [warningContainerId]="'ppCO2Warning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="ppCO2Unit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="formPH.ppCO2"
                [defaultControl]="formPH.ppCO2Default"
                [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
                [items]="PRESSURE_UNITS_KPA_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="ppCO2Error"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'ppCO2Warning'"
            [ngClass]="{ 'd-none': formPH.ppCO2.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">ppH<sub>2</sub>S</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="ppH2S"
              [ngClass]="{
                'input-error':
                  (formPH.ppH2S.touched || formPH.dirty) && (formPH.ppH2S.invalid || fgPH.errors?.sumInvalid)
              }"
              class="form-control input-xs no-spin"
              formControlName="ppH2S"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'ppH2SError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
              [selectedUnit]="ppH2SUnit.value"
              [defaultControl]="formPH.ppH2SDefault"
              [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
              [unitControl]="ppH2SUnit"
              warningMessage
              [warningContainerId]="'ppH2SWarning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="ppH2SUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
                [convertedControl]="formPH.ppH2S"
                [defaultControl]="formPH.ppH2SDefault"
                [object]="{ defaultUnit: unitMeasureService.pressureUnits.Kpa }"
                [items]="PRESSURE_UNITS_KPA_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="ppH2SError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'ppH2SWarning'"
            [ngClass]="{ 'd-none': formPH.ppH2S.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">Ca<sup>2+</sup></label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="ca2"
              [ngClass]="{
                'input-error':
                  (formPH.ca2.touched || formPH.dirty) && formPH.ca2.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="ca2"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'ca2Error'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Concentration"
              [selectedUnit]="ca2Unit.value"
              [defaultControl]="formPH.ca2Default"
              [object]="{
                defaultUnit:
                  unitMeasureService.concentrationUnits.MilliequivalentsLiter,
                molecularWeight: 40,
                valence: 2
              }"
              [unitControl]="ca2Unit"
              warningMessage
              [warningContainerId]="'ca2Warning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="ca2Unit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Concentration"
                [convertedControl]="formPH.ca2"
                [defaultControl]="formPH.ca2Default"
                [object]="{
                  defaultUnit:
                    unitMeasureService.concentrationUnits.MilliequivalentsLiter,
                  molecularWeight: 40,
                  valence: 2
                }"
                [items]="CONCENTRATION_UNITS_MEQL_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="ca2Error"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'ca2Warning'"
            [ngClass]="{ 'd-none': formPH.ca2.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs"
          >HCO<sub>3</sub><sup style="top: -0.8em">-</sup></label
        >
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="ca2"
              [ngClass]="{
                'input-error':
                  (formPH.hco3.touched || formPH.dirty) && formPH.hco3.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="hco3"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'hco3Error'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Concentration"
              [selectedUnit]="hco3Unit.value"
              [defaultControl]="formPH.hco3Default"
              [object]="{
                defaultUnit:
                  unitMeasureService.concentrationUnits.MilliequivalentsLiter,
                molecularWeight: 61,
                valence: 1
              }"
              [unitControl]="hco3Unit"
              warningMessage
              [warningContainerId]="'hco3Warning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="hco3Unit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Concentration"
                [convertedControl]="formPH.hco3"
                [defaultControl]="formPH.hco3Default"
                [object]="{
                  defaultUnit:
                    unitMeasureService.concentrationUnits.MilliequivalentsLiter,
                  molecularWeight: 61,
                  valence: 1
                }"
                [items]="CONCENTRATION_UNITS_MEQL_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="hco3Error"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'hco3Warning'"
            [ngClass]="{ 'd-none': formPH.hco3.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 control-label input-xs">Temperature</label>
        <div class="col-sm-8">
          <div class="input-group xs-mb-15">
            <input
              id="temperature"
              [ngClass]="{
                'input-error':
                  (formPH.temperature.touched || formPH.dirty) &&
                  formPH.temperature.invalid
              }"
              class="form-control input-xs no-spin"
              formControlName="temperature"
              autocomplete="off"
              errorMessage
              [errorContainerId]="'temperatureError'"
              interchangeableUnit
              [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
              [selectedUnit]="temperatureUnit.value"
              [defaultControl]="formPH.temperatureDefault"
              [unitControl]="temperatureUnit"
              warningMessage
              [warningContainerId]="'temperatureWarning'"
            />
            <span class="unit input-group-addon input-xs label-dts thickness-0">
              <ng-select
                class="unit-select"
                [formControl]="temperatureUnit"
                convertUnit
                [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
                [convertedControl]="formPH.temperature"
                [defaultControl]="formPH.temperatureDefault"
                [items]="TEMPERATURE_UNITS_ARRAY"
                bindLabel="symbol"
                bindValue="enum"
                [clearable]="false"
                [searchable]="false"
              >
              </ng-select>
            </span>
          </div>
          <error-message
            id="temperatureError"
            class="text-danger"
            style="top: -15px; position: relative"
          ></error-message>
          <warning-message
            [id]="'temperatureWarning'"
            [ngClass]="{ 'd-none': formPH.temperature.invalid }"
            class="text-danger"
            style="top: -15px; position: relative"
          ></warning-message>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-12 control-label input-xs" style="margin-top: 8px"
          >pp (CO<sub>2</sub> + H<sub>2</sub>S)</label
        >
      </div>
      <div class="row" *ngIf="oPH || oPH === 0">
        <label class="col-sm-12 control-label input-xs" style="margin-top: 8px"
          >pH (In-situ)</label
        >
      </div>

      <div *ngIf="fgPH.errors?.sumInvalid" class="row">
        <div class="col-sm-12 control-label" style="padding: 1px 7px">
          <span style="color: red; font-size: 10px"
            >(ppH<sub>2</sub>S + ppCO<sub>2</sub>) must be between 1 and 10000
            kPa</span
          >
        </div>
      </div>

      <div class="row" style="margin-top: 15px">
        <div class="col-sm-8 control-label" style="padding: 1px 7px">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="fgPH.disabled || fgPH.invalid"
          >
            Calculate pH
          </button>
        </div>
        <div class="col-sm-4"></div>
      </div>
    </div>
    <div class="col-4 col-sm-4" style="padding-right: 25px">
      <div class="row" style="min-height: 80px">
        <div class="col-sm-12">
          <div
            class="col-sm-8"
            style="text-align: right; height: 25px; font-size: 18px"
          >
            <button
              type="button"
              class="btn-no-styles"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Reset"
              (click)="onNewCalculatePH()"
              [disabled]="fgPH.disabled"
              [ngClass]="{ 'btn-disabled': fgPH.disabled }"
            >
              <b>Reset</b>
              <span class="icon s7-refresh"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oPpCO2"
                class="form-control input-xs no-spin"
                formControlName="oPpCO2"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oPpCO2Unit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >kPa</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oPpH2S"
                class="form-control input-xs no-spin"
                formControlName="oPpH2S"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oPpH2SUnit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >kPa</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oCa2"
                class="form-control input-xs no-spin"
                formControlName="oCa2"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oCa2Unit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >meq/l</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oHco3"
                class="form-control input-xs no-spin"
                formControlName="oHco3"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oHco3Unit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >meq/l</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oTemperature"
                class="form-control input-xs no-spin"
                formControlName="oTemperature"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oTemperatureUnit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >°C</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15">
              <input
                id="oSumPP"
                class="form-control input-xs no-spin"
                formControlName="oSumPP"
                dlNumericInput
                tabindex="-1"
                readonly
              />
              <span
                id="oSumPPUnit"
                class="input-group-addon input-xs label-dts thickness-0 not-allowed unit"
                >kPa</span
              >
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
      <div class="row" *ngIf="oPH || oPH === 0">
        <div class="form-group">
          <div class="col-sm-8">
            <div class="input-group xs-mb-15" style="width: 100%">
              <input
                id="oPH"
                class="form-control input-xs no-spin"
                dlNumericInput
                tabindex="-1"
                readonly
                [value]="oPH"
              />
            </div>
          </div>
          <label class="col-sm-4 control-label input-xs"></label>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="row" style="margin-top: 45px">
  <div
    class="am-checkbox"
    *ngIf="(imgModel && imgModelString != imgModelDefaultString) || imgPH"
  >
    <input type="checkbox" id="hasPtCoordCheckbox" [formControl]="hasPtCoord" />
    <label for="hasPtCoordCheckbox">Show point coordinates on chart</label>
  </div>
  <div class="col-7 col-sm-7" *ngIf="imgModel">
    <img id="imgModel" [src]="imgModel" style="padding-left: 0" />
  </div>
  <div class="col-5 col-sm-5" *ngIf="imgPH">
    <img id="imgPH" [src]="imgPH" style="padding-left: 0" />
  </div>
</div>

<div *ngIf="rangeOpt2" class="boundary range-container">
  <div
    id="rangeOpt2"
    class="range-cr-box"
    appFreeDragging
    appFreeDraggingHandle
  >
    <button type="button" class="close" (click)="closeModal()">&times;</button>
    <div id="rangeTitleContainer">
      <p id="rangesModalTitle"><b> Valid Ranges </b></p>
      <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
        <div class="swal2-icon-content" style="font-size: 1.75em">!</div>
      </div>
    </div>

    <div id="rangeVal">
      <p style="color: #666"><b>INPUT:</b></p>
      <p>pH (In-situ) 2.5 - 6.5</p>
      <p>ppH<sub>2</sub>S 0.01 - 1000 kPa</p>
      <p style="color: #666"><br /><b>pH (In-situ) DETERMINATION:</b></p>
      <p>
        ppCO<sub>2</sub> 1 < (ppH<sub>2</sub>S + ppCO<sub>2</sub>) < 10000 kPa
      </p>
      <p>
        ppH<sub>2</sub>S 1 < (ppH<sub>2</sub>S + ppCO<sub>2</sub>) < 10000 kPa
      </p>
      <p>Ca<sup>2+</sup> >= 0 meq/l</p>
      <p>HCO<sub>3</sub><sup style="top: -0.8em">-</sup> >= 0 meq/l</p>
      <p>Temperature 20 - 150 °C</p>
    </div>
  </div>
</div>
