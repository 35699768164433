import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { ActionService } from "src/app/services/Action.service";
import { TreeAction } from "src/app/models/tree/TreeAction";
import { TreeLevels } from "src/app/models/tree/TreeLevels";
import { TreeService } from "src/app/services/Tree.service";

import Swal from "sweetalert2";
import { toTitleCase } from "../../helpers/Index";
import { HeaderState } from "src/app/models/header/HeaderState";
import { DataService } from "src/app/services/Data.service";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
declare var jQuery: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit, OnDestroy {
  title: string = "Sidebar";

  //Properties
  get isLoad() {
    return this.action == HeaderState.load;
  }
  get isSave() {
    return this.action == HeaderState.save;
  }
  get isDelete() {
    return this.action == HeaderState.delete;
  }

  //Actions
  actionSubscription: Subscription;
  dataSubscription: Subscription;
  action: HeaderState = HeaderState.default;
  msgTitle: string = "";
  disabled: boolean = true;
  selected: TreeAction = {
    id: 0,
    text: "",
    level: "",
    activate: false,
    action: "",
    path: "",
    parents: "",
    userId: 0,
    notes: "",
    visibility: "",
  };

  default: TreeAction = {
    id: 0,
    text: "",
    level: "",
    activate: false,
    action: "",
    path: "",
    parents: "",
    userId: 0,
    notes: "",
    visibility: "",
  };

  //Menu
  userId: number = 6;
  department: string = "IT";
  rol: string = "USER";
  userTree: TreeLevels;

  constructor(
    public actionService: ActionService,
    public dataService: DataService,
    public treeService: TreeService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.dataService.currentUserIdSource.subscribe(userid => (this.userId= userid));
    this.dataService.currentRolSource.pipe(take(1)).subscribe(role => (this.rol= role));
    this.dataService.currentDepartmentSource.subscribe(dpto => (this.department= dpto));
    this.getAction();

    this.router.events.subscribe(() => {      
      jQuery("#sidebar").removeClass("active");
      this.dataService.changeData(this.default,null);
    });
  }

  getHeaderColor() {
    switch (this.action) {
      case HeaderState.load:
        return {
          background: "#009",
          color: "#fff",
        };
      case HeaderState.save:
        return {
          background: "#090",
          color: "#fff",
        };
      case HeaderState.delete:
        return {
          background: "#900",
          color: "#fff",
        };
      case HeaderState.cancel:
        return;
      default:
        return {
          background: "#009",
          color: "#fff",
        };
    }
  }

  getAction() {
    this.actionSubscription = this.actionService.currentMessage.subscribe(
      (action) => {
        this.action = action;
        if (action != HeaderState.cancel) {
          this.msgTitle = toTitleCase(action);
        }
      }
    );
  }

  getTreeLevels(tree: TreeLevels) {
    this.userTree = tree;
  }

  activateActionMenu(select: TreeAction) {
    this.selected = select;
    this.disabled = !select.activate;
  }

  rsbCancel() {
    jQuery("#sidebar").removeClass("active");
    jQuery(".overlay").removeClass("active");
    this.disabled = true;
    this.actionService.changeAction(HeaderState.cancel);
  }

  loadSimulation() {
    this.dataService.changeData(this.selected);
    this.disabled = true;
    jQuery("#sidebar").removeClass("active");
    jQuery(".overlay").removeClass("active");
  }

  confirmSave() {
      this.selected.visibility = jQuery(
        "#visibilitySelect option:selected"
      ).text();
      this.selected.notes = jQuery("#noteTextarea").val();
      this.dataService.changeData(this.selected);
  }

  confirmDelete() {
    this.dataService.changeData(this.selected,this.userTree);
    this.disabled = true;
  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
  }
}
