<style>
  p {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  label {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  #middle-column {
    margin-left: -10px;
    width: 200px;
  }

  .table > thead > tr > th {
    font-weight: bold;
    color: black;
    border-bottom: 1px solid black;
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  .table > tbody > tr > td {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
    font-weight: 300;
    vertical-align: middle;
  }

  .slider.slider-horizontal {
    width: 185px !important;
    height: 20px;
  }

  .notView {
    display: none;
  }

  .croquis-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 50px;
    color: #808080;
  }

  .croquis-title > p {
    text-align: center;
    margin: 0;
  }

  .croquis-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .refGlobalContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .refContainer {
    width: 35%;
  }

  .refContainer > div > p {
    font-family: FrutigerRoman, sans-serif;
    font-size: 11px;
    margin: 0 !important;
  }

  .greenRef{
    color: #009900;
  }

  .orangeRef{
    color: #ffa31a;
  }

  .blueRef{
    color: #010199;
  }

  .violetRef{
    color: #d11874;
  }

  .lineContainer{
    display: flex; 
    flex-wrap: wrap; 
    align-items: baseline; 
  }

  .lineContainer > p {
    padding-left: 3%;
  }

  .lineRef{
    border: 3px solid;
     width: 30px;
     height: 1px;
  }

  .croquis-img > img {
    width: 100%;
  }

  .container-coquis {
    display: flex;
    flex-wrap: wrap;
  }

  .container-maxX {
    display: flex;
    flex-wrap: wrap;
  }

  .container-wearPercentage {
    display: flex;
    flex-wrap: wrap;
  }

  .axis-y-graph-container {
    display: flex;
    justify-content: center;
  }

  .axis-y-graph-container > p {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-align: center;
    color: var(--main-grey-color);
    font-size: 12px;
    opacity: 0.8;
  }

  .axis-x-container {
    display: block;
  }

  .axis-x-container > p {
    text-align: center;
    color: var(--main-grey-color);
    font-size: 12px;
    opacity: 0.8;
  }

  #line-chart {
    height: 250px;
    width: 100%;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
</style>
<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">Casing Wear Derating Calculator</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.CASING_WEAR.name }}</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div [ngStyle]="{'border': !isPreSimulationFormValid ? 'none' : ''}" class="panel-heading">
            <div>
              <div style="font-size: 13px; display: none !important">
                <div class="form-group">
                  <label>Unit System</label>
                  <div class="am-radio inline">
                    <input
                      id="rdMetric"
                      type="radio"
                      name="radioOption"
                      class="input-xs"
                      [(ngModel)]="radioOption"
                      value="metric"
                      disabled
                    />
                    <label for="rdMetric" class="input-xs">Metric</label>
                  </div>
                  <div class="am-radio inline">
                    <input
                      id="rdImperial"
                      type="radio"
                      checked="true"
                      name="radioOption"
                      class="input-xs"
                      [(ngModel)]="radioOption"
                      value="imperial"
                    />
                    <label for="rdImperial" class="input-xs">Imperial</label>
                  </div>
                </div>
              </div>
              <app-pre-simulation #preSimulation></app-pre-simulation>
            </div>
          </div>
          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="panel-body">
            <div *ngFor="let item of items">
              <div *ngIf="item.visible">
                <app-filtroform
                  [item]="item"
                  (filterAction)="filterActionItem($event)"
                ></app-filtroform>
              </div>
            </div>
          </div>
          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" id="graph-table">
            <div class="panel-body">
              <div class="graph-container">
                <div class="col-sm-10" style="padding: 0">
                  <div class="axis-y-graph-container">
                    <p>Derating Coeff [%]</p>
                    <div id="line-chart"></div>
                  </div>
                  <div class="axis-x-container">
                    <p>Groove Depth [% of WT]</p>
                  </div>
                  <div>
                    <div>
                      <div *ngFor="let label of labelsFiltered; let i = index">
                        <div 
                          class="col-sm-2"
                          [style.color]="colors[i]"
                        >
                          <p>{{ label }}</p>
                        </div>
                      </div>
                      <div
                        class="col-sm-2"
                        class="col-sm-2"
                        style="color: #000"
                      >
                        <p>Interpolated Wear {{ wearPerVal }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="row" style="margin-bottom: 10px">
                    <div class="form-group container-maxX">
                      <label style="width: 100%">Set Max X Axis</label>
                      <input
                        id="maxAxis"
                        type="text"
                        class="bslider form-control"
                        data-slider-min="0"
                        data-slider-max="50"
                        data-slider-step="10"
                        data-slider-value="50"
                        style="
                          display: none;
                          width: 90% !important;
                          height: 20px;
                        "
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="form-group container-wearPercentage"
                      style="margin-bottom: 8px"
                    >
                      <label style="width: 90%">Wear Percentage</label>
                      <input
                        type="number"
                        class="form-control"
                        id="wearPerVal"
                        min="0"
                        max="{{ maxX }}"
                        [(ngModel)]="wearPerVal"
                        (change)="inputChanged($event)"
                        style="
                          padding: 2px;
                          height: 30px;
                          width: 90%;
                          margin-bottom: 8px;
                        "
                      />
                    </div>

                    <div class="form-group">
                      <input
                        id="wearPer"
                        type="text"
                        class="bslider form-control"
                        data-slider-min="0"
                        data-slider-max="90"
                        data-slider-step="5"
                        data-slider-value="0"
                        style="
                          display: none;
                          width: 90% !important;
                          height: 20px;
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-body">
              <h2 class="title" style="color: black">
                Groove Depth {{ wearPerVal }}% Of Nominal Wall Thickness
              </h2>
              <table #table class="table">
                <thead>
                  <tr>
                    <th>Configuration</th>
                    <th>Load Mode</th>
                    <th *ngIf="maxX >= 0">
                      0%<sup *ngIf="wearPerVal == 0">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 0 && wearPerVal < 10">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="maxX >= 10">
                      10%<sup *ngIf="wearPerVal == 10">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 10 && wearPerVal < 20">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="maxX >= 20">
                      20%<sup *ngIf="wearPerVal == 20">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 20 && wearPerVal < 30">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="maxX >= 30">
                      30%<sup *ngIf="wearPerVal == 30">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 30 && wearPerVal < 40">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="maxX >= 40">
                      40%<sup *ngIf="wearPerVal == 40">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 40 && wearPerVal < 50">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="maxX >= 50">
                      50%<sup *ngIf="wearPerVal == 50">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 50 && wearPerVal < 60">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="isAdmin && maxX >= 60">
                      60%<sup *ngIf="wearPerVal == 60">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 60 && wearPerVal < 70">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="isAdmin && maxX >= 70">
                      70%<sup *ngIf="wearPerVal == 70">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 70 && wearPerVal < 80">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="isAdmin && maxX >= 80">
                      80%<sup *ngIf="wearPerVal == 80">[1]</sup>
                    </th>
                    <th *ngIf="wearPerVal > 80 && wearPerVal < 90">
                      {{ wearPerVal }}%<sup>[1]</sup>
                    </th>
                    <th *ngIf="isAdmin && maxX >= 90">
                      90%<sup *ngIf="wearPerVal == 90">[1]</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of table_data">
                    <td *ngIf="item?.configuration">
                      {{ item.configuration }}
                    </td>
                    <td *ngIf="item?.load">
                      {{ item.load }}
                    </td>
                    <td *ngIf="item?.col0 && maxX >= 0">
                      {{ item.col0 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 0 &&
                        wearPerVal < 10
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="item?.col10 && maxX >= 10">
                      {{ item.col10 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 10 &&
                        wearPerVal < 20
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="item?.col20 && maxX >= 20">
                      {{ item.col20 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 20 &&
                        wearPerVal < 30
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="item?.col30 && maxX >= 30">
                      {{ item.col30 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 30 &&
                        wearPerVal < 40
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="item?.col40 && maxX >= 40">
                      {{ item.col40 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 40 &&
                        wearPerVal < 50
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="item?.col50 && maxX >= 50">
                      {{ item.col50 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 50 &&
                        wearPerVal < 60
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="isAdmin && item?.col60 && maxX >= 60">
                      {{ item.col60 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 60 &&
                        wearPerVal < 70
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="isAdmin && item?.col70 && maxX >= 70">
                      {{ item.col70 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 70 &&
                        wearPerVal < 80
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="isAdmin && item?.col80 && maxX >= 80">
                      {{ item.col80 }}
                    </td>
                    <td
                      *ngIf="
                        item?.wearPercentage &&
                        wearPerVal > 80 &&
                        wearPerVal < 90
                      "
                    >
                      {{ item.wearPercentage.value }}%
                    </td>
                    <td *ngIf="isAdmin && item?.col90 && maxX >= 90">
                      {{ item.col90 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="panel-body">
              <h3 class="title" style="color: black">Description</h3>
              <p>
                Resistance Worn = Resistance Nom * Derating Coeff/100<br />
                Where :<br />
                Resistance Worn Is the resistance of the worn tubular;<br />
                Resistance Nom Is the resistance of the new component (i.e. from
                data-sheets)<br />
                Derating Coeff Is the calculated derating for the give wear
                depth<br />
                [1] user calculations Interpolated
              </p>
            </div>
            <div class="panel-body refGlobalContainer">
              <div class="form-group" style="padding-bottom: 0px; width: 65%;">
                <div class="am-checkbox">
                  <form [formGroup]="fgCroquis">
                    Use Same Scale<input
                      formControlName="croquis"
                      type="checkbox"
                      style="float: left"
                      class="needsclick"
                    />
                    <label (click)="changeUseSameScaleValue()"></label>
                  </form>
                </div>
              </div>
                <div class="refContainer">
                  <p style="margin: 0 !important;">Sketch References</p>
                  <div class="lineContainer">
                    <div class="blueRef lineRef" ></div>
                    <p>PIPE BODY OUTER DIAMETER (PBOD)</p>
                  </div>
                  <div class="lineContainer">
                    <div class="violetRef lineRef"></div>
                    <p>PIPE BODY INNER DIAMETER WITH WEAR (PBIDW)</p>
                  </div>
                  <div class="lineContainer">
                    <div class="orangeRef lineRef"></div>
                    <p>TOOL JOINT OUTER DIAMETER (TJOD)</p>
                  </div>
                  <div class="lineContainer">
                    <div class="greenRef lineRef"></div>
                    <p>REFERENCE PIPE BODY INNER DIAMETER (PBID)</p>
                  </div>   
                </div>
            </div>

            <div class="panel-body"> 
              <div
                *ngFor="let image of imagesFiltered; let i = index"
                class="col-sm-3"
              >
                <div>
                  <div class="row croquis-img">
                    <img [src]="image.base64" />
                  </div>
                  <div class="row croquis-title">
                    <p>{{ labelsFiltered[i] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="ball-clip-rotate"
      [fullScreen]="true"
      ><p style="color: white">Loading...</p></ngx-spinner
    >
  </div>
</div>
