import { LogLevel } from "angular-auth-oidc-client";

export const environment = {
  production: false,
  auth: {
    clientID: '591e0876-8267-4be7-a226-14f5318be2d7',
    postLogoutRedirectUri:'https://wede.appsqa.tenaris.com/login',
    logLevel: LogLevel.Debug
  },
  apiEndpoint: 'https://wede.appsqa.tenaris.com/api/',
  manualsUrl: {
    corrosionRate: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/EY9C2LV_JdRDgLtv_GX_jbIBZtK1DnYEa4UHHgr7tYABMw',
    corrosionClas: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/EXLiLK5lAZ5EgdWto4lFtp0BgZuqW3nHQimPy4bZ1A7vbg',
    casingWear: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/EZrVG_W7UjFPsY9_BkJ4FosB4Im0X5DG46AhXdBuEK_P2A',
    slottedPipe: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/ER6VPW4F1QJHixC3mViuHwkBeWDeqbSj3KJRNMx9XG3zkA?e=OKwNWQ',
    prePerforatedPipe: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/EWTIeyz7D7NNn2O61MMkYuUBD9AFBThL2icMfI8VXVEaMw?e=bcr1J8',
    matSelGuidelines: 'https://tenaris.sharepoint.com/:b:/s/Apps-Wede-QA/EZJvF1TlFDxJmm6W5kCDB2oBfLZM1QiLC8Oqy1-v8NQpaA?e=23eApt',
  }
};
