import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { merge, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { DataService } from "src/app/services/Data.service";
import { PerforatedPipeService } from "src/app/services/perforated-pipe/perforated-pipe.service";
import {
  AbstractControlWarning
} from "src/app/shared/helpers/AbstractControlWarning";
import { SlottedModalComponent } from "../../../shared/slotted-modal/slotted-modal.component";
import { DCPerforatedPipesRequest } from "../../models/perforated-pipe";
import { PerforatedService } from "../../services/perforated.service";
import { Groupings } from "../../../shared/enums/groupings.enum";
import { Patterns } from "../../../shared/enums/patterns.enum";

@Component({
  selector: "app-perforated-pipes",
  templateUrl: "./perforated-pipes.component.html",
  styleUrls: ["./perforated-pipes.component.css"],
})
export class PerforatedPipePipesComponent implements OnInit, OnDestroy {
  @Input() fgPerforatedPipe: FormGroup;

  @ViewChild(SlottedModalComponent)
  slottedModalComponent: SlottedModalComponent;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;

  canEditPipes: boolean = false;
  wtByOdWarning: boolean = false;

  constructor(
    private perforatedService: PerforatedService,
    private perforatedPipeService: PerforatedPipeService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.currentRolSource.pipe(take(1)).subscribe((role) => {
      this.canEditPipes = role == "ADMIN" || role == "SUPER_USER";
    });

    this.refreshDataCheckData$ =
      this.perforatedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "PIPES") this.dataCheck();
      });

    merge(
      this.fgPerforatedPipe.get("fgPipes").valueChanges,
      this.fgPerforatedPipe.get("fgType").valueChanges
    ).subscribe(() => {
      if (
        this.fgPerforatedPipe.get("fgType").valid &&
        this.fgPerforatedPipe.get("fgPipes").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });

    merge(
      this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").valueChanges,
      this.fgPerforatedPipe.get("fgPipes").get("wallThickness").valueChanges
    ).subscribe(() => {
      let od = Number(
        String(
          this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").value
        ).replace(",", ".")
      );
      let wt = Number(
        String(
          this.fgPerforatedPipe.get("fgPipes").get("wallThickness").value
        ).replace(",", ".")
      );

      if (
        this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter").value != "" &&
        this.fgPerforatedPipe.get("fgPipes").get("wallThickness").value != "" &&
        od &&
        wt &&
        wt >= od / 2
      ) {
        if (this.canEditPipes) this.wtByOdWarning = true;
        else
          setTimeout(() => {
            this.fgPerforatedPipe.get("fgPipes").setErrors({ wtByOd: true });
          }, 1);
      } else {
        if (this.canEditPipes) this.wtByOdWarning = false;
        else this.fgPerforatedPipe.get("fgPipes").setErrors(null);
      }
    });
  }

  dataCheck() {
    let dcPerforatedPipesRequest: DCPerforatedPipesRequest = {
      iConnection: this.fgPerforatedPipe.get("fgPipes").get("connection").value,
      iGrade: this.fgPerforatedPipe.get("fgPipes").get("grade").value,
      iGradeStrength: this.fgPerforatedPipe.get("fgPipes").get("gradeStrength")
        .value,
      iNominalWeight: this.fgPerforatedPipe.get("fgPipes").get("nominalWeight")
        .value,
      iOutsideDiameter: this.fgPerforatedPipe.get("fgPipes").get("outsideDiameter")
        .value,
      iPipeBodyCollapseRating: this.fgPerforatedPipe
        .get("fgPipes")
        .get("pipeBodyCollapseRating").value,
      iRemainingBodyWall: this.fgPerforatedPipe
        .get("fgPipes")
        .get("minWallThickness").value,
      iWallThickness: this.fgPerforatedPipe.get("fgPipes").get("wallThickness")
        .value,
      rdAligned:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdHelicoidal:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Helicoidal,
      rdNo: this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
        .value === false,
      rdYes: this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
        .value === true,
    };

    this.perforatedPipeService
      .dataCheckPerforatedPipes(dcPerforatedPipesRequest)
      .subscribe((data) => {
        let dataCheckData = [];

        if (data.results.Output.hasError)
          for (let key in data.results.Output.errors) {
            let errorData = {
              type: "ERROR",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.errors[key].description,
              options: data.results.Output.errors[key].options,
            };

            for (let optionKey in data.results.Output.errors[key].options) {
              errorData.options.push(
                data.results.Output.errors[key].options[optionKey]
              );
            }

            dataCheckData.push(errorData);
          }

        if (data.results.Output.hasWarning)
          for (let key in data.results.Output.warnings) {
            let warningData = {
              type: "WARNING",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.warnings[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.warnings[key].options) {
              warningData.options.push(
                data.results.Output.warnings[key].options[optionKey]
              );
            }

            dataCheckData.push(warningData);
          }

        this.perforatedService.setDataCheckData(dataCheckData, "PIPES");
      });
  }

  resetModal() {
    this.slottedModalComponent.resetModal();
    this.slottedModalComponent.preloadFilters(this.fgPerforatedPipe.get("fgPipes").value);
  }

  get gradeWarning() {
    return this.fgPerforatedPipe
      .get("fgPipes")
      .get("gradeWarning") as AbstractControlWarning;
  }

  get outsideDiameter() {
    return this.fgPerforatedPipe
      .get("fgPipes")
      .get("outsideDiameter") as AbstractControlWarning;
  }

  get wallThickness() {
    return this.fgPerforatedPipe
      .get("fgPipes")
      .get("wallThickness") as AbstractControlWarning;
  }

  get nominalWeight() {
    return this.fgPerforatedPipe
      .get("fgPipes")
      .get("nominalWeight") as AbstractControlWarning;
  }

  get minWallThickness() {
    return this.fgPerforatedPipe
      .get("fgPipes")
      .get("minWallThickness") as AbstractControlWarning;
  }

  ngOnDestroy(): void {
    this.refreshDataCheckData$.unsubscribe();
  }
}
