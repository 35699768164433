import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FilterCR } from "../models/filterCR";
import { ExportCRRequest, SaveCRRequest } from "../models/treeCR";
import { saveAs } from "file-saver";
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CorrosionRateService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  getCalculation(filterCR: FilterCR) {
    let data = [
      {
        key: filterCR.key,
        temperature: filterCR.temperatureDefault,
        pressureCO2: filterCR.pressureCO2Default,
        concentrationNaCl: filterCR.naClPercent,
      },
    ];

    let req = {
      data: data,
    };

    const url = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/calculation`;

    return this.http.post<any>(url, req, this.httpOptions).pipe(
      map((response) => {
        if (response) {
          return response.data.output;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }

  exportCsv(exportCRRequest: ExportCRRequest): any {
    let apiUrlCc = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/export`;
    this.http.post(apiUrlCc, exportCRRequest).subscribe(
      (response) => {
        const byteArray = new Uint8Array(
          atob(response["data"]["file"])
            .split("")
            .map((char) => char.charCodeAt(0))
        );

        //Byte Order Mark, encoding to represent csv's specials chars
        const BOM = "\ufeff";

        let blob = new Blob([BOM, byteArray], { type: "text/csv" });

        saveAs(
          blob,
          "Data13CR_"
            .concat(formatDate(new Date(), "yyyyMMdd", "en"))
            .concat(".csv")
        );
      },
      (e) => {
        throw e;
      }
    );
  }

  existsSimulation(treeId: number) {
    const url = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/exists/${treeId}`;

    return this.http.get<any>(url, this.httpOptions).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }

  saveSimulation(data: SaveCRRequest) {
    let req = {
      resumeCreateCorrosionRateRequest: data,
    };

    const url = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate`;

    return this.http.post<any>(url, req, this.httpOptions).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }

  loadSimulation(treeId: number) {
    const url = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/byTreeId/${treeId}`;

    return this.http.get<any>(url, this.httpOptions).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }

  deleteSimulation(treeId: number) {
    const url = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/byTreeId/${treeId}`;

    return this.http.delete<any>(url, this.httpOptions).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }

  registerLog(request: any) {
    const httpOptionsCustom = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Loading': 'true'
      })
    }

    const URL: string = `${environment.apiEndpoint}corrosionrate/v1/CorrosionRate/RegisterLogs`;

    return this.http.post<any>(URL, request, httpOptionsCustom).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }
}
