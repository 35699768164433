<div id="divInstallation" class="panel-default">
  <div id="notesPanel" class="widget-head">
    <h3>Notes</h3>
  </div>
  <div class="panel-body">
    <form [formGroup]="fgPerforatedPipe">
      <div formGroupName="fgNotes">
        <div class="row">
          <textarea
            id="notesArea"
            class="form-control"
            formControlName="notes"
            placeholder="Enter your message"
          >
          </textarea>
        </div>
      </div>
    </form>
  </div>
</div>
