import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ComboData
} from "src/app/modules/slotted-perforated-pipe/shared/slotted-modal/models/dcp-modal";
import { DcpShortNames } from "../../../../enums/dcp-short-names.enum";

@Component({
  selector: "app-main-filters",
  templateUrl: "./main-filters.component.html",
  styleUrls: ["./main-filters.component.css"],
})
export class MainFiltersComponent implements OnInit {
  @Output() changeMainFilters = new EventEmitter();

  @Input() fgFilters: FormGroup;
  @Input() filters: { [key in DcpShortNames]: ComboData[] };

  constructor() {}

  ngOnInit(): void {
    this.fgFilters.get("fgMainFilters").valueChanges.subscribe(() => {
      this.changeMainFilters.emit(this.fgFilters.get("fgMainFilters").valid);
    });
  }

  public get dcpShortNames(): typeof DcpShortNames {
    return DcpShortNames;
  }
}
