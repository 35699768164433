import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DataService } from './services/Data.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  isAuthenticated$: Observable<AuthenticatedResult>; 
  lisAuthenticated:boolean;
  sub$: Boolean;


    public DataUserQa: IDataUser[] =
    [
      {
        "groupid": "85a19cfa-f9f3-4b2d-8811-7fd02b231d86",
        "module": "Customer",
        "rol": "USER"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Customer",
        "rol": "ADMIN"
      },
      {
        "groupid": "f290ac58-4f98-462e-9556-0740e1dc335a",
        "module": "ISO15156",
        "rol": "USER"
      },
      {
        "groupid": "685a34f1-bf1a-49b3-9d92-7e52b51de343",
        "module": "ISO15156",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "ISO15156",
        "rol": "ADMIN"
      },
      {
        "groupid": "972950e5-5762-4bf4-9c7b-1a08de151744",
        "module": "13Cr",
        "rol": "USER"
      },
      {
        "groupid": "c9376fd6-afdc-4e0c-a663-cd1768907af5",
        "module": "Casing Wear",
        "rol": "USER"
      },
      {
        "groupid": "e79183b7-aa78-4782-951e-c2bb338d4370",
        "module": "CorrisonClass",
        "rol": "USER"
      },
      {
        "groupid": "d98c8f17-6777-4c8a-8e2f-6cb374cd9281",
        "module": "PipeRating",
        "rol": "USER"
      },
      {
        "groupid": "029f9b47-e441-45b8-9f6f-3b643be7f542",
        "module": "SlottedPipe",
        "rol": "USER"
      },
      {
        "groupid": "4a7db2e1-144c-4ef5-b0a7-44d66adc0f1e",
        "module": "13Cr",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "8f0411f8-a986-4a32-b91a-518225fc842f",
        "module": "Casing Wear",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "f38c5907-130c-4f07-a9b9-f89e23e4b9e3",
        "module": "CorrisonClass",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "d5d9aaac-5f7f-4e13-a923-08f096ad3262",
        "module": "PipeRating",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "a82332f8-5811-46b0-b8dd-a07643118e93",
        "module": "SlottedPipe",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "80ec66e2-2c30-47e8-948d-8462752a37bb",
        "module": "Statitics",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "13Cr",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Casing Wear",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "CorrisonClass",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "PipeRating",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Settings",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "SlottedPipe",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Statitics",
        "rol": "ADMIN"
      },
    ]
    ;

    public DataUserPr: IDataUser[] =
    [
      {
        "groupid": "b22a02d6-f7e7-462a-934f-dcb94ec5667b",
        "module": "Customer",
        "rol": "USER"
      },
      {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Customer",
        "rol": "ADMIN"
      },
      {
        "groupid": "45ebf0d3-76fa-4c37-ab05-d0b13b9e73a9",
        "module": "ISO15156",
        "rol": "USER"
      },
      {
        "groupid": "1d82db37-4e3e-4481-8e5f-f24e79115609",
        "module": "ISO15156",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "ISO15156",
        "rol": "ADMIN"
      },
       {
        "groupid": "8eb336c3-3df1-4ee1-a6c7-67601138a427",
        "module": "13Cr",
        "rol": "USER"
       },
       {
        "groupid": "a88bb902-a44f-44b8-a28a-702ff5cef49a",
        "module": "Casing Wear",
        "rol": "USER"
       },
       {
        "groupid": "c5e21c82-b6b6-441d-bf1a-dd0600c114e3",
        "module": "CorrisonClass",
        "rol": "USER"
       },
       {
        "groupid": "40ef9927-bcb0-4635-a1f0-2f5a6334bb66",
        "module": "PipeRating",
        "rol": "USER"
       },
       {
        "groupid": "bb3d5168-14c4-494f-856a-e65d609fdecc",
        "module": "SlottedPipe",
        "rol": "USER"
       },
       {
        "groupid": "cf183043-c27d-4969-8c3b-84672efe7933",
        "module": "13Cr",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "f2a2bdbf-97ad-41e5-8ec4-d3d18d49f743",
        "module": "Casing Wear",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "cee15557-5d20-4bd9-ae09-7bb69ce6c0f8",
        "module": "CorrisonClass",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "9a9112ef-40c8-4fcf-9fd0-c889b01e5c4c",
        "module": "PipeRating",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "b048f6bb-9a8e-41b8-a39e-f2c888459824",
        "module": "SlottedPipe",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "2590262e-90bf-4dfa-9091-7452702f7903",
        "module": "Statitics",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "13Cr",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Casing Wear",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "CorrisonClass",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "PipeRating",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Settings",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "SlottedPipe",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Statitics",
        "rol": "ADMIN"
       },
    ]
    ;




  public AccessUser: IDataUser[] = [];



  constructor(public oidcSecurityService: OidcSecurityService,
    private router: Router
    ,public dataService: DataService){
  
  } 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.oidcSecurityService.checkAuth().toPromise().then(({ isAuthenticated, userData, accessToken }) => {
        this.lisAuthenticated = isAuthenticated;

        let groupsData = sessionStorage.getItem('0-'+environment.auth.clientID);
        const obj = JSON.parse(groupsData);
        
        if(obj['userData'] != undefined && obj['userData']['groups'] != undefined)
        {
            if (`${environment.production}` == 'true'){
              this.DataUserPr.forEach((key : any, val: any) => {
    
                obj['userData']['groups'].forEach((keys : any, vals :any) => {
    
                    if(key['groupid'] == keys)
                    {
    
                        let obj:IDataUser = {
                            "groupid": key['groupid'],
                            "module": key['module'],
                            "rol": key['rol']
                        }
                        this.AccessUser.push(obj);

                        if(route.data.role == key['module'].toString())
                          this.dataService.changeRol(key['rol']);
                    }
                    
                })
            });
             }
             else{
            this.DataUserQa.forEach((key : any, val: any) => {
    
                obj['userData']['groups'].forEach((keys : any, vals :any) => {
    
                    if(key['groupid'] == keys)                 
                    {
    
                        let obj:IDataUser = {
                            "groupid": key['groupid'],
                            "module": key['module'],
                            "rol": key['rol']
                        }
                        this.AccessUser.push(obj);
                        if(route.data.role == key['module'].toString())
                          this.dataService.changeRol(key['rol']);
                    }
                    
                })
            });
          }
        }

        if ((!this.lisAuthenticated && this.AccessUser.length < 1)){
          this.router.navigate(['/login'])
          return false;
        }else if (this.AccessUser.length < 1)
        {
          this.noPermits();
          this.router.navigate(['/login'])
          return false;
        }
        return true;
      });
  }
  noPermits(){
    Swal.fire({
      title: "You don't have access to this application",
      html: 'If you need permission please create a ticket through ITUSS Self Service ' + '<a href="https://applications.tenaris.net/sites/ITUserSupport">https://applications.tenaris.net/sites/ITUserSupport</a>',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#aaa',
      timer: 30000,
      showConfirmButton: false
    });
  }
}
export interface IDataUsers  {
  val: IDataUser [];
}
export interface IDataUser {
  groupid: string,
  module: string,
  rol: string
}