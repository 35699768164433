import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function arrayContainsValidator(array: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return array.filter(
      (x) =>
        x.temperatureDefault == control.value.temperatureDefault &&
        x.pressureCO2Default == control.value.pressureCO2Default &&
        x.sodiumChlorideDefault == control.value.sodiumChlorideDefault
    ).length > 1 ? {duplicatedEntry: {value: true}} : null;
  };
}
