<div>
  <div class="widget widget-fullwidth widget-small">
    <div
      id="table1_wrapper"
      class="dataTables_wrapper form-inline dt-bootstrap no-footer"
    >
      <div class="row am-datatable-body">
        <div class="col-sm-12">
          <table
            id="dcp-table"
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover"
          >
            <thead>
              <tr>
                <th class="title tituloColumna">Outside Diameter</th>
                <th class="title tituloColumna">Wall Thickness (Weight)</th>
                <th class="title tituloColumna">Grade</th>
                <th class="title tituloColumna">Connection</th>
                <th class="title tituloColumna">Min. Wall Thickness</th>
                <th class="title tituloColumna">Min. Yield Strength</th>
                <th class="title tituloColumna">Collapse Pressure</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dcpProduct of dcpProducts" class="dt-row" (click)="loadDataInForm(dcpProduct)">
                <td>{{ dcpProduct.outsideDiameter }}</td>
                <td>
                  {{ dcpProduct.wallThickness }} ({{
                    dcpProduct.nominalWeight
                  }})
                </td>
                <td>{{ dcpProduct.grade }}</td>
                <td>{{ dcpProduct.connection }}</td>
                <td>{{ (1 + dcpProduct.minWallThickness) * 100 }}%</td>
                <td>{{ dcpProduct.minYieldStrength }}</td>
                <td>{{ dcpProduct.collapsePressure }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
