import { AbstractControl, ValidationErrors } from "@angular/forms";

export class ccRangeValidation {
  //ToDo preguntar si se puedfe borrar
  //Temperature
  // static limitTemperature(min: number, max: number, unitFormControl: FormControl, unitMeasureService: UnitMeasureService) {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     if (unitFormControl)
  //     {
  //       const value = control.value;
  //       const unit: TemperatureUnits = unitFormControl.value;

  //       const minConverted: number = unitMeasureService.convertUnit(min, UnitMeasures.Temperature, unit, false);
  //       const maxConverted: number = unitMeasureService.convertUnit(max, UnitMeasures.Temperature, unit, false);
  //       const unitSymbol: string = unitMeasureService.getUnitSymbol(UnitMeasures.Temperature, unit);

  //       const error: any = { limitTemperature: true, errorMessage: `The value entered must be between ${minConverted}${unitSymbol} and ${maxConverted}${unitSymbol}` };

  //       return value < minConverted || value > maxConverted ? error : null;
  //     }
  //     else
  //       return null;
  //   }
  // };

  //TotalPressure
  static limitTotalPressure(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 0 || value > 1000) {
      return { limitTotalPressure: true };
    }
    return null;
  }

  //Co2InGas and H2SInGas
  static limitGasRange(control: AbstractControl) : ValidationErrors | null{
    const value = control.value;
    if (value < 0 || value > 100) {
      return { limitGasRange: true };
    }
    return null;
  }

  //pCo2 up to 20 bar
  static limit_pCo2(group: AbstractControl): ValidationErrors | null {
    const value = group.get('oPartialCO2pressure').value;

    if (value > 20) {
      return { limit_pCo2: true };
    }
    return null;
  }


  //Pipe Outer Diameter
  static limitPipeDiameter(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 25 || value > 800) {
      return { limitPipeDiameter: true };
    }
    return null;
  }

  //Pipe Wall Thickness
  static limitPipeThickness(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 3 || value > 50) {
      return { limitPipeThickness: true };
    }
    return null;
  }

  //C
  static limitCarbonRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 0 || value > 0.5) {
      return { limitCarbonRange: true };
    }
    return null;
  }

  //Cr
  static limitChromiumRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 0 || value > 1.2) {
      //In %
      return { limitChromiumRange: true };
    }
    return null;
  }

  //Oil Production
  static limitProductionRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 0 || value > 25000) {
      return { limitProductionRange: true };
    }
    return null;
  }

   //Water Production
   static waterProductionRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 1 || value > 25000) {
      return { limitProductionRange: true };
    }
    return null;
  }

  //Desviation at considered point
  static limitDesviationRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 0 || value > 30) {
      //In degress
      return { limitDesviationRange: true };
    }
    return null;
  }

  //Api Oil Gravity
  static limitApiOilRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < 7 || value > 50) {
      return { limitApiOilRange: true };
    }
    return null;
  }

  //Liquid Velocity U
  static limitVelocityURange(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if ((value || value === 0) && (value < 0.1 || value > 13)) { //In Sec
      return { limitVelocityURange: true };
    }
    return null;
  }
  //Water Chemical Composition 
  // static limitWaterChemicalCompositionRange(control: AbstractControl): ValidationErrors | null {
  //   const value = control.value;
  //   if (value < 0 || value > 10000) {
  //     return { limitWaterChemicalCompositionRange: true };
  //   }
  //   return null;
  // }

  //Dissolved Solids
  // static limitDissolvedSolidRange(control: AbstractControl): ValidationErrors | null {
  //   const value = control.value;
  //   if (value < 0 || value > 500000) {
  //     return { limitDissolvedSolidRange: true };
  //   }
  //   return null;
  // }
}