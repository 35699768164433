import { Component } from "@angular/core";

@Component({
  selector: "warning-message",
  templateUrl: "./warning-message.component.html",
  styleUrls: ["./warning-message.component.scss"],
})
export class WarningMessageComponent {
  constructor() {}
}
