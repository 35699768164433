import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { of, Observable, BehaviorSubject } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { TreeLevels, TreeAction, TreeNode } from "../models/tree/Index";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TreeService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  resp: TreeLevels[] = [];

  private subjetOnlyMySimulations = new BehaviorSubject<boolean>(true);
  observableOnlyMySimulations = this.subjetOnlyMySimulations.asObservable();

  constructor(private http: HttpClient) {}

  changeOnlyMySimulationsValue(newData: boolean) {
    this.subjetOnlyMySimulations.next(newData);
  }

  getTreeByUserId(userId: number, module: string): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/byUserId/${userId}/${module}`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
    // const resp = this.resp.filter(reg => reg.users[0].userId == userId);
    // for (let i = 0; i < resp[0].users.length; i++) {
    //   const tree = resp[0].users[i];
    //   if ( tree.userId = userId)
    //     return of(tree);
    // }
  }

  getNodeTreeById(id: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/byNodeId/${id}`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
    //const ret = this.resp.filter(tree => tree[0].userId == id);
    //return of(ret[0]);
  }

  getNodeTreeByUserIdVisibility(
    userId: number,
    department: string,
    rol: string,
    module: string,
    onlyMySimulations: boolean
  ): Observable<any> {
    if (rol.length == 0) {
      rol = "user";
    }
    let httpParams: HttpParams = new HttpParams()
      .set("userId", userId)
      .set("department", department)
      .set("rol", rol)
      .set("module", module)
      .set("onlyUserSimulations", onlyMySimulations);

    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/byVisibility`;
    const resp = this.http
      .get(url, { params: httpParams, headers: this.httpOptions.headers })
      .pipe(
        tap((resp: any) => resp),
        catchError((err) => {
          throw err.message;
        })
      );
    return resp;
  }

  postNodeTree(treeNode: TreeNode): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/createNode`;
    const body = `{"createTreeRequest":${JSON.stringify(treeNode)}}`;
    const resp = this.http.post(url, body, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
    //return of(this.resp);
  }

  putNodeTree(id: number, treeNode: TreeNode): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/updateNode/${id}`;
    const body = `{"updateTreeRequest":${JSON.stringify(treeNode)}}`;
    const resp = this.http.put(url, body, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
  }

  renameNodeTree(nodeId: number, nodeName: string, module: string): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/renameNode/${nodeId}`;
    let renameNodeRequest= {
      nodeId : nodeId,
      nodeName: nodeName,
      module: module
    }
    const body = `{"renameNodeRequest":${JSON.stringify(renameNodeRequest)}}`;
    const resp = this.http.put(url, body, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
  }

  deleteNodeTree(id: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Tree/deleteNode/${id}`;
    const resp = this.http.delete(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError((err) => {
        throw err.message;
      })
    );
    return resp;
  }

  // Frontent - Only

  // saveTree(treeLevel: TreeLevels): Observable<TreeLevels> {
  //   let newTree = this.resp.filter(reg => reg[0].userId !== treeLevel[0].userId);
  //   newTree.push(treeLevel);
  //   return of(newTree.pop());
  // }

  deleteTree(treeLevel: TreeLevels, userId: number, selected: TreeAction) {
    let deleted: any = null;
    if (selected.level == "user") {
      return;
    }

    for (let cust = 0; cust < treeLevel.users[0].customers.length; cust++) {
      const customer = treeLevel.users[0].customers[cust];
      if (customer.id == selected.id) {
        deleted = treeLevel.users[0].customers.splice(cust, 1);
        break;
      }
      for (let regi = 0; regi < customer.regions.length; regi++) {
        const region = customer.regions[regi];
        if (region.id == selected.id) {
          deleted = treeLevel.users[0].customers[cust].regions.splice(regi, 1);
          break;
        }
        for (let proj = 0; proj < region.projects.length; proj++) {
          const project = region.projects[proj];
          if (project.id == selected.id) {
            deleted = treeLevel.users[0].customers[cust].regions[
              regi
            ].projects.splice(proj, 1);
            break;
          }
          for (let simu = 0; simu < project.simulations.length; simu++) {
            const simulation = project.simulations[simu];
            if (simulation.id == selected.id) {
              deleted = treeLevel.users[0].customers[cust].regions[
                regi
              ].projects[proj].simulations.splice(simu, 1);
              break;
            }
          }
        }
      }
    }

    let resp: any;
    this.deleteNodeTree(selected.id).subscribe((node) => {
      if (node.status.success) {
        resp = node.data;
        return of(treeLevel);
      }
    });
  }
}
