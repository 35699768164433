import { TemperatureUnits } from "../enums/temperature-units.enum";

export const TEMPERATURE_UNITS = {
  CELSIUS: {
    name: "Celsius",
    symbol: "°C",
    enum: TemperatureUnits.Celsius,
    fractionDigits: 4
  },
  KELVIN: {
    name: "Kelvin",
    symbol: "°K",
    enum: TemperatureUnits.Kelvin,
    fractionDigits: 4
  },
  FAHRENHEIT: {
    name: "Fahrenheit",
    symbol: "°F",
    enum: TemperatureUnits.Fahrenheit,
    fractionDigits: 4
  },
};

export const TEMPERATURE_UNITS_ARRAY: TemperatureUnit[] = [
  TEMPERATURE_UNITS.CELSIUS,
  TEMPERATURE_UNITS.KELVIN,
  TEMPERATURE_UNITS.FAHRENHEIT,
];

class TemperatureUnit {
  name: string;
  symbol: string;
  enum: TemperatureUnits;
  fractionDigits: number;
}
