import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { merge, Subscription } from "rxjs";
import { PerforatedPipeService } from "src/app/services/perforated-pipe/perforated-pipe.service";
import { Patterns } from "../../../shared/enums/patterns.enum";
import { DCPerforatedLengthRequest } from "../../models/perforated-pipe";
import { PerforatedService } from "../../services/perforated.service";

@Component({
  selector: "app-perforated-lengths",
  templateUrl: "./perforated-lengths.component.html",
  styleUrls: ["./perforated-lengths.component.css"],
})
export class PerforatedLengthComponent implements OnInit {
  @Input() fgPerforatedPipe: FormGroup;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;

  constructor(
    private perforatedService: PerforatedService,
    private perforatedPipeService: PerforatedPipeService
  ) {}

  ngOnInit(): void {
    this.refreshDataCheckData$ =
      this.perforatedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "LENGTHS") this.dataCheck();
      });

    merge(
      this.fgPerforatedPipe.get("fgType").valueChanges,
      this.fgPerforatedPipe.get("fgLengths").valueChanges
    ).subscribe(() => {
      if (
        this.fgPerforatedPipe.get("fgType").valid &&
        this.fgPerforatedPipe.get("fgLengths").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });

    this.fgPerforatedPipe.get("fgLengths").valueChanges.subscribe(() => {
      let LT = Number(
        String(
          this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT").value
        ).replace(",", ".")
      );
      let LL1 = Number(
        String(
          this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
            .value
        ).replace(",", ".")
      );
      let LL2 = Number(
        String(
          this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
            .value
        ).replace(",", ".")
      );

      if (
        this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT").value !=
          "" &&
        this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
          .value != "" &&
        this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
          .value != "" &&
        LT &&
        LL1 &&
        LL2 &&
        LT <= LL1 + LL2
      ) {
        setTimeout(() => {
          this.fgPerforatedPipe.get("fgLengths").setErrors({ LTSum: true });
        }, 1);
      } else {
        this.fgPerforatedPipe.get("fgLengths").setErrors(null);
      }
    });
  }

  dataCheck() {
    let dcPerforatedLengthRequest: DCPerforatedLengthRequest = {
      iLL1: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
        .value,
      iLL2: this.fgPerforatedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
        .value,
      iPipeLengthLT: this.fgPerforatedPipe.get("fgLengths").get("pipeLengthLT")
        .value,
      rdAligned:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdHelicoidal:
        this.fgPerforatedPipe?.get("fgType").get("pattern").value ===
        Patterns.Helicoidal,
      rdNo:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === false,
      rdYes:
        this.fgPerforatedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === true,
    };

    this.perforatedPipeService
      .dataCheckPerforatedLength(dcPerforatedLengthRequest)
      .subscribe((data) => {
        let dataCheckData = [];

        if (data.results.Output.hasError)
          for (let key in data.results.Output.errors) {
            let errorData = {
              type: "ERROR",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.errors[key].description,
              options: data.results.Output.errors[key].options,
            };

            for (let optionKey in data.results.Output.errors[key].options) {
              errorData.options.push(
                data.results.Output.errors[key].options[optionKey]
              );
            }

            dataCheckData.push(errorData);
          }

        if (data.results.Output.hasWarning)
          for (let key in data.results.Output.warnings) {
            let warningData = {
              type: "WARNING",
              parent: data.results.Output.belongsTo,
              description: data.results.Output.warnings[key].description,
              options: [],
            };

            for (let optionKey in data.results.Output.warnings[key].options) {
              warningData.options.push(
                data.results.Output.warnings[key].options[optionKey]
              );
            }

            dataCheckData.push(warningData);
          }

        this.perforatedService.setDataCheckData(dataCheckData, "LENGTHS");
      });
  }

  ngOnDestroy(): void {
    this.refreshDataCheckData$.unsubscribe();
  }
}
