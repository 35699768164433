import { TreeAction, TreeLevels } from "src/app/models/tree/Index";

export function getTreeParent(trees: TreeLevels, selected: TreeAction): string {
  let ids: string = '';
  for (let user = 0; user < trees.users.length; user++) {
    const userTree = trees.users[user];
    if (userTree.userName == selected.text) {
      ids = `${userTree.id}`;
      break;
    }
    for (let cust = 0; cust < userTree.customers.length; cust++) {
      const customer = userTree.customers[cust];
      if (customer.customerName == selected.text) {
        ids = `${userTree.id}-${customer.id}`;
        break;
      }
      for (let regi = 0; regi < customer.regions.length; regi++) {
        const region = customer.regions[regi];
        if (region.regionName == selected.text) {
          ids = `${userTree.id}-${customer.id}-${region.id}`;
          break;
        }
        for (let proj = 0; proj < region.projects.length; proj++) {
          const project = region.projects[proj];
          if (project.projectName == selected.text) {
            ids = `${userTree.id}-${customer.id}-${region.id}-${project.id}`;
            break;
          }
          for (let simu = 0; simu < project.simulations.length; simu++) {
            const simulation = project.simulations[simu];
            if (simulation.simulationName == selected.text && simulation.id == selected.id) {
              ids = `${customer.id}-${region.id}-${project.id}-${simulation.id}`;
              break;
            }
          }
        };
      };
    };
  };
  return ids;
}
