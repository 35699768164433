import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { WarningEnum } from "../enums/warnings.enum";
import { AbstractControlWarning } from "../helpers/AbstractControlWarning";

@Directive({
  selector: "[warningMessage]",
})
export class WarningMessageDirective implements OnInit, OnDestroy {
  @Input() warningContainerId: string;
  @Input() hiddenWarnings: WarningEnum[];
  @Input() priorityWarnings: { type: WarningEnum; origin: string }[];
  elementRef: ElementRef;
  controlValueChanges: Subscription;

  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    this.hiddenWarnings = this.hiddenWarnings ?? [];
    let abstractControlWarning = this.ngControl
      .control as AbstractControlWarning;

    this.controlValueChanges = abstractControlWarning?.valueChanges.subscribe(
      () => {
        const warnings = Object.entries(
          abstractControlWarning?.warnings || {}
        ).filter(
          (warning) =>
            !this.hiddenWarnings.some(
              (hiddenWarning) => hiddenWarning === warning[1].type
            )
        );

        const warningElement = document.getElementById(this.warningContainerId);

        if (!warnings.length) {
          if (typeof warningElement != "undefined" && warningElement != null)
            warningElement.getElementsByClassName(
              "warning-message"
            )[0].innerHTML = "";
          return;
        }

        let existsPriority = warnings.some((warning) =>
          this.priorityWarnings?.some(
            (priorityWarning) =>
              priorityWarning.type === warning[1].type &&
              priorityWarning.origin === warning[1].origin
          )
        );

        const warningsToHtml =
          this.priorityWarnings?.length > 0 && existsPriority
            ? warnings.filter((warning) =>
                this.priorityWarnings.some(
                  (priorityWarning) =>
                    priorityWarning.type === warning[1].type &&
                    priorityWarning.origin === warning[1].origin
                )
              )
            : warnings;

        let warningsHtml: string = "";
        warningsToHtml.forEach((warning) => {
          warningsHtml += warning[1].warningMessage + "</br>";
        });

        if (typeof warningElement != "undefined" && warningElement != null)
          warningElement.getElementsByClassName(
            "warning-message"
          )[0].innerHTML = warningsHtml;
      }
    );
  }

  ngOnDestroy(): void {
    this.controlValueChanges.unsubscribe();
  }
}
