import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import { APIResponse } from "../http/models/api-response";

@Injectable({
  providedIn: "root",
})
export class AttachmentsService {
  private readonly API_ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient, private httpService: HttpService) {}

  download(attachmentId: number): Observable<void> {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Attachment/download/${attachmentId}`;

    return this.http.get<APIResponse>(URL).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success) {
          let attachment = APIResponse.data.file as Attachment;

          const byteArray = new Uint8Array(
            atob(attachment.file)
              .split("")
              .map((char) => char.charCodeAt(0))
          );

          const file = new Blob([byteArray]);

          return FileSaver.saveAs(file, attachment.fileName);
        } else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  delete(attachmentId: number): Observable<boolean> {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Attachment/delete/${attachmentId}`;

    return this.http.delete<APIResponse>(URL).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success) {
          return APIResponse.status.success;
        } else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }
}

class Attachment {
  fileName: string;
  file: string;
}
