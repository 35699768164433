<section id="sub-filters">
  <form [formGroup]="fgFilters">
    <div formGroupName="fgSubFilters">
      <div class="row" style="margin-bottom: 20px;">
        <div class="col-md-3">
          <label class="filter-title">Min. Wall Thickness</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.MW"
            bindValue="id"
            bindLabel="text"
            formControlName="minWallThickness"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Type</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.TP"
            bindValue="id"
            bindLabel="text"
            formControlName="type"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Pipe Body Drift</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.PD"
            bindValue="id"
            bindLabel="text"
            formControlName="pipeBodyDrift"
          >
          </ng-select>
        </div>
        <div class="col-md-3">
          <label class="filter-title">Special Bevel</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.SB"
            bindValue="id"
            bindLabel="text"
            formControlName="specialBevel"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="filter-title">Connection OD Option</label>
          <ng-select
            class="slotted"
            placeholder="Select"
            clearAllText="Clear"
            [items]="filters?.CO"
            bindValue="id"
            bindLabel="text"
            formControlName="connectionODOption"
          >
          </ng-select>
        </div>
      </div>
    </div>
  </form>
</section>
