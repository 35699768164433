import { Pipe, PipeTransform } from "@angular/core";

/*
 * Returns a character if the value is empty.
 * Takes a 'defaultValue' argument that defaults is '-'.
 * Usage:
 *   value | roundDecimals:decimalPlaces
 * Example:
 *   {{ null | emptyDataTable:'Empty' }}
 *   formats to: 'Empty'
 */
@Pipe({
  name: "emptyDataTable",
})
export class EmptyDataTablePipe implements PipeTransform {
  transform(value: any, defaultValue: string = "-"): string {
    return value || value === 0 ? value : defaultValue;
  }
}
