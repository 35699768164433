import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { merge, of, Subscription } from "rxjs";
import { max, take } from "rxjs/operators";
import { HeaderState } from "src/app/models/header/HeaderState";
import { ActionService } from "src/app/services/Action.service";
import { DataService } from "src/app/services/Data.service";
import { PdfService } from "src/app/services/pdf.service";
import { TreeService } from "src/app/services/Tree.service";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import {
  CONCENTRATION_UNITS,
  CONCENTRATION_UNITS_ARRAY,
  CONCENTRATION_UNITS_CL_13CR_ARRAY,
} from "src/app/shared/consts/concentration-units";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { PRESSURE_UNITS_ARRAY } from "src/app/shared/consts/pressure-units";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { TEMPERATURE_UNITS_ARRAY } from "src/app/shared/consts/temperature-units";
import { VELOCITY_UNITS_ARRAY } from "src/app/shared/consts/velocity-units";
import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";
import { PressureUnits } from "src/app/shared/enums/pressure-units.enum";
import { TemperatureUnits } from "src/app/shared/enums/temperature-units.enum";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { VelocityUnits } from "src/app/shared/enums/velocity-units.enum";
import { AbstractControlWarning } from "src/app/shared/helpers/AbstractControlWarning";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";
import { WarningValidator } from "src/app/shared/validators/warning.validator";
import Swal from "sweetalert2";
import { PrintedModalData } from "../../CasingWear/models/printed";
import { FiltersCRComponent } from "../components/filters-cr/filters-cr.component";
import { FilterCR } from "../models/filterCR";
import {
  ExportCRRequest,
  FilterValueCR,
  PrintCRRequest,
  PrintPdfCRRequest,
  SaveCRRequest,
} from "../models/treeCR";
import { CorrosionRateService } from "../services/corrosion-rate.service";
import { arrayContainsValidator } from "../validators/array-contains.directive";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";

@Component({
  selector: "app-corrosion-rate",
  templateUrl: "corrosion-rate.component.html",
})
export class CorrosionRateComponent implements OnInit {
  simulationName: string = "";

  subActionService: Subscription;
  subDataService: Subscription;

  isFormModified: boolean;
  isPreSimulationFormValid: boolean = false;
  customerId: number | null;
  countryId: number | null;

  //Filters
  temperatureUnitControl: FormControl = new FormControl(
    TemperatureUnits.Celsius
  );
  temperatureControls: AbstractControl[];
  temperatureDefaultControls: AbstractControl[];

  pressureCO2UnitControl: FormControl = new FormControl(PressureUnits.Bar);
  pressureCO2Controls: AbstractControl[];
  pressureCO2DefaultControls: AbstractControl[];

  sodiumChlorideUnitControl: FormControl = new FormControl(
    ConcentrationUnits.MilligramsLiter
  );
  sodiumChlorideControls: AbstractControl[];
  sodiumChlorideDefaultControls: AbstractControl[];

  naClPercentControls: AbstractControl[];
  waterDensityDefaultControls: AbstractControl[];

  conventional13CrUnitControl: FormControl = new FormControl(
    VelocityUnits.MillimetersYear
  );
  conventional13CrControls: AbstractControl[];
  conventional13CrDefaultControls: AbstractControl[];

  super13CrUnitControl: FormControl = new FormControl(
    VelocityUnits.MillimetersYear
  );
  super13CrControls: AbstractControl[];
  super13CrDefaultControls: AbstractControl[];

  modified13CrUnitControl: FormControl = new FormControl(
    VelocityUnits.MillimetersYear
  );
  modified13CrControls: AbstractControl[];
  modified13CrDefaultControls: AbstractControl[];

  fgFiltersCR: FormGroup = this.fb.group({
    id: [null],
    modelVer: [null],
    temperature: new FormControl(),
    temperatureDefault: new FormControl(),
    pressureCO2: new FormControl(),
    pressureCO2Default: new FormControl(),
    sodiumChloride: new FormControl(),
    sodiumChlorideDefault: new FormControl(),
    naClPercent: new FormControl({ value: null, disabled: true }),
    waterDensityDefault: new FormControl(1),
    conventional13Cr: new FormControl(),
    conventional13CrDefault: new FormControl(),
    super13Cr: new FormControl(),
    super13CrDefault: new FormControl(),
    modified13Cr: new FormControl(),
    modified13CrDefault: new FormControl(),
  });
  filtersCR: FilterCR[] = [];
  fgEmptyFilter?: FormGroup;
  deletedSimulations: number[] = [];
  showModalReport = false;
  validReportCR: boolean;
  controlsMinLength: number;

  rangeCR: boolean = false;

  nameCurrentUser: string;
  department: string = "";

  idCustomer: number | null;
  idCountry: number | null;

  @ViewChild(FiltersCRComponent) filters: FiltersCRComponent;

  //Navigation
  readonly NAVIGATIONS = NAVIGATIONS;

  //Units
  readonly TEMPERATURE_UNITS_ARRAY = TEMPERATURE_UNITS_ARRAY;
  readonly PRESSURE_UNITS_ARRAY = PRESSURE_UNITS_ARRAY;
  readonly CONCENTRATION_UNITS_ARRAY = CONCENTRATION_UNITS_ARRAY;
  readonly CONCENTRATION_UNITS_CL_13CR_ARRAY = CONCENTRATION_UNITS_CL_13CR_ARRAY;
  readonly VELOCITY_UNITS_ARRAY = VELOCITY_UNITS_ARRAY;

  readonly TEMPERATURE_LIMIT_13CR: number = 150;
  readonly TEMPERATURE_LIMIT_13CRS: number = 180;
  readonly TEMPERATURE_LIMIT_13CRM: number = 180;
  readonly TEMPERATURE_LIMIT_WARNING: any = {
    origin: "TEMPERATURE",
    message: "Temperature limitation",
  };

  readonly NACL_LIMIT_13CR: number = 10;
  readonly NACL_LIMIT_13CRS: number = 20;
  readonly NACL_LIMIT_13CRM: number = 10;
  readonly NACL_LIMIT_WARNING: any = {
    origin: "NACL",
    message: "Possible risk of localized corrosion",
  };

  readonly NACL_TEMPERATURE_POINT_1 = {naCl: 10, temperature: 100};
  readonly NACL_TEMPERATURE_POINT_2 = {naCl: 5, temperature: 150};
  readonly NACL_LIMITER_FUNCTION_13CR: (temperature: number) => number = (temperature: number) => {
    let maxNaCl: number;

    if (temperature <= this.NACL_TEMPERATURE_POINT_1.temperature)
      maxNaCl = this.NACL_TEMPERATURE_POINT_1.naCl;
    else {
      const m = (this.NACL_TEMPERATURE_POINT_2.naCl - this.NACL_TEMPERATURE_POINT_1.naCl) / (this.NACL_TEMPERATURE_POINT_2.temperature - this.NACL_TEMPERATURE_POINT_1.temperature);
      const b = this.NACL_TEMPERATURE_POINT_1.naCl - m * this.NACL_TEMPERATURE_POINT_1.temperature;
      maxNaCl = m * temperature + b;
    }
    
    return maxNaCl;
  };
  readonly TEMPERATURE_LIMITER_FUNCTION_13CR: (naCl: number) => number = (naCl: number) => {
    let maxTemperature: number;

    if (naCl <= this.NACL_TEMPERATURE_POINT_2.naCl)
      maxTemperature = this.NACL_TEMPERATURE_POINT_2.temperature;
    else {
      const m = (this.NACL_TEMPERATURE_POINT_2.temperature - this.NACL_TEMPERATURE_POINT_1.temperature) / (this.NACL_TEMPERATURE_POINT_2.naCl - this.NACL_TEMPERATURE_POINT_1.naCl);
      const b = this.NACL_TEMPERATURE_POINT_1.temperature - m * this.NACL_TEMPERATURE_POINT_1.naCl;
      maxTemperature = m * naCl + b;
    }

    return maxTemperature;    
  };

  maxTemperatureLimit: number;
  isModified13CrVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private actionService: ActionService,
    private dataService: DataService,
    private pdfService: PdfService,
    private corrosionRateService: CorrosionRateService,
    private treeService: TreeService,
    public unitMeasureService: UnitMeasureService,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.dataService.changeModulo("corrosionrate");
    this.subscribeActionService();
    this.subscribeDataService();
    this.calculateFormLimits();

    this.dataService.currentRolSource.pipe(take(1)).subscribe((rol) => {
      if (rol == "ADMIN" || rol == "SUPER_USER")
        this.isModified13CrVisible = true;
      else this.isModified13CrVisible = false;
    });
    this.dataService.currentNameSurnameSource.subscribe(
      (name) => (this.nameCurrentUser = name)
    );
    this.dataService.currentDepartmentSource.subscribe(
      (dpto) => (this.department = dpto)
    );
    this.dataService.currentValidateReportCRSource.subscribe(
      (validReport) => (this.validReportCR = validReport)
    );

    this.preSimulationService.formValidity$.subscribe(isValid => {
      this.isPreSimulationFormValid = isValid;
    });
  }

  setControls(fgFiltersCR?: FormGroup) {
    this.updateValidationsExistingControls();

    let temperatureControls = this.getControlsByKey("temperature", fgFiltersCR);
    this.temperatureControls = temperatureControls.converted;
    this.temperatureDefaultControls = temperatureControls.default;

    let pressureCO2Controls = this.getControlsByKey("pressureCO2", fgFiltersCR);
    this.pressureCO2Controls = pressureCO2Controls.converted;
    this.pressureCO2DefaultControls = pressureCO2Controls.default;

    let sodiumChlorideControls = this.getControlsByKey(
      "sodiumChloride",
      fgFiltersCR
    );
    this.sodiumChlorideControls = sodiumChlorideControls.converted;
    this.sodiumChlorideDefaultControls = sodiumChlorideControls.default;

    let naClPercentControls = this.getControlsByKey(
      "naClPercent",
      fgFiltersCR
    );
    this.naClPercentControls = naClPercentControls.converted;

    let waterDensityControls = this.getControlsByKey(
      "waterDensity",
      fgFiltersCR
    );
    this.waterDensityDefaultControls = waterDensityControls.default;

    let conventional13CrControls = this.getControlsByKey(
      "conventional13Cr",
      fgFiltersCR
    );
    this.conventional13CrControls = conventional13CrControls.converted;
    this.conventional13CrDefaultControls = conventional13CrControls.default;

    let super13CrControls = this.getControlsByKey("super13Cr", fgFiltersCR);
    this.super13CrControls = super13CrControls.converted;
    this.super13CrDefaultControls = super13CrControls.default;

    let modified13CrControls = this.getControlsByKey(
      "modified13Cr",
      fgFiltersCR
    );
    this.modified13CrControls = modified13CrControls.converted;
    this.modified13CrDefaultControls = modified13CrControls.default;

    this.isFormModified = true;
    this.simulationName = "";
    this.dataService.changeValidateReportCR(false);
  }

  private updateValidationsExistingControls() {
    let fgFilters: FormGroup;
    for (const fgFilterCRKey in this.fgFiltersCR.controls) {
      fgFilters = this.fgFiltersCR.get(fgFilterCRKey) as FormGroup;
      if (fgFilterCRKey.startsWith("fgFilterCR")) {
        for (const fgFilterKey in fgFilters.controls) {
          if (
            fgFilterKey.includes("13Cr") &&
            !fgFilterKey.includes("Default")
          ) {
            let abstractControlWarning = this.fgFiltersCR
              .get(fgFilterCRKey)
              .get(fgFilterKey) as AbstractControlWarning;

            abstractControlWarning.warnings = [];
            abstractControlWarning.clearValidators();

            if (this.fgFiltersCR.get(fgFilterCRKey).valid)
              switch (fgFilterKey) {
                case "conventional13Cr":
                  this.addValidator(
                    fgFilterCRKey,
                    fgFilterKey,
                    this.TEMPERATURE_LIMIT_13CR,
                    this.NACL_LIMIT_13CR,
                    true
                  );
                  break;
                case "super13Cr":
                  this.addValidator(
                    fgFilterCRKey,
                    fgFilterKey,
                    this.TEMPERATURE_LIMIT_13CRS,
                    this.NACL_LIMIT_13CRS
                  );
                  break;
                case "modified13Cr":
                  this.addValidator(
                    fgFilterCRKey,
                    fgFilterKey,
                    this.TEMPERATURE_LIMIT_13CRM,
                    this.NACL_LIMIT_13CRM
                  );
                  break;
              }

            abstractControlWarning.updateValueAndValidity();
          } else
            this.fgFiltersCR
              .get(fgFilterCRKey)
              .get(fgFilterKey)
              .updateValueAndValidity({ emitEvent: false });
        }
      } else
        this.fgFiltersCR
          .get(fgFilterCRKey)
          .updateValueAndValidity({ emitEvent: false });
    }
  }

  private roundToTwoDecimals(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  private addValidator(
    fgFilterCRKey: string,
    fgFilterKey: string,
    temperatureLimit13CR: number,
    sodiumChlorideLimit13CR: number,
    limitedByFunction: boolean = false
  ) {
    if(limitedByFunction) {
      let naClLimit = this.NACL_LIMIT_13CR;
      let naClLimitMessage = this.NACL_LIMIT_WARNING.message;

      if (this.fgFiltersCR.get(fgFilterCRKey).get("naClPercent").value < this.NACL_LIMIT_13CR) {
        naClLimit = this.NACL_LIMITER_FUNCTION_13CR(this.fgFiltersCR.get(fgFilterCRKey).get("temperatureDefault").value);
        let temperatureLimitConverted =
          this.unitMeasureService.convertUnit(
            this.fgFiltersCR.get(fgFilterCRKey).get("temperatureDefault").value,
            UnitMeasures.Temperature,
            this.temperatureUnitControl.value,
            false,
            2
          );
          naClLimitMessage = this.NACL_LIMIT_WARNING.message;
      }

      this.fgFiltersCR
      .get(fgFilterCRKey)
      .get(fgFilterKey)
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          this.TEMPERATURE_LIMIT_13CR,
          this.fgFiltersCR.get(fgFilterCRKey).get("temperatureDefault").value,
          this.TEMPERATURE_LIMIT_WARNING.origin,
          `${
            this.TEMPERATURE_LIMIT_WARNING.message
          } (${this.unitMeasureService.convertUnit(
            this.TEMPERATURE_LIMIT_13CR,
            UnitMeasures.Temperature,
            this.temperatureUnitControl.value,
            false,
            2
          )} ${this.unitMeasureService.getUnitSymbol(
            UnitMeasures.Temperature,
            this.temperatureUnitControl.value
          )})`
        ),
        WarningValidator.maxValueLimitedByFunctionWarning(
          this.NACL_LIMITER_FUNCTION_13CR,
          this.NACL_LIMIT_13CR,
          this.fgFiltersCR.get(fgFilterCRKey).get("naClPercent").value,
          this.fgFiltersCR.get(fgFilterCRKey).get("temperatureDefault").value,
          this.NACL_LIMIT_WARNING.origin,
          naClLimitMessage
        ),
      ]);
    }
    else
      this.fgFiltersCR
        .get(fgFilterCRKey)
        .get(fgFilterKey)
        .addValidators([
          WarningValidator.maxValueLimitedByValueWarning(
            temperatureLimit13CR,
            this.fgFiltersCR.get(fgFilterCRKey).get("temperatureDefault").value,
            this.TEMPERATURE_LIMIT_WARNING.origin,
            `${
              this.TEMPERATURE_LIMIT_WARNING.message
            } (${this.unitMeasureService.convertUnit(
              temperatureLimit13CR,
              UnitMeasures.Temperature,
              this.temperatureUnitControl.value,
              false,
              2
            )} ${this.unitMeasureService.getUnitSymbol(
              UnitMeasures.Temperature,
              this.temperatureUnitControl.value
            )})`
          ),
          WarningValidator.maxValueLimitedByValueWarning(
            sodiumChlorideLimit13CR,
            this.fgFiltersCR.get(fgFilterCRKey).get("naClPercent").value,
            this.NACL_LIMIT_WARNING.origin,
            this.NACL_LIMIT_WARNING.message
          ),
        ]);
  }

  getControlsByKey(key: string, fgFiltersCR?: FormGroup) {
    let defaultControls: AbstractControl[] = [];
    let convertedControls: AbstractControl[] = [];

    if (fgFiltersCR) this.fgEmptyFilter = fgFiltersCR;

    if (this.fgEmptyFilter) {
      this.getControls(
        this.fgEmptyFilter,
        key,
        defaultControls,
        convertedControls
      );
    }

    for (const formKey in this.fgFiltersCR.controls) {
      if (formKey.includes("fgFilterCR")) {
        const formGroup = this.fgFiltersCR.get(formKey) as FormGroup;
        this.getControls(formGroup, key, defaultControls, convertedControls);
      }
    }

    return { converted: convertedControls, default: defaultControls };
  }

  private getControls(
    formGroup: FormGroup,
    key: string,
    defaultControls: AbstractControl[],
    convertedControls: AbstractControl[]
  ) {
    let controlKey: string;
    let control: AbstractControl;
    for (controlKey in formGroup.controls) {
      if (controlKey.includes(key)) {
        control = formGroup.get(controlKey);
        if (controlKey.includes("Default")) defaultControls.push(control);
        else convertedControls.push(control);
      }
    }
  }

  private subscribeActionService() {
    this.subActionService = this.actionService.currentMessage.subscribe(
      (value) => {
        switch (value) {
          case HeaderState.new:
            this.confirmReset();
            break;
          case HeaderState.report:
            this.printReport();
            break;
          case HeaderState.validate:
            this.confirmReport();
            break;
          case HeaderState.save:
            //Save;
            break;
          case HeaderState.delete:
            //Delete;
            break;
          case HeaderState.load:
            //Load;
            break;
          case HeaderState.download:
            this.downloadCsv();
            break;
          default:
            break;
        }
      }
    );
  }

  private subscribeDataService() {
    this.subDataService = this.dataService.currentData.subscribe(
      (selectedSimulation) => {
        if (selectedSimulation.action != "") {
          switch (selectedSimulation.action) {
            case HeaderState.load:
              this.confirmLoad(selectedSimulation);
              break;
            case HeaderState.save:
              this.confirmSave(selectedSimulation);
              break;
            case HeaderState.delete:
              this.confirmDelete(selectedSimulation);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  confirmLoad(selectedSimulation: any) {
    if (this.isFormNotEmpty()) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) this.loadSimulation(selectedSimulation);
      });
    } else {
      this.loadSimulation(selectedSimulation);
    }
  }

  private isFormNotEmpty() {
    return (
      Object.entries(this.fgFiltersCR.controls).length >
        this.controlsMinLength ||
      Object.entries(this.fgFiltersCR.controls).some((x) =>
        x[0].includes("fgFilter")
      )
    );
  }

  private loadSimulation(selectedSimulation: any) {
    this.resetSimulation();

    this.corrosionRateService.loadSimulation(selectedSimulation.id).subscribe(
      (resp) => {
        this.customerId = resp.customerId;
        this.countryId = resp.countryId;
        this.preSimulationService.setLoadedCustomerId(this.customerId);
        this.preSimulationService.setLoadedCountryId(this.countryId);
        this.preSimulationService.setSavedCustomerId(this.customerId);
        this.preSimulationService.setSavedCountryId(this.countryId);

        jQuery("#sidebar").removeClass("active");
        jQuery(".overlay").removeClass("active");

        if (resp.id && resp.modelVer) {
          this.fgFiltersCR.get("id").setValue(resp.id, { emitEvent: false });
          this.fgFiltersCR
            .get("modelVer")
            .setValue(resp.modelVer, { emitEvent: false });
        }

        let conventional13CrWarning: boolean;
        let super13CrWarning: boolean;
        let modified13CrWarning: boolean;

        resp.data.forEach((detail) => {
          let key = this.filtersCR.length
            ? this.filtersCR[this.filtersCR.length - 1].key + 1
            : this.filtersCR.length + 1;

          let filterCR: FilterCR = {
            key: key,
            temperature: detail.temperature,
            pressureCO2: detail.pco2,
            sodiumChloride: detail.naCl,
            naClPercent: detail.naClPercent,
            waterDensityDefault: detail.water_density,
            conventional13Cr: detail.conventional13cr,
            super13Cr: detail.super13cr,
            modified13Cr: detail.modified13cr,
          };

          let naClPercentValue = this.unitMeasureService.convertUnit(
            filterCR.sodiumChloride,
            UnitMeasures.Concentration,
            ConcentrationUnits.NaClPercent,
            false,
            this.unitMeasureService.getUnitFractionDigits(
              UnitMeasures.Concentration,
              ConcentrationUnits.NaClPercent
            ),
            {
              waterDensity: filterCR.waterDensityDefault,
              defaultUnit:
                this.unitMeasureService.concentrationUnits
                  .MilligramsLiter,
            }
          );

          conventional13CrWarning =
            filterCR.temperature > this.TEMPERATURE_LIMIT_13CR;
          super13CrWarning =
            filterCR.temperature > this.TEMPERATURE_LIMIT_13CRS;
          modified13CrWarning =
            filterCR.temperature > this.TEMPERATURE_LIMIT_13CRM;

          let naClLimit = this.NACL_LIMIT_13CR;
          let naClLimitMessage = this.NACL_LIMIT_WARNING.message;

          if (naClPercentValue < this.NACL_LIMIT_13CR) {
            naClLimit = this.NACL_LIMITER_FUNCTION_13CR(filterCR.temperature);
            let temperatureLimitConverted =
              this.unitMeasureService.convertUnit(
                filterCR.temperature,
                UnitMeasures.Temperature,
                this.temperatureUnitControl.value,
                false,
                2
              );
              naClLimitMessage = this.NACL_LIMIT_WARNING.message;
          }

          this.fgFiltersCR.addControl(
            "fgFilterCR" + key,
            this.fb.group(
              {
                id: [detail.id],
                temperature: [
                  this.unitMeasureService.convertUnit(
                    filterCR.temperature,
                    UnitMeasures.Temperature,
                    this.temperatureUnitControl.value,
                    false,
                    2
                  ),
                  [
                    Validators.required,
                    Validators.pattern(
                      REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS
                    ),
                    InterchaneableUnitValidator.delimited(
                      0,
                      this.maxTemperatureLimit,
                      this.temperatureUnitControl,
                      UnitMeasures.Temperature,
                      this.unitMeasureService,
                      null,
                      null,
                      2
                    ),
                  ],
                ],
                temperatureDefault: [filterCR.temperature],
                pressureCO2: [
                  this.unitMeasureService.convertUnit(
                    filterCR.pressureCO2,
                    UnitMeasures.Pressure,
                    this.pressureCO2UnitControl.value,
                    false,
                    2
                  ),
                  [
                    Validators.required,
                    Validators.pattern(
                      REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS
                    ),
                    InterchaneableUnitValidator.delimited(
                      0,
                      50,
                      this.pressureCO2UnitControl,
                      UnitMeasures.Pressure,
                      this.unitMeasureService,
                      null,
                      null,
                      2
                    ),
                  ],
                ],
                pressureCO2Default: [filterCR.pressureCO2],
                sodiumChloride: [
                  this.unitMeasureService.convertUnit(
                    filterCR.sodiumChloride,
                    UnitMeasures.Concentration,
                    this.sodiumChlorideUnitControl.value,
                    false,
                    this.unitMeasureService.getUnitFractionDigits(
                      UnitMeasures.Concentration,
                      this.sodiumChlorideUnitControl.value
                    ),
                    {
                      waterDensity: filterCR.waterDensityDefault,
                      defaultUnit:
                        this.unitMeasureService.concentrationUnits
                          .MilligramsLiter,
                    }
                  ),
                  [
                    Validators.required,
                    Validators.pattern(
                      REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS
                    ),
                    InterchaneableUnitValidator.minWithoutConvert(
                      0,
                      this.sodiumChlorideUnitControl,
                      UnitMeasures.Concentration,
                      this.unitMeasureService
                    ),
                  ],
                ],
                sodiumChlorideDefault: [filterCR.sodiumChloride],
                naClPercent: [{value: naClPercentValue, disabled: true}],
                waterDensityDefault: [filterCR.waterDensityDefault],
                conventional13Cr: [
                  conventional13CrWarning
                    ? null
                    : this.unitMeasureService.convertUnit(
                        filterCR.conventional13Cr,
                        UnitMeasures.Velocity,
                        this.conventional13CrUnitControl.value,
                        false,
                        2
                      ),
                  [
                    WarningValidator.maxValueLimitedByValueWarning(
                      this.TEMPERATURE_LIMIT_13CR,
                      filterCR.temperature,
                      this.TEMPERATURE_LIMIT_WARNING.origin,
                      `${
                        this.TEMPERATURE_LIMIT_WARNING.message
                      } (${this.unitMeasureService.convertUnit(
                        this.TEMPERATURE_LIMIT_13CR,
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value,
                        false,
                        2
                      )} ${this.unitMeasureService.getUnitSymbol(
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value
                      )})`
                    ),
                    WarningValidator.maxValueLimitedByFunctionWarning(
                      this.NACL_LIMITER_FUNCTION_13CR,
                      this.NACL_LIMIT_13CR,
                      naClPercentValue,
                      filterCR.temperature,
                      this.NACL_LIMIT_WARNING.origin,
                      naClLimitMessage
                    ),
                  ],
                ],
                conventional13CrDefault: [
                  conventional13CrWarning ? null : filterCR.conventional13Cr,
                ],
                super13Cr: [
                  super13CrWarning
                    ? null
                    : this.unitMeasureService.convertUnit(
                        filterCR.super13Cr,
                        UnitMeasures.Velocity,
                        this.super13CrUnitControl.value,
                        false,
                        2
                      ),
                  [
                    WarningValidator.maxValueLimitedByValueWarning(
                      this.TEMPERATURE_LIMIT_13CRS,
                      filterCR.temperature,
                      this.TEMPERATURE_LIMIT_WARNING.origin,
                      `${
                        this.TEMPERATURE_LIMIT_WARNING.message
                      } (${this.unitMeasureService.convertUnit(
                        this.TEMPERATURE_LIMIT_13CRS,
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value,
                        false,
                        2
                      )} ${this.unitMeasureService.getUnitSymbol(
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value
                      )})`
                    ),

                    WarningValidator.maxValueLimitedByValueWarning(
                      this.NACL_LIMIT_13CRS,
                      naClPercentValue,
                      this.NACL_LIMIT_WARNING.origin,
                      this.NACL_LIMIT_WARNING.message
                    ),
                  ],
                ],
                super13CrDefault: [
                  super13CrWarning ? null : filterCR.super13Cr,
                ],
                modified13Cr: [
                  modified13CrWarning
                    ? null
                    : this.unitMeasureService.convertUnit(
                        filterCR.modified13Cr,
                        UnitMeasures.Velocity,
                        this.modified13CrUnitControl.value,
                        false,
                        2
                      ),
                  [
                    WarningValidator.maxValueLimitedByValueWarning(
                      this.TEMPERATURE_LIMIT_13CRM,
                      naClPercentValue,
                      this.TEMPERATURE_LIMIT_WARNING.origin,
                      `${
                        this.TEMPERATURE_LIMIT_WARNING.message
                      } (${this.unitMeasureService.convertUnit(
                        this.TEMPERATURE_LIMIT_13CRM,
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value,
                        false,
                        2
                      )} ${this.unitMeasureService.getUnitSymbol(
                        UnitMeasures.Temperature,
                        this.temperatureUnitControl.value
                      )})`
                    ),
                    WarningValidator.maxValueLimitedByValueWarning(
                      this.NACL_LIMIT_13CRM,
                      filterCR.sodiumChloride,
                      this.NACL_LIMIT_WARNING.origin,
                      this.NACL_LIMIT_WARNING.message
                    ),
                  ],
                ],
                modified13CrDefault: [
                  modified13CrWarning ? null : filterCR.modified13Cr,
                ],
              },
              { validators: arrayContainsValidator(this.filtersCR) }
            ),
            { emitEvent: false }
          );

          this.fgFiltersCR.markAllAsTouched();

          this.filtersCR.push(filterCR);
        });

        this.setControls(this.fgFiltersCR);

        this.simulationName = selectedSimulation.path;

        this.isFormModified = false;

        this.dataService.setSimulationIdName(selectedSimulation.path);

        if(!this.customerId || !this.countryId)
          Swal.fire({
            title: "Action required",
            text: "Please select customer and country to view the simulation",
            icon: "warning",
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
          });
        else
          Swal.fire({
            title: "Loaded!",
            text: "The simulation has been loaded.",
            icon: "success",
            confirmButtonColor: "#009",
            timer: 3000,
            timerProgressBar: true,
          });

        this.dataService.changeValidateReportCR(true);
      },
      (err: Error) => {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        this.simulationName = "";
        this.isFormModified = false;
        this.dataService.changeValidateReportCR(false);
      },
      () => {
        this.setLoadedSimulationDelayed(selectedSimulation.path)
      }
    );
  }

  setLoadedSimulationDelayed(simulationName) {
    setTimeout(() => {
      this.simulationName = simulationName;

      this.isFormModified = false;

      this.dataService.changeValidateReportCR(true);
    }, 500)
  }

  confirmSave(selectedSimulation: any) {
    if (selectedSimulation.visibility == "Select") {
      this.fireWarningModal("Action required", "Please select Visibility");
    } else {
      this.corrosionRateService
        .existsSimulation(selectedSimulation.id)
        .subscribe(
          (resp) => {
            if (this.fgFiltersCR.valid && this.isFormNotEmpty()) {
              Swal.fire({
                title: resp.exists
                  ? "Do you want to overwrite the simulation"
                  : "Do you want to save the simulation",
                text: selectedSimulation.path + "?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#009",
                cancelButtonColor: "#aaa",
                confirmButtonText: "Proceed",
              }).then((result) => {
                if (result.isConfirmed) {
                  let saveCRRequest: SaveCRRequest =
                    this.initSaveRequest(selectedSimulation);
                  this.saveSimulation(saveCRRequest, selectedSimulation.path);
                  this.dataService.changeValidateReportCR(true);
                }
              });
            } else {
              this.fireWarningModal(
                "Action required",
                "Please complete at least one corrosion rate"
              );

              jQuery("#sidebar").removeClass("active");
              jQuery(".overlay").removeClass("active");
            }
          },
          (err: Error) => {
            Swal.fire({
              title: err.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        );
    }
  }

  private calculateFormLimits() {
    this.controlsMinLength = Object.entries(this.fgFiltersCR.controls).length;
    of(
      this.TEMPERATURE_LIMIT_13CR,
      this.TEMPERATURE_LIMIT_13CRS,
      this.TEMPERATURE_LIMIT_13CRM
    )
      .pipe(max())
      .subscribe((max) => (this.maxTemperatureLimit = max));
  }

  private saveSimulation(saveCRRequest: SaveCRRequest, simulationPath: string) {
    this.corrosionRateService.saveSimulation(saveCRRequest).subscribe(
      (resp) => {
        Swal.fire({
          title: "Saved!",
          text: "The simulation has been saved.",
          icon: "success",
          confirmButtonColor: "#009",
          timer: 3000,
          timerProgressBar: true,
        });
        jQuery("#sidebar").removeClass("active");
        jQuery(".overlay").removeClass("active");

        this.fgFiltersCR.get("id").setValue(resp.id, { emitEvent: false });
        this.fgFiltersCR
          .get("modelVer")
          .setValue(resp.modelVer, { emitEvent: false });

        resp.simulationDetails.forEach((detail) => {
          this.fgFiltersCR
            .get(detail.key)
            .get("id")
            .setValue(detail.id, { emitEvent: false });
        });

        this.simulationName = simulationPath;
        this.dataService.setSimulationIdName(simulationPath);
        this.isFormModified = false;
        this.customerId = saveCRRequest.customerId;
        this.countryId = saveCRRequest.countryId;
        this.preSimulationService.setSavedCustomerId(this.customerId);
        this.preSimulationService.setSavedCountryId(this.countryId);   
      },
      (err: Error) => {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
      }
    );
  }

  confirmReset() {
    if (this.isFormNotEmpty()) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSimulation();
          this.preSimulationService.resetValues();
        }
      });
    } else {
      this.resetSimulation();
      this.preSimulationService.resetValues();
      Swal.fire({
        title: "New simulation created",
        icon: "info",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
  }

  private resetSimulation() {
    this.simulationName = "";

    for (const key in this.fgFiltersCR.controls)
      if (key != "id" && key != "modelVer") this.fgFiltersCR.removeControl(key);

    this.filters.newSimulation();
    this.fgFiltersCR.reset();
    this.filtersCR = [];

    this.dataService.changeValidateReportCR(false);
  }

  confirmReport() {
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
      
      return;
    }
    
    if (this.validReportCR) {
      if (
        this.fgFiltersCR.valid &&
        this.isFormNotEmpty() &&
        this.isFormModified || !this.validateIfPreSimulationSaved(this.customerId, this.countryId)
      ) {
        this.dataService.changeValidateReportCR(false);
        this.fireWarningModal(
          "Action required",
          "Please save simulation to print report"
        );
      } else if (
        !this.fgFiltersCR.valid ||
        !this.isFormNotEmpty() ||
        this.isFormModified
      ) {
        this.dataService.changeValidateReportCR(false);
        this.fireWarningModal(
          "Action required",
          "Please verify all values to print report"
        );
      }
    } else if (
      this.fgFiltersCR.valid &&
      this.isFormNotEmpty() &&
      (this.isFormModified || !this.validateIfPreSimulationSaved(this.customerId, this.countryId))
    )
      this.fireWarningModal(
        "Action required",
        "Please save simulation to print report"
      );
    else
      this.fireWarningModal(
        "Action required",
        "Please verify all values to print report"
      );
  }

  validateIfPreSimulationSaved(customerId: number | null, countryId: number | null): boolean {
    let isSaved = true;
    if(this.preSimulationService.getCurrentCountryId() == null || this.preSimulationService.getCurrentCustomerId() == null || 
      this.preSimulationService.getCurrentCountryId() != countryId || this.preSimulationService.getCurrentCustomerId() != customerId)
      isSaved = false;

      return isSaved;
  }


  private printReport() {
    let printPdfCRRequest: PrintPdfCRRequest = this.printFileCRRequest(
      this.actionService.data
    );
    this.pdfService.getPdfCR(printPdfCRRequest);
  }

  private fireWarningModal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
    });
  }

  private initFileCRRequest() {
    let filterValues: FilterValueCR[] = [];

    Object.keys(this.fgFiltersCR.controls).forEach((key) => {
      if (key.startsWith("fgFilterCR")) {
        filterValues.push({
          temperature: this.fgFiltersCR.get(key).get("temperature").value,
          pco2: this.fgFiltersCR.get(key).get("pressureCO2").value,
          naCl: this.fgFiltersCR.get(key).get("sodiumChloride").value,
          naClPercent: this.fgFiltersCR.get(key).get("naClPercent").value,
          water_density: this.fgFiltersCR.get(key).get("waterDensityDefault").value,
          conventional13cr: this.fgFiltersCR.get(key).get("conventional13Cr")
            .value,
          super13cr: this.fgFiltersCR.get(key).get("super13Cr").value,
          modified13cr: this.fgFiltersCR.get(key).get("modified13Cr").value,
        });
      }
    });

    let printCRRequest: PrintCRRequest = {
      createdBy: this.nameCurrentUser ? this.nameCurrentUser : "-",
      modelVer: this.fgFiltersCR.get("modelVer").value,
      temperatureUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Temperature,
        this.temperatureUnitControl.value
      ),
      pco2Unit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Pressure,
        this.pressureCO2UnitControl.value
      ),
      naClUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Concentration,
        this.sodiumChlorideUnitControl.value
      ),
      conventional13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.conventional13CrUnitControl.value
      ),
      super13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.super13CrUnitControl.value
      ),
      modified13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.modified13CrUnitControl.value
      ),
      items: filterValues,
      isModified13CrVisible: this.isModified13CrVisible,
    };

    return printCRRequest;
  }

  private printFileCRRequest(printedModalData: PrintedModalData) {
    let filterValues: FilterValueCR[] = [];

    Object.keys(this.fgFiltersCR.controls).forEach((key) => {
      if (key.startsWith("fgFilterCR")) {
        filterValues.push({
          temperature: this.fgFiltersCR.get(key).get("temperature").value,
          pco2: this.fgFiltersCR.get(key).get("pressureCO2").value,
          naCl: this.fgFiltersCR.get(key).get("sodiumChloride").value,
          naClPercent: this.fgFiltersCR.get(key).get("naClPercent").value,
          water_density: this.fgFiltersCR.get(key).get("waterDensityDefault").value,
          conventional13cr: this.fgFiltersCR.get(key).get("conventional13Cr")
            .value,
          super13cr: this.fgFiltersCR.get(key).get("super13Cr").value,
          modified13cr: this.fgFiltersCR.get(key).get("modified13Cr").value,
        });
      }
    });

    let printPdfCRRequest: PrintPdfCRRequest = {
      isModified13CrVisible: this.isModified13CrVisible,
      printOnlyFirstPage: printedModalData.printOnlyFirstPage,
      requestedBy: printedModalData.requestedBy,
      authorizedBy: printedModalData.authorizedBy,
      reviewedBy: printedModalData.reviewedBy,
      SDCaseNumber: printedModalData.SDCaseNumber,
      createdBy: this.nameCurrentUser ? this.nameCurrentUser : "-",
      modelVer: this.fgFiltersCR.get("modelVer").value,
      temperatureUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Temperature,
        this.temperatureUnitControl.value
      ),
      pco2Unit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Pressure,
        this.pressureCO2UnitControl.value
      ),
      naClUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Concentration,
        this.sodiumChlorideUnitControl.value
      ),
      conventional13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.conventional13CrUnitControl.value
      ),
      super13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.super13CrUnitControl.value
      ),
      modified13crUnit: this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Velocity,
        this.modified13CrUnitControl.value
      ),
      items: filterValues,
    };

    return printPdfCRRequest;
  }

  private initSaveRequest(selectedSimulation: any) {
    let filterValues: FilterValueCR[] = [];

    Object.keys(this.fgFiltersCR.controls).forEach((key) => {
      if (key.startsWith("fgFilterCR")) {
        filterValues.push({
          id: this.fgFiltersCR.get(key).get("id")?.value,
          key: key,
          temperature: this.fgFiltersCR.get(key).get("temperatureDefault")
            .value,
          pco2: this.fgFiltersCR.get(key).get("pressureCO2Default").value,
          naCl: this.fgFiltersCR.get(key).get("sodiumChlorideDefault").value,
          naClPercent: this.fgFiltersCR.get(key).get("naClPercent").value,
          water_density: this.fgFiltersCR.get(key).get("waterDensityDefault")
            .value,
          conventional13cr: this.fgFiltersCR
            .get(key)
            .get("conventional13CrDefault").value,
          super13cr: this.fgFiltersCR.get(key).get("super13CrDefault").value,
          modified13cr: this.fgFiltersCR.get(key).get("modified13CrDefault")
            .value,
        });
      }
    });

    let saveCRRequest: SaveCRRequest = {
      id: this.fgFiltersCR.get("id")?.value,
      userId: selectedSimulation.userId,
      treeId: selectedSimulation.id,
      name: selectedSimulation.text,
      department: this.department,
      visibility: selectedSimulation.visibility,
      notes: selectedSimulation.notes,
      data: filterValues,
      deletedSimulations: this.deletedSimulations,
      customerId: this.preSimulationService.getCurrentCustomerId(),
      countryId: this.preSimulationService.getCurrentCountryId(),
    };

    return saveCRRequest;
  }

  downloadCsv() {
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
      
      return;
    }

    if (this.validReportCR) {
      if (
        this.fgFiltersCR.valid &&
        this.isFormNotEmpty() &&
        this.isFormModified
      ) {
        this.dataService.changeValidateReportCR(false);
        this.fireWarningModal(
          "Action required",
          "Please save simulation to print report"
        );
      } else if (
        !this.fgFiltersCR.valid ||
        !this.isFormNotEmpty() ||
        this.isFormModified
      ) {
        this.dataService.changeValidateReportCR(false);
        this.fireWarningModal(
          "Action required",
          "Please verify all values to print report"
        );
      } else {
        let exportCRRequest: ExportCRRequest = this.initFileCRRequest();
        this.corrosionRateService.exportCsv(exportCRRequest);
      }
    } else if (
      this.fgFiltersCR.valid &&
      this.isFormNotEmpty() &&
      this.isFormModified
    )
      this.fireWarningModal(
        "Action required",
        "Please save simulation to print report"
      );
    else
      this.fireWarningModal(
        "Action required",
        "Please verify all values to print report"
      );
  }

  confirmDelete(selectedSimulation: any) {
    Swal.fire({
      title: "Do you want to delete the item",
      text: selectedSimulation.path + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSimulation(selectedSimulation);
      }
    });
  }

  private deleteSimulation(selectedSimulation: any) {
    this.treeService.deleteTree(
      this.dataService.extraData,
      selectedSimulation.userId,
      selectedSimulation
    );
    this.actionService.changeAction(HeaderState.cancel);
    jQuery('#sidebar').removeClass('active');
    jQuery('.overlay').removeClass('active');
    if (selectedSimulation.level == "simulation") {
      this.corrosionRateService
        .deleteSimulation(selectedSimulation.id)
        .subscribe(
          (resp) => {
            Swal.fire({
              title: "Deleted!",
              text: "The item has been deleted.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });

            if (resp.id == this.fgFiltersCR.get("id").value)
              this.resetSimulation();
          },
          (err: Error) => {
            Swal.fire({
              title: err.message,
              icon: "error",
              confirmButtonColor: "#009",
              cancelButtonColor: "#aaa",
            });
          }
        );
    } else {
      Swal.fire({
        title: "Deleted!",
        text: "The item has been deleted.",
        icon: "success",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  rangePopUp(){
    this.rangeCR= true;
  }

  closeModal(){
    this.rangeCR= false;
  }

  ngOnDestroy() {
    this.subActionService.unsubscribe();
    this.subDataService.unsubscribe();
    this.preSimulationService.resetValues();
  }
}
