import { ConcentrationUnits } from "../enums/concentration-units.enum";

export const CONCENTRATION_UNITS = {
  KILOGRAMS_CUBIC_DECIMETER: {
    name: "Kilograms per cubic decimeter",
    symbol: "kg/dm³",
    enum: ConcentrationUnits.KilogramsCubicDecimeter,
    fractionDigits: 4,
  },
  GRAMS_CUBIC_CENTIMETER: {
    name: "grams per cubic centimeter",
    symbol: "g/cm³",
    enum: ConcentrationUnits.GramsCubicCentimeter,
    fractionDigits: 4,
  },
  MILLIGRAMS_LITER: {
    name: "Milligrams per liter",
    symbol: "mg/l",
    enum: ConcentrationUnits.MilligramsLiter,
    fractionDigits: 4,
  },
  PPM: {
    name: "Parts per million",
    symbol: "ppm",
    enum: ConcentrationUnits.Ppm,
    fractionDigits: 4,
  },
  NA_CL_PERCENT: {
    name: "Sodium chloride percent",
    symbol: "%NaCl",
    enum: ConcentrationUnits.NaClPercent,
    fractionDigits: 2,
  },
  NA_CL_GRAMS_LITER: {
    name: "Sodium chloride grams per liter",
    symbol: "g/l NaCl",
    enum: ConcentrationUnits.NaClGramsLiter,
    fractionDigits: 4,
  },
  MILLIEQUIVALENTS_LITER: {
    name: "Milliequivalents per liter",
    symbol: "meq/l",
    enum: ConcentrationUnits.MilliequivalentsLiter,
    fractionDigits: 4,
  },
};

export const CONCENTRATION_UNITS_ARRAY: ConcentrationUnit[] = [
  CONCENTRATION_UNITS.KILOGRAMS_CUBIC_DECIMETER,
  CONCENTRATION_UNITS.GRAMS_CUBIC_CENTIMETER,
];

export const CONCENTRATION_UNITS_CL_ARRAY: ConcentrationUnit[] = [
  CONCENTRATION_UNITS.MILLIGRAMS_LITER,
  CONCENTRATION_UNITS.PPM,
  CONCENTRATION_UNITS.NA_CL_PERCENT,
];

export const CONCENTRATION_UNITS_CL_13CR_ARRAY: ConcentrationUnit[] = [
  CONCENTRATION_UNITS.MILLIGRAMS_LITER,
  CONCENTRATION_UNITS.PPM,
];

export const CONCENTRATION_UNITS_CL_PERCENT_DEF_ARRAY: ConcentrationUnit[] = [
  CONCENTRATION_UNITS.NA_CL_PERCENT,
  CONCENTRATION_UNITS.MILLIGRAMS_LITER,
  CONCENTRATION_UNITS.PPM,
];

export const CONCENTRATION_UNITS_MEQL_ARRAY: ConcentrationUnit[] = [
  CONCENTRATION_UNITS.MILLIEQUIVALENTS_LITER,
  CONCENTRATION_UNITS.MILLIGRAMS_LITER,
];

class ConcentrationUnit {
  name: string;
  symbol: string;
  enum: ConcentrationUnits;
  fractionDigits: number;
}
