import {
  Directive,
  HostListener
} from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[commaToDot]",
})
export class CommaToDotDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener("input", ["$event"])
  change($event: { target: any; }) {
    const item = $event.target;
    const originalValue = item.value;
    const recoverPosition = item.selectionStart;

    const replacedValue = originalValue.replace(/,/g, ".");
    if (replacedValue != originalValue) {
      if (this.ngControl)
        this.ngControl.control.setValue(replacedValue, { emit: false });

      item.value = replacedValue;
      item.selectionStart = item.selectionEnd = recoverPosition;
    }
  }
}
