import { VolumeUnits } from "../enums/volume-units.enum";

export const VOLUME_UNITS = {
  CUBIC_METERS_DAY: {
    name: "Cubic meters per day",
    symbol: "m³/day",
    enum: VolumeUnits.CubicMetersDay,
    fractionDigits: 4,
  },
  BARRELS_DAY: {
    name: "Barrels per day",
    symbol: "bbl/day",
    enum: VolumeUnits.BarrelsDay,
    fractionDigits: 4,
  },
};

export const VOLUME_UNITS_ARRAY: VolumeUnit[] = [
  VOLUME_UNITS.CUBIC_METERS_DAY,
  VOLUME_UNITS.BARRELS_DAY,
];

class VolumeUnit {
  name: string;
  symbol: string;
  enum: VolumeUnits;
  fractionDigits: number;
}
