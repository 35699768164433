import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Datasheet } from "../models/dcp-modal";

@Injectable({
  providedIn: "root",
})
export class SlottedModalService {
  private dcpProductsSource$: Subject<Datasheet[]> = new Subject<Datasheet[]>();
  dcpProducts$ = this.dcpProductsSource$.asObservable();

  constructor() {}

  setDcpProducts(dcpProducts: Datasheet[]) {
    this.dcpProductsSource$.next(dcpProducts);
  }
}
