<form [formGroup]="fgFilters">
  <app-main-filters
    [fgFilters]="fgFilters"
    [filters]="filters"
    (changeMainFilters)="changeMainFilters($event)"
  ></app-main-filters>
  <app-sub-filters
    [fgFilters]="fgFilters"
    [filters]="filters"
    (changeSubFilters)="changeSubFilters($event)"
    *ngIf="subfiltersVisible"
  ></app-sub-filters>
  <div *ngIf="canSimulateUserRole" class="simulate-user-role-container">
    <input formControlName="simulateUserRole" type="checkbox" id="SimulateUserRole">
    <label for="SimulateUserRole">Simulate user role</label>
  </div>
</form>
