import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformToPercent'
})
export class TransformToPercentPipe implements PipeTransform {
  transform(value: any): any {
    return (value || value === 0) && Number.isFinite(value) ? (Number(value) * 100) : value;
  }
}
