import {
  HttpClient, HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SlottedPerforatedPipeImagesRequest } from "src/app/modules/slotted-perforated-pipe/shared/models/slotted-perforated-image";
import {
  DCSlottedBeforeRequest,
  DCSlottedLengthRequest,
  DCSlottedPipesRequest,
  DCSlottedSlotsRequest,
  DeleteSlottedSimulationResponse,
  ExistsSlottedSimulationResponse,
  LoadSlottedSimulationResponse,
  SaveSimulationSlottedRequest, SlottedPipeRequest,
  SlottedPipeResponse
} from "src/app/modules/slotted-perforated-pipe/slotted-pipe/models/slotted-pipe";
import { environment } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import { APIResponse } from "../http/models/api-response";

@Injectable({
  providedIn: "root",
})
export class SlottedPipeService {
  private readonly API_ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getSlottedPipeImages(
    slottedPerforatedPipeImagesRequest: SlottedPerforatedPipeImagesRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      slottedPerforatedPipeImagesRequest,
      httpParams,
      "Input"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/createReportImages`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  getSlottedPipeResults(
    slottedPipeRequest: SlottedPipeRequest
  ): Observable<SlottedPipeResponse> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      slottedPipeRequest,
      httpParams,
      "SlottedPerforatedPipeRequest"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/calculation`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as SlottedPipeResponse;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  saveSimulation(saveSimulationSlottedRequest: SaveSimulationSlottedRequest) {
    let formData: FormData = new FormData();

    formData = this.httpService.getFormDataFromObject(
      saveSimulationSlottedRequest,
      formData,
      "saveSlottedSimulationRequest"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/saveSimulation`;

    const http$ = this.http.post<any>(URL, formData, {
      reportProgress: true,
      observe: "events",
    });

    return http$;
  }

  loadSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/byTreeId/${treeId}`;
    const http$ = this.http
      .get<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as LoadSlottedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  existsSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/exists/${treeId}`;
    const http$ = this.http
      .get<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as ExistsSlottedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  deleteSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/byTreeId/${treeId}`;
    const http$ = this.http
      .delete<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as DeleteSlottedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  dataCheckSlottedPipes(
    dcSlottedPipesRequest: DCSlottedPipesRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcSlottedPipesRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/validate/pipedata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }


  dataCheckSlottedLength(
    dcSlottedLengthRequest: DCSlottedLengthRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcSlottedLengthRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/validate/lengthdata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  dataCheckSlottedSlots(
    dcSlottedSlotsRequest: DCSlottedSlotsRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcSlottedSlotsRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/validate/slotdata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  dataCheckSlottedBefore(
    dcSlottedBeforeRequest: DCSlottedBeforeRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcSlottedBeforeRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/validate/beforesubmit`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  registerLog(request: any) {
    const httpOptionsCustom = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Loading': 'true'
      })
    }

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Slotted/RegisterLogs`;

    return this.http.post<any>(URL, request, httpOptionsCustom).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }
}
