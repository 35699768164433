<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">Customer Mgr</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.CUSTOMER_MGR.name }}</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <div class="form-group">
              <label class="col-sm-1 control-label title input-xs"
                >Customers</label
              >
              <p style="float: right"></p>
            </div>
          </div>

          <div class="panel-body table">
            <div class="widget widget-fullwidth widget-small">
              <div
                id="table1_wrapper"
                class="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                <div class="col-sm-12 n-p">
                  <div class="d-f">
                    <button
                      id="refresh-btn"
                      type="button"
                      class="btn btn-primary md-close"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Refresh table data"
                      (click)="refreshTable()"
                    >
                      <label>Refresh Table Data</label>
                      <span class="icon s7-refresh"> </span>
                    </button>
                    <label id="onlyNotVerified">
                      <input
                        type="checkbox"
                        [(ngModel)]="onlyNotVerified"
                        (ngModelChange)="refreshTable()"
                      />
                      Only Not Verified
                    </label>
                  </div>
                  <button
                    id="add-customer-btn"
                    type="button"
                    class="btn btn-primary md-close"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Add new customer"
                    data-toggle="modal"
                    data-target="#create-customer-modal"
                    data-placement="right"
                    data-tooltip
                    (click)="addCustomer()"
                  >
                    <label>Add New Customer</label>
                    <span class="icon s7-plus"> </span>
                  </button>
                </div>
                <div class="row am-datatable-body">
                  <div class="col-sm-12">
                    <table
                      id="tableUser"
                      datatable
                      [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger"
                      class="row-border hover"
                    >
                      <thead>
                        <tr>
                          <th class="title tituloColumna">Id</th>
                          <th class="title tituloColumna">Description</th>
                          <th class="title tituloColumna">Verified</th>
                          <th class="title tituloColumna">Last Update User</th>
                          <th class="title tituloColumna">Last Update Date</th>
                          <th class="title tituloColumna">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let customer of customers"
                          class="datosGrilla"
                        >
                          <td>{{ customer.id }}</td>
                          <td>{{ customer.description }}</td>
                          <td>{{ customer.verified ? "Yes" : "No" }}</td>
                          <td>{{ customer.lastUpdateUser }}</td>
                          <td>
                            {{ customer.lastUpdateDate | date : "medium" }}
                          </td>
                          <td class="center">
                            <i
                              title="Verify customer"
                              data-toggle="modal"
                              data-target="#form-verify-customer"
                              class="icon s7-check iconosGrilla"
                              (click)="verifyCustomer(customer.id)"
                            ></i>
                            <i
                              title="Edit customer"
                              data-toggle="modal"
                              data-target="#form-edit-customer"
                              class="icon s7-pen iconosGrilla"
                              (click)="editCustomer(customer.id)"
                            ></i>
                            <i
                              title="Delete customer"
                              class="icon s7-trash iconosGrilla"
                              (click)="
                                handleCustomerDeletionNotice(customer.id)
                              "
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="form-edit-customer"
          tabindex="-1"
          role="dialog"
          class="modal fade modal-colored-header"
        >
          <div
            class="modal-dialog custom-width"
            style="width: 550px; height: 500px"
          >
            <div class="modal-content">
              <div style="background-color: #009">
                <div class="panel-heading">
                  <h3 style="color: white; font-weight: bold">Edit Customer</h3>
                </div>
              </div>

              <div class="modal-body form" style="padding-top: 20px">
                <div class="form-group">
                  <label>Id</label>
                  <input
                    type="name"
                    class="form-control"
                    [(ngModel)]="customer.id"
                    disabled="true"
                  />
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <ng-select
                    class="pre-sim-ng-select"
                    placeholder="Select"
                    [items]="customersCopy"
                    bindLabel="description"
                    bindValue="description"
                    clearAllText="Clear"
                    [searchable]="true"
                    [(ngModel)]="customer.description"
                    appendTo="body"
                    [addTag]="true"
                  >
                  </ng-select>
                </div>
              </div>
              <div
                class="modal-footer"
                style="padding-top: 0px; border-top: none"
              >
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-primary md-close"
                  (click)="submitEdit()"
                >
                  Edit
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-default md-close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="form-verify-customer"
          tabindex="-1"
          role="dialog"
          class="modal fade modal-colored-header"
        >
          <div
            class="modal-dialog custom-width"
            style="width: 550px; height: 500px"
          >
            <div class="modal-content">
              <div style="background-color: #009">
                <div class="panel-heading">
                  <h3 style="color: white; font-weight: bold">
                    Verify Customer
                  </h3>
                </div>
              </div>

              <div class="modal-body form" style="padding-top: 20px">
                <div class="form-group">
                  <label>Id</label>
                  <input
                    type="name"
                    class="form-control"
                    [(ngModel)]="customer.id"
                    disabled="true"
                  />
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <input
                    type="name"
                    class="form-control"
                    [(ngModel)]="customer.description"
                    disabled="true"
                  />
                </div>
                <div class="form-group">
                  <label>
                    <input type="checkbox" [(ngModel)]="customer.verified" />
                    Verified
                  </label>
                </div>
              </div>
              <div
                class="modal-footer"
                style="padding-top: 0px; border-top: none"
              >
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-primary md-close"
                  (click)="submitVerify()"
                >
                  Verify
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-default md-close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-create-customer-modal></app-create-customer-modal>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
  >
</div>
