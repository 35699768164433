<div class="container">
  <div class="row">
    <div class="col-12">
      <table class="table table-hover" style="margin-top: 10px;">
        <thead style='background-color: black; color: white;'>
          <tr>
            <th>Schema</th>
            <th>Table</th>
            <th>Owner</th>
            <th>Indexes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tabla of tablas">
            <td>{{tabla.schemaName}}</td>
            <td>{{tabla.tableName}}</td>
            <td>{{tabla.tableOwner}}</td>
            <td>{{tabla.hasIndexes}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <div class="form-group" style="width: 500px;display: inline-block;">
        <label for="tableName">Nombre de la tabla:</label>
        <input type="text" class="form-control" id="tableName" [(ngModel)]="tableName" autofocus>
      </div>
      <button class="btn btn-primary" (click)="verStructure()">Ver Campos</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="table table-hover" style="margin-top: 10px;">
        <thead style='background-color: black; color: white;'>
          <tr>
            <th>Table</th>
            <th>Column</th>
            <th>Data Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stru of structures">
            <td>{{stru.tableName}}</td>
            <td>{{stru.columnName}}</td>
            <td>{{stru.dataType}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-primary" (click)="getText()">Probar texto</button>
      {{texto}}
    </div>
  </div>
</div>