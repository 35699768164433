<style>
  .am-left-sidebar {
    width: 120px !important;
  }

  .my-menu li:hover > ul {
    display: block;
  }
  .sub-menu{
    display: none;
    position: fixed;
    left: 100px;
    top: 0;
    height: 100%;
    background-color: #484747;
    z-index: 1051 !important;
    padding: 0;
    table-layout: fixed;
    list-style: none;
    width: 280px;
  }
  .sub-menu li a{
    padding: 11px 25px;
    color: #b3b3b3;
    font-size: 13px;
    display: block;
    font-weight: 300;
  }
</style>
<div class="am-left-sidebar my-menu">
  <div class="content">
    <div class="am-logo"></div>
    <ul class="sidebar-elements">
      <li class="parent" *ngFor="let url of urls">
        <a *ngIf="url.icon.length > 0 && url.visible" [routerLink]="url.href" >
          <i class="icon s7-{{url.icon}}"></i>
          <span >{{url.text}}</span>
        </a>
        <a *ngIf="url.image.length > 0 && url.visible" [routerLink]="url.href">
          <span>
            <img src={{url.image}} />
          </span>
          <span>{{url.text}}</span>
        </a>
        <ul class="sub-menu" *ngIf="url.subItems.length">
          <li class="title">{{url.sectionTitle}}</li>
          <li *ngFor="let lnk of url.subItems">
            <a *ngIf="lnk.icon.length > 0 && lnk.visible" [routerLink]="lnk.href">
              <i class="icon s7-{{lnk.icon}}" style="font-size: 32px;"></i>
              <span>{{lnk.text}}</span>
            </a>
            <a *ngIf="lnk.image.length > 0 && lnk.visible" [routerLink]="lnk.href">
              <span><img src={{lnk.image}} /></span>
              <span>{{lnk.text}}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="parent">
        <a (click)="logout(configurations[0].configId)">
          <i class="icon s7-power"></i>
          <span>Logout</span></a>
      </li>
    </ul>
  </div>
</div>