<style>
  .cabecera {
    color: #808080;
  }
</style>
<div class="am-content">
<div class="main-content">
  <div class="page-head">
    <h2 class="title">App Mgr</h2>
    <ol class="breadcrumb">
      <li><a href="/home">Home</a></li>
      <li class="active">App Mgr</li>
    </ol>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="form-group">
            <label class="col-sm-1 control-label title input-xs">Department</label>
            <div class="col-sm-3">
              <select class="form-control input-sm">
                <option>Department 1</option>
                <option>Department 2</option>
                <option>Department 3</option>
                <option>Department 4</option>
                <option>Department 5</option>
              </select>
            </div>
          </div>
        </div>
        <div class="panel-body table">
          <div class="widget widget-fullwidth widget-small">
            <div id="table1_wrapper" class="dataTables_wrapper form-inline dt-bootstrap no-footer">
              <div class="row am-datatable-header">
                <div class="col-sm-6" >
                  <div class="dataTables_length" id="table1_length">
                    <label>Show <select name="table1_length" aria-controls="table1" class="form-control input-sm">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select> entries</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div id="table1_filter" class="dataTables_filter" style="text-align: end;">
                    <label>Search:<input type="search" class="form-control input-sm" placeholder=""
                        aria-controls="table1"></label>
                  </div>
                </div>
              </div>
              <div class="row am-datatable-body">
                <div class="col-sm-12">
                  <table id="table1" class="table table-striped table-hover table-fw-widget dataTable no-footer"
                    role="grid" aria-describedby="table1_info">
                    <thead>
                      <tr role="row">
                        <th class="sorting_asc cabecera" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-sort="ascending" aria-label="Name: activate to sort column descending"
                          style="width: 50px;text-align: left;font-weight: bold !important;">UserID</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Surname: activate to sort column ascending"
                          style="width: 294px;text-align: left;font-weight: bold !important;">
                          Name</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Mail Address: activate to sort column ascending"
                          style="width: 270px;text-align: left;font-weight: bold !important;">Surname</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Department: activate to sort column ascending"
                          style="width: 198px;text-align: left;font-weight: bold !important;">
                          Department</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Roles: activate to sort column ascending"
                          style="width: 147px;text-align: left;font-weight: bold !important;">Web
                          13Cr Corrosion</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Nr Login: activate to sort column ascending"
                          style="width: 147px;text-align: left;font-weight: bold !important;">
                          Web Advanced Ratings</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Last Login: activate to sort column ascending"
                          style="width: 147px;text-align: left;font-weight: bold !important;">
                          Web Casing Wear</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Is Active: activate to sort column ascending"
                          style="width: 147px;text-align: left;font-weight: bold !important;">
                          Web Corrosion CLAS</th>
                        <th class="sorting" tabindex="0" aria-controls="table1" rowspan="1" colspan="1"
                          aria-label="Action: activate to sort column ascending"
                          style="width: 147px;text-align: left;font-weight: bold !important;">Web
                          Slotted</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="gradeA odd" role="row">
                        <td class="sorting_1">1</td>
                        <td>Firefox 1.0</td>
                        <td>Win 98+ / OSX.2+</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt5" id="swt5"><span>
                                <label for="swt5"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt6" id="swt6"><span>
                                <label for="swt6"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt7" id="swt7"><span>
                                <label for="swt7"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt8" id="swt8"><span>
                                <label for="swt8"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt9" id="swt9"><span>
                                <label for="swt9"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA even" role="row">
                        <td class="sorting_1">2</td>
                        <td>Firefox 1.5</td>
                        <td>Win 98+ / OSX.2+</td>
                        <td class="center">Department 2</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt11" id="swt11"><span>
                                <label for="swt11"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt12" id="swt12"><span>
                                <label for="swt12"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt13" id="swt13"><span>
                                <label for="swt13"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt14" id="swt14"><span>
                                <label for="swt14"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt15" id="swt15"><span>
                                <label for="swt15"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA odd" role="row">
                        <td class="sorting_1">3</td>
                        <td>Firefox 2.0</td>
                        <td>Win 98+ / OSX.2+</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA even" role="row">
                        <td class="sorting_1">4</td>
                        <td>Firefox 3.0</td>
                        <td>Win 2k+ / OSX.3+</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA odd" role="row">
                        <td class="sorting_1">5</td>
                        <td>Camino 1.0</td>
                        <td>OSX.2+</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA even" role="row">
                        <td class="sorting_1">6</td>
                        <td>Camino 1.5</td>
                        <td>OSX.3+</td>
                        <td class="center">Department 3</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA odd" role="row">
                        <td class="sorting_1">7</td>
                        <td>Netscape 7.2</td>
                        <td>Win 95+ / Mac OS 8.6-9.2</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA even" role="row">
                        <td class="sorting_1">8</td>
                        <td>Netscape Browser 8</td>
                        <td>Win 98SE+</td>
                        <td class="center">Department 1</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA odd" role="row">
                        <td class="sorting_1">9</td>
                        <td>Netscape Navigator 9</td>
                        <td>Win 98+ / OSX.2+</td>
                        <td class="center">Department 3</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="gradeA even" role="row">
                        <td class="sorting_1">10</td>
                        <td>Mozilla 1.0</td>
                        <td>Win 95+ / OSX.1+</td>
                        <td class="center">Department 4</td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt16" id="swt16"><span>
                                <label for="swt16"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt17" id="swt17"><span>
                                <label for="swt17"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt18" id="swt18"><span>
                                <label for="swt18"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt19" id="swt19"><span>
                                <label for="swt19"></label></span>
                            </div>
                          </div>
                        </td>
                        <td class="center">
                          <div class="col-sm-6 xs-pt-5">
                            <div class="switch-button switch-button-success switch-button-xs">
                              <input type="checkbox" checked="" name="swt20" id="swt20"><span>
                                <label for="swt20"></label></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row am-datatable-footer">
                <div class="col-sm-5">
                  <div class="dataTables_info" id="table1_info" role="status" aria-live="polite">Showing 1 to 10 of 57
                    entries</div>
                </div>
                <div class="col-sm-7" style="text-align: end;">
                  <div class="dataTables_paginate paging_simple_numbers" id="table1_paginate">
                    <ul class="pagination">
                      <li class="paginate_button previous disabled" id="table1_previous"><a href="#"
                          aria-controls="table1" data-dt-idx="0" tabindex="0">Previous</a></li>
                      <li class="paginate_button active"><a href="#" aria-controls="table1" data-dt-idx="1"
                          tabindex="0">1</a></li>
                      <li class="paginate_button "><a href="#" aria-controls="table1" data-dt-idx="2" tabindex="0">2</a>
                      </li>
                      <li class="paginate_button "><a href="#" aria-controls="table1" data-dt-idx="3" tabindex="0">3</a>
                      </li>
                      <li class="paginate_button "><a href="#" aria-controls="table1" data-dt-idx="4" tabindex="0">4</a>
                      </li>
                      <li class="paginate_button "><a href="#" aria-controls="table1" data-dt-idx="5" tabindex="0">5</a>
                      </li>
                      <li class="paginate_button "><a href="#" aria-controls="table1" data-dt-idx="6" tabindex="0">6</a>
                      </li>
                      <li class="paginate_button next" id="table1_next"><a href="#" aria-controls="table1"
                          data-dt-idx="7" tabindex="0">Next</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>