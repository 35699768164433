import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSelGuidelinesService } from "src/app/services/mat-sel-guidelines/mat-sel-guidelines.service";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { PRESSURE_UNITS_KPA_ARRAY } from "src/app/shared/consts/pressure-units";
import { TEMPERATURE_UNITS_ARRAY } from "src/app/shared/consts/temperature-units";
import { PressureUnits } from "src/app/shared/enums/pressure-units.enum";
import { TemperatureUnits } from "src/app/shared/enums/temperature-units.enum";
import { RunModelOpt1, RunModelOpt1Request } from "../models/opt1";
import Swal from "sweetalert2";
import { MatSelGuidelinesComponentsService } from "../services/mat-sel-guidelines-components.service";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";
import { combineLatest, merge } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";
import { NumericInputValidator } from "src/app/shared/validators/numeric-input.validator";

@Component({
  selector: "app-option1",
  templateUrl: "./option1.component.html",
  styleUrls: ["./option1.component.css"],
})
export class Option1Component implements OnInit {
  fg: FormGroup;

  grades: string[] = [];

  readonly TEMPERATURE_UNITS_ARRAY = TEMPERATURE_UNITS_ARRAY;
  readonly PRESSURE_UNITS_KPA_ARRAY = PRESSURE_UNITS_KPA_ARRAY;

  minTemperatureUnit: FormControl = new FormControl(TemperatureUnits.Celsius);
  totalPressureUnit: FormControl = new FormControl(PressureUnits.Kpa);

  unitPPH2S: string;
  PPH2S: string;
  defaultPPH2S: number;

  model: RunModelOpt1;

  rangeOpt1: boolean = false;

  constructor(
    private fb: FormBuilder,
    public unitMeasureService: UnitMeasureService,
    private matSelGuidelinesService: MatSelGuidelinesService,
    private matSelGuidelinesComponentsService: MatSelGuidelinesComponentsService,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.fg = this.fb.group({
      minTemperature: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          InterchaneableUnitValidator.delimited(
            20,
            150,
            this.minTemperatureUnit,
            UnitMeasures.Temperature,
            this.unitMeasureService
          ),
        ],
      ],
      minTemperatureDefault: [null, Validators.required],
      totalPressure: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          InterchaneableUnitValidator.delimited(
            0,
            20000,
            this.totalPressureUnit,
            UnitMeasures.Pressure,
            this.unitMeasureService,
            { defaultUnit: PressureUnits.Kpa }
          ),
        ],
      ],
      totalPressureDefault: [null, Validators.required],
      h2s: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
          NumericInputValidator.min(0),
        ],
      ],
      grade: [null, Validators.required],
    });

    merge(
      this.fg.get("minTemperatureDefault").valueChanges.pipe(pairwise()),
      this.fg.get("totalPressureDefault").valueChanges.pipe(pairwise()),
      this.fg.get("h2s").valueChanges.pipe(pairwise()),
      this.fg.get("grade").valueChanges.pipe(pairwise())
    ).subscribe(([prevVal, currVal]) => {
      if (prevVal !== currVal) {
        this.model = null;
        this.matSelGuidelinesComponentsService.updateModelOpt1(this.model);
        this.matSelGuidelinesComponentsService.setIsPrinteable(false);
        this.matSelGuidelinesComponentsService.fireFormChanges();
      }
    });

    this.fg.valueChanges.subscribe(() => {
      this.matSelGuidelinesComponentsService.updateFgOpt1(this.fg.value);
      this.matSelGuidelinesComponentsService.updateFormOpt1(this.fg);
    });

    this.matSelGuidelinesService.getGrades().subscribe((grades) => {
      this.grades = grades;
    });

    this.matSelGuidelinesComponentsService.loadOpt1Obs.subscribe((load) => {
      if (load) {
        this.fg?.patchValue({
          minTemperature: this.unitMeasureService.convertUnit(
            load.min_temperature,
            UnitMeasures.Temperature,
            this.minTemperatureUnit.value,
            false,
            4,
            { defaultUnit: TemperatureUnits.Celsius }
          ),
          totalPressure: this.unitMeasureService.convertUnit(
            load.total_pressure,
            UnitMeasures.Pressure,
            this.totalPressureUnit.value,
            false,
            4,
            { defaultUnit: PressureUnits.Kpa }
          ),
          h2s: load.percentage_H2S,
          grade: load.grade,
        });

        this.defaultPPH2S = load.partial_pressure_H2S;
        this.PPH2S =
          load.partial_pressure_H2S || load.partial_pressure_H2S === 0
            ? this.unitMeasureService
                .convertUnit(
                  load.partial_pressure_H2S,
                  UnitMeasures.Pressure,
                  this.totalPressureUnit.value,
                  false,
                  4,
                  { defaultUnit: PressureUnits.Kpa }
                )
                .toFixed(4)
            : null;
        this.matSelGuidelinesComponentsService.updatePPH2SOpt1(this.PPH2S);

        this.model = {
          backgroundColor: load.background_color,
          criteria15156opt1: load.criteria,
          ppH2s: load.partial_pressure_H2S,
          recommendedGrades: load.recommended_grades?.split(","),
          recommendedGradesString: load.recommended_grades,
          recommendedGradesComment: load.recommended_grades_comment,
          sourCondition: load.sour_condition,
        };

        if (this.areAllValuesNullOrUndefined(this.model)) this.model = null;

        this.matSelGuidelinesComponentsService.updateModelOpt1(this.model);
        this.updateAllControls(this.fg);
      } else this.onNew();
    });

    this.matSelGuidelinesComponentsService.resetValuesObs.subscribe(() => {
      this.onNew();
    });

    combineLatest([
      this.minTemperatureUnit.valueChanges.pipe(
        startWith(this.minTemperatureUnit.value)
      ),
      this.totalPressureUnit.valueChanges.pipe(
        startWith(this.totalPressureUnit.value)
      ),
    ]).subscribe(([minTemperatureValue, totalPressureValue]) => {
      const units = {
        minTemperature: minTemperatureValue,
        totalPressure: totalPressureValue,
      };

      this.PPH2S = this.PPH2S
        ? this.unitMeasureService
            .convertUnit(
              this.defaultPPH2S,
              UnitMeasures.Pressure,
              totalPressureValue,
              false,
              4,
              { defaultUnit: PressureUnits.Kpa }
            )
            .toFixed(4)
        : null;

      this.matSelGuidelinesComponentsService.updatePPH2SOpt1(this.PPH2S);

      this.unitPPH2S = this.unitMeasureService.getUnitSymbol(
        UnitMeasures.Pressure,
        totalPressureValue
      );
      this.matSelGuidelinesComponentsService.updateOpt1Units(units);
    });

    this.unitPPH2S = this.unitMeasureService.getUnitSymbol(
      UnitMeasures.Pressure,
      PressureUnits.Kpa
    );
  }

  areAllValuesNullOrUndefined(obj: any): boolean {
    return Object.values(obj).every(
      (value) =>
        value === null ||
        value === undefined ||
        (typeof value === "object" && Object.keys(value).length === 0)
    );
  }

  updateAllControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.updateAllControls(control);
      } else {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }

  onNew() {
    this.fg.reset();
  }

  onSubmit() {
    if (this.fg.invalid)
      Swal.fire({
        title: "Action required",
        text: "Please complete all inputs to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

    let request: RunModelOpt1Request = {
      minTemp: this.fg.value.minTemperatureDefault,
      totPressure: this.fg.value.totalPressureDefault,
      percentageH2S: this.fg.value.h2s,
      grade: this.fg.value.grade,
      customerId: this.preSimulationService.getCurrentCustomerId(),
      countryId: this.preSimulationService.getCurrentCountryId(),
    };

    this.matSelGuidelinesService.runModelOpt1(request).subscribe((response) => {
      this.defaultPPH2S = response.ppH2s;
      this.PPH2S =
        response.ppH2s || response.ppH2s === 0
          ? this.unitMeasureService
              .convertUnit(
                response.ppH2s,
                UnitMeasures.Pressure,
                this.totalPressureUnit.value,
                false,
                4,
                { defaultUnit: PressureUnits.Kpa }
              )
              .toFixed(4)
          : null;
      this.matSelGuidelinesComponentsService.updatePPH2SOpt1(this.PPH2S);

      this.model = {
        backgroundColor: response.backgroundColor,
        criteria15156opt1: response.criteria15156opt1,
        ppH2s: response.ppH2s?.toFixed(4),
        recommendedGrades: response.recommendedGrades?.split(","),
        recommendedGradesString: response.recommendedGrades,
        recommendedGradesComment: response.recommendedGradesComment,
        sourCondition: response.sourCondition,
      };

      this.matSelGuidelinesComponentsService.updateModelOpt1(this.model);
    });
  }

  rangePopUp() {
    this.rangeOpt1 = true;
  }

  closeModal() {
    this.rangeOpt1 = false;
  }

  get form() {
    return this.fg.controls;
  }
}
