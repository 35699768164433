import { AbstractControl, ValidationErrors } from "@angular/forms";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { UnitMeasures } from "../enums/unit-measures.enum";
export class InterchaneableUnitValidator {
  static delimited(
    min: number,
    max: number,
    unitFormControl: AbstractControl,
    unitMeasure: UnitMeasures,
    unitMeasureService: UnitMeasureService,
    object?: any,
    customErrorMessage?: string,
    customFractionUnits?: number,
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number = Number(String(control.value).replace(",", "."));

      if (unitFormControl && (value || value === 0)) {
        const unit: any = unitFormControl.value;
        const fractionDigits: number = customFractionUnits ?? unitMeasureService.getUnitFractionDigits(
          unitMeasure,
          unit
        );
        const minConverted: number = unitMeasureService.convertUnit(
          min,
          unitMeasure,
          unit,
          false,
          fractionDigits,
          object
        );
        const maxConverted: number = unitMeasureService.convertUnit(
          max,
          unitMeasure,
          unit,
          false,
          fractionDigits,
          object
        );
        const unitSymbol: string = unitMeasureService.getUnitSymbol(
          unitMeasure,
          unit
        );

        //to validate functions that invert min and max values (homographic function per example)
        let realMin = minConverted;
        let realMax = maxConverted;
        realMin = minConverted < maxConverted ? minConverted : maxConverted;
        realMax = minConverted < maxConverted ? maxConverted : minConverted;

        const error: any = {
          delimited: true,
          errorMessage:
            customErrorMessage ??
            `The value entered must be between ${realMin} ${unitSymbol} and ${realMax} ${unitSymbol}`,
        };

        return value < realMin || value > realMax ? error : null;
      } else return null;
    };
  }
  static minWithoutConvert(
    min: number,
    unitFormControl: AbstractControl,
    unitMeasure: UnitMeasures,
    unitMeasureService: UnitMeasureService,
    customErrorMessage?: string,
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number = Number(String(control.value).replace(",", "."));

      if (unitFormControl && (value || value === 0)) {
        const unit: any = unitFormControl.value;
        const unitSymbol: string = unitMeasureService.getUnitSymbol(
          unitMeasure,
          unit
        );

        const error: any = {
          delimited: true,
          errorMessage:
            customErrorMessage ??
            `The value entered must be greater or equal than ${min} ${unitSymbol}`,
        };

        return value < min ? error : null;
      } else return null;
    };
  }
}
