import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";

type Range = { min: number | null, max: number | null};

/**
 * `DensityCalculator` is a class that calculates the density of a substance based on its concentration.
 * 
 * This class uses two maps, `densityByMgLMap` and `densityByPpmMap`, to map ranges of concentrations (in mg/L and ppm, respectively) 
 * to corresponding ranges of densities. Each map entry represents a range of concentrations and the corresponding range of densities.
 * 
 * The `calculateDensity` method calculates the density for a given concentration and unit of concentration. It uses the 
 * appropriate map based on the unit of concentration, then uses the `lookForEntries` method to find the map entry where the given 
 * concentration falls within the range of the key. It then uses the `interpolateDensity` method to interpolate the density based on 
 * the given concentration and the range of concentrations.
 * 
 * The `lookForEntries` method is a helper method that finds the map entry where a given number falls within the range of the key.
 * 
 * The `interpolateDensity` method is a helper method that interpolates the density based on a given concentration and a range of concentrations.
 */
export class DensityCalculator {
    private readonly densityByMgLMap = new Map<Range, Range>([
        [{ min: null, max: 6098.15 }, { min: null, max: 1.0053 }],
        [{ min: 6098.15, max: 12283.65 }, { min: 1.0053, max: 1.0125 }],
        [{ min: 12283.65, max: 24896.08 }, { min: 1.0125, max: 1.0268 }],
        [{ min: 24896.08, max: 37899.15 }, { min: 1.0268, max: 1.0413 }],
        [{ min: 37899.15, max: 51240.72 }, { min: 1.0413, max: 1.0559 }],
        [{ min: 51240.72, max: 64948.66 }, { min: 1.0559, max: 1.0707 }],
        [{ min: 64948.66, max: 73866.9 }, { min: 1.0707, max: 1.0802 }],
        [{ min: 73866.9, max: 79030.27 }, { min: 1.0802, max: 1.0857 }],
        [{ min: 79030.27, max: 93492.83 }, { min: 1.0857, max: 1.1009 }],
        [{ min: 93492.83, max: 108333.91 }, { min: 1.1009, max: 1.1162 }],
        [{ min: 108333.91, max: 123589.9 }, { min: 1.1162, max: 1.1319 }],
        [{ min: 123589.9, max: 139251.1 }, { min: 1.1319, max: 1.1478 }],
        [{ min: 139251.1, max: 155338.13 }, { min: 1.1478, max: 1.164 }],
        [{ min: 155338.13, max: 171847.35 }, { min: 1.164, max: 1.1804 }],
        [{ min: 171847.35, max: 188817.6 }, { min: 1.1804, max: 1.1972 }],
        [{ min: 188817.6, max: null }, { min: 1.1972, max: null }]
    ]);
    private readonly densityByPpmMap = new Map<Range, Range>([
        [{ min: null, max: 6037.8 }, { min: null, max: 1.0053 }],
        [{ min: 6037.8, max: 12162 }, { min: 1.0053, max: 1.0125 }],
        [{ min: 12162, max: 24171 }, { min: 1.0125, max: 1.0268 }],
        [{ min: 24171, max: 36441.5 }, { min: 1.0268, max: 1.0413 }],
        [{ min: 36441.5, max: 48340.3 }, { min: 1.0413, max: 1.0559 }],
        [{ min: 48340.3, max: 60699.7 }, { min: 1.0559, max: 1.0707 }],
        [{ min: 60699.7, max: 68395.3 }, { min: 1.0707, max: 1.0802 }],
        [{ min: 68395.3, max: 72504.8 }, { min: 1.0802, max: 1.0857 }],
        [{ min: 72504.8, max: 84993.5 }, { min: 1.0857, max: 1.1009 }],        
        [{ min: 84993.5, max: 96726.7 }, { min: 1.1009, max: 1.1162 }],        
        [{ min: 96726.7, max: 109371.6 }, { min: 1.1162, max: 1.1319 }],
        [{ min: 109371.6, max: 121087.9 }, { min: 1.1319, max: 1.1478 }],
        [{ min: 121087.9, max: 133912.2 }, { min: 1.1478, max: 1.164 }],
        [{ min: 133912.2, max: 145633.3 }, { min: 1.164, max: 1.1804 }],
        [{ min: 145633.3, max: 157348 }, { min: 1.1804, max: 1.1972 }],        
        [{ min: 157348, max: null }, { min: 1.1972, max: null }]
     ]);

    /**
     * This function calculates the density based on the given concentration 'x' and the unit of concentration.
     * @param x - The concentration to calculate the density for.
     * @param unit - The unit of the concentration.
     * @returns The calculated density.
     */
    calculateDensity(x: number, unit: ConcentrationUnits): number {
        // Map each unit to its corresponding map
        const unitToMap = {
            [ConcentrationUnits.MilligramsLiter]: this.densityByMgLMap,
            [ConcentrationUnits.Ppm]: this.densityByPpmMap
        };

        // Get the map corresponding to the unit
        const map = unitToMap[unit];

        // If the unit is not recognized, throw an error
        if (!map)
            throw new Error(`Unknown unit: ${unit}`);

        // Use the map to find the range, then interpolate the density using the found range
        let density = this.interpolateDensity(x, this.lookForEntries(x, map));

        return density;
    }

    /**
     * This function looks for the entry in the map where the given number 'x' falls within the range of the key.
     * @param x - The number to look for in the ranges of the map keys.
     * @param map - The map with ranges as keys and values.
     * @returns The map entry where 'x' falls within the range of the key, or null if no such entry is found.
     */
    private lookForEntries(x: number, map: Map<Range, Range>) {
        let entry: { range: Range; density: Range; } = null;

        for (let [range, density] of map.entries()) {
            // Define conditions for checking if 'x' is within the range
            const isAboveMin = range.min === null && x < range.max;
            const isBelowMax = range.max === null && x > range.min;
            const isWithinRange = x >= range.min && x <= range.max;

            // Check if 'x' is within the range
            if (isAboveMin || isBelowMax || isWithinRange) {
                // If 'x' is within the range, set 'entry' to the current range-density pair and exit the loop
                entry = { range, density };
                break;
            }
        }

        return entry;
    }

    /**
     * Calculates the default density based on the given value.
     * 
     * @param x - The input value.
     * @returns The calculated default density.
     */
    getDefaultDensity(x: number): number {
        return 6098.15 + ((x - 1.0053) * (12283.65 - 6098.15)) / (1.0125 - 1.0053);
    }

    /**
     * This function interpolates the density based on the given concentration 'x' and the range of concentrations.
     * @param x - The concentration for which the density will be interpolated.
     * @param entry - An object containing the range of concentrations and corresponding densities.
     * @returns The interpolated density.
     *
     * If range.min is null, it returns range.max. This implies that for concentrations less than range.max, the density is constant.
     * If range.max is null, it returns range.min. This implies that for concentrations greater than range.min, the density is constant.
     * Otherwise, it performs a linear interpolation between range.min and range.max based on the value of 'x'.
     */
    private interpolateDensity(x: number, entry: {range: Range, density: Range}): number {
        return (entry.range.min === null || entry.range.max === null) ? this.getDefaultDensity(x)
            : entry.density.min + ((x - entry.range.min) * (entry.density.max - entry.density.min)) / (entry.range.max - entry.range.min);
    }
}
