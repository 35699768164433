export class CorrosionClasInput {
    iTemperature: number;
    iTotalPressure: number;
    iCO2perc: number;
    iH2Sperc: number;
    radioOption: string;
    iPipeOD: number;
    iPipeWT: number;
    iPipeID: number;
    iCperc: number;
    iCrperc: number;
    iOilProduction: number;
    iWaterProduction: number;
    iWatercut: number;
    iDesviacion: number;
    iAPIoilGravity: number;
    iLiquidVelocity: number;
    iNa: number;
    iK: number;
    iCa: number;
    iMg: number;
    iBa: number;
    iSr: number;
    iCl: number;
    iHCO3: number;
    iSO42: number;
    iFe: number;
    iAcetato: number;
    oAbsoluteTemp: number;
    oPartialCO2pressure: number;
    oPartialH2Spressure: number;
    oWbreak: number;
    oDissolvedSolids: number;
}