import { HttpClient } from "@angular/common/http";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/User.service";
import { UserMgr } from "./components/Usermgr.model";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import {
  MyArrayType,
  UpdateNameTreeRequest,
  UpdateSimulation,
  UpdateSimulationRequest,
  UpdateTree,
  UpdateUserEditRequest,
} from "src/app/models/user/User";
import { DataService } from "src/app/services/Data.service";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { DataTableDirective } from "angular-datatables";

declare var jQuery: any;

@Component({
  selector: "app-usermgr",
  templateUrl: "usermgr.component.html",
})
export class UsermgrComponent implements OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  errorMessage: string;
  postId: number;
  title = "User Mgr";
  tituloModalEdit = "Edit User";
  userMgrData: UserMgr[];
  userMgrDataForEdit: UserMgr[];
  SSuserId: Number;
  userData: UserMgr = {
    id: 0,
    user_ad: "",
    name: "",
    rol: "",
    surname: "",
    mail_address: "",
    department: "",
    nro_login: 0,
    last_update: new Date(Date.now()),
    last_update_user: "",
    creation_date: new Date(Date.now()),
    creation_user: "",
    deletion_date: new Date(Date.now()),
    deleted_flag: false,
    deleted_user: "",
    last_login: new Date(Date.now()),
  };

  selectedValue = null;

  rol = [
    { id: "Admin", name: "Admin" },
    { id: "SuperAdmin", name: "SuperAdmin" },
  ];
  selectedValueRol = null;

  readonly NAVIGATIONS = NAVIGATIONS;

  constructor(
    private serviceUser: UserService,
    private http: HttpClient,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.ObtenerUsuarios();

    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      order: [[0, "asc"]],
      columnDefs: [{targets:[7], type: 'date' }]
    };
    this.dataService.currentUserIdSource.subscribe(
      (userId) => (this.SSuserId = userId)
    );
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ObtenerUsuarios() {
    this.serviceUser.getAllUser().subscribe((users) => {
      this.userMgrData = users["data"].sort((a, b) => (a.id > b.id ? 1 : -1));

      this.dtTrigger.next();
    });
  }

  Editar(id: number) {
    this.userData = this.userMgrData.find((item) => id === item.id);
  }

  GrabarCambiosEditados() {
    let mail: string = this.getCurrentUserMail();

    //graba cambios luego de editar en modal

    let obj: MyArrayType = {
      id: this.userData.id,
      user_ad: this.userData.user_ad,
      name: this.userData.name,
      rol: this.userData.rol,
      surname: this.userData.surname,
      mail_address: this.userData.mail_address,
      department: this.userData.department,
      nro_login: this.userData.nro_login,
      last_update: new Date(Date.now()),
      last_update_user: mail,
      creation_date: this.userData.creation_date,
      creation_user: this.userData.creation_user,
      deletion_date: this.userData.deletion_date,
      deleted_flag: this.userData.deleted_flag,
      deleted_user: this.userData.deleted_user,
      last_login: this.userData.last_login,
    };
    const array: UpdateUserEditRequest = {
      updateUserEditRequest: obj,
    };

    const url = `${environment.apiEndpoint}usermgr/v1/User/UpdateUserEdit/${this.userData.id}`;
    this.http.put<any>(url, array).subscribe((data) => {
      this.postId = data.id;
    });

    const updateSimulation: UpdateSimulation = {
      user_id: this.userData.id,
      department: this.userData.department,
    };
    const updateRequest: UpdateSimulationRequest = {
      updateSimulationRequest: updateSimulation,
    };
    //update simulations according to new user department
    const urlUpdateSimulation = `${environment.apiEndpoint}usermgr/v1/User/UpdateUserSimulation`;
    this.http.put<any>(urlUpdateSimulation, updateRequest).subscribe((data) => {
      this.postId = data.id;
      this.confirmUpdated();
      this.refreshTable();
    });
    //update rate simulations13dr according to new user department
    const urlUpdateSimulation13cr = `${environment.apiEndpoint}usermgr/v1/User/UpdateUserSimulation13cr`;
    this.http
      .put<any>(urlUpdateSimulation13cr, updateRequest)
      .subscribe((data) => {
        this.postId = data.id;
      });
    //editar nombre de tree nivel user
    const updateTree: UpdateTree = {
      user_id: this.userData.id,
      item_name: this.userData.name + " " + this.userData.surname,
    };
    const updateNameTreeRequest: UpdateNameTreeRequest = {
      updateNameTreeRequest: updateTree,
    };
    const urlUpdateTree = `${environment.apiEndpoint}usermgr/v1/User/UpdateNameTree`;

    this.http
      .put<any>(urlUpdateTree, updateNameTreeRequest)
      .subscribe((data) => {
        this.postId = data.id;
      });

    //si se ha cambiado el nombre del usuario logueado, debe reflejarse toda la sesión
    if (this.SSuserId == this.userData.id) {
      this.dataService.changeNameSurname(
        this.userData.name + " " + this.userData.surname
      );
    }
  }

  Eliminar(id: number) {
    this.userData = this.userMgrData.find((item) => id === item.id);

    this.confirmDelete();
  }

  confirmUpdated() {
    Swal.fire({
      title: "Updated!",
      text: "The User has been Updated.",
      icon: "success",
      confirmButtonColor: "#009",
      timer: 8000,
      timerProgressBar: true,
    });
  }

  confirmDelete() {
    Swal.fire({
      title: "Do you want to reset the user:",
      text: this.userData.name + " " + this.userData.surname,
      icon: "warning",
      confirmButtonColor: "#009",
      showCancelButton: true,
      confirmButtonText: "Proceed",
      timer: 8000,
    }).then((result) => {
      if (result.isConfirmed) {
        this.UpdateForDelete(this.userData.id);
      }
    });
  }

  UpdateForDelete(id: number) {
    let mail: string = this.getCurrentUserMail();

    let obj: MyArrayType = {
      id: this.userData.id,
      user_ad: this.userData.user_ad,
      name: this.userData.name,
      rol: this.userData.rol,
      surname: this.userData.surname,
      mail_address: this.userData.mail_address,
      department: this.userData.department,
      nro_login: 0,
      last_update: this.userData.last_update,
      last_update_user: this.userData.last_update_user,
      creation_date: this.userData.creation_date,
      creation_user: this.userData.creation_user,
      deletion_date: new Date(Date.now()),
      deleted_flag: true,
      deleted_user: mail,
      last_login: this.userData.last_login,
    };
    const array: UpdateUserEditRequest = {
      updateUserEditRequest: obj,
    };
    const url = `${environment.apiEndpoint}usermgr/v1/User/UpdateUserEdit/${this.userData.id}`;
    this.http.put<any>(url, array).subscribe((data) => {
      this.postId = data.id;
      this.succesDelete();
      this.refreshTable();
    });
  }

  succesDelete() {
    Swal.fire({
      title: "The user was reset, when entering again it will be updated.",
      icon: "info",
      confirmButtonColor: "#009",
      timer: 8000,
    });
  }

  refreshTable(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.serviceUser.getAllUser().subscribe((users) => {
        dtInstance.clear().draw();

        // Destroy the table first
        dtInstance.destroy();

        // Set the new data
        this.userMgrData = users["data"].sort((a, b) => (a.id > b.id ? 1 : -1));

        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    });
  }

  private getCurrentUserMail() {
    let groupsData = sessionStorage.getItem("0-" + environment.auth.clientID);
    let mail: string;

    if (groupsData.length > 0) {
      const obj = JSON.parse(groupsData);
      if (obj["userData"]) {
        mail = obj["userData"]["preferred_username"];
      }
    }
    return mail;
  }
}
