import { Component, OnInit } from "@angular/core";
import { Results } from "../../models/perforated-pipe";
import { PerforatedService } from "../../services/perforated.service";

@Component({
  selector: "app-perforated-results",
  templateUrl: "./perforated-results.component.html",
  styleUrls: ["./perforated-results.component.css"],
})
export class PerforatedResultsComponent implements OnInit {
  perforatedResults: Results;
  showPerforatedResults: boolean = false;

  constructor(private perforatedService: PerforatedService) {}

  ngOnInit(): void {
    this.perforatedService.perforatedResults$.subscribe((perforatedResults) => {
      this.perforatedResults = perforatedResults;
    });

    this.perforatedService.showPerforatedResults$.subscribe((showPerforatedResults) => {
      this.showPerforatedResults = showPerforatedResults;
    });
  }
}
