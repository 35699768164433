import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    public data: any = [];
    public opt1Checked: boolean = false;
    public opt2Checked: boolean = false;
    public craChecked: boolean = false;
    public subject = new Subject<any>();
    private messageSource = new BehaviorSubject(this.data);
    currentMessage = this.messageSource.asObservable();
    
    changeAction(message: string) {
        this.messageSource.next(message)
    }
}