<section>
  <form [formGroup]="fgFilterCR">
    <div class="row">
      <div class="col-sm-5">
        <div class="col-sm-3">
          <input
            #inputFocus
            formControlName="temperature"
            class="form-control input-xs no-spin filter-input"
            [ngClass]="{
              'input-error':
                (key && (fgFilterCR.errors?.['duplicatedEntry'] || (
                (fgFilterCR.get('temperature').touched ||
                  fgFilterCR.get('temperature').dirty) &&
                fgFilterCR.get('temperature').invalid))) || (!key && !fgFilterCR.get('temperature').errors?.required && fgFilterCR.get('temperature').invalid)
            }"
            autocomplete="off"
            errorMessage
            [errorContainerId]="'temperatureError-' + key"
            [hiddenErrors]="hiddenErrors"
            interchangeableUnit
            [unitMeasure]="unitMeasureService.unitMeasures.Temperature"
            [selectedUnit]="temperatureUnitControl.value"
            [defaultControl]="fgFilterCR.get('temperatureDefault')"
            [unitControl]="temperatureUnitControl"
            [controlDisabledUnit]="false"
          />
          <error-message
            [id]="'temperatureError-' + key"
            class="text-danger"
          ></error-message>
        </div>
        <div class="col-sm-3">
          <input
            formControlName="pressureCO2"
            class="form-control input-xs no-spin filter-input"
            [ngClass]="{
              'input-error':
                (key && (fgFilterCR.errors?.['duplicatedEntry'] || (
                (fgFilterCR.get('pressureCO2').touched ||
                  fgFilterCR.get('pressureCO2').dirty) &&
                fgFilterCR.get('pressureCO2').invalid))) || (!key && !fgFilterCR.get('pressureCO2').errors?.required && fgFilterCR.get('pressureCO2').invalid)
            }"
            autocomplete="off"
            errorMessage
            [errorContainerId]="'pressureCO2Error-' + key"
            [hiddenErrors]="hiddenErrors"
            interchangeableUnit
            [unitMeasure]="unitMeasureService.unitMeasures.Pressure"
            [selectedUnit]="pressureCO2UnitControl.value"
            [defaultControl]="fgFilterCR.get('pressureCO2Default')"
            [unitControl]="pressureCO2UnitControl"
            [controlDisabledUnit]="false"
          />
          <error-message
            [id]="'pressureCO2Error-' + key"
            class="text-danger"
          ></error-message>
        </div>
        <div class="col-sm-3">
          <input
            formControlName="sodiumChloride"
            class="form-control input-xs no-spin filter-input"
            [ngClass]="{
              'input-error':
                (key && (fgFilterCR.errors?.['duplicatedEntry'] || (
                (fgFilterCR.get('sodiumChloride').touched ||
                  fgFilterCR.get('sodiumChloride').dirty) &&
                fgFilterCR.get('sodiumChloride').invalid))) || (!key && !fgFilterCR.get('sodiumChloride').errors?.required && fgFilterCR.get('sodiumChloride').invalid)
            }"
            autocomplete="off"
            errorMessage
            [errorContainerId]="'sodiumChlorideError-' + key"
            [hiddenErrors]="hiddenErrors"
            interchangeableUnit
            [unitMeasure]="unitMeasureService.unitMeasures.Concentration"
            [selectedUnit]="sodiumChlorideUnitControl.value"
            [defaultControl]="fgFilterCR.get('sodiumChlorideDefault')"
            [object]="{
              waterDensity: fgFilterCR.get('waterDensityDefault').value,
              defaultUnit: unitMeasureService.concentrationUnits.MilligramsLiter
            }"
            [unitControl]="sodiumChlorideUnitControl"
            [controlDisabledUnit]="false"
          />
          <error-message
            [id]="'sodiumChlorideError-' + key"
            class="text-danger"
          ></error-message>
        </div>
        <div class="col-sm-3">
          <input
            formControlName="naClPercent"
            class="form-control input-xs no-spin filter-input"
            autocomplete="off"
            errorMessage
            [errorContainerId]="'naClPercentError-' + key"
            [hiddenErrors]="hiddenErrors"
          />
          <error-message
            [id]="'naClPercentError-' + key"
            class="text-danger"
          ></error-message>
        </div>
      </div>

      <div [ngClass]="{'col-sm-4': !isModified13CrVisible, 'col-sm-5': isModified13CrVisible}">
        <div [ngClass]="{'col-sm-6': !isModified13CrVisible, 'col-sm-4': isModified13CrVisible}">
          <input
            formControlName="conventional13Cr"
            class="input-xs no-spin result-input"
            readonly
            tabindex="-1"
            warningMessage
            [warningContainerId]="'conventional13CrWarning-' + key"
            [priorityWarnings]="priorityWarnings"
            [ngClass]="{
              'input-warning': conventional13Cr?.warnings?.length > 0
            }"
            [placeholder]="isNotAvailable('conventional13Cr')"
          />
          <warning-message
            [id]="'conventional13CrWarning-' + key"
            class="text-danger"
          ></warning-message>
        </div>
        <div [ngClass]="{'col-sm-6': !isModified13CrVisible, 'col-sm-4': isModified13CrVisible}">
          <input
            formControlName="super13Cr"
            class="input-xs no-spin result-input"
            readonly
            tabindex="-1"
            warningMessage
            [warningContainerId]="'super13CrWarning-' + key"
            [ngClass]="{
              'input-warning': super13Cr?.warnings?.length > 0
            }"
            [placeholder]="isNotAvailable('super13Cr')"
          />
          <warning-message
            [id]="'super13CrWarning-' + key"
            class="text-danger"
          ></warning-message>
        </div>
        <div *ngIf="isModified13CrVisible" class="col-sm-4">
          <input
            formControlName="modified13Cr"
            class="input-xs no-spin result-input"
            readonly
            tabindex="-1"
            warningMessage
            [warningContainerId]="'modified13CrWarning-' + key"
            [ngClass]="{
              'input-warning': modified13Cr?.warnings?.length > 0
            }"
            [placeholder]="isNotAvailable('modified13Cr')"
          />
          <warning-message
            [id]="'modified13CrWarning-' + key"
            class="text-danger"
          ></warning-message>
        </div>
      </div>

      <div [ngClass]="{'col-sm-3': !isModified13CrVisible, 'col-sm-2': isModified13CrVisible}">
        <div *ngIf="key" class="actions-container">
          <div
            class="icon"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete inputs row"
            (click)="deleteFilter()"
          >
            <span class="s7-less"></span>
          </div>
          <div
            class="icon"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Clear inputs values"
            (click)="cleanFilter()"
          >
            <span class="s7-refresh"></span>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
