export enum UnitMeasures {
  Temperature,
  Pressure,
  Percentage,
  Length,
  Volume,
  APIGravity,
  Concentration,
  Velocity,
}
