import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { CONCENTRATION_UNITS } from "src/app/shared/consts/concentration-units";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";
import { WarningValidator } from "src/app/shared/validators/warning.validator";
import { FilterCR } from "../../models/filterCR";
import { CorrosionRateService } from "../../services/corrosion-rate.service";
import { arrayContainsValidator } from "../../validators/array-contains.directive";
import { FilterCRComponent } from "../filter-cr/filter-cr.component";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";
@Component({
  selector: "app-filters-cr",
  templateUrl: "filters-cr.component.html",
  styleUrls: ["filters-cr.component.scss"],
})
export class FiltersCRComponent implements OnInit {
  fgFilterCR: FormGroup = this.fb.group({
    temperature: [
      null,
      [
        Validators.required,
        Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS),
      ],
    ],
    temperatureDefault: [null],
    pressureCO2: [
      null,
      [
        Validators.required,
        Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS),
      ],
    ],
    pressureCO2Default: [null],
    sodiumChloride: [
      null,
      [
        Validators.required,
        Validators.pattern(REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS),
      ],
    ],
    sodiumChlorideDefault: [null],
    naClPercent: [
      { value: null, disabled: true }
    ],
    waterDensityDefault: [1],
    conventional13Cr: [null],
    conventional13CrDefault: [null],
    super13Cr: [null],
    super13CrDefault: [null],
    modified13Cr: [null],
    modified13CrDefault: [null],
  });

  @Output() setControls = new EventEmitter();

  @Input() fgFiltersCR: FormGroup;
  @Input() filtersCR: FilterCR[];
  @Input() deletedSimulations: number[];
  @Input() unitMeasureService: UnitMeasureService;
  @Input() temperatureUnitControl: AbstractControl;
  @Input() pressureCO2UnitControl: AbstractControl;
  @Input() sodiumChlorideUnitControl: AbstractControl;
  @Input() conventional13CrUnitControl: AbstractControl;
  @Input() super13CrUnitControl: AbstractControl;
  @Input() modified13CrUnitControl: AbstractControl;
  @Input() readonly TEMPERATURE_LIMIT_13CR: number;
  @Input() readonly TEMPERATURE_LIMIT_13CRS: number;
  @Input() readonly TEMPERATURE_LIMIT_13CRM: number;
  @Input() readonly MAX_TEMPERATURE_LIMIT: number;
  @Input() readonly TEMPERATURE_LIMIT_WARNING: any;
  @Input() readonly NACL_LIMIT_13CR: number;
  @Input() readonly NACL_LIMIT_13CRS: number;
  @Input() readonly NACL_LIMIT_13CRM: number;
  @Input() readonly NACL_LIMIT_WARNING: any;

  readonly NACL_TEMPERATURE_POINT_1 = {naCl: 10, temperature: 100};
  readonly NACL_TEMPERATURE_POINT_2 = {naCl: 5, temperature: 150};
  readonly NACL_LIMITER_FUNCTION_13CR: (temperature: number) => number = (temperature: number) => {
    let maxNaCl: number;

    if (temperature <= this.NACL_TEMPERATURE_POINT_1.temperature)
      maxNaCl = this.NACL_TEMPERATURE_POINT_1.naCl;
    else {
      const m = (this.NACL_TEMPERATURE_POINT_2.naCl - this.NACL_TEMPERATURE_POINT_1.naCl) / (this.NACL_TEMPERATURE_POINT_2.temperature - this.NACL_TEMPERATURE_POINT_1.temperature);
      const b = this.NACL_TEMPERATURE_POINT_1.naCl - m * this.NACL_TEMPERATURE_POINT_1.temperature;
      maxNaCl = m * temperature + b;
    }
    
    return maxNaCl;
  };
  readonly TEMPERATURE_LIMITER_FUNCTION_13CR: (naCl: number) => number = (naCl: number) => {
    let maxTemperature: number;

    if (naCl <= this.NACL_TEMPERATURE_POINT_2.naCl)
      maxTemperature = this.NACL_TEMPERATURE_POINT_2.temperature;
    else {
      const m = (this.NACL_TEMPERATURE_POINT_2.temperature - this.NACL_TEMPERATURE_POINT_1.temperature) / (this.NACL_TEMPERATURE_POINT_2.naCl - this.NACL_TEMPERATURE_POINT_1.naCl);
      const b = this.NACL_TEMPERATURE_POINT_1.temperature - m * this.NACL_TEMPERATURE_POINT_1.naCl;
      maxTemperature = m * naCl + b;
    }

    return maxTemperature;    
  };

  @ViewChild('emptyInput') filter:FilterCRComponent;

  hiddenErrors: string[] = ["required"];
  temperatureUnitEnabled: number;
  pressureCO2UnitEnabled: number;
  sodiumChlorideUnitEnabled: number;

  constructor(
    private fb: FormBuilder,
    private CRService: CorrosionRateService,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.configFiltersValidators();
  }

  private configFiltersValidators() {
    this.fgFilterCR
      .get("temperature")
      .addValidators(
        InterchaneableUnitValidator.delimited(
          0,
          this.MAX_TEMPERATURE_LIMIT,
          this.temperatureUnitControl,
          UnitMeasures.Temperature,
          this.unitMeasureService,
          null,
          null,
          2
        )
      );

    this.fgFilterCR
      .get("pressureCO2")
      .addValidators(
        InterchaneableUnitValidator.delimited(
          0,
          50,
          this.pressureCO2UnitControl,
          UnitMeasures.Pressure,
          this.unitMeasureService,
          null,
          null,
          2
        )
      );

    this.fgFilterCR
      .get("sodiumChloride")
      .addValidators(
        InterchaneableUnitValidator.minWithoutConvert(
          0,
          this.sodiumChlorideUnitControl,
          UnitMeasures.Concentration,
          this.unitMeasureService
        )
      );
  }

  private deleteArrayPosition(key: number) {
    let index = this.filtersCR.findIndex((x) => x.key == key);

    this.filtersCR.splice(index, 1);
  }

  private updateArrayPosition(key: number, filterCR: FilterCR) {
    let index = this.filtersCR.findIndex((x) => x.key == key);

    this.filtersCR[index].key = filterCR.key;
    this.filtersCR[index].temperature = filterCR.temperature;
    this.filtersCR[index].temperatureDefault = filterCR.temperatureDefault;
    this.filtersCR[index].pressureCO2 = filterCR.pressureCO2;
    this.filtersCR[index].pressureCO2Default = filterCR.pressureCO2Default;
    this.filtersCR[index].sodiumChloride = filterCR.sodiumChloride;
    this.filtersCR[index].sodiumChlorideDefault =
      filterCR.sodiumChlorideDefault;
    this.filtersCR[index].naClPercent = filterCR.naClPercent;
    this.filtersCR[index].waterDensityDefault = filterCR.waterDensityDefault;
    this.filtersCR[index].conventional13Cr = filterCR.conventional13Cr;
    this.filtersCR[index].conventional13Cr = filterCR.conventional13CrDefault;
    this.filtersCR[index].super13Cr = filterCR.super13Cr;
    this.filtersCR[index].super13CrDefault = filterCR.super13CrDefault;
    this.filtersCR[index].modified13Cr = filterCR.modified13Cr;
    this.filtersCR[index].modified13CrDefault = filterCR.modified13CrDefault;
  }

  private updateForm(filterCR: FilterCR) {
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("temperature")
      .setValue(filterCR.temperature, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("temperatureDefault")
      .setValue(filterCR.temperatureDefault, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("pressureCO2")
      .setValue(filterCR.pressureCO2, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("pressureCO2Default")
      .setValue(filterCR.pressureCO2Default, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("sodiumChloride")
      .setValue(filterCR.sodiumChloride, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("sodiumChlorideDefault")
      .setValue(filterCR.sodiumChlorideDefault, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("naClPercent")
      .setValue(filterCR.naClPercent, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("waterDensityDefault")
      .setValue(filterCR.waterDensityDefault, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("conventional13Cr")
      .setValue(filterCR.conventional13Cr, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("conventional13CrDefault")
      .setValue(filterCR.conventional13CrDefault, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("super13Cr")
      .setValue(filterCR.super13Cr, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("super13CrDefault")
      .setValue(filterCR.super13CrDefault, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("modified13Cr")
      .setValue(filterCR.modified13Cr, { emitEvent: false });
    this.fgFiltersCR
      .get("fgFilterCR" + filterCR.key)
      .get("modified13CrDefault")
      .setValue(filterCR.modified13CrDefault, { emitEvent: false });
  }

  private roundToTwoDecimals(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  add(filterToAdd: any) {
    this.CRService.getCalculation(filterToAdd).subscribe((calculations) => {
      let conventional13CrWarning: boolean;
      let super13CrWarning: boolean;
      let modified13CrWarning: boolean;
      calculations.forEach(
        (calculation: {
          key: number;
          corrosionRateCr13: number;
          corrosionRateCr13S: number;
          corrosionRateCr13M: number;
        }) => {
          conventional13CrWarning =
            filterToAdd.temperatureDefault > this.TEMPERATURE_LIMIT_13CR;
          super13CrWarning =
            filterToAdd.temperatureDefault > this.TEMPERATURE_LIMIT_13CRS;
          modified13CrWarning =
            filterToAdd.temperatureDefault > this.TEMPERATURE_LIMIT_13CRM;
            
          let naClLimit = this.NACL_LIMIT_13CR;
          let naClLimitMessage = `${this.NACL_LIMIT_WARNING.message} ( >${this.roundToTwoDecimals(naClLimit)} ${CONCENTRATION_UNITS.NA_CL_PERCENT.symbol})`;

          if (filterToAdd.naClPercent < this.NACL_LIMIT_13CR) {
            naClLimit = this.NACL_LIMITER_FUNCTION_13CR(filterToAdd.temperatureDefault);
            let temperatureLimitConverted =
              this.unitMeasureService.convertUnit(
                filterToAdd.temperatureDefault,
                UnitMeasures.Temperature,
                this.temperatureUnitControl.value,
                false,
                2
              );
              naClLimitMessage = `${this.NACL_LIMIT_WARNING.message} ( >${this.roundToTwoDecimals(naClLimit)} ${CONCENTRATION_UNITS.NA_CL_PERCENT.symbol} for ${temperatureLimitConverted} ${this.unitMeasureService.getUnitSymbol(
                UnitMeasures.Temperature,
                this.temperatureUnitControl.value
              )})`;
          }

          if (!filterToAdd.key) {
            filterToAdd.key = this.filtersCR.length
              ? this.filtersCR[this.filtersCR.length - 1].key + 1
              : this.filtersCR.length + 1;

            this.fgFiltersCR.addControl(
              "fgFilterCR" + filterToAdd.key,
              this.fb.group(
                {
                  id: [null],
                  temperature: [
                    filterToAdd.temperature,
                    [
                      Validators.required,
                      Validators.pattern(
                        REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS
                      ),
                      InterchaneableUnitValidator.delimited(
                        0,
                        this.MAX_TEMPERATURE_LIMIT,
                        this.temperatureUnitControl,
                        UnitMeasures.Temperature,
                        this.unitMeasureService,
                        null,
                        null,
                        2
                      ),
                    ],
                  ],
                  temperatureDefault: [filterToAdd.temperatureDefault],
                  pressureCO2: [
                    filterToAdd.pressureCO2,
                    [
                      Validators.required,
                      Validators.pattern(
                        REGEXP_PATTERNS.SIGNED_NUMBER_2_DECIMALS
                      ),
                      InterchaneableUnitValidator.delimited(
                        0,
                        50,
                        this.pressureCO2UnitControl,
                        UnitMeasures.Pressure,
                        this.unitMeasureService,
                        null,
                        null,
                        2
                      ),
                    ],
                  ],
                  pressureCO2Default: [filterToAdd.pressureCO2Default],
                  sodiumChloride: [
                    filterToAdd.sodiumChloride,
                    [
                      Validators.required,
                      Validators.pattern(
                        REGEXP_PATTERNS.SIGNED_NUMBER_4_DECIMALS
                      ),
                      InterchaneableUnitValidator.minWithoutConvert(
                        0,
                        this.sodiumChlorideUnitControl,
                        UnitMeasures.Concentration,
                        this.unitMeasureService
                      ),
                    ],
                  ],
                  sodiumChlorideDefault: [filterToAdd.sodiumChlorideDefault],
                  naClPercent: [
                    {value: filterToAdd.naClPercent, disabled: true}
                  ],
                  waterDensityDefault: [filterToAdd.waterDensityDefault],
                  conventional13Cr: [
                    conventional13CrWarning
                      ? null
                      : this.unitMeasureService.convertUnit(
                          calculation.corrosionRateCr13,
                          UnitMeasures.Velocity,
                          this.conventional13CrUnitControl.value,
                          false,
                          2
                        ),
                    [
                      WarningValidator.maxValueLimitedByValueWarning(
                        this.TEMPERATURE_LIMIT_13CR,
                        filterToAdd.temperatureDefault,
                        this.TEMPERATURE_LIMIT_WARNING.origin,
                        `${
                          this.TEMPERATURE_LIMIT_WARNING.message
                        } (${this.unitMeasureService.convertUnit(
                          this.TEMPERATURE_LIMIT_13CR,
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value,
                          false,
                          2
                        )} ${this.unitMeasureService.getUnitSymbol(
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value
                        )})`
                      ),
                      WarningValidator.maxValueLimitedByFunctionWarning(
                        this.NACL_LIMITER_FUNCTION_13CR,
                        this.NACL_LIMIT_13CR,
                        filterToAdd.naClPercent,
                        filterToAdd.temperatureDefault,
                        this.NACL_LIMIT_WARNING.origin,
                        naClLimitMessage
                      ),
                    ],
                  ],
                  conventional13CrDefault: [
                    conventional13CrWarning
                      ? null
                      : calculation.corrosionRateCr13,
                  ],
                  super13Cr: [
                    super13CrWarning
                      ? null
                      : this.unitMeasureService.convertUnit(
                          calculation.corrosionRateCr13S,
                          UnitMeasures.Velocity,
                          this.super13CrUnitControl.value,
                          false,
                          2
                        ),
                    [
                      WarningValidator.maxValueLimitedByValueWarning(
                        this.TEMPERATURE_LIMIT_13CRS,
                        filterToAdd.temperature,
                        this.TEMPERATURE_LIMIT_WARNING.origin,
                        `${
                          this.TEMPERATURE_LIMIT_WARNING.message
                        } (${this.unitMeasureService.convertUnit(
                          this.TEMPERATURE_LIMIT_13CR,
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value,
                          false,
                          2
                        )} ${this.unitMeasureService.getUnitSymbol(
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value
                        )})`
                      ),
                      WarningValidator.maxValueLimitedByValueWarning(
                        this.NACL_LIMIT_13CRS,
                        filterToAdd.naClPercent,
                        this.NACL_LIMIT_WARNING.origin,
                        `${this.NACL_LIMIT_WARNING.message} (${this.NACL_LIMIT_13CRS} ${CONCENTRATION_UNITS.NA_CL_PERCENT.symbol})`
                      ),
                    ],
                  ],
                  super13CrDefault: [
                    super13CrWarning ? null : calculation.corrosionRateCr13S,
                  ],
                  modified13Cr: [
                    modified13CrWarning
                      ? null
                      : this.unitMeasureService.convertUnit(
                          calculation.corrosionRateCr13M,
                          UnitMeasures.Velocity,
                          this.modified13CrUnitControl.value,
                          false,
                          2
                        ),
                    [
                      WarningValidator.maxValueLimitedByValueWarning(
                        this.TEMPERATURE_LIMIT_13CRM,
                        filterToAdd.temperature,
                        this.TEMPERATURE_LIMIT_WARNING.origin,
                        `${
                          this.TEMPERATURE_LIMIT_WARNING.message
                        } (${this.unitMeasureService.convertUnit(
                          this.TEMPERATURE_LIMIT_13CRM,
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value,
                          false,
                          2
                        )} ${this.unitMeasureService.getUnitSymbol(
                          UnitMeasures.Temperature,
                          this.temperatureUnitControl.value
                        )})`
                      ),
                      WarningValidator.maxValueLimitedByValueWarning(
                        this.NACL_LIMIT_13CRM,
                        filterToAdd.naClPercent,
                        this.NACL_LIMIT_WARNING.origin,
                        `${this.NACL_LIMIT_WARNING.message} (${this.NACL_LIMIT_13CRM} ${CONCENTRATION_UNITS.NA_CL_PERCENT.symbol})`
                      ),
                    ],
                  ],
                  modified13CrDefault: [
                    modified13CrWarning ? null : calculation.corrosionRateCr13M,
                  ],
                },
                { validators: arrayContainsValidator(this.filtersCR) }
              )
            );

            this.filtersCR.push(filterToAdd);
          } else {
            filterToAdd.conventional13Cr = conventional13CrWarning
              ? null
              : this.unitMeasureService.convertUnit(
                  calculation.corrosionRateCr13,
                  UnitMeasures.Velocity,
                  this.conventional13CrUnitControl.value,
                  false,
                  2
                );
            filterToAdd.conventional13CrDefault = conventional13CrWarning
              ? null
              : calculation.corrosionRateCr13;
            filterToAdd.super13Cr = super13CrWarning
              ? null
              : this.unitMeasureService.convertUnit(
                  calculation.corrosionRateCr13S,
                  UnitMeasures.Velocity,
                  this.super13CrUnitControl.value,
                  false,
                  2
                );
            filterToAdd.super13CrDefault = super13CrWarning
              ? null
              : calculation.corrosionRateCr13S;
            filterToAdd.modified13Cr = modified13CrWarning
              ? null
              : this.unitMeasureService.convertUnit(
                  calculation.corrosionRateCr13M,
                  UnitMeasures.Velocity,
                  this.modified13CrUnitControl.value,
                  false,
                  2
                );
            filterToAdd.modified13CrDefault = modified13CrWarning
              ? null
              : calculation.corrosionRateCr13M;

            this.updateArrayPosition(filterToAdd.key, filterToAdd);

            this.updateForm(filterToAdd);
          }

          let logRequest = [{
            Temperature: filterToAdd.temperatureDefault,
            PressureCo2: filterToAdd.pressureCO2Default,
            Chlorides: filterToAdd.sodiumChlorideDefault,
            WaterDensity: filterToAdd.waterDensityDefault,
            Conventional13Cr: calculation.corrosionRateCr13,
            Super13Cr: calculation.corrosionRateCr13S,
            Modified13Cr: calculation.corrosionRateCr13M,
            CustomerId: this.preSimulationService.getCurrentCustomerId(),
            CountryId: this.preSimulationService.getCurrentCountryId(),
          }]

          this.CRService.registerLog(logRequest).subscribe();

          this.setControls.emit(this.fgFiltersCR);
        }
      );
    });
  }

  delete(key: number) {
    this.deleteArrayPosition(key);

    if (this.fgFiltersCR.get("fgFilterCR" + key).get("id").value)
      this.deletedSimulations.push(
        this.fgFiltersCR.get("fgFilterCR" + key).get("id").value
      );

    this.fgFiltersCR.removeControl("fgFilterCR" + key);

    this.filter.enableOrDisableUnits();

    this.setControls.emit(this.fgFiltersCR);
  }

  clear(filterCR: FilterCR) {
    this.updateArrayPosition(filterCR.key, filterCR);

    this.setControls.emit(this.fgFiltersCR);
  }

  newSimulation() {
    this.filter.resetFgFilterCR();
  }

  formChanges(fgFiltersCR?: FormGroup) {
    this.setControls.emit(fgFiltersCR);
  }

  isTemperatureUnitEnabled(isTemperatureUnitEnabled: number) {
    this.temperatureUnitEnabled = isTemperatureUnitEnabled;
  }

  isPressureCO2UnitEnabled(isPressureCO2UnitEnabled: number) {
    this.pressureCO2UnitEnabled = isPressureCO2UnitEnabled;
  }

  isSodiumChlorideUnitEnabled(isSodiumChlorideUnitEnabled: number) {
    this.sodiumChlorideUnitEnabled = isSodiumChlorideUnitEnabled;
  }

  getFormGroup(key: number) {
    return this.fgFiltersCR.get("fgFilterCR" + key) as FormGroup;
  }
}
