import { Component, Injectable } from '@angular/core';
import { Structure } from 'src/app/models/test/Structure';
import { Table } from 'src/app/models/test/Table';
import { TestService } from 'src/app/services/Test.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
})

@Injectable()
export class TestComponent {

  tablas: Table[] = [];
  structures: Structure[] = [];
  tableName: string = '';
  texto: string = '';

  constructor(public testService: TestService) { }

  ngOnInit() {
    this.getTables();
  }

  getTables() {
    this.testService.getTablas().subscribe(tbl => {
      if (tbl.status.success) {
        this.tablas = tbl.data;
      }
    },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }
  
  verStructure(){
    this.getStructure();
  }

  getStructure() {
    this.testService.getStructure(this.tableName).subscribe(stru => {
      if (stru.status.success) {
        this.structures = stru.data;
      }
    },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }

  getText(){
    this.testService.getTexto().subscribe(m => {
      if (m) {
        this.texto = m.msg;
      }
    },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }
}

