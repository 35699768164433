<div [hidden]="!showModal" class="boundary free-dragging-modal-container">
  <div class="box" appFreeDragging appFreeDraggingHandle>
    <button type="button" class="close" (click)="closeModal()">&times;</button>
    <div class="header-container">
      <div id="rangeTitleContainer">
        <p id="rangesModalTitle"><b> Valid Ranges </b></p>
        <div id="alertIcon" class="swal2-icon swal2-warning swal2-icon-show">
          <div class="swal2-icon-content" style="font-size: 1.75em">!</div>
        </div>
      </div>
    </div>
    <div class="row body-container">
      <div class="col-md-6">
        <p class="sub-title"><b>Pipes</b></p>
        <p>Outside Diameter 2.375 - 20 in</p>
        <p>Nominal Weight 4.6 - 297.1 lb/ft</p>
        <p>Wall Thickness 0.19 - 1.5 in</p>
        <p>Min. Wall Thickness 87.5 - 100 %</p>
      </div>
      <div class="col-md-6">
        <p class="sub-title"><b>Slots Scheme</b></p>
        <p>Max Groove Length at OD <= 5 in</p>
        <p>Max Groove Length at ID <= 5 in</p>
        <p>Max Groove Width at OD < 0.5 in</p>
        <p>Max Groove Width at ID < 0.5 in</p>
        <p>Number of Grooves < 5</p>
        <p>Number of Axes < 180</p>
      </div>
    </div>
  </div>
</div>
