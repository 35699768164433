import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { TreeLevels } from 'src/app/models/tree/TreeLevels';
import { TreeAction } from 'src/app/models/tree/TreeAction';
import { getTreeParent, getTreePath } from '../../helpers/Index';
import { Subscription } from 'rxjs';
import { ActionService } from 'src/app/services/Action.service';
import { HeaderState } from 'src/app/models/header/HeaderState';
import { TreeService } from 'src/app/services/Tree.service';
declare var jQuery: any;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
})
export class TreeComponent implements OnInit, OnChanges {
  title: string = 'Tree';

  //Tree values
  @Input() tree: TreeLevels;
  @Input() onlyUserSimulationsOption: boolean;
  @Output() canActivateActions = new EventEmitter<TreeAction>();
  @ViewChild('treeL1', { read: ElementRef }) myTreeL1: ElementRef<HTMLElement>;
  searchText: string = null;
  onlyMySimulations: boolean = true;
  selected: TreeAction = { id: 0, text: '', level: '', activate: true, action: '', path: '', parents:'', userId: 0, notes: '', visibility: '' };
  actionSubscription: Subscription;
  action: HeaderState = HeaderState.default;

  constructor(public actionService: ActionService, public treeService: TreeService) { }
  
  ngOnInit(): void {
    this.getAction();
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tree != undefined || this.tree != null){
      setTimeout(this.triggerFalseClick, 500);
    }
  }

  onCheckOnlyMySimulations(onlyMySimulations: boolean) {
    this.treeService.changeOnlyMySimulationsValue(onlyMySimulations);
  }

  getAction() {
    this.actionSubscription = this.actionService.currentMessage.subscribe(action => {
      if(action == HeaderState.cancel){
        this.searchText = null;
      }
    });
  }

  triggerFalseClick() {
    jQuery('.dd').nestable();
    jQuery('.dd').nestable('collapseAll');

    jQuery('.dd-item').each(function() {
      let childElementCount = $(this).find('.dd-list').children().length;
      if (childElementCount > 0) {
          if(jQuery(this).find('button[data-action="collapse"]').length === 0 && jQuery(this).find('button[data-action="expand"]').length === 0)
            jQuery(this).prepend('<button data-action="collapse" style="display: none;" type="button">Collapse</button><button data-action="expand" type="button">Expand</button>');
      }
    });

    //TODO: Revisar qué solucionaba esto
    // jQuery('.dd').nestable('expandAll');
    // let el: HTMLElement = this.myTreeL1?.nativeElement;
    // if (el != undefined){
    //   el.click();
    // }
  }

  canActivateButton(event) {
    this.selected.id = event.target.getAttribute('data-id');
    this.selected.text = event.target.getAttribute('data-text');
    this.selected.level = event.target.getAttribute('data-level');
    this.selected.path = getTreePath(this.tree, this.selected);
    this.selected.parents = getTreeParent(this.tree, this.selected);
    this.canActivateActions.emit(this.selected);
  }

}
