import { Component, OnInit } from '@angular/core';
import {
    OpenIdConfiguration
  } from 'angular-auth-oidc-client';
import { LoginService } from 'src/app/pages/auth/login/Login.service';
import { HomeComponent } from 'src/app/pages/home/Home.component';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styles: []
})
export default class MenuComponent  {
    
    isAdmin: boolean = true;
    configurations: OpenIdConfiguration[];

    public DataUserQa: IDataUser[] =
    [
      {
        "groupid": "85a19cfa-f9f3-4b2d-8811-7fd02b231d86",
        "module": "Customer",
        "rol": "USER"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Customer",
        "rol": "ADMIN"
      },
      {
        "groupid": "f290ac58-4f98-462e-9556-0740e1dc335a",
        "module": "ISO15156",
        "rol": "USER"
      },
      {
        "groupid": "685a34f1-bf1a-49b3-9d92-7e52b51de343",
        "module": "ISO15156",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "ISO15156",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "13Cr",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Casing Wear",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "CorrisonClass",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "PipeRating",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Settings",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "SlottedPipe",
        "rol": "ADMIN"
      },
      {
        "groupid": "ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a",
        "module": "Statitics",
        "rol": "ADMIN"
      },
      {
        "groupid": "972950e5-5762-4bf4-9c7b-1a08de151744",
        "module": "13Cr",
        "rol": "USER"
      },
      {
        "groupid": "c9376fd6-afdc-4e0c-a663-cd1768907af5",
        "module": "Casing Wear",
        "rol": "USER"
      },
      {
        "groupid": "e79183b7-aa78-4782-951e-c2bb338d4370",
        "module": "CorrisonClass",
        "rol": "USER"
      },
      {
        "groupid": "d98c8f17-6777-4c8a-8e2f-6cb374cd9281",
        "module": "PipeRating",
        "rol": "USER"
      },
      {
        "groupid": "029f9b47-e441-45b8-9f6f-3b643be7f542",
        "module": "SlottedPipe",
        "rol": "USER"
      },
      {
        "groupid": "4a7db2e1-144c-4ef5-b0a7-44d66adc0f1e",
        "module": "13Cr",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "8f0411f8-a986-4a32-b91a-518225fc842f",
        "module": "Casing Wear",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "f38c5907-130c-4f07-a9b9-f89e23e4b9e3",
        "module": "CorrisonClass",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "d5d9aaac-5f7f-4e13-a923-08f096ad3262",
        "module": "PipeRating",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "a82332f8-5811-46b0-b8dd-a07643118e93",
        "module": "SlottedPipe",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "80ec66e2-2c30-47e8-948d-8462752a37bb",
        "module": "Statitics",
        "rol": "SUPER_USER"
      }

    ]
    ;

    public DataUserPr: IDataUser[] =
    [
      {
        "groupid": "b22a02d6-f7e7-462a-934f-dcb94ec5667b",
        "module": "Customer",
        "rol": "USER"
      },
      {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Customer",
        "rol": "ADMIN"
      },
      {
        "groupid": "45ebf0d3-76fa-4c37-ab05-d0b13b9e73a9",
        "module": "ISO15156",
        "rol": "USER"
      },
      {
        "groupid": "1d82db37-4e3e-4481-8e5f-f24e79115609",
        "module": "ISO15156",
        "rol": "SUPER_USER"
      },
      {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "ISO15156",
        "rol": "ADMIN"
      },
      {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "13Cr",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Casing Wear",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "CorrisonClass",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "PipeRating",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Settings",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "SlottedPipe",
        "rol": "ADMIN"
       },
       {
        "groupid": "47796373-e0b2-478e-82da-d4f560f84fc6",
        "module": "Statitics",
        "rol": "ADMIN"
       },
       {
        "groupid": "8eb336c3-3df1-4ee1-a6c7-67601138a427",
        "module": "13Cr",
        "rol": "USER"
       },
       {
        "groupid": "a88bb902-a44f-44b8-a28a-702ff5cef49a",
        "module": "Casing Wear",
        "rol": "USER"
       },
       {
        "groupid": "c5e21c82-b6b6-441d-bf1a-dd0600c114e3",
        "module": "CorrisonClass",
        "rol": "USER"
       },
       {
        "groupid": "40ef9927-bcb0-4635-a1f0-2f5a6334bb66",
        "module": "PipeRating",
        "rol": "USER"
       },
       {
        "groupid": "bb3d5168-14c4-494f-856a-e65d609fdecc",
        "module": "SlottedPipe",
        "rol": "USER"
       },
       {
        "groupid": "cf183043-c27d-4969-8c3b-84672efe7933",
        "module": "13Cr",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "f2a2bdbf-97ad-41e5-8ec4-d3d18d49f743",
        "module": "Casing Wear",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "cee15557-5d20-4bd9-ae09-7bb69ce6c0f8",
        "module": "CorrisonClass",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "9a9112ef-40c8-4fcf-9fd0-c889b01e5c4c",
        "module": "PipeRating",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "b048f6bb-9a8e-41b8-a39e-f2c888459824",
        "module": "SlottedPipe",
        "rol": "SUPER_USER"
       },
       {
        "groupid": "2590262e-90bf-4dfa-9091-7452702f7903",
        "module": "Statitics",
        "rol": "SUPER_USER"
       }
    ]
    ;
    public ojbAccess : IDataUser;
    public TestGroupUser : string[] = ["e79183b7-aa78-4782-951e-c2bb338d4370","972950e5-5762-4bf4-9c7b-1a08de151744","029f9b47-e441-45b8-9f6f-3b643be7f542","tesprueba"];
    public TestGroupSuperUser : string[] = ["f38c5907-130c-4f07-a9b9-f89e23e4b9e3","8f0411f8-a986-4a32-b91a-518225fc842f","tesprueba","80ec66e2-2c30-47e8-948d-8462752a37bb"];
    public TestGroupNoAuth : string[] = ["otroGrupo","tespruebagrupo"];
    public TestGroupAdmin : string[] = ["ff73b7d3-53e1-4cf6-aa55-880d4e7bbe6a","tesprueba"];
    public AccessUser: IDataUser[] = [];

    //first
    constructor(
        private loginService: LoginService
        )
    {
        // service.getLogin().subscribe((e: boolean) => this.isAdmin = e);


    }



    //second
    ngOnInit() {
 

        this.configurations = this.loginService.currentConfig();


        
        let groupsData = sessionStorage.getItem('0-'+environment.auth.clientID);
        const obj = JSON.parse(groupsData);

        if(obj['userData'] != undefined )
        {

            if (`${environment.production}` == 'true'){
            this.DataUserPr.forEach((key : any, val: any) => {

                obj['userData']['groups'].forEach((keys : any, vals :any) => { //grupos del autenticado


                    if(key['groupid'] == keys)
                    {

                        let obj:IDataUser = {
                            "groupid": key['groupid'],
                            "module": key['module'],
                            "rol": key['rol']
                        }
                        this.AccessUser.push(obj);
                    }
                
                })
            });
        }else {
            this.DataUserQa.forEach((key : any, val: any) => {

                obj['userData']['groups'].forEach((keys : any, vals :any) => { //grupos del autenticado


                    if(key['groupid'] == keys)
                    {

                        let obj:IDataUser = {
                            "groupid": key['groupid'],
                            "module": key['module'],
                            "rol": key['rol']
                        }
                        this.AccessUser.push(obj);
                    }
                })
            });
        }


            this.AccessUser.forEach((key : any, val: any) => 
            {
                // let day : number = 4;

                switch (key['module']) {
                    case "CorrisonClass":                    
                        this.urls.find(item => item.href == "/corrosionclas").visible = true;
                        break;
                    case "Casing Wear":
                        this.urls.find(item => item.href == "/casingwear").visible = true;
                        break;
                    case "13Cr":
                        this.urls.find(item => item.href == "/corrosionrate").visible = true;
                        break;
                    case "PipeRating":
                        this.urls.find(item => item.href == "/advancedpipe").visible = true;
                        break;
                    case "SlottedPipe":
                        this.urls.find(item => item.href == "/slottedperforatedpipe").visible = true;
                        break;
                    case "Statitics":
                        this.urls.find(item => item.href == "/statistics").visible = true;
                        break;       
                    case "Settings":
                        this.urls.find(item => item.href == "/settings").visible = true;
                        break;              
                    case "Customer":
                        this.urls.find(item => item.href == "/settings").visible = true;
                        this.urls.find(item => item.href == "/settings").subItems.find(item => item.href == "/customermgr").visible = true;
                        break;
                    case "ISO15156":
                      this.urls.find(item => item.href == "/isoguidelines").visible = true;
                      break;               
                    default:
                        break;
                }


            });
        }
    }

    //asignar valores booleano para saber si corresponde mostrar o no 
    //segun el rol que tiene.

    public urls = [
        { 
            href : "/slottedperforatedpipe",
            icon: '',
            image: '../../../../assets/img/ico/Slotted-32.ico',
            text: 'Slotted / Pre-Perforated Pipe',
            visible: false,
            sectionTitle:'Models',//moduleSlotted
            subItems: [{
              href: '/slotted',
              icon: '',
              image: '../../../../assets/img/ico/SlottedNEW-32.ico',
              text: 'Slotted Model',
              visible: true,
              subItems: []
          },
          {
              href: '/perforated',
              icon: '',
              image: '../../../../assets/img/ico/PerforatedNEW-32.ico',
              text: 'Pre-Perforated Model',
              visible: true,
              subItems: []
          }]
        },
        {
            href: '/casingwear',
            icon: '',
            image: '../../../../assets/img/ico/CasingWear-32.ico',
            text: 'Casing Wear',
            visible: false,//moduleCasingwear
            subItems: []
        },
        {
            href: '/advancedpipe',
            icon: '',
            image: '../../../../assets/img/ico/AdvancedCalc-32.ico',
            text: 'Advanced Pipe Ratings',
            visible: false ,//moduleAdvancedpipe
            subItems: []
        },
        {
            href: '/corrosionrate',
            icon: '',
            image: '../../../../assets/img/ico/Corrosion13Cr-32.ico',
            text: '13Cr Corrosion Rate',
            visible: false,//moduleCorrosionrate
            subItems: []
        },
        {
            href: '/corrosionclas',
            icon: '',
            image: '../../../../assets/img/ico/corrosionClas-32.ico',
            text: 'Corrosion CLAS',
            visible: false,//moduleCorrosionclas
            subItems: []
        },
        {
          href: '/isoguidelines',
          icon: '',
          image: '../../../../assets/img/ico/MatSelGuidelines-32.ico',
          text: 'ISO 15156 Guidelines',
          visible: false,
          subItems: []
        },
        {
            href: '/statistics',
            icon: 'graph2',
            image: '',
            text: 'Statistics',
            visible: false,//moduleStatistics
            subItems: []
        },
        {
        href: '/settings',
        icon: 'settings',
        image: '',
        text: 'Settings',
        visible: false,
        sectionTitle:'Settings',//moduleSettings
        subItems: [{
            href: '/usermgr',
            icon: 'users',
            image: '',
            text: 'User Mgr',
            visible: true,
            subItems: []
        },
        {
          href: '/customermgr',
          icon: 'portfolio',
          image: '',
          text: 'Customer Mgr',
          visible: false,
          subItems: []
        },
        {
            href: '/appmgr',
            icon: 'lock',
            image: '',
            text: 'App Mgr',
            visible: true,
            subItems: []
        }]
    }]

    logout(configId: string) {
        this.loginService.logout(configId);
      }

}

export interface IDataUsers  {
    val: IDataUser [];
  }
  export interface IDataUser {
    groupid: string,
    module: string,
    rol: string
  }