export class CorrosionClas {
    id: number
    user_id: number;
    tree_id: number;
    name: string
    department: string
    heart_treatment: number;
    temperature: number;
    total_pressure: number;
    cO2_per: number;
    h2S_per: number;
    external_pipe_diam: number;
    pipe_wall_thickness: number;
    c_per: number;
    cr_per: number;
    oil_production: number;
    water_production: number;
    watercut: number;
    liquid_hold_up: number;
    deviation: number;
    api_oil_gravity: number;
    liquid_velocity: number;
    na: number;
    k: number;
    ca: number;
    mg: number;
    ba: number;
    sr: number;
    cl: number;
    water_density: number;
    hcO3: number;
    sO4: number;
    fe: number;
    acetate: number;
    dissolvedSolids: number;
    wbreak: number;
    visibility: string
    notes: string
    rev: number;
    ph_pure_H2O_CO2: number;
    ph_solution: number;
    reaction_at_surface: number;
    transport: number;
    oil_protection_factor_foil: number;
    oil_protection_factor_foil_calc: number;
    oil_protection_factor_foil_restrwc: number;
    scale_protection_factor_fscale: number;
    scale_protection_factor_tsc: number;
    chromium_factor: number;
    carbon_factor: number;
    h2S_ppm: number;
    hS_ppm: number;
    h2S_mol_liter: number;
    hS_mol_liter: number;
    fH2S: number;
    corrected_by_composition: number;
    corrected_by_oil: number;
    corrected_by_scale: number;
    corrected_by_scales_CO2_or_H2S: number;
    customerId: number;
    countryId: number;
}

export class CorrosionClasPdf {
    printOnlyFirstPage: boolean;
    requestedBy: string;
    reviewedBy: string;
    authorizedBy: string;
    SDCaseNumber: string;
    createdBy: string;
    id: number
    user_id: number;
    tree_id: number;
    name: string
    department: string
    heart_treatment: string;
    temperature: number;
    total_pressure: number;
    cO2_per: number;
    h2S_per: number;
    external_pipe_diam: number;
    pipe_wall_thickness: number;
    c_per: number;
    cr_per: number;
    oil_production: number;
    water_production: number;
    watercut: number;
    liquid_hold_up: number;
    deviation: number;
    api_oil_gravity: number;
    liquid_velocity: number;
    na: number;
    k: number;
    ca: number;
    mg: number;
    ba: number;
    sr: number;
    cl: number;
    water_density: number;
    hcO3: number;
    sO4: number;
    fe: number;
    acetate: number;
    dissolvedSolids: number;
    wbreak: number;
    visibility: string
    notes: string
    rev: number;
    ph_pure_H2O_CO2: number;
    ph_solution: number;
    reaction_at_surface: number;
    transport: number;
    oil_protection_factor_foil: number;
    oil_protection_factor_foil_calc: number;
    oil_protection_factor_foil_restrwc: number;
    scale_protection_factor_fscale: number;
    scale_protection_factor_tsc: number;
    chromium_factor: number;
    carbon_factor: number;
    h2S_ppm: number;
    hS_ppm: number;
    h2S_mol_liter: number;
    hS_mol_liter: number;
    fH2S: number;
    corrected_by_composition: number;
    corrected_by_oil: number;
    corrected_by_scale: number;
    corrected_by_scales_CO2_or_H2S: number;
    pipeInnerDiameter: number;
    maximumWaterInOilWbreak: number;
    temperatureUnit: string;
    totalPressureUnit: string;
    CO2percUnit: string;
    H2SpercUnit: string;
    pipeODUnit: string;
    pipeIDUnit: string;
    pipeWTUnit: string;
    oilProductionUnit: string;
    waterProductionUnit: string;
    APIoilGravityUnit: string;
    waterDensityUnit: string;
    ClUnit: string;
}