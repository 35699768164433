<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">Pre-Perforated Model</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.PERFORATED.name }}</li>
      </ol>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div [ngStyle]="{'border': !isPreSimulationFormValid ? 'none' : ''}" class="panel-heading">
              <div id="simulationSelectorPanel">
                <div id="simulationPanel">
                  <div *ngIf="simulationName == ''; else elseBlock">
                    Simulation
                  </div>
                  <ng-template #elseBlock>
                    <div>
                      <strong> Simulation: {{ simulationName }} </strong>
                    </div></ng-template
                  >
                </div>
                <div>
                  <div>
                    <div id="unitSystemPanel" class="form-group">
                      <label id="unitSystemLabel">Unit System</label>
                      <div class="am-radio inline">
                        <input
                          id="rdMetric"
                          type="radio"
                          name="radioOption"
                          class="input-xs"
                          value="metric"
                          disabled
                        />
                        <label for="rdMetric" class="input-xs">Metric</label>
                      </div>
                      <div class="am-radio inline">
                        <input
                          id="rdImperial"
                          type="radio"
                          checked="true"
                          name="radioOption"
                          class="input-xs"
                          value="imperial"
                        />
                        <label for="rdImperial" class="input-xs"
                          >Imperial</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-pre-simulation #preSimulation></app-pre-simulation>
            </div>
            <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="range-container">
              Complete the following fields: Model Validity 
              <span id="rangeModal" (click)="showRangeModal()" ><b>Ranges</b></span>
            </div>
            <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="panel-body">
              <form [formGroup]="fgPerforatedPipe" (ngSubmit)="onSubmit()">
                <div class="row">
                  <app-perforated-type [fgPerforatedPipe]="fgPerforatedPipe">
                  </app-perforated-type>
                  <app-perforated-pipes
                    [fgPerforatedPipe]="fgPerforatedPipe"
                  ></app-perforated-pipes>
                </div>
                <div class="row">
                  <app-perforated-holes-scheme
                    [fgPerforatedPipe]="fgPerforatedPipe"
                  ></app-perforated-holes-scheme>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <app-perforated-lengths
                      [fgPerforatedPipe]="fgPerforatedPipe"
                    ></app-perforated-lengths>
                  </div>
                  <div class="col-md-4">
                    <app-perforated-notes
                      [fgPerforatedPipe]="fgPerforatedPipe"
                    ></app-perforated-notes>
                  </div>
                  <div class="col-md-4">
                    <app-perforated-attachments
                      [fgPerforatedPipe]="fgPerforatedPipe"
                    ></app-perforated-attachments>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <img
                      src="../../../../../assets/img/perforated/FullPipeLengths.png"
                      style="width: 100%"
                    />
                    <p style="text-align: center;">Fig. 5<br>Full pipe</p>
                  </div>
                  <div class="col-md-8">
                    <img *ngIf="dynamicImage" [src]="dynamicImage" style="width: 100%" />
                  </div>
                </div>
                <div class="row row-styles">
                  <div class="col-md-12" style="text-align: right">
                    <button
                      *ngIf="showDataCheck"
                      title="Data Check"
                      type="button"
                      class="datacheck"
                      (click)="preDataCheck()"
                      tabindex="-1"
                    >
                      Data Check
                    </button>
                    <button
                      #dataCheckButton
                      data-toggle="modal"
                      data-target="#dataCheckModal"
                      data-placement="right"
                      data-tooltip
                      *ngIf="showDataCheck"
                      type="button"
                      class="d-none"
                      tabindex="-1"
                    >
                      Check
                    </button>
                  </div>
                  <div
                    *ngIf="showPipeExists"
                    class="col-md-12"
                    id="inputContainer"
                  >
                    <div
                      style="margin-right: 1%"
                      [ngClass]="{
                        disabled: this.fgPerforatedPipe.get('pipeExists').disabled
                      }"
                    >
                      <input
                        type="checkbox"
                        formControlName="pipeExists"
                        [ngClass]="{
                          'disabled-section':
                            this.fgPerforatedPipe.get('pipeExists').disabled
                        }"
                      />
                    </div>
                    <p>I have checked that pipe exists</p>
                  </div>
                  <div class="col-md-12" id="submitRow">
                    <p *ngIf="showPipeExists" id="checkParagraph">
                      To enable simulation check flag: "I have checked that pipe
                      exists"
                    </p>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      [disabled]="fgPerforatedPipe.invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <app-perforated-results></app-perforated-results>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-perforated-ranges-modal></app-perforated-ranges-modal>
</div>

<app-data-check-modal></app-data-check-modal>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
