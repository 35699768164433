import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CorrosionClas } from 'src/app/models/corrosionclas/Index';
import { HeaderState } from 'src/app/models/header/HeaderState';
import { TreeAction } from 'src/app/models/tree/TreeAction';
import { TreeLevels } from 'src/app/models/tree/TreeLevels';
import { CorrosionclasService } from 'src/app/services/Corrosionclas.service';
import { TreeService } from 'src/app/services/Tree.service';
import { activateTreeItem, createTree } from '../../helpers/Index';
import Swal from 'sweetalert2';
import { TreeNode } from 'src/app/models/tree/TreeNode';
import { CorrosionRateService } from 'src/app/modules/CorrosionRate/services/corrosion-rate.service';
import { DataService } from 'src/app/services/Data.service';
import { SlottedPipeService } from 'src/app/services/slotted-pipe/slotted-pipe.service';
import { MatSelGuidelinesService } from 'src/app/services/mat-sel-guidelines/mat-sel-guidelines.service';
declare var jQuery: any;

@Component({
  selector: 'app-tree-delete',
  templateUrl: './tree.delete.component.html',
})
export class TreeDeleteComponent implements OnInit {
  title: string = 'Tree-Delete';

  @Output() canActivateActionsMenu = new EventEmitter<TreeAction>();
  @Output() treeLevels = new EventEmitter<TreeLevels>();
  @Input() userId: number = 0;
  @Input() module: string = '';
  tree: TreeLevels;
  valueNote: string = '';
  nameUser: string;
  userNode: TreeNode;
  constructor(public treeService: TreeService, public corrosionService: CorrosionclasService
    , public matSelGuidelinesService: MatSelGuidelinesService, public corrosionRateService: CorrosionRateService,public dataService: DataService, public slottedPipeService: SlottedPipeService) { }
  ngOnInit(): void {
    this.dataService.currentUserIdSource.subscribe(userId => (this.userId= userId));
    this.dataService.currentModuloSource.subscribe(module => (this.module= module));
    this.dataService.currentNameSurnameSource.subscribe(name => (this.nameUser= name));
    this.ValidaIngresoUsuario();
  }

  // ngAfterViewInit() {
  //   jQuery('.dd').nestable('colapseAll');
  //   jQuery('.dd').nestable('expandAll');
  // }

  getTree(userId: number,module: string) {
    this.treeService.getTreeByUserId(userId,module).subscribe(levels => {
      if (levels.status.success) {
        this.tree = createTree(levels.data);
      }
    },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }

  canActivateActions(selected: TreeAction) {
    // jQuery('.dd').nestable('colapseAll');
    // jQuery('.dd').nestable('expandAll');
    selected.userId = this.userId;
    selected.action = HeaderState.delete;
    selected = activateTreeItem(this.tree, HeaderState.delete, selected);
    this.getSimulation(selected);
    this.canActivateActionsMenu.emit(selected);
    this.treeLevels.emit(this.tree);
  }

  getSimulation(selected: TreeAction) {
    if (selected.level == 'simulation' && selected.id > 0) {
      if(this.module == 'corrosionclas'){
        this.corrosionService.getSimulationByTreeId(selected.id).subscribe(simDB => {
          if (simDB.status.success == true) {
            let simulation = new CorrosionClas();
            simulation = simDB.data;
            this.valueNote = simulation.notes;
            jQuery('#visibilitySelect option:selected').text(simulation.visibility);
          } else {
            this.valueNote = '';
            jQuery('#visibilitySelect option:selected').text('Select');
          }
        },
          err => {
            Swal.fire({
              title: 'An error occurred',
              icon: 'error',
              confirmButtonColor: '#009',
              cancelButtonColor: '#aaa',
            });
          });
      }
      else if(this.module == 'corrosionrate'){
        this.corrosionRateService.loadSimulation(selected.id).subscribe(simDB => {
          this.valueNote = simDB.notes==null? '':simDB.notes;
          jQuery('#visibilitySelect option:selected').text(simDB.visibility==null?'Select':simDB.visibility);
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
      }
      else if(this.module == 'slottedPipe'){
        this.slottedPipeService.loadSimulation(selected.id).subscribe(simulation => {
          this.valueNote = simulation.tree_notes;
          simulation.visibility ? jQuery('#visibilitySelect option:selected').text(simulation.visibility) : jQuery('#visibilitySelect option:selected').text('Select');
          this.dataService.changeIdSimulationClickedOnTreeSource(simulation.id);
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
      }
      else if(this.module == 'matSelGuidelines'){
        this.matSelGuidelinesService.loadSimulation(selected.id).subscribe(simulation => {
          this.valueNote = simulation.matSelGuideline?.tree_notes;
          simulation.matSelGuideline?.visibility ? jQuery('#visibilitySelect option:selected').text(simulation.matSelGuideline?.visibility) : jQuery('#visibilitySelect option:selected').text('Select');
          this.dataService.changeIdSimulationClickedOnTreeSource(simulation.matSelGuideline?.tree_id);
      },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
    }
      else {
        this.valueNote = '';
      }
    } 
  }


  ValidaIngresoUsuario() {
    //validar si existe, si no se inserta en la tabla TREE.    
    this.treeService.getTreeByUserId(this.userId,this.module).subscribe(
      levels => {

        this.userNode = levels['data'].find(item => item.item_level == 'user');


        if (levels['data'] == null || levels['data'] == undefined || levels['data'].length < 1) {

          //no existe en la tabla tree, insertar user
          let newNode: TreeNode = {
            id: 0,
            user_id: this.userId,
            item_id: 1,
            item_name: this.nameUser,
            item_level: 'user',
            parent_id: null,
            module: this.module,
            last_update_date: null,
            creation_date: null,
            deletion_date: null,
            deleted_flag: false
          };
          //graba el row tree
          this.treeService.postNodeTree(newNode).subscribe(
            node => {
              if (node.status.success) {
                this.getTree(this.userId,this.module  );
              }
            });
        } else {
          //actualiza el nombre en la tabla tree con el nombre de la tabla user

          this.treeService.getNodeTreeById(this.userNode['id']).subscribe(node => {
            if (node.status.success) {
              let newNode: TreeNode = node.data;
              newNode.item_name = this.nameUser;
      
              this.treeService.putNodeTree(this.userNode['id'], newNode).subscribe(node => {
                this.getTree(this.userId,this.module);
              });
            }
          });
        }
      });
  }
}
