import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms"
import { ActionService } from "src/app/services/Action.service";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-casingwearpdf',
  templateUrl: 'casingwearpdf.component.html',
  styleUrls: ['./casingwearpdf.component.scss']
})
export class CasingwearpdfComponent implements OnInit, OnDestroy {

  isAdmin : boolean;

  title = "Casingwear CLAS";
  isOutputVisible: boolean = false;

  table_data = [
    { 'configuration': 'OD x "WT" TjOD: OD GR GRADE ksi', 'load': 'Busrst', 'col0': '100%', 'col10': '91%', 'col20': '81%', 'col30': '71%', 'col40': '61%', 'col50': '61%', 'col60': '51%', 'col70': '41%', 'col80': '31%', 'col90': '21%', 'col100': '11%' },
    { 'configuration': 'OD x "WT" TjOD: OD GR GRADE ksi', 'load': 'Collapse', 'col0': '100%', 'col10': '91%', 'col20': '81%', 'col30': '71%', 'col40': '61%', 'col50': '61%', 'col60': '51%', 'col70': '41%', 'col80': '31%', 'col90': '21%', 'col100': '11%' },
    { 'configuration': 'OD x "WT" TjOD: OD GR GRADE ksi', 'load': 'Busrst', 'col0': '100%', 'col10': '91%', 'col20': '81%', 'col30': '71%', 'col40': '61%', 'col50': '61%', 'col60': '51%', 'col70': '41%', 'col80': '31%', 'col90': '21%', 'col100': '11%' },
    { 'configuration': 'OD x "WT" TjOD: OD GR GRADE ksi', 'load': 'Collapse', 'col0': '100%', 'col10': '91%', 'col20': '81%', 'col30': '71%', 'col40': '61%', 'col50': '61%', 'col60': '51%', 'col70': '41%', 'col80': '31%', 'col90': '21%', 'col100': '11%' }
  ];


  varFecha:number = Date.now();
  constructor(
    public actionService: ActionService
    ){
    this.isAdmin = true;



  }



  ngOnInit() {


      
  }
 
  
  ngOnDestroy() {

  }

}