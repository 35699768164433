export class TreeLevels {
  users: TreeUser[];
}

export class TreeUser {
  id: number;
  userId: number;
  userName: string;
  accion: string;
  customers: TreeCustomer[];
  visible: boolean;
}

export class TreeCustomer {
  id: number;
  parentId: number;
  customerId: number;
  customerName: string;
  accion: string;
  regions: TreeRegion[];
  visible: boolean;
}

export class TreeRegion {
  id: number;
  parentId: number;
  regionId: number;
  regionName: string;
  accion: string;
  projects: TreeProject[];
  visible: boolean;
}

export class TreeProject {
  id: number;
  parentId: number;
  projectId: number;
  projectName: string;
  accion: string;
  simulations: TreeSimulation[];
  visible: boolean;
}

export class TreeSimulation {
  id: number;
  parentId: number;
  simulationId: number;
  simulationName: string;
  visibility: string;
  notes: string;
  accion: string;
  visible: boolean = true;
}
