import { LengthUnits } from "../enums/length-units.enum";

export const LENGTH_UNITS = {
  MM: {
    name: "Millimeters",
    symbol: "mm",
    enum: LengthUnits.Mm,
    fractionDigits: 4,
  },
  IN: {
    name: "Inches",
    symbol: "in",
    enum: LengthUnits.In,
    fractionDigits: 4,
  },
};

export const LENGTH_UNITS_ARRAY: LengthUnit[] = [
  LENGTH_UNITS.MM,
  LENGTH_UNITS.IN,
];

class LengthUnit {
  name: string;
  symbol: string;
  enum: LengthUnits;
  fractionDigits: number;
}
