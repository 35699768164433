import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor() {}

  getHttpParamsFromObject(
    object: any,
    httpParams: HttpParams,
    startingKey?: string
  ) {
    Object.keys(object).forEach((key) => {
      if (
        object[key] &&
        typeof object[key] === "object" &&
        !(object[key] instanceof Array) &&
        Object.keys(object[key]).length > 0
      )
        httpParams = this.getHttpParamsFromObject(
          object[key],
          httpParams,
          startingKey ? `${startingKey}.${key}` : key
        );
      else {
        if (object[key] && object[key] instanceof Array) {
          object[key].forEach((arrayKey) => {
            httpParams = httpParams.append(
              startingKey ? `${startingKey}.${key}` : key,
              arrayKey
            );
          });
        } else if (object[key] || object[key] === 0 || object[key] === false)
          httpParams = httpParams.append(
            startingKey ? `${startingKey}.${key}` : key,
            object[key]
          );
      }
    });

    return httpParams;
  }

  getFormDataFromObject(object: any, formData: FormData, startingKey?: string) {
    Object.keys(object).forEach((key) => {
      if (
        object[key] &&
        typeof object[key] === "object" &&
        !(object[key] instanceof Array) &&
        Object.keys(object[key]).length > 0
      )
        this.getFormDataFromObject(
          object[key],
          formData,
          startingKey ? `${startingKey}.${key}` : key
        );
      else {
        if (object[key] && object[key] instanceof Array) {
          object[key].forEach((arrayKey, index) => {
            if (
              arrayKey &&
              typeof arrayKey === "object" &&
              !(arrayKey instanceof Array) &&
              Object.keys(arrayKey).length > 0
            )
              this.getFormDataFromObject(
                arrayKey,
                formData,
                startingKey
                  ? `${startingKey}.${key}[${index}]`
                  : `${key}[${index}]`
              );
            else
              formData.append(
                startingKey ? `${startingKey}.${key}` : key,
                arrayKey
              );
          });
        } else if (object[key] || object[key] === 0 || object[key] === false)
          formData.append(
            startingKey ? `${startingKey}.${key}` : key,
            object[key]
          );
      }
    });

    return formData;
  }

  buildUrl(module: string, version: string, resource: string, action: string, apiEndpoint = environment.apiEndpoint): string {
    return `${apiEndpoint}${module}/${version}/${resource}/${action}`;
  }

  handleError(error: HttpErrorResponse) {
    if (isDevMode())
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `,
          error.error
        );
      }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }

  handleErrorMessage(errorMessage: string) {
    if (isDevMode()) console.error("An error occurred:", errorMessage);

    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }
}
