import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { TreeAction } from '../models/tree/Index';
import { SessionData } from '../models/user/User';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private userId: number;
    public data: TreeAction = { id: 0, text: '', level: '', activate: false, action: '', path: '', parents: '', userId: 0, notes: '', visibility: '' };
    public extraData: any;
    public subject = new Subject<any>();
    private source = new BehaviorSubject(this.data);
    currentData = this.source.asObservable();

    changeData(data: TreeAction,extraData?: any) {
        this.extraData = extraData;
        this.source.next(data)
    }

    public idSimulationClickedOnTree: any = [];
    private idSimulationClickedOnTreeSource = new  BehaviorSubject(this.idSimulationClickedOnTree);
    currentIdSimulationClickedOnTreeSource = this.idSimulationClickedOnTreeSource.asObservable();
    changeIdSimulationClickedOnTreeSource(idSimulationClickedOnTree: number) {
        this.idSimulationClickedOnTreeSource.next(idSimulationClickedOnTree)
    }

    public userIds: any = [];
    private userIdSource = new  BehaviorSubject(this.userIds);
    currentUserIdSource = this.userIdSource.asObservable();
    changeUserId(userId: number) {
        this.userId = userId;
        this.userIdSource.next(userId);
    }

    public department: any = [];
    private departmentSource = new  BehaviorSubject(this.department);
    currentDepartmentSource = this.departmentSource.asObservable();
    changeDepartment(department: string) {
        this.departmentSource.next(department)
    }    

    public nameSurname: any = [];
    private nameSurnameSource = new  BehaviorSubject(this.nameSurname);
    currentNameSurnameSource = this.nameSurnameSource.asObservable();
    changeNameSurname(nameSurname: string) {
        this.nameSurnameSource.next(nameSurname)
    }    

    public rol: any = [];
    private rolSource = new  BehaviorSubject(this.rol);
    currentRolSource = this.rolSource.asObservable();
    changeRol(rol: string) {
        this.rolSource.next(rol)
    }    

    public modulo: any = [];
    private moduloSource = new  BehaviorSubject(this.modulo);
    currentModuloSource = this.moduloSource.asObservable();
    changeModulo(modulo: string) {
        this.moduloSource.next(modulo)
    }   

    
    public validateReportCW: any = [];
    private validateReportCWSource = new  BehaviorSubject(this.validateReportCW);
    currentValidateReportCWSource = this.validateReportCWSource.asObservable();
    changeValidateReportCW(validateReportCW: boolean) {
        this.validateReportCWSource.next(validateReportCW)
    }   

    public validateReportCC: any = [];
    private validateReportCCSource = new BehaviorSubject(this.validateReportCC);
    currentValidateReportCCSource = this.validateReportCCSource.asObservable();
    changeValidateReportCC(validateReportCC: boolean){
        this.validateReportCCSource.next(validateReportCC);
    }

    public validateReportCR: any = [];
    private validateReportCRSource = new BehaviorSubject(this.validateReportCR);
    currentValidateReportCRSource = this.validateReportCRSource.asObservable();
    changeValidateReportCR(validateReportCR: boolean){
        this.validateReportCRSource.next(validateReportCR);
    }

    public validateReportSP: any = [];
    private validateReportSPSource = new BehaviorSubject(this.validateReportSP);
    currentValidateReportSPSource = this.validateReportSPSource.asObservable();
    changeValidateReportSP(validateReportSP: boolean){
        this.validateReportSPSource.next(validateReportSP);
    }

    public validateReportPR: any = [];
    private validateReportPRSource = new BehaviorSubject(this.validateReportPR);
    currentValidateReportPRSource = this.validateReportPRSource.asObservable();
    changeValidateReportPR(validateReportPR: boolean){
        this.validateReportPRSource.next(validateReportPR);
    }

    public validateReportMSG: any = [];
    private validateReportMSGSource = new BehaviorSubject(this.validateReportMSG);
    currentValidateReportMSGSource = this.validateReportMSGSource.asObservable();
    changeValidateReportMSG(validateReportMSG: boolean){
        this.validateReportMSGSource.next(validateReportMSG);
    }

    public simulationIdNameSource$: Subject<string> = new Subject<string>();
    simulationIdName$ = this.simulationIdNameSource$.asObservable();

    setSimulationIdName(simulationId: string) {
    this.simulationIdNameSource$.next(simulationId);
    }
    
    getUserId(): number {
        return this.userId;
    }
}