import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { APIResponse } from '../http/models/api-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { Country } from 'src/app/shared/components/pre-simulation/models/pre-simulation';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private readonly MODULE: string = "customer";
  private readonly VERSION: string = "v1";
  private readonly RESOURCE: string = "Country";
  private readonly GET_PRE_SIMULATION_COUNTRIES_ACTION: string = "GetPreSimulationActiveCountries";

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getActiveCountries(search?: string): Observable<Country[]> {

    let httpParams = new HttpParams();

    if (search)
      httpParams = httpParams.set("search", search);

    return this.http
      .get<APIResponse>(
        this.httpService.buildUrl(
          this.MODULE,
          this.VERSION,
          this.RESOURCE,
          this.GET_PRE_SIMULATION_COUNTRIES_ACTION
        ),
        { params: httpParams }
      )
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success) return APIResponse.data;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
  }
}
