import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  
  resp: any = [];
  constructor(public http: HttpClient) { }
  
  getTablas(): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Order/tables`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
  }

  getStructure(tableName: string): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Order/structure/${tableName}`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
  }

  getTexto(): Observable<any> {
    
    const url = `${environment.apiEndpoint}corrosionclas/v1/Order/texto`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
  }
  
}

