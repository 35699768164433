import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { merge } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { DataService } from "src/app/services/Data.service";
import { UnitMeasureService } from "src/app/services/unit-measures.service";
import { REGEXP_PATTERNS } from "src/app/shared/consts/regexp-patterns";
import { ConcentrationUnits } from "src/app/shared/enums/concentration-units.enum";
import { UnitMeasures } from "src/app/shared/enums/unit-measures.enum";
import { WarningEnum } from "src/app/shared/enums/warnings.enum";
import { AbstractControlWarning } from "src/app/shared/helpers/AbstractControlWarning";
import { InterchaneableUnitValidator } from "src/app/shared/validators/interchangeable-unit.validator";
import Swal from "sweetalert2";
import { FilterCR } from "../../models/filterCR";
import { DensityCalculator } from "../../bussiness-logic/density-calculator";

@Component({
  selector: "app-filter-cr",
  templateUrl: "filter-cr.component.html",
  styleUrls: ["filter-cr.component.scss"],
})
export class FilterCRComponent implements OnInit, AfterViewInit {
  @Output() add = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() formChanges = new EventEmitter();
  @Output() isTemperatureUnitEnabled = new EventEmitter();
  @Output() isPressureCO2UnitEnabled = new EventEmitter();
  @Output() isSodiumChlorideUnitEnabled = new EventEmitter();

  @Input() key?: number;
  @Input() fgFiltersCR: FormGroup;
  @Input() fgFilterCR: FormGroup;
  @Input() unitMeasureService: UnitMeasureService;
  @Input() temperatureUnitControl: AbstractControl;
  @Input() pressureCO2UnitControl: AbstractControl;
  @Input() sodiumChlorideUnitControl: AbstractControl;
  @Input() conventional13CrUnitControl: AbstractControl;
  @Input() super13CrUnitControl: AbstractControl;
  @Input() modified13CrUnitControl: AbstractControl;
  @Input() temperatureUnitEnabled: number;
  @Input() pressureCO2UnitEnabled: number;
  @Input() sodiumChlorideUnitEnabled: number;
  @Input() hiddenErrors: string[];

  priorityWarnings: any[] = [
    { origin: "TEMPERATURE", type: WarningEnum.MaxValueLimitedByValue },
  ];

  @ViewChild("inputFocus") inputFocus: ElementRef;

  fgFilterCRDefaultValues: any;

  isModified13CrVisible: boolean = false;

  densityCalculator = new DensityCalculator();

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.currentRolSource.pipe(take(1)).subscribe((rol) => {
      if (rol == "ADMIN" || rol == "SUPER_USER")
        this.isModified13CrVisible = true;
      else this.isModified13CrVisible = false;
    });

    this.fgFilterCRDefaultValues = this.fgFilterCR.value;
    this.configForm();
  }

  ngAfterViewInit(): void {
    this.enableOrDisableUnitsEvent();
    this.updateAndValidityResults();
  }

  enableOrDisableUnits() {
    this.enableOrDisableTemperatureUnit();
    this.enableOrDisablePressureCO2Unit();
    this.enableOrDisableSodiumChlorideUnit();
  }

  private enableOrDisableUnitsEvent() {
    this.fgFilterCR.get("temperature").valueChanges.subscribe(() => {
      this.enableOrDisableTemperatureUnit();
    });

    this.fgFilterCR.get("pressureCO2").valueChanges.subscribe(() => {
      this.enableOrDisablePressureCO2Unit();
    });

    this.fgFilterCR.get("sodiumChloride").valueChanges.subscribe(() => {
      this.enableOrDisableSodiumChlorideUnit();
    });
  }

  private enableOrDisableTemperatureUnit() {
    let enableUnit: number = 1;

    const checkedErrors = this.checkErrors(
      enableUnit,
      "temperature",
      this.temperatureUnitEnabled
    );

    enableUnit = checkedErrors.enableUnit;

    this.isTemperatureUnitEnabled?.emit(checkedErrors.emptyInputError ? -1 : 1);

    if (enableUnit > 0)
      this.temperatureUnitControl.enable({ emitEvent: false });
    else this.temperatureUnitControl.disable({ emitEvent: false });
  }

  private enableOrDisablePressureCO2Unit() {
    let enableUnit: number = 1;

    const checkedErrors = this.checkErrors(
      enableUnit,
      "pressureCO2",
      this.pressureCO2UnitEnabled
    );

    enableUnit = checkedErrors.enableUnit;

    this.isPressureCO2UnitEnabled?.emit(checkedErrors.emptyInputError ? -1 : 1);

    if (enableUnit > 0)
      this.pressureCO2UnitControl.enable({ emitEvent: false });
    else this.pressureCO2UnitControl.disable({ emitEvent: false });
  }

  private enableOrDisableSodiumChlorideUnit() {
    let enableUnit: number = 1;

    const checkedErrors = this.checkErrors(
      enableUnit,
      "sodiumChloride",
      this.sodiumChlorideUnitEnabled
    );

    enableUnit = checkedErrors.enableUnit;

    this.isSodiumChlorideUnitEnabled?.emit(
      checkedErrors.emptyInputError ? -1 : 1
    );

    if (enableUnit > 0)
      this.sodiumChlorideUnitControl.enable({ emitEvent: false });
    else this.sodiumChlorideUnitControl.disable({ emitEvent: false });
  }

  private checkErrors(enableUnit: number, fcKey: string, unitEnabled: number) {
    let emptyInputError: boolean = false;
    if (this.fgFilterCR.parent) {
      //undefined cause waterDensity unit have an issue
      if (unitEnabled == undefined || unitEnabled > 0)
        Object.keys(this.fgFilterCR.parent.controls).forEach((key) => {
          if (key.startsWith("fgFilterCR"))
            enableUnit =
              !this.fgFilterCR.parent.get(key).get(fcKey).valid &&
              !this.fgFilterCR.parent.get(key).get(fcKey).errors?.required
                ? -1
                : enableUnit;
        });
      else enableUnit = -1;
    } else {
      if (
        !this.fgFilterCR.get(fcKey).valid &&
        !this.fgFilterCR.get(fcKey).errors?.required
      ) {
        enableUnit = -1;
        emptyInputError = true;
      } else {
        Object.keys(this.fgFiltersCR.controls).forEach((key) => {
          if (key.startsWith("fgFilterCR"))
            enableUnit =
              !this.fgFiltersCR.get(key).get(fcKey).valid &&
              !this.fgFiltersCR.get(key).get(fcKey).errors?.required
                ? -1
                : enableUnit;
        });
      }
    }
    return { enableUnit: enableUnit, emptyInputError: emptyInputError };
  }

  private updateAndValidityResults() {
    this.fgFilterCR.get("conventional13Cr").updateValueAndValidity();
    this.fgFilterCR.get("super13Cr").updateValueAndValidity();
    this.fgFilterCR.get("modified13Cr").updateValueAndValidity();
  }

  private configForm() {
    //If Chlorides changes then the value of NaCl must be updated
    this.fgFilterCR
    .get("sodiumChlorideDefault")
    .valueChanges.subscribe((defaultValue) => {
      if (
        this.fgFilterCR.get("sodiumChlorideDefault").valid &&
        (this.fgFilterCR.get("sodiumChlorideDefault").value || this.fgFilterCR.get("sodiumChlorideDefault").value === 0)
      ) {
        let clControl = this.fgFilterCR.get("sodiumChlorideDefault");

        let object = {
          waterDensity: this.densityCalculator.calculateDensity(clControl.value, this.sodiumChlorideUnitControl.value),
          defaultUnit:
            this.unitMeasureService.concentrationUnits.MilligramsLiter,
        };

        this.fgFilterCR
        .get("naClPercent")
        .setValue(
          this.unitMeasureService.convertUnit(
            clControl.value,
            UnitMeasures.Concentration,
            ConcentrationUnits.NaClPercent,
            false,
            this.unitMeasureService.getUnitFractionDigits(
              UnitMeasures.Concentration,
              ConcentrationUnits.NaClPercent
            ),
            object
          ),
          { emitEvent: false }
        );
        
        this.fgFilterCR
        .get("waterDensityDefault")
        .setValue(
          object.waterDensity,
          { emitEvent: false }
        );
      }
      else {
        this.fgFilterCR
        .get("naClPercent")
        .setValue(
          null,
          { emitEvent: false }
        );
      }
    });

    merge(
      this.fgFilterCR.get("temperatureDefault").valueChanges,
      this.fgFilterCR.get("pressureCO2Default").valueChanges,
      this.fgFilterCR.get("sodiumChlorideDefault").valueChanges,
    )
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.valueFormChanges();
      });
  }

  private valueFormChanges() {
    this.formChanges.emit(this.key ? null : this.fgFilterCR);
    if (this.fgFilterCR.valid || this.fgFilterCR.errors?.duplicatedEntry) {
      let filterCRAdd: FilterCR = {
        key: this.key,
        temperature: this.fgFilterCR.get("temperature").value,
        temperatureDefault: this.fgFilterCR.get("temperatureDefault").value,
        pressureCO2: this.fgFilterCR.get("pressureCO2").value,
        pressureCO2Default: this.fgFilterCR.get("pressureCO2Default").value,
        sodiumChloride: this.fgFilterCR.get("sodiumChloride").value,
        sodiumChlorideDefault: this.fgFilterCR.get("sodiumChlorideDefault").value,
        naClPercent: this.fgFilterCR.get("naClPercent").value,
        waterDensityDefault: this.fgFilterCR.get("waterDensityDefault").value,
      };

      if (!this.key) {
        this.fgFilterCR.reset(this.fgFilterCRDefaultValues, {
          emitEvent: false,
        });
        this.inputFocus.nativeElement.focus();
      }

      this.add.emit(filterCRAdd);
    } else if (this.key) {
      this.clearResults();
      this.updateArray();
    }
  }

  resetFgFilterCR() {
    this.fgFilterCR.reset(this.fgFilterCRDefaultValues);
  }

  private clearResults() {
    this.fgFilterCR
      .get("conventional13Cr")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("conventional13CrDefault")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR.get("super13Cr").setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("super13CrDefault")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR.get("modified13Cr").setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("modified13CrDefault")
      .setValue(null, { emitEvent: false });

    this.updateAndValidityResults();
  }

  private clearInputs() {
    this.fgFilterCR.get("temperature").setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("temperatureDefault")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR.get("pressureCO2").setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("pressureCO2Default")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR.get("sodiumChloride").setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("sodiumChlorideDefault")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("naClPercent")
      .setValue(null, { emitEvent: false });
    this.fgFilterCR
      .get("waterDensityDefault")
      .setValue(1, { emitEvent: false });
  }

  private updateArray() {
    let filterCRUpdate: FilterCR = {
      key: this.key,
      temperature: this.fgFilterCR.get("temperature").value,
      temperatureDefault: this.fgFilterCR.get("temperatureDefault").value,
      pressureCO2: this.fgFilterCR.get("pressureCO2").value,
      pressureCO2Default: this.fgFilterCR.get("pressureCO2Default").value,
      sodiumChloride: this.fgFilterCR.get("sodiumChloride").value,
      sodiumChlorideDefault: this.fgFilterCR.get("sodiumChlorideDefault").value,
      naClPercent: this.fgFilterCR.get("naClPercent").value,
      waterDensityDefault: this.fgFilterCR.get("waterDensityDefault").value,
      conventional13Cr: this.fgFilterCR.get("conventional13Cr").value,
      conventional13CrDefault: this.fgFilterCR.get("conventional13CrDefault")
        .value,
      super13Cr: this.fgFilterCR.get("super13Cr").value,
      super13CrDefault: this.fgFilterCR.get("super13CrDefault").value,
      modified13Cr: this.fgFilterCR.get("modified13Cr").value,
      modified13CrDefault: this.fgFilterCR.get("modified13CrDefault").value,
    };

    this.enableOrDisableUnits();
    this.validityInputs();
    this.clear.emit(filterCRUpdate);
  }

  validityInputs() {
    this.fgFilterCR.get("temperature").updateValueAndValidity({ emitEvent: false });
    this.fgFilterCR.get("pressureCO2").updateValueAndValidity({ emitEvent: false });
    this.fgFilterCR.get("sodiumChloride").updateValueAndValidity({ emitEvent: false });
  }

  cleanFilter() {
    Swal.fire({
      title: "Are you sure?",
      text: "The values on filter will be cleared",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.clearInputs();
        this.clearResults();
        this.fgFilterCR.markAllAsTouched();
        this.updateArray();
      }
    });
  }

  deleteFilter() {
    this.delete.emit(this.key);
  }

  get conventional13Cr() {
    return this.fgFilterCR.get("conventional13Cr") as AbstractControlWarning;
  }

  get super13Cr() {
    return this.fgFilterCR.get("super13Cr") as AbstractControlWarning;
  }

  get modified13Cr() {
    return this.fgFilterCR.get("modified13Cr") as AbstractControlWarning;
  }

  isNotAvailable(key: string) {
    const abstractControlWarning = this.fgFilterCR.get(
      key
    ) as AbstractControlWarning;
    return abstractControlWarning?.warnings?.some(
      (warning) =>
        warning.type === WarningEnum.MaxValueLimitedByValue &&
        warning.origin === "TEMPERATURE"
    )
      ? "Not available"
      : "";
  }
}
