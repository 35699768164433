<style>
   @media only screen and (min-height: 480px) and (max-height: 522px) {
    .treecontainer {
      height: 21vh;
    }
  }

  @media only screen and (min-height: 523px) and (max-height: 580px) {
    .treecontainer {
      height: 31vh;
    }
  }

  @media only screen and (min-height: 581px) and (max-height: 680px) {
    .treecontainer {
      height: 37vh;
    }
  }

  @media only screen and (min-height: 800px) and (max-height: 830px) {
    .treecontainer {
      height: 54vh;
    }
  }


  @media only screen and (min-height: 830px) and (max-height: 880px) {
    .treecontainer {
      height: 57vh;
    }
  }

  @media only screen and (min-height: 881px) and (max-height: 904px) {
    .treecontainer {
      height: 57vh;
    }
  }

  @media only screen and (min-height: 905px) and (max-height: 968px) {
    .treecontainer {
      height: 60vh;
    }
  }

  @media only screen and (min-height: 969px) {
    .treecontainer {
      height: 61vh;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1030px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      overflow-y: scroll;
    }
  }

  @media only screen and (min-width: 1030px) and (max-width: 1366px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;
      overflow-y: scroll;
    }

    .form-group{
      margin-bottom: 2px;
    }
  }

  @media only screen and (min-width: 1370px) and (max-width: 1920px) {
    .treecontainer {
      display: block;
      padding-right: 5px;
      margin: 0;
      margin-top: 5px;
      width: 100%;      
      overflow-y: scroll;
    }

    .form-group{
      margin-bottom: 4px;
    }
  }

  app-context-menu {
    width: 120px;
    background: #fff;
    color: #009;
    padding: 15px;
    box-shadow: 2px 2px 5px rgba(37, 37, 37, 0.75) !important;
    -moz-box-shadow: 2px 2px 5px 2px rgb(37, 37, 37) !important;
    -webkit-box-shadow: 2px 2px 5px rgb(37, 37, 37) !important;
  }
</style>

<div class="treecontainer">
  <div class="element" (contextmenu)="displayContextMenu($event); false">
    <app-tree [tree]="tree" (canActivateActions)="canActivateActions($event)"></app-tree>
  </div>
  
  <app-context-menu *ngIf="rightClickMenuItems.length > 0 && isDisplayContextMenu" [ngStyle]="getRightClickMenuStyle()"
    [contextMenuItems]="rightClickMenuItems" (onContextMenuItemClick)="handleMenuItemClick($event)">
  </app-context-menu>
</div>
<div class="form-group">
  <label for="noteTextarea">Notes</label>
  <textarea class="form-control" id="noteTextarea" [value]="valueNote" rows="4" style="height: 80px;"></textarea>
</div>
<div class="form-group" style="margin-bottom: 0px;">
  <label for="visibilitySelect">Visibility</label>
  <select id="visibilitySelect" class="form-control" [(ngModel)]="visibilitySelect">
    <option value="Select">Select</option>
    <option value="Private">Private</option>
    <option value="Colleagues">Colleagues</option>
    <option value="Public">Public</option>
  </select>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true"><p style="color: white" > Saving... </p></ngx-spinner>