export function toTitleCase(value: string): string {
    if (value.length == 0){
        return '';
    }
    value = value.toLowerCase();
    return value.replace(
        /\w\S*/g,
        function (txt: string) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        }
    );
}
