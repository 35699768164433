<div id="divInstallation" class="panel-default">
  <div id="lengthPanel" class="widget-head">
    <h3>Lengths</h3>
  </div>
  <div class="panel-body">
    <form [formGroup]="fgSlottedPipe">
      <div formGroupName="fgLengths">
        <div class="row">
          <div class="col-sm-7">
            <label>Pipe Length LT (see Fig. 7)</label>
          </div>
          <div class="col-sm-5">
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="pipeLengthLT"
                  class="form-control input-xs no-spin"
                  commaToDot
                  [ngClass]="{
                    'input-error': fgSlottedPipe.get('fgLengths').errors?.LTSum
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  ft
                </span>
              </div>
              <span
                class="text-danger error-warning-text"
                *ngIf="fgSlottedPipe.get('fgLengths').errors?.LTSum"
              >
                LT must be lower than the sum of LL1 and LL2.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-7">
            <label>Free Distance to Box LL1</label>
          </div>
          <div class="col-sm-5">
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="freeDistanceToBoxLL1"
                  class="form-control input-xs no-spin"
                  commaToDot
                  [ngClass]="{
                    'input-error': fgSlottedPipe.get('fgLengths').errors?.LTSum
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  ft
                </span>
              </div>
              <span
                class="text-danger error-warning-text"
                *ngIf="fgSlottedPipe.get('fgLengths').errors?.LTSum"
              >
                LT must be lower than the sum of LL1 and LL2.
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-7">
            <label>Free Distance to Pin LL2</label>
          </div>
          <div class="col-sm-5">
            <div class="xs-mb-15">
              <div class="input-group">
                <input
                  formControlName="freeDistanceToBoxLL2"
                  class="form-control input-xs no-spin"
                  commaToDot
                  [ngClass]="{
                    'input-error': fgSlottedPipe.get('fgLengths').errors?.LTSum
                  }"
                />
                <span class="input-group-addon input-xs label-dts thickness-0">
                  ft
                </span>
              </div>
              <span
                class="text-danger error-warning-text"
                *ngIf="fgSlottedPipe.get('fgLengths').errors?.LTSum"
              >
                LT must be lower than the sum of LL1 and LL2.
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-7">
            <p style="font-size: 11px;">
              R1 = 18-22 ft = 5.49 - 6.71 m<br>
              R2 = 27-30 ft = 8.23 - 9.14 m<br>
              R3 = 38-45 ft = 11.58 - 13.72 m
            </p>
          </div>
          <div class="col-sm-5">
            <button
              *ngIf="showDataCheck"
              data-toggle="modal"
              data-target="#dataCheckModal"
              data-placement="right"
              data-tooltip
              title="Data Check"
              type="button"
              class="datacheck"
              (click)="dataCheck()"
              tabindex="-1"
            >
              Data Check
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
