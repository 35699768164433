export enum DcpShortNames {
  Connection = "CN",
  OutsideDiameter = "OD",
  WallThickness = "WT",
  Grade = "GR",
  Type = "TP",
  PipeBodyDrift = "PD",
  ConnectionOdOption = "CO",
  MinWallThickness = "MW",
  NominalWeight = "NW",
  SpecialBevel = "SB",
}
