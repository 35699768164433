<style>
    .s7-user:before {
      color: black;
    }
    .s7-lock:before{
      color: black;
    }
    </style>
    <body class="am-splash-screen">
    
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=Edge">
        
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
      <meta name="description" content="">
      <meta name="author" content="">
      <title>Tenaris</title>
      <div class="am-wrapper am-login am-white-header" style="background-color: #f0f0f0;">
            <div class="login-container">
              <div class="panel panel-default">
                <div class="panel-heading"><img src="" alt="logo" width="150px" height="39px" class="logo-img"><span>Well Design Toolbox</span></div>
                <div class="panel-body">
                  <form class="form-horizontal">
                    <div class="login-form">
                      <div class="form-group login-submit" style="text-align: center;">
                        <button style="width:80%;" data-dismiss="modal" type="button" class="btn btn-primary btn-lg" >Login</button>
                      </div>
                    </div>
                  </form>
                </div>
          </div>
        </div>
      </div>
    </body>