<style>
  .panel-body {
    font-family: FrutigerRoman, sans-serif;
    color: #555;
    background-color: #fff;
    padding: 5px !important;
    width: 400px;
  }

  .panel-body label {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }

  /* Sidebar Style */
  #sidebar {
    width: 400px;
    position: fixed;
    top: 0;
    right: -400px;
    height: 937; 
    z-index: 9999;
    background: #fff;
    color: #555;
    transition: all 0.5s ease-in-out;
  }

  #sidebar.active {
    right: 0;
    box-shadow: -2px 0 1px rgba(37, 37, 37, 0.5) !important;
    -moz-box-shadow: -2px 0 1px rgb(37, 37, 37, 0.5) !important;
    -webkit-box-shadow: -2px 0 1px rgb(37, 37, 37, 0.5) !important;
  }

  #dismiss {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: rgba(0, 0, 0, 0.0);
    position: absolute;
    top: 40px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  #dismiss:hover {
    background: #fff;
    color: #000016;
  }

  #sidebar .sidebar-header {
    padding: 20px;
  }

  #sidebar .sidebar-header h3 {
    font-family: FrugiterBold, sans-serif;
    font-weight: bold;
    font-size: 20px;
  }

  #sidebar .footer {
    text-align: right;
    position: fixed;
    bottom: 0;
    width: 400px;
    height: 80px;
    line-height: 80px;
    background-color: #dbdbdb;
    /* padding-right: 10px; */
    border-top: #555 solid 2px;
  }

  .sidebar-btn:disabled{
    opacity: 0.3;
  }

  @media (min-width: 800px){
    .container {
      padding: 0;
      width: 400;
      height: 937px;
    }
  }

  @media (min-width: 1200px){
    .container {
      padding: 0;
      width: 400;
      height: 1040px;
    }
  }
</style>

<nav id="sidebar">
  <div id="dismiss" (click)="rsbCancel()">
    <span (click)="rsbCancel()"><i class="fa fa-times" aria-hidden="true"></i></span>
  </div>

  <div class="container">
    <div class="sidebar-header" [ngStyle]="getHeaderColor()">
      <h3>Simulation Anagraphic - {{ msgTitle }}</h3>
    </div>

      <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          
          <div class="panel-body">
            <div *ngIf="isLoad" class="form-group">
              <app-tree-load [userId]="userId" [department]="department" [rol]="rol" (canActivateActionsMenu)="activateActionMenu($event)">
              </app-tree-load>
            </div>
            <div *ngIf="isDelete" class="form-group">
              <app-tree-delete [userId]="userId" (treeLevels)="getTreeLevels($event)" (canActivateActionsMenu)="activateActionMenu($event)">
              </app-tree-delete>
            </div>
            <div *ngIf="isSave">
              <app-tree-create [userId]="userId" (canActivateActionsMenu)="activateActionMenu($event)">
              </app-tree-create>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button *ngIf="isLoad" type="button" id="rsb-load" class="btn btn-primary sidebar-btn" style="margin: 5px;"
        (click)="loadSimulation()" [disabled]="disabled">Load</button>
      <button *ngIf="isSave" type="button" id="rsb-load" class="btn btn-primary sidebar-btn" style="margin: 5px;"
        (click)="confirmSave()" [disabled]="disabled">Save</button>
      <button *ngIf="isDelete" type="button" id="rsb-delete" class="btn btn-primary sidebar-btn" style="margin: 5px;"
        (click)="confirmDelete()" [disabled]="disabled">Delete</button>
      <button type="button" id="rsb-cancel" class="btn btn-default" (click)="rsbCancel()">Cancel</button>
    </div>
  </div>
</nav>

