import { Component, EventEmitter, HostListener, Input, OnInit, Output, } from '@angular/core';
import { ContextMenuModel } from 'src/app/models/contextmenu/Context-menu-model';
import { CorrosionClas } from 'src/app/models/corrosionclas/Index';
import { CorrosionclasService } from 'src/app/services/Corrosionclas.service';
import { HeaderState } from 'src/app/models/header/HeaderState';
import { TreeLevel, TreeNode, TreeState } from 'src/app/models/tree/Index';
import { TreeAction } from 'src/app/models/tree/TreeAction';
import { TreeCustomer, TreeLevels, TreeProject, TreeRegion, TreeSimulation } from 'src/app/models/tree/TreeLevels';
import { TreeService } from 'src/app/services/Tree.service';
import { activateTreeItem, createTree, getTreeParent, getTreePath } from '../../helpers/Index';
import Swal from 'sweetalert2';
import { CorrosionRateService } from 'src/app/modules/CorrosionRate/services/corrosion-rate.service';
import { DataService } from 'src/app/services/Data.service';
import { SlottedPipeService } from 'src/app/services/slotted-pipe/slotted-pipe.service';
import { PerforatedPipeService } from 'src/app/services/perforated-pipe/perforated-pipe.service';
import { MatSelGuidelinesService } from 'src/app/services/mat-sel-guidelines/mat-sel-guidelines.service';
declare var jQuery: any;

@Component({
  selector: 'app-tree-create',
  templateUrl: './tree.create.component.html',
})
export class TreeCreateComponent implements OnInit {
  title: string = 'Tree-Create';

  //Context Menu
  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  menuNewValue: string = '';
  userNode: TreeNode;
  //Tree values
  @Output() canActivateActionsMenu = new EventEmitter<TreeAction>();
  @Input() userId: number = 0;
  @Input() module: string = '';
  tree: TreeLevels = null;
  selected: TreeAction = { id: 0, text: '', level: '', activate: false, action: '', path: '', parents: '', userId: 0, notes: '', visibility: '' };
  valueNote: string = '';
  nameUser: string;
  visibilitySelect: string = "Select";

  constructor(public treeService: TreeService, public corrosionService: CorrosionclasService
    ,public corrosionRateService: CorrosionRateService,
    public slottedPipeService: SlottedPipeService,
    public perforatedPipeService: PerforatedPipeService,
    public matSelGuidelinesService: MatSelGuidelinesService,
    private dataService: DataService) { }

  ngOnInit() {
    this.dataService.currentUserIdSource.subscribe(userId => (this.userId= userId));
    this.dataService.currentModuloSource.subscribe(module => (this.module= module));
    this.dataService.currentNameSurnameSource.subscribe(name => (this.nameUser= name));
    this.ValidaIngresoUsuario();
  }

  ValidaIngresoUsuario() {
    //validar si existe, si no se inserta en la tabla TREE.    
    this.treeService.getTreeByUserId(this.userId,this.module).subscribe(
      levels => {

        this.userNode = levels['data'].find(item => item.item_level == 'user');
        

        if (levels['data'] == null || levels['data'] == undefined || levels['data'].length < 1) {

          //no existe en la tabla tree, insertar user
          let newNode: TreeNode = {
            id: 0,
            user_id: this.userId,
            item_id: 1,
            item_name: this.nameUser,
            item_level: 'user',
            parent_id: null,
            module: this.module,
            last_update_date: null,
            creation_date: null,
            deletion_date: null,
            deleted_flag: false
          };
          //graba el row tree
          this.treeService.postNodeTree(newNode).subscribe(
            node => {
              if (node.status.success) {
                this.getTree(this.userId,this.module);
              }
            });
        } else {
          //actualiza el nombre en la tabla tree con el nombre de la tabla user

          this.treeService.getNodeTreeById(this.userNode['id']).subscribe(node => {
            if (node.status.success) {
              let newNode: TreeNode = node.data;
              newNode.item_name = this.nameUser;
              newNode.module = this.module;
              this.treeService.putNodeTree(this.userNode['id'], newNode).subscribe(node => {
                this.getTree(this.userId,this.module);
              });
            }
          });
        }
      });
  }

  getTree(userId: number,module: string) {
    this.treeService.getTreeByUserId(userId,module).subscribe(levels => {
      if (levels.status.success) {
        setTimeout(null, 1000);
        this.tree = createTree(levels.data);
      }
    },
      err => {
        Swal.fire({
          title: 'An error occurred',
          icon: 'error',
          confirmButtonColor: '#009',
          cancelButtonColor: '#aaa',
        });
      });
  }

  getSimulation() {
    if (this.selected.level == 'simulation' && this.selected.id > 0) {
      if(this.module == 'corrosionclas'){
          this.corrosionService.getSimulationByTreeId(this.selected.id).subscribe(simDB => {
            if (simDB.status.success == true) {
              let simulation = new CorrosionClas();
              simulation = simDB.data;
              this.valueNote = simulation.notes;
              this.visibilitySelect = simulation.visibility;
            } else {
              this.valueNote = '';
              this.visibilitySelect = 'Select';
            }
          },
            err => {
              Swal.fire({
                title: 'An error occurred',
                icon: 'error',
                confirmButtonColor: '#009',
                cancelButtonColor: '#aaa',
              });
            });
      }
      else if(this.module == 'corrosionrate'){
        this.corrosionRateService.loadSimulation(this.selected.id).subscribe(simDB => {
            this.valueNote = simDB.notes==null? '':simDB.notes;
            this.visibilitySelect = simDB.visibility==null?'Select':simDB.visibility;
            this.dataService.changeIdSimulationClickedOnTreeSource(simDB.id);
        },
          err => {
            Swal.fire({
              title: 'An error occurred',
              icon: 'error',
              confirmButtonColor: '#009',
              cancelButtonColor: '#aaa',
            });
          });
      }
      else if(this.module == 'slottedPipe'){
        this.slottedPipeService.loadSimulation(this.selected.id).subscribe(simulation => {
          this.valueNote = simulation.tree_notes;
          this.visibilitySelect = simulation.visibility ?? 'Select';
          this.dataService.changeIdSimulationClickedOnTreeSource(simulation.id);
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
      }
      else if(this.module == 'perforatedPipe'){
        this.perforatedPipeService.loadSimulation(this.selected.id).subscribe(simulation => {
          this.valueNote = simulation.tree_notes;
          this.visibilitySelect = simulation.visibility ?? 'Select';
          this.dataService.changeIdSimulationClickedOnTreeSource(simulation.id);
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
      }
      else if(this.module == 'matSelGuidelines'){
        this.matSelGuidelinesService.loadSimulation(this.selected.id).subscribe(simulation => {
          this.valueNote = simulation.matSelGuideline.tree_notes;
          this.visibilitySelect = simulation.matSelGuideline.visibility ?? 'Select';
          this.dataService.changeIdSimulationClickedOnTreeSource(simulation.matSelGuideline.tree_id);
      },
        err => {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        });
      }
    } else {
      this.valueNote = '';
    }
  }

  canActivateActions(selected: TreeAction) {
    selected.userId = this.userId;
    selected.action = HeaderState.save;
    selected.notes = this.valueNote;
    selected.visibility = jQuery('#visibilitySelect option:selected').text();
    this.selected = selected;
    this.selected = activateTreeItem(this.tree, HeaderState.save, selected);
    this.getSimulation();
    this.canActivateActionsMenu.emit(selected);
  }

  //Context Menu
  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
  }

  displayContextMenu(event) {
    this.isDisplayContextMenu = true;
    this.selected.id = event.target.getAttribute('data-id');
    this.selected.text = event.target.getAttribute('data-text');
    this.selected.level = event.target.getAttribute('data-level');
    this.selected.path = getTreePath(this.tree, this.selected);
    this.selected.parents = getTreeParent(this.tree, this.selected);
    this.rightClickMenuItems = [];

    if (this.selected.level !== 'simulation') {
      this.rightClickMenuItems.push({ menuText: 'Append', menuEvent: 'Append', menuIcon: 'fa fa-plus' },);
    }
    let options = [
      { menuText: 'Rename', menuEvent: 'Rename', menuIcon: 'fa fa-pencil-square-o' },
      { menuText: 'Sep', menuEvent: null, menuIcon: null },
      { menuText: 'Expand All', menuEvent: 'ExpandAll', menuIcon: 'fa fa-caret-square-o-down' },
      { menuText: 'Collapse All', menuEvent: 'CollapseAll', menuIcon: 'fa fa-caret-square-o-up' },
    ];
    this.rightClickMenuItems.push(...options);
  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      right: '370px',
      top: '160px',
      'z-index': 9998
    }
  }

  async handleMenuItemClick(event) {
    switch (event.data) {
      case "Append":
        if (this.selected.level !== 'simulation') {
          if (this.selected.path === '') {
            Swal.fire({
              icon: 'warning',
              text: 'Click over the item'
            })
          }
          else {
            let { value: newName } = await Swal.fire({
              title: 'Append item to ' + this.selected.level + ': ' + this.selected.path,
              input: 'text',
              inputLabel: 'Your Item Name',
              inputValue: '',
              showCancelButton: true,
              confirmButtonColor: '#009',
              inputValidator: (value) => {
                if (!value) {
                  return 'Value is required!'
                }
              }
            });
            this.menuNewValue = newName;
            if (this.menuNewValue !== undefined) {
              this.addTreeItem();
              //setTimeout(this.getTree, 2000, this.userId);
              //jQuery('.dd').nestable('expandAll');
            }
          }
        }
        break;
      case "Rename":
        if (this.selected.level == 'user') {
          Swal.fire('You can not...', 'Rename the user level in the tree.', 'warning');
          return
        }
        if (this.selected.path === '') {
          Swal.fire({
            icon: 'warning',
            text: 'Click over the item'
          })
        }
        else {
          let { value: newName } = await Swal.fire({
            title: 'Rename item in ' + this.selected.level + ': ' + this.selected.path,
            input: 'text',
            inputLabel: 'Your new item Name',
            inputValue: this.selected.text,
            showCancelButton: true,
            confirmButtonColor: '#009',
            inputValidator: (value) => {
              if (!value) {
                return 'Value is required!'
              }
            }
          })
          this.menuNewValue = newName;
          if (this.menuNewValue !== undefined) {
            this.renameTreeItem()
            //jQuery('.dd').nestable('expandAll');
          }
        }
        break;
      case "ExpandAll":
        jQuery('.dd').nestable('expandAll');
        break;
      case "CollapseAll":
        jQuery('.dd').nestable('collapseAll');
        break;
    }
  }

  //Agregar Nodos al Tree
  addTreeItem() {

    this.selected.action = TreeState.create;
    if (this.selected.level == TreeLevel.l1) {
      const newCustomer = new TreeCustomer();
      newCustomer.parentId = this.selected.id;
      newCustomer.customerId = this.tree.users[0].customers.length + 1;
      newCustomer.customerName = this.menuNewValue;
      newCustomer.accion = TreeState.create;
      newCustomer.visible = true;
      newCustomer.regions = [];

      let newNode: TreeNode = {
        id: 0,
        user_id: this.userId,
        item_id: newCustomer.customerId,
        item_name: newCustomer.customerName,
        item_level: TreeLevel.l2,
        parent_id: newCustomer.parentId,
        module: this.module,
        last_update_date: null,
        creation_date: null,
        deletion_date: null,
        deleted_flag: false
      };

      this.treeService.postNodeTree(newNode).subscribe(node => {
        if (node?.status.success) {
          newCustomer.id = node.data.treeId;
          this.tree.users[0].customers.push(newCustomer);
        } else {
          Swal.fire({
            title: 'An error occurred',
            icon: 'error',
            confirmButtonColor: '#009',
            cancelButtonColor: '#aaa',
          });
        }
      });
      return;
    }
    if (this.tree.users[0].customers.length > 0) {
      for (let i2 = 0; i2 < this.tree.users[0].customers.length; i2++) {
        const customer = this.tree.users[0].customers[i2];
        if (this.selected.level == TreeLevel.l2 && customer.customerName == this.selected.text) {
          const newRegion = new TreeRegion()
          newRegion.parentId = this.selected.id;
          newRegion.regionId = customer.regions.length + 1;
          newRegion.regionName = this.menuNewValue;
          newRegion.accion = TreeState.create;
          newRegion.visible = true;
          newRegion.projects = [];

          let newNode: TreeNode = {
            id: 0,
            user_id: this.userId,
            item_id: newRegion.regionId,
            item_name: newRegion.regionName,
            item_level: TreeLevel.l3,
            parent_id: this.selected.id,
            module: this.module,
            last_update_date: null,
            creation_date: null,
            deletion_date: null,
            deleted_flag: false
          };

          this.treeService.postNodeTree(newNode).subscribe(node => {
            if (node?.status.success) {
              newRegion.id = node.data.treeId
              this.tree.users[0].customers[i2].regions.push(newRegion);
            } else {
              Swal.fire({
                title: 'An error occurred',
                icon: 'error',
                confirmButtonColor: '#009',
                cancelButtonColor: '#aaa',
              });
            }
          });
          return;
        }
        if (this.tree.users[0].customers[i2].regions.length > 0) {
          for (let i3 = 0; i3 < this.tree.users[0].customers[i2].regions.length; i3++) {
            const region = this.tree.users[0].customers[i2].regions[i3];
            if (this.selected.level == TreeLevel.l3 && region.regionName == this.selected.text) {
              const newProject = new TreeProject();
              newProject.parentId = this.selected.id;
              newProject.projectId = region.projects.length + 1;
              newProject.projectName = this.menuNewValue;
              newProject.accion = TreeState.create;
              newProject.simulations = [];
              newProject.visible = true;

              let newNode: TreeNode = {
                id: 0,
                user_id: this.userId,
                item_id: newProject.projectId,
                item_name: newProject.projectName,
                item_level: TreeLevel.l4,
                parent_id: this.selected.id,
                module: this.module,
                last_update_date: null,
                creation_date: null,
                deletion_date: null,
                deleted_flag: false
              };

              this.treeService.postNodeTree(newNode).subscribe(node => {
                if (node?.status.success) {
                  newProject.id = node.data.treeId
                  this.tree.users[0].customers[i2].regions[i3].projects.push(newProject);
                } else {
                  Swal.fire({
                    title: 'An error occurred',
                    icon: 'error',
                    confirmButtonColor: '#009',
                    cancelButtonColor: '#aaa',
                  });
                }
              });
              return;
            }
            if (this.tree.users[0].customers[i2].regions[i3].projects.length > 0) {
              for (let i4 = 0; i4 < this.tree.users[0].customers[i2].regions[i3].projects.length; i4++) {
                const project = this.tree.users[0].customers[i2].regions[i3].projects[i4];
                if (this.selected.level == TreeLevel.l4 && project.projectName == this.selected.text) {
                  const newSimulation = new TreeSimulation();
                  newSimulation.parentId = this.selected.id;
                  newSimulation.simulationId = project.simulations.length + 1;
                  newSimulation.simulationName = this.menuNewValue;
                  newSimulation.visibility = jQuery('#visibilitySelect option:selected').text();
                  newSimulation.notes = this.valueNote;
                  newSimulation.accion = TreeState.create;
                  newSimulation.visible = true;

                  let newNode: TreeNode = {
                    id: 0,
                    user_id: this.userId,
                    item_id: newSimulation.simulationId,
                    item_name: newSimulation.simulationName,
                    item_level: TreeLevel.l5,
                    parent_id: this.selected.id,
                    module: this.module,
                    last_update_date: null,
                    creation_date: null,
                    deletion_date: null,
                    deleted_flag: false
                  };

                  this.treeService.postNodeTree(newNode).subscribe(node => {
                    if (node?.status.success) {
                      newSimulation.id = node.data.treeId
                      this.tree.users[0].customers[i2].regions[i3].projects[i4].simulations.push(newSimulation);
                    } else {
                      Swal.fire({
                        title: 'An error occurred',
                        icon: 'error',
                        confirmButtonColor: '#009',
                        cancelButtonColor: '#aaa',
                      });
                    }
                  });
                  return;
                }
              }
            }
          }
        }
      }
    }
  }

  // Renombra Nodos del Tree
  renameTreeItem() {
    if (this.selected.level == 'user') {
      return;
    }
    this.selected.action = TreeState.update;
    if (this.tree.users[0].customers.length > 0) {
      this.tree.users[0].customers.forEach(customer => {
        if (customer.customerName == this.selected.text) {
          this.updatePath(this.selected.text, this.menuNewValue)
          customer.customerName = this.menuNewValue;
          customer.accion = TreeState.update;
          this.updateTreeNode();
          return;
        }
        if (customer.regions.length > 0) {
          customer.regions.forEach(region => {
            if (region.regionName == this.selected.text) {
              this.updatePath(this.selected.text, this.menuNewValue)
              region.regionName = this.menuNewValue;
              region.accion = TreeState.update;
              this.updateTreeNode();
              return;
            }
            if (region.projects.length > 0) {
              region.projects.forEach(project => {
                if (project.projectName == this.selected.text) {
                  this.updatePath(this.selected.text, this.menuNewValue)
                  project.projectName = this.menuNewValue;
                  project.accion = TreeState.update;
                  this.updateTreeNode();
                  return;
                }
                if (project.simulations.length > 0) {
                  project.simulations.forEach(simulation => {
                    if (simulation.simulationName == this.selected.text) {
                      this.updatePath(this.selected.text, this.menuNewValue)
                      simulation.simulationName = this.menuNewValue;
                      simulation.accion = TreeState.update;
                      //this.treeService.saveTree(this.tree[0])
                      this.updateTreeNode();
                      return;
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  updatePath(oldValue: string, newValue: string) {
    if (oldValue.length == 0 || newValue.length == 0) {
      return
    }
    let path = this.selected.path;
    let newpath = path.replace(oldValue, newValue);
    this.selected.path = newpath;
  }

  updateTreeNode() {
        this.treeService.renameNodeTree(this.selected.id, this.menuNewValue, this.module).subscribe(node => {
          if (node?.status.success) {
            Swal.fire('Item updated', '', 'success')
            return
          } else {
            Swal.fire('Item Not updated', '', 'error')
          }
        });
  }

}
