import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, finalize, timeout } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // 'instances' is a counter for the number of active HTTP requests
  instances: number = 0;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private spinner: NgxSpinnerService
  ) {}

  intercept(
    parameterRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Retrieve the 'Skip-Loading' header from the request parameters
    const skipLoading = parameterRequest.headers.get("Skip-Loading");

    // If the 'Skip-Loading' header is not present, increment the instance count and show the spinner
    if (!skipLoading) {
      this.instances++;
      this.spinner.show();
    }

    // Get the access token
    const token: string = this.oidcSecurityService.getAccessToken();

    // If there's no token, use the original request. If there is a token, clone the request and add the Authorization header
    const request: HttpRequest<any> = !token
      ? parameterRequest
      : parameterRequest.clone({
          headers: parameterRequest.headers.set(
            "Authorization",
            `Bearer ${token}`
          ),
        });

    // Handle the request and then finalize it
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      // Set a timeout of 180 seconds (3 minutes) for the request
      timeout(180000),
      finalize(() => {
        // If the 'Skip-Loading' header is not present, decrement the instance count
        if (!skipLoading) {
          this.instances--;

          // If there are no more instances, hide the spinner
          if (!this.instances) this.spinner.hide();
        }
      })
    );
  }
}
