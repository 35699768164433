import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeNumber'
})
export class SafeNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (typeof value !== 'number' || isNaN(value) || !isFinite(value) || Object.is(value, -0))
      return value;
    else
      return new Intl.NumberFormat(args[0], { minimumFractionDigits: args[1], maximumFractionDigits: args[2] }).format(value);  
  }
}
