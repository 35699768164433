import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: 
        {
          authority: 'https://login.microsoftonline.com/a054342c-6f8c-4378-ad61-a8ad00f2b736',
          authWellknownEndpointUrl: 'https://login.microsoftonline.com/a054342c-6f8c-4378-ad61-a8ad00f2b736/v2.0',
          postLogoutRedirectUri: environment.auth.postLogoutRedirectUri ,
          redirectUrl: window.location.origin,
          clientId: environment.auth.clientID,
          scope: `openid profile ${environment.auth.clientID}/custom_scope`,
          responseType: 'code',
          silentRenew: true,
          maxIdTokenIatOffsetAllowedInSeconds: 600,
          issValidationOff: false,
          autoUserInfo: false,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true,
          logLevel: environment.auth.logLevel,
          unauthorizedRoute:'https://applications.tenaris.net/sites/ITUserSupport',
          
        }, 
        //Soline clientId: '591e0876-8267-4be7-a226-14f5318be2d7',
        //Emily  clientId: '127a7a86-07d9-437b-a324-5c4fd9eb0535',
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
