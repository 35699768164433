import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject, Subscription } from "rxjs";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import Swal from "sweetalert2";
import { PerforatedService } from "../../perforated-pipe/services/perforated.service";
import { SlottedService } from "../../slotted-pipe/services/slotted.service";

@Component({
  selector: "app-data-check-modal",
  templateUrl: "./data-check-modal.component.html",
  styleUrls: ["./data-check-modal.component.css"],
})
export class DataCheckModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Must be declared as "any", not as "DataTables.Settings"
  dtOptions: any = {};

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild("options") el: ElementRef;
  @ViewChild("closeBtn") closeBtn: ElementRef;

  dtTrigger: Subject<any> = new Subject();
  isDtInitialized: boolean = false;
  dtData: any;
  section: string;
  dataCheckData$: Subscription;
  isSubmitting: boolean = false;
  isSubmitDisabled: boolean = false;
  service: SlottedService | PerforatedService;

  constructor(
    private slottedService: SlottedService,
    private perforatedService: PerforatedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url === NAVIGATIONS.SLOTTED.route)
      this.service = this.slottedService;
    else if (this.router.url === NAVIGATIONS.PERFORATED.route)
      this.service = this.perforatedService;
    
    this.dtOptions = {
      data: null,
      columns: null,
      responsive: true,
    };
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
          
    this.initDt();
  }

  submit() {
    this.closeModal();
    this.service.submit();
  }

  private initDt() {
    this.dataCheckData$ = this.service.dataCheckData$.subscribe(
      (dtData) => {
        this.isSubmitting = dtData.isSubmitting;
        this.isSubmitDisabled = dtData.anySectionWithErrors;

        if (dtData.data.length === 0) {
          this.closeModal();
          if (!this.isSubmitting) {
            Swal.fire({
              title: "Successful Data Check",
              text: "There are no errors or warnings.",
              icon: "success",
              confirmButtonColor: "#009",
              timer: 3000,
              timerProgressBar: true,
            });
          }
          else if (this.isSubmitting && !dtData.anySectionWithErrors) {
            this.service.submit();
          }
        }

        this.dtData = dtData.data;
        this.section = dtData.section;
        this.configDt(this.service);
      }
    );
  }

  private configDt(service: SlottedService | PerforatedService) {
    // 'that' is used to send context to jQuery function
    let that = this;

    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance
        .then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();

          $("table tbody")
            .off()
            .on("click", "ol li", function (e) {
              service.setDataCheckFix(
                that.dtData[$(this).attr("idata")].options[$(this).attr("ioption")].data,
                that.section,
                that.isSubmitting
              );

              e.stopPropagation();
            });
        })
        .finally(() => {
          this.el.nativeElement.className = "none";
        });
    } else this.dtTrigger.next();
  }

  private closeModal(): void {
    this.closeBtn.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.dataCheckData$.unsubscribe();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if (!this.dtTrigger.closed)
        this.dtTrigger.next();
    });
  }
}
