<div
  id="dcpModal"
  tabindex="-1"
  role="dialog"
  class="modal fade modal-colored-header"
>
  <div class="modal-dialog modal-lg">
    <div style="background-color: #009">
      <div class="panel-heading headering">
        <h1>Product Searcher</h1>
        <button #closeDcpModal type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
    </div>

    <div class="panel-body" style="background-color: white; color: black">
      <app-dcp-filter [dcpService]="dcpService"></app-dcp-filter>

      <app-dcp-table></app-dcp-table>
    </div>
  </div>
</div>
