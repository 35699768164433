import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AttachmentsService } from "src/app/services/attachments/attachments.service";
import Swal from "sweetalert2";
import {
  Attachment,
  LoadSlottedPerforatedAttachments,
} from "../../models/slotted-pipe";
import { SlottedService } from "../../services/slotted.service";

@Component({
  selector: "app-slotted-attachments",
  templateUrl: "./slotted-attachments.component.html",
  styleUrls: ["./slotted-attachments.component.css"],
})
export class SlottedAttachmentsComponent implements OnInit {
  @Input() fgSlottedPipe: FormGroup;

  attachments: Attachment[] = [];
  attachmentsUploaded: LoadSlottedPerforatedAttachments[] = [];

  constructor(
    private slottedService: SlottedService,
    private attachmentsService: AttachmentsService
  ) {}

  ngOnInit(): void {
    this.slottedService.attachments$.subscribe((attachments) => {
      this.attachments = attachments;
    });

    this.slottedService.attachmentsUploaded$.subscribe((attachments) => {
      this.attachmentsUploaded = attachments;
    });
  }

  onSelect(event) {
    event.addedFiles.reduce(async (previousPromise, nextFileAdded) => {
      await previousPromise;
      return this.confirmAddAttachment(nextFileAdded);
    }, Promise.resolve());
  }

  private confirmAddAttachment(fileAdded: any) {
    return new Promise<void>((resolve) => {
      let file: File = fileAdded;

      //Indexes to check override if exists
      let attachmentIndex = this.attachments.findIndex(
        (x) => x.file.name === file.name
      );
      let attachmentUploadedIndex = this.attachmentsUploaded.findIndex(
        (x) => x.file_name === file.name
      );

      const INDEX_NOT_FOUND = -1;
      const ATTACHMENT_UPLOADING_EXIST = attachmentIndex !== INDEX_NOT_FOUND;
      const ATTACHMENT_UPLOADED_EXIST =
        attachmentUploadedIndex !== INDEX_NOT_FOUND;
      const ATTACHMENT_EXIST =
        ATTACHMENT_UPLOADING_EXIST || ATTACHMENT_UPLOADED_EXIST;

      let attachment: Attachment = {
        file: file,
        overwrite: false,
      };

      if (ATTACHMENT_EXIST)
        this.confirmReplace(
          attachment,
          ATTACHMENT_UPLOADED_EXIST,
          ATTACHMENT_UPLOADING_EXIST,
          attachmentUploadedIndex
        ).then(() => {
          resolve();
        });
      else {
        this.addAttachment(attachment);
        resolve();
      }
    });
  }

  private confirmReplace(
    attachment: Attachment,
    attachmentUploadedExist: boolean,
    attachmentUploadingExist: boolean,
    attachmentUploadedIndex: number
  ) {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: "Are you sure?",
        html: `The <b>${attachment.file.name}</b> attachment will replace an attachment that is already uploaded or an attachment that will be uploaded`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      })
        .then((result) => {
          if (result.isConfirmed) {
            if (attachmentUploadedExist) {
              attachment.overwrite = true;
              this.attachmentsUploaded[attachmentUploadedIndex].overwritten =
                true;
            }

            this.addAttachment(attachment);

            if (attachmentUploadingExist) {
              this.attachments.splice(
                this.attachments.findIndex(
                  (x) => x.file.name === attachment.file.name
                ),
                1
              );
            }

            this.slottedService.setAttachments(this.attachments);
            this.slottedService.setAttachmentsUploaded(this.attachmentsUploaded);
          }
        })
        .finally(() => {
          resolve();
        });
    });
  }

  private addAttachment(attachment: Attachment) {
    this.attachments.push(attachment);
    this.slottedService.setAttachments(this.attachments);
  }

  onRemove(event) {
    let attachment: Attachment = event;

    this.attachments.splice(this.attachments.indexOf(attachment), 1);

    if (attachment.overwrite) {
      let attachmentUploadedIndex = this.attachmentsUploaded.findIndex(
        (x) => x.file_name === attachment.file.name
      );

      this.attachmentsUploaded[attachmentUploadedIndex].overwritten = false;
    }

    this.slottedService.setAttachments(this.attachments);
    this.slottedService.setAttachmentsUploaded(this.attachmentsUploaded);
  }

  confirmDeleteAttachment(attachment: LoadSlottedPerforatedAttachments) {
    Swal.fire({
      title: "Are you sure?",
      text: "The attachment will be deleted permanently",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteAttachment(attachment);
      }
    });
  }

  deleteAttachment(attachment: LoadSlottedPerforatedAttachments) {
    this.attachmentsService.delete(attachment.id).subscribe(() => {
      if (attachment.overwritten) {
        let attachmentIndex = this.attachments.findIndex(
          (x) => x.file.name === attachment.file_name
        );

        this.attachments[attachmentIndex].overwrite = false;
      }

      this.attachmentsUploaded.splice(
        this.attachmentsUploaded.findIndex(
          (x) => x.file_name === attachment.file_name
        ),
        1
      );
      
      this.slottedService.setAttachments(this.attachments);
      this.slottedService.setAttachmentsUploaded(this.attachmentsUploaded);
    });
  }

  downloadAttachment(id: number) {
    this.attachmentsService.download(id).subscribe();
  }
}
