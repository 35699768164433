import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './admin.guard';
import { CasingwearComponent } from './modules/CasingWear/pages/Casingwear.component';
import { CorrosionRateComponent } from './modules/CorrosionRate/pages/corrosion-rate.component';
import { PerforatedPipeComponent } from './modules/slotted-perforated-pipe/perforated-pipe/perforated-pipe.component';
import { SlottedPipeComponent } from './modules/slotted-perforated-pipe/slotted-pipe/slotted-pipe.component';
import { AppmgrComponent } from './pages/appmgr/Appmgr.component';
import { LoginComponent } from './pages/auth/login/Login.component';
import { AdvancedpipeComponent } from './pages/home/advancedpipe/Advancedpipe.component';
import { CorrosionclasComponent } from './pages/home/corrosionclas/Corrosionclas.component';
import { HelpComponent } from './pages/home/help/help.component';
import { HomeComponent } from './pages/home/Home.component';
import { TestComponent } from './pages/home/test/Test.component';
import { LayoutComponent } from './pages/layout/Layout.component';
import { CasingwearpdfComponent } from './pages/reports/casingwear/Casingwearpdf.component';
import { CorrosionclaspdfComponent } from './pages/reports/corrosionclass/Corrosionclaspdf.component';
import { StatisticsComponent } from './pages/statistics/Statistics.component';
import { UnauthorizedComponent } from './pages/unauthorized/Unauthorized.component';
import { UsermgrComponent } from './pages/usermgr/Usermgr.component';
import { UserGuard } from './user.guard';
import { CustomermgrComponent } from './modules/customermgr/customermgr.component';
import { MatSelGuidelinesComponent } from './modules/mat-sel-guidelines/mat-sel-guidelines.component';

const appRoutes: Routes = [
  {path: '', 
  component: LayoutComponent,
  children:[
    {path: '',redirectTo: '/home', pathMatch: 'full'},
    {path:'advancedpipe', data:{role:'PipeRating'}, canActivate: [AdminGuard], component:AdvancedpipeComponent},
    {path:'casingwear', data:{role:'Casing Wear'}, canActivate: [AdminGuard], component:CasingwearComponent},
    {path:'corrosionclas',data:{role:'CorrisonClass'}, canActivate: [AdminGuard], component:CorrosionclasComponent},
    {path:'corrosionclas/:id/:action',data:{role:'CorrisonClass'}, canActivate: [AdminGuard], component:CorrosionclasComponent},
    {path:'corrosionrate',data:{role:'13Cr'}, canActivate: [AdminGuard], component:CorrosionRateComponent},
    {path:'slotted',data:{role:'SlottedPipe'}, canActivate: [AdminGuard], component:SlottedPipeComponent},
    {path:'perforated',data:{role:'SlottedPipe'}, canActivate: [AdminGuard], component:PerforatedPipeComponent},
    {path:'appmgr',data:{role:'Settings'}, canActivate: [AdminGuard], component:AppmgrComponent},
    {path:'statistics',data:{role:'Statitics'}, canActivate: [AdminGuard], component:StatisticsComponent},
    {path:'usermgr',data:{role:'Settings'}, canActivate: [AdminGuard], component:UsermgrComponent},
    {path:'customermgr',data:{role:'Customer'}, canActivate: [AdminGuard], component:CustomermgrComponent},
    {path:'isoguidelines',data:{role:'ISO15156'}, canActivate: [AdminGuard], component:MatSelGuidelinesComponent},
    {path:'home', canActivate: [UserGuard], component:HomeComponent},
    {path:'help',canActivate:[UserGuard],component:HelpComponent},
    {path:'test', canActivate: [UserGuard], component:TestComponent},
  ]
  },
  {path:'corrosionclaspdf/:id', component:CorrosionclaspdfComponent, pathMatch: "full"},
  {path:'casingwearpdf', component:CasingwearpdfComponent,pathMatch: "full"},
  {path:'login', component:LoginComponent, pathMatch: "full"},
  {path:'**', redirectTo: 'home'},
  {path: 'unauthorized', component: UnauthorizedComponent },
  {path: 'forbidden', component: UnauthorizedComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes),
  ],

  exports: [RouterModule],

})
export class AppRoutingModule { 
  // RouterModule.forRoot(appRoutes, { useHash: true }),
}
//RouterModule.forRoot(appRoutes, { useHash: true }),
