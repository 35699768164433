import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DcpShortNames } from "../../../../enums/dcp-short-names.enum";
import { ComboData } from "../../../models/dcp-modal";

@Component({
  selector: "app-sub-filters",
  templateUrl: "./sub-filters.component.html",
  styleUrls: ["./sub-filters.component.css"],
})
export class SubFiltersComponent implements OnInit {
  @Output() changeSubFilters = new EventEmitter();

  @Input() fgFilters: FormGroup;
  @Input() filters: { [key in DcpShortNames]: ComboData[] };

  constructor() {}

  ngOnInit(): void {
    this.fgFilters.get("fgSubFilters").valueChanges.subscribe(() => {
      this.changeSubFilters.emit(this.fgFilters.get("fgMainFilters").valid);
    });
  }

  public get dcpShortNames(): typeof DcpShortNames {
    return DcpShortNames;
  }
}
