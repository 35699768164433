export enum HeaderState {
  default = '',
  new = 'new',
  load = 'load',
  save = 'save',
  delete = 'delete',
  report = 'report',
  cancel = 'cancel',
  validate = 'validate',
  download = 'download'
}