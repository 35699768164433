import { Component, Input, OnInit } from "@angular/core";
import { OpenIdConfiguration } from "angular-auth-oidc-client";
import { ActionService } from "src/app/services/Action.service";
import { Subscription } from "rxjs";
import { LoginService } from "src/app/pages/auth/login/Login.service";
import { HeaderState } from "src/app/models/header/HeaderState";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "src/app/services/User.service";
import { UserMgr } from "src/app/pages/usermgr/components/Usermgr.model";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PrintedModalData } from "src/app/modules/CasingWear/models/printed";
import { Router } from "@angular/router";
import { NAVIGATIONS } from "../../consts/navigations";
import { DataService } from "src/app/services/Data.service";
import { environment } from "src/environments/environment";
import { PreSimulationService } from "../pre-simulation/service/pre-simulation.service";
import { MatSelGuidelinesComponentsService } from "src/app/modules/mat-sel-guidelines/services/mat-sel-guidelines-components.service";

declare var jQuery: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  providers: [LoginService],
})
export class HeaderComponent implements OnInit {
  @Input()
  showCorrosionClas: boolean;
  showCorrosionRate: boolean;
  showCasingWear: boolean;
  showIcons: boolean;
  nameUser: string = null;
  @Input() imagePath: any;
  configurations: OpenIdConfiguration[];

  enabledButtonReportCC: boolean = true;
  message: string;
  subscription: Subscription;
  userMgrDataArray: UserMgr[];
  userMgrData: UserMgr;
  showModalReport = false;
  currentUrl: string = "";

  fgCreateReport: FormGroup;

  validReportCw: boolean = false;
  isOutputVisible: boolean = false;
  isSimulationSaved: boolean = false;
  validReportCR: boolean = false;
  validReportCC: boolean = false;
  validReportSP: boolean = false;
  validReportPR: boolean = false;
  validReportMSG: boolean = false;
  readonly NAVIGATIONS = NAVIGATIONS;

  opt1: boolean = false;
  opt2: boolean = false;
  cra: boolean = false;

  constructor(
    private actionService: ActionService,
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    private serviceUser: UserService,
    private router: Router,
    private fb: FormBuilder,
    public dataService: DataService,
    private preSimulationService: PreSimulationService,
    private matSelGuidelinesComponentsService: MatSelGuidelinesComponentsService
  ) {}

  logout(configId: string) {
    this.loginService.logout(configId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.dataService.currentValidateReportCWSource.subscribe(
      (validReport) => (this.validReportCw = validReport)
    );
    this.dataService.currentValidateReportCCSource.subscribe(
      (validReport) => (this.validReportCC = validReport)
    );
    this.dataService.currentValidateReportCRSource.subscribe(
      (validReport) => (this.validReportCR = validReport)
    );
    this.dataService.currentValidateReportSPSource.subscribe(
      (validReport) => (this.validReportSP = validReport)
    );
    this.dataService.currentValidateReportPRSource.subscribe(
      (validReport) => (this.validReportPR = validReport)
    );
    this.dataService.currentValidateReportMSGSource.subscribe(
      (validReport) => (this.validReportMSG = validReport)
    );
    let createReportStoredValues = JSON.parse(
      sessionStorage.getItem("createReport")
    );

    this.fgCreateReport = this.fb.group({
      customer: [createReportStoredValues?.customer ?? ""],
      country: [createReportStoredValues?.country ?? ""],
      SDCaseNumber: [createReportStoredValues?.SDCaseNumber ?? ""],
      requestedBy: [createReportStoredValues?.requestedBy ?? ""],
      reviewedBy: [createReportStoredValues?.reviewedBy ?? ""],
      authorizedBy: [createReportStoredValues?.authorizedBy ?? ""],
      preMachiningManufacturer: [
        createReportStoredValues?.preMachiningManufacturer ?? "",
      ],
      printOnlyFirstPage: [
        createReportStoredValues?.printOnlyFirstPage ?? false,
      ],
      option1: [],
      option2: [],
      cra: [],
    });

    this.resetValidationPrintForm();

    this.dataService.simulationIdName$.subscribe((simulationPath) => {
      this.fgCreateReport.get("SDCaseNumber").setValue(simulationPath);
    });

    this.fgCreateReport.valueChanges.subscribe(() => {
      sessionStorage.setItem(
        "createReport",
        JSON.stringify(this.fgCreateReport.value)
      );
    });

    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
      this.actionService.changeAction(HeaderState.default);

      this.showModalReport = false;

      this.resetValidationPrintForm();
    });

    this.configurations = this.loginService.currentConfig();

    this.dataService.currentNameSurnameSource.subscribe(
      (name) => (this.nameUser = name)
    );

    this.preSimulationService.customerDescriptionChanges$.subscribe(
      (description) => {
        this.fgCreateReport.get("customer").setValue(description);
      }
    );

    this.preSimulationService.countryDescriptionChanges$.subscribe(
      (description) => {
        this.fgCreateReport.get("country").setValue(description);
      }
    );
  }

  onReportClickCcValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportCC == true &&
      this.preSimulationService.getFormLoadedValidity() &&
      this.validateIfPreSimulationSaved(
        this.preSimulationService.getSavedCustomerId(),
        this.preSimulationService.getSavedCountryId()
      )
    ) {
      this.showModalReport = true;
    }
  }

  onReportClickCwValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportCw == true &&
      this.preSimulationService.getFormLoadedValidity()
    )
      this.showModalReport = true;
  }

  onReportClickCRValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportCR == true &&
      this.preSimulationService.getFormLoadedValidity() &&
      this.validateIfPreSimulationSaved(
        this.preSimulationService.getSavedCustomerId(),
        this.preSimulationService.getSavedCountryId()
      )
    ) {
      this.showModalReport = true;
    }
  }

  onReportClickSPValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportSP == true &&
      this.preSimulationService.getFormLoadedValidity() &&
      this.validateIfPreSimulationSaved(
        this.preSimulationService.getSavedCustomerId(),
        this.preSimulationService.getSavedCountryId()
      )
    )
      this.showModalReport = true;
  }

  onReportClickPRValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportPR == true &&
      this.preSimulationService.getFormLoadedValidity() &&
      this.validateIfPreSimulationSaved(
        this.preSimulationService.getSavedCustomerId(),
        this.preSimulationService.getSavedCountryId()
      )
    )
      this.showModalReport = true;
  }

  onReportClickMSGValidate(): void {
    this.showModalReport = false;
    this.actionService.changeAction(HeaderState.validate);
    if (
      this.validReportMSG == true &&
      this.preSimulationService.getFormLoadedValidity() &&
      this.validateIfPreSimulationSaved(
        this.preSimulationService.getSavedCustomerId(),
        this.preSimulationService.getSavedCountryId()
      )
    ) {
      this.opt1 = this.matSelGuidelinesComponentsService.getIsPrinteableOpt1()
        ? true
        : false;
      this.opt2 = this.matSelGuidelinesComponentsService.getIsPrinteableOpt2()
        ? true
        : false;
      this.cra = this.matSelGuidelinesComponentsService.getIsPrinteableCra()
        ? true
        : false;

      this.fgCreateReport.get("option1").setValue(this.opt1);

      if (!this.opt1) this.fgCreateReport.get("option1").disable();

      this.fgCreateReport.get("option2").setValue(this.opt2);

      if (!this.opt2) this.fgCreateReport.get("option2").disable();

      this.fgCreateReport.get("cra").setValue(this.cra);

      if (!this.cra) this.fgCreateReport.get("cra").disable();

      this.showModalReport = true;
    }
  }

  private validateIfPreSimulationSaved(
    customerId: number | null,
    countryId: number | null
  ): boolean {
    let isSaved = true;

    if (
      this.preSimulationService.getCurrentCountryId() == null ||
      this.preSimulationService.getCurrentCustomerId() == null ||
      this.preSimulationService.getCurrentCountryId() != countryId ||
      this.preSimulationService.getCurrentCustomerId() != customerId
    )
      isSaved = false;

    return isSaved;
  }

  private resetValidationPrintForm() {
    this.dataService.changeValidateReportCW(false);
    this.dataService.changeValidateReportCR(false);
    this.dataService.changeValidateReportCC(false);
    this.dataService.changeValidateReportSP(false);
    this.dataService.changeValidateReportPR(false);
    this.dataService.changeValidateReportMSG(false);
  }

  onReportClick(): void {
    if (this.fgCreateReport.invalid) {
      this.fgCreateReport.markAllAsTouched();
      return;
    }

    let printedModalData: PrintedModalData = {
      SDCaseNumber: this.fgCreateReport.get("SDCaseNumber").value,
      requestedBy: this.fgCreateReport.get("requestedBy").value,
      reviewedBy: this.fgCreateReport.get("reviewedBy").value,
      authorizedBy: this.fgCreateReport.get("authorizedBy").value,
      printOnlyFirstPage: this.fgCreateReport.get("printOnlyFirstPage").value,
      preMachiningManufacturer: this.fgCreateReport.get(
        "preMachiningManufacturer"
      ).value,
    };

    this.actionService.data = printedModalData;
    this.actionService.opt1Checked = this.fgCreateReport.get("option1").value;
    this.actionService.opt2Checked = this.fgCreateReport.get("option2").value;
    this.actionService.craChecked = this.fgCreateReport.get("cra").value;
    jQuery("#form-create-report").modal("hide");
    this.actionService.changeAction(HeaderState.report);
  }

  onDownloadClick(): void {
    this.actionService.changeAction(HeaderState.download);
  }

  onNewClick() {
    this.actionService.changeAction(HeaderState.new);
  }

  onLinkToManualClick() {
    switch (this.currentUrl) {
      case NAVIGATIONS.CASING_WEAR.route:
        window.open(environment.manualsUrl.casingWear, "_blank");
        break;
      case NAVIGATIONS.CORROSION_CLAS.route:
        window.open(environment.manualsUrl.corrosionClas, "_blank");
        break;
      case NAVIGATIONS.CORROSION_RATE.route:
        window.open(environment.manualsUrl.corrosionRate, "_blank");
        break;
      case NAVIGATIONS.SLOTTED.route:
        window.open(environment.manualsUrl.slottedPipe, "_blank");
        break;
      case NAVIGATIONS.PERFORATED.route:
        window.open(environment.manualsUrl.prePerforatedPipe, "_blank");
        break;
      case NAVIGATIONS.MAT_SEL_GUIDELINES.route:
        window.open(environment.manualsUrl.matSelGuidelines, "_blank");
        break;
      default:
        break;
    }
  }

  onDeleteClick() {
    this.showRightSidebar();
    this.actionService.changeAction(HeaderState.delete);
  }

  onLoadClick() {
    this.showRightSidebar();
    this.actionService.changeAction(HeaderState.load);
  }

  onSaveClick() {
    this.showRightSidebar();
    this.actionService.changeAction(HeaderState.save);
  }

  showRightSidebar() {
    jQuery("#sidebar").addClass("active");
    jQuery(".overlay").addClass("active");
    jQuery(".collapse.in").toggleClass("in");
    jQuery("a[aria-expanded=true]").attr("aria-expanded", "false");
  }
}
