import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DcpService } from "src/app/services/dcp/dcp.service";
import { PerforatedService } from "../../perforated-pipe/services/perforated.service";
import { SlottedService } from "../../slotted-pipe/services/slotted.service";
import { DcpFilterComponent } from "./components/dcp-filter/dcp-filter.component";
import { Datasheet, SearchItemsRequest } from "./models/dcp-modal";

@Component({
  selector: "app-slotted-modal",
  templateUrl: "./slotted-modal.component.html",
  styleUrls: ["./slotted-modal.component.css"],
})
export class SlottedModalComponent implements OnInit {
  searchItemsRequest: SearchItemsRequest;
  dcpProducs: Datasheet[];

  @ViewChild(DcpFilterComponent) dcpFilterComponent: DcpFilterComponent;
  @ViewChild('closeDcpModal') closeDcpModal: ElementRef;

  constructor(public dcpService: DcpService, private slottedService: SlottedService, private perforatedService: PerforatedService) {}

  ngOnInit() {
    this.slottedService.closeDcpModal$.subscribe((closeDcpModal) => {
      if (closeDcpModal) this.closeModal();
    });
    this.perforatedService.closeDcpModal$.subscribe((closeDcpModal) => {
      if (closeDcpModal) this.closeModal();
    });
  }

  resetModal() {
    this.dcpFilterComponent.resetFilters();
  }

  preloadFilters(filterValues: any) {
    this.dcpFilterComponent.preloadFilters(filterValues);
  }

  closeModal() {
    this.closeDcpModal.nativeElement.click();
  }
}
