import { TreeUser } from "src/app/models/tree/Index";

export function getTreeVisible(users: TreeUser[]): TreeUser[] {

  for (let i1 = 0; i1 < users.length; i1++) {
    users[i1].visible = true;
    for (let i2 = 0; i2 < users[i1].customers.length; i2++) {
      users[i1].customers[i2].visible = true;
      for (let i3 = 0; i3 < users[i1].customers[i2]?.regions.length; i3++) {
        users[i1].customers[i2].regions[i3].visible = true;
        for (let i4 = 0; i4 < users[i1].customers[i2]?.regions[i3]?.projects.length; i4++) {
          users[i1].customers[i2].regions[i3].projects[i4].visible = true;
          for (let i5 = 0; i5 < users[i1].customers[i2].regions[i3].projects[i4].simulations.length; i5++) {
            users[i1].customers[i2].regions[i3].projects[i4].simulations[i5].visible = true;
          }
        }
      }
    }
  }
  
  return users;
}