import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserMgr } from "../pages/usermgr/components/Usermgr.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userMgrDataService: UserMgr[];

  constructor(private http: HttpClient) {}

  getAllUser() {
    // const url = `${environment.apiEndpoint}UpdateUserEdit/${this.userData.id}`;
    const url = `${environment.apiEndpoint}usermgr/v1/User/GetUsers`;
    return this.http.get<any>(url);
    // return this.http.get<any>('https://localhost:44331/api/usermgr/v1/User/GetUsers');
  }

  // getUser(id:number){
  //   return this.http.get<any>('https://localhost:44331/api/usermgr/v1/User/GetUsers');
  //     // return this.userMgrDataService.find(item => id === item.id);
  // }

  getHttpFoto(isLogged: boolean) {
    let httpParams: HttpParams = new HttpParams()
    .set("isLogged", isLogged);

    const url = `${environment.apiEndpoint}usermgr/v1/User/GetProfile`;
    return this.http.get<any>(url, { params: httpParams });
  }
}
