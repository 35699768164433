<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">ISO 15156 Guidelines</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.MAT_SEL_GUIDELINES.name }}</li>
      </ol>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div
            [ngStyle]="{ border: !isPreSimulationFormValid ? 'none' : '' }"
            class="panel-heading"
          >
            <div style="font-size: 16px">
              <div *ngIf="simulationName == ''; else elseBlock">Simulation</div>
              <ng-template #elseBlock>
                <div>
                  <strong> Simulation: {{ simulationName }} </strong>
                </div></ng-template
              >
            </div>
            <app-pre-simulation #preSimulation></app-pre-simulation>
          </div>

          <div [ngStyle]="{'display': !isPreSimulationFormValid ? 'none' : ''}" class="panel-body">
              <div class="tab-container">
                <ul class="nav nav-tabs">
                  <li class="active">
                    <a href="#opt1" data-toggle="tab">ISO 15156-2:2020 Option 1</a>
                  </li>
                  <li><a href="#opt2" data-toggle="tab">ISO 15156-2:2020 Option 2</a></li>
                  <li><a href="#cra" data-toggle="tab">ISO 15156-3:2020 (CRA)</a></li>
                </ul>
                <div class="tab-content">
                  <div id="opt1" class="tab-pane active cont">
                    <app-option1></app-option1>
                  </div>
                  <div id="opt2" class="tab-pane cont">
                    <app-option2></app-option2>
                  </div>
                  <div id="cra" class="tab-pane cont">
                    <app-cra></app-cra>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-create-customer-modal></app-create-customer-modal>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
  >
</div>
