import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from "rxjs";
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CorrosionClasInput, CorrosionClas } from '../models/corrosionclas/Index';

@Injectable({
  providedIn: 'root'
})
export class CorrosionclasService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) { }

  getSimulationById(id: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/byId/${id}`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
    //const ret = this.sims.filter(sim => sim.id == id);
    //return of(ret[0]);
  }

  getSimulationByTreeId(treeId: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/byTreeId/${treeId}`;
    const resp = this.http.get(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
    return resp;
    //const ret = this.sims.filter(sim => sim.id == id);
    //return of(ret[0]);
  }

  postCalculation(inputValues: CorrosionClasInput): Observable<any> {
    let url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/calculation`;
    let body = JSON.stringify(inputValues);
    const resp = this.http.post(url, body, this.httpOptions).pipe(
      tap( (resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
      return resp;
    //return of(this.resp);
  }

  postSimulation(formData: CorrosionClas): Observable<any> {
    let url = `${environment.apiEndpoint}corrosionclas/v1/Simulation`;
    let body = `{"createSimulationRequest":${JSON.stringify(formData)}}`;
    const resp = this.http.post(url, body, this.httpOptions).pipe(
      tap( (resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
      return resp;
    //return of(this.resp);
  }

  putSimulation(id: number, formData: CorrosionClas): Observable<any> {
    let url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/${id}`;
    let body = `{"updateSimulationRequest":${JSON.stringify(formData)}}`;
    const resp = this.http.put(url, body, this.httpOptions).pipe(
      tap( (resp: any) => resp),
      catchError(err => {
        throw err.message;
      }));
      return resp;
    //return of(this.resp);
  }

  deleteSimulation(id: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/${id}`;
    const resp = this.http.delete(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        return of(null);
      }));
    return resp;
    //return of(this.sims);
  }

  deleteSimulationByTree(treeId: number): Observable<any> {
    const url = `${environment.apiEndpoint}corrosionclas/v1/Simulation/byTree/${treeId}`;
    const resp = this.http.delete(url, this.httpOptions).pipe(
      tap((resp: any) => resp),
      catchError(err => {
        return of(null);
      }));
    return resp;
    //return of(this.sims);
  }

  registerLog(request: any) {
    const httpOptionsCustom = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Loading': 'true'
      })
    }

    const URL: string = `${environment.apiEndpoint}corrosionclas/v1/Simulation/RegisterLogs`;

    return this.http.post<any>(URL, request, httpOptionsCustom).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }
}
