import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { UnitMeasures } from "../enums/unit-measures.enum";
import { UnitMeasureService } from "../../services/unit-measures.service";
import { Subscription } from "rxjs";

@Directive({
  selector: "[convertUnit]",
})
export class ConvertUnitDirective implements OnInit, OnDestroy {
  @Input() unitMeasure: UnitMeasures;
  @Input() convertedControl: AbstractControl;
  @Input() defaultControl: AbstractControl;
  @Input() object?: any;
  controlValueChanges: Subscription;

  constructor(
    private ngControl: NgControl,
    private unitMeasureService: UnitMeasureService
  ) {}

  ngOnInit(): void {
    this.configureControlValueChanges();
  }

  private configureControlValueChanges() {
    let defaultValue: number;
    let convertedValue: number;
    this.controlValueChanges = this.ngControl.control.valueChanges.subscribe(
      (controlValue: any) => {
        if (this.convertedControl.valid) {
          defaultValue = this.defaultControl.value;
          convertedValue =
            defaultValue != null
              ? this.unitMeasureService.convertUnit(
                  defaultValue,
                  this.unitMeasure,
                  controlValue,
                  false,
                  this.unitMeasureService.getUnitFractionDigits(
                    this.unitMeasure,
                    controlValue
                  ),
                  this.object
                )
              : null;

          this.convertedControl.setValue(convertedValue, { emitEvent: false });
          this.defaultControl.setValue(defaultValue);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.controlValueChanges.unsubscribe();
  }
}
