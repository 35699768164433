import { PercentageUnits } from "../enums/percentage-units.enum";

export const PERCENTAGE_UNITS = {
  PERCENTAGE: {
    name: "Percent",
    symbol: "%",
    enum: PercentageUnits.Percent,
    fractionDigits: 4,
  },
  PPM: {
    name: "Parts per million",
    symbol: "ppm",
    enum: PercentageUnits.Ppm,
    fractionDigits: 4,
  },
};

export const PERCENTAGE_UNITS_ARRAY: PercentageUnit[] = [
  PERCENTAGE_UNITS.PERCENTAGE,
  PERCENTAGE_UNITS.PPM,
];

class PercentageUnit {
  name: string;
  symbol: string;
  enum: PercentageUnits;
  fractionDigits: number;
}
