<style>
  .menu-link {
    padding: 5px 0 !important;
    cursor: pointer !important;
  }

  .menu-link hr {
    margin: 2px;
    padding: 2px;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  #dismissContextMenu {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #fff;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  #dismissContextMenu:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #000050;
  }
</style>

<ng-container>
  <div id="dismissContextMenu">
    <span><i class="fa fa-times" aria-hidden="true"></i></span>
  </div>
  <div class="menu-link" *ngFor="let menuItem of contextMenuItems; index as i"
    (click)="onContextMenuClick($event, menuItem.menuEvent)">
    <hr *ngIf="i < contextMenuItems.length - 1 && menuItem.menuText == 'Sep'; else showItem" />
    <ng-template #showItem>
      <span *ngIf="menuItem.menuIcon !== null"><i class={{menuItem.menuIcon}}></i></span> {{ menuItem.menuText }}
    </ng-template>
  </div>
  <hr>
  <div>
    <p>References:</p>
    <p><span class="s7-user" style="font-size: 20px;"></span>&nbsp;User
    <br/><span class="s7-culture" style="font-size: 20px;"></span>&nbsp;Customer
    <br/><span class="s7-global" style="font-size: 20px;"></span>&nbsp;Region
    <br/><span class="s7-portfolio" style="font-size: 20px;"></span>&nbsp;Project
    <br/><span class="s7-science" style="font-size: 20px;"></span>&nbsp;Simulation</p>
  </div>
</ng-container>