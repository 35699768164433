import { Component } from "@angular/core";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
@Component({
  selector: "app-help",
  templateUrl: "help.component.html",
})
export class HelpComponent {
  readonly NAVIGATIONS = NAVIGATIONS;

  constructor() {}
}
