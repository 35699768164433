import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FilterAction } from "../models/Filteraction";
import { CasingwearService } from "../services/Casingwaer.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  CasingWearArrayReq,
  CasingWearObjReq,
  GetPercentagesRequest,
  SeriesRequest,
  SeriesResponse,
} from "../models/Casingwear";
import { ActionService } from "src/app/services/Action.service";
import { Subscription } from "rxjs";
import { HeaderState } from "src/app/models/header/HeaderState";
import Swal from "sweetalert2";
import { PrintedModalData, PrintedCWRequest } from "../models/printed";
import { PdfService } from "src/app/services/pdf.service";
import { debounce } from "src/app/shared/helpers/debounce-decorator";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { DataService } from "src/app/services/Data.service";
import { take } from "rxjs/operators";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";

declare var jQuery: any;
declare var Morris: any;
var graph;
@Component({
  selector: "app-casingwear",
  templateUrl: "casingwear.component.html",
})
export class CasingwearComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener("window:resize", ["$event"])
  @debounce(700)
  onResize(event) {
    if (this.graph_data.some((x) => !x.length)) graph.redraw();
  }

  subActionService: Subscription;

  title = "Casing Wear";
  casingWearData: CasingWearObjReq[] = [];
  filterRow: CasingWearObjReq;
  casingWearOne: CasingWearArrayReq;
  imageString: any[] = new Array<any>(4);

  imagePath: Imagen[] = [
    { showImage: false, base64: "" },
    { showImage: false, base64: "" },
    { showImage: false, base64: "" },
    { showImage: false, base64: "" },
  ];
  @Input() radioOption: string = "imperial";
  wearPerVal: number = 0;
  maxX: number = 50;
  
  selected = "";
  showModalReport = false;
  isAdmin: boolean = false;
  items: FilterAction[] = [
    {
      action: "",
      nroItem: 0,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: true,
      titles: true,
      label: "",
    },
    {
      action: "",
      nroItem: 0,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: false,
      titles: false,
      label: "",
    },
    {
      action: "",
      nroItem: 0,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: false,
      titles: false,
      label: "",
    },
    {
      action: "",
      nroItem: 0,
      valFiltro0: 0,
      valFiltro1: 0,
      valFiltro2: 0,
      valFiltro3: 0,
      valFiltro4: "",
      visible: false,
      titles: false,
      label: "",
    },
  ];
  visible: boolean[] = [true, false, false, false];
  colors: string[] = ["#009900", "#CC0066", "#000099", "#666666"];
  labels: string[] = ["", "", "", ""];
  table_data: any[] = [{}, {}, {}, {}];
  graph_data: any[] = [];
  graph_labels: string [] = [];
  graph_ykeys: string [] = [];
  serie1 = [];
  serie2 = [];
  serie3 = [];
  serie4 = [];
  nameCurrentUser :string;
  rolCurrentUser:string;
  fgCroquis: FormGroup = this._fb.group({
    croquis: [true],
  });
  validReportCw: boolean=false;
  wearPercentages: any[] = new Array(4);

  isPreSimulationFormValid: boolean = false;

  readonly NAVIGATIONS = NAVIGATIONS;

  constructor(
    private service: CasingwearService,
    private sanitizer: DomSanitizer,
    public actionService: ActionService,
    private ngZone: NgZone,
    private _actionService: ActionService,
    private _pdfService: PdfService,
    private _fb: FormBuilder
    ,public dataService: DataService,
    private preSimulationService: PreSimulationService
  ) {}

  private initCroquis() {
    this.casingWearOne = {
      cant: this.casingWearData.length,
      CasingWearObj: this.casingWearData,
      isSameScale: this.fgCroquis.get("croquis").value,
      wearPercentage: this.wearPerVal,
    };

    this.getCroquis(this.casingWearOne);
  }

  changeUseSameScaleValue() {
    this.fgCroquis
      .get("croquis")
      .setValue(!this.fgCroquis.get("croquis").value);
  }

  ngOnInit(): void {
    this.getAction();
    this.dataService.currentRolSource.pipe(take(1)).subscribe(rol => (this.rolCurrentUser= rol));
    this.dataService.currentNameSurnameSource.subscribe(name => (this.nameCurrentUser= name))
    this.dataService.currentValidateReportCWSource.subscribe(validReport => (this.validReportCw= validReport));
    if (this.rolCurrentUser == "ADMIN" || this.rolCurrentUser == "SUPER_USER") {
      this.isAdmin = true;
      this.maxX = 90;
    } else {
      this.isAdmin = false;
      this.maxX = 50;
    }
    this.configurateFormCroquis();
    this.dataService.setSimulationIdName("");

    this.preSimulationService.formValidity$.subscribe(isValid => {
      this.isPreSimulationFormValid = isValid;
    });
  }

  configurateFormCroquis() {
    this.fgCroquis.get("croquis").valueChanges.subscribe(() => {
      this.initCroquis();
    });
  }

  ngAfterViewInit(): void {
    this.initGraph();
    jQuery("#graph-table").addClass("notView");
    jQuery("#maxAxis").bootstrapSlider();
    jQuery("#maxAxis").bootstrapSlider("setAttribute", "max", this.maxX);
    jQuery("#maxAxis").bootstrapSlider("setValue", this.maxX);
    jQuery("#wearPer").bootstrapSlider();
    jQuery("#wearPer").bootstrapSlider("setAttribute", "max", this.maxX);   
    jQuery("#wearPer").bootstrapSlider().on("slideStop", (e) => {
        this.wearPerVal = e.value;
        this.initGraph();
        this.getPercentages();
        this.initCroquis();
      });
    jQuery(() => {
      jQuery("#maxAxis").on("slideStop", (slideEvt) => {
        let mustGetPercentages = false;
        this.ngZone.run(() => {
          this.maxX = slideEvt.value;
        });

        jQuery("#wearPer").bootstrapSlider(
          "setAttribute",
          "max",
          slideEvt.value
        );

        if (slideEvt.value < this.wearPerVal) {
          this.wearPerVal = slideEvt.value;
          this.initCroquis();
          mustGetPercentages = true;
        }

        jQuery("#wearPer").bootstrapSlider("setValue", this.wearPerVal);

        this.createGraphData();
        //TODO: Check the last parameter, It is for optimization purposes by sliding the maximum X axis and not getting the percentages from the server.
        this.createTableData(null, mustGetPercentages ? false : true);
        this.initGraph();
      });
    });


  }

  inputChanged(value: any) {
    const targetValue = Number(value.target.value)??0;
    if (targetValue > this.maxX) {
      this.wearPerVal = this.maxX;
      jQuery("#wearPerVal").val(this.maxX);
    } else if (targetValue <= 0)
    {      
      this.wearPerVal = 0;
        jQuery("#wearPerVal").val(0);
    }

    jQuery("#wearPer").bootstrapSlider("setValue", targetValue);
    this.initGraph();
    this.getPercentages();
    this.initCroquis();
  }

  initGraph() {
    jQuery("#line-chart").empty();
    graph = null;
    graph = Morris.Line({
      element: "line-chart",
      data: this.graph_data,
      axes: true,
      grid: true,
      xkey: "x",
      ykeys: this.graph_ykeys,
      labels: this.graph_labels,
      hideHover: "auto",
      parseTime: false,
      behaveLikeLine: true,
      resize: false,
      pointFillColors: ["#ffffff"],
      pointStrokeColors: ["black"],
      lineColors: this.colors,
      postUnits: "%",
      events: [this.wearPerVal / 10],
      eventStrokeWidth: 3,
      eventLineColors: ["#000"],
    });
  }

  filterActionItem(filter: FilterAction) {
    if (filter.action == "new") {
      let ind: number = filter.nroItem + 1;
      if (ind > 4) {
        ind = 4;
      }
      this.items[ind].nroItem = ind;
      this.items[ind].visible = true;
      return;
    } else if (filter.action == "refresh") {
      this.graph_data = [];
      this.table_data[filter.nroItem] = {};
      this.labels[filter.nroItem] = "";
      this.imagePath[filter.nroItem].showImage = false;
      this.imagePath[filter.nroItem].base64 = "";
      this.imageString = this.imageString.filter(item => item.tableDataId !== filter.nroItem);

      let cwDataIndex = this.casingWearData.findIndex(
        (x) => x.id == filter.nroItem
      );

      if (cwDataIndex != -1) this.casingWearData.splice(cwDataIndex, 1);

      this['serie' + (filter.nroItem + 1)] = []

      if((this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)) > -1 && this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)) > -1)) {
        this.graph_labels.splice(this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)),1)
        this.graph_ykeys.splice(this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)),1)
      } 

      this.createGraphData();

      if (
        !this.serie1.length &&
        !this.serie2.length &&
        !this.serie3.length &&
        !this.serie4.length
      ) {
          jQuery("#graph-table").addClass("notView");
          this.dataService.changeValidateReportCW(false);
      } 
    } else if (filter.action == "clean") {
      this.graph_data = [];
      this.table_data[filter.nroItem] = {};
      this.labels[filter.nroItem] = "";
      this.imagePath[filter.nroItem].showImage = false;
      this.imagePath[filter.nroItem].base64 = "";
      this.imageString = this.imageString.filter(item => item.tableDataId !== filter.nroItem);

      this['serie' + (filter.nroItem + 1)] = []
      if((this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)) > -1 && this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)) > -1)) {
        this.graph_labels.splice(this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)),1)
        this.graph_ykeys.splice(this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)),1)
      } 
      this.createGraphData();
      graph.setData(this.graph_data);

      if (
        !this.serie1.length &&
        !this.serie2.length &&
        !this.serie3.length &&
        !this.serie4.length
      )
        this.dataService.changeValidateReportCW(false);
    } else if (filter.action == "delete") {
      this.graph_data = [];
      this.table_data[filter.nroItem] = {};
      this.labels[filter.nroItem] = "";
      this.imagePath[filter.nroItem].showImage = false;
      this.imagePath[filter.nroItem].base64 = "";
      this.items[filter.nroItem].visible = false;

      let cwDataIndex = this.casingWearData.findIndex(
        (x) => x.id == filter.nroItem
      );

      if (cwDataIndex != -1) this.casingWearData.splice(cwDataIndex, 1);

      this['serie' + (filter.nroItem + 1)] = []
      if((this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)) > -1 && this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)) > -1)) {
        this.graph_labels.splice(this.graph_labels.indexOf('Filter ' + (filter.nroItem + 1)),1)
        this.graph_ykeys.splice(this.graph_ykeys.indexOf('filtro' + (filter.nroItem + 1)),1)
      } 
      this.createGraphData();
      graph.setData(this.graph_data);
      
      if (
        !this.serie1.length &&
        !this.serie2.length &&
        !this.serie3.length &&
        !this.serie4.length
      ) {
          jQuery("#graph-table").addClass("notView");
          this.dataService.changeValidateReportCW(false);
      } 

      this.initCroquis();
    } else if (filter.action == "complete") {
      this.filterRow = {
        id: filter.nroItem,
        outside_diameter: filter.valFiltro0,
        wall_thickness: filter.valFiltro1,
        tool_join_od: filter.valFiltro2,
        grade: filter.valFiltro3,
        loadmode: filter.valFiltro4,
        percent: 0,
        lpfmax: 10,
        lpfend: 0,
        stadate: new Date(Date.now()),
      };

      this.showNewData(filter.nroItem, this.filterRow);

      let cwDataIndex = this.casingWearData.findIndex(
        (x) => x.id == filter.nroItem
      );

      if (cwDataIndex != -1) this.casingWearData.splice(cwDataIndex, 1);

      this.casingWearData.push(this.filterRow);
      this.casingWearData.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });

      this.initGraph();
      this.initCroquis();
      this.dataService.changeValidateReportCW(true);
    } else if (filter.action == "emptyData") {
      this.graph_data = [];
      this.table_data[filter.nroItem] = {};
      this.labels[filter.nroItem] = "";
      this.imagePath[filter.nroItem].showImage = false;
      this.imagePath[filter.nroItem].base64 = "";

      let cwDataIndex = this.casingWearData.findIndex(
        (x) => x.id == filter.nroItem
      );

      if (cwDataIndex != -1) this.casingWearData.splice(cwDataIndex, 1);

      if (filter.nroItem == 0) {
        this.serie1 = [];

        if (!this.serie2.length && !this.serie3.length && !this.serie4.length)
          jQuery("#graph-table").addClass("notView");
      } else if (filter.nroItem == 1) {
        this.serie2 = [];

        if (!this.serie1.length && !this.serie3.length && !this.serie4.length)
          jQuery("#graph-table").addClass("notView");
      } else if (filter.nroItem == 2) {
        this.serie3 = [];

        if (!this.serie1.length && !this.serie2.length && !this.serie4.length)
          jQuery("#graph-table").addClass("notView");
      } else if (filter.nroItem == 3) {
        this.serie4 = [];

        if (!this.serie1.length && !this.serie2.length && !this.serie3.length)
          jQuery("#graph-table").addClass("notView");
      }

      this.createGraphData();
      this.initCroquis();

      if (
        !this.serie1.length &&
        !this.serie2.length &&
        !this.serie3.length &&
        !this.serie4.length
      )
        this.dataService.changeValidateReportCW(false);
    }
  }

  showNewData(nroItem, row: CasingWearObjReq) {
    const label: string = `${row.outside_diameter}" x ${row.wall_thickness}" TJOD: ${row.tool_join_od}" ${row.grade} [ksi] ${row.loadmode}`;
    const params: SeriesRequest = {
      selOd: row.outside_diameter,
      selWt: row.wall_thickness,
      selOdGr: row.tool_join_od,
      selGrade: row.grade,
      selLoadMode: row.loadmode,
    };
    this.labels[nroItem] = label;
    this.table_data[nroItem] = {
      configuration: label.substring(0, label.indexOf("]") + 1),
      load: row.loadmode,
      wearPercentage: null,
      col0: row.outside_diameter,
      col10: "91%",
      col20: "81%",
      col30: "71%",
      col40: "61%",
      col50: "61%",
      col60: "51%",
      col70: "41%",
      col80: "31%",
      col90: "21%",
      col100: "11%",
    };
    jQuery("#graph-table").removeClass("notView");
    this.getSeries(params, nroItem);
  }

  getCroquis(req: CasingWearArrayReq) {
    this.service.getHttpCroquis(req).subscribe((croquis) => {
      this.imageString = [];
      if (croquis["data"]["imageList"] != undefined) {
        for (let i = 0; i < 4; i++) {
          this.imagePath[i].showImage = croquis["data"]["imageList"][i]
            ? true
            : false;
          this.imagePath[i].base64 = croquis["data"]["imageList"][i]
            ? this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/png;base64, ${croquis["data"]["imageList"][i]}`
              )
            : "";
          if (req.CasingWearObj.length > i) {
            this.imagePath[i].id = req.CasingWearObj[i].id;

            this.imageString[i] = {
              tableDataId: req.CasingWearObj[i].id,
              imagen: croquis["data"]["imageList"][i]
                ? croquis["data"]["imageList"][i]
                : "",
            };
          }
        }
      }
    });
  }
  getSeries(req: SeriesRequest, nroItem: number) {
    let resp: SeriesResponse[] = [];
    this.service.getSeries(req).subscribe((values) => {
      if (values.status.success == true) {
        resp = values.data;
        if (nroItem == 0) {
          this.serie1 = resp;
        }
        if (nroItem == 1) {
          this.serie2 = resp;
        }
        if (nroItem == 2) {
          this.serie3 = resp;
        }
        if (nroItem == 3) {
          this.serie4 = resp;
        }
        this.createGraphData();

        let selectedFilter = {
          Item: nroItem,
          Outside_diameter: req.selOd,
          Wall_thickness : req.selWt,
          Tool_joint_outside_diameter: req.selOdGr,
          Grade: req.selGrade,
          Load_mode: req.selLoadMode,
          Zero_percent_value: resp[0].value,
          Ten_percent_value: resp[1].value,
          Twenty_percent_value: resp[2].value,
          Thirty_percent_value: resp[3].value,
          Forty_percent_value: resp[4].value,
          Fifty_percent_value: resp[5].value,
        }

        this.createTableData(selectedFilter);
      }
    });
  }
  createGraphData() {
    this.graph_data = [];
    const limit = this.maxX / 10;
    if (limit == 0) {
      return;
    }
    for (let item = 0; item <= limit; item++) {
      for (let index = 1; index <= 4; index++) {
      if (this['serie' + index].length) {
        const elem1 = this['serie' + index][item];
        if (this.graph_data[item]) {
          this.graph_data[item]["filtro" + index] = elem1.value;
        } 
        else {
        let filterData = {
          x: elem1.percent + "%"
        };
        filterData["filtro" + index] = elem1.value;
        this.graph_data.push(filterData); 
        }
  
        if(!(this.graph_labels.indexOf('Filter ' + index) > -1 && this.graph_ykeys.indexOf('filtro' + index) > -1)) {
          this.graph_labels.splice(index - 1,0,'Filter ' + index)
          this.graph_ykeys.splice(index - 1,0,'filtro' + index)
        } 
      }
      }
    }

    graph.setData(this.graph_data);
  }

  createTableData(selectedFilter?, notPercentData?) {

    this.table_data = [];
    const limit = this.maxX / 10;
    if (limit == 0) {
      return;
    }
    if (this.labels.length == 0) {
      return;
    }
    for (let item = 0; item < this.labels.length; item++) {
      const labelItem = this.labels[item];
      if (labelItem.length > 0) {
        let label = this.labels[item].substring(
          0,
          this.labels[item].indexOf("]") + 1
        );
        let load = this.labels[item].substring(
          this.labels[item].indexOf("]") + 1
        );
        let obj = { configuration: label, load: load };
        if (item == 0) {
          for (let item = 0; item <= limit; item++) {
            const elem1 = this.serie1[item];
            obj["col" + elem1.percent] = elem1.value + "%";
          }
          this.table_data[item] = obj;
        }
        if (item == 1) {
          for (let item = 0; item <= limit; item++) {
            const elem2 = this.serie2[item];
            obj["col" + elem2.percent] = elem2.value + "%";
          }
          this.table_data[item] = obj;
        }
        if (item == 2) {
          for (let item = 0; item <= limit; item++) {
            const elem3 = this.serie3[item];
            obj["col" + elem3.percent] = elem3.value + "%";
          }
          this.table_data[item] = obj;
        }
        if (item == 3) {
          for (let item = 0; item <= limit; item++) {
            const elem4 = this.serie4[item];
            obj["col" + elem4.percent] = elem4.value + "%";
          }
          this.table_data[item] = obj;
        }
      }
    }

    for (let i = 0; i < this.wearPercentages.length; i++)
      if (this.wearPercentages[i] && this.table_data[i])
        this.table_data[i].wearPercentage = this.wearPercentages[i];

    if(!notPercentData)
      this.getPercentages(selectedFilter);
  }

  getAction() {
    this.subActionService = this._actionService.currentMessage.subscribe(
      (value) => {
        switch (value) {
          case HeaderState.report:
            this.printReport(this._actionService.data);
            break;
          case HeaderState.validate:
            this.confirmReport();
            break;
          default:
            break;
        }
      }
    );
  }

  printReport(printedModalData: PrintedModalData) {
    let printedCWRequest: PrintedCWRequest = {
      printOnlyFirstPage: printedModalData.printOnlyFirstPage,
      requestedBy: printedModalData.requestedBy,
      authorizedBy: printedModalData.authorizedBy,
      reviewedBy: printedModalData.reviewedBy,
      SDCaseNumber: printedModalData.SDCaseNumber,
      createdBy: this.nameCurrentUser
        ? this.nameCurrentUser
        : "-",
      tableData: this.table_data,
      wearPercentage: this.wearPerVal,
      serie1: this.serie1,
      serie2: this.serie2,
      serie3: this.serie3,
      serie4: this.serie4,
      maxXAxis: this.maxX,
      croquis: this.imageString,
    };

    this._pdfService.getPdfCw(printedCWRequest);
    this._actionService.changeAction(HeaderState.default);
  }

  confirmReport() {
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
    else if (this.validReportCw == false) {
      Swal.fire({
        title: "Action required",
        text: "Please select all values to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
  }

  getPercentages(selectedFilter?) {
    let req: GetPercentagesRequest = {
      percentage: this.wearPerVal,
      serie1: this.serie1,
      serie2: this.serie2,
      serie3: this.serie3,
      serie4: this.serie4,
    };

    let logRequest = [];
    let log;
    
    this.service.getPercentages(req).subscribe((percentages) => {
      for (let i = 0; i < this.table_data.length; i++) {
        if (this.table_data[i]) {
          this.wearPercentages[i] = percentages["data"]["wearPercentage"][i];

          this.table_data[i].wearPercentage = this.wearPercentages[i];
        }

        if(selectedFilter && selectedFilter.Item == i) {
          log = {
            OutsideDiameter: selectedFilter.Outside_diameter,
            WallThickness : selectedFilter.Wall_thickness,
            ToolJointOutsideDiameter: selectedFilter.Tool_joint_outside_diameter,
            Grade: selectedFilter.Grade,
            LoadMode: selectedFilter.Load_mode,
            ZeroPercentValue: selectedFilter.Zero_percent_value,
            TenPercentValue: selectedFilter.Ten_percent_value,
            TwentyPercentValue: selectedFilter.Twenty_percent_value,
            ThirtyPercentValue: selectedFilter.Thirty_percent_value,
            FortyPercentValue: selectedFilter.Forty_percent_value,
            FiftyPercentValue: selectedFilter.Fifty_percent_value,
            WearPercentage: this.wearPerVal,
            WearPercentageValue: percentages["data"]["wearPercentage"][i].value,
            CustomerId: this.preSimulationService.getCurrentCustomerId(),
            CountryId: this.preSimulationService.getCurrentCountryId()
          }

          logRequest.push(log);
        }
        else if(!selectedFilter && this.casingWearData[i]) {
          log = {
            OutsideDiameter: this.casingWearData[i].outside_diameter,
            WallThickness : this.casingWearData[i].wall_thickness,
            ToolJointOutsideDiameter: this.casingWearData[i].tool_join_od,
            Grade: this.casingWearData[i].grade,
            LoadMode: this.casingWearData[i].loadmode,
            ZeroPercentValue: this[`serie${this.casingWearData[i].id + 1}`][0].value,
            TenPercentValue: this[`serie${this.casingWearData[i].id + 1}`][1].value,
            TwentyPercentValue: this[`serie${this.casingWearData[i].id + 1}`][2].value,
            ThirtyPercentValue: this[`serie${this.casingWearData[i].id + 1}`][3].value,
            FortyPercentValue: this[`serie${this.casingWearData[i].id + 1}`][4].value,
            FiftyPercentValue: this[`serie${this.casingWearData[i].id + 1}`][5].value,
            WearPercentage: this.wearPerVal,
            WearPercentageValue: percentages["data"]["wearPercentage"][this.casingWearData[i].id].value,
            CustomerId: this.preSimulationService.getCurrentCustomerId(),
            CountryId: this.preSimulationService.getCurrentCountryId()
          }

          logRequest.push(log);
        }
      }

      
      this.service.registerLog(logRequest).subscribe();
    });
  }

  get labelsFiltered ()
  {
    return this.labels.filter((x) => x != '');
  }

  get imagesFiltered ()
  {
    return this.imagePath.filter((x) => x.showImage);
  }

  ngOnDestroy() {
    this.subActionService.unsubscribe();
    this.preSimulationService.resetValues();
  }
}
export interface Imagen {
  id?: number;
  showImage: boolean;
  base64: any;
}
