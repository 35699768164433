<style>
  .panel-body table {
    font-family: "FrutigerRoman,sans-serif";
  }
  div .modal-header {
    background-color: white;
    color: black;
  }
  .tituloColumna {
    font-weight: bold;
    font-family: FrugiterBold, sans-serif;
    font-size: 14px;
    margin-bottom: 11px;
    margin-top: 11px;
  }
  .datosGrilla {
    font-family: FrutigerRoman, sans-serif;
    font-size: 13px;
  }
  .iconosGrilla {
    font-size: 20px;
  }
</style>
<div class="am-content">
  <div class="main-content">
    <div class="page-head">
      <h2 class="title">User Mgr</h2>
      <ol class="breadcrumb">
        <li>
          <a [routerLink]="NAVIGATIONS.HOME.route">{{
            NAVIGATIONS.HOME.name
          }}</a>
        </li>
        <li class="active">{{ NAVIGATIONS.USER_MGR.name }}</li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <div class="form-group">
              <label class="col-sm-1 control-label title input-xs">Users</label>
              <p style="float: right"></p>
            </div>
          </div>

          <div class="panel-body table">
            <div class="widget widget-fullwidth widget-small">
              <div
                id="table1_wrapper"
                class="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                <div class="row am-datatable-body">
                  <div class="col-sm-12">
                    <table
                      id="tableUser"
                      datatable
                      [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger"
                      class="row-border hover"
                    >
                      <thead>
                        <tr>
                          <th class="title tituloColumna">UserID</th>
                          <th class="title tituloColumna">Name</th>
                          <th class="title tituloColumna">Surname</th>
                          <th class="title tituloColumna">Mail Address</th>
                          <th class="title tituloColumna">Department</th>
                          <th class="title tituloColumna">Roles</th>
                          <th class="title tituloColumna">Nr Login</th>
                          <th class="title tituloColumna">Last Login</th>
                          <th class="title tituloColumna">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let user of userMgrData; index as i"
                          class="datosGrilla"
                        >
                          <td>{{ user.id }}</td>
                          <td>{{ user.name }}</td>
                          <td>{{ user.surname }}</td>
                          <td>{{ user.mail_address }}</td>
                          <td>{{ user.department }}</td>
                          <td>{{ user.rol }}</td>
                          <td>{{ user.nro_login }}</td>
                          <td>{{ user.last_login | date: "medium" }}</td>
                          <td class="center">
                            <i
                              data-toggle="modal"
                              data-target="#form-edit-user"
                              class="icon s7-pen iconosGrilla"
                              (click)="Editar(user.id)"
                            ></i
                            ><i
                              class="icon s7-trash iconosGrilla"
                              (click)="Eliminar(user.id)"
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Inicio de modal que muestra campos para editar datos del usuario-->
        <div
          id="form-edit-user"
          tabindex="-1"
          role="dialog"
          class="modal fade modal-colored-header"
        >
          <div
            class="modal-dialog custom-width"
            style="width: 550px; height: 500px"
          >
            <div class="modal-content">
              <div style="background-color: #009">
                <div class="panel-heading">
                  <h3 style="color: white; font-weight: bold">
                    {{ tituloModalEdit }}
                  </h3>
                </div>
              </div>

              <div class="modal-body form" style="padding-top: 20px">
                <div class="form-group">
                  <label>User Id</label>
                  <input
                    type="name"
                    class="form-control"
                    [(ngModel)]="userData.id"
                    disabled="true"
                  />
                </div>
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="name"
                    placeholder="Name"
                    class="form-control"
                    [(ngModel)]="userData.name"
                  />
                </div>
                <div class="form-group">
                  <label>Surname</label>
                  <input
                    type="name"
                    placeholder="Surname"
                    class="form-control"
                    [(ngModel)]="userData.surname"
                  />
                </div>
                <div class="form-group">
                  <label>Department</label>
                  <input
                    type="name"
                    class="form-control"
                    [(ngModel)]="userData.department"
                  />
                </div>
                <div class="form-group">
                  <label>Role</label>
                  <input
                    type="name"
                    class="form-control"
                    value="{{ userData.rol }}"
                    disabled="true"
                  />
                </div>
              </div>
              <div
                class="modal-footer"
                style="padding-top: 0px; border-top: none"
              >
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-primary md-close"
                  (click)="GrabarCambiosEditados()"
                >
                  Submit
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-default md-close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--Fin de modal que muestra campos para editar datos del usuario-->
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
  >
</div>
