import { AbstractControl, ValidationErrors } from "@angular/forms";
export class NumericInputValidator {
  static min(
    min: number,
    customErrorMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number = Number(String(control.value).replace(",", "."));

      if ((value || value === 0) && control.value != '') {

        const error: any = {
          delimited: true,
          errorMessage:
            customErrorMessage ??
            `The value entered must be greater than ${min}`,
        };

        return ((isEqualsIncluded && value < min) || (!isEqualsIncluded && value <= min)) ? error : null;
      } else return null;
    };
  }

  static max(
    max: number,
    customErrorMessage?: string,
    isEqualsIncluded: boolean = true
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: number = Number(String(control.value).replace(",", "."));

      if ((value || value === 0) && control.value != '') {

        const error: any = {
          delimited: true,
          errorMessage:
            customErrorMessage ??
            `The value entered must be lower than ${max}`,
        };

        return ((isEqualsIncluded && value > max) || (!isEqualsIncluded && value >= max)) ? error : null;
      } else return null;
    };
  }
}
