import { Component } from "@angular/core";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent {
  readonly NAVIGATIONS = NAVIGATIONS;

  constructor() {}
}
