import { Component, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';

import {
  AuthenticatedResult,
  OidcClientNotification,
  OpenIdConfiguration,
  UserDataResult
} from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Config, LoginService } from './Login.service';
import { Router } from '@angular/router';
declare var jQuery:any;
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  providers: [ LoginService ],
})

export class LoginComponent {
  private isAdmin = new EventEmitter<boolean>();
  // isAdmin: boolean = true;
  
  error: any;
  headers: string[] = [];
  config: Config | undefined;
  configurations: OpenIdConfiguration[];
  userDataChanged$: Observable<OidcClientNotification<any>>;
  userData$: Observable<UserDataResult>;
  isAuthenticated$: Observable<AuthenticatedResult>; 
  isAuth$: boolean;
  lisAuthenticated: boolean;

  constructor(
     private loginService: LoginService

    ,private serviceRouter: Router    
     ){

    // if(this.lisAuthenticated || this.lisAuthenticated == undefined)
    // {
    //   this.serviceRouter.navigate(['/home']);
    // }

  }



  noPermits(){
    Swal.fire({
      title: "You don't have access to this application",
      html: 'If you need permission please create a ticket through ITUSS Self Service ' + '<a href="https://applications.tenaris.net/sites/ITUserSupport">https://applications.tenaris.net/sites/ITUserSupport</a>',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#aaa',
      timer: 30000,
      showConfirmButton: false
    });
  }



  ngOnInit() {



    this.configurations = this.loginService.currentConfig();



  }


  login(configId: string) {
    this.loginService.login(configId);
    
  }


}
