import {
  HttpClient, HttpHeaders, HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { DCPerforatedBeforeRequest, DCPerforatedHolesRequest, DCPerforatedLengthRequest, DCPerforatedPipesRequest, PerforatedPipeRequest, PerforatedPipeResponse, DeletePerforatedSimulationResponse, LoadPerforatedSimulationResponse, ExistsPerforatedSimulationResponse, SaveSimulationPerforatedRequest } from "src/app/modules/slotted-perforated-pipe/perforated-pipe/models/perforated-pipe";
import { SlottedPerforatedPipeImagesRequest } from "src/app/modules/slotted-perforated-pipe/shared/models/slotted-perforated-image";
import { environment } from "src/environments/environment";
import { HttpService } from "../http/http.service";
import { APIResponse } from "../http/models/api-response";

@Injectable({
  providedIn: "root",
})
export class PerforatedPipeService {
  private readonly API_ENDPOINT: string = environment.apiEndpoint;

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getPerforatedPipeImages(
    slottedPerforatedPipeImagesRequest: SlottedPerforatedPipeImagesRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      slottedPerforatedPipeImagesRequest,
      httpParams,
      "Input"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/createReportImages`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  getPerforatedPipeResults(
    perforatedPipeRequest: PerforatedPipeRequest
  ): Observable<PerforatedPipeResponse> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      perforatedPipeRequest,
      httpParams,
      "SlottedPerforatedPipeRequest"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/calculation`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as PerforatedPipeResponse;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  saveSimulation(saveSimulationPerforatedRequest: SaveSimulationPerforatedRequest) {
    let formData: FormData = new FormData();

    formData = this.httpService.getFormDataFromObject(
      saveSimulationPerforatedRequest,
      formData,
      "SavePerforatedSimulationRequestParams"
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/saveSimulation`;

    const http$ = this.http.post<any>(URL, formData, {
      reportProgress: true,
      observe: "events",
    });

    return http$;
  }

  loadSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/byTreeId/${treeId}`;
    const http$ = this.http
      .get<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as LoadPerforatedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  existsSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/exists/${treeId}`;
    const http$ = this.http
      .get<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as ExistsPerforatedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  deleteSimulation(treeId: number) {
    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/byTreeId/${treeId}`;
    const http$ = this.http
      .delete<APIResponse>(URL, {
        headers: new HttpHeaders({
          "Content-type": "application/json",
        }),
      })
      .pipe(
        map((APIResponse: APIResponse) => {
          if (APIResponse.status.success)
            return APIResponse.data as DeletePerforatedSimulationResponse;
          else this.httpService.handleErrorMessage(APIResponse.status.message);
        }),
        catchError(this.httpService.handleError)
      );
    return http$;
  }

  dataCheckPerforatedPipes(
    dcPerforatedPipesRequest: DCPerforatedPipesRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcPerforatedPipesRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/validate/pipedata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  dataCheckPerforatedLength(
    dcPerforatedLengthRequest: DCPerforatedLengthRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcPerforatedLengthRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/validate/lengthdata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  dataCheckPerforatedHoles(
    dcPerforatedSlotsRequest: DCPerforatedHolesRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcPerforatedSlotsRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/validate/perfdata`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  dataCheckPerforatedBefore(
    dcPerforatedBeforeRequest: DCPerforatedBeforeRequest
  ): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = this.httpService.getHttpParamsFromObject(
      dcPerforatedBeforeRequest,
      httpParams
    );

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/validate/beforesubmit`;

    return this.http.get<APIResponse>(URL, { params: httpParams }).pipe(
      map((APIResponse: APIResponse) => {
        if (APIResponse.status.success)
          return APIResponse.data as any;
        else this.httpService.handleErrorMessage(APIResponse.status.message);
      }),
      catchError(this.httpService.handleError)
    );
  }

  registerLog(request: any) {
    const httpOptionsCustom = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Skip-Loading': 'true'
      })
    }

    const URL: string = `${this.API_ENDPOINT}slottedperforatedpipes/v1/Perforated/RegisterLogs`;

    return this.http.post<any>(URL, request, httpOptionsCustom).pipe(
      map((response) => {
        if (response && response.status.success) {
          return response.data;
        } else {
          throw new Error('An error occurred');
        }
      })
    );
  }
}
