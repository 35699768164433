import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UpdateUserEditRequest } from "src/app/models/user/User";
import { DataService } from "src/app/services/Data.service";
import { UserService } from "src/app/services/User.service";
import { environment } from "src/environments/environment";
import { UserMgr } from "../usermgr/components/Usermgr.model";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  userMgrData: UserMgr[];
  userDataNoReset: UserMgr;
  userDataReset: UserMgr;
  postId: number;
  rolName: string;
  imagePath: any;
  departmentProfile: string = "";
  nameProfile: string = "";
  surnameProfile: string = "";
  base64data: any;

  constructor(
    private serviceUser: UserService,
    private dataService: DataService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.GetDataProfile();
  }

  GetDataProfile() {
    this.serviceUser.getHttpFoto(sessionStorage.getItem("logged") ? true : false).subscribe((userProfile) => {
      this.nameProfile = userProfile["data"]["givenName"]
        ? userProfile["data"]["givenName"]
            .trim()
            .toLowerCase()
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
        : "";

      this.surnameProfile = userProfile["data"]["surname"]
        ? userProfile["data"]["surname"]
            .trim()
            .toLowerCase()
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
        : "";

      this.dataService.changeUserId(
        userProfile["data"]["id"] ? userProfile["data"]["id"] : ""
      );

      this.dataService.changeDepartment(
        userProfile["data"]["department"] ? userProfile["data"]["department"] : ""
      );

      this.dataService.changeNameSurname(
        this.nameProfile + " " + this.surnameProfile
      );

      this.imagePath = userProfile["data"]["photo"]
      ? this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png;base64, ${userProfile["data"]["photo"]}`
        )
      : "";
      
      sessionStorage.setItem("logged", "true");
    });
  }
}
