import { HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { merge } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { take } from "rxjs/operators";
import { HeaderState } from "src/app/models/header/HeaderState";
import { EmptyDataTablePipe } from "src/app/pipes/empty-data-table/empty-data-table.pipe";
import { RoundDecimalsPipe } from "src/app/pipes/round-decimals/round-decimals.pipe";
import { TransformToPercentPipe } from "src/app/pipes/transform-to-percent/transform-to-percent.pipe";
import { ActionService } from "src/app/services/Action.service";
import { DataService } from "src/app/services/Data.service";
import { HttpService } from "src/app/services/http/http.service";
import { PdfService } from "src/app/services/pdf.service";
import { SlottedPipeService } from "src/app/services/slotted-pipe/slotted-pipe.service";
import { TreeService } from "src/app/services/Tree.service";
import { NAVIGATIONS } from "src/app/shared/consts/navigations";
import { SimulationTypes } from "src/app/shared/enums/simulation-types";
import { UnitSystems } from "src/app/shared/enums/unit-systems";
import { NumericInputValidator } from "src/app/shared/validators/numeric-input.validator";
import { WarningValidator } from "src/app/shared/validators/warning.validator";
import Swal from "sweetalert2";
import { PrintedModalData } from "../../CasingWear/models/printed";
import { PipeResults } from "../shared/enums/slotted-results.enum";
import { Groupings } from "../shared/enums/groupings.enum";
import {
  Attachment,
  DCSlottedBeforeRequest,
  LoadSlottedPerforatedAttachments,
  Results,
  SaveSimulationSlottedRequest,
  SaveSlottedSimulationResponse,
  SlottedPipeRequest,
} from "./models/slotted-pipe";
import { PrintPdfSPRequest } from "./models/treeSP";
import { SlottedService } from "./services/slotted.service";
import { Patterns } from "../shared/enums/patterns.enum";
import { SlottedPerforatedPipeImagesRequest } from "../shared/models/slotted-perforated-image";
import { PreSimulationService } from "src/app/shared/components/pre-simulation/service/pre-simulation.service";

@Component({
  selector: "app-slotted-pipe",
  templateUrl: "./slotted-pipe.component.html",
  styleUrls: ["./slotted-pipe.component.css"],
})
export class SlottedPipeComponent implements OnInit {
  //Services
  subActionService: Subscription;
  subDataService: Subscription;
  //Validators
  validReportSP: boolean = false;
  isSimulationSaved: boolean = false;
  customerId: number | null;
  countryId: number | null;
  //Form Group
  fgSlottedPipe: FormGroup;
  formInitialValues: any;
  //Attributes
  simulationName: string = "";
  treeId?: number = null;
  version?: number = null;
  nameCurrentUser: string;
  department: string = "";
  slottedResults: Results;
  attachments: Attachment[] = [];
  attachmentsUploaded: LoadSlottedPerforatedAttachments[] = [];
  dynamicImage: any = null;
  pdfImages: any = null;

  showDataCheck: boolean = false;
  refreshDataCheckData$: Subscription;
  dcpProduct$: Subscription;

  showPipeExists: boolean = false;
  showGradeWarning: boolean = false;

  isPreSimulationFormValid: boolean = false;

  @ViewChild('dataCheckButton') dataCheckButton: ElementRef;
  

  readonly NAVIGATIONS = NAVIGATIONS;

  constructor(
    private fb: FormBuilder,
    private slottedPipeService: SlottedPipeService,
    private slottedService: SlottedService,
    private actionService: ActionService,
    private dataService: DataService,
    private treeService: TreeService,
    private httpService: HttpService,
    private pdfService: PdfService,
    private sanitizer: DomSanitizer,
    private preSimulationService: PreSimulationService
  ) {}

  ngOnInit(): void {
    this.dataService.changeModulo("slottedPipe");

    this.createForm();
    this.subscribeActionService();
    this.subscribeDataService();

    this.dataService.currentValidateReportSPSource.subscribe(
      (validReport) => (this.validReportSP = validReport)
    );

    this.dataService.currentDepartmentSource.subscribe(
      (dpto) => (this.department = dpto)
    );

    this.dataService.currentNameSurnameSource.subscribe(
      (name) => (this.nameCurrentUser = name)
    );

    this.preSimulationService.formValidity$.subscribe(isValid => {
      this.isPreSimulationFormValid = isValid;
    });

    this.slottedService.slottedResults$.subscribe((slottedResults) => {
      this.slottedResults = slottedResults;
    });

    this.slottedService.attachments$.subscribe((attachments) => {
      this.attachments = attachments;
    });

    this.slottedService.attachmentsUploaded$.subscribe(
      (attachmentsUploaded) => {
        this.attachmentsUploaded = attachmentsUploaded;
      }
    );

    this.slottedService.submit$.subscribe(() => {
      this.submit();
    });

    this.slottedService.slottedInputGrooves$.subscribe((grooves) => {
      if (grooves == false) {
        this.disableForm();
        Swal.fire({
          text: "The model and equations related with this analysis are valid only for grooves machined with saw. Irregularities in the final geometry of the slots, residual tensions, and high temperature changes, produced due to alternative slotting methods, were not taken into account.",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
      } else if (grooves == true) {
        this.enableForm();
      }
    });

    this.dataService.currentRolSource.pipe(take(1)).subscribe((role) => {
      if (role == "USER") {
        this.addFormValidators();
        this.showPipeExists = false;
      } else if (role == "ADMIN" || role == "SUPER_USER") {
        this.showPipeExists = true;
        this.fgSlottedPipe
          .get("pipeExists")
          .addValidators(Validators.requiredTrue);
        this.addFormWarnings();
      }
    });

    this.slottedService.dataCheckFix$.subscribe((fix) => {
      fix.data.forEach((dataCheckFix) => {
        switch (dataCheckFix.fieldName) {
          case "iConnection":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("connection")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGrade":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("grade")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGradeStrength":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("gradeStrength")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iNominalWeight":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("nominalWeight")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iOutsideDiameter":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("outsideDiameter")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPipeBodyCollapseRating":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("pipeBodyCollapseRating")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iRemainingBodyWall":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("minWallThickness")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iWallThickness":
            this.fgSlottedPipe
              .get("fgPipes")
              .get("wallThickness")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iLL1":
            this.fgSlottedPipe
              .get("fgLengths")
              .get("freeDistanceToBoxLL1")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iLL2":
            this.fgSlottedPipe
              .get("fgLengths")
              .get("freeDistanceToBoxLL2")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPipeLengthLT":
            this.fgSlottedPipe
              .get("fgLengths")
              .get("pipeLengthLT")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iCircSeparation":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("gangOnlyCircSeparation")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iPitchBetweenAxes":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("staggeredOnly")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iNumberOfGrooves":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("gangOnly")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iNumberOfColumnsXft":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("columnsPerFoot")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iNumberOfAxis":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("numberOfAxis")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iMaxGrooveWOD":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("maxGrooveWidthOD")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iMaxGrooveWID":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("maxGrooveWidthID")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iMaxGrooveLOD":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("maxGrooveLengthOD")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iMaxGrooveLID":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("maxGrooveLengthID")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGroovesPerimetralDistance":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("groovesPerimetralDistance")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGroovesPerFoot":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("groovesPerFoot")
              .setValue(dataCheckFix.dataValue);
            break;
          case "iGroovesAxialDistance":
            this.fgSlottedPipe
              .get("fgSlots")
              .get("groovesAxialDistance")
              .setValue(dataCheckFix.dataValue);
            break;
        }
      });

      this.slottedService.setRefreshDataCheckData(fix.section, fix.isSubmitting);
    });

    this.refreshDataCheckData$ =
      this.slottedService.refreshDataCheckData$.subscribe((refreshData) => {
        if (refreshData.section == "BEFORE") this.dataCheck(refreshData.isSubmitting);
      });

    this.dcpProduct$ = this.slottedService.dcpProduct$.subscribe(
      (dcpProduct) => {
        this.fgSlottedPipe
          .get("fgPipes")
          .get("outsideDiameter")
          .setValue(dcpProduct.outsideDiameter);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("wallThickness")
          .setValue(dcpProduct.wallThickness);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("nominalWeight")
          .setValue(dcpProduct.nominalWeight);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("minWallThickness")
          .setValue((1 + dcpProduct.minWallThickness) * 100);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("grade")
          .setValue(dcpProduct.grade);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("connection")
          .setValue(dcpProduct.connection);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("gradeStrength")
          .setValue(dcpProduct.minYieldStrength / 1000);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating")
          .setValue(dcpProduct.collapsePressure);
        this.fgSlottedPipe
          .get("fgPipes")
          .get("gradeWarning")
          .setValue(dcpProduct.gradeWarning);

        this.fgSlottedPipe
          .get("fgPipes")
          .get("grade")
          .valueChanges.pipe(take(1))
          .subscribe(() => {
            this.fgSlottedPipe
              .get("fgPipes")
              .get("gradeWarning")
              .setValue(false);
          });

        this.slottedService.setCloseDcpModal(true);
      }
    );

    merge(
      this.fgSlottedPipe.get("fgType").valueChanges,
      this.fgSlottedPipe.get("fgSlots").valueChanges,
      this.fgSlottedPipe.get("fgPipes").valueChanges,
      this.fgSlottedPipe.get("fgLengths").valueChanges
    ).subscribe(() => {
      if (
        this.fgSlottedPipe.get("fgType").valid &&
        this.fgSlottedPipe.get("fgSlots").valid &&
        this.fgSlottedPipe.get("fgPipes").valid &&
        this.fgSlottedPipe.get("fgLengths").valid
      ) {
        this.showDataCheck = true;
      } else {
        this.showDataCheck = false;
      }
    });
  }

  private enableForm() {
    this.fgSlottedPipe.enable({ emitEvent: false });

    if (
      this.fgSlottedPipe.get("fgType").get("pattern").value === Patterns.Aligned
    )
      this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").disable();

    if (
      this.fgSlottedPipe.get("fgType").get("grouping").value ===
      Groupings.Single
    ) {
      this.fgSlottedPipe.get("fgSlots").get("gangOnlyCircSeparation").disable();
      this.fgSlottedPipe.get("fgSlots").get("gangOnly").disable();
    }
  }

  addFormWarnings() {
    //PIPES
    this.fgSlottedPipe
      .get("fgPipes")
      .get("gradeWarning")
      .addValidators(
        WarningValidator.requiredBooleanWarning(
          true,
          "GRADE",
          "This tool does not apply for anisotropic (Highly alloyed) materials, High Collapse or Improved Collapse grades. For these cases contact WEDE."
        )
      );

    this.fgSlottedPipe
      .get("fgPipes")
      .get("outsideDiameter")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          20,
          null,
          "OUTSIDE_DIAMETER",
          "Pipe OD out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          2.375,
          null,
          "OUTSIDE_DIAMETER",
          "Pipe OD out of common range."
        ),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("nominalWeight")
      .addValidators([
        WarningValidator.minValueLimitedByValueWarning(
          4.6,
          null,
          "NOMINAL_WEIGHT",
          "Nominal weight out of common range."
        ),
        WarningValidator.maxValueLimitedByValueWarning(
          297.1,
          null,
          "NOMINAL_WEIGHT",
          "Nominal weight out of common range."
        ),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("wallThickness")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          1.5,
          null,
          "WALL_THICKNESS",
          "Wall thickness out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          0.19,
          null,
          "WALL_THICKNESS",
          "Wall thickness out of common range."
        ),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("minWallThickness")
      .addValidators([
        WarningValidator.maxValueLimitedByValueWarning(
          100,
          null,
          "MIN_WALL_THICKNESS",
          "Remaining body wall out of common range."
        ),
        WarningValidator.minValueLimitedByValueWarning(
          87.5,
          null,
          "MIN_WALL_THICKNESS",
          "Remaining body wall out of common range."
        ),
      ]);

    //SLOTS
    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthOD")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          5,
          null,
          "MAX_GROOVE_LENGTH_OD",
          "Groove length out of valid range."
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthID")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          5,
          null,
          "MAX_GROOVE_LENGTH_ID",
          "Groove length out of valid range."
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveWidthOD")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          0.5,
          null,
          "MAX_GROOVE_WIDTH_OD",
          "Groove width out of valid range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveWidthID")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          0.5,
          null,
          "MAX_GROOVE_WIDTH_ID",
          "Groove width out of valid range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("gangOnly")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          5,
          null,
          "GANG_ONLY",
          "Gang number bigger than common range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("numberOfAxis")
      .addValidators(
        WarningValidator.maxValueLimitedByValueWarning(
          180,
          null,
          "NUMBER_OF_AXIS",
          "Number of axes to big to fit in the circunference.",
          false
        )
      );
  }
  addFormValidators() {
    //PIPES
    this.fgSlottedPipe
      .get("fgPipes")
      .get("outsideDiameter")
      .addValidators([
        NumericInputValidator.max(20, "Pipe OD out of common range."),
        NumericInputValidator.min(2.375, "Pipe OD out of common range."),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("nominalWeight")
      .addValidators([
        NumericInputValidator.max(297.1, "Nominal weight out of common range."),
        NumericInputValidator.min(4.6, "Nominal weight out of common range."),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("wallThickness")
      .addValidators([
        NumericInputValidator.max(21.5, "Wall thickness out of common range."),
        NumericInputValidator.min(0.19, "Wall thickness out of common range."),
      ]);

    this.fgSlottedPipe
      .get("fgPipes")
      .get("minWallThickness")
      .addValidators([
        NumericInputValidator.max(
          100,
          "Remaining body wall out of common range."
        ),
        NumericInputValidator.min(
          87.5,
          "Remaining body wall out of common range."
        ),
      ]);

    //SLOTS
    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthOD")
      .addValidators(
        NumericInputValidator.max(5, "Groove length out of valid range.")
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveLengthID")
      .addValidators(
        NumericInputValidator.max(5, "Groove length out of valid range.")
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveWidthOD")
      .addValidators(
        NumericInputValidator.max(
          0.5,
          "Groove width out of valid range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("maxGrooveWidthID")
      .addValidators(
        NumericInputValidator.max(
          0.5,
          "Groove width out of valid range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("gangOnly")
      .addValidators(
        NumericInputValidator.max(
          5,
          "Gang number bigger than common range.",
          false
        )
      );

    this.fgSlottedPipe
      .get("fgSlots")
      .get("numberOfAxis")
      .addValidators(
        NumericInputValidator.max(
          180,
          "Number of axes to big to fit in the circunference.",
          false
        )
      );
  }

  disableForm() {
    this.fgSlottedPipe
      .get("fgType")
      .get("pattern")
      .disable({ emitEvent: false });
    this.fgSlottedPipe
      .get("fgType")
      .get("grouping")
      .disable({ emitEvent: false });
    this.fgSlottedPipe.get("fgPipes").disable({ emitEvent: false });
    this.fgSlottedPipe.get("fgSlots").disable({ emitEvent: false });
    this.fgSlottedPipe.get("fgLengths").disable({ emitEvent: false });
    this.fgSlottedPipe.get("fgNotes").disable({ emitEvent: false });
    this.fgSlottedPipe.get("fgAttachments").disable({ emitEvent: false });
    this.fgSlottedPipe.get("pipeExists").disable({ emitEvent: false });
  }

  private createForm() {
    this.fgSlottedPipe = this.fb.group({
      fgType: this.fb.group({
        groovesMachinedWithSaw: [null, Validators.requiredTrue],
        pattern: [null, Validators.required],
        grouping: [null, Validators.required],
      }),
      fgPipes: this.fb.group({
        outsideDiameter: [null, Validators.required],
        wallThickness: [null, Validators.required],
        nominalWeight: [null, Validators.required],
        minWallThickness: [null, Validators.required],
        grade: [null, Validators.required],
        connection: [null, Validators.required],
        gradeStrength: [null, Validators.required],
        pipeBodyCollapseRating: [null, Validators.required],
        gradeWarning: [false],
      }),
      fgSlots: this.fb.group({
        maxGrooveLengthOD: [null, Validators.required],
        maxGrooveLengthID: [null, Validators.required],
        maxGrooveWidthOD: [null, Validators.required],
        maxGrooveWidthID: [null, Validators.required],
        groovesPerFoot: [null, Validators.required],
        groovesAxialDistance: [null, Validators.required],
        gangOnly: [null, Validators.required],
        numberOfAxis: [null, Validators.required],
        staggeredOnly: [null, Validators.required],
        gangOnlyCircSeparation: [null, Validators.required],
        columnsPerFoot: [null, Validators.required],
        groovesPerimetralDistance: [null, Validators.required],
      }),
      fgLengths: this.fb.group({
        pipeLengthLT: [null, Validators.required],
        freeDistanceToBoxLL1: [null, Validators.required],
        freeDistanceToBoxLL2: [null, Validators.required],
      }),
      fgNotes: this.fb.group({
        notes: [null],
      }),
      fgAttachments: this.fb.group({
        attachments: [null],
      }),
      pipeExists: [false],
    });

    this.formInitialValues = this.fgSlottedPipe.value;
  }

  onSubmit() {
    this.preDataCheck(true);
  }

  preDataCheck(isSubmitting = false) {
    if(!this.fgSlottedPipe.get("fgType").valid) {
      Swal.fire({
        title: "Invalid Type Section",
        text: "Please, complete the type section.",
        icon: "warning",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      this.dataCheck(isSubmitting);
      this.dataCheckButton.nativeElement.click();
    }
  }

  submit() {
    this.simulationName = "";
    this.dataService.changeValidateReportSP(false);
    this.isSimulationSaved = false;
    let slottedPipeRequest: SlottedPipeRequest = {
      lengths: {
        pipeLength: this.fgSlottedPipe.get("fgLengths").get("pipeLengthLT")
          .value,
        lenL1: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
          .value,
        lenL2: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
          .value,
        lenMachined:
          this.fgSlottedPipe.get("fgLengths").get("pipeLengthLT").value -
          (this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
            .value +
            this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
              .value),
      },
      pipe: {
        od: this.fgSlottedPipe.get("fgPipes").get("outsideDiameter").value,
        nominalWeight: this.fgSlottedPipe.get("fgPipes").get("nominalWeight")
          .value,
        grade: this.fgSlottedPipe.get("fgPipes").get("grade").value,
        gradeStrength: this.fgSlottedPipe.get("fgPipes").get("gradeStrength")
          .value,
        wt: this.fgSlottedPipe.get("fgPipes").get("wallThickness").value,
        connection: this.fgSlottedPipe.get("fgPipes").get("connection").value,
        collapsePressure: this.fgSlottedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating").value,
        rbw: this.fgSlottedPipe.get("fgPipes").get("minWallThickness").value,
      },
      slotsScheme: {
        groovePerimDistance: this.fgSlottedPipe
          .get("fgSlots")
          .get("groovesPerimetralDistance").value,
        grooveAxialDistance: this.fgSlottedPipe
          .get("fgSlots")
          .get("groovesAxialDistance").value,
        staggeredColumnPitch:
          this.fgSlottedPipe.get("fgType").get("pattern").value ==
          Patterns.Staggered
            ? this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").value
            : 0,
        numColumnsPerFoot: this.fgSlottedPipe
          .get("fgSlots")
          .get("columnsPerFoot").value,
        numAxis: this.fgSlottedPipe.get("fgSlots").get("numberOfAxis").value,
        gangCircSeparation:
          this.fgSlottedPipe.get("fgType").get("grouping").value ==
          Groupings.Gangs
            ? this.fgSlottedPipe.get("fgSlots").get("gangOnlyCircSeparation")
                .value
            : 0,
        gangNumofGroove:
          this.fgSlottedPipe.get("fgType").get("grouping").value ==
          Groupings.Gangs
            ? this.fgSlottedPipe.get("fgSlots").get("gangOnly").value
            : 0,
        maxGrooveWideID: this.fgSlottedPipe
          .get("fgSlots")
          .get("maxGrooveWidthID").value,
        maxGrooveWideOD: this.fgSlottedPipe
          .get("fgSlots")
          .get("maxGrooveWidthOD").value,
        maxGrooveLenID: this.fgSlottedPipe
          .get("fgSlots")
          .get("maxGrooveLengthID").value,
        maxGrooveLenOD: this.fgSlottedPipe
          .get("fgSlots")
          .get("maxGrooveLengthOD").value,
        groovePerFoot: this.fgSlottedPipe.get("fgSlots").get("groovesPerFoot")
          .value,
        holeDiameterToll: 0,
        holeDiameter: 0,
        groovePitchBetweenAxisHelicoidal: 0,
      },
      type: {
        machinedSaw: this.fgSlottedPipe
          .get("fgType")
          .get("groovesMachinedWithSaw").value,
        pattern: this.fgSlottedPipe.get("fgType").get("pattern").value,
        grouping: this.fgSlottedPipe.get("fgType").get("grouping").value,
      },
    };

    this.getImages();

    this.slottedPipeService.getSlottedPipeResults(slottedPipeRequest).subscribe(
      (slottedPipeResponse) => {
        this.slottedService.setSlottedResults(slottedPipeResponse.results);
        this.slottedService.setShowSlottedResults(true);

        let logRequest = [{
          GroovesMachinedWithSaw: slottedPipeRequest.type.machinedSaw,
          Pattern: slottedPipeRequest.type.pattern,
          Grouping: slottedPipeRequest.type.grouping,
          OutsideDiameter: slottedPipeRequest.pipe.od,
          WallThickness: slottedPipeRequest.pipe.wt,
          NominalWeight: slottedPipeRequest.pipe.nominalWeight,
          MinWallThickness: slottedPipeRequest.pipe.rbw,
          Grade: slottedPipeRequest.pipe.grade,
          Connection: slottedPipeRequest.pipe.connection,
          GradeStrength: slottedPipeRequest.pipe.gradeStrength,
          PipeBodyCollapseRating: slottedPipeRequest.pipe.collapsePressure,
          MaxGrooveLengthAtOD: slottedPipeRequest.slotsScheme.maxGrooveLenOD,
          MaxGrooveLengthAtID: slottedPipeRequest.slotsScheme.maxGrooveLenID,
          MaxGrooveWidthAtOD: slottedPipeRequest.slotsScheme.maxGrooveWideOD,
          MaxGrooveWidthAtID: slottedPipeRequest.slotsScheme.maxGrooveWideID,
          GroovesPerFoot: slottedPipeRequest.slotsScheme.groovePerFoot,
          NumberOfAxes: slottedPipeRequest.slotsScheme.numAxis,
          NumberOfColumnsPerFoot: slottedPipeRequest.slotsScheme.numColumnsPerFoot,
          GroovesAxialDistance: slottedPipeRequest.slotsScheme.grooveAxialDistance,
          FirstSecondRowPitch: slottedPipeRequest.type.pattern ==
          Patterns.Staggered
            ? slottedPipeRequest.slotsScheme.staggeredColumnPitch
            : null,
          GroovesPerimetralDistance: slottedPipeRequest.slotsScheme.groovePerimDistance,
          NumberOfGrooves: slottedPipeRequest.type.grouping ==
          Groupings.Gangs
            ? slottedPipeRequest.slotsScheme.gangNumofGroove
            : null,
          CircSeparation: slottedPipeRequest.type.grouping ==
          Groupings.Gangs
            ? slottedPipeRequest.slotsScheme.gangCircSeparation
            : null,
          PipeLengthLT: slottedPipeRequest.lengths.pipeLength,
          FreeDistanceToBoxLL1: slottedPipeRequest.lengths.lenL1,
          FreeDistanceToPinLL2: slottedPipeRequest.lengths.lenL2,
          AxialPipeBodyRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Axial.pipeBodyRating),
          TorsionPipeBodyRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Torsion.pipeBodyRating),
          CollapsePipeBodyRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Collapse.pipeBodyRating),
          BendingPipeBodyRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Bending.pipeBodyRating),
          AxialSlottedPipeRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Axial.slottedPipeRating),
          TorsionSlottedPipeRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Torsion.slottedPipeRating),
          CollapseSlottedPipeRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Collapse.slottedPipeRating),
          BendingSlottedPipeRatings: this.validateNumberToLogs(slottedPipeResponse.results.results.Bending.slottedPipeRating),
          AxialRemainingFactor: this.validateNumberToLogs(slottedPipeResponse.results.results.Axial.reductionFactor),
          TorsionRemainingFactor: this.validateNumberToLogs(slottedPipeResponse.results.results.Torsion.reductionFactor),
          CollapseRemainingFactor: this.validateNumberToLogs(slottedPipeResponse.results.results.Collapse.reductionFactor),
          BendingRemainingFactor: this.validateNumberToLogs(slottedPipeResponse.results.results.Bending.reductionFactor),
          PercentageAtOuterDiameter: this.validateNumberToLogs(slottedPipeResponse.results.openAreaPercentAtOD),
          PercentageAtInnerDiameter: this.validateNumberToLogs(slottedPipeResponse.results.openAreaPercentAtID),
          TotalAreaAtOuterDiameter: this.validateNumberToLogs(slottedPipeResponse.results.openAreaInchAtOD),
          TotalAreaAtInnerDiameter: this.validateNumberToLogs(slottedPipeResponse.results.openAreaInchAtID),
          CustomerId: this.preSimulationService.getCurrentCustomerId(),
          CountryId: this.preSimulationService.getCurrentCountryId()
        }];

        this.slottedPipeService.registerLog(logRequest).subscribe();
      },
      (err: Error) => {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
      },
      () => {
        this.formChangesOnce();
      }
    );
  }

  private validateNumberToLogs(value: number) {
    let returnValue: number = value;
    if (typeof value !== 'number' || isNaN(value) || !isFinite(value))
      returnValue = null;
    else if (Object.is(value, -0))
      returnValue = 0;
    return value;
  }

  private getImages() {
    return new Promise<void>((resolve) => {
      let slottedPerforatedPipeImagesRequest: SlottedPerforatedPipeImagesRequest =
        {
          iEnumSimType: SimulationTypes.SLOTTED,
          iEnumUnitMeasure: UnitSystems.API,
          iCircSeparation: this.fgSlottedPipe
            .get("fgSlots")
            .get("gangOnlyCircSeparation").value,
          iPatternType: this.fgSlottedPipe.get("fgType").get("pattern").value,
          iGroupingType: this.fgSlottedPipe.get("fgType").get("grouping").value,
          iHoleDiameter: 0,
          iLL1: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
            .value,
          iLL2: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
            .value,
          iMaxGrooveLOD: this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveLengthOD").value,
          iMaxGrooveWID: this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveWidthID").value,
          iMaxGrooveWOD: this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveWidthOD").value,
          iNumberOfAxis: this.fgSlottedPipe.get("fgSlots").get("numberOfAxis")
            .value,
          iNumberOfColumnsXft: this.fgSlottedPipe
            .get("fgSlots")
            .get("columnsPerFoot").value,
          iNumberOfGrooves:
            this.fgSlottedPipe.get("fgType").get("grouping").value ==
            Groupings.Gangs
              ? this.fgSlottedPipe.get("fgSlots").get("gangOnly").value
              : 0,
          iOutsideDiameter: this.fgSlottedPipe
            .get("fgPipes")
            .get("outsideDiameter").value,
          iPerimetralDistance: this.fgSlottedPipe
            .get("fgSlots")
            .get("groovesPerimetralDistance").value,
          iPipeLengthLT: this.fgSlottedPipe.get("fgLengths").get("pipeLengthLT")
            .value,
          iPitchBetweenColumn:
            this.fgSlottedPipe.get("fgType").get("pattern").value ==
            Patterns.Staggered
              ? this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").value
              : 0,
          iPitchCircBetweenAxes: 0,
          iRotate: true,
          iSlot_Hole_AxialDistance: this.fgSlottedPipe
            .get("fgSlots")
            .get("groovesAxialDistance").value,
        };

      this.slottedPipeService
        .getSlottedPipeImages(slottedPerforatedPipeImagesRequest)
        .subscribe((slottedPipeImagesResponse) => {
          this.pdfImages = slottedPipeImagesResponse.imageDic;

          this.dynamicImage = slottedPipeImagesResponse.imageList
            ? this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/png;base64, ${slottedPipeImagesResponse.imageList}`
              )
            : null;

          resolve();
        });
    });
  }

  checkPipeExists() {
    const controlPipeExist: AbstractControl =
      this.fgSlottedPipe.get("pipeExists");
    controlPipeExist.setValue(!controlPipeExist.value);
  }

  private fireWarningModal(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
    });
  }

  private subscribeDataService() {
    this.subDataService = this.dataService.currentData.subscribe(
      (selectedSimulation) => {
        if (selectedSimulation.action != "") {
          switch (selectedSimulation.action) {
            case HeaderState.load:
              this.confirmLoad(selectedSimulation);
              break;
            case HeaderState.save:
              this.confirmSave(selectedSimulation);
              break;
            case HeaderState.delete:
              this.confirmDelete(selectedSimulation);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  confirmSave(selectedSimulation: any) {
    if (selectedSimulation.visibility == "Select") {
      this.fireWarningModal("Action required", "Please select Visibility");
    } else {
      this.slottedPipeService
        .existsSimulation(selectedSimulation.id)
        .subscribe((resp) => {
          Swal.fire({
            title: resp.exists
              ? "Do you want to overwrite the simulation"
              : "Do you want to save the simulation",
            text: selectedSimulation.path + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
            confirmButtonText: "Proceed",
            width: 700,
          }).then((result) => {
            if (result.isConfirmed) {
              let saveSimulationSlottedRequest: SaveSimulationSlottedRequest = {
                userId: selectedSimulation.userId,
                treeId: selectedSimulation.id,
                name: selectedSimulation.text,
                department: this.department,
                visibility: selectedSimulation.visibility,
                treeNotes: selectedSimulation.notes,
                simulationNotes: this.fgSlottedPipe.get("fgNotes").get("notes")
                  .value,
                idAttachmentsToCopy:
                  selectedSimulation.id == this.treeId
                    ? []
                    : this.attachmentsUploaded
                        .filter((x) => !x.overwritten)
                        .map((x) => x.id),
                overwriteAttachments: selectedSimulation.id != this.treeId,
                customerId: this.preSimulationService.getCurrentCustomerId(),
                countryId: this.preSimulationService.getCurrentCountryId(),
                type: {
                  groove_machined_with_saw: this.fgSlottedPipe
                    .get("fgType")
                    .get("groovesMachinedWithSaw").value,
                  pattern: this.fgSlottedPipe.get("fgType").get("pattern")
                    .value,
                  grouping: this.fgSlottedPipe.get("fgType").get("grouping")
                    .value,
                },
                pipe: {
                  outside_diameter: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("outsideDiameter").value,
                  nominal_weight: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("nominalWeight").value,
                  grade: this.fgSlottedPipe.get("fgPipes").get("grade").value,
                  grade_strength: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("gradeStrength").value,
                  wall_thickness: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("wallThickness").value,
                  connection: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("connection").value,
                  pipe_body_collapse_rating: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("pipeBodyCollapseRating").value,
                  remaining_body_wall: this.fgSlottedPipe
                    .get("fgPipes")
                    .get("minWallThickness").value,
                },
                slot: {
                  grooves_perimetral_distance: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("groovesPerimetralDistance").value,
                  grooves_axial_distance: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("groovesAxialDistance").value,
                  staggered_column_pitch:
                    this.fgSlottedPipe.get("fgType").get("pattern").value ==
                    Patterns.Staggered
                      ? this.fgSlottedPipe.get("fgSlots").get("staggeredOnly")
                          .value
                      : null,
                  number_of_columns_xft: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("columnsPerFoot").value,
                  number_of_axis: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("numberOfAxis").value,
                  circ_separation:
                    this.fgSlottedPipe.get("fgType").get("grouping").value ==
                    Groupings.Gangs
                      ? this.fgSlottedPipe
                          .get("fgSlots")
                          .get("gangOnlyCircSeparation").value
                      : null,
                  number_of_grooves:
                    this.fgSlottedPipe.get("fgType").get("grouping").value ==
                    Groupings.Gangs
                      ? this.fgSlottedPipe.get("fgSlots").get("gangOnly").value
                      : null,
                  maxGroove_WID: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("maxGrooveWidthID").value,
                  maxGroove_WOD: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("maxGrooveWidthOD").value,
                  maxGroove_LID: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("maxGrooveLengthID").value,
                  maxGroove_LOD: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("maxGrooveLengthOD").value,
                  grooves_per_foot: this.fgSlottedPipe
                    .get("fgSlots")
                    .get("groovesPerFoot").value,
                },
                length: {
                  pipe_length_LT: this.fgSlottedPipe
                    .get("fgLengths")
                    .get("pipeLengthLT").value,
                  LL1: this.fgSlottedPipe
                    .get("fgLengths")
                    .get("freeDistanceToBoxLL1").value,
                  LL2: this.fgSlottedPipe
                    .get("fgLengths")
                    .get("freeDistanceToBoxLL2").value,
                },
                attachments: this.attachments,
                outputs: {
                  open_area_inch_at_id: this.slottedResults?.openAreaInchAtID,
                  open_area_percent_at_id:
                    this.slottedResults?.openAreaPercentAtID,
                  open_area_inch_at_od: this.slottedResults?.openAreaInchAtOD,
                  open_area_percent_at_od:
                    this.slottedResults?.openAreaPercentAtOD,
                  results: [
                    {
                      key: PipeResults.Axial,
                      pipe_body_rating:
                        this.slottedResults?.results.Axial.pipeBodyRating,
                      slotted_pipe_rating:
                        this.slottedResults?.results.Axial.slottedPipeRating,
                      reduction_factor:
                        this.slottedResults?.results.Axial.reductionFactor,
                    },
                    {
                      key: PipeResults.Bending,
                      pipe_body_rating:
                        this.slottedResults?.results.Bending.pipeBodyRating,
                      slotted_pipe_rating:
                        this.slottedResults?.results.Bending.slottedPipeRating,
                      reduction_factor:
                        this.slottedResults?.results.Bending.reductionFactor,
                    },
                    {
                      key: PipeResults.Collapse,
                      pipe_body_rating:
                        this.slottedResults?.results.Collapse.pipeBodyRating,
                      slotted_pipe_rating:
                        this.slottedResults?.results.Collapse.slottedPipeRating,
                      reduction_factor:
                        this.slottedResults?.results.Collapse.reductionFactor,
                    },
                    {
                      key: PipeResults.Torsion,
                      pipe_body_rating:
                        this.slottedResults?.results.Torsion.pipeBodyRating,
                      slotted_pipe_rating:
                        this.slottedResults?.results.Torsion.slottedPipeRating,
                      reduction_factor:
                        this.slottedResults?.results.Torsion.reductionFactor,
                    },
                  ],
                },
              };

              this.slottedPipeService
                .saveSimulation(saveSimulationSlottedRequest)
                .subscribe(
                  (event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                      console.log("UPLOADING FILES");
                      console.log(event.loaded);
                      console.log(event.total);
                    }
                    if (event.type === HttpEventType.Response) {
                      if (event.body.status.success) {
                        let resp = event.body
                          .data as SaveSlottedSimulationResponse;

                        Swal.fire({
                          title: "Saved!",
                          text: "The simulation has been saved.",
                          icon: "success",
                          confirmButtonColor: "#009",
                          timer: 3000,
                          timerProgressBar: true,
                        });
                        jQuery("#sidebar").removeClass("active");
                        jQuery(".overlay").removeClass("active");

                        this.treeId = resp.treeId;
                        this.version = resp.version;
                        this.simulationName = selectedSimulation.path;
                        this.isSimulationSaved = true;
                        this.customerId = saveSimulationSlottedRequest.customerId;
                        this.countryId = saveSimulationSlottedRequest.countryId;
                        this.preSimulationService.setSavedCustomerId(this.customerId);
                        this.preSimulationService.setSavedCountryId(this.countryId);   
                        this.dataService.setSimulationIdName(
                          selectedSimulation.path
                        );

                        if (resp.attachments.length > 0) {
                          this.slottedService.setAttachments([]);
                          this.slottedService.setAttachmentsUploaded(
                            resp.attachments
                          );
                        }

                        this.setValidationToPrint();
                      } else
                        this.httpService.handleErrorMessage(
                          event.body.status.message
                        );
                    }
                  },
                  (err: Error) => {
                    Swal.fire({
                      title: err.message,
                      icon: "error",
                      confirmButtonColor: "#009",
                      cancelButtonColor: "#aaa",
                    });
                  },
                  () => {
                    this.formChangesOnce();
                  }
                );
            }
          });
        });
    }
  }

  private setValidationToPrint() {
    if (this.fgSlottedPipe.valid && this.slottedResults)
      this.dataService.changeValidateReportSP(true);
  }

  confirmLoad(selectedSimulation: any) {
    if (
      JSON.stringify(this.formInitialValues) !==
      JSON.stringify(this.fgSlottedPipe.value)
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) this.loadSimulation(selectedSimulation);
      });
    } else {
      this.loadSimulation(selectedSimulation);
    }
  }

  private loadSimulation(selectedSimulation: any) {
    this.resetSimulation();

    this.slottedPipeService.loadSimulation(selectedSimulation.id).subscribe(
      (resp) => {
        this.customerId = resp.customerId;
        this.countryId = resp.countryId;
        this.preSimulationService.setLoadedCustomerId(this.customerId);
        this.preSimulationService.setLoadedCountryId(this.countryId);
        this.preSimulationService.setSavedCustomerId(this.customerId);
        this.preSimulationService.setSavedCountryId(this.countryId);

        jQuery("#sidebar").removeClass("active");
        jQuery(".overlay").removeClass("active");

        if (resp.data) {
          if (resp.treeId) this.treeId = resp.treeId;

          if (resp.version || resp.version === 0) this.version = resp.version;

          this.fgSlottedPipe
            .get("fgType")
            .get("groovesMachinedWithSaw")
            .setValue(resp.data.type.groove_machined_with_saw);
          this.fgSlottedPipe
            .get("fgType")
            .get("pattern")
            .setValue(resp.data.type.pattern);
          this.fgSlottedPipe
            .get("fgType")
            .get("grouping")
            .setValue(resp.data.type.grouping);

          this.fgSlottedPipe
            .get("fgPipes")
            .get("outsideDiameter")
            .setValue(resp.data.pipe.outside_diameter);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("wallThickness")
            .setValue(resp.data.pipe.wall_thickness);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("nominalWeight")
            .setValue(resp.data.pipe.nominal_weight);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("minWallThickness")
            .setValue(resp.data.pipe.remaining_body_wall);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("grade")
            .setValue(resp.data.pipe.grade);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("connection")
            .setValue(resp.data.pipe.connection);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("gradeStrength")
            .setValue(resp.data.pipe.grade_strength);
          this.fgSlottedPipe
            .get("fgPipes")
            .get("pipeBodyCollapseRating")
            .setValue(resp.data.pipe.pipe_body_collapse_rating);

          this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveLengthOD")
            .setValue(resp.data.slotScheme.maxGroove_LOD);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveLengthID")
            .setValue(resp.data.slotScheme.maxGroove_LID);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveWidthOD")
            .setValue(resp.data.slotScheme.maxGroove_WOD);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("maxGrooveWidthID")
            .setValue(resp.data.slotScheme.maxGroove_WID);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("groovesPerFoot")
            .setValue(resp.data.slotScheme.grooves_per_foot);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("groovesAxialDistance")
            .setValue(resp.data.slotScheme.grooves_axial_distance);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("gangOnly")
            .setValue(resp.data.slotScheme.number_of_grooves);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("numberOfAxis")
            .setValue(resp.data.slotScheme.number_of_axis);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("staggeredOnly")
            .setValue(resp.data.slotScheme.staggered_column_pitch);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("gangOnlyCircSeparation")
            .setValue(resp.data.slotScheme.circ_separation);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("columnsPerFoot")
            .setValue(resp.data.slotScheme.number_of_columns_xft);
          this.fgSlottedPipe
            .get("fgSlots")
            .get("groovesPerimetralDistance")
            .setValue(resp.data.slotScheme.grooves_perimetral_distance);

          this.fgSlottedPipe
            .get("fgLengths")
            .get("pipeLengthLT")
            .setValue(resp.data.length.pipe_length_LT);
          this.fgSlottedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL1")
            .setValue(resp.data.length.lL1);
          this.fgSlottedPipe
            .get("fgLengths")
            .get("freeDistanceToBoxLL2")
            .setValue(resp.data.length.lL2);

          const pipeExists: boolean =
            resp.data.outputs.open_area_inch_at_id != null ||
            resp.data.outputs.open_area_inch_at_od != null ||
            resp.data.outputs.open_area_percent_at_id != null ||
            resp.data.outputs.open_area_percent_at_od != null;

          this.fgSlottedPipe.get("pipeExists").setValue(pipeExists);

          if (resp.data.attachments.length > 0)
            this.slottedService.setAttachmentsUploaded(resp.data.attachments);

          if (pipeExists) {
            this.getImages().then(() => {
              if(!this.customerId || !this.countryId)
                Swal.fire({
                  title: "Action required",
                  text: "Please select customer and country to view the simulation",
                  icon: "warning",
                  confirmButtonColor: "#009",
                  cancelButtonColor: "#aaa",
                });
              else
                Swal.fire({
                  title: "Loaded!",
                  text: "The simulation has been loaded.",
                  icon: "success",
                  confirmButtonColor: "#009",
                  timer: 3000,
                  timerProgressBar: true,
                });         
            });
            this.slottedService.setSlottedResultsOnLoad(resp.data.outputs);
            this.slottedService.setShowSlottedResults(true);
          }
          else {
            if(!this.customerId || !this.countryId)
              Swal.fire({
                title: "Action required",
                text: "Please select customer and country to view the simulation",
                icon: "warning",
                confirmButtonColor: "#009",
                cancelButtonColor: "#aaa",
              });
            else
              Swal.fire({
                title: "Loaded!",
                text: "The simulation has been loaded.",
                icon: "success",
                confirmButtonColor: "#009",
                timer: 3000,
                timerProgressBar: true,
              });   
          }
        }

        this.fgSlottedPipe
          .get("fgNotes")
          .get("notes")
          .setValue(resp.simulation_notes);

        this.simulationName = selectedSimulation.path;
        this.isSimulationSaved = true;
        this.dataService.setSimulationIdName(selectedSimulation.path);
        this.setValidationToPrint();
      },
      (err: Error) => {
        Swal.fire({
          title: err.message,
          icon: "error",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });

        this.resetSimulation();
      },
      () => {
        this.formChangesOnce();
      }
    );
  }

  confirmDelete(selectedSimulation: any) {
    Swal.fire({
      title: "Do you want to delete the item",
      text: selectedSimulation.path + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#009",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Proceed",
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteSimulation(selectedSimulation);
      }
    });
  }

  private deleteSimulation(selectedSimulation: any) {
    this.treeService.deleteTree(
      this.dataService.extraData,
      selectedSimulation.userId,
      selectedSimulation
    );
    this.actionService.changeAction(HeaderState.cancel);
    jQuery('#sidebar').removeClass('active');
    jQuery('.overlay').removeClass('active');
    if (selectedSimulation.level == "simulation") {
      this.slottedPipeService.deleteSimulation(selectedSimulation.id).subscribe(
        (resp) => {
          Swal.fire({
            title: "Deleted!",
            text: "The item has been deleted.",
            icon: "success",
            confirmButtonColor: "#009",
            timer: 3000,
            timerProgressBar: true,
          });

          if (resp.treeId == this.treeId) this.resetSimulation();
        },
        (err: Error) => {
          Swal.fire({
            title: err.message,
            icon: "error",
            confirmButtonColor: "#009",
            cancelButtonColor: "#aaa",
          });
        }
      );
    } else {
      Swal.fire({
        title: "Deleted!",
        text: "The item has been deleted.",
        icon: "success",
        confirmButtonColor: "#009",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  private subscribeActionService() {
    this.subActionService = this.actionService.currentMessage.subscribe(
      (value) => {
        switch (value) {
          case HeaderState.new:
            this.confirmReset();
            break;
          case HeaderState.report:
            this.printReport();
            break;
          case HeaderState.validate:
            this.confirmReport();
            break;
          default:
            break;
        }
      }
    );
  }

  confirmReset() {
    if (
      JSON.stringify(this.formInitialValues) !==
      JSON.stringify(this.fgSlottedPipe.value)
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "The values on screen will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetSimulation();
          this.preSimulationService.resetValues();
        }
      });
    } else {
      this.resetSimulation();
      this.preSimulationService.resetValues();
      Swal.fire({
        title: "New simulation created",
        icon: "info",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
    }
  }

  private resetSimulation() {
    this.treeId = null;
    this.version = null;
    this.simulationName = "";
    this.slottedService.setShowSlottedResults(false);
    this.slottedService.setSlottedResults(null);
    this.fgSlottedPipe.reset(this.formInitialValues);
    this.dataService.changeValidateReportSP(false);
    this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").enable();
    this.fgSlottedPipe.get("fgSlots").get("gangOnlyCircSeparation").enable();
    this.fgSlottedPipe.get("fgSlots").get("gangOnly").enable();
    this.pdfImages = null;
    this.dynamicImage = null;
    this.slottedService.setAttachments([]);
    this.slottedService.setAttachmentsUploaded([]);
  }

  private formChangesOnce() {
    this.fgSlottedPipe.valueChanges.pipe(take(1)).subscribe(() => {
      this.treeId = null;
      this.version = null;
      this.simulationName = "";
      this.slottedService.setShowSlottedResults(false);
      this.slottedService.setSlottedResults(null);
      this.dataService.changeValidateReportSP(false);
      this.isSimulationSaved = false;
      this.dynamicImage = null;
      this.pdfImages = null;
    });
  }

  private printReport() {
    let printPdfSPRequest: PrintPdfSPRequest = this.printFileSPRequest(
      this.actionService.data
    );
    this.pdfService.getPdfSP(printPdfSPRequest);
  }

  private printFileSPRequest(printedModalData: PrintedModalData) {
    var roundDecimalsPipe = new RoundDecimalsPipe();
    var emptyDataTablePipe = new EmptyDataTablePipe();
    var transformToPercentPipe = new TransformToPercentPipe();
    let printPdfSPRequest: PrintPdfSPRequest = {
      printOnlyFirstPage: printedModalData.printOnlyFirstPage,
      requestedBy: printedModalData.requestedBy,
      authorizedBy: printedModalData.authorizedBy,
      reviewedBy: printedModalData.reviewedBy,
      SDCaseNumber: printedModalData.SDCaseNumber,
      createdBy: this.nameCurrentUser ? this.nameCurrentUser : "-",
      modelVer: this.version,
      preMachiningManufacturer: printedModalData.preMachiningManufacturer,
      type: {
        grooveMachinedWithSaw: this.fgSlottedPipe
          .get("fgType")
          .get("groovesMachinedWithSaw").value,
        pattern: this.fgSlottedPipe.get("fgType").get("pattern").value,
        grouping: this.fgSlottedPipe.get("fgType").get("grouping").value,
      },
      pipe: {
        outsideDiameter: this.fgSlottedPipe
          .get("fgPipes")
          .get("outsideDiameter").value,
        nominalWeight: this.fgSlottedPipe.get("fgPipes").get("nominalWeight")
          .value,
        grade: this.fgSlottedPipe.get("fgPipes").get("grade").value,
        gradeStrength: this.fgSlottedPipe.get("fgPipes").get("gradeStrength")
          .value,
        wallThickness: this.fgSlottedPipe.get("fgPipes").get("wallThickness")
          .value,
        connection: this.fgSlottedPipe.get("fgPipes").get("connection").value,
        pipeBodyCollapseRating: this.fgSlottedPipe
          .get("fgPipes")
          .get("pipeBodyCollapseRating").value,
        remainingBodyWall: this.fgSlottedPipe
          .get("fgPipes")
          .get("minWallThickness").value,
      },
      slot: {
        groovesPerimetralDistance: this.fgSlottedPipe
          .get("fgSlots")
          .get("groovesPerimetralDistance").value,
        groovesAxialDistance: this.fgSlottedPipe
          .get("fgSlots")
          .get("groovesAxialDistance").value,
        staggeredColumnPitch:
          this.fgSlottedPipe.get("fgType").get("pattern").value ==
          Patterns.Staggered
            ? this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").value
            : null,
        numberOfColumnsXft: this.fgSlottedPipe
          .get("fgSlots")
          .get("columnsPerFoot").value,
        numberOfAxis: this.fgSlottedPipe.get("fgSlots").get("numberOfAxis")
          .value,
        circSeparation:
          this.fgSlottedPipe.get("fgType").get("grouping").value ==
          Groupings.Gangs
            ? this.fgSlottedPipe.get("fgSlots").get("gangOnlyCircSeparation")
                .value
            : null,
        numberOfGrooves:
          this.fgSlottedPipe.get("fgType").get("grouping").value ==
          Groupings.Gangs
            ? this.fgSlottedPipe.get("fgSlots").get("gangOnly").value
            : null,
        maxGrooveWID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthID")
          .value,
        maxGrooveWOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthOD")
          .value,
        maxGrooveLID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthID")
          .value,
        maxGrooveLOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthOD")
          .value,
        groovesPerFoot: this.fgSlottedPipe.get("fgSlots").get("groovesPerFoot")
          .value,
      },
      length: {
        pipeLengthLT: this.fgSlottedPipe.get("fgLengths").get("pipeLengthLT")
          .value,
        LL1: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
          .value,
        LL2: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
          .value,
      },
      outputs: {
        openAreaInchAtID: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(this.slottedResults?.openAreaInchAtID, 0)
        ),
        openAreaPercentAtID: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.slottedResults?.openAreaPercentAtID,
            2
          )
        ),
        openAreaInchAtOD: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(this.slottedResults?.openAreaInchAtOD, 0)
        ),
        openAreaPercentAtOD: emptyDataTablePipe.transform(
          roundDecimalsPipe.transform(
            this.slottedResults?.openAreaPercentAtOD,
            2
          )
        ),
        results: [
          {
            key: PipeResults.Axial,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Axial.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Axial.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.slottedResults?.results.Axial.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Bending,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Bending.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Bending.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.slottedResults?.results.Bending.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Collapse,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Collapse.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Collapse.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.slottedResults?.results.Collapse.reductionFactor
                ),
                0
              )
            ),
          },
          {
            key: PipeResults.Torsion,
            pipeBodyRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Torsion.pipeBodyRating,
                0
              )
            ),
            slottedPipeRating: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                this.slottedResults?.results.Torsion.slottedPipeRating,
                0
              )
            ),
            reductionFactor: emptyDataTablePipe.transform(
              roundDecimalsPipe.transform(
                transformToPercentPipe.transform(
                  this.slottedResults?.results.Torsion.reductionFactor
                ),
                0
              )
            ),
          },
        ],
      },
      patternImage: this.pdfImages?.Pattern,
      pipeLengthImage: this.pdfImages?.PipeLength,
      slotHoleImage: this.pdfImages?.Slot_Hole,
      xSectionImage: this.pdfImages?.XSection,
    };
    return printPdfSPRequest;
  }

  confirmReport() {    
    if (!this.preSimulationService.getFormLoadedValidity()) {
      Swal.fire({
        title: "Action required",
        text: "Please select customer and country to generate results",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });

      this.isSimulationSaved = false;
      
      return;
    }

    if (!this.validReportSP) {
      if (this.fgSlottedPipe.invalid) {
        Swal.fire({
          title: "Action required",
          text: "Please load a simulation or verify values for all fields",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
      if (!this.slottedResults) {
        Swal.fire({
          title: "Action required",
          text: "Please press submit and save the simulation to print report",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
      if (this.isSimulationSaved == false || !this.validateIfPreSimulationSaved(this.customerId, this.countryId)) {
        Swal.fire({
          title: "Action required",
          text: "Please save the simulation to print report",
          icon: "warning",
          confirmButtonColor: "#009",
          cancelButtonColor: "#aaa",
        });
        return;
      }
    }

    if (!this.validateIfPreSimulationSaved(this.customerId, this.countryId)) {
      Swal.fire({
        title: "Action required",
        text: "Please save the simulation to print report",
        icon: "warning",
        confirmButtonColor: "#009",
        cancelButtonColor: "#aaa",
      });
      return;
    }
  }

  validateIfPreSimulationSaved(customerId: number | null, countryId: number | null): boolean {
    let isSaved = true;
    if(this.preSimulationService.getCurrentCountryId() == null || this.preSimulationService.getCurrentCustomerId() == null || 
      this.preSimulationService.getCurrentCountryId() != countryId || this.preSimulationService.getCurrentCustomerId() != customerId)
      isSaved = false;

      return isSaved;
  }

  dataCheck(isSubmitting = false) {
    let dcSlottedBeforeRequest: DCSlottedBeforeRequest = {
      iOutsideDiameter: this.fgSlottedPipe.get("fgPipes").get("outsideDiameter")
        .value,
      iWallThickness: this.fgSlottedPipe.get("fgPipes").get("wallThickness")
        .value,
      iGroovesAxialDistance: this.fgSlottedPipe
        .get("fgSlots")
        .get("groovesAxialDistance").value,
      iGroovesPerFoot: this.fgSlottedPipe.get("fgSlots").get("groovesPerFoot")
        .value,
      iGroovesPerimetralDistance: this.fgSlottedPipe
        .get("fgSlots")
        .get("groovesPerimetralDistance").value,
      iMaxGrooveLID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthID")
        .value,
      iMaxGrooveLOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveLengthOD")
        .value,
      iMaxGrooveWID: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthID")
        .value,
      iMaxGrooveWOD: this.fgSlottedPipe.get("fgSlots").get("maxGrooveWidthOD")
        .value,
      iNumberOfAxis: this.fgSlottedPipe.get("fgSlots").get("numberOfAxis")
        .value,
      iNumberOfColumnsXft: this.fgSlottedPipe
        .get("fgSlots")
        .get("columnsPerFoot").value,
      iNumberOfGrooves:
        this.fgSlottedPipe.get("fgType").get("grouping").value ==
        Groupings.Gangs
          ? this.fgSlottedPipe.get("fgSlots").get("gangOnly").value
          : null,
      iPitchBetweenAxes:
        this.fgSlottedPipe.get("fgType").get("pattern").value ==
        Patterns.Staggered
          ? this.fgSlottedPipe.get("fgSlots").get("staggeredOnly").value
          : null,
      iCircSeparation:
        this.fgSlottedPipe.get("fgType").get("grouping").value ==
        Groupings.Gangs
          ? this.fgSlottedPipe.get("fgSlots").get("gangOnlyCircSeparation")
              .value
          : null,
      rdAligned:
        this.fgSlottedPipe?.get("fgType").get("pattern").value ===
        Patterns.Aligned,
      rdStaggered:
        this.fgSlottedPipe?.get("fgType").get("pattern").value ===
        Patterns.Staggered,
      rdGroupingGangs:
        this.fgSlottedPipe?.get("fgType").get("grouping").value ===
        Groupings.Gangs,
      rdGroupingSingle:
        this.fgSlottedPipe?.get("fgType").get("grouping").value ===
        Groupings.Single,
      rdNo:
        this.fgSlottedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === false,
      rdYes:
        this.fgSlottedPipe?.get("fgType").get("groovesMachinedWithSaw")
          .value === true,
      iConnection: this.fgSlottedPipe.get("fgPipes").get("connection").value,
      iGrade: this.fgSlottedPipe.get("fgPipes").get("grade").value,
      iGradeStrength: this.fgSlottedPipe.get("fgPipes").get("gradeStrength")
        .value,
      iNominalWeight: this.fgSlottedPipe.get("fgPipes").get("nominalWeight")
        .value,
      iPipeBodyCollapseRating: this.fgSlottedPipe
        .get("fgPipes")
        .get("pipeBodyCollapseRating").value,
      iRemainingBodyWall: this.fgSlottedPipe
        .get("fgPipes")
        .get("minWallThickness").value,
      iLL1: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL1")
        .value,
      iLL2: this.fgSlottedPipe.get("fgLengths").get("freeDistanceToBoxLL2")
        .value,
      iPipeLengthLT: this.fgSlottedPipe.get("fgLengths").get("pipeLengthLT")
        .value,
    };

    this.slottedPipeService
      .dataCheckSlottedBefore(dcSlottedBeforeRequest)
      .subscribe((data) => {
        let dataCheckData = [];
        let anySectionWithErrors = false;
        let anySectionWithWarnings = false;

        for (let section in data.results) {
          if (data.results[section].hasError) {
            anySectionWithErrors = true;
            for (let key in data.results[section].errors) {
              let errorData = {
                type: "ERROR",
                parent: data.results[section].belongsTo,
                description: data.results[section].errors[key].description,
                options: [],
              };

              for (let optionKey in data.results[section].errors[key].options) {
                errorData.options.push(
                  data.results[section].errors[key].options[optionKey]
                );
              }

              dataCheckData.push(errorData);
            }
          }

          if (data.results[section].hasWarning) {
            anySectionWithWarnings = true;
            for (let key in data.results[section].warnings) {
              let warningData = {
                type: "WARNING",
                parent: data.results[section].belongsTo,
                description: data.results[section].warnings[key].description,
                options: [],
              };

              for (let optionKey in data.results[section].warnings[key]
                .options) {
                warningData.options.push(
                  data.results[section].warnings[key].options[optionKey]
                );
              }

              dataCheckData.push(warningData);
            }
          }
        }

        this.slottedService.setDataCheckData(dataCheckData, "BEFORE", isSubmitting, anySectionWithErrors, anySectionWithWarnings);
      });
  }

  showRangeModal() {
    this.slottedService.setShowRangeModal(true);
  }

  ngOnDestroy() {
    this.subActionService.unsubscribe();
    this.subDataService.unsubscribe();
    this.refreshDataCheckData$.unsubscribe();
    this.dcpProduct$.unsubscribe();
    this.preSimulationService.resetValues();
  }
}
