import { Pipe, PipeTransform } from '@angular/core';
import { TreeUser } from '../models/tree/Index';
import { getTreeFiltered, getTreeVisible } from '../shared/helpers/Index';

@Pipe({ name: 'appFilter', pure: true })
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      for (let u = 0; u < items.length; u++) {
        getTreeVisible(items);
      }
      return items;
    } else {
      searchText = searchText.toLocaleLowerCase();
    }

    let res: TreeUser[] = [];
    for (let u = 0; u < items.length; u++) {
      let add = getTreeFiltered(items[u], searchText);
      if (add != null) {
        res.push(add);
      }
    }
    return res;
  }
}
