import { HeaderState } from "src/app/models/header/HeaderState";
import { TreeAction, TreeLevel, TreeLevels } from "src/app/models/tree/Index";

export function activateTreeItem(trees: TreeLevels, action: HeaderState, selected: TreeAction): TreeAction {
  selected.activate = true;
  if (selected.level == TreeLevel.l1) {
    switch (action) {
      case HeaderState.load:
        selected.activate = false;
        return selected;
      case HeaderState.save:
        selected.activate = false;
        return selected;
      case HeaderState.delete:
        selected.activate = false;
    }
  }

  if (selected.level == TreeLevel.l2) {
    switch (action) {
      case HeaderState.load:
        selected.activate = false;
        return selected;
      case HeaderState.save:
        selected.activate = false;
        return selected;
      case HeaderState.delete:
        if (hasChildren(trees, selected)) {
          selected.activate = false;
          return selected;  
        } else {
          selected.activate = true;
          return selected;
        }
    }
  }

  if (selected.level == TreeLevel.l3) {
    switch (action) {
      case HeaderState.load:
        selected.activate = false;
        return selected;
      case HeaderState.save:
        selected.activate = false;
        return selected;
      case HeaderState.delete:
        if (hasChildren(trees, selected)) {
          selected.activate = false;
          return selected;  
        } else {
          selected.activate = true;
          return selected;
        }
    }
  }

  if (selected.level == TreeLevel.l4) {
    switch (action) {
      case HeaderState.load:
        selected.activate = false;
        return selected;
      case HeaderState.save:
        selected.activate = false;
        return selected;
      case HeaderState.delete:
        if (hasChildren(trees, selected)) {
          selected.activate = false;
          return selected;  
        } else {
          selected.activate = true;
          return selected;
        }
    }
  }

  if (selected.level == TreeLevel.l5) {
    switch (action) {
      case HeaderState.load:
        selected.activate = true;
        return selected;
      case HeaderState.save:
        selected.activate = true;
        return selected;
      case HeaderState.delete:
        selected.activate = true;
        return selected;
    }
  }

  return selected;
}

function hasChildren(trees: TreeLevels, selected: TreeAction): boolean {
  let ret = false;

  trees.users[0].customers.forEach(customer => {
    if (customer.id == selected.id) {
      if (customer.regions.length > 0) {
        ret = true;
      }
    }
    customer.regions.forEach(region => {
      if (region.id == selected.id) {
        if (region.projects.length > 0) {
          ret = true;
        }
      }
      region.projects.forEach(project => {
        if (project.id == selected.id) {
          if (project.simulations.length > 0) {
            ret = true;
          }
        }
      });
    });
  });
  return ret;
}